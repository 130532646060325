import React from 'react';

const Eye = ({ ...props }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.44 8.7C14.925 5.1825 12.075 3 8.99999 3C5.92499 3 3.07499 5.1825 1.55999 8.7C1.51869 8.79462 1.49738 8.89676 1.49738 9C1.49738 9.10324 1.51869 9.20538 1.55999 9.3C3.07499 12.8175 5.92499 15 8.99999 15C12.075 15 14.925 12.8175 16.44 9.3C16.4813 9.20538 16.5026 9.10324 16.5026 9C16.5026 8.89676 16.4813 8.79462 16.44 8.7ZM8.99999 13.5C6.62249 13.5 4.37249 11.7825 3.07499 9C4.37249 6.2175 6.62249 4.5 8.99999 4.5C11.3775 4.5 13.6275 6.2175 14.925 9C13.6275 11.7825 11.3775 13.5 8.99999 13.5ZM8.99999 6C8.40665 6 7.82663 6.17595 7.33328 6.50559C6.83993 6.83524 6.45541 7.30377 6.22835 7.85195C6.00129 8.40013 5.94188 9.00333 6.05763 9.58527C6.17339 10.1672 6.45911 10.7018 6.87867 11.1213C7.29823 11.5409 7.83278 11.8266 8.41472 11.9424C8.99666 12.0581 9.59986 11.9987 10.148 11.7716C10.6962 11.5446 11.1648 11.1601 11.4944 10.6667C11.824 10.1734 12 9.59334 12 9C12 8.20435 11.6839 7.44129 11.1213 6.87868C10.5587 6.31607 9.79564 6 8.99999 6ZM8.99999 10.5C8.70332 10.5 8.41331 10.412 8.16663 10.2472C7.91996 10.0824 7.7277 9.84811 7.61417 9.57403C7.50064 9.29994 7.47093 8.99834 7.52881 8.70736C7.58669 8.41639 7.72955 8.14912 7.93933 7.93934C8.14911 7.72956 8.41638 7.5867 8.70735 7.52882C8.99832 7.47094 9.29992 7.50065 9.57401 7.61418C9.8481 7.72771 10.0824 7.91997 10.2472 8.16665C10.412 8.41332 10.5 8.70333 10.5 9C10.5 9.39782 10.342 9.77936 10.0606 10.0607C9.77934 10.342 9.39781 10.5 8.99999 10.5Z"
        fill="white"
      />
    </svg>
  );
};

export default Eye;
