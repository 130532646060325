import {
  GET_ACTIVITY_TAB_PARAMS_REQUEST,
  GET_ADDRESSES_PARENT_DETAIL_PARAMS_REQUEST,
  GET_CHATS_PARAMS_REQUEST,
  GET_CHILDREN_PARENT_DETAIL_TYPE_PARAMS_REQUEST,
  GET_JOBS_PARAMS_REQUEST,
  GET_NANNY_PARAMS_REQUEST,
  GET_NANNY_TAB_PARAMS_REQUEST,
  GET_PARENTS_PARAMS_REQUEST,
  GET_PAYMENT_PARAMS_REQUEST,
  GET_REVIEWS_PARAMS_REQUEST,
  GET_SITTERS_PARAMS_REQUEST,
  GET_TRANSACTIONS_PARAMS_REQUEST,
  PayloadActionData,
} from 'models';

// =============START=================PARENT===================================

export const GET_PARENT_LIST_REQUEST: string = 'GET_PARENT_LIST_REQUEST';

export const GET_PARENT_DETAIL_REQUEST: string = 'GET_PARENT_DETAIL_REQUEST';

export const GET_PARENT_LIST_DETAIL_REQUEST: string =
  'GET_PARENT_LIST_DETAIL_REQUEST';

export const DELETE_PARENT_REQUEST: string = 'DELETE_PARENT_REQUEST';

export const DELETE_PARENT_DETAIL_REQUEST: string =
  'DELETE_PARENT_DETAIL_REQUEST';

export const DELETE_ALL_PARENT_REQUEST: string = 'DELETE_ALL_PARENT_REQUEST';

export const GET_PARENT_PAYMENT_HISTORY_REQUEST: string =
  'GET_PARENT_PAYMENT_HISTORY_REQUEST';

export const GET_PARENT_CHATS_REQUEST: string = 'GET_PARENT_CHATS_REQUEST';

export const GET_PARENT_ADDRESSES_REQUEST: string =
  'GET_PARENT_ADDRESSES_REQUEST';

export const GET_PARENT_CHILDREN_LIST_REQUEST: string =
  'GET_PARENT_CHILDREN_LIST_REQUEST';

export const GET_PARENT_TRANSACTIONS_REQUEST: string =
  'GET_PARENT_TRANSACTIONS_REQUEST';

export const GET_PARENT_JOBS_REQUEST: string = 'GET_PARENT_JOBS_REQUEST';
export const GET_PARENT_ACTIVITY_REQUEST: string =
  'GET_PARENT_ACTIVITY_REQUEST';

// =============END=================PARENT===================================
// =============START=================SITTER===================================

export const GET_SITTERS_LIST_REQUEST: string = 'GET_SITTERS_LIST_REQUEST';
export const GET_SITTERS_OPTION_LIST_REQUEST: string =
  'GET_SITTERS_OPTION_LIST_REQUEST';

export const GET_SITTER_DETAIL_REQUEST: string = 'GET_SITTER_DETAIL_REQUEST';

export const GET_SITTER_JOBS_REQUEST: string = 'GET_SITTER_JOBS_REQUEST';

export const GET_SITTER_TRANSACTIONS_REQUEST: string =
  'GET_SITTER_TRANSACTIONS_REQUEST';

export const DELETE_SITTER_REQUEST: string = 'DELETE_SITTER_REQUEST';

export const GET_STAFFS_LIST_REQUEST: string = 'GET_STAFFS_LIST_REQUEST';

export const GET_USERS_LIST_REQUEST: string = 'GET_USERS_LIST_REQUEST';

export const GET_USERS_DETAIL_REQUEST: string = 'GET_USERS_DETAIL_REQUEST';

const GET_STAFFS_LIST_REQUEST_WITH_KEYWORD: string =
  'GET_STAFFS_LIST_REQUEST_WITH_KEYWORD';

export default GET_STAFFS_LIST_REQUEST_WITH_KEYWORD;

export const GET_STAFFS_DETAIL_REQUEST: string = 'GET_STAFFS_DETAIL_REQUEST';

export const DELETE_STAFFS_REQUEST: string = 'DELETE_STAFFS_REQUEST';
export const DELETE_USERS_REQUEST: string = 'DELETE_USERS_REQUEST';

export const GET_SITTER_PENDING_PAYOUT_REQUEST: string =
  'GET_SITTER_PENDING_PAYOUT_REQUEST';
export const GET_SITTER_PAYOUT_HISTORY_REQUEST: string =
  'GET_SITTER_PAYOUT_HISTORY_REQUEST';
export const GET_SITTER_CHATS_REQUEST: string = 'GET_SITTER_CHATS_REQUEST';
export const GET_SITTER_REVIEWS_REQUEST: string = 'GET_SITTER_REVIEWS_REQUEST';

// =============END=================SITTER===================================
// =============START=================NANNY===================================
export const GET_NANNY_LIST_REQUEST: string = 'GET_NANNY_LIST_REQUEST';
export const GET_NANNY_DETAIL_REQUEST: string = 'GET_NANNY_DETAIL_REQUEST';
export const DELETE_NANNY_REQUEST: string = 'DELETE_NANNY_REQUEST';
export const GET_NANNY_BOOKING_REQUEST: string = 'GET_NANNY_BOOKING_REQUEST';
export const GET_NANNY_PICTURES_REQUEST: string = 'GET_NANNY_PICTURES_REQUEST';
export const DELETE_NANNY_PICTURES_REQUEST: string =
  'DELETE_NANNY_PICTURES_REQUEST';
export const GET_NANNY_TESTIMONIAL_REQUEST: string =
  'GET_NANNY_TESTIMONIAL_REQUEST';
export const DELETE_NANNY_TESTIMONIAL_REQUEST: string =
  'DELETE_NANNY_TESTIMONIAL_REQUEST';
export const GET_NANNY_CERTIFICATE_REQUEST: string =
  'GET_NANNY_CERTIFICATE_REQUEST';
export const DELETE_NANNY_CERTIFICATE_REQUEST: string =
  'DELETE_NANNY_CERTIFICATE_REQUEST';
export const GET_NANNY_CERTIFICATE_ASSOCIATION_REQUEST: string =
  'GET_NANNY_CERTIFICATE_ASSOCIATION_REQUEST';
export const DELETE_NANNY_CERTIFICATE_ASSOCIATION_REQUEST: string =
  'DELETE_NANNY_CERTIFICATE_ASSOCIATION_REQUEST';
export const GET_NANNY_ACTIVITY_REQUEST: string = 'GET_NANNY_ACTIVITY_REQUEST';

// =============END=================NANNY===================================
export const getParentListRequest = (
  params?: GET_PARENTS_PARAMS_REQUEST | null
) => {
  return {
    type: GET_PARENT_LIST_REQUEST,
    payload: { params },
  };
};

export const getSitterListRequest = (
  params?: GET_SITTERS_PARAMS_REQUEST | null
) => {
  return {
    type: GET_SITTERS_LIST_REQUEST,
    payload: { params },
  };
};

export const getSitterJobsAction = (
  id: string,
  params?: GET_JOBS_PARAMS_REQUEST | null
) => {
  return {
    type: GET_SITTER_JOBS_REQUEST,
    payload: { params: { ...(params || {}), sitter_id: id } },
  };
};

export const getSitterTransactionsAction = (
  id: string,
  params?: GET_TRANSACTIONS_PARAMS_REQUEST | null
) => {
  return {
    type: GET_SITTER_TRANSACTIONS_REQUEST,
    payload: {
      params: { ...(params || {}), user_id: id },
    },
  };
};

export const getParentPaymentHistoryAction = (
  id: string,
  params?: GET_PAYMENT_PARAMS_REQUEST | null
) => {
  return {
    type: GET_PARENT_PAYMENT_HISTORY_REQUEST,
    payload: {
      params: { ...(params || {}), user_id: id },
    },
  };
};

export const getSitterPendingPayoutRequest = (
  id: string,
  params?: GET_PAYMENT_PARAMS_REQUEST | null
) => {
  return {
    type: GET_SITTER_PENDING_PAYOUT_REQUEST,
    payload: { params: { ...(params || {}), user_id: id } },
  };
};

export const getSitterPayoutHistoryRequest = (
  id: string,
  params?: GET_PAYMENT_PARAMS_REQUEST | null
) => {
  return {
    type: GET_SITTER_PAYOUT_HISTORY_REQUEST,
    payload: { params: { ...(params || {}), user_id: id } },
  };
};

export const getSitterChatsRequest = (
  id: string,
  params: GET_CHATS_PARAMS_REQUEST
) => {
  return {
    type: GET_SITTER_CHATS_REQUEST,
    payload: { params: { ...params, sitter_id: id } },
  };
};

export const getSitterReviewsRequest = (
  id: string,
  params: GET_REVIEWS_PARAMS_REQUEST
) => {
  return {
    type: GET_SITTER_REVIEWS_REQUEST,
    payload: { params: { ...params, user_id: id } },
  };
};

export const getParentChatsRequest = (
  id: string,
  params: GET_CHATS_PARAMS_REQUEST
) => {
  return {
    type: GET_PARENT_CHATS_REQUEST,
    payload: { params: { ...params, parent_id: id } },
  };
};

export const getParentAddressesRequest = (
  id: string,
  params?: GET_ADDRESSES_PARENT_DETAIL_PARAMS_REQUEST
) => {
  return {
    type: GET_PARENT_ADDRESSES_REQUEST,
    payload: { id, params },
  };
};

export const getParentChildrenListRequest = (
  id: string,
  params?: GET_CHILDREN_PARENT_DETAIL_TYPE_PARAMS_REQUEST
) => {
  return {
    type: GET_PARENT_CHILDREN_LIST_REQUEST,
    payload: { id, params },
  };
};

export const getParentTransactionsAction = (
  id: string,
  params?: GET_TRANSACTIONS_PARAMS_REQUEST | null
) => {
  return {
    type: GET_PARENT_TRANSACTIONS_REQUEST,
    payload: {
      params: { ...(params || {}), user_id: id },
    },
  };
};

export const getParentJobsAction = (
  id: string,
  params?: GET_JOBS_PARAMS_REQUEST | null
) => {
  return {
    type: GET_PARENT_JOBS_REQUEST,
    payload: { params: { ...(params || {}), parent_id: id } },
  };
};

export const getParentActivityRequest = (
  id: string,
  params?: GET_ACTIVITY_TAB_PARAMS_REQUEST | null
) => {
  return {
    type: GET_PARENT_ACTIVITY_REQUEST,
    payload: { params: { ...(params || {}), parentId: id } },
  };
};

export const deleteUsersRequest = (id: string, onSuccess?: () => void) => {
  return {
    type: DELETE_USERS_REQUEST,
    payload: { id, onSuccess },
  };
};

export const getNannyListRequest = (
  params?: GET_NANNY_PARAMS_REQUEST | null
) => {
  return {
    type: GET_NANNY_LIST_REQUEST,
    payload: { params },
  };
};

export const deleteNannyAction = (payload: {
  id: string;
  onSuccess?: () => void;
}) => {
  return {
    type: DELETE_NANNY_REQUEST,
    payload,
  };
};

export const deleteNannyPicturesAction = (payload: {
  id: string;
  onSuccess?: () => void;
}) => {
  return {
    type: DELETE_NANNY_PICTURES_REQUEST,
    payload,
  };
};

export const getNannyDetailAction = (
  payload: PayloadActionData<{ id: string }>
) => {
  return {
    type: GET_NANNY_DETAIL_REQUEST,
    payload,
  };
};

export const getNannyBookingsAction = (
  id: string,
  params?: GET_NANNY_TAB_PARAMS_REQUEST | null
) => {
  return {
    type: GET_NANNY_BOOKING_REQUEST,
    payload: { params: { ...(params || {}), nannyId: id } },
  };
};

export const getNannyActivityRequest = (
  id: string,
  params?: GET_ACTIVITY_TAB_PARAMS_REQUEST | null
) => {
  return {
    type: GET_NANNY_ACTIVITY_REQUEST,
    payload: { params: { ...(params || {}), nannyId: id } },
  };
};

export const getNannyPicturesRequest = (
  id: string,
  params?: GET_NANNY_TAB_PARAMS_REQUEST | null
) => {
  return {
    type: GET_NANNY_PICTURES_REQUEST,
    payload: { params: { ...(params || {}), nannyId: id } },
  };
};

export const getNannyTestimonialRequest = (
  id: string,
  params?: GET_NANNY_TAB_PARAMS_REQUEST | null
) => {
  return {
    type: GET_NANNY_TESTIMONIAL_REQUEST,
    payload: { params: { ...(params || {}), nannyId: id } },
  };
};

export const deleteNannyTestimonialAction = (payload: {
  id: string;
  onSuccess?: () => void;
}) => {
  return {
    type: DELETE_NANNY_TESTIMONIAL_REQUEST,
    payload,
  };
};

export const getNannyCertificateAssociationRequest = (
  id: string,
  params?: GET_NANNY_TAB_PARAMS_REQUEST | null
) => {
  return {
    type: GET_NANNY_CERTIFICATE_ASSOCIATION_REQUEST,
    payload: { params: { ...(params || {}), nannyId: id } },
  };
};

export const deleteNannyCertificateAssociationAction = (payload: {
  id: string;
  onSuccess?: () => void;
}) => {
  return {
    type: DELETE_NANNY_CERTIFICATE_ASSOCIATION_REQUEST,
    payload,
  };
};
