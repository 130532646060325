import { HeadCell, REQUESTS_TYPE } from 'models';

export const headCells: readonly HeadCell<REQUESTS_TYPE>[] = [
  {
    id: 'number',
    numeric: false,
    disablePadding: true,
    isSort: true,
    label: 'Request No.',
  },
  {
    id: 'Parent',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Parent Name',
  },
  {
    id: 'from_date',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Recurring Start',
  },
  {
    id: 'to_date',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Recurring End ',
  },
  {
    id: 'days_of_week',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Day of Week',
  },
  {
    id: 'start_recurring_period',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Session',
  },
  {
    id: 'address',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Address',
  },
  {
    id: 'selected_sitter_only',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Selected Sitter Only',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Status',
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Created Date',
  },
];
