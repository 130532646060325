import { HeadCell, REQUESTS_TYPE } from 'models';

export const headCells: readonly HeadCell<REQUESTS_TYPE>[] = [
  {
    id: 'number',
    numeric: false,
    disablePadding: true,
    isSort: true,
    label: 'Request No.',
  },
  {
    id: 'Parent',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Parent Name',
  },
  {
    id: 'from_date',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Start Datetime',
  },
  {
    id: 'to_date',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'End Datetime',
  },
  {
    id: 'address',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Short Address',
  },
  {
    id: 'selected_sitter_only',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Selected Sitter Only',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Status',
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Created Date',
  },
];

export const OPTIONS_DATA = [
  {
    label: 'Bulk Action',
    value: 'bulkAction',
  },
  {
    label: 'Option 2',
    value: 'option2',
  },
  {
    label: 'Option 3',
    value: 'option3',
  },
];
