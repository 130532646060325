import { IIdleTimerProps, useIdleTimer } from 'react-idle-timer';
import { useAppSelector } from 'store/hook';

interface UseIdleTimeoutProps extends IIdleTimerProps {
  idleTime?: number; // minute
}

const useIdleTimeout = ({ idleTime = 30, ...rest }: UseIdleTimeoutProps) => {
  const { isLoggedIn } = useAppSelector((state) => state.authenticationReducer);
  const handleIdle = () => {
    // Logout
    localStorage.clear();
    window.location.reload();
  };

  const idleTimer = useIdleTimer({
    timeout: 1000 * 60 * idleTime,
    onIdle: handleIdle,
    debounce: 500,
    disabled: !isLoggedIn,
    ...rest,
  });
  return {
    idleTimer,
  };
};
export default useIdleTimeout;
