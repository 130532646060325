import { takeLatest, call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { DELETE_CHAT_REQUEST } from 'store/reducers/chat/actionTypes';
import { deleteChatApi } from 'services';

function* deleteChatRequest(
  action: PayloadAction<{
    id: string;
    onSuccess?: () => void;
  }>
) {
  try {
    yield call(deleteChatApi, action.payload.id);
    if (action.payload.onSuccess) action.payload.onSuccess();
  } catch (error: unknown) {}
}

function* chat() {
  yield takeLatest(DELETE_CHAT_REQUEST, deleteChatRequest);
}

export default chat;
