import { HeadCell, TRANSACTION_TYPE } from 'models';

export const headCells: readonly HeadCell<TRANSACTION_TYPE>[] = [
  {
    id: 'number',
    numeric: false,
    disablePadding: true,
    isSort: true,
    label: 'TXN No.',
  },
  {
    id: 'job_number',
    numeric: false,
    disablePadding: true,
    isSort: true,
    label: 'Job No.',
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'TXN Type',
  },
  {
    id: 'user_id' as keyof TRANSACTION_TYPE,
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'User',
  },
  {
    id: 'email' as keyof TRANSACTION_TYPE,
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Email',
  },
  {
    id: 'doc_ref_no',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Doc Ref No.',
  },
  {
    id: 'include_in_payout',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Include In Payout',
  },
  {
    id: 'total_amount',
    numeric: true,
    disablePadding: false,
    isSort: true,
    label: 'Total Amount',
  },
  {
    id: 'timestamp',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'TXN Date',
  },
];
