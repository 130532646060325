import {
  Paper,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  useTheme,
  Box,
  Button,
} from '@mui/material';
import { DeleteDialog, HeadTable, TableCaption } from 'components';
import React, { useState, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hook';
import usePagination from '@mui/material/usePagination/usePagination';
import {
  HeadCell,
  GET_CHILDREN_PARENT_DETAIL_TYPE_PARAMS_REQUEST,
  ECLOSE_POPUP,
  PAYMENT_DETAIL_TYPE,
  WALLET_TXN_FOR_CASH_OUT,
  DELETE_DIALOG_TYPE,
} from 'models';
import { setParentChildrenListParams } from 'store/reducers/accounts';
import { getSortItem, printPrice } from 'helpers';
import { ButtonDelete, ButtonDetail } from 'components/common/table';
import { useDateTime } from 'hooks';
import { PopupDetailTransaction } from 'components/Popups/Transaction';
import PopupAddTXN from 'components/Popups/Transaction/PopupAddTXN';

const headCells: readonly HeadCell<WALLET_TXN_FOR_CASH_OUT>[] = [
  {
    id: 'number',
    numeric: false,
    disablePadding: true,
    isSort: true,
    label: 'TXN No.',
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'TXN Type',
  },
  {
    id: 'User',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'User',
  },
  {
    id: 'doc_ref_no',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Doc Ref No.',
  },
  {
    id: 'total_amount',
    numeric: true,
    disablePadding: false,
    isSort: true,
    label: 'Total Amount',
  },
  {
    id: 'timestamp',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'TXN Date',
  },
];

interface Props {
  payouts_detail: PAYMENT_DETAIL_TYPE | null;
  onSuccess: any;
}
const RelatedTXN = ({ payouts_detail, onSuccess }: Props) => {
  const { children } = useAppSelector(
    (state) => state.accountReducer.parents.parent_detail_tab
  );

  const { params } = children;

  const dispatch = useAppDispatch();
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [openCreatePopup, setOpenCreatePopup] = useState(false);
  // const [openEditPopup, setOpenEditPopup] = useState(false);
  const [itemAction, setItemAction] = useState({});
  const [itemTransaction, setItemTransaction] =
    useState<WALLET_TXN_FOR_CASH_OUT>();
  const [openDetailPopup, setOpenDetailPopup] = useState(false);

  const theme = useTheme();
  const { formatDateTime } = useDateTime();

  const setOpenDetail = (event: any, data: WALLET_TXN_FOR_CASH_OUT) => {
    event.stopPropagation();

    setItemTransaction(data);
    setOpenDetailPopup(true);
  };

  const onSetListParams = (
    params: GET_CHILDREN_PARENT_DETAIL_TYPE_PARAMS_REQUEST
  ) => {
    dispatch(setParentChildrenListParams(params));
  };

  const handleChangeRowsPerPage = (event: SelectChangeEvent) => {
    const limit = parseInt(event.target.value, 10);
    onSetListParams({ ...params, page: 1, limit });
  };

  const { items } = usePagination({
    count: children.metadata.total_pages,
    page: children.metadata.page,
    onChange(_event, page) {
      onSetListParams({ ...params, page: page });
    },
  });

  const sortItem = useMemo(() => {
    return getSortItem(params?.sort);
  }, [params]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = children?.records?.map((n: any) => n?.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  // const handleClick = (_: any, name: string) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected: readonly string[] = [];
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }

  //   setSelected(newSelected);
  // };

  const handleRequestSort = (
    _: any,
    property: keyof WALLET_TXN_FOR_CASH_OUT
  ) => {
    setSelected([]);
    const isAsc = sortItem?.orderBy === property && sortItem?.order === 'asc';
    onSetListParams({
      ...params,
      sort: `${property}-${isAsc ? 'desc' : 'asc'}`,
    });
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const onClosePopup = (type: ECLOSE_POPUP) => {
    switch (type) {
      case ECLOSE_POPUP.DETAIL:
        setOpenDetailPopup(false);
        break;
      case ECLOSE_POPUP.DELETE:
        setOpenDeletePopup(false);
        setItemAction({});
        break;
      case ECLOSE_POPUP.ADD:
        setOpenCreatePopup(false);
        setItemAction({});
        break;
    }
  };

  const setOpenDelete = (event: any, id_txn: any) => {
    event.stopPropagation();
    setItemAction({
      wallet_txn_id: id_txn,
      cash_out_txn_id: payouts_detail?.record.id,
    });
    setOpenDeletePopup(true);
  };

  const setOpenCreate = () => {
    setOpenCreatePopup(true);
  };

  return (
    <>
      <Box display="flex" justifyContent="flex-end" mb={'8px'}>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            height: 30,
            fontWeight: 600,
            fontSize: '13px',
            lineHeight: '100%',
          }}
          onClick={setOpenCreate}
        >
          Add Transaction
        </Button>
      </Box>
      <Stack spacing="8px">
        <TableContainer
          component={Paper}
          elevation={0}
          sx={{
            border: '1px solid #D9D9D9',
            maxHeight: 'calc(100vh - 395px)',
          }}
        >
          <Table
            stickyHeader
            sx={{ minWidth: 650 }}
            aria-labelledby="tableTitle"
          >
            <HeadTable<WALLET_TXN_FOR_CASH_OUT>
              onCheckbox={false}
              numSelected={selected.length}
              order={sortItem?.order}
              orderBy={sortItem?.orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={children?.records.length}
              headCells={headCells}
            />
            <TableBody
              sx={{
                height: 'auto !important',
              }}
            >
              {payouts_detail?.wallet_txn_for_cash_out &&
                payouts_detail.wallet_txn_for_cash_out.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      {/* <TableCell
                        padding="checkbox"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                        }}
                      >
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          onChange={(e) => handleClick(e, row.id)}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell> */}
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                          fontSize: '14px',
                          fontWeight: 400,
                        }}
                      >
                        {row.number}
                      </TableCell>

                      <TableCell
                        align="left"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                          fontSize: '14px',
                          fontWeight: 400,
                        }}
                      >
                        {row.type}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                          fontSize: '14px',
                          fontWeight: 400,
                        }}
                      >
                        {row.User?.first_name} {row.User?.last_name}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                          fontSize: '14px',
                          fontWeight: 400,
                        }}
                      >
                        {row.doc_ref_no}
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                          fontSize: '14px',
                          fontWeight: 400,
                          color:
                            row.total_amount > 0
                              ? theme.palette.green.light
                              : '#000',
                        }}
                      >
                        {printPrice(row.total_amount)}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                          fontSize: '14px',
                          fontWeight: 400,
                        }}
                      >
                        {formatDateTime(row.timestamp)}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                        }}
                      >
                        <Stack direction="row" spacing="10px">
                          <ButtonDetail
                            onClick={(e) => setOpenDetail(e, row)}
                          />
                          <ButtonDelete
                            onClick={(e) => setOpenDelete(e, row.id)}
                          />
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TableCaption
          onlyOnePage={true}
          rowsPerPage={params?.limit ?? 0}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          metadata={children.metadata}
          numberItem={payouts_detail?.wallet_txn_for_cash_out.length}
          items={items}
        />
      </Stack>
      {openDeletePopup && (
        <DeleteDialog
          name=""
          open={openDeletePopup}
          onClose={() => onClosePopup(ECLOSE_POPUP.DELETE)}
          subject=""
          onSuccess={onSuccess}
          type={DELETE_DIALOG_TYPE.DELETE_TXN_FROM_PAYOUT}
          data={itemAction}
        />
      )}
      {openDetailPopup && (
        <PopupDetailTransaction
          open
          subject={itemTransaction?.id}
          handleClosePopup={() => onClosePopup(ECLOSE_POPUP.DETAIL)}
        />
      )}
      {openCreatePopup && (
        <PopupAddTXN
          open={openCreatePopup}
          handleClosePopup={() => onClosePopup(ECLOSE_POPUP.ADD)}
          // subject={itemAction}
          data={payouts_detail?.record.id}
          onSuccess={onSuccess}
        />
      )}
    </>
  );
};

export default RelatedTXN;
