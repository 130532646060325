import { AxiosResponse } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  deleteSitterApi,
  getSitterDetailApi,
  getSittersListApi,
} from 'services';
// import { PayloadAction } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';
import { TOAST } from 'config';
import { NotifyService, getMetadata } from 'helpers';
import {
  CHAT_TYPE,
  DataListResponse,
  GET_CHATS_PARAMS_REQUEST,
  GET_JOBS_PARAMS_REQUEST,
  GET_PAYMENT_PARAMS_REQUEST,
  GET_SITTERS_PARAMS_REQUEST,
  GET_TRANSACTIONS_PARAMS_REQUEST,
  JOBS_BODY_RESULT,
  METADATA_TYPE,
  PAYMENT_BODY_RESULT,
  REVIEW_TYPE,
  SITTER_BODY_RESULT,
  SITTER_DETAIL_TYPE,
  TRANSACTIONS_BODY_RESULT,
} from 'models';
import { toast } from 'react-toastify';
import {
  getChatListApi,
  getPaymentListApi,
  getTransactionsListApi,
} from 'services';
import { getJobsListApi } from 'services/jobs';
import { getReviewsApi } from 'services/reviews';
import {
  setSitterChatsSuccess,
  setSitterDetailSuccess,
  setSitterJobsSuccess,
  setSitterListSuccess,
  setSitterOptionListSuccess,
  setSitterPayoutHistorySuccess,
  setSitterPendingPayoutSuccess,
  setSitterReviewsSuccess,
  setSitterTransactionsSuccess,
} from 'store/reducers/accounts';
import {
  DELETE_SITTER_REQUEST,
  GET_SITTERS_LIST_REQUEST,
  GET_SITTERS_OPTION_LIST_REQUEST,
  GET_SITTER_CHATS_REQUEST,
  GET_SITTER_DETAIL_REQUEST,
  GET_SITTER_JOBS_REQUEST,
  GET_SITTER_PAYOUT_HISTORY_REQUEST,
  GET_SITTER_PENDING_PAYOUT_REQUEST,
  GET_SITTER_REVIEWS_REQUEST,
  GET_SITTER_TRANSACTIONS_REQUEST,
} from 'store/reducers/accounts/actionTypes';
import { setGlobalLoading, setLoadingPopup } from 'store/reducers/global';

function* setSitterListRequest(
  action: PayloadAction<{
    params?: GET_SITTERS_PARAMS_REQUEST;
  }>
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<{
      data: SITTER_BODY_RESULT;
    }> = yield call(getSittersListApi, action.payload.params);
    yield put(setSitterListSuccess(response.data.data));
    yield put(setGlobalLoading(false));
  } catch (error: any) {
    toast.error(error, TOAST.error);
    yield put(setGlobalLoading(false));
  }
}

function* getSitterDetailRequest(
  action: PayloadAction<{
    id: string;
    onSuccess?: () => void;
  }>
) {
  try {
    yield put(setLoadingPopup(true));
    const response: AxiosResponse<{
      data: SITTER_DETAIL_TYPE;
    }> = yield call(getSitterDetailApi, action.payload.id);
    yield put(setSitterDetailSuccess(response.data.data));
    if (action.payload.onSuccess) action.payload.onSuccess();
    yield put(setLoadingPopup(false));
  } catch (error: unknown) {
    NotifyService.error(error);
  }
}

function* deleteSitterRequest(
  action: PayloadAction<{
    id: string;
    onSuccess?: () => void;
  }>
) {
  try {
    yield call(deleteSitterApi, action.payload.id);
    if (action.payload.onSuccess) action.payload.onSuccess();
  } catch (error: unknown) {}
}

function* getSitterJobsRequest(
  action: PayloadAction<{
    params?: GET_JOBS_PARAMS_REQUEST;
  }>
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<JOBS_BODY_RESULT> = yield call(
      getJobsListApi,
      action.payload.params
    );
    yield put(
      setSitterJobsSuccess({
        records: response.data.records,
        metadata: response.data.metadata,
      })
    );
    yield put(setGlobalLoading(false));
  } catch (error: unknown) {}
}

function* getSitterTransactionsRequest(
  action: PayloadAction<{
    params?: GET_TRANSACTIONS_PARAMS_REQUEST;
  }>
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<{
      data: TRANSACTIONS_BODY_RESULT;
    }> = yield call(getTransactionsListApi, action.payload.params);
    yield put(setSitterTransactionsSuccess(response.data.data));
    yield put(setGlobalLoading(false));
  } catch (error: unknown) {
    NotifyService.error(error);
    yield put(setGlobalLoading(false));
  }
}

function* getSitterPendingPayoutRequest(
  action: PayloadAction<{
    params?: GET_PAYMENT_PARAMS_REQUEST;
  }>
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<{
      data: PAYMENT_BODY_RESULT;
    }> = yield call(getPaymentListApi, action.payload.params);
    yield put(
      setSitterPendingPayoutSuccess({
        records: response.data.data.records,
        metadata: response.data.data.metadata,
      })
    );
    yield put(setGlobalLoading(false));
  } catch (error: unknown) {
    NotifyService.error(error);
    yield put(setGlobalLoading(false));
  }
}

function* getSitterPayoutHistoryRequest(
  action: PayloadAction<{
    params?: GET_PAYMENT_PARAMS_REQUEST;
  }>
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<{
      data: PAYMENT_BODY_RESULT;
    }> = yield call(getPaymentListApi, action.payload.params);
    yield put(
      setSitterPayoutHistorySuccess({
        records: response.data.data.records,
        metadata: response.data.data.metadata,
      })
    );
    yield put(setGlobalLoading(false));
  } catch (error: unknown) {
    NotifyService.error(error);
    yield put(setGlobalLoading(false));
  }
}

function* getSitterChatsRequest(
  action: PayloadAction<{
    params?: GET_CHATS_PARAMS_REQUEST;
  }>
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<{
      data: DataListResponse<CHAT_TYPE>;
    }> = yield call(getChatListApi, action.payload.params);
    yield put(
      setSitterChatsSuccess({
        records: response.data.data.rows,
        metadata: getMetadata(
          response.data.data,
          action.payload.params?.page,
          action.payload.params?.limit
        ),
      })
    );
    yield put(setGlobalLoading(false));
  } catch (error: unknown) {}
}

function* getSitterReviewsRequest(
  action: PayloadAction<{
    params?: GET_CHATS_PARAMS_REQUEST;
  }>
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<{
      data: {
        rows: REVIEW_TYPE[];
        metadata: METADATA_TYPE;
      };
    }> = yield call(getReviewsApi, action.payload.params);
    yield put(
      setSitterReviewsSuccess({
        records: response.data.data.rows,
        metadata: response.data.data.metadata,
      })
    );
    yield put(setGlobalLoading(false));
  } catch (error: unknown) {}
}

function* setSitterOptionListRequest(
  action: PayloadAction<{
    params?: GET_SITTERS_PARAMS_REQUEST;
  }>
) {
  try {
    const response: AxiosResponse<{
      data: SITTER_BODY_RESULT;
    }> = yield call(getSittersListApi, action.payload.params);
    yield put(setSitterOptionListSuccess(response.data.data));
  } catch (error: unknown) {}
}

function* sitters() {
  yield takeLatest(GET_SITTERS_LIST_REQUEST, setSitterListRequest);
  yield takeLatest(GET_SITTERS_OPTION_LIST_REQUEST, setSitterOptionListRequest);
  yield takeLatest(DELETE_SITTER_REQUEST, deleteSitterRequest);
  yield takeLatest(GET_SITTER_DETAIL_REQUEST, getSitterDetailRequest);
  yield takeLatest(GET_SITTER_JOBS_REQUEST, getSitterJobsRequest);
  yield takeLatest(
    GET_SITTER_TRANSACTIONS_REQUEST,
    getSitterTransactionsRequest
  );
  yield takeLatest(
    GET_SITTER_PENDING_PAYOUT_REQUEST,
    getSitterPendingPayoutRequest
  );
  yield takeLatest(
    GET_SITTER_PAYOUT_HISTORY_REQUEST,
    getSitterPayoutHistoryRequest
  );
  yield takeLatest(GET_SITTER_CHATS_REQUEST, getSitterChatsRequest);
  yield takeLatest(GET_SITTER_REVIEWS_REQUEST, getSitterReviewsRequest);
}

export default sitters;
