import { yupResolver } from '@hookform/resolvers/yup';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { CloseCircle } from 'asset';
import { LoadingPopup } from 'components/common';
import PhoneNumberField from 'components/common/PhoneNumberField';
import { Inputs, Select, SelectInput } from 'components/form-control';
import { GetOptionsResponse } from 'components/form-control/Select';
import { INIT_COUNTRY_CODE, TOAST, patternPassword } from 'config';
import yup from 'config/yup.custom';
import { NotifyService } from 'helpers';
import {
  ADDRESS_DETAIL_PARENT_DETAIL_TYPE,
  ADDRESS_PARENT_DETAIL_TYPE,
  OptionItem,
  PHONE_TYPE,
} from 'models';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  createParentApi,
  editParentDetailAddressApi,
  editParentDetailApi,
  getParentAddressApi,
} from 'services';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setParentDetailSuccess } from 'store/reducers/accounts';
import { GET_PARENT_DETAIL_REQUEST } from 'store/reducers/accounts/actionTypes';
import PopupEditAddress from '../PopupEditAddress';

interface Props {
  open: boolean;
  handleClosePopup?: () => void;
  onSuccess?: any;
  subject?: any;
  cleanDetail?: boolean;
}

interface IFormInputs {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: PHONE_TYPE;
  status: string;
  password: string;
  address?: OptionItem | null;
  profile_picture: string | null;
}

const OPTIONS_DATA = [
  {
    name: 'Active',
    value: 'active',
  },
  {
    name: 'Inactive',
    value: 'inactive',
  },
];

const defaultValues: IFormInputs = {
  first_name: '',
  last_name: '',
  email: '',
  phone_number: {
    country_code: INIT_COUNTRY_CODE.code,
    phone_number: '',
  },
  status: '',
  password: '',
  address: undefined,
  profile_picture: null,
};

const PopupCreateOrEditParent = (props: Props) => {
  const { open, handleClosePopup, subject, onSuccess, cleanDetail } = props;
  const parent_detail = useAppSelector(
    (state) => state.accountReducer.parents.parent_detail
  );
  const { loadingPopup } = useAppSelector((state) => state.globalReducer);
  const dispatch = useAppDispatch();
  const [openEditAddress, setOpenEditAddress] = useState(false);
  const [openAddAddress, setOpenAddAddress] = useState(false);
  const [urlPicture, setUrlPicture] = useState('');
  const [loading, setLoading] = useState<boolean>(false);

  const schema = useMemo(() => {
    return yup.object().shape({
      first_name: yup.string().required('First name is required'),
      last_name: yup.string().required('Last name is required'),
      email: yup.string().email('Invalid email').required('Email is required'),
      phone_number: yup
        .object({
          country_code: yup.string().required('Country code is required'),
          phone_number: yup.string().required('Phone number is required'),
        })
        .required('Phone number is required'),
      status: yup.string().notRequired(),
      password: subject
        ? yup
            .string()
            .matches(patternPassword, {
              message: 'Invalid password',
              excludeEmptyString: true,
            })
            .notRequired()
        : yup
            .string()
            .matches(patternPassword, {
              message: 'Invalid password',
              excludeEmptyString: true,
            })
            .required('Password is required'),
    });
  }, [subject]);

  const form = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  const { reset, handleSubmit, watch } = form;

  useEffect(() => {
    if (open && subject) {
      dispatch({
        type: GET_PARENT_DETAIL_REQUEST,
        payload: {
          id: subject,
        },
      });
    }
    // eslint-disable-next-line
  }, [open, subject]);

  useEffect(() => {
    if (open && subject && parent_detail) {
      const addressValue = parent_detail?.addresses.find((a) => a.is_default);
      const { first_name, last_name, email, status, profile_picture } =
        parent_detail;
      const {
        country_code = defaultValues.phone_number?.country_code,
        phone_number: phoneNumberValue = '',
      } = parent_detail;
      const address = !!addressValue
        ? {
            id: addressValue.id,
            label: getAddressLabel({
              block_number: addressValue.block_number,
              name: addressValue.name,
              street: addressValue.street,
              unit_number: addressValue.unit_number,
            }),
          }
        : undefined;

      reset({
        first_name,
        last_name,
        email,
        phone_number: {
          country_code,
          phone_number: phoneNumberValue,
        },
        status,
        password: '',
        profile_picture,
        address,
      });
      setUrlPicture(profile_picture);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, subject, parent_detail]);

  const onSubmit = async (data: IFormInputs) => {
    setLoading(true);
    const _data: any = {
      ...data,
      country_code: data.phone_number?.country_code,
      phone_number: data.phone_number?.phone_number,
      profile_picture: null,
    };
    if (subject) {
      if (!!data.address)
        await editParentDetailAddressApi(subject, data.address.id, {
          is_default: true,
        })
          .then((r) => {
            if (r.data.message === 'Success') {
              toast.success('Success');
              onSuccess();
            }
          })
          .catch((e) => {
            toast.error(e, TOAST.error);
          })
          .finally(() => {});
      if (!data.password) delete _data.password;
      delete _data.address;
      await editParentDetailApi(subject, _data)
        .then((r) => {
          if (r.data.message === 'Success') {
            toast.success('Success');
            onSuccess();
            _handleClosePopup();
          }
        })
        .catch((e) => {
          toast.error(e, TOAST.error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      await createParentApi(_data)
        .then((r) => {
          if (r.data.message === 'Success') {
            toast.success('Success');
            onSuccess();
            _handleClosePopup();
          }
        })
        .catch((e) => {
          toast.error(e, TOAST.error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const _handleClosePopup = () => {
    handleClosePopup && handleClosePopup();
    reset(defaultValues);
    if (cleanDetail && parent_detail) {
      dispatch(setParentDetailSuccess(null));
    }
  };

  const onSuccessAddOrEditAddress = (
    data?: ADDRESS_DETAIL_PARENT_DETAIL_TYPE
  ) => {
    const address = form.getValues('address');
    if (address && address.id === data?.id) {
      form.setValue('address', { ...data, label: getAddressLabel(data) });
    }
  };

  const getAddressLabel = (data: {
    block_number: string;
    name: string;
    street: string;
    unit_number: string;
  }) => {
    return `${data?.block_number} ${data.name} ${data.street} ${data?.unit_number}`;
  };

  const getOptions =
    (key: keyof IFormInputs) =>
    async (page: number = 1, limit: number = 20, keyword?: string) => {
      let data: GetOptionsResponse | null = null;

      switch (key) {
        case 'address':
          if (subject) {
            data = await getParentAddressApi(subject)
              .then(({ data }) => {
                return {
                  metadata: data.metadata,
                  options: data.records
                    .map((it: ADDRESS_PARENT_DETAIL_TYPE) => ({
                      id: it.id,
                      name: it.address_name,
                      street: it.street_name,
                      block_number: it.block_no,
                      unit_number: it.unit_no,
                      country: it.country,
                      postal_code: it.postal_code,
                    }))
                    .map((it: ADDRESS_DETAIL_PARENT_DETAIL_TYPE) => ({
                      ...it,
                      label: getAddressLabel(it),
                    })),
                };
              })
              .catch((e) => {
                NotifyService.error(e);
                return null;
              });
          }
          break;
      }

      return data;
    };

  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiPaper-root': {
          width: 523,
          height: 'auto',
          borderRadius: 0,
        },
      }}
    >
      <DialogTitle
        sx={{
          padding: '20px 25px',
        }}
      >
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '100%',
            }}
          >
            {subject ? 'Edit' : 'Create'} Parent
          </Typography>
          <IconButton
            onClick={_handleClosePopup}
            sx={{
              padding: 0,
            }}
          >
            <CloseCircle />
          </IconButton>
        </Grid>
      </DialogTitle>
      {(loadingPopup || loading) && <LoadingPopup color="primary" />}
      <DialogContent
        sx={{
          p: '30px 24px 50px 26px',
        }}
        dividers
      >
        <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
          <Stack height="100%" justifyContent="space-between">
            <Stack spacing="16px">
              {subject && (
                <Stack alignItems="center">
                  <Avatar
                    src={urlPicture}
                    sx={{
                      width: '117px',
                      height: '117px',
                      marginRight: '6px',
                    }}
                  />
                  <Button
                    sx={{
                      mt: '10px',
                      maxHeight: '15px',
                      '&:hover': {
                        bgcolor: 'transparent',
                      },
                    }}
                    variant="text"
                    color="inherit"
                    onClick={() => {
                      form.setValue('profile_picture', null);
                      setUrlPicture('');
                    }}
                  >
                    Remove Picture
                  </Button>
                </Stack>
              )}
              <Stack direction="row" spacing="14px">
                <Stack sx={{ width: '50%' }}>
                  <Inputs title="First Name" form={form} name="first_name" />
                </Stack>
                <Stack sx={{ width: '50%' }}>
                  <Inputs title="Last Name" form={form} name="last_name" />
                </Stack>
              </Stack>
              <Grid>
                <Inputs title="Email" form={form} name="email" />
              </Grid>
              <Grid>
                <PhoneNumberField
                  title="Phone Number"
                  name="phone_number"
                  form={form}
                />
              </Grid>
              {!subject && (
                <Grid>
                  <SelectInput
                    title="Status"
                    form={form}
                    options={OPTIONS_DATA}
                    name="status"
                  />
                </Grid>
              )}
              <Grid>
                <Inputs
                  showEyes
                  title={subject ? 'Change password' : 'Password'}
                  type="password"
                  name="password"
                  placeholder="Password"
                  form={form}
                />
              </Grid>
              {subject && (
                <>
                  <Grid>
                    <Select
                      title="Address"
                      name="address"
                      form={form}
                      isHasMore
                      filterFunc
                      refreshWhenOpen
                      getOptions={getOptions('address')}
                    />
                  </Grid>
                  <Box display="flex" sx={{ mt: '9px !important' }}>
                    <Button
                      sx={{
                        padding: '0px',
                        color: '#226fe3',
                        fontSize: '14px',
                        fontWeight: 400,
                        marginRight: '15px',
                      }}
                      disabled={!watch('address')}
                      onClick={() => setOpenEditAddress(true)}
                    >
                      Edit Address
                    </Button>
                    <Button
                      sx={{
                        padding: '0px',
                        color: '#226fe3',
                        fontSize: '14px',
                        fontWeight: 400,
                      }}
                      onClick={() => setOpenAddAddress(true)}
                    >
                      Add New Address
                    </Button>
                  </Box>
                </>
              )}
            </Stack>

            <Stack
              direction="row"
              justifyContent="space-between"
              marginTop="25px"
              spacing="8px"
            >
              <Button
                variant="outlined"
                color="secondary"
                sx={{
                  width: '233.5px',
                  height: '30px',
                }}
                onClick={_handleClosePopup}
              >
                Cancel
              </Button>
              <Button
                color="secondary"
                variant="contained"
                sx={{
                  width: '233.5px',
                  height: '30px',
                }}
                type="submit"
              >
                {subject ? 'Update' : 'Create'}
              </Button>
            </Stack>
          </Stack>
        </form>
      </DialogContent>

      {subject && (openEditAddress || openAddAddress) && (
        <PopupEditAddress
          open
          isEdit={openEditAddress}
          subject={subject}
          idAddress={form.watch('address')?.id}
          handleClosePopup={() => {
            setOpenAddAddress(false);
            setOpenEditAddress(false);
          }}
          onSuccess={onSuccessAddOrEditAddress}
        />
      )}
    </Dialog>
  );
};

export default PopupCreateOrEditParent;
