import { HeadCell, COUNTRIES_TYPE } from 'models';

export const headCells: readonly HeadCell<COUNTRIES_TYPE>[] = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    isSort: true,
    label: 'ID',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Country',
  },
  {
    id: 'phone_code',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Country code',
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Created Date',
  },
];
