import { HeadCell, TRANSACTION_TYPE } from 'models';

export const headCells: readonly HeadCell<TRANSACTION_TYPE>[] = [
  {
    id: 'number',
    numeric: false,
    disablePadding: true,
    isSort: true,
    label: 'TXN No.',
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'TXN Type',
  },
  {
    id: 'doc_ref_no',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Doc Ref No.',
  },
  {
    id: 'total_amount',
    numeric: true,
    disablePadding: false,
    isSort: true,
    label: 'Total Amount',
  },
  {
    id: 'timestamp',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'TXN Date',
  },
  {
    id: 'payout_date',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Payout Date',
  },
];
