import {
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import {
  DeleteDialog,
  HeadTable,
  PopupCreateOrEditActivityBooking,
} from 'components';
import {
  ButtonDelete,
  ButtonEdit,
  TableCellNoData,
} from 'components/common/table';
import { printPrice } from 'helpers';
import { useDateTime } from 'hooks';
import {
  BOOKING_ACTIVITY_LOG,
  DELETE_DIALOG_TYPE,
  POPUP_ACTION,
  TYPE_POPUP,
  bookingActivityLogTypeName,
} from 'models';
import { memo, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { getListActivityLogInBookingAction } from 'store/reducers/nanny-bookings/actionTypes';
import { headCells } from './data';

interface ActivityLogInBookingProps {
  bookingId: string;
}

export const ActivityLogInBooking = memo(
  ({ bookingId }: ActivityLogInBookingProps) => {
    const { formatDateTime } = useDateTime();
    const dispatch = useAppDispatch();
    const [popupAction, setPopupAction] =
      useState<POPUP_ACTION<BOOKING_ACTIVITY_LOG>>();

    const { booking_detail } = useAppSelector(
      (state) => state.nannyBookingsReducer
    );
    const { activityLog } = useAppSelector(
      (state) => state.nannyBookingsReducer.booking_detail_tab
    );

    useEffect(() => {
      dispatch(getListActivityLogInBookingAction({ data: { id: bookingId } }));
    }, [bookingId, dispatch]);

    const onShowPopupAction = (
      event: any,
      type: TYPE_POPUP,
      data?: BOOKING_ACTIVITY_LOG
    ) => {
      event?.stopPropagation();
      setPopupAction({ type: type, data });
    };

    const onClosePopupAction = () => {
      setPopupAction(undefined);
    };

    const onReload = () => {
      dispatch(getListActivityLogInBookingAction({ data: { id: bookingId } }));
    };

    return (
      <>
        <Stack
          direction="row"
          spacing="8px"
          justifyContent="flex-end"
          mb="16px"
        >
          <Button
            variant="contained"
            color="secondary"
            sx={{
              height: 30,
              fontWeight: 600,
              fontSize: '13px',
              lineHeight: '100%',
            }}
            onClick={() => onShowPopupAction(null, TYPE_POPUP.ADD)}
          >
            Add Activity
          </Button>
        </Stack>
        <Stack spacing="8px">
          <TableContainer
            component={Paper}
            elevation={0}
            sx={{
              border: '1px solid #D9D9D9',
              maxHeight: 'calc(100vh - 395px)',
            }}
          >
            <Table
              stickyHeader
              sx={{ minWidth: 650 }}
              aria-labelledby="tableTitle"
            >
              <HeadTable<BOOKING_ACTIVITY_LOG>
                rowCount={activityLog?.data.length}
                headCells={headCells}
                onCheckbox={false}
              />
              <TableBody
                sx={{
                  height: 'auto !important',
                }}
              >
                {activityLog?.data?.length ? (
                  activityLog.data.map((row, index) => {
                    const labelId = `enhanced-activity-tab-cb-${index}`;
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          sx={{
                            borderRight: '1px solid #DEE2E6',
                            padding: '18px 12px 17px',
                            fontSize: '14px',
                            fontWeight: 400,
                          }}
                        >
                          {bookingActivityLogTypeName[row.type]}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            borderRight: '1px solid #DEE2E6',
                            padding: '18px 12px 17px',
                            fontSize: '14px',
                            fontWeight: 400,
                          }}
                        >
                          {printPrice(row.amount)}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            borderRight: '1px solid #DEE2E6',
                            padding: '18px 12px 17px',
                            fontSize: '14px',
                            fontWeight: 400,
                            color: row.parent
                              ? 'secondary.main'
                              : 'grey1.light',
                          }}
                        >
                          {row.parent ? 'Yes' : 'No'}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            borderRight: '1px solid #DEE2E6',
                            padding: '18px 12px 17px',
                            fontSize: '14px',
                            fontWeight: 400,
                            color: row.nannyList?.length
                              ? 'secondary.main'
                              : 'grey1.light',
                          }}
                        >
                          {row.nannyList?.length ? 'Yes' : 'No'}
                        </TableCell>
                        {/* <TableCell
                          align="left"
                          sx={{
                            borderRight: '1px solid #DEE2E6',
                            padding: '18px 12px 17px',
                            fontSize: '14px',
                            fontWeight: 400,
                            minWidth: '110px',
                          }}
                        >
                          <Grid
                            container
                            alignItems="center"
                            textTransform="capitalize"
                          >
                            <Grid
                              sx={{
                                width: '8px',
                                height: '8px',
                                borderRadius: '50%',
                                marginRight: '5px',
                                backgroundColor: '#25B995',
                              }}
                            />
                            <Typography>Active</Typography>
                          </Grid>
                        </TableCell> */}
                        <TableCell
                          align="left"
                          sx={{
                            borderRight: '1px solid #DEE2E6',
                            padding: '18px 12px 17px',
                            fontSize: '14px',
                            fontWeight: 400,
                          }}
                        >
                          {formatDateTime(row.updatedAt, 'DD MMM YYYY HH:mm')}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            borderRight: '1px solid #DEE2E6',
                            padding: '18px 12px 17px',
                          }}
                        >
                          <Stack direction="row" spacing="10px">
                            <ButtonEdit
                              onClick={(e) =>
                                onShowPopupAction(e, TYPE_POPUP.EDIT, row)
                              }
                            />
                            <ButtonDelete
                              onClick={(e) =>
                                onShowPopupAction(e, TYPE_POPUP.DELETE, row)
                              }
                            />
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCellNoData colSpan={headCells.length + 1} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
        {popupAction?.type === TYPE_POPUP.DELETE && (
          <DeleteDialog
            open
            onClose={onClosePopupAction}
            subject={popupAction?.data?.id}
            onSuccess={onReload}
            type={DELETE_DIALOG_TYPE.ACTIVITY_LOG_IN_BOOKING}
            name="activity log"
          />
        )}
        {[TYPE_POPUP.ADD, TYPE_POPUP.EDIT].includes(
          popupAction?.type as TYPE_POPUP
        ) &&
          !!booking_detail && (
            <PopupCreateOrEditActivityBooking
              subject={popupAction?.data}
              booking={booking_detail}
              onClose={onClosePopupAction}
              onSuccess={onReload}
            />
          )}
      </>
    );
  }
);

export default ActivityLogInBooking;
