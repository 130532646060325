import { GET_PROMOS_LIST_PARAMS } from 'models';

export const GET_PROMOS_LIST_REQUEST: string = 'GET_PROMOS_LIST_REQUEST';
export const DELETE_PROMOS_REQUEST: string = 'DELETE_PROMOS_REQUEST';

export const getPromosListRequest = (
  params?: GET_PROMOS_LIST_PARAMS | null
) => {
  return {
    type: GET_PROMOS_LIST_REQUEST,
    payload: { params },
  };
};

export const deletePromosRequest = (id: string, onSuccess?: () => void) => {
  return {
    type: DELETE_PROMOS_REQUEST,
    payload: { id, onSuccess },
  };
};
