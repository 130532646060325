import { METADATA_TYPE } from "./accounts";
import { OptionItem } from "./global";


export interface REPORT_TYPE {
  id: string,
  actor_id: string,
  actor_role: string,
  target_id: string,
  target_role: string,
  reason_id: string,
  description: string,
  status: string,
  remark: string,
  images: string[],
  createdAt: string,
  updatedAt: string,
  updatedBy: string,
  Admin?: {
    full_name: string;
    id: string;
  },
  actor: {
    id: string,
    first_name: string,
    last_name: string,
  },
  target: {
    id: string,
    first_name: string,
    last_name: string,
  },
  ReportReason: {
    id: string,
    reason: string,
  }
}

export interface GET_REPORT_LIST_PARAMS {
  page?: number;
  limit?: number;
  offset?: number;
  sort?: string;
  keyword?: string;
}

export interface REPORT_LIST_STATE {
  records: REPORT_TYPE[];
  metadata: METADATA_TYPE;
  params?: GET_REPORT_LIST_PARAMS | null;
}

export enum REPORT_STATUS {
  NEW = 'new',
  PENDING = 'pending',
  ACTIONED = 'actioned',
}


export const reportStatusColor: { [key: string]: string } = {
  [REPORT_STATUS.NEW]: '#FF3B30',
  [REPORT_STATUS.PENDING]: '#FFCC00',
  [REPORT_STATUS.ACTIONED]: '#25B995',
};

export interface UPDATE_REPORT_STATUS_BODY {
  status: string,
  remark: string
}

export const reportStatuses: OptionItem[] = [
  { id: REPORT_STATUS.NEW, label: 'New' },
  { id: REPORT_STATUS.PENDING, label: 'Pending' },
  { id: REPORT_STATUS.ACTIONED, label: 'Actioned' },
]