import {
  FormHelperText,
  InputBase,
  inputBaseClasses,
  InputLabel,
} from '@mui/material';
import React, { FC, Fragment } from 'react';
import { Controller } from 'react-hook-form';

interface Props {
  title: string;
  type?: string;
  name: string;
  form: any;
  rules?: any;
  autoComplete?: string;
}

const InputText: FC<Props> = ({
  type = 'text',
  title,
  form,
  name,
  rules,
  ...rest
}) => {
  const {
    control,
    formState: { errors },
  } = form;

  return (
    <Fragment>
      <InputLabel
        sx={{
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '32px',
          color: 'white.light',
        }}
      >
        {title}
      </InputLabel>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => (
          <InputBase
            {...field}
            type={type}
            placeholder={title}
            sx={{
              color: 'white.light',
              fontSize: '16px',
              lineHeight: '200%',
              // p: '16px',
              width: '375px',
              height: '64px',
              background: ' rgba(255, 255, 255, 0.2)',
              borderRadius: '4px',
              [`& .${inputBaseClasses.input}`]: {
                padding: '0px 16px',
                height: '100%',
                borderRadius: '4px',
                MozAppearance: 'textfield',
              },
            }}
            {...rest}
          />
        )}
      />

      {!!(errors as any)[name] && (
        <FormHelperText
          error
          sx={{
            fontSize: '16px',
            letterSpacing: '0.045em',
            color: 'white.light',
          }}
        >
          {(errors as any)?.[name]?.message || ''}
        </FormHelperText>
      )}
    </Fragment>
  );
};

export default InputText;
