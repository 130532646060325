import { CERTIFICATE_TYPE, HeadCell } from 'models';

export const headCells: readonly HeadCell<CERTIFICATE_TYPE>[] = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    isSort: false,
    label: 'ID',
  },
  {
    id: 'icon',
    numeric: false,
    disablePadding: true,
    isSort: false,
    label: 'Icon',
  },
  {
    id: 'title',
    numeric: false,
    disablePadding: true,
    isSort: false,
    label: 'Certificate Name',
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: true,
    isSort: false,
    label: 'Created Date',
  },
];
