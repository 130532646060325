import React from 'react';

interface Props {
  fill?: string;
}

const Pencil = ({ fill, ...props }: Props) => {
  return (
    <svg
      width={19}
      height={18}
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.3571 5.43001C17.3576 5.33131 17.3387 5.23346 17.3014 5.14208C17.2641 5.0507 17.2091 4.96759 17.1396 4.89751L13.9596 1.71751C13.8895 1.648 13.8064 1.59301 13.715 1.55568C13.6236 1.51836 13.5258 1.49944 13.4271 1.50001C13.3284 1.49944 13.2305 1.51836 13.1391 1.55568C13.0478 1.59301 12.9646 1.648 12.8946 1.71751L10.7721 3.84001L2.57457 12.0375C2.50506 12.1076 2.45006 12.1907 2.41274 12.2821C2.37542 12.3735 2.3565 12.4713 2.35707 12.57V15.75C2.35707 15.9489 2.43609 16.1397 2.57674 16.2803C2.71739 16.421 2.90816 16.5 3.10707 16.5H6.28707C6.39201 16.5057 6.49699 16.4893 6.59518 16.4518C6.69338 16.4144 6.7826 16.3567 6.85707 16.2825L15.0096 8.08501L17.1396 6.00001C17.208 5.92732 17.2638 5.84366 17.3046 5.75251C17.3118 5.69273 17.3118 5.63229 17.3046 5.57251C17.3081 5.5376 17.3081 5.50242 17.3046 5.46751L17.3571 5.43001ZM5.97957 15H3.85707V12.8775L11.3046 5.43001L13.4271 7.55251L5.97957 15ZM14.4846 6.49501L12.3621 4.37251L13.4271 3.31501L15.5421 5.43001L14.4846 6.49501Z"
        fill={fill || "white"}
      />
    </svg>
  );
};

export default Pencil;
