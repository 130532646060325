import React from 'react';

const Magnifier = () => {
  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.2825 15.2176L13.5 12.4576C14.5801 11.1109 15.1031 9.40152 14.9616 7.68103C14.8201 5.96053 14.0248 4.35964 12.7391 3.20753C11.4535 2.05543 9.77536 1.43968 8.0497 1.4869C6.32403 1.53412 4.68205 2.24072 3.46136 3.46141C2.24068 4.68209 1.53408 6.32408 1.48686 8.04974C1.43964 9.77541 2.05538 11.4536 3.20749 12.7392C4.35959 14.0248 5.96048 14.8201 7.68098 14.9617C9.40148 15.1032 11.1108 14.5801 12.4575 13.5001L15.2175 16.2601C15.2872 16.3303 15.3702 16.3861 15.4616 16.4242C15.553 16.4623 15.651 16.4819 15.75 16.4819C15.849 16.4819 15.947 16.4623 16.0384 16.4242C16.1298 16.3861 16.2128 16.3303 16.2825 16.2601C16.4177 16.1202 16.4932 15.9333 16.4932 15.7388C16.4932 15.5443 16.4177 15.3574 16.2825 15.2176V15.2176ZM8.25 13.5001C7.21165 13.5001 6.19662 13.1921 5.33326 12.6153C4.4699 12.0384 3.797 11.2185 3.39964 10.2591C3.00228 9.29983 2.89831 8.24423 3.10088 7.22583C3.30345 6.20743 3.80347 5.27197 4.53769 4.53774C5.27192 3.80351 6.20738 3.3035 7.22578 3.10093C8.24418 2.89836 9.29978 3.00232 10.2591 3.39968C11.2184 3.79704 12.0383 4.46995 12.6152 5.33331C13.1921 6.19666 13.5 7.2117 13.5 8.25005C13.5 9.64244 12.9469 10.9778 11.9623 11.9624C10.9777 12.9469 9.64239 13.5001 8.25 13.5001V13.5001Z"
        fill="#999999"
      />
    </svg>
  );
};

export default Magnifier;
