import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import * as CONSTANTS from 'config/constants';
import {
  SIGNIN_BODY_REQUEST,
  SIGNIN_BODY_RESPONSE,
  VERIFY_BODY_REQUEST,
  VERIFY_BODY_RESPONSE,
} from 'models';
import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import { loginApi, setToken, setTokenNanny, verify2FAApi } from 'services';
import { setLoggedInSuccess } from 'store/reducers/authentication';
import {
  LOGIN_REQUEST,
  VERIFY_REQUEST,
} from 'store/reducers/authentication/actionTypes';
import { setGlobalLoading } from 'store/reducers/global';

function* loginRequest(
  action: PayloadAction<{
    data: SIGNIN_BODY_REQUEST;
    onSuccess?: () => void;
    onFailed?: (data?: any) => void;
  }>
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<{
      data: SIGNIN_BODY_RESPONSE;
    }> = yield call(loginApi, action.payload.data);
    yield put(setGlobalLoading(false));
    const rs = response.data.data;
    localStorage.setItem(CONSTANTS.REFRESH_TOKEN, rs.refresh_token);

    if (rs.action && action.payload.onSuccess) {
      localStorage.setItem(CONSTANTS.ACCESS_TOKEN_OTP, rs.access_token);
      action.payload.onSuccess();
    } else {
      localStorage.setItem(CONSTANTS.ACCESS_TOKEN, rs.access_token);
      yield put(setLoggedInSuccess(true));
      setToken(rs.access_token);
      setTokenNanny(rs.access_token);
      // if (action.payload.onSuccess) action.payload.onSuccess();
    }
  } catch (error: unknown) {
    console.log(error);
    if (action.payload.onFailed) action.payload.onFailed();
    yield put(setGlobalLoading(false));
    toast.error('You have entered an invalid email or password!', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
}

function* verifyOtpRequest(
  action: PayloadAction<{
    data: VERIFY_BODY_REQUEST;
    onSuccess?: () => void;
    onFailed?: (data?: any) => void;
  }>
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<{
      data: VERIFY_BODY_RESPONSE;
    }> = yield call(verify2FAApi, action.payload.data);
    yield put(setGlobalLoading(false));
    const rs = response.data.data;
    localStorage.setItem(CONSTANTS.ACCESS_TOKEN, rs.access_token);
    localStorage.setItem(CONSTANTS.REFRESH_TOKEN, rs.refresh_token);
    setToken(rs.access_token);
    setTokenNanny(rs.access_token);
    yield put(setLoggedInSuccess(true));
  } catch (error: unknown) {
    console.log(error);
    if (action.payload.onFailed) action.payload.onFailed();
    yield put(setGlobalLoading(false));
    toast.error('Invalid token', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
}

function* login() {
  yield takeLatest(LOGIN_REQUEST, loginRequest);
  yield takeLatest(VERIFY_REQUEST, verifyOtpRequest);
}

export default login;
