import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { CloseCircle } from 'asset';
import { LoadingPopup } from 'components/common';
import { InputFile, Inputs } from 'components/form-control';
import { TOAST } from 'config';
import yup from 'config/yup.custom';
import { NotifyService } from 'helpers';
import { PICTURE_TYPE, UPDATE_NANNY_PICTURE_TYPE } from 'models';
import { useEffect, useMemo, useState } from 'react';
import { ControllerRenderProps, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { updateImageApi } from 'services';
import { nannyTabsApi } from 'services/accounts/nanny';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setNannyDetailSuccess } from 'store/reducers/accounts';

interface PopupCreateOrEditNannyProps {
  nannyId: string;
  subject?: PICTURE_TYPE;
  cleanDetail?: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

interface IFormInputs {
  id: string;
  caption: string;
  file: File;
  urlImg: string;
}

const PopupCreateOrEditNannyPicture = ({
  subject,
  cleanDetail,
  onClose,
  onSuccess,
  nannyId,
}: PopupCreateOrEditNannyProps) => {
  const { loadingPopup } = useAppSelector((state) => state.globalReducer);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [fileImg, setFileImg] = useState<File>();

  const schema = useMemo(() => {
    return yup.object().shape({
      file: yup.mixed().required('Photo is required'),
      caption: yup.string().required('Description is required'),
    });
  }, []);

  const form = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const {
    reset,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = form;

  const urlImg = watch('urlImg');

  useEffect(() => {
    if (subject) {
      reset({
        urlImg: subject.imageUrl,
        caption: subject.caption || '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subject]);

  const onSubmit = async (data: IFormInputs) => {
    setLoading(true);
    const _data: UPDATE_NANNY_PICTURE_TYPE = {
      id: nannyId,
      caption: data.caption,
      file: fileImg,
    };
    if (subject) {
    } else {
      await nannyTabsApi
        .uploadPicture(nannyId, _data)
        .then((r) => {
          NotifyService.success('Success');
          onSuccess();
          _onClose();
        })
        .catch((e) => {
          NotifyService.error(e);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const _onClose = () => {
    onClose?.();
    if (cleanDetail) {
      dispatch(setNannyDetailSuccess(null));
    }
  };

  const handleChangeImg = async (
    e: React.ChangeEvent<HTMLInputElement>,
    _: ControllerRenderProps<any, string>
  ) => {
    const files = e?.target?.files;
    if (!files) return;
    setFileImg(files[0]);
    const formData = new FormData();
    formData.append('image', files[0]);
    setLoading(true);
    await updateImageApi(formData)
      .then((r) => {
        if (r.data.message === 'Success') {
          setValue('urlImg', r.data.data);
        }
      })
      .catch(() => {
        toast.error('Upload Photo Failed', TOAST.error);
      })
      .finally(() => {
        setLoading(false);
      });
    e.target.files = null;
  };

  return (
    <Dialog
      open
      sx={{
        '& .MuiPaper-root': {
          width: 523,
          height: 'auto',
          borderRadius: 0,
        },
      }}
    >
      <DialogTitle
        sx={{
          padding: '20px 25px',
        }}
      >
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '100%',
            }}
          >
            {subject ? 'Edit' : 'Add'} Photo
          </Typography>
          <IconButton
            onClick={_onClose}
            sx={{
              padding: 0,
            }}
          >
            <CloseCircle />
          </IconButton>
        </Grid>
      </DialogTitle>
      {(loadingPopup || loading) && <LoadingPopup color="primary" />}
      <DialogContent
        sx={{
          p: '30px 24px 50px 26px',
        }}
        dividers
      >
        <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
          <Stack height="100%" justifyContent="space-between">
            <Stack spacing="16px">
              <Stack>
                <Inputs
                  title="Description"
                  placeholder="Write something here"
                  form={form}
                  name="caption"
                  rows={6}
                />
              </Stack>
              <Stack>
                <Stack>
                  <InputFile
                    form={form}
                    nameFile={urlImg}
                    name="file"
                    title="Photo"
                    accept="image/*"
                    handleChange={handleChangeImg}
                    errorsName={errors.file?.message}
                    sx={{
                      width: 118,
                      height: 34,
                      boxSizing: 'border-box',
                    }}
                  />
                </Stack>
                {urlImg && (
                  <Box width="109px" sx={{ position: 'relative' }}>
                    <div
                      style={{
                        position: 'absolute',
                        zIndex: 2,
                        background: '#302d2d6b',
                        width: '109px',
                      }}
                    />
                    <img src={urlImg} alt="" width="109" />
                  </Box>
                )}
              </Stack>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              marginTop="25px"
              spacing="8px"
            >
              <Button
                variant="outlined"
                color="secondary"
                sx={{
                  width: '233.5px',
                  height: '30px',
                }}
                onClick={_onClose}
              >
                Cancel
              </Button>
              <Button
                color="secondary"
                variant="contained"
                sx={{
                  width: '233.5px',
                  height: '30px',
                }}
                type="submit"
              >
                {subject ? 'Update' : 'Create'}
              </Button>
            </Stack>
          </Stack>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default PopupCreateOrEditNannyPicture;
