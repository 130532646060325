import { GET_USER_FLAG_LIST_PARAMS_REQUEST } from 'models';

export const GET_FLAG_LIST_REQUEST: string = 'GET_FLAG_LIST_REQUEST';
export const GET_FLAG_BY_USER_REQUEST: string = 'GET_FLAG_BY_USER_REQUEST';

export const getUserFlagListRequest = (
  params?: GET_USER_FLAG_LIST_PARAMS_REQUEST | null
) => {
  return {
    type: GET_FLAG_LIST_REQUEST,
    payload: { params },
  };
};

export const getFlagByUserListRequest = (id?: string) => {
  return {
    type: GET_FLAG_BY_USER_REQUEST,
    payload: { id },
  };
};
