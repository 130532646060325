import type { ThemeOptions } from '@mui/material/styles';
import { createBreakpoints } from '@mui/system';

const breakpoints = createBreakpoints({});

/**
 * Style overrides for Material UI components.
 *
 * @see https://mui.com/customization/theme-components/#global-style-overrides
 */

export const components: ThemeOptions['components'] = {
  // MuiContainer: {
  //   defaultProps: {
  //     maxWidth: false,
  //   },
  //   styleOverrides: {
  //     root: {
  //       maxWidth: 1232,
  //       [breakpoints.down('sm')]: {
  //         paddingLeft: 24,
  //         paddingRight: 24,
  //       },
  //       [breakpoints.up('lg')]: {
  //         paddingLeft: '0px !important',
  //         paddingRight: '0px !important',
  //       },
  //     },
  //   },
  // },
  // MuiButtonGroup: {
  //   styleOverrides: {
  //     root: {
  //       boxShadow: 'none',
  //     },
  //   },
  // },
  // MuiOutlinedInput: {
  //   styleOverrides: {
  //     root: {
  //       borderRadius: '5px',
  //       '&::placeholder': {
  //         fontWeight: 400,
  //         fontSize: 16,
  //         lineHeight: 1,
  //         letterSpacing: '0.045em',
  //         color: ' #999999',
  //       },
  //     },
  //     input: {
  //       padding: '10px 17px',
  //     },
  //   },
  // },
  // MuiInputLabel: {
  //   styleOverrides: {
  //     root: {
  //       color: '#000',
  //       marginBottom: '5px',
  //       lineHeight: 1,
  //       letterSpacing: '0.045em',
  //     },
  //   },
  // },
  MuiButton: {
    variants: [
      {
        props: { variant: 'contained' },
        style: {
          boxShadow: 'none',
          borderRadius: '4px',
          padding: '13.5px 22px 14.5px',
          '&:hover': {
            boxShadow: 'none',
          },
          '&:disabled': {
            backgroundColor: '#FFA8A1',
            color: '#fff',
          },
        },
      },
      {
        props: { variant: 'contained', color: 'secondary' },
        style: {
          '&:disabled': {
            backgroundColor: '#6FA1ED',
            color: '#fff',
          },
        },
      },
      {
        props: { variant: 'outlined' },
        style: {
          borderRadius: '4px',
          padding: '13.5px 22px 14.5px',
          border: '1px solid rgba(255, 121, 110, 0.5)',
          '&:hover': {
            background: 'rgba(255, 121, 110, 0.04)',
          },
          '&:disabled': {
            border: '1px solid #B7B7B7',
            color: '#B7B7B7',
          },
        },
      },
      {
        props: { variant: 'outlined', color: 'secondary' },
        style: {
          borderRadius: '4px',
          padding: '13.5px 22px 14.5px',
          border: '1px solid rgba(34, 111, 227, 0.5)',
          '&:hover': {
            background: 'rgba(34, 111, 227, 0.04)',
          },
          '&:disabled': {
            border: '1px solid #B7B7B7',
          },
        },
      },
      {
        props: { variant: 'text' },
        style: {
          borderRadius: '4px',
          padding: '13.5px 22px 14.5px',
          '&:hover': {
            background: 'rgba(255, 121, 110, 0.04)',
          },
          '&:disabled': {
            color: '#B7B7B7',
          },
        },
      },
      {
        props: { variant: 'text', color: 'secondary' },
        style: {
          borderRadius: '4px',
          padding: '13.5px 22px 14.5px',
          '&:hover': {
            background: 'rgba(34, 111, 227, 0.04)',
          },
          '&:disabled': {
            color: '#B7B7B7',
          },
        },
      },
    ],
    styleOverrides: {
      root: {
        textTransform: 'initial',
        letterSpacing: '0.25px',
        fontWeight: 500,
        lineHeight: '16px',
        fontSize: 16,
      },
    },
  },
  MuiToolbar: {
    styleOverrides: {
      root: {
        minHeight: '57px',
        [breakpoints.up('xs')]: {
          minHeight: '57px',
        },
      },
    },
  },
};
