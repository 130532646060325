import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { CloseCircle } from 'asset';
import { LoadingPopup } from 'components/common';
import { DateTimePicker, Inputs, TimerPicker } from 'components/form-control';
import Select, { GetOptionsResponse } from 'components/form-control/Select';
import yup from 'config/yup.custom';
import { NotifyService } from 'helpers';
import { useDateTime } from 'hooks';
import {
  CASH_TRANSACTION_CODE,
  CASH_TRANSACTION_STATUS,
  CASH_TRANSACTION_STATUSES,
  CREATE_PAYMENT_BODY,
  GET_USERS_OPTION_PARAMS_REQUEST,
  OptionItem,
  TRANSACTION_TYPE_TYPE,
  UPDATE_PAYMENT_BODY,
} from 'models';
import { memo, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  createPaymentApi,
  getPaymentApi,
  getTransactionTypesApi,
  getUsersOptionApi,
  updatePaymentApi,
} from 'services';

interface IFormInputs {
  amount: number | string;
  user_id: OptionItem;
  status: OptionItem;
  timestamp?: string;
  remark?: string;
}

const defaultValues: Partial<IFormInputs> = {
  amount: '',
  user_id: undefined,
  status: undefined,
  timestamp: '',
  remark: '',
};

interface Props {
  open: boolean;
  title: string;
  typeCode: CASH_TRANSACTION_CODE;
  subject?: string;
  onSuccess: () => void;
  handleClosePopup: () => void;
}

const PopupCreateOrEditTopupPayout = memo(
  ({ open, title, typeCode, subject, onSuccess, handleClosePopup }: Props) => {
    const { moment } = useDateTime();
    const [type, setType] = useState<TRANSACTION_TYPE_TYPE>();
    const [loadingPopup, setLoadingPopup] = useState(false);

    const schema = useMemo(() => {
      return yup.object({
        user_id: yup.mixed().required('User is required'),
        status: yup.mixed().required('Status is required'),
        amount: yup
          .number()
          .typeError('Amount is required')
          .min(0, 'Amount must be greater than or equal to 0')
          .required('Amount is required'),
        timestamp: yup.string().notRequired(),
        remark: yup.string().notRequired(),
      });
    }, []);

    const statuses = useMemo(() => {
      switch (typeCode) {
        case CASH_TRANSACTION_CODE.TOP_UP:
          return CASH_TRANSACTION_STATUSES.filter(
            (status) => status.id !== CASH_TRANSACTION_STATUS.PENDING
          );
      }
      return CASH_TRANSACTION_STATUSES;
    }, [typeCode]);

    const form = useForm<IFormInputs>({
      resolver: yupResolver(schema),
      mode: 'onChange',
      defaultValues: defaultValues,
    });

    const { reset, handleSubmit } = form;

    const _handleClosePopup = () => {
      reset({ ...defaultValues });
      setType(undefined);
      handleClosePopup();
    };

    useEffect(() => {
      if (open && typeCode) {
        const fetchTypes = async () => {
          await getTransactionTypesApi()
            .then(({ data }) => {
              const _type = data.data.find((it) => it.code === typeCode);
              setType(_type);
            })
            .catch((e) => NotifyService.error(e));
        };
        fetchTypes();
      }
    }, [open, typeCode]);

    useEffect(() => {
      if (subject && open) {
        setLoadingPopup(true);
        const getDetail = async () => {
          await getPaymentApi(subject)
            .then(({ data }) => {
              reset({
                amount: data.data.record.amount,
                timestamp: moment(data.data.record.timestamp).toISOString(),
                status: CASH_TRANSACTION_STATUSES.find(
                  (it) => it.id === data.data.record.status
                ),
                user_id: data.data.record.user
                  ? {
                      id: data.data.record.user.user_id,
                      label: `${data.data.record.user.first_name} ${data.data.record.user.last_name}`,
                    }
                  : undefined,
                remark: data.data.record.remark || '',
              });
              setLoadingPopup(false);
            })
            .catch((e) => NotifyService.error(e));
        };
        getDetail();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subject, open]);

    const onSubmit = async (data: IFormInputs) => {
      let date, time;
      if (data.timestamp) {
        date = moment(data.timestamp).format('YYYY-MM-DD');
        time = moment(data.timestamp).format('HH:mm');
      }
      setLoadingPopup(true);
      if (subject) {
        const body: UPDATE_PAYMENT_BODY = {
          amount: Number(data.amount),
          remark: data.remark,
          date,
          time,
        };
        await updatePaymentApi(subject, body)
          .then((r) => {
            _handleClosePopup();
            onSuccess();
            NotifyService.success(r);
          })
          .catch((err) => NotifyService.error(err))
          .finally(() => {
            setLoadingPopup(false);
          });
      } else {
        const body: CREATE_PAYMENT_BODY = {
          type_id: type?.id ?? '',
          user_id: data.user_id?.id,
          status: data.status?.id,
          amount: Number(data.amount),
          remark: data.remark,
          date,
          time,
        };
        await createPaymentApi(body)
          .then((r) => {
            _handleClosePopup();
            onSuccess();
            NotifyService.success(r);
          })
          .catch((err) => NotifyService.error(err))
          .finally(() => {
            setLoadingPopup(false);
          });
      }
    };

    const getOptions =
      (key: keyof IFormInputs) =>
      async (page: number = 1, limit: number = 20, keyword?: string) => {
        let data: GetOptionsResponse | null = null;
        switch (key) {
          case 'user_id':
            const paramsUser: GET_USERS_OPTION_PARAMS_REQUEST = {
              page: page,
              limit: limit,
            };
            if (keyword) paramsUser.keyword = keyword;
            data = await getUsersOptionApi(paramsUser)
              .then(({ data }) => {
                return {
                  metadata: data.data.metadata,
                  options: data.data.records.map((it) => ({
                    ...it,
                    label: `${it.first_name} ${it.last_name} (${it.email})`,
                  })),
                };
              })
              .catch((e) => {
                NotifyService.error(e);
                return null;
              });
            break;
        }
        return data;
      };

    return (
      <Dialog
        open={open}
        sx={{
          '& .MuiPaper-root': {
            minWidth: 523,
            borderRadius: 0,
          },
        }}
      >
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '20px 25px',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '100%',
            }}
          >
            {subject ? 'Edit' : 'Create'} {title}
          </Typography>
          <IconButton onClick={_handleClosePopup} sx={{ p: 0 }}>
            <CloseCircle />
          </IconButton>
        </Grid>
        <Divider />
        {loadingPopup && <LoadingPopup color="primary" />}
        <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
          <Stack
            p="20px 24px 50px 26px"
            height="100%"
            justifyContent="space-between"
          >
            <Stack spacing="16px">
              <Grid>
                <Select
                  isDisabled={!!subject}
                  title="User"
                  name="user_id"
                  form={form}
                  isHasMore
                  filterFunc={false}
                  getOptions={getOptions('user_id')}
                />
              </Grid>
              <Grid>
                <Inputs title="Amount" form={form} name="amount" />
              </Grid>
              <Grid>
                <Select
                  title="Status"
                  name="status"
                  form={form}
                  filterFunc={true}
                  isDisabled={!!subject}
                  options={statuses}
                />
              </Grid>
              <Grid>
                <Stack direction="row" spacing="14px" alignItems="flex-start">
                  <Stack sx={{ width: '50%' }}>
                    <DateTimePicker title="Date" form={form} name="timestamp" />
                  </Stack>
                  <Stack sx={{ width: '50%' }}>
                    <TimerPicker title="Time" form={form} name="timestamp" />
                  </Stack>
                </Stack>
              </Grid>
              <Grid>
                <Inputs title="Remark" form={form} name="remark" />
              </Grid>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing="8px"
              pt="42px"
            >
              <Button
                variant="outlined"
                color="secondary"
                sx={{
                  width: '233.5px',
                  height: '30px',
                }}
                onClick={_handleClosePopup}
              >
                Cancel
              </Button>
              <Button
                color="secondary"
                variant="contained"
                sx={{
                  width: '233.5px',
                  height: '30px',
                }}
                type="submit"
              >
                {subject ? 'Update' : 'Create'}
              </Button>
            </Stack>
          </Stack>
        </form>
      </Dialog>
    );
  }
);

export default PopupCreateOrEditTopupPayout;
