import React from 'react';

const ChartSquare = ({ ...props }) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      // {...props}
    >
      <path
        d="M10.1098 18.1501H6.31982C5.90982 18.1501 5.56982 17.8101 5.56982 17.4001V12.2802C5.56982 11.2402 6.41981 10.3901 7.45981 10.3901H10.1098C10.5198 10.3901 10.8598 10.7301 10.8598 11.1401V17.3901C10.8598 17.8101 10.5198 18.1501 10.1098 18.1501ZM7.06982 16.6501H9.3598V11.9001H7.45981C7.24981 11.9001 7.06982 12.0701 7.06982 12.2901V16.6501Z"
        fill={props.active ? '#fff' : '#8E8E93'}
      />
      <path
        d="M13.8901 18.1501H10.1001C9.6901 18.1501 9.3501 17.8101 9.3501 17.4001V7.74011C9.3501 6.70011 10.2001 5.8501 11.2401 5.8501H12.7601C13.8001 5.8501 14.6501 6.70011 14.6501 7.74011V17.4001C14.6401 17.8101 14.3101 18.1501 13.8901 18.1501ZM10.8601 16.6501H13.1501V7.74011C13.1501 7.53011 12.9801 7.3501 12.7601 7.3501H11.2401C11.0301 7.3501 10.8501 7.52011 10.8501 7.74011V16.6501H10.8601Z"
        fill={props.active ? '#fff' : '#8E8E93'}
      />
      <path
        d="M17.6801 18.1501H13.8901C13.4801 18.1501 13.1401 17.8101 13.1401 17.4001V12.8501C13.1401 12.4401 13.4801 12.1001 13.8901 12.1001H16.5401C17.5801 12.1001 18.4301 12.9501 18.4301 13.9901V17.4001C18.4301 17.8101 18.1001 18.1501 17.6801 18.1501ZM14.6401 16.6501H16.9301V13.9901C16.9301 13.7801 16.7601 13.6001 16.5401 13.6001H14.6401V16.6501Z"
        fill={props.active ? '#fff' : '#8E8E93'}
      />
      <path
        d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z"
        fill={props.active ? '#fff' : '#8E8E93'}
      />
    </svg>
  );
};

export default ChartSquare;
