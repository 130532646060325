import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from '@mui/material';
import { ChangeVideo, CloseCircle, DeteleVideo } from 'asset';
import { GlobalLoading, LoadingPopup } from 'components/common';
import PhoneNumberField from 'components/common/PhoneNumberField';
import {
  GroupCheckbox,
  InputFile,
  Inputs,
  SelectInput,
} from 'components/form-control';
import Select from 'components/form-control/Select';
import { GetOptionsResponse } from 'components/form-control/Selects';
import { INIT_COUNTRY_CODE, TOAST, patternPassword } from 'config';
import yup from 'config/yup.custom';
import { NotifyService } from 'helpers';
import {
  GET_REGIONS_PARAMS_REQUEST,
  ICheckListObjectData,
  ICreateOrEditSitterData,
  IFormInputsSitter,
  REGIONS_TYPE,
} from 'models';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Controller,
  ControllerRenderProps,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  createSitterApi,
  editSitterApi,
  getAddressByPostalCodeApi,
  getCheckListsApi,
  getDetailSitterBadgesApi,
  getSitterChecklistApi,
  updateImageApi,
  updateVideoApi,
} from 'services';
import { getRegionListApi } from 'services/settings';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setSitterDetailSuccess } from 'store/reducers/accounts';
import { GET_SITTER_DETAIL_REQUEST } from 'store/reducers/accounts/actionTypes';
import { defaultValues, experienceGroup, optionStatus } from './data';

interface Props {
  open: boolean;
  subject?: string;
  onSuccess: () => void;
  handleClosePopup: () => void;
  cleanDetail?: boolean;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const PopupCreateOrEditSitter = (props: Props) => {
  const { open, handleClosePopup, subject, onSuccess, cleanDetail } = props;

  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { sitter_detail } = useAppSelector(
    (state) => state.accountReducer.sitters
  );
  const { loadingPopup } = useAppSelector((state) => state.globalReducer);

  const [tabValue, setTabValue] = useState(0);
  const [fileVideo, setFileVideo] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const schema = useMemo(() => {
    return yup.object({
      details: yup.object({
        first_name: yup.string().required('First name is required'),
        last_name: yup.string().required('Last name is required'),
        email: yup
          .string()
          .email('Invalid email')
          .required('Email is required'),
        phone: yup
          .object({
            country_code: yup.string().required('Country code is required'),
            phone_number: yup.string().required('Phone number is required'),
          })
          .required('Phone number is required'),
        bio: yup.string().notRequired(),
        salary_per_hour: yup
          .string()
          .matches(/^[0-9]*$/g, {
            message: 'Hourly Rates must be a number',
            excludeEmptyString: true,
          })
          .notRequired(),
        address_name: yup.string().notRequired(),
        block_number: yup.string().notRequired(),
        street: yup.string().notRequired(),
        building_name: yup.string().notRequired(),
        unit_number: yup.string().notRequired(),
        postal_code: yup
          .string()
          .matches(/^[0-9]*$/g, {
            message: 'Postal Code must be a number',
            excludeEmptyString: true,
          })
          .notRequired(),
        region_id: yup.mixed().notRequired(),
        video: yup.string().notRequired(),
        years_of_experience: yup.string().matches(/^[0-9]*\.?[0-9]*$/g, {
          message: 'Years of experience must be a number',
          excludeEmptyString: true,
        }),
        status: yup.string().notRequired(),
        password: subject
          ? yup
              .string()
              .matches(patternPassword, {
                message: 'Invalid password',
                excludeEmptyString: true,
              })
              .notRequired()
          : yup
              .string()
              .matches(patternPassword, {
                message: 'Invalid password',
                excludeEmptyString: true,
              })
              .required('Password is required'),
      }),
      check_list_obj: yup.array(
        yup.object({
          group_name: yup.string().notRequired(),
          items: yup.array(
            yup.object({
              id: yup.string().notRequired(),
              is_checked: yup.bool().notRequired(),
              text: yup.string().notRequired(),
              name: yup.string().notRequired(),
              type: yup.string().notRequired(),
            })
          ),
        })
      ),
      badge_ids: yup.array(
        yup.object({
          id: yup.string().required(),
          is_checked: yup.bool().notRequired(),
          title: yup.string().notRequired(),
        })
      ),
    });
  }, [subject]);

  const form = useForm<IFormInputsSitter>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  const {
    reset,
    handleSubmit,
    setValue,
    control,
    getValues,
    watch,
    formState: { errors },
  } = form;

  const urlImg = watch('details.profile_picture');

  const { fields: checklists } = useFieldArray({
    control,
    name: 'check_list_obj',
  });

  const { fields: badges } = useFieldArray({
    control,
    name: 'badge_ids',
  });

  const _handleClosePopup = () => {
    handleClosePopup();
    reset({ ...defaultValues });
    setTabValue(0);
    setFileVideo(undefined);
    if (cleanDetail && sitter_detail) {
      dispatch(setSitterDetailSuccess(null));
    }
  };

  useEffect(() => {
    if (!open) return;
    if (subject) {
      dispatch({
        type: GET_SITTER_DETAIL_REQUEST,
        payload: { id: subject },
      });
    }
  }, [open, subject, dispatch]);

  const getCheckLists = async () => {
    if (subject) {
      return await getSitterChecklistApi(subject)
        .then((r) => r.data.data.check_list)
        .catch((e) => {
          NotifyService.error(e);
          return null;
        });
    } else {
      return await getCheckListsApi()
        .then((r) => r.data.data)
        .catch((e) => {
          NotifyService.error(e);
          return null;
        });
    }
  };

  const getDetailSitterBadges = async () => {
    return await getDetailSitterBadgesApi(subject)
      .then((r) => r.data.data)
      .catch((e) => {
        NotifyService.error(e);
        return null;
      });
  };

  useEffect(() => {
    if (open && ((subject && sitter_detail) || !subject)) {
      const fetchData = async () => {
        const [checkLists, badges] = await Promise.all([
          getCheckLists(),
          getDetailSitterBadges(),
        ]);
        let value: IFormInputsSitter = {
          ...defaultValues,
          check_list_obj:
            checkLists?.map((it) => ({
              group_name: changeGroupName(it.group_name),
              items: it.items.map((t) => ({ ...t, text: t.text || '' })),
            })) || [],
          badge_ids:
            badges?.map((it) => ({ ...it, is_checked: !!it.is_checked })) || [],
        };
        if (subject && sitter_detail) {
          value.details = {
            ...value.details,
            first_name: sitter_detail?.user?.first_name || '',
            last_name: sitter_detail?.user?.last_name || '',
            email: sitter_detail?.user?.email || '',
            phone: {
              country_code:
                sitter_detail?.user?.country_code || INIT_COUNTRY_CODE.code,
              phone_number: sitter_detail?.user?.phone_number || '',
            },
            bio: sitter_detail?.bio || '',
            salary_per_hour: sitter_detail?.salary_per_hour ?? 0,
            status: sitter_detail?.status || '',
            address_name: sitter_detail?.address?.name || '',
            block_number: sitter_detail?.address?.block_number || '',
            street: sitter_detail?.address?.street || '',
            building_name: sitter_detail?.address?.building_name || '',
            unit_number: sitter_detail?.address?.unit_number || '',
            postal_code: sitter_detail?.address?.postal_code || '',
            region_id: sitter_detail?.address?.Region
              ? {
                  id: sitter_detail?.address?.Region.id,
                  label: sitter_detail?.address?.Region.name,
                }
              : null,
            video: sitter_detail?.short_video || '',
            years_of_experience: sitter_detail?.years_of_experience ?? 0,
            profile_picture: sitter_detail?.profile_picture || '',
          };
        }
        reset(value);
      };
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, subject, sitter_detail]);

  const editSitter = async (data: ICreateOrEditSitterData) => {
    if (!subject) return;
    await editSitterApi(subject, data)
      .then((r) => {
        onSuccess();
        _handleClosePopup();
        NotifyService.success(r);
      })
      .catch((e) => NotifyService.error(e))
      .finally(() => setLoading(false));
  };

  const createSitter = async (data: ICreateOrEditSitterData) => {
    await createSitterApi(data)
      .then((r) => {
        onSuccess();
        _handleClosePopup();
        NotifyService.success(r);
      })
      .catch((e) => NotifyService.error(e))
      .finally(() => setLoading(false));
  };

  const handleChangeImg = async (
    e: React.ChangeEvent<HTMLInputElement>,
    _: ControllerRenderProps<any, string>
  ) => {
    const files = e?.target?.files;
    if (!files) return;
    const formData = new FormData();
    formData.append('image', files[0]);
    setLoading(true);
    await updateImageApi(formData)
      .then((r) => {
        if (r.data.message === 'Success') {
          setValue('details.profile_picture', r.data.data);
        }
      })
      .catch(() => {
        toast.error('Upload Photo Failed', TOAST.error);
      })
      .finally(() => {
        setLoading(false);
      });
    e.target.files = null;
  };

  const actionForm = (data: ICreateOrEditSitterData) => {
    if (subject) editSitter(data);
    else createSitter(data);
  };

  const onSubmit = async (data: IFormInputsSitter) => {
    const check_list_obj: ICheckListObjectData[] = data.check_list_obj.reduce(
      (res: ICheckListObjectData[], group) => {
        const itemsAdd: ICheckListObjectData[] = group.items
          .filter((t) => t.is_checked)
          .map((t) => ({ id: t.id, ...(!!t.text && { text: t.text }) }));
        return res.concat(itemsAdd);
      },
      []
    );

    const createOrEditData: ICreateOrEditSitterData = {
      first_name: data.details.first_name,
      last_name: data.details.last_name,
      email: data.details.email,
      country_code: data.details.phone.country_code,
      phone_number: data.details.phone.phone_number,
      bio: data.details.bio,
      salary_per_hour: Number(data.details.salary_per_hour ?? 0),
      status: data.details.status,
      address_name: data.details.address_name,
      block_number: data.details.block_number,
      street: data.details.street,
      building_name: data.details.building_name,
      unit_number: data.details.unit_number,
      postal_code: data.details.postal_code,
      region_id: data.details.region_id?.id,
      years_of_experience: Number(data.details.years_of_experience ?? 0),
      check_list_obj: check_list_obj,
      badge_ids:
        data.badge_ids?.filter((it) => it.is_checked)?.map((it) => it.id) || [],
      profile_picture: data.details.profile_picture,
    };
    if (data.details.password) {
      createOrEditData.password = data.details.password;
    }
    setLoading(true);
    if (fileVideo) {
      const formData = new FormData();
      formData.append('video', fileVideo);
      await updateVideoApi(formData)
        .then((r) => {
          if (r.data.message === 'success') {
            createOrEditData.short_video = r.data.url;
            actionForm(createOrEditData);
          }
        })
        .catch(() => {
          NotifyService.error('Upload Video Failed');
          setLoading(false);
        })
        .finally(() => {});
    } else actionForm(createOrEditData);
  };

  const handleChangeTabValue = (_: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleChangeVideo = (e: any, _: any) => {
    if (e?.target?.files === null) return;
    const files = e?.target?.files[0];
    setFileVideo(files);
    e.target.value = null;
  };

  const changeGroupName = (id: string) =>
    experienceGroup.find((g) => g.id === id)?.name || '';

  const removeVideo = () => {
    setFileVideo('');
    setValue('details.video', '');
  };

  const getOptions =
    (key: string) =>
    async (page: number = 1, limit: number = 20, keyword?: string) => {
      let data: GetOptionsResponse | null = null;
      switch (key) {
        case 'region_id':
          const paramsRegions: GET_REGIONS_PARAMS_REQUEST = {
            page: page,
            limit: limit,
          };
          if (keyword) paramsRegions.keyword = keyword;
          data = await getRegionListApi(paramsRegions)
            .then(({ data }) => {
              return {
                metadata: data.data.metadata,
                options: data.data.rows.map((it: REGIONS_TYPE) => ({
                  ...it,
                  label: it.name,
                })),
              };
            })
            .catch((e) => {
              NotifyService.error(e);
              return null;
            });
          break;
      }
      return data;
    };

  const handlePopulateAddress = async (value: any) => {
    await getAddressByPostalCodeApi(value)
      .then((r) => {
        const data = r.data.data;
        if (!data) return;
        setValue('details.block_number', data.BLK_NO);
        setValue('details.building_name', data.BUILDING);
        setValue('details.street', data.ROAD_NAME);
        NotifyService.success('Success');
      })
      .catch((e) => NotifyService.error(e));
  };

  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiPaper-root': {
          minWidth: 523,
          borderRadius: 0,
        },
      }}
    >
      <GlobalLoading open={loading} />
      <DialogTitle
        sx={{
          padding: '20px 25px',
        }}
      >
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '100%',
            }}
          >
            {subject ? 'Edit' : 'Create'} Sitter
          </Typography>
          <IconButton
            onClick={_handleClosePopup}
            sx={{
              padding: 0,
            }}
          >
            <CloseCircle />
          </IconButton>
        </Grid>
      </DialogTitle>
      {loadingPopup && <LoadingPopup color="primary" />}
      <DialogContent
        sx={{
          p: '20px 24px 50px 26px',
        }}
        dividers
      >
        <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
          <Stack height="100%" justifyContent="space-between">
            <Stack spacing="31px">
              <Tabs
                sx={{ borderBottom: '1px solid  rgba(0, 0, 0, 0.08)' }}
                value={tabValue}
                onChange={handleChangeTabValue}
                aria-label="basic tabs example"
              >
                <Tab
                  sx={{
                    width: 1 / 3,
                    textTransform: 'capitalize',
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '20px',
                    letterSpacing: '0.1px',
                  }}
                  label="Details"
                  {...a11yProps(0)}
                />
                <Tab
                  sx={{
                    width: 1 / 3,
                    textTransform: 'capitalize',
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '20px',
                    letterSpacing: '0.1px',
                  }}
                  label="Experience"
                  {...a11yProps(1)}
                />
                <Tab
                  sx={{
                    width: 1 / 3,
                    textTransform: 'capitalize',
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '20px',
                    letterSpacing: '0.1px',
                  }}
                  label="Badges"
                  {...a11yProps(2)}
                />
              </Tabs>
              <TabPanel value={tabValue} index={0}>
                <Stack spacing="16px">
                  <Stack>
                    <Stack>
                      <InputFile
                        form={form}
                        nameFile={urlImg as string}
                        name="profile_picture"
                        title="Profile Picture"
                        accept="image/*"
                        handleChange={handleChangeImg}
                        errorsName={errors.details?.profile_picture?.message}
                        sx={{
                          width: 118,
                          height: 34,
                          boxSizing: 'border-box',
                        }}
                      />
                    </Stack>
                    {urlImg && (
                      <Box width="109px" sx={{ position: 'relative' }}>
                        <div
                          style={{
                            position: 'absolute',
                            zIndex: 2,
                            background: '#302d2d6b',
                            width: '109px',
                          }}
                        />
                        <img src={urlImg} alt="" width="109" />
                      </Box>
                    )}
                  </Stack>
                  <Stack direction="row" spacing="14px">
                    <Stack
                      sx={{
                        width: '50%',
                      }}
                    >
                      <Inputs
                        title="First Name"
                        form={form}
                        name="details.first_name"
                        errorsName={errors.details?.first_name?.message}
                      />
                    </Stack>
                    <Stack
                      sx={{
                        width: '50%',
                      }}
                    >
                      <Inputs
                        title="Last Name"
                        form={form}
                        name="details.last_name"
                        errorsName={errors.details?.last_name?.message}
                      />
                    </Stack>
                  </Stack>
                  <Stack>
                    <Inputs
                      title="Email"
                      form={form}
                      name="details.email"
                      errorsName={errors.details?.email?.message}
                    />
                  </Stack>
                  <Stack>
                    <PhoneNumberField
                      title="Phone Number"
                      name="details.phone"
                      form={form}
                      errorsName={
                        errors.details?.phone?.message ||
                        errors.details?.phone?.country_code?.message ||
                        errors.details?.phone?.phone_number?.message
                      }
                    />
                  </Stack>
                  <Grid>
                    <Inputs
                      title="Hourly Rates"
                      form={form}
                      name="details.salary_per_hour"
                      errorsName={errors.details?.salary_per_hour?.message}
                    />
                  </Grid>
                  <Grid>
                    <Inputs
                      title="Address Name"
                      form={form}
                      name="details.address_name"
                      errorsName={errors.details?.address_name?.message}
                    />
                  </Grid>
                  <Grid>
                    <Inputs
                      title="Block No."
                      form={form}
                      name="details.block_number"
                      errorsName={errors.details?.block_number?.message}
                    />
                  </Grid>
                  <Grid>
                    <Inputs
                      title="Street address"
                      form={form}
                      name="details.street"
                      errorsName={errors.details?.street?.message}
                    />
                  </Grid>
                  <Grid>
                    <Inputs
                      title="Building Name"
                      form={form}
                      name="details.building_name"
                      errorsName={errors.details?.building_name?.message}
                    />
                  </Grid>
                  <Stack direction="row" spacing="14px" alignItems="end">
                    <Stack
                      sx={{
                        width: '100%',
                      }}
                    >
                      <Inputs
                        title="Postal Code"
                        form={form}
                        name="details.postal_code"
                        errorsName={errors.details?.postal_code?.message}
                      />
                    </Stack>
                    <Stack>
                      <Button
                        disabled={!!!form.watch('details.postal_code')}
                        variant="outlined"
                        color="secondary"
                        sx={{ maxHeight: '38px', mb: '3px' }}
                        onClick={() => {
                          handlePopulateAddress(
                            form.watch('details.postal_code')
                          );
                        }}
                      >
                        Populate
                      </Button>
                    </Stack>
                  </Stack>
                  <Stack>
                    <Inputs
                      title="Unit No."
                      form={form}
                      name="details.unit_number"
                      errorsName={errors.details?.unit_number?.message}
                    />
                  </Stack>

                  <Grid sx={{ zIndex: 100 }}>
                    <Select
                      title="Region"
                      form={form}
                      isHasMore
                      filterFunc={false}
                      name="details.region_id"
                      getOptions={getOptions('region_id')}
                      errorsName={errors.details?.region_id?.message}
                    />
                  </Grid>
                  <Grid>
                    <Inputs
                      title="About me"
                      form={form}
                      name="details.bio"
                      errorsName={errors.details?.bio?.message}
                      rows={6}
                    />
                  </Grid>
                  <Grid>
                    {getValues('details.video') ? (
                      <>
                        <Box
                          sx={{
                            position: 'relative',
                            borderRadius: '8px',
                            overflow: 'hidden',
                            border: '1px solid rgba(0, 0, 0, 0.08)',
                            '& video': {
                              height: '180px',
                              objectFit: 'contain',
                              width: '-webkit-fill-available',
                              display: getValues('details.video')
                                ? 'flex'
                                : 'none',
                            },
                          }}
                        >
                          <Box
                            sx={{
                              width: '100%',
                              height: '100%',
                              position: 'absolute',
                              zIndex: 99999,
                              display: getValues('details.video')
                                ? 'flex'
                                : 'none',
                              justifyContent: 'space-evenly',
                              alignItems: 'center',
                            }}
                          >
                            <Controller
                              name="details.video"
                              control={control}
                              render={({ field }) => {
                                return (
                                  <IconButton
                                    aria-label="upload picture"
                                    component="label"
                                    {...field}
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      fontWeight: 400,
                                      fontSize: 13,
                                      color: '#fff',
                                    }}
                                  >
                                    <input
                                      hidden
                                      accept="video/*"
                                      type="file"
                                      onChange={(e) =>
                                        handleChangeVideo(e, field)
                                      }
                                    />
                                    <ChangeVideo />
                                    Change
                                  </IconButton>
                                );
                              }}
                            />
                            <IconButton
                              onClick={removeVideo}
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                fontWeight: 400,
                                fontSize: 13,
                                color: '#fff',
                              }}
                            >
                              <DeteleVideo />
                              Remove
                            </IconButton>
                          </Box>
                          <Box sx={{ position: 'relative' }}>
                            <div
                              style={{
                                position: 'absolute',
                                zIndex: 2,
                                background: '#302d2d6b',
                                width: '100%',
                                height: '100%',
                              }}
                            />
                            <video
                              src={getValues('details.video') ?? undefined}
                            />
                          </Box>
                        </Box>
                      </>
                    ) : (
                      <>
                        <InputFile
                          form={form}
                          name="details.video"
                          title="Video"
                          accept="video/*"
                          handleChange={handleChangeVideo}
                          sx={{
                            width: 118,
                            height: 34,
                            boxSizing: 'border-box',
                          }}
                          errorsName={errors.details?.video?.message}
                          nameFile={fileVideo?.name}
                        />
                        {!!fileVideo && (
                          <Button sx={{ p: 0 }} onClick={removeVideo}>
                            Remove video
                          </Button>
                        )}
                      </>
                    )}
                  </Grid>
                  <Grid>
                    <Inputs
                      showEyes
                      title={subject ? 'Change password' : 'Password'}
                      type="password"
                      name="details.password"
                      placeholder="Password"
                      form={form}
                      errorsName={errors.details?.password?.message}
                    />
                  </Grid>
                  {!subject && (
                    <Grid>
                      <SelectInput
                        title="Status"
                        form={form}
                        options={optionStatus}
                        name="details.status"
                      />
                    </Grid>
                  )}
                </Stack>
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <Stack spacing="16px">
                  <Grid>
                    <Inputs
                      title="Years of experience"
                      type="number"
                      form={form}
                      name="details.years_of_experience"
                      errorsName={errors.details?.years_of_experience?.message}
                      sx={{
                        width: '70%',
                        height: '48px',
                        bgcolor: theme.palette.background.light,
                      }}
                    />
                  </Grid>

                  {checklists.map((item, index) => (
                    <GroupCheckbox
                      key={item.id}
                      fieldIndex={index}
                      form={form}
                    />
                  ))}
                </Stack>
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <FormGroup>
                  {badges.map((item, index) => (
                    <Controller
                      key={item.id}
                      name={`badge_ids.${index}.is_checked`}
                      control={form.control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="secondary"
                              checked={field.value || false}
                              onChange={(e) => field.onChange(e.target.checked)}
                              name="none"
                            />
                          }
                          label={watch(`badge_ids.${index}.title`)}
                        />
                      )}
                    />
                  ))}
                </FormGroup>
              </TabPanel>
            </Stack>

            <Stack
              direction="row"
              justifyContent="space-between"
              spacing="8px"
              pt="42px"
            >
              <Button
                variant="outlined"
                color="secondary"
                sx={{
                  width: '233.5px',
                  height: '30px',
                }}
                onClick={_handleClosePopup}
              >
                Cancel
              </Button>
              <Button
                color="secondary"
                variant="contained"
                sx={{
                  width: '233.5px',
                  height: '30px',
                }}
                type="submit"
              >
                {subject ? 'Update' : 'Create'}
              </Button>
            </Stack>
          </Stack>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default PopupCreateOrEditSitter;
