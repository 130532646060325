import { METADATA_TYPE } from './accounts';
import { OptionItem } from './global';

export interface PAYMENT_TYPE {
  amount: number;
  createdAt: string;
  doc_ref_no: string | null;
  first_name: string;
  full_name: string;
  id: string;
  last_name: string;
  number: string;
  payment_method_type: string;
  remark: string | null;
  status: string;
  stripe_transaction_id: string | null;
  timestamp: string;
  total_amount: number;
  type: string;
  updatedAt: string;
  user: {
    first_name: string;
    full_name: string;
    last_name: string;
    user_id: string;
    phone_number: string;
  };
  walletTransaction?: {
    id: string;
    number: string;
  };
  PayoutMethods: {
    account_holder_name: string;
    bank_account_number: string;
    bank_name: string;
    createdAt: string;
    deletedAt: string | null;
    id: string;
    sitter_id: string;
    updatedAt: string;
  }[];
}

export interface GET_PAYMENT_PARAMS_REQUEST {
  page?: number;
  limit?: number;
  sort?: string;
  code?: string;
  user_id?: string;
  timestamp?: string;
  user?: string;
  keyword?: string;
  status?: string;
}
export interface PAYMENT_BODY_RESULT {
  records: PAYMENT_TYPE[];
  metadata: METADATA_TYPE;
}

export interface PAYMENT_METADATA_TYPE extends METADATA_TYPE {
  pending_payouts_count?: number;
}
export interface PAYMENT_BODY_PARAMS_RESULT {
  records: PAYMENT_TYPE[];
  metadata: PAYMENT_METADATA_TYPE;
  params: GET_PAYMENT_PARAMS_REQUEST | null;
}

export enum CASH_TRANSACTION_CODE {
  TOP_UP = 'CIN', // cash-in : for top-up transaction
  CASH_FEE = 'CF', // cash-in : for top-up transaction
  PAYOUT = 'COU', // cash-out : for payout transaction
}

export enum CASH_TRANSACTION_STATUS {
  PENDING = 'pending',
  SUCCESS = 'success',
  FAIL = 'fail',
}

export const CASH_TRANSACTION_STATUSES: OptionItem[] = [
  { id: CASH_TRANSACTION_STATUS.PENDING, label: 'Pending' },
  { id: CASH_TRANSACTION_STATUS.SUCCESS, label: 'Success' },
  { id: CASH_TRANSACTION_STATUS.FAIL, label: 'Fail' },
];

export const cashTXTStatusColor: { [key: string]: string } = {
  [CASH_TRANSACTION_STATUS.SUCCESS]: '#25B995',
  [CASH_TRANSACTION_STATUS.PENDING]: '#FFCC00',
  [CASH_TRANSACTION_STATUS.FAIL]: '#FF3B30',
};

export interface PAYMENT_LOG {
  id: string;
  actor_name: string;
  actor_id: string;
  action: string;
  createdAt: string;
  content: {
    status: string;
    payment_method_id: string;
    payment_method_type: string;
    stripe_transaction_id: string;
  };
}

export interface WALLET_TXN_FOR_CASH_OUT {
  User: { id: string; first_name: string; last_name: string };
  doc_ref_no: string;
  id: string;
  number: string;
  timestamp: string;
  total_amount: number;
  type: string;
}

export interface PAYMENT_DETAIL_TYPE {
  record: PAYMENT_TYPE;
  logs: PAYMENT_LOG[];
  wallet_txn_for_cash_out: WALLET_TXN_FOR_CASH_OUT[];
}

export interface UPDATE_PAYMENT_BODY {
  status?: string;
  amount?: number;
  date?: string;
  time?: string;
  remark?: string;
}

export interface CREATE_PAYMENT_BODY {
  user_id: string;
  type_id: string;
  amount: number;
  status: string;
  date?: string;
  time?: string;
  remark?: string;
}

export interface UPDATE_PAYMENT_STATUS {
  remark?: string;
  status: string;
}

export interface UPDATE_MANY_PAYMENT_STATUS {
  ids: string[];
  status: string;
  remark?: string;
}

export interface TXN_PAYOUT_TYPE {
  id: string;
  type: string;
  timestamp: string;
  number: string;
  user_id: string;
  sr_id: string | null;
  booking_id: string | null;
  job_id: string | null;
  payment_method_type: string;
  doc_ref_no: string;
  last4: string;
  cash_txn_id: string;
  amount: number;
  tax_amount: number;
  total_amount: number;
  remark: number | null;
  createdBy: string;
  updatedBy: string;
  deletedBy: string | null;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  TransactionType: {
    code: string;
    sign: string;
  };
  CashoutWalletTransaction: string | null;
}

export interface GET_TXN_PAYOUT_PARAMS_REQUEST {
  page?: number;
  limit?: number;
  cash_txn_id?: string;
  keyword?: string;
}

export interface TXN_PAYOUT_BODY_RESULT {
  records: TXN_PAYOUT_TYPE[];
  metadata: METADATA_TYPE;
  params?: GET_TXN_PAYOUT_PARAMS_REQUEST | null;
}

export interface GET_PAYMENT_METHODS_PARAMS_REQUEST {
  page?: number;
  limit?: number;
  sort?: string;
  code?: string;
  sitter_id?: string;
}
