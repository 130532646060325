import { GET_REVIEW_LIST_PARAMS_REQUEST } from 'models';

export const DELETE_REVIEW_REQUEST: string = 'DELETE_REVIEW_REQUEST';

export const GET_REVIEW_BY_ADMIN_REQUEST: string =
  'GET_REVIEW_BY_ADMIN_REQUEST';

export const deleteReviewRequest = (id: string, onSuccess?: () => void) => {
  return {
    type: DELETE_REVIEW_REQUEST,
    payload: { id, onSuccess },
  };
};

export const getReviewsByAdminRequest = (
  params?: GET_REVIEW_LIST_PARAMS_REQUEST | null
) => {
  return {
    type: GET_REVIEW_BY_ADMIN_REQUEST,
    payload: { params },
  };
};
