import { Box, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { LogoAunty } from 'asset/images';
import { InputText } from 'components';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from 'store/hook';

interface IFormInputs {
  token: string;
}

const CheckOTP = () => {
  const dispatch = useAppDispatch();
  const form = useForm<IFormInputs>({
    mode: 'onChange',
    defaultValues: {
      token: '',
    },
  });

  const { handleSubmit } = form;

  const onSubmit = async (data: IFormInputs) => {
    dispatch({
      type: 'VERIFY_REQUEST',
      payload: {
        data,
      },
    });
  };

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background:
          'linear-gradient(0deg, rgba(255, 121, 110, 0.04), rgba(255, 121, 110, 0.04)), #FFFFFF',
      }}
    >
      <Stack spacing="47px" alignItems="center">
        <LogoAunty />
        <Box
          sx={{
            p: '32px',
            backgroundColor: 'primary.main',
            borderRadius: '8px',
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Stack spacing="16px">
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: '20px',
                  lineHeight: '160%',
                  textAlign: 'center',
                  color: 'white.light',
                }}
              >
                Administrator Login
              </Typography>
              <Box>
                <InputText
                  title="OTP"
                  name="token"
                  form={form}
                  rules={{
                    required: 'OTP is required',
                    validate: (value: string) =>
                      value.length === 6 || 'Incorrect OTP',
                  }}
                />
              </Box>
              <Button
                type="submit"
                sx={{
                  color: '#FF796E',
                  fontWeight: 700,
                  fontSize: '20px',
                  lineHeight: '32px',

                  p: '16px',
                  width: '375px',
                  height: '64px',
                  background: ' #FFFFFF',
                  borderRadius: '4px',

                  '&:hover': {
                    backgroundColor: ' #FFFFFF',
                  },
                }}
              >
                Login
              </Button>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Stack>
  );
};

export default CheckOTP;
