import {
  Button,
  Checkbox,
  InputAdornment,
  InputBase,
  inputBaseClasses,
  Paper,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import usePagination from '@mui/material/usePagination/usePagination';
import { Magnifier } from 'asset';
import { DeleteDialog, HeadTable, TableCaption } from 'components';
import { ButtonDelete } from 'components/common/table';
import PopupCreateNannyCertificate from 'components/Popups/PopupCreateNannyCertificate';
import { getSortItem } from 'helpers';
import { useDateTime } from 'hooks';
import useDebounce from 'hooks/useDebounce';
import {
  CERTIFICATE_TYPE,
  DELETE_DIALOG_TYPE,
  GET_CERTIFICATES_PARAMS_REQUEST,
  TYPE_POPUP,
} from 'models';
import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setCertificateListParams } from 'store/reducers/settings';
import { getCertificateListRequest } from 'store/reducers/settings/actionType';
import { headCells } from './data';

const Certificates = () => {
  const dispatch = useAppDispatch();
  const { certificateList } = useAppSelector(
    (state) => state.settingsReducer.certificates
  );
  const { formatDateTime } = useDateTime();
  const { params } = certificateList;
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [keyword, setKeyword] = useState('');
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [openCreatePopup, setOpenCreatePopup] = useState(false);
  const [itemAction, setItemAction] = useState<CERTIFICATE_TYPE>();

  const onSetCertParams = (params: GET_CERTIFICATES_PARAMS_REQUEST | null) => {
    dispatch(setCertificateListParams(params));
  };

  useEffect(() => {
    dispatch(getCertificateListRequest(params));
  }, [params, dispatch]);

  // useEffect(() => {
  //   setKeyword(params?.keyword ?? '');
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const setOpenDelete = (event: any, data: CERTIFICATE_TYPE) => {
    event.stopPropagation();
    setItemAction(data);
    setOpenDeletePopup(true);
  };

  const setOpenCreate = () => {
    setOpenCreatePopup(true);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = certificateList.records.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (_: any, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangeRowsPerPage = (event: SelectChangeEvent) => {
    const limit = parseInt(event.target.value, 10);
    onSetCertParams({ ...params, pageNum: 1, pageSize: limit });
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const { items } = usePagination({
    count: certificateList.metadata.total_pages,
    page: certificateList.metadata.page,
    onChange(_event, page) {
      onSetCertParams({ ...params, pageNum: page });
    },
  });

  const onSetKeywordParams = useDebounce((keyword: string) => {
    const _params = { ...params, page: 1 };
    if (keyword) _params.keyword = keyword;
    else delete _params.keyword;
    onSetCertParams(_params);
  }, 250);

  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(event.target.value);
    onSetKeywordParams(event.target.value);
  };

  const onClosePopup = (type: number) => {
    switch (type) {
      case TYPE_POPUP.DELETE:
        setOpenDeletePopup(false);
        setItemAction(undefined);
        break;
      case TYPE_POPUP.CREATE_OR_EDIT:
        setOpenCreatePopup(false);
        setOpenEditPopup(false);
        setItemAction(undefined);
        break;
    }
  };

  const onReloadList = () => {
    dispatch(getCertificateListRequest(params));
  };

  const sortItem = useMemo(() => {
    return getSortItem('');
  }, []);

  const handleRequestSort = (
    _: React.MouseEvent<unknown>,
    property: keyof CERTIFICATE_TYPE
  ) => {
    setSelected([]);
    // const isAsc = sortItem?.orderBy === property && sortItem?.order === 'asc';
    onSetCertParams({
      ...params,
      // order: `${property}-${isAsc ? 'desc' : 'asc'}`,
    });
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between" mb="16px">
        <InputBase
          placeholder="Search"
          sx={{
            maxWidth: '210px',
            height: '30px',
            flexGrow: 1,
            // paddingRight: '9px',
            background: '#F2F4F6',
            border: '1px solid #D9D9D9',
            p: '0px 7px 0px 11px',
            borderRadius: '5px',
            [`& .${inputBaseClasses.input}`]: {
              fontSize: 14,
              fontWeight: 400,
              lineHeight: '100%',
              p: 0,
              height: 'fit-content',
            },
          }}
          onChange={onChangeSearch}
          value={keyword}
          endAdornment={
            <InputAdornment position="end">
              <Magnifier />
            </InputAdornment>
          }
        />
        <Stack direction="row" spacing="8px" justifyContent="flex-end">
          <Button
            variant="contained"
            color="secondary"
            sx={{
              height: 30,
              fontWeight: 600,
              fontSize: '13px',
              lineHeight: '100%',
            }}
            onClick={setOpenCreate}
          >
            Create
          </Button>
        </Stack>
      </Stack>
      <Stack spacing="8px">
        <TableContainer
          component={Paper}
          elevation={0}
          sx={{
            border: '1px solid #D9D9D9',
            maxHeight: 'calc(100vh - 280px)',
          }}
        >
          <Table
            stickyHeader
            sx={{ minWidth: 650 }}
            aria-labelledby="tableTitle"
          >
            <HeadTable<CERTIFICATE_TYPE>
              numSelected={selected.length}
              order={sortItem?.order}
              orderBy={sortItem?.orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={certificateList.records.length}
              headCells={headCells}
            />
            <TableBody
              sx={{
                height: 'auto !important',
              }}
            >
              {certificateList.records.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    <TableCell
                      padding="checkbox"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                      }}
                    >
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        onChange={(e) => handleClick(e, row.id)}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align="left"
                      component="th"
                      scope="row"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                        width: '365px',
                      }}
                    >
                      {row.id}
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '5px 12px 5px',
                        fontSize: '14px',
                        fontWeight: 400,
                      }}
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        height="100%"
                        width="100%"
                      >
                        <img
                          src={row.icon}
                          alt="img"
                          height="42px"
                          width="42px"
                          style={{
                            borderRadius: '50%',
                          }}
                        />
                      </Stack>
                    </TableCell>
                    <TableCell
                      align="left"
                      component="th"
                      scope="row"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                        width: '365px',
                      }}
                    >
                      {row.title}
                    </TableCell>
                    <TableCell
                      align="left"
                      component="th"
                      scope="row"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                        width: '365px',
                      }}
                    >
                      {formatDateTime(row.createdAt)}
                    </TableCell>

                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        width: '209px',
                      }}
                    >
                      <Stack direction="row" spacing="10px">
                        {/* <ButtonEdit onClick={(e) => setOpenEdit(e, row)} /> */}
                        <ButtonDelete onClick={(e) => setOpenDelete(e, row)} />
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TableCaption
          rowsPerPage={params?.pageSize ?? 0}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          metadata={certificateList.metadata}
          items={items}
        />
      </Stack>

      {((openEditPopup && itemAction) || openCreatePopup) && (
        <PopupCreateNannyCertificate
          open={true}
          subject={itemAction?.id}
          onSuccess={onReloadList}
          handleClosePopup={() => onClosePopup(TYPE_POPUP.CREATE_OR_EDIT)}
        />
      )}
      {openDeletePopup && (
        <DeleteDialog
          name=""
          open={openDeletePopup}
          onClose={() => onClosePopup(TYPE_POPUP.DELETE)}
          subject={itemAction?.id}
          onSuccess={onReloadList}
          type={DELETE_DIALOG_TYPE.NANNY_CERTIFICATE}
        />
      )}
    </>
  );
};

export default Certificates;
