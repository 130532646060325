import {
  Box,
  Button,
  Dialog,
  Divider,
  Grid,
  IconButton,
  InputLabel,
  Radio,
  Stack,
  Typography,
} from '@mui/material';
import { CheckCircle, CheckCircleEmpty, CloseCircle } from 'asset';
import { InputFile, Inputs } from 'components/form-control';
import { NotifyService } from 'helpers';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  createParentChildrenDetailApi,
  editParentChildrenDetailApi,
  getParentChildrenDetailApi,
  updateImageApi,
} from 'services';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'config/yup.custom';
import { CREATE_OR_EDIT_CHILDREN_PARENT_DETAIL_BODY } from 'models';
import { toast } from 'react-toastify';
import { TOAST } from 'config';
import { theme } from 'theme';
import { LoadingPopup } from 'components/common';

interface Props {
  open: boolean;
  subject?: string;
  idChildren?: string;
  handleClosePopup: () => void;
  onSuccess: () => void;
}

interface IFormInputs {
  age_range: number | string;
  full_name: string;
  picture: string;
}

const OPTIONS_DATA = [
  {
    name: '0 - 1 months',
    value: 0,
  },
  {
    name: '2 - 12 months',
    value: 1,
  },
  {
    name: '1 - 3 years old',
    value: 2,
  },
  {
    name: '4 - 6 years old',
    value: 3,
  },
  {
    name: '7 - 12 years old',
    value: 4,
  },
  {
    name: '13+ years old',
    value: 5,
  },
];

const defaultValues: Partial<IFormInputs> = {
  age_range: undefined,
  full_name: '',
  picture: '',
};

const PopupCreateOrEditChildren = (props: Props) => {
  const { open, handleClosePopup, subject, onSuccess, idChildren } = props;

  const [nameIcon, setNameIcon] = useState<string>();
  const [urlImage, setUrlImage] = useState<any>();
  const [selectedValue, setSelectedValue] = useState<number>();
  const [loadingPopup, setLoadingPopup] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue: number = Number(event.target.value);
    setSelectedValue(newValue);
  };

  const schema = useMemo(() => {
    return yup.object().shape({
      age_range: yup.mixed().required('Age Group is required'),
      full_name: yup
        .string()
        .max(50, 'Maximum of 50 characters')
        .required('Children Name is required'),
    });
  }, []);

  const form = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  const { reset, handleSubmit, watch, control } = form;

  const numberCount = watch('full_name')?.length;

  const _handleClosePopup = () => {
    reset({ ...defaultValues });
    handleClosePopup();
  };

  useEffect(() => {
    if (idChildren && subject) {
      setLoadingPopup(true);
      const getDetail = async () => {
        await getParentChildrenDetailApi(subject, idChildren)
          .then((r) => {
            reset({
              full_name: r.data.data.full_name,
              age_range: r.data.data.age_range,
              picture: r.data.data.picture,
            });
            setSelectedValue(r.data.data.age_range);
            setUrlImage(r.data.data.picture);
            const nameIcon =
              r.data.data.picture.split('/')[
                r.data.data.picture.split('/').length - 1
              ];

            setNameIcon(nameIcon);
            setLoadingPopup(false);
          })
          .catch((e) => NotifyService.error(e))
          .finally(() => {});
      };
      getDetail();
    }
  }, [idChildren, subject, reset]);

  const onSubmit = async (value: IFormInputs) => {
    if (!subject) return;
    const data: CREATE_OR_EDIT_CHILDREN_PARENT_DETAIL_BODY = {
      full_name: value.full_name,
      age_range: Number(value.age_range),
      picture: urlImage,
    };
    if (idChildren) {
      await editParentChildrenDetailApi(subject, idChildren, data)
        .then((r) => {
          if (r.data.message === 'Success') {
            onSuccess();
            _handleClosePopup();
            NotifyService.success(r);
          }
        })
        .catch((e) => NotifyService.error(e))
        .finally(() => {});
    } else {
      await createParentChildrenDetailApi(subject, data)
        .then((r) => {
          if (r.data.message === 'Success') {
            onSuccess();
            _handleClosePopup();
            NotifyService.success(r);
          }
        })
        .catch((e) => NotifyService.error(e))
        .finally(() => {});
    }
  };

  const handleChangeIcon = (e: any, _: any) => {
    if (e?.target?.files === null) return;
    const files = e?.target?.files[0];
    const formData = new FormData();
    formData.append('image', files);
    const fetchData = async () => {
      await updateImageApi(formData)
        .then((r) => {
          if (r.data.message === 'Success') {
            setUrlImage(r.data.data);
          }
        })
        .catch(() => {
          toast.error('Upload Image Failed', TOAST.error);
        })
        .finally(() => {});
    };
    fetchData();
    setNameIcon(files.name);
    e.target.value = null;
  };

  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiPaper-root': {
          width: 523,
          height: 'auto',
          borderRadius: 0,
        },
      }}
    >
      <Grid
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '20px 25px',
        }}
      >
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '100%',
          }}
        >
          {idChildren ? 'Edit' : 'Create'} Children
        </Typography>
        <IconButton
          onClick={_handleClosePopup}
          sx={{
            padding: 0,
          }}
        >
          <CloseCircle />
        </IconButton>
      </Grid>
      <Divider />
      {loadingPopup && <LoadingPopup color="primary" />}
      <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
        <Stack
          p="30px 24px 50px 26px"
          height="100%"
          justifyContent="space-between"
        >
          <Stack mb="20px">
            <InputFile
              form={form}
              nameFile={nameIcon}
              name="picture"
              title="Profile Photo"
              accept="image/*"
              handleChange={handleChangeIcon}
              sx={{
                width: 118,
                height: 34,
                boxSizing: 'border-box',
              }}
            />
          </Stack>
          {urlImage && (
            <Box
              mb="20px"
              width="109px"
              height="109px"
              sx={{ position: 'relative' }}
            >
              <div
                style={{
                  position: 'absolute',
                  zIndex: 2,
                  background: '#302d2d6b',
                  width: '109px',
                  height: '109px',
                }}
              />
              <img src={urlImage} alt="" width="109" height="109" />
            </Box>
          )}
          <Box mb="20px">
            <Inputs
              title="What’s your child’s name?"
              form={form}
              name="full_name"
              showCount={true}
              numberCount={numberCount}
            />
          </Box>

          {/* <SelectInput
            title="How old is your child?"
            form={form}
            options={OPTIONS_DATA}
            name="age_range"
          /> */}
          <Stack>
            <InputLabel
              sx={{
                fontWeight: 500,
                fontSize: '15px',
                lineHeight: '100%',
                color: '#000',
                marginBottom: '5px',
              }}
            >
              How old is your child?
            </InputLabel>
            <Controller
              control={control}
              name="age_range"
              render={({ field }) => (
                <Stack {...field} spacing="12px">
                  {OPTIONS_DATA.map((item, index) => (
                    <Stack
                      key={index}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{
                        pl: '16px',
                        bgcolor:
                          selectedValue === item.value
                            ? 'rgba(34, 111, 227, 0.08)'
                            : theme.palette.background.light,
                        borderRadius: '8px',
                        border:
                          selectedValue === item.value
                            ? '1px solid #226FE3'
                            : '1px solid transparent',
                      }}
                    >
                      <Typography>{item.name}</Typography>
                      <Radio
                        color="secondary"
                        checked={selectedValue === item.value}
                        onChange={handleChange}
                        value={item.value}
                        name="age_range"
                        checkedIcon={<CheckCircle />}
                        icon={<CheckCircleEmpty />}
                        inputProps={{ 'aria-label': 'A' }}
                      />
                    </Stack>
                  ))}
                </Stack>
              )}
            />
          </Stack>
          <Stack direction="row" justifyContent="space-between" mt="25px">
            <Button
              variant="outlined"
              color="secondary"
              sx={{
                width: '233.5px',
                height: '30px',
              }}
              onClick={_handleClosePopup}
            >
              Cancel
            </Button>
            <Button
              color="secondary"
              variant="contained"
              sx={{
                width: '233.5px',
                height: '30px',
              }}
              type="submit"
            >
              {idChildren ? 'Update' : 'Create'}
            </Button>
          </Stack>
        </Stack>
      </form>
    </Dialog>
  );
};

export default PopupCreateOrEditChildren;
