import {
  Button,
  Dialog,
  dialogClasses,
  DialogContent,
  // DialogContentText,
  DialogTitle,
  Grid,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { DeleteIcon } from 'asset';
import { SelectInput } from 'components/form-control';
import { NotifyService } from 'helpers';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { updateJobStatusApi } from 'services/jobs';

interface Props {
  open: boolean;
  onClose: () => void;
  subject: any;
  onSuccess: any;
}

interface IFormInputs {
  user: string;
}

const OPTIONS_DATA = [
  {
    name: 'Parent',
    value: 'parent',
  },
  {
    name: 'Sitter',
    value: 'sitter',
  },
];

const PopupCancelledJob = ({
  open = true,
  onClose,
  subject,
  onSuccess,
}: Props) => {
  const [isUser, setIsUser] = useState('');

  const form = useForm<IFormInputs>({
    mode: 'onChange',
    defaultValues: {
      user: '',
    },
  });

  const { handleSubmit } = form;

  const onSuccessAccept = () => {
    onClose();
    onSuccess();
  };

  const onSubmit = async (data: IFormInputs) => {
    await updateJobStatusApi(subject as string, {
      status: 'canceled',
      behalf_of: data.user,
    })
      .then((r) => {
        onSuccessAccept();
        NotifyService.success(r);
      })
      .catch((e) => NotifyService.error(e))
      .finally(() => {});
  };

  const theme = useTheme();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        [`& .${dialogClasses.paper}`]: {
          maxWidth: '400px',
          width: '100%',
        },
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          py: '24px',
          display: 'flex',
          alignItems: 'center',
        }}
        component="div"
      >
        <DeleteIcon />
      </DialogTitle>
      <DialogContent
        sx={{
          padding: '0px 24px',
        }}
      >
        <Stack spacing="8px">
          <Typography variant="Large_Body_18_SemiBold">Cancel Job</Typography>
          <Typography variant="Small_Body_16_Regular">
            Are you sure you want to cancel this job?
          </Typography>
          <form
            id="job"
            onSubmit={handleSubmit(onSubmit)}
            style={{ height: '100%' }}
          >
            <Stack spacing="8px">
              <Grid>
                <SelectInput
                  title="Cancelled by"
                  form={form}
                  options={OPTIONS_DATA}
                  name="user"
                  handleOnChange={(e: any) => {
                    setIsUser(e.target.value);
                  }}
                />
              </Grid>
              <Typography
                sx={{
                  fontSize: '13px',
                  lineHeight: '16px',
                  color: theme.palette.primary.main,
                }}
              >
                {isUser === ''
                  ? ''
                  : isUser === 'parent'
                  ? '24hr refund rule applies.'
                  : 'Full refund to parent.'}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              spacing="8px"
              sx={{
                py: '24px',
              }}
            >
              <Button
                onClick={onClose}
                variant="outlined"
                sx={{
                  border: '1px solid rgba(0, 0, 0, 0.08)',
                  color: 'black.light',
                  ':hover': {
                    color: 'primary.main',
                    border: '1px solid rgba(0, 0, 0, 0.08)',
                    bgcolor: 'transparent',
                  },
                  width: '100%',
                }}
              >
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  border: `1px solid ${theme.palette.primary.main}`,
                  width: '100%',
                }}
                type="submit"
                disabled={isUser === ''}
              >
                Confirm
              </Button>
            </Stack>
          </form>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default PopupCancelledJob;
