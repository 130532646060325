import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { CloseCircle } from 'asset';
import { Inputs } from 'components/form-control';
import {
  createParentAddressApi,
  editParentAddressDetailApi,
  getAddressByPostalCodeApi,
  getParentAddressDetailApi,
} from 'services';
import { NotifyService } from 'helpers';
import {
  ADDRESS_DETAIL_PARENT_DETAIL_TYPE,
  GET_REGIONS_PARAMS_REQUEST,
  OptionItem,
  REGIONS_TYPE,
} from 'models';
import Select, { GetOptionsResponse } from 'components/form-control/Select';
import { getRegionListApi } from 'services/settings';
import { LoadingPopup } from 'components/common';

interface Props {
  open: boolean;
  handleClosePopup: () => void;
  onSuccess: (data?: ADDRESS_DETAIL_PARENT_DETAIL_TYPE) => void;
  subject?: any;
  idAddress?: any;
  isEdit?: boolean;
}

interface IFormInputs {
  name: string;
  block_number: string;
  street: string;
  building_name: string;
  unit_number: string;
  postal_code: string;
  region_id: OptionItem | null;
}

const defaultValues: Partial<IFormInputs> = {
  name: '',
  block_number: '',
  street: '',
  building_name: '',
  unit_number: '',
  postal_code: '',
  region_id: undefined,
};

const PopupEditAddress = (props: Props) => {
  const { open, handleClosePopup, subject, idAddress, onSuccess, isEdit } =
    props;

  const [loadingPopup, setLoadingPopup] = useState(false);

  const form = useForm<IFormInputs>({
    defaultValues: defaultValues,
  });

  const { reset, handleSubmit, setValue } = form;

  useEffect(() => {
    if (isEdit) {
      getDetail();
    }
    // eslint-disable-next-line
  }, [isEdit]);

  const getDetail = async () => {
    setLoadingPopup(true);
    await getParentAddressDetailApi(subject, idAddress)
      .then((r) => {
        reset({
          name: r.data.data.name,
          block_number: r.data.data.block_number,
          street: r.data.data.street,
          building_name: r.data.data.building_name,
          unit_number: r.data.data.unit_number,
          postal_code: r.data.data.postal_code,
          region_id: r.data.data.region
            ? {
                id: r.data.data.region.id,
                label: r.data.data.region.name,
              }
            : undefined,
        });
        setLoadingPopup(false);
      })
      .catch((e) => NotifyService.error(e))
      .finally(() => {});
  };

  const onSubmit = async (value: IFormInputs) => {
    const data = {
      ...value,
      region_id: value.region_id?.id,
    };
    if (isEdit) {
      await editParentAddressDetailApi(subject, idAddress, data)
        .then((r) => {
          if (r.data.message === 'Success') {
            onSuccess(r.data.data);
            handleClosePopup();
            NotifyService.success('Success');
          }
        })
        .catch((e) => NotifyService.error(e))
        .finally(() => {});
    } else {
      await createParentAddressApi(subject, data)
        .then((r) => {
          if (r.data.message === 'Success') {
            onSuccess();
            handleClosePopup();
            NotifyService.success('Success');
          }
        })
        .catch((e) => NotifyService.error(e))
        .finally(() => {});
    }
  };

  const getOptions =
    (key: keyof IFormInputs) =>
    async (page: number = 1, limit: number = 20, keyword?: string) => {
      let data: GetOptionsResponse | null = null;
      switch (key) {
        case 'region_id':
          const paramsRegions: GET_REGIONS_PARAMS_REQUEST = {
            page: page,
            limit: limit,
          };
          if (keyword) paramsRegions.keyword = keyword;
          data = await getRegionListApi(paramsRegions)
            .then(({ data }) => {
              return {
                metadata: data.data.metadata,
                options: data.data.rows.map((it: REGIONS_TYPE) => ({
                  ...it,
                  label: it.name,
                })),
              };
            })
            .catch((e) => {
              NotifyService.error(e);
              return null;
            });
          break;
      }
      return data;
    };

  const handlePopulateAddress = async (value: string) => {
    await getAddressByPostalCodeApi(value)
      .then((r) => {
        const data = r.data.data;
        if (!data) return;
        setValue('block_number', data.BLK_NO);
        setValue('building_name', data.BUILDING);
        setValue('street', data.ROAD_NAME);
      })
      .catch((e) => NotifyService.error(e));
  };

  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiPaper-root': {
          width: 523,
          borderRadius: 0,
          height: 'auto',
        },
      }}
    >
      <Grid
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '20px 25px',
        }}
      >
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '100%',
          }}
        >
          {isEdit ? 'Edit Addresses' : 'Add New Address'}
        </Typography>
        <IconButton
          onClick={handleClosePopup}
          sx={{
            padding: 0,
          }}
        >
          <CloseCircle />
        </IconButton>
      </Grid>
      <Divider />
      {loadingPopup && <LoadingPopup color="primary" />}
      <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
        <Stack
          p="30px 24px 50px 26px"
          height="100%"
          justifyContent="space-between"
        >
          <Stack spacing="16px">
            <Grid>
              <Inputs
                title="Address Name"
                form={form}
                name="name"
                rules={{
                  required: 'Address Name is required',
                }}
              />
            </Grid>
            <Grid>
              <Inputs
                title="Block No."
                form={form}
                name="block_number"
                rules={{
                  required: 'Block Number is required',
                }}
              />
            </Grid>
            <Grid>
              <Inputs
                title="Street Name"
                form={form}
                name="street"
                rules={{
                  required: 'Street Name is required',
                }}
              />
            </Grid>
            <Grid>
              <Inputs title="Building Name" form={form} name="building_name" />
            </Grid>
            <Grid>
              <Inputs title="Unit No." form={form} name="unit_number" />
            </Grid>
            <Grid>
              <Inputs
                showPopulate
                title="Postal Code"
                form={form}
                name="postal_code"
                handlePopulate={handlePopulateAddress}
              />
            </Grid>
            <Grid>
              <Select
                title="Region"
                form={form}
                isHasMore
                filterFunc={false}
                name="region_id"
                getOptions={getOptions('region_id')}
              />
            </Grid>
          </Stack>

          <Stack direction="row" justifyContent="space-between" mt="32px">
            <Button
              onClick={() => {
                handleClosePopup();
              }}
              variant="outlined"
              color="secondary"
              sx={{
                width: '233.5px',
                height: '30px',
              }}
            >
              Cancel
            </Button>
            <Button
              color="secondary"
              variant="contained"
              sx={{
                width: '233.5px',
                height: '30px',
              }}
              type="submit"
            >
              {isEdit ? 'Update' : 'Add'}
            </Button>
          </Stack>
        </Stack>
      </form>
    </Dialog>
  );
};

export default PopupEditAddress;
