import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { NotifyService } from 'helpers';
import {
  GET_ACTIVITY_TAB_PARAMS_REQUEST,
  GET_NANNY_PARAMS_REQUEST,
  GET_NANNY_TAB_PARAMS_REQUEST,
  NANNY_LIST_STATE,
} from 'models';
import { call, put, takeLatest } from 'redux-saga/effects';
import { nannyApi, nannyTabsApi } from 'services/accounts/nanny';
import {
  setNannyActivitySuccess,
  setNannyBookingSuccess,
  setNannyCertificateSuccess,
  setNannyDetailSuccess,
  setNannyListSuccess,
  setNannyPicturesSuccess,
  setNannyTestimonialsSuccess,
} from 'store/reducers/accounts';
import {
  DELETE_NANNY_CERTIFICATE_ASSOCIATION_REQUEST,
  DELETE_NANNY_PICTURES_REQUEST,
  DELETE_NANNY_REQUEST,
  DELETE_NANNY_TESTIMONIAL_REQUEST,
  GET_NANNY_ACTIVITY_REQUEST,
  GET_NANNY_BOOKING_REQUEST,
  GET_NANNY_CERTIFICATE_ASSOCIATION_REQUEST,
  GET_NANNY_DETAIL_REQUEST,
  GET_NANNY_LIST_REQUEST,
  GET_NANNY_PICTURES_REQUEST,
} from 'store/reducers/accounts/actionTypes';
import { setGlobalLoading } from 'store/reducers/global';
import { GET_NANNY_TESTIMONIAL_REQUEST } from '../../reducers/accounts/actionTypes';

function* setNannyListRequest(
  action: PayloadAction<{
    params?: GET_NANNY_PARAMS_REQUEST;
  }>
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<{
      data: NANNY_LIST_STATE;
    }> = yield call(nannyApi.getAll, action.payload.params);

    yield put(setNannyListSuccess(response.data));
    yield put(setGlobalLoading(false));
  } catch (error: any) {
    NotifyService.error(error);
    yield put(setGlobalLoading(false));
  }
}

function* getNannyDetailRequest(
  action: PayloadAction<{
    data: { id: string };
    onSuccess?: () => void;
  }>
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<{
      data: any;
    }> = yield call(nannyApi.getDetail, action.payload.data.id);
    yield put(setNannyDetailSuccess(response.data.data));
    if (action.payload.onSuccess) action.payload.onSuccess();
    yield put(setGlobalLoading(false));
  } catch (error: unknown) {
    NotifyService.error(error);
    yield put(setGlobalLoading(false));
  }
}

function* deleteNannyRequest(
  action: PayloadAction<{
    id: string;
    onSuccess?: () => void;
  }>
) {
  try {
    yield call(nannyApi.deleteBulk, [action.payload.id]);
    if (action.payload.onSuccess) action.payload.onSuccess();
  } catch (error: unknown) {}
}

function* getNannyBookingsRequest(
  action: PayloadAction<{
    params?: GET_NANNY_TAB_PARAMS_REQUEST;
  }>
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<any> = yield call(
      nannyTabsApi.getBookings,
      action.payload.params
    );
    yield put(setNannyBookingSuccess(response.data));
    yield put(setGlobalLoading(false));
  } catch (error: unknown) {}
}

function* getNannyActivityRequest(
  action: PayloadAction<{
    params?: GET_ACTIVITY_TAB_PARAMS_REQUEST;
  }>
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<any> = yield call(
      nannyTabsApi.getActivity,
      action.payload.params
    );
    yield put(setNannyActivitySuccess(response.data));
    yield put(setGlobalLoading(false));
  } catch (error: unknown) {}
}

function* getNannyPicturesRequest(
  action: PayloadAction<{
    params?: GET_NANNY_TAB_PARAMS_REQUEST;
  }>
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<any> = yield call(
      nannyTabsApi.getPictures,
      action.payload.params
    );
    yield put(setNannyPicturesSuccess(response.data));
    yield put(setGlobalLoading(false));
  } catch (error: unknown) {}
}

function* deleteNannyPictureRequest(
  action: PayloadAction<{
    id: string;
    onSuccess?: () => void;
  }>
) {
  try {
    yield call(nannyTabsApi.deletePictures, action.payload.id);
    if (action.payload.onSuccess) action.payload.onSuccess();
  } catch (error: unknown) {}
}

function* getNannyTestimonialsRequest(
  action: PayloadAction<{
    params?: GET_NANNY_TAB_PARAMS_REQUEST;
  }>
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<any> = yield call(
      nannyTabsApi.getTestimonials,
      action.payload.params
    );
    yield put(setNannyTestimonialsSuccess(response.data));
    yield put(setGlobalLoading(false));
  } catch (error: unknown) {}
}

function* deleteNannyTestimonialsRequest(
  action: PayloadAction<{
    id: string;
    onSuccess?: () => void;
  }>
) {
  try {
    yield call(nannyTabsApi.deleteTestimonials, action.payload.id);
    if (action.payload.onSuccess) action.payload.onSuccess();
  } catch (error: unknown) {}
}

function* getNannyCertificatesAssociationRequest(
  action: PayloadAction<{
    params?: GET_NANNY_TAB_PARAMS_REQUEST;
  }>
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<any> = yield call(
      nannyTabsApi.getCertificateAssociation,
      action.payload.params
    );
    yield put(setNannyCertificateSuccess(response.data));
    yield put(setGlobalLoading(false));
  } catch (error: unknown) {}
}

function* deleteNannyCertificateRequestAssociation(
  action: PayloadAction<{
    id: string;
    onSuccess?: () => void;
  }>
) {
  try {
    yield call(nannyTabsApi.deleteCertificateAssociation, action.payload.id);
    if (action.payload.onSuccess) action.payload.onSuccess();
  } catch (error: unknown) {
    NotifyService.error(error);
  }
}

function* nanny() {
  yield takeLatest(GET_NANNY_LIST_REQUEST, setNannyListRequest);
  yield takeLatest(GET_NANNY_DETAIL_REQUEST, getNannyDetailRequest);
  yield takeLatest(DELETE_NANNY_REQUEST, deleteNannyRequest);
  yield takeLatest(GET_NANNY_BOOKING_REQUEST, getNannyBookingsRequest);
  yield takeLatest(GET_NANNY_ACTIVITY_REQUEST, getNannyActivityRequest);
  yield takeLatest(GET_NANNY_PICTURES_REQUEST, getNannyPicturesRequest);
  yield takeLatest(DELETE_NANNY_PICTURES_REQUEST, deleteNannyPictureRequest);
  yield takeLatest(GET_NANNY_TESTIMONIAL_REQUEST, getNannyTestimonialsRequest);
  yield takeLatest(
    DELETE_NANNY_TESTIMONIAL_REQUEST,
    deleteNannyTestimonialsRequest
  );
  yield takeLatest(
    GET_NANNY_CERTIFICATE_ASSOCIATION_REQUEST,
    getNannyCertificatesAssociationRequest
  );
  yield takeLatest(
    DELETE_NANNY_CERTIFICATE_ASSOCIATION_REQUEST,
    deleteNannyCertificateRequestAssociation
  );
  // yield debounce(
  //   250,
  //   GET_STAFFS_LIST_REQUEST_WITH_KEYWORD,
  //   setNannyListRequest
  // );
}

export default nanny;
