import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  FLAG_BY_USER_STATE,
  FLAG_LIST_STATE,
  GET_USER_FLAG_LIST_PARAMS_REQUEST,
} from 'models';

export interface FlagState {
  flag: {
    flag_list: FLAG_LIST_STATE;
    flag_by_id: FLAG_BY_USER_STATE;
  };
}

const initialState: FlagState = {
  flag: {
    flag_list: {
      records: [],
      metadata: {
        page: 1,
        limit: 50,
        page_count: 1,
        total_pages: 1,
        total_count: 1,
      },
      params: { page: 1, limit: 50 },
    },
    flag_by_id: {
      data: [],
    },
  },
};

const flagSlice = createSlice({
  name: 'flag',
  initialState,
  reducers: {
    setflagListSuccess(
      state: FlagState,
      action: PayloadAction<FLAG_LIST_STATE>
    ) {
      state.flag.flag_list = {
        ...state.flag.flag_list,
        ...action.payload,
      };
    },
    setflagListParams(
      state: FlagState,
      action: PayloadAction<GET_USER_FLAG_LIST_PARAMS_REQUEST | null>
    ) {
      state.flag.flag_list.params = action.payload;
    },
    setflagByUserSuccess(
      state: FlagState,
      action: PayloadAction<FLAG_BY_USER_STATE>
    ) {
      state.flag.flag_by_id = {
        ...state.flag.flag_by_id,
        ...action.payload,
      };
    },
  },
});

// Actions
export const { setflagListSuccess, setflagListParams, setflagByUserSuccess } =
  flagSlice.actions;

export default flagSlice.reducer;
