import { Button, ButtonProps } from "@mui/material";
import { Pencil } from "asset";
import { memo } from "react";

interface Props extends ButtonProps {
}

const ButtonEdit = memo(({ ...rest }: Props) => {

  return (
    <Button
      sx={{
        minWidth: '38px !important',
        height: '32px',
        '&.MuiButton-root': {
          padding: '0px !important',
        },
      }}
      variant="contained"
      color="secondary"
      {...rest}
    >
      <Pencil />
    </Button>
  )
})

export default ButtonEdit