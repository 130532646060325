import { HeadCell, USERS_TYPE } from 'models';

export const headCells: readonly HeadCell<USERS_TYPE>[] = [
  {
    id: 'first_name',
    numeric: false,
    disablePadding: true,
    isSort: false,
    label: 'First name',
  },
  {
    id: 'last_name',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Last name',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Email',
  },
  {
    id: 'phone_number',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Phone',
  },
  {
    id: 'parent',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Parent',
  },
  {
    id: 'sitter',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Sitter',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Status',
  },
];
