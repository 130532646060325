import { GET_TRANSACTIONS_PARAMS_REQUEST } from 'models';

export const GET_TRANSACTIONS_LIST_REQUEST: string =
  'GET_TRANSACTIONS_LIST_REQUEST';

export const GET_TRANSACTION_DETAIL_REQUEST: string =
  'GET_TRANSACTION_DETAIL_REQUEST';

export const DELETE_TRANSACTION_REQUEST: string = 'DELETE_TRANSACTION_REQUEST';

export const DELETE_ALL_TRANSACTIONS_REQUEST: string =
  'DELETE_ALL_TRANSACTIONS_REQUEST';

export const DELETE_PAYMENT_TRANSACTION_REQUEST: string =
  'DELETE_PAYMENT_TRANSACTION_REQUEST';

export const GET_TRANSACTIONS_TYPE_LIST_REQUEST: string =
  'GET_TRANSACTIONS_TYPE_LIST_REQUEST';

export const deleteTransactionRequest = (
  id: string,
  onSuccess?: () => void
) => {
  return {
    type: DELETE_TRANSACTION_REQUEST,
    payload: {
      id,
      onSuccess,
    },
  };
};

export const deletePaymentTransactionRequest = (
  id: string,
  onSuccess?: () => void
) => {
  return {
    type: DELETE_PAYMENT_TRANSACTION_REQUEST,
    payload: {
      id,
      onSuccess,
    },
  };
};

export const getTransactionsListRequest = (
  params?: GET_TRANSACTIONS_PARAMS_REQUEST | null
) => {
  return {
    type: GET_TRANSACTIONS_LIST_REQUEST,
    payload: { params },
  };
};

export const getTransactionsTypeListRequest = () => {
  return {
    type: GET_TRANSACTIONS_TYPE_LIST_REQUEST,
  };
};
