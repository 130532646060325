import {
  Box,
  Button,
  Checkbox,
  Paper,
  Rating,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import usePagination from '@mui/material/usePagination/usePagination';
import { SolidStar } from 'asset';
import { DeleteDialog, HeadTable, TableCaption } from 'components';
import PopupCreateOrEditNannyTestimonial from 'components/Popups/Nanny/PopupCreateOrEditNannyTestimonial';
import {
  ButtonDelete,
  ButtonEdit,
  TableCellNoData,
} from 'components/common/table';
import { getSortItem } from 'helpers';
import { useDateTime } from 'hooks';
import {
  DELETE_DIALOG_TYPE,
  ECLOSE_POPUP,
  GET_NANNY_TAB_PARAMS_REQUEST,
  HeadCell,
  TESTIMONIAL_TYPE,
} from 'models';
import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setNannyTestimonialsParams } from 'store/reducers/accounts';
import { getNannyTestimonialRequest } from 'store/reducers/accounts/actionTypes';

const headCells: readonly HeadCell<TESTIMONIAL_TYPE>[] = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    isSort: false,
    label: 'Booking ID',
  },
  {
    id: 'parentFirstName',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Parent',
  },
  {
    id: 'content',
    numeric: false,
    disablePadding: true,
    isSort: false,
    label: 'Comments',
  },
  {
    id: 'anonymous',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Anonymous',
  },
  {
    id: 'stars',
    numeric: false,
    disablePadding: true,
    isSort: false,
    label: 'Rating',
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Review Date',
  },
];

interface ServiceRequestProps {
  nannyId: string | undefined;
}
const Testimonials = ({ nannyId }: ServiceRequestProps) => {
  const { testimonials } = useAppSelector(
    (state) => state.accountReducer.nanny.nanny_detail_tab
  );

  const { params } = testimonials;

  const dispatch = useAppDispatch();
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [openCreatePopup, setOpenCreatePopup] = useState(false);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [itemAction, setItemAction] = useState<TESTIMONIAL_TYPE>();
  const { formatDateTime } = useDateTime();

  const onSetListParams = (params: GET_NANNY_TAB_PARAMS_REQUEST) => {
    dispatch(setNannyTestimonialsParams(params));
  };

  useEffect(() => {
    if (nannyId) {
      dispatch(getNannyTestimonialRequest(nannyId, params));
    }
  }, [params, nannyId, dispatch]);

  const onReloadList = () => {
    if (!nannyId) return;
    dispatch(getNannyTestimonialRequest(nannyId, params));
  };

  const handleChangeRowsPerPage = (event: SelectChangeEvent) => {
    const limit = parseInt(event.target.value, 10);
    onSetListParams({ ...params, pageNum: 1, pageSize: limit });
  };

  const { items } = usePagination({
    count: testimonials.totalPages,
    page: testimonials.pageNum,
    onChange(_event, page) {
      onSetListParams({ ...params, pageNum: page });
    },
  });

  const sortItem = useMemo(() => {
    return getSortItem('');
  }, []);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = testimonials?.data?.map((n: any) => n?.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (_: any, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleRequestSort = (_: any, property: keyof TESTIMONIAL_TYPE) => {
    setSelected([]);
    // const isAsc = sortItem?.orderBy === property && sortItem?.order === 'asc';
    // onSetListParams({
    //   ...params,
    //   sort: `${property}-${isAsc ? 'desc' : 'asc'}`,
    // });
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const onClosePopup = (type: ECLOSE_POPUP) => {
    switch (type) {
      case ECLOSE_POPUP.DELETE:
        setOpenDeletePopup(false);
        setItemAction(undefined);
        break;
      case ECLOSE_POPUP.CREATE_OR_EDIT:
        setOpenCreatePopup(false);
        setOpenEditPopup(false);
        setItemAction(undefined);
        break;
    }
  };

  const setOpenDelete = (event: any, data: TESTIMONIAL_TYPE) => {
    event.stopPropagation();
    setItemAction(data);
    setOpenDeletePopup(true);
  };

  const setOpenEdit = (event: any, data: TESTIMONIAL_TYPE) => {
    event.stopPropagation();
    setItemAction(data);
    setOpenEditPopup(true);
  };

  const setOpenCreate = () => {
    setOpenCreatePopup(true);
  };

  return (
    <>
      <Box display="flex" justifyContent="flex-end" mb={'8px'}>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            height: 30,
            fontWeight: 600,
            fontSize: '13px',
            lineHeight: '100%',
          }}
          onClick={setOpenCreate}
        >
          Add Testimonial
        </Button>
      </Box>
      <Stack spacing="8px">
        <TableContainer
          component={Paper}
          elevation={0}
          sx={{
            border: '1px solid #D9D9D9',
            maxHeight: 'calc(100vh - 395px)',
          }}
        >
          <Table
            stickyHeader
            sx={{ minWidth: 650 }}
            aria-labelledby="tableTitle"
          >
            <HeadTable<TESTIMONIAL_TYPE>
              numSelected={selected.length}
              order={sortItem?.order}
              orderBy={sortItem?.orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={testimonials?.data.length}
              headCells={headCells}
            />
            <TableBody
              sx={{
                height: 'auto !important',
              }}
            >
              {!!testimonials?.data.length ? (
                testimonials?.data?.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell
                        padding="checkbox"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                        }}
                      >
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          onChange={(e) => handleClick(e, row.id)}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '5px 12px 5px',
                          fontSize: '14px',
                          fontWeight: 400,
                        }}
                      >
                        {row.bookingId}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                          fontSize: '14px',
                          fontWeight: 400,
                        }}
                      >
                        {row.parentFirstName}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                          fontSize: '14px',
                          fontWeight: 400,
                        }}
                      >
                        {row.content}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                          fontSize: '14px',
                          fontWeight: 400,
                        }}
                      >
                        {(row.anonymous && 'Yes') || 'No'}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                          fontSize: '14px',
                          fontWeight: 400,
                        }}
                      >
                        <Rating
                          name="read-only"
                          value={row.stars}
                          readOnly
                          icon={<SolidStar fontSize="16px" />}
                          emptyIcon={
                            <SolidStar color="#D1D1D6" fontSize="16px" />
                          }
                        />
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                          fontSize: '14px',
                          fontWeight: 400,
                        }}
                      >
                        {formatDateTime(
                          row.createdAt,
                          'DD MMM YYYY',
                          'DD-MM-YYYY'
                        )}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                        }}
                      >
                        <Stack direction="row" spacing="10px">
                          <ButtonEdit onClick={(e) => setOpenEdit(e, row)} />
                          <ButtonDelete
                            onClick={(e) => setOpenDelete(e, row)}
                          />
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCellNoData colSpan={headCells.length + 2} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TableCaption
          rowsPerPage={params?.pageSize ?? 0}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          metadata={{
            page: testimonials.pageNum,
            limit: testimonials.pageSize,
            page_count: 1,
            total_pages: testimonials.totalPages,
            total_count: testimonials.totalSize,
          }}
          items={items}
        />
      </Stack>

      <DeleteDialog
        name=""
        open={openDeletePopup}
        onClose={() => onClosePopup(ECLOSE_POPUP.DELETE)}
        subject={itemAction?.id}
        onSuccess={onReloadList}
        type={DELETE_DIALOG_TYPE.NANNY_TESTIMONIAL}
      />
      {((itemAction && openEditPopup) || openCreatePopup) && (
        <PopupCreateOrEditNannyTestimonial
          nannyId={nannyId || ''}
          subject={itemAction}
          onClose={() => onClosePopup(ECLOSE_POPUP.CREATE_OR_EDIT)}
          onSuccess={onReloadList}
        />
      )}
    </>
  );
};

export default Testimonials;
