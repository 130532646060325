import React from 'react';

const CheckCircle = () => {
  return (
    <svg
      width={25}
      height={24}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.0714111" width={24} height={24} rx={12} fill="#226FE3" />
      <path
        d="M6.07141 12L10.3141 16.2426L18.7993 7.75732"
        stroke="white"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckCircle;
