import {
  CREATE_STAFFS_TYPE,
  EDIT_STAFFS_TYPE,
  GET_STAFFS_PARAMS_REQUEST,
} from 'models';
import { AppAPIInstance } from 'services';

export const getAdminsListApi = async (params?: GET_STAFFS_PARAMS_REQUEST) => {
  return await AppAPIInstance.get('/admin/staffs', { params });
};

export const createAdminsApi = async (data?: CREATE_STAFFS_TYPE) => {
  return await AppAPIInstance.post('/admin/staffs', data);
};

export const editAdminsApi = async (id: string, data?: EDIT_STAFFS_TYPE) => {
  return await AppAPIInstance.put(`/admin/staffs/${id}`, data);
};

export const getAdminDetailApi = async (id: string) => {
  return await AppAPIInstance.get(`/admin/staffs/${id}`);
};

export const deleteAdminApi = async (id: string) => {
  return await AppAPIInstance.delete(`/admin/staffs/${id}`);
};

export const deleteAdminAllApi = async (ids: string[]) => {
  const idsObj = {
    ids,
  };
  return await AppAPIInstance.delete('/admin/staffs', {
    data: idsObj,
  });
};

export const getAdminProfileApi = async () => {
  return await AppAPIInstance.get(`/admin/staffs/my-profile`);
};
