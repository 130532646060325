import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { CloseCircle } from 'asset';
import { Inputs } from 'components';
import { LoadingPopup } from 'components/common';
import { InputFile } from 'components/form-control';
import { FILE_SIZE_LIMIT, TOAST } from 'config';
import yup from 'config/yup.custom';
import { NotifyService } from 'helpers';
import { useEffect, useMemo, useState } from 'react';
import { ControllerRenderProps, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { updateImageApi } from 'services';
import { nannyTabsApi } from 'services/accounts/nanny';

interface CreateCertProps {
  open: boolean;
  subject?: string;
  onSuccess: () => void;
  handleClosePopup: () => void;
}

interface IFormInputs {
  title: string;
  icon: File | undefined;
  urlImg: string;
}

const defaultValues: Partial<IFormInputs> = {
  title: '',
  icon: undefined,
  urlImg: '',
};

const PopupCreateNannyCertificate = (props: CreateCertProps) => {
  const { open, handleClosePopup, onSuccess, subject } = props;

  const theme = useTheme();

  const [fileImg, setFileImg] = useState<File>();
  const [loadingPopup, setLoadingPopup] = useState(false);

  const schema = useMemo(() => {
    return yup.object({
      title: yup.string().required('Title is required'),
      icon: yup.mixed().required('Icon is required'),
    });
  }, []);

  const form = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  const {
    reset,
    handleSubmit,
    setValue,
    watch,
    setError,
    formState: { errors },
  } = form;
  const urlImg = watch('urlImg');

  const _handleClosePopup = () => {
    handleClosePopup();
    setFileImg(undefined);
    reset({ ...defaultValues });
  };

  const onSubmit = async (value: any) => {
    if (errors.icon?.message) return;
    const newData = { ...value, icon: fileImg };
    await nannyTabsApi
      .uploadCertificate(newData)
      .then((r) => {
        onSuccess();
        _handleClosePopup();
        NotifyService.success(r);
      })
      .catch((e) => NotifyService.error(e))
      .finally(() => {});
  };

  const handleChangeIcon = async (
    e: React.ChangeEvent<HTMLInputElement>,
    _: ControllerRenderProps<any, string>
  ) => {
    const files = e?.target?.files;
    if (!files) return;
    setFileImg(files[0]);
    const formData = new FormData();
    formData.append('image', files[0]);
    setLoadingPopup(true);
    await updateImageApi(formData)
      .then((r) => {
        if (r.data.message === 'Success') {
          setValue('urlImg', r.data.data);
        }
      })
      .catch(() => {
        toast.error('Upload Photo Failed', TOAST.error);
      })
      .finally(() => {
        setLoadingPopup(false);
      });
    e.target.files = null;
  };

  useEffect(() => {
    if (fileImg && fileImg?.size >= FILE_SIZE_LIMIT) {
      setError('icon', {
        type: 'onBlur',
        message: 'File size is too large',
      });
    }
  }, [errors, fileImg, setError]);

  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiPaper-root': {
          width: 527,
          borderRadius: 0,
        },
      }}
    >
      <Grid>
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '20px 25px',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '100%',
            }}
          >
            {subject ? 'Edit' : 'Add'} Certificate
          </Typography>
          <IconButton
            onClick={_handleClosePopup}
            sx={{
              padding: 0,
            }}
          >
            <CloseCircle />
          </IconButton>
        </Grid>
        <Divider />
        {loadingPopup && <LoadingPopup color="primary" />}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing="20px" p="25px 24px">
            <Stack>
              <Inputs title="Certificate name" form={form} name="title" />
            </Stack>
            <Stack>
              <InputFile
                form={form}
                nameFile={urlImg}
                name="icon"
                title="Attachment (Image files)"
                accept="image/*"
                handleChange={handleChangeIcon}
                errorsName={errors.icon?.message}
                sx={{
                  width: 118,
                  height: 34,
                  boxSizing: 'border-box',
                }}
              />
            </Stack>
            {urlImg && (
              <Box width="109px" height="109px" sx={{ position: 'relative' }}>
                <div
                  style={{
                    position: 'absolute',
                    zIndex: 2,
                    background: '#302d2d6b',
                    width: '109px',
                    height: '109px',
                  }}
                />
                <img src={urlImg} alt="" width="109" height="109" />
              </Box>
            )}
            <Stack direction="row" spacing="8px">
              <Button
                variant="outlined"
                color="secondary"
                onClick={_handleClosePopup}
                sx={{
                  height: 30,
                  width: '100%',
                  fontWeight: 600,
                  fontSize: '14px',
                  lineHeight: 1.1,
                  border: `1px solid ${theme.palette.secondary.main}`,
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                sx={{
                  height: 30,
                  width: '100%',
                  fontWeight: 600,
                  fontSize: '14px',
                  lineHeight: 1.1,
                }}
              >
                {subject ? 'Update' : 'Create'}
              </Button>
            </Stack>
          </Stack>
        </form>
      </Grid>
    </Dialog>
  );
};

export default PopupCreateNannyCertificate;
