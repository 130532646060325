import {
  AppBar,
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Tab,
  Tabs,
  Toolbar,
  Typography,
  // useTheme,
} from '@mui/material';
import { Layout, PopupCreateOrEditTopupPayout } from 'components';
import { drawerWidth } from 'config';
import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hook';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { EditParentIcon } from 'asset';
import { useNavigate, useParams } from 'react-router';
import { setParentDetailSuccess } from 'store/reducers/accounts';
import { CASH_TRANSACTION_CODE, ECLOSE_POPUP } from 'models';
import { GET_PAYOUTS_DETAIL_REQUEST } from 'store/reducers/payment/actionTypes';
import RelatedTXN from './RelatedTXN';
import { printPrice } from 'helpers';

const titleTab = ['Related TXN'];
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const PayoutsDetail = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [tabValue, setTabValue] = useState(0);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const { payouts_detail } = useAppSelector(
    (state) => state.paymentReducer.payment
  );
  const navigate = useNavigate();

  const setOpenEdit = (event: any) => {
    event.stopPropagation();
    setOpenEditPopup(true);
  };
  const onClosePopup = (txt: string) => {
    if (txt === ECLOSE_POPUP.CREATE_OR_EDIT) {
      setOpenEditPopup(false);
    }
  };

  const handleChangeTabValue = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setTabValue(newValue);
  };

  const onSuccessDeleteParent = () => {
    dispatch({
      type: GET_PAYOUTS_DETAIL_REQUEST,
      payload: { id: id },
    });
  };

  useEffect(() => {
    dispatch({
      type: GET_PAYOUTS_DETAIL_REQUEST,
      payload: { id: id },
    });
    return () => {
      dispatch(setParentDetailSuccess(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Layout>
      <AppBar
        position="fixed"
        sx={{
          width: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`,
          backgroundColor: 'white.light',
          borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
        }}
        elevation={0}
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Grid>
            <Typography
              sx={{
                fontSize: '20px',
                lineHeight: 1,
                color: 'grey1.light',
              }}
            >
              Finance / Payout /{payouts_detail?.record.number}
            </Typography>
          </Grid>
        </Toolbar>
      </AppBar>
      <Paper
        elevation={0}
        sx={{
          p: '16px',
        }}
      >
        <Grid container justifyContent="space-between" pb="16px">
          <Button
            startIcon={<ArrowBackIosNewIcon />}
            sx={{
              p: 0,
              color: 'black.light',
              ':hover': {
                backgroundColor: 'white.light',
              },
            }}
            variant="text"
            onClick={() => {
              navigate(`/finance/payouts`);
            }}
          >
            Back
          </Button>
          <Button
            startIcon={<EditParentIcon />}
            sx={{
              p: 0,
            }}
            onClick={(e) => setOpenEdit(e)}
          >
            Edit Transaction
          </Button>
        </Grid>
        <Stack
          justifyContent="center"
          sx={{
            backgroundColor: 'rgba(34, 111, 227, 0.04)',
            p: '21px 31px',
          }}
          spacing="30px"
        >
          <Stack direction="row" spacing="80px">
            <Grid display="flex" flexDirection="column" gap="4px">
              <Typography
                fontWeight={500}
                lineHeight="14px"
                fontSize={14}
                letterSpacing="0.1px"
                color="text.secondary"
              >
                Transaction ID
              </Typography>
              <Typography
                fontWeight={400}
                lineHeight="16px"
                fontSize={14}
                letterSpacing="0.25px"
                color="black.light"
              >
                {payouts_detail?.record.number}
              </Typography>
            </Grid>
            <Grid display="flex" flexDirection="column" gap="4px">
              <Typography
                fontWeight={500}
                lineHeight="14px"
                fontSize={14}
                letterSpacing="0.1px"
                color="text.secondary"
              >
                User
              </Typography>
              <Grid container>
                <Typography
                  fontWeight={400}
                  lineHeight="16px"
                  fontSize={14}
                  letterSpacing="0.25px"
                  color="black.light"
                  display="flex"
                >
                  {payouts_detail?.record.full_name}
                </Typography>
              </Grid>
            </Grid>
            <Grid display="flex" flexDirection="column" gap="4px">
              <Typography
                fontWeight={500}
                lineHeight="14px"
                fontSize={14}
                letterSpacing="0.1px"
                color="text.secondary"
              >
                Total Amount
              </Typography>
              <Grid container>
                <Typography
                  fontWeight={400}
                  lineHeight="16px"
                  fontSize={14}
                  letterSpacing="0.25px"
                  color="black.light"
                  display="flex"
                  textTransform="capitalize"
                >
                  {payouts_detail &&
                    printPrice(payouts_detail.record.total_amount)}
                </Typography>
              </Grid>
            </Grid>
            <Grid display="flex" flexDirection="column" gap="4px">
              <Typography
                fontWeight={500}
                lineHeight="14px"
                fontSize={14}
                letterSpacing="0.1px"
                color="text.secondary"
              >
                TXN Date
              </Typography>
              <Grid container>
                <Typography
                  fontWeight={400}
                  lineHeight="16px"
                  fontSize={14}
                  letterSpacing="0.25px"
                  color="black.light"
                  display="flex"
                  textTransform="capitalize"
                >
                  {payouts_detail?.record.createdAt}
                </Typography>
              </Grid>
            </Grid>
            <Grid display="flex" flexDirection="column" gap="4px">
              <Typography
                fontWeight={500}
                lineHeight="14px"
                fontSize={14}
                letterSpacing="0.1px"
                color="text.secondary"
              >
                Last Updated Date
              </Typography>
              <Grid container>
                <Typography
                  fontWeight={400}
                  lineHeight="16px"
                  fontSize={14}
                  letterSpacing="0.25px"
                  color="black.light"
                  display="flex"
                  textTransform="capitalize"
                >
                  {payouts_detail?.record.updatedAt}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
        </Stack>
        <Grid
          container
          sx={{
            borderBottom: '1px solid  rgba(0, 0, 0, 0.08)',
            marginBottom: '16px',
          }}
        >
          <Tabs
            value={tabValue}
            onChange={handleChangeTabValue}
            aria-label="basic tabs example"
          >
            {titleTab.map((item, index) => {
              return (
                <Tab
                  key={index}
                  label={item}
                  {...a11yProps(index)}
                  sx={{
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '125%',
                    letterSpacing: '0.1px',
                    color: '#959595',
                    textTransform: 'capitalize',
                  }}
                />
              );
            })}
          </Tabs>
        </Grid>
        <TabPanel value={tabValue} index={0}>
          <RelatedTXN
            payouts_detail={payouts_detail}
            onSuccess={onSuccessDeleteParent}
          />
        </TabPanel>
      </Paper>
      {openEditPopup && (
        <PopupCreateOrEditTopupPayout
          open={openEditPopup}
          title="Payout"
          typeCode={CASH_TRANSACTION_CODE.PAYOUT}
          subject={payouts_detail?.record.id}
          onSuccess={onSuccessDeleteParent}
          handleClosePopup={() => onClosePopup(ECLOSE_POPUP.CREATE_OR_EDIT)}
        />
      )}
    </Layout>
  );
};

export default PayoutsDetail;
