import React from 'react';

const VerifiedIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00004 14.6666C11.6819 14.6666 14.6667 11.6819 14.6667 7.99998C14.6667 4.31808 11.6819 1.33331 8.00004 1.33331C4.31814 1.33331 1.33337 4.31808 1.33337 7.99998C1.33337 11.6819 4.31814 14.6666 8.00004 14.6666ZM11.0614 6.30697C11.2309 6.089 11.1917 5.77486 10.9737 5.60533C10.7557 5.43579 10.4416 5.47506 10.272 5.69303L7.6007 9.12761C7.5413 9.20398 7.42957 9.21389 7.35765 9.14917L5.66786 7.62836C5.46261 7.44363 5.14646 7.46027 4.96173 7.66552C4.777 7.87078 4.79364 8.18692 4.9989 8.37165L6.68868 9.89246C7.1921 10.3455 7.97424 10.2762 8.39005 9.74155L11.0614 6.30697Z"
        fill="#34C759"
      />
    </svg>
  );
};

export default VerifiedIcon;
