export const GET_REQUESTS_JUST_ONCE_REQUEST: string =
  'GET_REQUESTS_JUST_ONCE_REQUEST';

export const GET_REQUESTS_RECURRING_REQUEST: string =
  'GET_REQUESTS_RECURRING_REQUEST';

export const GET_REQUESTS_JUST_ONCE_REQUEST_WITH_KEYWORD: string =
  'GET_REQUESTS_JUST_ONCE_REQUEST_WITH_KEYWORD';

export const GET_REQUESTS_RECURRING_REQUEST_WITH_KEYWORD: string =
  'GET_REQUESTS_RECURRING_REQUEST_WITH_KEYWORD';

export const GET_REQUESTS_DETAIL_REQUEST: string =
  'GET_REQUESTS_DETAIL_REQUEST';
export const DELETE_REQUESTS_REQUEST: string = 'DELETE_REQUESTS_REQUEST';
export const DELETE_SITTER_REQUESTS_REQUEST: string =
  'DELETE_SITTER_REQUESTS_REQUEST';
export const DELETE_ALL_REQUESTS_REQUEST: string =
  'DELETE_ALL_REQUESTS_REQUEST';

export const REQUEST_FOR_PAYMENT: string = 'REQUEST_FOR_PAYMENT';
