import { FLAG_BY_USER_TYPE, HeadCell } from 'models';

export const headCells: readonly HeadCell<FLAG_BY_USER_TYPE>[] = [
  {
    id: 'flag',
    numeric: false,
    disablePadding: true,
    isSort: false,
    label: 'Flag',
  },
  {
    id: 'remark',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Remark',
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Review Date',
  },
];
