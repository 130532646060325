import { takeLatest, call, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { GET_SETTING_CONFIGS_REQUEST } from 'store/reducers/settings/actionType';
import { SETTING_CONFIGS } from 'models';
import { getSettingConfigsApi } from 'services';
import { setSettingConfigsSuccess } from 'store/reducers/settings';

function* getSettingConfigsRequest() {
  try {
    const response: AxiosResponse<{ data: SETTING_CONFIGS }> = yield call(
      getSettingConfigsApi
    );

    yield put(setSettingConfigsSuccess(response.data.data));
  } catch (error: unknown) {}
}

function* configs() {
  yield takeLatest(GET_SETTING_CONFIGS_REQUEST, getSettingConfigsRequest);
}

export default configs;
