import { Stack, Box, Divider, Button } from "@mui/material"
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'config/yup.custom';
import { useForm } from 'react-hook-form';
import { useEffect, useMemo, useState } from "react";
import { Inputs, Select } from "components/form-control";
import { OptionItem, SETTING_KEY } from "models";
import { getSettingsGeneralApi, getSettingsTimezonesApi, updateSettingsApi } from "services";
import { NotifyService } from "helpers";
import { useAppDispatch } from "store/hook";
import { getSettingConfigsRequest } from "store/reducers/settings/actionType";

interface IFormInputs {
  [SETTING_KEY.timezone]: OptionItem;
  [SETTING_KEY.admin_email]: string;
}

const defaultValues: Partial<IFormInputs> = {
  [SETTING_KEY.timezone]: undefined,
  [SETTING_KEY.admin_email]: ''
}

const General = () => {
  
  const dispatch = useAppDispatch();
  const [timezones, setTimezones] = useState<OptionItem[]>([])

  const schema = useMemo(() => {
    return yup.object({
      [SETTING_KEY.timezone]: yup.mixed().required("Timezone is required"),
      [SETTING_KEY.admin_email]: yup.string().emails().notRequired()
    });
  }, []);

  const form = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  const {
    reset,
    handleSubmit,
  } = form;

  const fetchData = async () => {
    await getSettingsGeneralApi()
      .then(({ data: { data } }) => {
        reset({
          [SETTING_KEY.timezone]: data[SETTING_KEY.timezone] ? { id: data[SETTING_KEY.timezone], label: data[SETTING_KEY.timezone] } : undefined,
          [SETTING_KEY.admin_email]: data[SETTING_KEY.admin_email],
        })
      })
      .catch(e => NotifyService.error(e))
  }

  useEffect(() => {
    const fetchTimezones = async () => {
      await getSettingsTimezonesApi()
        .then(({ data }) => {
          setTimezones(data.data.map(it => ({ id: it, label: it })))
        })
        .catch(e => NotifyService.error(e))
    }
    fetchTimezones()
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = async (value: IFormInputs) => {
    const data = {
      [SETTING_KEY.timezone]: value[SETTING_KEY.timezone]?.id,
      [SETTING_KEY.admin_email]: value[SETTING_KEY.admin_email]?.split(',').map(t => t.trim()).filter(t => !!t).join(',')
    }
    await updateSettingsApi(data)
      .then((r) => {
        fetchData()
        NotifyService.success(r)
        dispatch(getSettingConfigsRequest())
      })
      .catch((e) => NotifyService.error(e))
  }

  return (
    <Box onSubmit={handleSubmit(onSubmit)} component="form">
      <Stack sx={{ width: '475px' }} spacing="16px">
        <Stack>
          <Select
            title="Timezone"
            form={form}
            filterFunc={true}
            name={SETTING_KEY.timezone}
            options={timezones}
          />
        </Stack>
        <Divider />
        <Stack>
          <Inputs
            rows={4}
            title="Admin Email (for Email notifications)"
            form={form}
            name={SETTING_KEY.admin_email}
            helperText="Separate emails by comma (,)"
          />
        </Stack>
        <Divider />
        <Button
          fullWidth
          color="secondary"
          variant="contained"
          sx={{
            height: '30px',
          }}
          type="submit"
        >
          Save
        </Button>
      </Stack>
    </Box>
  )
}

export default General