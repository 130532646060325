import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  ACTIVITY_TAB_TYPE,
  ADDRESS_PARENT_DETAIL_TYPE,
  CASH_TRANSACTION_CODE,
  CASH_TRANSACTION_STATUS,
  CERTIFICATE_TAB_TYPE,
  CHAT_TYPE,
  CHILDREN_PARENT_DETAIL_TYPE,
  GET_ACTIVITY_TAB_PARAMS_REQUEST,
  GET_ADDRESSES_PARENT_DETAIL_PARAMS_REQUEST,
  GET_CHATS_PARAMS_REQUEST,
  GET_CHILDREN_PARENT_DETAIL_TYPE_PARAMS_REQUEST,
  GET_JOBS_PARAMS_REQUEST,
  GET_NANNY_PARAMS_REQUEST,
  GET_NANNY_TAB_PARAMS_REQUEST,
  GET_PARENTS_PARAMS_REQUEST,
  GET_PAYMENT_PARAMS_REQUEST,
  GET_REQUESTS_PARAMS_REQUEST,
  GET_REVIEWS_PARAMS_REQUEST,
  GET_SITTERS_PARAMS_REQUEST,
  GET_STAFFS_PARAMS_REQUEST,
  GET_TRANSACTIONS_PARAMS_REQUEST,
  JOBS_BODY_RESULT,
  LIST_BODY_RESULT,
  NANNY_DETAIL_TAB_STATE,
  NANNY_DETAIL_TYPE,
  NANNY_LIST_STATE,
  PARENTS_LIST_STATE,
  PARENT_DETAIL_TAB_STATE,
  PARENT_DETAIL_TYPE,
  PARENT_TYPE,
  PAYMENT_BODY_RESULT,
  PICTURE_TAB_TYPE,
  REVIEW_TYPE,
  SITTERS_LIST_DETAIL_STATE,
  SITTERS_LIST_STATE,
  SITTER_BODY_RESULT,
  SITTER_DETAIL_TYPE,
  STAFFS_DETAIL_TYPE,
  STAFFS_LIST_STATE,
  TESTIMONIAL_TAB_TYPE,
  TRANSACTIONS_BODY_RESULT,
  USER_DETAIL_TYPE,
} from 'models';
import {
  GET_USERS_PARAMS_REQUEST,
  USERS_LIST_STATE,
} from './../../../models/accounts';

export interface AccountType {
  sitters: {
    sitter_list: SITTERS_LIST_STATE;
    sitter_detail: SITTER_DETAIL_TYPE | null;
    sitter_list_detail: SITTERS_LIST_DETAIL_STATE;
    sitter_option: SITTERS_LIST_STATE;
  };
  parents: {
    parent_list: PARENTS_LIST_STATE;
    parent_detail: PARENT_DETAIL_TYPE | null;
    parent_detail_tab: PARENT_DETAIL_TAB_STATE;
  };
  nanny: {
    nanny_list: NANNY_LIST_STATE;
    nanny_detail: NANNY_DETAIL_TYPE | null;
    nanny_detail_tab: NANNY_DETAIL_TAB_STATE;
  };
  staffs: {
    staffs_list: STAFFS_LIST_STATE;
    staffs_detail: STAFFS_DETAIL_TYPE | null;
  };
  users: {
    users_list: USERS_LIST_STATE;
    users_detail: USER_DETAIL_TYPE | null;
  };
}

export const initialAcountState: AccountType = {
  sitters: {
    sitter_list: {
      params: {
        sort: 'createdAt-desc',
        page: 1,
        limit: 50,
      },
      records: [],
      metadata: {
        page: 1,
        limit: 50,
        page_count: 1,
        total_pages: 1,
        total_count: 1,
        pending_sitters_count: 0,
      },
    },
    sitter_option: {
      params: {
        sort: 'createdAt-desc',
        page: 1,
        limit: 50,
      },
      records: [],
      metadata: {
        page: 1,
        limit: 50,
        page_count: 1,
        total_pages: 1,
        total_count: 1,
        pending_sitters_count: 0,
      },
    },
    sitter_detail: null,
    sitter_list_detail: {
      jobs: {
        params: {
          sort: 'createdAt-desc',
          page: 1,
          limit: 50,
        },
        records: [],
        metadata: {
          page: 1,
          limit: 50,
          page_count: 1,
          total_pages: 1,
          total_count: 1,
        },
      },
      transactions: {
        params: {
          page: 1,
          limit: 50,
        },
        records: [],
        metadata: {
          page: 1,
          limit: 50,
          page_count: 1,
          total_pages: 1,
          total_count: 1,
        },
      },
      pendingPayout: {
        params: {
          page: 1,
          limit: 50,
          status: CASH_TRANSACTION_STATUS.PENDING,
          code: CASH_TRANSACTION_CODE.PAYOUT,
        },
        records: [],
        metadata: {
          page: 1,
          limit: 50,
          page_count: 1,
          total_pages: 1,
          total_count: 1,
        },
      },
      payoutHistory: {
        params: {
          page: 1,
          limit: 50,
          code: CASH_TRANSACTION_CODE.PAYOUT,
        },
        records: [],
        metadata: {
          page: 1,
          limit: 50,
          page_count: 1,
          total_pages: 1,
          total_count: 1,
        },
      },
      chat: {
        params: { page: 1, limit: 50, deleted: false },
        records: [],
        metadata: {
          page: 1,
          limit: 50,
          page_count: 1,
          total_pages: 1,
          total_count: 1,
        },
      },
      reviews: {
        params: { page: 1, limit: 50 },
        records: [],
        metadata: {
          page: 1,
          limit: 50,
          page_count: 1,
          total_pages: 1,
          total_count: 1,
        },
      },
    },
  },
  parents: {
    parent_list: {
      params: { sort: 'createdAt-desc', page: 1, limit: 50 },
      records: [],
      metadata: {
        page: 1,
        limit: 50,
        page_count: 1,
        total_pages: 1,
        total_count: 1,
      },
    },
    parent_detail: null,
    parent_detail_tab: {
      jobs: {
        params: { sort: 'createdAt-desc', page: 1, limit: 50 },
        records: [],
        metadata: {
          page: 1,
          limit: 50,
          page_count: 1,
          total_pages: 1,
          total_count: 1,
        },
      },
      paymentHistory: {
        params: {
          page: 1,
          limit: 50,
          code: CASH_TRANSACTION_CODE.TOP_UP,
        },
        records: [],
        metadata: {
          page: 1,
          limit: 50,
          page_count: 1,
          total_pages: 1,
          total_count: 1,
        },
      },
      chat: {
        params: { page: 1, limit: 50 },
        records: [],
        metadata: {
          page: 1,
          limit: 50,
          page_count: 1,
          total_pages: 1,
          total_count: 1,
        },
      },
      addresses: {
        params: { sort: 'createdAt-desc', page: 1, limit: 50 },
        records: [],
        metadata: {
          page: 1,
          limit: 50,
          page_count: 1,
          total_pages: 1,
          total_count: 1,
        },
      },
      children: {
        params: { sort: 'createdAt-desc', page: 1, limit: 50 },
        records: [],
        metadata: {
          page: 1,
          limit: 50,
          page_count: 1,
          total_pages: 1,
          total_count: 1,
        },
      },
      transactions: {
        params: { sort: 'createdAt-desc', page: 1, limit: 50 },
        records: [],
        metadata: {
          page: 1,
          limit: 50,
          page_count: 1,
          total_pages: 1,
          total_count: 1,
        },
      },
      activity: {
        params: {
          pageNum: 1,
          pageSize: 50,
          showDeleted: false,
        },
        data: [],
        pageNum: 1,
        pageSize: 50,
        totalPages: 1,
        totalSize: 1,
      },
      requests: {
        params: { sort: 'createdAt-desc', page: 1, limit: 50 },
        records: [],
        metadata: {
          page: 1,
          limit: 50,
          page_count: 1,
          total_pages: 1,
          total_count: 1,
        },
      },
    },
  },
  nanny: {
    nanny_list: {
      params: { pageNum: 1, pageSize: 50 },
      data: [],
      pageNum: 1,
      pageSize: 50,
      totalPages: 1,
      totalSize: 1,
    },
    nanny_detail: null,
    nanny_detail_tab: {
      bookings: {
        params: { pageNum: 1, pageSize: 50 },
        data: [],
        pageNum: 1,
        pageSize: 50,
        totalPages: 1,
        totalSize: 1,
      },
      paymentHistory: {
        params: {
          page: 1,
          limit: 50,
          code: CASH_TRANSACTION_CODE.TOP_UP,
        },
        records: [],
        metadata: {
          page: 1,
          limit: 50,
          page_count: 1,
          total_pages: 1,
          total_count: 1,
        },
      },
      pendingPayout: {
        params: { page: 1, limit: 50 },
        records: [],
        metadata: {
          page: 1,
          limit: 50,
          page_count: 1,
          total_pages: 1,
          total_count: 1,
        },
      },
      activity: {
        params: {
          pageNum: 1,
          pageSize: 50,
          showDeleted: false,
        },
        data: [],
        pageNum: 1,
        pageSize: 50,
        totalPages: 1,
        totalSize: 1,
      },
      pictures: {
        params: { pageNum: 1, pageSize: 50 },
        data: [],
        pageNum: 1,
        pageSize: 50,
        totalPages: 1,
        totalSize: 1,
      },
      certificate: {
        params: { pageNum: 1, pageSize: 50 },
        data: [],
        pageNum: 1,
        pageSize: 50,
        totalPages: 1,
        totalSize: 1,
      },
      transactions: {
        params: { sort: 'createdAt-desc', page: 1, limit: 50 },
        records: [],
        metadata: {
          page: 1,
          limit: 50,
          page_count: 1,
          total_pages: 1,
          total_count: 1,
        },
      },
      testimonials: {
        params: { pageNum: 1, pageSize: 50 },
        data: [],
        pageNum: 1,
        pageSize: 50,
        totalPages: 1,
        totalSize: 1,
      },
    },
  },
  staffs: {
    staffs_list: {
      params: {
        sort: 'asc',
        page: 1,
        limit: 50,
        // role: 'admin',
        orderBy: 'createdAt',
      },
      records: [],
      metadata: {
        page: 1,
        limit: 50,
        page_count: 1,
        total_pages: 1,
        total_count: 1,
      },
    },

    staffs_detail: null,
  },
  users: {
    users_list: {
      params: { sort: 'createdAt-desc', page: 1, limit: 50 },
      record: [],
      metadata: {
        page: 1,
        limit: 50,
        page_count: 1,
        total_pages: 1,
        total_count: 1,
      },
    },
    users_detail: null,
  },
};

const accountsSlice = createSlice({
  name: 'accounts',
  initialState: initialAcountState,
  reducers: {
    setSitterListSuccess(
      state: AccountType,
      action: PayloadAction<SITTER_BODY_RESULT>
    ) {
      state.sitters.sitter_list = {
        ...state.sitters.sitter_list,
        ...action.payload,
      };
    },
    setSitterListParams(
      state: AccountType,
      action: PayloadAction<GET_SITTERS_PARAMS_REQUEST | null>
    ) {
      state.sitters.sitter_list.params = action.payload;
    },
    setSitterOptionListSuccess(
      state: AccountType,
      action: PayloadAction<SITTER_BODY_RESULT>
    ) {
      if (action.payload.metadata.page === 1) {
        state.sitters.sitter_option = {
          ...state.sitters.sitter_option,
          ...action.payload,
        };
      } else {
        state.sitters.sitter_option = {
          ...state.sitters.sitter_option,
          ...action.payload,
          records: [
            ...state.sitters.sitter_option.records,
            ...action.payload.records,
          ],
        };
      }
    },
    setSitterDetailSuccess(
      state: AccountType,
      action: PayloadAction<SITTER_DETAIL_TYPE | null>
    ) {
      state.sitters.sitter_detail = action.payload;
    },
    setParentListSuccess(
      state: AccountType,
      action: PayloadAction<LIST_BODY_RESULT<PARENT_TYPE>>
    ) {
      state.parents.parent_list = {
        ...state.parents.parent_list,
        ...action.payload,
      };
    },
    setParentListParams(
      state: AccountType,
      action: PayloadAction<GET_PARENTS_PARAMS_REQUEST | null>
    ) {
      state.parents.parent_list.params = action.payload;
    },
    setParentDetailSuccess(
      state: AccountType,
      action: PayloadAction<PARENT_DETAIL_TYPE | null>
    ) {
      state.parents.parent_detail = action.payload;
    },
    setParentRequestsParams(
      state: AccountType,
      action: PayloadAction<GET_REQUESTS_PARAMS_REQUEST | null>
    ) {
      state.parents.parent_detail_tab.requests.params = action.payload;
    },
    setParentRequestsSuccess(state: AccountType, action: PayloadAction<any>) {
      state.parents.parent_detail_tab.requests = {
        ...state.parents.parent_detail_tab.requests,
        ...action.payload,
      };
    },
    setStaffsListParams(
      state: AccountType,
      action: PayloadAction<GET_STAFFS_PARAMS_REQUEST | null>
    ) {
      state.staffs.staffs_list.params = action.payload;
    },
    setStaffsListSuccess(
      state: AccountType,
      action: PayloadAction<STAFFS_LIST_STATE>
    ) {
      state.staffs.staffs_list = {
        ...state.staffs.staffs_list,
        ...action.payload,
      };
    },
    setStaffsDetailSuccess(
      state: AccountType,
      action: PayloadAction<STAFFS_DETAIL_TYPE | null>
    ) {
      state.staffs.staffs_detail = action.payload;
    },
    setSitterListDetail(
      state: AccountType,
      action: PayloadAction<SITTERS_LIST_DETAIL_STATE>
    ) {
      state.sitters.sitter_list_detail = action.payload;
    },
    setSitterJobsParams(
      state: AccountType,
      action: PayloadAction<GET_JOBS_PARAMS_REQUEST | null>
    ) {
      state.sitters.sitter_list_detail.jobs.params = action.payload;
    },
    setSitterJobsSuccess(
      state: AccountType,
      action: PayloadAction<JOBS_BODY_RESULT>
    ) {
      state.sitters.sitter_list_detail.jobs = {
        ...state.sitters.sitter_list_detail.jobs,
        ...action.payload,
      };
    },
    setSitterTransactionsParams(
      state: AccountType,
      action: PayloadAction<GET_TRANSACTIONS_PARAMS_REQUEST | null>
    ) {
      state.sitters.sitter_list_detail.transactions.params = action.payload;
    },
    setSitterTransactionsSuccess(
      state: AccountType,
      action: PayloadAction<TRANSACTIONS_BODY_RESULT>
    ) {
      state.sitters.sitter_list_detail.transactions = {
        ...state.sitters.sitter_list_detail.transactions,
        ...action.payload,
      };
    },
    setParentDetailTabState(
      state: AccountType,
      action: PayloadAction<PARENT_DETAIL_TAB_STATE>
    ) {
      state.parents.parent_detail_tab = action.payload;
    },
    setParentPaymentHistoryParams(
      state: AccountType,
      action: PayloadAction<GET_PAYMENT_PARAMS_REQUEST>
    ) {
      state.parents.parent_detail_tab.paymentHistory.params = action.payload;
    },
    setParentPaymentHistorySuccess(
      state: AccountType,
      action: PayloadAction<PAYMENT_BODY_RESULT>
    ) {
      state.parents.parent_detail_tab.paymentHistory = {
        ...state.parents.parent_detail_tab.paymentHistory,
        ...action.payload,
      };
    },
    setSitterPendingPayoutParams(
      state: AccountType,
      action: PayloadAction<GET_PAYMENT_PARAMS_REQUEST>
    ) {
      state.sitters.sitter_list_detail.pendingPayout.params = action.payload;
    },
    setSitterPendingPayoutSuccess(
      state: AccountType,
      action: PayloadAction<PAYMENT_BODY_RESULT>
    ) {
      state.sitters.sitter_list_detail.pendingPayout = {
        ...state.sitters.sitter_list_detail.pendingPayout,
        ...action.payload,
      };
    },
    setSitterPayoutHistoryParams(
      state: AccountType,
      action: PayloadAction<GET_PAYMENT_PARAMS_REQUEST>
    ) {
      state.sitters.sitter_list_detail.payoutHistory.params = action.payload;
    },
    setSitterPayoutHistorySuccess(
      state: AccountType,
      action: PayloadAction<PAYMENT_BODY_RESULT>
    ) {
      state.sitters.sitter_list_detail.payoutHistory = {
        ...state.sitters.sitter_list_detail.payoutHistory,
        ...action.payload,
      };
    },
    setSitterChatsParams(
      state: AccountType,
      action: PayloadAction<GET_CHATS_PARAMS_REQUEST>
    ) {
      state.sitters.sitter_list_detail.chat.params = action.payload;
    },
    setSitterChatsSuccess(
      state: AccountType,
      action: PayloadAction<LIST_BODY_RESULT<CHAT_TYPE>>
    ) {
      state.sitters.sitter_list_detail.chat = {
        ...state.sitters.sitter_list_detail.chat,
        ...action.payload,
      };
    },
    setParentChatsParams(
      state: AccountType,
      action: PayloadAction<GET_CHATS_PARAMS_REQUEST>
    ) {
      state.parents.parent_detail_tab.chat.params = action.payload;
    },
    setParentChatsSuccess(
      state: AccountType,
      action: PayloadAction<LIST_BODY_RESULT<CHAT_TYPE>>
    ) {
      state.parents.parent_detail_tab.chat = {
        ...state.parents.parent_detail_tab.chat,
        ...action.payload,
      };
    },
    setParentAddressesParams(
      state: AccountType,
      action: PayloadAction<GET_ADDRESSES_PARENT_DETAIL_PARAMS_REQUEST>
    ) {
      state.parents.parent_detail_tab.addresses.params = action.payload;
    },
    setParentAddressesSuccess(
      state: AccountType,
      action: PayloadAction<LIST_BODY_RESULT<ADDRESS_PARENT_DETAIL_TYPE>>
    ) {
      state.parents.parent_detail_tab.addresses = {
        ...state.parents.parent_detail_tab.addresses,
        ...action.payload,
      };
    },
    setParentChildrenListParams(
      state: AccountType,
      action: PayloadAction<GET_CHILDREN_PARENT_DETAIL_TYPE_PARAMS_REQUEST>
    ) {
      state.parents.parent_detail_tab.children.params = action.payload;
    },
    setParentChildrenListSuccess(
      state: AccountType,
      action: PayloadAction<LIST_BODY_RESULT<CHILDREN_PARENT_DETAIL_TYPE>>
    ) {
      state.parents.parent_detail_tab.children = {
        ...state.parents.parent_detail_tab.children,
        ...action.payload,
      };
    },
    setParentTransactionsParams(
      state: AccountType,
      action: PayloadAction<GET_TRANSACTIONS_PARAMS_REQUEST>
    ) {
      state.parents.parent_detail_tab.transactions.params = action.payload;
    },
    setParentTransactionsSuccess(
      state: AccountType,
      action: PayloadAction<TRANSACTIONS_BODY_RESULT>
    ) {
      state.parents.parent_detail_tab.transactions = {
        ...state.parents.parent_detail_tab.transactions,
        ...action.payload,
      };
    },
    setParentJobsParams(
      state: AccountType,
      action: PayloadAction<GET_JOBS_PARAMS_REQUEST | null>
    ) {
      state.parents.parent_detail_tab.jobs.params = action.payload;
    },
    setParentJobsSuccess(
      state: AccountType,
      action: PayloadAction<JOBS_BODY_RESULT>
    ) {
      state.parents.parent_detail_tab.jobs = {
        ...state.parents.parent_detail_tab.jobs,
        ...action.payload,
      };
    },
    setSitterReviewsParams(
      state: AccountType,
      action: PayloadAction<GET_REVIEWS_PARAMS_REQUEST>
    ) {
      state.sitters.sitter_list_detail.reviews.params = action.payload;
    },
    setSitterReviewsSuccess(
      state: AccountType,
      action: PayloadAction<LIST_BODY_RESULT<REVIEW_TYPE>>
    ) {
      state.sitters.sitter_list_detail.reviews = {
        ...state.sitters.sitter_list_detail.reviews,
        ...action.payload,
      };
    },
    setUsersListParams(
      state: AccountType,
      action: PayloadAction<GET_USERS_PARAMS_REQUEST | null>
    ) {
      state.users.users_list.params = action.payload;
    },
    setUsersListSuccess(
      state: AccountType,
      action: PayloadAction<USERS_LIST_STATE>
    ) {
      state.users.users_list = {
        ...state.users.users_list,
        ...action.payload,
      };
    },
    setUsersDetailSuccess(
      state: AccountType,
      action: PayloadAction<USER_DETAIL_TYPE | null>
    ) {
      state.users.users_detail = action.payload;
    },
    setNannyListSuccess(state: AccountType, action: any) {
      state.nanny.nanny_list = {
        ...state.nanny.nanny_list,
        ...action.payload,
      };
    },
    setNannyListParams(
      state: AccountType,
      action: PayloadAction<GET_NANNY_PARAMS_REQUEST | null>
    ) {
      state.nanny.nanny_list.params = action.payload;
    },
    setNannyDetailSuccess(
      state: AccountType,
      action: PayloadAction<NANNY_DETAIL_TYPE | null>
    ) {
      state.nanny.nanny_detail = action.payload;
    },
    setNannyDetailTab(
      state: AccountType,
      action: PayloadAction<NANNY_DETAIL_TAB_STATE>
    ) {
      state.nanny.nanny_detail_tab = {
        ...state.nanny.nanny_detail_tab,
        ...action.payload,
      };
    },
    setNannyBookingSuccess(state: AccountType, action: PayloadAction<any>) {
      state.nanny.nanny_detail_tab.bookings = {
        ...state.nanny.nanny_detail_tab.bookings,
        ...action.payload,
      };
    },
    setNannyBookingParams(
      state: AccountType,
      action: PayloadAction<GET_NANNY_TAB_PARAMS_REQUEST | null>
    ) {
      state.nanny.nanny_detail_tab.bookings.params = action.payload;
    },
    setNannyPicturesSuccess(
      state: AccountType,
      action: PayloadAction<PICTURE_TAB_TYPE>
    ) {
      state.nanny.nanny_detail_tab.pictures = {
        ...state.nanny.nanny_detail_tab.pictures,
        ...action.payload,
      };
    },
    setNannyPicturesParams(
      state: AccountType,
      action: PayloadAction<GET_NANNY_TAB_PARAMS_REQUEST | null>
    ) {
      state.nanny.nanny_detail_tab.pictures.params = action.payload;
    },
    setNannyTestimonialsSuccess(
      state: AccountType,
      action: PayloadAction<TESTIMONIAL_TAB_TYPE>
    ) {
      state.nanny.nanny_detail_tab.testimonials = {
        ...state.nanny.nanny_detail_tab.testimonials,
        ...action.payload,
      };
    },
    setNannyTestimonialsParams(
      state: AccountType,
      action: PayloadAction<GET_NANNY_TAB_PARAMS_REQUEST | null>
    ) {
      state.nanny.nanny_detail_tab.testimonials.params = action.payload;
    },
    setNannyCertificateSuccess(
      state: AccountType,
      action: PayloadAction<CERTIFICATE_TAB_TYPE>
    ) {
      state.nanny.nanny_detail_tab.certificate = {
        ...state.nanny.nanny_detail_tab.certificate,
        ...action.payload,
      };
    },
    setNannyCertificateParams(
      state: AccountType,
      action: PayloadAction<GET_NANNY_TAB_PARAMS_REQUEST | null>
    ) {
      state.nanny.nanny_detail_tab.certificate.params = action.payload;
    },
    setNannyActivitySuccess(
      state: AccountType,
      action: PayloadAction<ACTIVITY_TAB_TYPE>
    ) {
      state.nanny.nanny_detail_tab.activity = {
        ...state.nanny.nanny_detail_tab.activity,
        ...action.payload,
      };
    },
    setNannyActivityParams(
      state: AccountType,
      action: PayloadAction<GET_ACTIVITY_TAB_PARAMS_REQUEST | null>
    ) {
      state.nanny.nanny_detail_tab.activity.params = action.payload;
    },
    setParentActivitySuccess(
      state: AccountType,
      action: PayloadAction<ACTIVITY_TAB_TYPE>
    ) {
      state.parents.parent_detail_tab.activity = {
        ...state.parents.parent_detail_tab.activity,
        ...action.payload,
      };
    },
    setParentActivityParams(
      state: AccountType,
      action: PayloadAction<GET_ACTIVITY_TAB_PARAMS_REQUEST | null>
    ) {
      state.parents.parent_detail_tab.activity.params = action.payload;
    },
  },
});

// Actions
export const {
  setParentActivitySuccess,
  setParentActivityParams,
  setNannyActivityParams,
  setNannyActivitySuccess,
  setSitterListSuccess,
  setSitterListParams,
  setSitterDetailSuccess,
  setParentListSuccess,
  setParentListParams,
  setParentDetailSuccess,
  setStaffsListParams,
  setStaffsListSuccess,
  setStaffsDetailSuccess,
  setParentRequestsParams,
  setParentRequestsSuccess,
  setSitterListDetail,
  setParentDetailTabState,
  setSitterJobsParams,
  setSitterJobsSuccess,
  setSitterTransactionsParams,
  setSitterTransactionsSuccess,
  setParentPaymentHistoryParams,
  setParentPaymentHistorySuccess,
  setSitterPendingPayoutParams,
  setSitterPendingPayoutSuccess,
  setSitterPayoutHistoryParams,
  setSitterPayoutHistorySuccess,
  setSitterChatsParams,
  setSitterChatsSuccess,
  setParentChatsParams,
  setParentChatsSuccess,
  setParentAddressesParams,
  setParentAddressesSuccess,
  setParentChildrenListParams,
  setParentChildrenListSuccess,
  setParentTransactionsParams,
  setParentTransactionsSuccess,
  setParentJobsParams,
  setParentJobsSuccess,
  setSitterReviewsParams,
  setSitterReviewsSuccess,
  setUsersListParams,
  setUsersListSuccess,
  setUsersDetailSuccess,
  setSitterOptionListSuccess,
  setNannyListSuccess,
  setNannyListParams,
  setNannyDetailSuccess,
  setNannyDetailTab,
  setNannyBookingSuccess,
  setNannyPicturesSuccess,
  setNannyBookingParams,
  setNannyPicturesParams,
  setNannyTestimonialsSuccess,
  setNannyTestimonialsParams,
  setNannyCertificateSuccess,
  setNannyCertificateParams,
} = accountsSlice.actions;

export default accountsSlice.reducer;
