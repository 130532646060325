import React from 'react';

const IconChecked = ({ ...props }) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9997 1.33333H3.99967C2.52692 1.33333 1.33301 2.52724 1.33301 4V12C1.33301 13.4728 2.52692 14.6667 3.99967 14.6667H11.9997C13.4724 14.6667 14.6663 13.4728 14.6663 12V4C14.6663 2.52724 13.4724 1.33333 11.9997 1.33333ZM11.061 6.30699C11.2306 6.08902 11.1913 5.77488 10.9733 5.60535C10.7553 5.43581 10.4412 5.47508 10.2717 5.69305L7.60034 9.12762C7.54093 9.204 7.4292 9.21391 7.35728 9.14918L5.66749 7.62838C5.46224 7.44365 5.14609 7.46028 4.96137 7.66554C4.77664 7.87079 4.79327 8.18694 4.99853 8.37167L6.68832 9.89248C7.19174 10.3456 7.97388 10.2762 8.38969 9.74157L11.061 6.30699Z"
        fill="#226FE3"
      />
    </svg>
  );
};

export default IconChecked;
