import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GET_REVIEW_LIST_PARAMS_REQUEST, REVIEWS_STATE } from 'models';

export interface ReviewsState {
  reviews: {
    reviews_by_admin: REVIEWS_STATE;
  };
}

const initialState: ReviewsState = {
  reviews: {
    reviews_by_admin: {
      rows: [],
      metadata: {
        page: 1,
        limit: 50,
        page_count: 1,
        total_pages: 1,
        total_count: 1,
      },
      params: { page: 1, limit: 50 },
    },
  },
};

const reviewsSlice = createSlice({
  name: 'reviews',
  initialState,
  reducers: {
    setReviewsListSuccess(
      state: ReviewsState,
      action: PayloadAction<REVIEWS_STATE>
    ) {
      state.reviews.reviews_by_admin = {
        ...state.reviews.reviews_by_admin,
        ...action.payload,
      };
    },
    setReviewsParams(
      state: ReviewsState,
      action: PayloadAction<GET_REVIEW_LIST_PARAMS_REQUEST | null>
    ) {
      state.reviews.reviews_by_admin.params = action.payload;
    },
  },
});

// Actions
export const { setReviewsListSuccess, setReviewsParams } = reviewsSlice.actions;

export default reviewsSlice.reducer;
