import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Collapse,
  useTheme,
} from '@mui/material';
import { IFormInputsSitter } from 'models';
import React, { Fragment } from 'react';
import { Controller, UseFormReturn, useWatch } from 'react-hook-form';
import Inputs from '../Inputs';

interface Props {
  fieldIndex: number;
  form: UseFormReturn<IFormInputsSitter, any>;
}

const GroupCheckbox = (props: Props) => {
  const { fieldIndex, form } = props;

  const theme = useTheme();

  const section = useWatch({
    name: `check_list_obj.${fieldIndex}`,
    control: form.control,
  });

  return (
    <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
      <FormLabel
        sx={{
          fontWeight: 500,
          fontSize: '15px',
          lineHeight: '100%',
          color: '#000',
          marginBottom: '5px',
          '&.Mui-focused': {
            color: '#000',
          },
        }}
      >
        {section.group_name}
      </FormLabel>
      <FormGroup>
        {section.items.map((item, index) => {
          return (
            <Fragment key={index}>
              <Controller
                name={`check_list_obj.${fieldIndex}.items.${index}.is_checked`}
                control={form.control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="secondary"
                        checked={field.value || false}
                        onChange={(e) => field.onChange(e.target.checked)}
                        name="none"
                      />
                    }
                    label={item.name}
                  />
                )}
              />

              {item.type === 'text' && (
                <Collapse
                  in={form.watch(
                    `check_list_obj.${fieldIndex}.items.${index}.is_checked`
                  )}
                >
                  <Inputs
                    form={form}
                    placeholder="Others"
                    name={`check_list_obj.${fieldIndex}.items.${index}.text`}
                    sx={{
                      width: '70%',
                      height: '48px',
                      bgcolor: theme.palette.background.light,
                    }}
                  />
                </Collapse>
              )}
            </Fragment>
          );
        })}
      </FormGroup>
    </FormControl>
  );
};

export default GroupCheckbox;
