import React from 'react';

const Calendar = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3334 13.5H9.66675C9.39341 13.5 9.16675 13.2733 9.16675 13C9.16675 12.7267 9.39341 12.5 9.66675 12.5H12.3334C12.6067 12.5 12.8334 12.7267 12.8334 13C12.8334 13.2733 12.6067 13.5 12.3334 13.5Z"
        fill="#226FE3"
      />
      <path
        d="M11.0001 14.8333C10.7268 14.8333 10.5001 14.6066 10.5001 14.3333V11.6666C10.5001 11.3933 10.7268 11.1666 11.0001 11.1666C11.2735 11.1666 11.5001 11.3933 11.5001 11.6666V14.3333C11.5001 14.6066 11.2735 14.8333 11.0001 14.8333Z"
        fill="#226FE3"
      />
      <path
        d="M8.1068 7.74671C8.0868 7.74671 8.07346 7.74671 8.05346 7.74671C8.02013 7.74004 7.97346 7.74004 7.93346 7.74671C6.00013 7.68671 4.54013 6.16671 4.54013 4.29337C4.53346 3.37337 4.89346 2.50671 5.5468 1.85337C6.20013 1.20004 7.0668 0.833374 7.99346 0.833374C9.90013 0.833374 11.4535 2.38671 11.4535 4.29337C11.4535 6.16671 9.99346 7.68004 8.1268 7.74671C8.12013 7.74671 8.11346 7.74671 8.1068 7.74671ZM7.99346 1.83337C7.33346 1.83337 6.72013 2.09337 6.25346 2.55337C5.79346 3.02004 5.54013 3.63337 5.54013 4.28671C5.54013 5.62004 6.58013 6.70004 7.9068 6.74004C7.9468 6.73337 8.03346 6.73337 8.12013 6.74004C9.43346 6.68004 10.4535 5.60671 10.4535 4.28671C10.4535 2.94004 9.3468 1.83337 7.99346 1.83337Z"
        fill="#226FE3"
      />
      <path
        d="M7.9934 15.04C6.6334 15.04 5.34673 14.6866 4.3734 14.0333C3.44673 13.4133 2.94006 12.5666 2.94006 11.6533C2.94006 10.74 3.4534 9.89995 4.3734 9.28662C6.36673 7.95329 9.60673 7.95329 11.6001 9.28662C11.8267 9.43995 11.8934 9.75329 11.7401 9.97995C11.5867 10.2133 11.2734 10.2733 11.0467 10.12C9.38673 9.01329 6.58673 9.01329 4.92673 10.12C4.28673 10.5466 3.94006 11.0866 3.94006 11.6533C3.94006 12.22 4.28673 12.7733 4.92673 13.2C5.7334 13.74 6.82006 14.0333 7.98673 14.0333C8.26006 14.0333 8.48673 14.26 8.48673 14.5333C8.48673 14.8066 8.26673 15.04 7.9934 15.04Z"
        fill="#226FE3"
      />
    </svg>
  );
};

export default Calendar;
