import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from '@mui/material';
import { EditParentIcon } from 'asset';
import { Layout } from 'components';
import PopupCreateOrEditNanny from 'components/Popups/Nanny/PopupCreateOrEditNanny';
import { drawerWidth } from 'config';
import { POPUP_ACTION, TYPE_POPUP } from 'models';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from 'store/hook';
import {
  initialAcountState,
  setNannyDetailSuccess,
  setNannyDetailTab,
} from 'store/reducers/accounts';
import { getNannyDetailAction } from 'store/reducers/accounts/actionTypes';
import Activity from './Activity';
import Booking from './Booking';
import Certificates from './Certificates';
import Pictures from './Pictures';
import Testimonials from './Testimonials';
import { DetailItem } from './styles';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nanny-detail-tabpanel-${index}`}
      aria-labelledby={`nanny-detail-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `nanny-detail-tab-${index}`,
    'aria-controls': `nanny-detail-tabpanel-${index}`,
  };
}

const tabs: { label: string; isDisable: boolean }[] = [
  {
    label: 'Booking',
    isDisable: false,
  },
  {
    label: 'Activity',
    isDisable: false,
  },
  {
    label: 'Pictures',
    isDisable: false,
  },
  {
    label: 'Certificate',
    isDisable: false,
  },
  {
    label: 'Testimonials',
    isDisable: false,
  },
];

export const NannyDetail = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { nannyId } = useParams();

  const [tabValue, setTabValue] = useState(0);
  const [popupAction, setPopupAction] = useState<POPUP_ACTION>();
  const { nanny_detail } = useAppSelector(
    (state) => state.accountReducer.nanny
  );

  const handleChangeTabValue = (_: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const onShowPopupAction = (type: TYPE_POPUP) => {
    setPopupAction({ type: type });
  };

  const onClosePopupAction = () => {
    setPopupAction(undefined);
  };

  const onReloadDetail = () => {
    dispatch(getNannyDetailAction({ data: { id: nannyId as any } }));
  };

  useEffect(() => {
    dispatch(getNannyDetailAction({ data: { id: nannyId as any } }));
    return () => {
      dispatch(setNannyDetailSuccess(null));
      dispatch(setNannyDetailTab(initialAcountState.nanny.nanny_detail_tab));
    };
  }, [nannyId, dispatch]);

  return (
    <Layout>
      <AppBar
        position="fixed"
        sx={{
          width: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`,
          backgroundColor: 'white.light',
          borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
        }}
        elevation={0}
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Grid>
            <Typography
              sx={{
                fontSize: '20px',
                lineHeight: 1,
                color: 'grey1.light',
              }}
            >
              Accounts / Nanny / {nannyId}
            </Typography>
          </Grid>
        </Toolbar>
      </AppBar>
      <Paper elevation={0} sx={{ p: '16px' }}>
        <Grid container justifyContent="space-between" pb="16px">
          <Button
            startIcon={<ArrowBackIosNewIcon />}
            sx={{
              p: 0,
              color: 'black.light',
              ':hover': {
                backgroundColor: 'white.light',
              },
            }}
            variant="text"
            onClick={() => navigate(`/accounts/nanny`)}
          >
            Back
          </Button>
          <Button
            startIcon={<EditParentIcon />}
            sx={{
              p: 0,
            }}
            onClick={() => onShowPopupAction(TYPE_POPUP.EDIT)}
          >
            Edit Nanny
          </Button>
        </Grid>
        <Box
          sx={{
            backgroundColor: 'rgba(34, 111, 227, 0.04)',
            padding: '21px 31px',
          }}
        >
          <Grid container rowSpacing={'8px'} columnSpacing={'70px'}>
            <DetailItem item>
              <Stack spacing={2} direction="row" alignItems="center">
                <Avatar
                  src={nanny_detail?.profilePicture}
                  sx={{
                    width: '40px',
                    height: '40px',
                    marginRight: '6px',
                  }}
                />
                <Stack spacing={0.5}>
                  <Typography
                    fontWeight={500}
                    lineHeight="14px"
                    fontSize={14}
                    letterSpacing="0.1px"
                    color="text.secondary"
                  >
                    Nanny Name (First+Last)
                  </Typography>
                  <Typography
                    fontWeight={400}
                    lineHeight="16px"
                    fontSize={14}
                    letterSpacing="0.25px"
                    color="black.light"
                  >
                    {(nanny_detail?.firstName &&
                      `${nanny_detail?.firstName} ${nanny_detail?.lastName}`) ||
                      'No data to display yet'}
                  </Typography>
                </Stack>
              </Stack>
            </DetailItem>

            <DetailItem item>
              <Typography
                fontWeight={500}
                lineHeight="14px"
                fontSize={14}
                letterSpacing="0.1px"
                color="text.secondary"
              >
                Email
              </Typography>
              <Typography
                fontWeight={400}
                lineHeight="16px"
                fontSize={14}
                letterSpacing="0.25px"
                color="black.light"
              >
                {nanny_detail?.email || 'No data to display yet'}
              </Typography>
            </DetailItem>
            <DetailItem item>
              <Typography
                fontWeight={500}
                lineHeight="14px"
                fontSize={14}
                letterSpacing="0.1px"
                color="text.secondary"
              >
                Phone number
              </Typography>
              <Typography
                fontWeight={400}
                lineHeight="16px"
                fontSize={14}
                letterSpacing="0.25px"
                color="black.light"
              >
                {(nanny_detail?.countryCode &&
                  `${nanny_detail?.countryCode} ${nanny_detail?.phoneNumber}`) ||
                  'No data to display yet'}
              </Typography>
            </DetailItem>
            <DetailItem item>
              <Typography
                fontWeight={500}
                lineHeight="14px"
                fontSize={14}
                letterSpacing="0.1px"
                color="text.secondary"
              >
                Ratings
              </Typography>
              <Typography
                fontWeight={400}
                lineHeight="16px"
                fontSize={14}
                letterSpacing="0.25px"
                color="black.light"
              >
                {nanny_detail?.averageStars.toFixed(1)}/5.0
              </Typography>
            </DetailItem>
          </Grid>
        </Box>
        <Grid
          container
          sx={{
            borderBottom: '1px solid  rgba(0, 0, 0, 0.08)',
            marginBottom: '16px',
          }}
        >
          <Tabs value={tabValue} onChange={handleChangeTabValue}>
            {tabs.map((item, index) => {
              return (
                <Tab
                  key={index}
                  label={item.label}
                  disabled={item.isDisable}
                  sx={{
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '125%',
                    letterSpacing: '0.1px',
                    color: '#959595',
                    textTransform: 'capitalize',
                  }}
                  {...a11yProps(index)}
                />
              );
            })}
          </Tabs>
        </Grid>
        <TabPanel value={tabValue} index={0}>
          <Booking nannyId={nannyId} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Activity nannyId={nannyId} />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <Pictures nannyId={nannyId} />
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <Certificates nannyId={nannyId} />
        </TabPanel>
        <TabPanel value={tabValue} index={4}>
          <Testimonials nannyId={nannyId} />
        </TabPanel>
      </Paper>
      {popupAction?.type === TYPE_POPUP.EDIT && (
        <PopupCreateOrEditNanny
          subject={nannyId}
          onClose={onClosePopupAction}
          onSuccess={onReloadDetail}
        />
      )}
    </Layout>
  );
};

export default NannyDetail;
