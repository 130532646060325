import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  Stack,
  Typography,
} from '@mui/material';
import { CheckCircle, CheckCircleEmpty, CloseCircle } from 'asset';
import {
  DateTimePicker,
  Inputs,
  SelectInput,
  TimerPicker,
} from 'components/form-control';
import {
  createRequestsApi,
  getParentAddressApi,
  getParentChildrenApi,
  getParentListApi,
  getRequestsDetailApi,
  UpdateRequestsApi,
} from 'services';
import PopupEditAddress from '../../PopupEditAddress';
import { theme } from 'theme';
import { DAY_OF_WEEK, OPTIONS_DATA_TYPE, TypeSR } from './data';
import Select, { GetOptionsResponse } from 'components/form-control/Select';
import {
  ADDRESS_DETAIL_PARENT_DETAIL_TYPE,
  ADDRESS_PARENT_DETAIL_TYPE,
  CHILDREN_PARENT_DETAIL_TYPE,
  GET_PARENTS_PARAMS_REQUEST,
  OptionItem,
  PARENT_TYPE,
  REQUESTS_DETAIL_TYPE,
  SREQUEST_STATUSES,
  SR_TYPE,
} from 'models';
import { changeAge, NotifyService } from 'helpers';
import yup from 'config/yup.custom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDateTime } from 'hooks';
import { LoadingPopup } from 'components/common';

interface Props {
  open: boolean;
  handleClosePopup?: any;
  onSuccess?: any;
  subject?: any;
  parentId?: any;
  type: string | undefined;
}

interface IFormInputs {
  id: string;
  parent?: {
    id: string;
    label: string;
  };
  children: {
    id: string;
    picture: string | null;
    age_range: number;
    full_name: string;
    is_checked: boolean;
  }[];
  selected_sitter_only: boolean;
  type: string;
  days_of_week?: any[];
  from_date: string;
  from_time: string;
  to_date: string;
  to_time: string;
  address?: OptionItem | null;
  status: OptionItem | null;
  special_notes1: string;
  special_notes2: string;
  special_notes3: string;
}

const defaultValues: IFormInputs = {
  id: '',
  parent: undefined,
  type: '',
  from_date: '',
  from_time: '',
  to_date: '',
  to_time: '',
  children: [],
  days_of_week: [],
  address: undefined,
  selected_sitter_only: false,
  status: null,
  special_notes1: '',
  special_notes2: '',
  special_notes3: '',
};

const PopupEditServiceRequest = (props: Props) => {
  const { open, handleClosePopup, subject, onSuccess, type } = props;

  const { moment } = useDateTime();
  const [openEditAddress, setOpenEditAddress] = useState(false);
  const [openAddAddress, setOpenAddAddress] = useState(false);
  const [typeReq, setTypeReq] = useState(type);
  const [days] = useState(DAY_OF_WEEK);
  const [requests, setRequests] = useState<REQUESTS_DETAIL_TYPE>();
  const [loadingPopup, setLoadingPopup] = useState(false);

  const schema = useMemo(() => {
    return yup.object({
      parent: yup.mixed().required('Parent is required'),
      type: yup.mixed().required('Type is required'),
      from_time: yup.string().required('From time is required'),
      from_date: yup.string().required('From date is required'),
      to_time: yup.string().required('To time is required'),
      to_date: yup.string().required('To date is required'),
      children: yup.mixed().notRequired(),
      days_of_week: yup.mixed().notRequired(),
      selected_sitter_only: yup.mixed().notRequired(),
      address: yup.mixed().required('Address is required'),
      // status: yup.string().required('Status is required'),
    });
  }, []);

  const form = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  const { reset, handleSubmit, control, watch, setValue } = form;

  const { fields: days_of_week } = useFieldArray({
    control,
    name: 'days_of_week',
  });

  const {
    fields: children,
    remove: removeChildren,
    append: appendChildren,
  } = useFieldArray({
    control,
    name: 'children',
  });

  const _handleClosePopup = () => {
    reset({ ...defaultValues });
    setRequests(undefined);
    handleClosePopup();
  };

  useEffect(() => {
    if (subject) {
      setLoadingPopup(true);
      const getDetail = async () => {
        await getRequestsDetailApi(subject)
          .then(({ data }) => {
            setRequests(data.data);
            setLoadingPopup(false);
          })
          .catch((e) => NotifyService.error(e))
          .finally(() => {});
      };
      getDetail();
    }

    // eslint-disable-next-line
  }, [subject]);

  useEffect(() => {
    if (requests?.type) setTypeReq(requests?.type);
  }, [requests]);

  const getAddressLabel = (data: {
    block_number: string;
    name: string;
    street: string;
    unit_number: string;
  }) => {
    return `${data?.block_number} ${data.name} ${data.street} ${data?.unit_number}`;
  };

  const onSuccessAddOrEditAddress = (
    data?: ADDRESS_DETAIL_PARENT_DETAIL_TYPE
  ) => {
    const address = form.getValues('address');
    if (address && address.id === data?.id) {
      form.setValue('address', { ...data, label: getAddressLabel(data) });
    }
  };

  useEffect(() => {
    if (!subject) {
      reset({
        type: typeReq === SR_TYPE.JUST_ONCE ? 'just_once' : typeReq,
        days_of_week: days.map((item) => ({
          ...item,
          isChecked: false,
        })),
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (requests) {
      const setFormData = async () => {
        const startDays = moment(requests.from_datetime).toISOString();
        const endDays = moment(requests.to_datetime).toISOString();

        reset({
          id: requests.number,
          from_time: startDays,
          from_date: startDays,
          to_time: endDays,
          to_date: endDays,
          address: requests.address
            ? {
                ...requests.address,
                label: getAddressLabel(requests.address),
              }
            : undefined,
          selected_sitter_only: requests.selected_sitter_only,
          status: SREQUEST_STATUSES.find((f) => f.id === requests.status),
          type: requests.type,
          parent: requests.parent
            ? {
                id: requests.parent.parent_id,
                label: `${requests.parent.first_name} ${requests.parent.last_name}`,
              }
            : undefined,
          children: [],
          days_of_week: requests.days_of_week
            ? days.map((item) => ({
                ...item,
                isChecked: !!requests.days_of_week.find(
                  (day) => day === item.nameData
                ),
              }))
            : days.map((item) => ({
                ...item,
                isChecked: false,
              })),
          special_notes1: requests.special_notes[0],
          special_notes2: requests.special_notes[1],
          special_notes3: requests.special_notes[2],
        });
      };
      setFormData();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requests]);

  const createRequest = async (type: string, data: any) => {
    await createRequestsApi(type, data)
      .then((r) => {
        onSuccess();
        _handleClosePopup();
        NotifyService.success(r);
      })
      .catch((e) => NotifyService.error(e))
      .finally(() => {
        setLoadingPopup(false);
      });
  };

  const editRequest = async (data: any) => {
    if (!subject) return;
    await UpdateRequestsApi(subject, data)
      .then((r) => {
        _handleClosePopup();
        onSuccess();
        NotifyService.success(r);
      })
      .catch((e) => NotifyService.error(e))
      .finally(() => {
        setLoadingPopup(false);
      });
  };

  const actionForm = (data: any) => {
    setLoadingPopup(true);
    if (subject) editRequest(data);
    else {
      if (typeReq === SR_TYPE.JUST_ONCE) {
        delete data.days_of_week;

        createRequest(SR_TYPE.JUST_ONCE, data);
      } else {
        createRequest(SR_TYPE.RECURRING, data);
      }
    }
  };

  const onSubmit = async (data: IFormInputs) => {
    if (subject) {
      const dataUpdate = {
        address_id: data.address?.id,
        type: data.type,
        days_of_week: data.days_of_week
          ?.filter((it) => it.isChecked)
          ?.map((item) => item.value),
        from_date: moment(data.from_date).format('YYYY-MM-DD'),
        from_time: moment(data.from_time).format('HH:mm'),
        to_date: moment(data.to_date).format('YYYY-MM-DD'),
        to_time: moment(data.to_time).format('HH:mm'),
        status: data.status?.id,
        children: data.children
          ?.filter((it) => it.is_checked)
          ?.map((item) => ({
            id: item.id,
            picture: item.picture,
            age_range: item.age_range,
            full_name: item.full_name,
          })),
        special_notes: {
          '0': data.special_notes1 ? data.special_notes1 : '',
          '1': data.special_notes2 ? data.special_notes2 : '',
          '2': data.special_notes3 ? data.special_notes3 : '',
        },
        selected_sitter_only: data.selected_sitter_only,
      };
      actionForm(dataUpdate);
    } else {
      const dataCreate = {
        parent_id: data.parent?.id,
        address_id: data.address?.id,
        days_of_week: data.days_of_week
          ?.filter((it) => it.isChecked)
          ?.map((item) => item.value),
        from_date: moment(data.from_date).format('YYYY-MM-DD'),
        from_time: moment(data.from_time).format('HH:mm'),
        to_date: moment(data.to_date).format('YYYY-MM-DD'),
        to_time: moment(data.to_time).format('HH:mm'),
        children: data.children
          ?.filter((it) => it.is_checked)
          ?.map((item) => ({
            id: item.id,
            picture: item.picture,
            age_range: item.age_range,
            full_name: item.full_name,
          })),
        special_notes: {
          '0': data.special_notes1 ? data.special_notes1 : '',
          '1': data.special_notes2 ? data.special_notes2 : '',
          '2': data.special_notes3 ? data.special_notes3 : '',
        },
        selected_sitter_only: data.selected_sitter_only,
      };
      actionForm(dataCreate);
    }
  };

  const parent = watch('parent');

  const getOptions =
    (key: keyof IFormInputs) =>
    async (page: number = 1, limit: number = 20, keyword?: string) => {
      let data: GetOptionsResponse | null = null;

      switch (key) {
        case 'parent':
          const paramsParent: GET_PARENTS_PARAMS_REQUEST = {
            page: page,
            limit: limit,
          };
          if (keyword) paramsParent.keyword = keyword;
          data = await getParentListApi(paramsParent)
            .then(({ data }) => {
              return {
                metadata: data.data.metadata,
                options: data.data.records.map((it: PARENT_TYPE) => ({
                  id: it.parent_id,
                  label: `${it.first_name} ${it.last_name} (${it.email})`,
                })),
              };
            })
            .catch((e) => {
              NotifyService.error(e);
              return null;
            });
          break;
        case 'address':
          if (parent?.id) {
            data = await getParentAddressApi(parent.id)
              .then(({ data }) => {
                return {
                  metadata: data.metadata,
                  options: data.records
                    .map((it: ADDRESS_PARENT_DETAIL_TYPE) => ({
                      id: it.id,
                      name: it.address_name,
                      street: it.street_name,
                      block_number: it.block_no,
                      unit_number: it.unit_no,
                      country: it.country,
                      postal_code: it.postal_code,
                    }))
                    .map((it: ADDRESS_DETAIL_PARENT_DETAIL_TYPE) => ({
                      ...it,
                      label: getAddressLabel(it),
                    })),
                };
              })
              .catch((e) => {
                NotifyService.error(e);
                return null;
              });
          }
          break;
      }

      return data;
    };

  useEffect(() => {
    if (parent) {
      const getChildren = async () => {
        const dataChildren: CHILDREN_PARENT_DETAIL_TYPE[] =
          await getParentChildrenApi(parent.id)
            .then(({ data }) => data.records)
            .catch((e) => {
              NotifyService.error(e);
              return [];
            });

        if (dataChildren.length) {
          removeChildren();
          appendChildren(
            dataChildren.map((item) => ({
              id: item.id,
              picture: item.picture,
              age_range: item.age_range,
              full_name: item.children_name,
              is_checked: !!requests?.children.find(
                (temp) => temp.id === item.id
              ),
            }))
          );
        }
      };
      getChildren();
    } else {
      removeChildren();
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parent]);

  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiPaper-root': {
          width: 523,
          borderRadius: 0,
          height: 'auto',
        },
      }}
    >
      <DialogTitle
        sx={{
          padding: '20px 25px',
        }}
      >
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '100%',
            }}
          >
            {subject ? 'Edit' : 'Create'} Request Details
          </Typography>
          <IconButton
            onClick={handleClosePopup}
            sx={{
              padding: 0,
            }}
          >
            <CloseCircle />
          </IconButton>
        </Grid>
      </DialogTitle>
      {loadingPopup && <LoadingPopup color="primary" />}
      <DialogContent
        sx={{
          p: '30px 24px 50px 26px',
        }}
        dividers
      >
        <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
          <Stack height="100%" justifyContent="space-between">
            <Stack spacing="16px">
              <Grid>
                <Inputs title="SR No." form={form} name="id" readOnly />
              </Grid>
              <Grid>
                <Stack direction="row" spacing="14px" alignItems="flex-start">
                  <Stack
                    sx={{
                      width: '50%',
                    }}
                  >
                    <Select
                      isDisabled={!!subject}
                      title="Parent"
                      name="parent"
                      form={form}
                      isHasMore
                      filterFunc={false}
                      getOptions={getOptions('parent')}
                      onChange={() => {
                        setValue('address', undefined);
                      }}
                    />
                  </Stack>
                  <Stack
                    sx={{
                      width: '50%',
                    }}
                  >
                    <SelectInput
                      title="Request Type"
                      form={form}
                      options={OPTIONS_DATA_TYPE}
                      name="type"
                      handleOnChange={(e: any) => {
                        if (e.target.value === TypeSR.just_once)
                          setTypeReq(SR_TYPE.JUST_ONCE);
                        else setTypeReq(SR_TYPE.RECURRING);
                      }}
                    />
                  </Stack>
                </Stack>
              </Grid>
              <Grid>
                <Stack direction="row" spacing="14px" alignItems="flex-end">
                  <Stack
                    sx={{
                      width: '50%',
                    }}
                  >
                    <DateTimePicker title="Date" form={form} name="from_date" />
                  </Stack>
                  <Typography height="30px" color="#545454">
                    -
                  </Typography>
                  <Stack
                    sx={{
                      width: '50%',
                    }}
                  >
                    <DateTimePicker form={form} name="to_date" />
                  </Stack>
                </Stack>
              </Grid>
              <Grid>
                <Stack direction="row" spacing="14px" alignItems="flex-end">
                  <Stack
                    sx={{
                      width: '50%',
                    }}
                  >
                    <TimerPicker title="Time" form={form} name="from_time" />
                  </Stack>
                  <Typography height="30px" color="#545454">
                    -
                  </Typography>
                  <Stack
                    sx={{
                      width: '50%',
                    }}
                  >
                    <TimerPicker form={form} name="to_time" />
                  </Stack>
                </Stack>
              </Grid>
              <Grid>
                <Select
                  title="Address"
                  name="address"
                  form={form}
                  isHasMore
                  filterFunc
                  refreshWhenOpen
                  getOptions={getOptions('address')}
                />
              </Grid>

              {requests && (
                <Box display="flex" sx={{ mt: '9px !important' }}>
                  <Button
                    sx={{
                      padding: '0px',
                      color: '#226fe3',
                      fontSize: '14px',
                      fontWeight: 400,
                      marginRight: '15px',
                    }}
                    onClick={() => setOpenEditAddress(true)}
                  >
                    Edit Address
                  </Button>
                  <Button
                    sx={{
                      padding: '0px',
                      color: '#226fe3',
                      fontSize: '14px',
                      fontWeight: 400,
                    }}
                    onClick={() => setOpenAddAddress(true)}
                  >
                    Add New Address
                  </Button>
                </Box>
              )}
              <Grid>
                <Controller
                  name="selected_sitter_only"
                  control={form.control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          checked={field.value || false}
                          onChange={(e) => field.onChange(e.target.checked)}
                          name="none"
                        />
                      }
                      label="Selected Sitter Only"
                    />
                  )}
                />
              </Grid>
              <Divider />
              <Stack spacing="16px">
                <Grid>
                  <Inputs
                    title="I Need A Sitter Because..."
                    form={form}
                    name="special_notes1"
                  />
                </Grid>
                <Grid>
                  <Inputs
                    title="My Child/Children Loves...."
                    form={form}
                    name="special_notes2"
                  />
                </Grid>
                <Grid>
                  <Inputs
                    title="I Appreciate When My Sitter..."
                    form={form}
                    name="special_notes3"
                  />
                </Grid>
              </Stack>
              <Divider />
              {typeReq === TypeSR.recurring && (
                <Grid>
                  <InputLabel
                    sx={{
                      fontWeight: 500,
                      fontSize: '15px',
                      lineHeight: '100%',
                      color: '#000',
                      marginBottom: '10px',
                    }}
                  >
                    Days of Week
                  </InputLabel>
                  <FormGroup aria-label="position">
                    <Stack direction="row" justifyContent="space-between">
                      {days_of_week.map((item, index) => (
                        <Controller
                          key={index}
                          name={`days_of_week.${index}.isChecked`}
                          control={form.control}
                          render={({ field }) => (
                            <FormControlLabel
                              {...field}
                              sx={{
                                fontSize: '14px',
                                lineHeight: '16px',
                                letterSpacing: '0.05px',
                                color: 'rgba(60, 60, 67, 0.6)',
                                '& .MuiTypography-root': {
                                  mt: '5px',
                                },
                              }}
                              value={item.value}
                              labelPlacement="bottom"
                              control={
                                <Checkbox
                                  icon={<CheckCircleEmpty />}
                                  checkedIcon={<CheckCircle />}
                                  checked={field.value ?? false}
                                  // onChange={(e) => handleCheckDays(e, item.value)}
                                  sx={{
                                    p: 0,
                                  }}
                                />
                              }
                              label={item.name}
                            />
                          )}
                        />
                      ))}
                    </Stack>
                  </FormGroup>
                </Grid>
              )}

              {subject && (
                <Grid>
                  <Select
                    title="Status"
                    name="status"
                    bindLabel="name"
                    form={form}
                    filterFunc={true}
                    options={SREQUEST_STATUSES}
                  />
                </Grid>
              )}
              <Grid>
                <InputLabel
                  sx={{
                    fontWeight: 500,
                    fontSize: '15px',
                    lineHeight: '100%',
                    color: '#000',
                    marginBottom: '10px',
                  }}
                >
                  Child
                </InputLabel>
                <FormGroup>
                  <Stack spacing="17px">
                    {children?.map((item, index) => {
                      const child = form.watch(`children.${index}`);
                      return (
                        <Stack
                          key={item.id}
                          direction="row"
                          alignItems="center"
                        >
                          <Controller
                            name={`children.${index}.is_checked`}
                            control={form.control}
                            render={({ field }) => (
                              <Checkbox
                                {...field}
                                checked={field.value ?? false}
                                sx={{
                                  '&.Mui-checked': {
                                    color: theme.palette.secondary.main,
                                  },
                                }}
                              />
                            )}
                          />
                          <Avatar
                            alt={child.full_name}
                            src={child.picture ?? ''}
                          />
                          <Stack ml="8px">
                            <Typography
                              sx={{
                                fontWeight: 600,
                                fontSize: '13px',
                                lineHeight: '20px',
                                letterSpacing: '0.1px',
                              }}
                            >
                              {child.full_name}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: '13px',
                                lineHeight: '20px',
                                letterSpacing: '0.1px',
                              }}
                            >
                              {changeAge(child.age_range)}
                            </Typography>
                          </Stack>
                        </Stack>
                      );
                    })}
                  </Stack>
                </FormGroup>
              </Grid>
            </Stack>

            <Stack
              spacing="8px"
              direction="row"
              justifyContent="space-between"
              mt="32px"
            >
              <Button
                variant="outlined"
                color="secondary"
                sx={{
                  width: '233.5px',
                  height: '30px',
                }}
                onClick={() => {
                  reset();
                  _handleClosePopup();
                }}
              >
                Cancel
              </Button>
              <Button
                color="secondary"
                variant="contained"
                sx={{
                  width: '233.5px',
                  height: '30px',
                }}
                type="submit"
              >
                {subject ? 'Update' : 'Create'}
              </Button>
            </Stack>
          </Stack>
        </form>
      </DialogContent>

      {requests && (openEditAddress || openAddAddress) && (
        <PopupEditAddress
          open
          isEdit={openEditAddress}
          subject={requests?.parent?.parent_id}
          idAddress={form.watch('address')?.id}
          handleClosePopup={() => {
            setOpenAddAddress(false);
            setOpenEditAddress(false);
          }}
          onSuccess={onSuccessAddOrEditAddress}
        />
      )}
    </Dialog>
  );
};

export default PopupEditServiceRequest;
