import moment from 'moment-timezone';
import { useEffect } from 'react';
import { useAppSelector } from 'store/hook';

export function useDateTime() {
  const convertLocalToConfig = (value: moment.MomentInput) => {
    return moment(moment(value).local().format('YYYY-MM-DDTHH:mm:ss.SSS'));
  };

  const formatDateTime = (
    value?: moment.MomentInput,
    format: string = 'DD MMM yyyy HH:mm',
    valFormat?: string
  ) => {
    if (!value) return;
    return moment(value, valFormat).format(format);
  };

  const toLocalDateTime = (value: moment.MomentInput) => {
    return moment(value).local();
  };

  return {
    moment,
    toLocalDateTime,
    formatDateTime,
    convertLocalToConfig,
  };
}

export function useInitDateTime() {
  const configs = useAppSelector((state) => state.settingsReducer.configs);

  useEffect(() => {
    moment.tz.setDefault(configs?.timezone || 'Asia/Singapore');
  }, [configs?.timezone]);
}
