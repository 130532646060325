import { AxiosResponse } from 'axios';
import {
  BOOKING_ACTIVITY_LOG,
  DATA_PAGINATION,
  GET_NANNY_BOOKING_LIST_PARAMS_REQUEST,
  NANNY_BOOKING_DETAIL_TYPE,
  NANNY_BOOKING_TYPE,
  NANNY_IN_BOOKING_TYPE,
  PayloadActionData,
  ResponseType,
} from 'models';
import { call, put, takeLatest } from 'redux-saga/effects';

import { PayloadAction } from '@reduxjs/toolkit';
import { NotifyService } from 'helpers';
import { nannyBookingApi } from 'services';
import { setGlobalLoading, setLoadingPopup } from 'store/reducers/global';
import {
  setActivityLogInBookingSuccess,
  setNanniesInBookingSuccess,
  setNannyBookingDetailSuccess,
  setNannyBookingListSuccess,
} from 'store/reducers/nanny-bookings';
import {
  DELETE_ACTIVITY_LOG_IN_BOOKING_REQUEST,
  DELETE_NANNY_BOOKING_REQUEST,
  GET_LIST_ACTIVITY_LOG_IN_BOOKING_REQUEST,
  GET_LIST_NANNY_IN_BOOKING_REQUEST,
  GET_NANNY_BOOKING_DETAIL_REQUEST,
  GET_NANNY_BOOKING_LIST_REQUEST,
} from 'store/reducers/nanny-bookings/actionTypes';

function* getNannyBookingListRequest(
  action: PayloadAction<{
    params?: GET_NANNY_BOOKING_LIST_PARAMS_REQUEST;
  }>
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<DATA_PAGINATION<NANNY_BOOKING_TYPE>> =
      yield call(nannyBookingApi.getAll, action.payload.params);
    yield put(setGlobalLoading(false));
    yield put(setNannyBookingListSuccess(response.data));
  } catch (error: any) {
    NotifyService.error(error);
    yield put(setGlobalLoading(false));
  }
}

function* getNannyBookingDetailRequest(
  action: PayloadAction<PayloadActionData<{ id: string }>>
) {
  if (action.payload.isPopup) yield put(setLoadingPopup(true));
  else yield put(setGlobalLoading(true));
  try {
    const response: AxiosResponse<ResponseType<NANNY_BOOKING_DETAIL_TYPE>> =
      yield call(nannyBookingApi.getDetail, action.payload.data.id);
    yield put(setNannyBookingDetailSuccess(response.data.data));
    if (action.payload.onSuccess) action.payload.onSuccess();
  } catch (error: unknown) {}
  if (action.payload.isPopup) yield put(setLoadingPopup(false));
  else yield put(setGlobalLoading(false));
}

function* deleteNannyBookingRequest(
  action: PayloadAction<{
    id: string;
    onSuccess?: () => void;
  }>
) {
  try {
    yield call(nannyBookingApi.delete, action.payload.id);
    if (action.payload.onSuccess) action.payload.onSuccess();
  } catch (error: unknown) {}
}

function* getNanniesInBookingRequest(
  action: PayloadAction<PayloadActionData<{ id: string }>>
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<ResponseType<NANNY_IN_BOOKING_TYPE[]>> =
      yield call(nannyBookingApi.getNanniesInBooking, action.payload.data.id);
    yield put(setNanniesInBookingSuccess(response.data.data));
    yield put(setGlobalLoading(false));
  } catch (error: unknown) {}
}

function* getListActivityLogInBookingRequest(
  action: PayloadAction<PayloadActionData<{ id: string }>>
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<ResponseType<BOOKING_ACTIVITY_LOG[]>> =
      yield call(
        nannyBookingApi.getActivityLogInBooking,
        action.payload.data.id
      );
    yield put(setActivityLogInBookingSuccess(response.data.data));
    yield put(setGlobalLoading(false));
  } catch (error: unknown) {}
}

function* deleteActivityLogInBookingRequest(
  action: PayloadAction<{
    id: string;
    onSuccess?: () => void;
  }>
) {
  try {
    yield call(nannyBookingApi.deleteActivityLogInBooking, action.payload.id);
    if (action.payload.onSuccess) action.payload.onSuccess();
  } catch (error: unknown) {}
}

function* nannyBookings() {
  yield takeLatest(GET_NANNY_BOOKING_LIST_REQUEST, getNannyBookingListRequest);
  yield takeLatest(
    GET_NANNY_BOOKING_DETAIL_REQUEST,
    getNannyBookingDetailRequest
  );
  yield takeLatest(DELETE_NANNY_BOOKING_REQUEST, deleteNannyBookingRequest);
  yield takeLatest(
    GET_LIST_NANNY_IN_BOOKING_REQUEST,
    getNanniesInBookingRequest
  );
  yield takeLatest(
    GET_LIST_ACTIVITY_LOG_IN_BOOKING_REQUEST,
    getListActivityLogInBookingRequest
  );
  yield takeLatest(
    DELETE_ACTIVITY_LOG_IN_BOOKING_REQUEST,
    deleteActivityLogInBookingRequest
  );
}

export default nannyBookings;
