import { HeadCell, PAYMENT_TYPE } from 'models';

export const headCells: readonly HeadCell<PAYMENT_TYPE>[] = [
  {
    id: 'timestamp',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'TXN Datetime',
  },
  {
    id: 'number',
    numeric: false,
    disablePadding: true,
    isSort: true,
    label: 'TXN No.',
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'TXN Type',
  },
  {
    id: 'user',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'User',
  },
  {
    id: 'total_amount',
    numeric: true,
    disablePadding: false,
    isSort: true,
    label: 'Total Amount',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Status',
  },
  {
    id: 'remark',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Phone Number',
  },
  {
    id: 'PayoutMethods',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Bank Account Name',
  },
  {
    id: 'last_name',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Bank Account Number',
  },
  {
    id: 'doc_ref_no',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Doc Ref No.',
  },
  {
    id: 'walletTransaction',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Wallet Ref.',
  },
];
