import { FilterOption, FilterOptionType, HeadCell, PARENT_TYPE } from 'models';

export const headCells: readonly HeadCell<PARENT_TYPE>[] = [
  {
    id: 'first_name',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Parent name',
  },
  {
    id: 'phone_number',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Phone number',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Status',
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Create On',
  },
];

export const filterOptions: FilterOption[] = [
  {
    name: 'First Name',
    key: 'first_name',
    type: FilterOptionType.TEXT,
    placeholder: 'Enter first name',
  },
  {
    name: 'Last Name',
    key: 'last_name',
    type: FilterOptionType.TEXT,
    placeholder: 'Enter last name',
  },
  {
    name: 'Email',
    key: 'email',
    type: FilterOptionType.TEXT,
    placeholder: 'Enter email',
  },
];
