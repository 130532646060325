import { FormHelperText, styled } from "@mui/material";


const ErrorMessage = styled(FormHelperText)(({ theme }) => ({
  fontSize: '16px',
  letterSpacing: '0.045em',
  color: theme.palette.white.light,
}))

ErrorMessage.defaultProps = { 
  error: true
}

export default ErrorMessage