import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { CloseCircle } from 'asset';
import { LoadingPopup } from 'components/common';
import { DateTimePicker, Inputs, Select } from 'components/form-control';
import { GetOptionsResponse } from 'components/form-control/Select';
import yup from 'config/yup.custom';
import { NotifyService } from 'helpers';
import { useDateTime } from 'hooks';
import {
  CREATE_OR_UPDATE_NANNY_BOOKING_TYPE,
  GET_PARENTS_PARAMS_REQUEST,
  OptionItem,
  PARENT_TYPE,
  bookingNannyStatuses,
  durationOptions,
  houseTypeOptions,
  singleTwinPregnancyOptions,
  yesNoOptions,
} from 'models';
import { memo, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { getParentListApi, nannyBookingApi } from 'services';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setNannyBookingDetailSuccess } from 'store/reducers/nanny-bookings';
import { getNannyBookingDetailAction } from 'store/reducers/nanny-bookings/actionTypes';
import { bookingParentStatuses } from '../../../../models/nannyBooking';

interface PopupCreateOrEditNannyBookingProps {
  subject?: any;
  cleanDetail?: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

interface IFormInputs {
  id?: string;
  parentId: OptionItem<string>;
  nannyStatus: OptionItem<string>;
  parentStatus: OptionItem<string>;
  edd: string; //dd-MM-yyyy
  commencementDate: string | null; //dd-MM-yyyy
  singleTwinPregnancy: OptionItem<string>;
  houseType: OptionItem<string>;
  pets: OptionItem<boolean>;
  duration: OptionItem<number>;
  otherRequirements: string;
}

const defaultValues: Partial<IFormInputs> = {
  otherRequirements: '',
};

export const PopupCreateOrEditNannyBooking = memo(
  ({
    subject,
    cleanDetail,
    onClose,
    onSuccess,
  }: PopupCreateOrEditNannyBookingProps) => {
    const { moment } = useDateTime();
    const { booking_detail } = useAppSelector(
      (state) => state.nannyBookingsReducer
    );
    const { loadingPopup } = useAppSelector((state) => state.globalReducer);
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState<boolean>(false);

    const schema = useMemo(() => {
      return yup.object().shape({
        parentId: yup
          .mixed()
          .required('Parent is required')
          .nullable(false)
          .typeError('Parent is required'),
        nannyStatus: yup
          .mixed()
          .required('Nanny status is required')
          .nullable(false)
          .typeError('Nanny status is required'),
        parentStatus: yup
          .mixed()
          .required('Parent status is required')
          .nullable(false)
          .typeError('Parent status is required'),
        edd: yup
          .mixed()
          .required('Expected due date is required')
          .nullable(false)
          .typeError('Expected due date is required'),
        singleTwinPregnancy: yup
          .mixed()
          .required('Single/Twin pregnancy is required')
          .nullable(false)
          .typeError('Single/Twin pregnancy is required'),
        houseType: yup
          .mixed()
          .required('Single/Multi-storey is required')
          .nullable(false)
          .typeError('Single/Multi-storey is required'),
        pets: yup
          .mixed()
          .required('Pets is required')
          .nullable(false)
          .typeError('Pets is required'),
        duration: yup
          .mixed()
          .required('Duration is required')
          .nullable(false)
          .typeError('Duration is required'),
        otherRequirements: yup.string().notRequired(),
      });
    }, []);

    const form = useForm<IFormInputs>({
      resolver: yupResolver(schema),
      mode: 'onChange',
      defaultValues: defaultValues,
    });

    const { reset, handleSubmit } = form;

    useEffect(() => {
      if (subject) {
        dispatch(
          getNannyBookingDetailAction({ data: { id: subject }, isPopup: true })
        );
      }
    }, [subject, dispatch]);

    useEffect(() => {
      if (subject && booking_detail) {
        reset({
          id: booking_detail.bookingId,
          parentId: {
            id: booking_detail.parentId,
            label: booking_detail.parentName,
          },
          nannyStatus: bookingNannyStatuses.find(
            (t) => t.id === booking_detail.nannyStatus
          ),
          parentStatus: bookingParentStatuses.find(
            (t) => t.id === booking_detail.parentStatus
          ),
          edd: moment(booking_detail.edd, 'DD-MM-YYYY').toISOString(),
          commencementDate: booking_detail.commencementDate
            ? moment(
                booking_detail.commencementDate,
                'DD-MM-YYYY'
              ).toISOString()
            : null,
          singleTwinPregnancy: singleTwinPregnancyOptions.find(
            (t) => t.id === booking_detail.singleTwinPregnancy
          ),
          houseType: houseTypeOptions.find(
            (t) => t.id === booking_detail.houseType
          ),
          pets: yesNoOptions.find((t) => t.id === booking_detail.pets),
          duration: durationOptions.find(
            (t) => t.id === booking_detail.duration
          ),
          otherRequirements: booking_detail.otherRequirements || '',
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subject, booking_detail]);

    const onSubmit = async (data: IFormInputs) => {
      setLoading(true);
      const _data: CREATE_OR_UPDATE_NANNY_BOOKING_TYPE = {
        parentId: data.parentId?.id,
        nannyStatus: data.nannyStatus?.id,
        parentStatus: data.parentStatus?.id,
        edd: moment(data.edd).format('DD-MM-YYYY'),
        commencementDate: data.commencementDate
          ? moment(data.commencementDate).format('DD-MM-YYYY')
          : null,
        singleTwinPregnancy: data.singleTwinPregnancy?.id,
        houseType: data.houseType?.id,
        pets: data.pets?.id,
        duration: data.duration?.id,
        otherRequirements: data.otherRequirements || '',
      };
      if (subject) {
        await nannyBookingApi
          .update(subject, _data)
          .then((r) => {
            NotifyService.success('Success');
            onSuccess();
            _onClose();
          })
          .catch((e) => NotifyService.error(e))
          .finally(() => {
            setLoading(false);
          });
      } else {
        await nannyBookingApi
          .create(_data)
          .then((r) => {
            NotifyService.success('Success');
            onSuccess();
            _onClose();
          })
          .catch((e) => NotifyService.error(e))
          .finally(() => {
            setLoading(false);
          });
      }
    };

    const _onClose = () => {
      onClose?.();
      if (cleanDetail) {
        dispatch(setNannyBookingDetailSuccess(null));
      }
    };

    const getOptions =
      (key: keyof IFormInputs) =>
      async (page: number = 1, limit: number = 20, keyword?: string) => {
        let data: GetOptionsResponse | null = null;

        switch (key) {
          case 'parentId':
            const paramsParent: GET_PARENTS_PARAMS_REQUEST = {
              page: page,
              limit: limit,
            };
            if (keyword) paramsParent.keyword = keyword;
            data = await getParentListApi(paramsParent)
              .then(({ data }) => {
                return {
                  metadata: data.data.metadata,
                  options: data.data.records.map((it: PARENT_TYPE) => ({
                    id: it.parent_id,
                    label: `${it.first_name} ${it.last_name}`,
                  })),
                };
              })
              .catch((e) => {
                NotifyService.error(e);
                return null;
              });
            break;
        }

        return data;
      };

    return (
      <Dialog
        open
        sx={{
          '& .MuiPaper-root': {
            width: 523,
            height: 'auto',
            borderRadius: 0,
          },
        }}
      >
        <DialogTitle
          sx={{
            padding: '20px 25px',
          }}
        >
          <Grid
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '16px',
                lineHeight: '100%',
              }}
            >
              {subject ? 'Edit' : 'Create'} Booking
            </Typography>
            <IconButton
              onClick={_onClose}
              sx={{
                padding: 0,
              }}
            >
              <CloseCircle />
            </IconButton>
          </Grid>
        </DialogTitle>
        {(loadingPopup || loading) && <LoadingPopup color="primary" />}
        <DialogContent
          sx={{
            p: '30px 24px 50px 26px',
          }}
          dividers
        >
          <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
            <Stack height="100%" justifyContent="space-between">
              <Stack spacing="16px">
                {!!subject && (
                  <Grid>
                    <Inputs title="Booking ID" name="id" form={form} readOnly />
                  </Grid>
                )}
                <Grid>
                  <Select
                    isDisabled={!!subject}
                    title="Parent"
                    name="parentId"
                    placeholder="Search Parent"
                    form={form}
                    isHasMore
                    filterFunc={false}
                    getOptions={getOptions('parentId')}
                  />
                </Grid>
                <Stack>
                  <Grid container spacing="14px">
                    <Grid item xs={6}>
                      <DateTimePicker
                        isClearable
                        title="Commencement Date"
                        form={form}
                        name="commencementDate"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <DateTimePicker
                        title="Expected Due Date"
                        form={form}
                        name="edd"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Select
                        title="Single/Twin Pregnancy"
                        name="singleTwinPregnancy"
                        form={form}
                        options={singleTwinPregnancyOptions}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Select
                        title="Duration"
                        name="duration"
                        form={form}
                        options={durationOptions}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Select
                        title="Single/Multi-storey"
                        name="houseType"
                        form={form}
                        options={houseTypeOptions}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Select
                        title="Pets"
                        name="pets"
                        form={form}
                        options={yesNoOptions}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Inputs
                        rows={6}
                        form={form}
                        title="Other Requirements"
                        placeholder="Other Requirements"
                        name="otherRequirements"
                      />
                    </Grid>
                  </Grid>
                </Stack>
                <Divider />
                <Stack>
                  <Grid container spacing="14px">
                    <Grid item xs={6}>
                      <Select
                        title="Parent Status"
                        name="parentStatus"
                        form={form}
                        options={bookingParentStatuses}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Select
                        title="Nanny Status"
                        name="nannyStatus"
                        form={form}
                        options={bookingNannyStatuses}
                      />
                    </Grid>
                  </Grid>
                </Stack>
              </Stack>
              <Typography
                sx={{
                  mt: 1,
                  fontWeight: 500,
                  fontSize: '10px',
                  lineHeight: '100%',
                  color: '#000',
                  marginBottom: '5px',
                }}
              >
                *If Parent Status is 'Confirmed', Nanny Status should also be
                'Confirmed'. This means that parent has chosen the nanny. All
                nannies should have status as 'Cancelled' while chosen Nanny
                should have 'Assigned'*
              </Typography>
              <Stack
                direction="row"
                justifyContent="space-between"
                marginTop="25px"
                spacing="8px"
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  sx={{
                    width: '233.5px',
                    height: '30px',
                  }}
                  onClick={_onClose}
                >
                  Cancel
                </Button>
                <Button
                  color="secondary"
                  variant="contained"
                  sx={{
                    width: '233.5px',
                    height: '30px',
                  }}
                  type="submit"
                >
                  {subject ? 'Update' : 'Create'}
                </Button>
              </Stack>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    );
  }
);

export default PopupCreateOrEditNannyBooking;
