import {
  Checkbox,
  Paper,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import usePagination from '@mui/material/usePagination/usePagination';
import {
  DeleteDialog,
  HeadTable,
  PopupDetailChat,
  TableCaption,
  ToggleField,
} from 'components';
import {
  ButtonDelete,
  ButtonDetail,
  TableCellNoData,
} from 'components/common/table';
import { useDateTime } from 'hooks';
import {
  CHAT_DETAIL_POPUP_TYPE,
  CHAT_TYPE,
  DELETE_DIALOG_TYPE,
  ECLOSE_POPUP,
  GET_CHATS_PARAMS_REQUEST,
} from 'models';
import { memo, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setParentChatsParams } from 'store/reducers/accounts';
import { getParentChatsRequest } from '../../../../../store/reducers/accounts/actionTypes';
import { headCells } from './data';

interface Props {
  parentId?: string;
}

const Chat = memo(({ parentId }: Props) => {
  const dispatch = useAppDispatch();

  const { formatDateTime } = useDateTime();

  const [selected, setSelected] = useState<readonly string[]>([]);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [openDetailPopup, setOpenDetailPopup] = useState(false);
  const [itemAction, setItemAction] = useState<CHAT_TYPE>();

  const { chat } = useAppSelector(
    (state) => state.accountReducer.parents.parent_detail_tab
  );

  const { params } = chat;

  const onSetListParams = (params: GET_CHATS_PARAMS_REQUEST) => {
    dispatch(setParentChatsParams(params));
  };

  useEffect(() => {
    if (parentId) {
      dispatch(getParentChatsRequest(parentId, params));
    }
  }, [params, parentId, dispatch]);

  const onReloadList = () => {
    if (!parentId) return;
    dispatch(getParentChatsRequest(parentId, params));
  };

  const handleChangeRowsPerPage = (event: SelectChangeEvent) => {
    const limit = parseInt(event.target.value, 10);
    onSetListParams({ ...params, page: 1, limit });
  };

  const { items } = usePagination({
    count: chat.metadata.total_pages,
    page: chat.metadata.page,
    onChange(_event, page) {
      onSetListParams({ ...params, page: page });
    },
  });

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = chat?.records?.map((n: any) => n?.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleShowDelete = (val: boolean) => {
    if (val === null) return;
    onSetListParams({ ...params, deleted: val });
  };

  const handleClick = (_: any, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const onClosePopup = (type: ECLOSE_POPUP) => {
    switch (type) {
      case ECLOSE_POPUP.DELETE:
        setOpenDeletePopup(false);
        setItemAction(undefined);
        break;
      case ECLOSE_POPUP.DETAIL:
        setOpenDetailPopup(false);
        setItemAction(undefined);
        break;
    }
  };

  const setOpenDelete = (event: any, data: CHAT_TYPE) => {
    event.stopPropagation();
    setItemAction(data);
    setOpenDeletePopup(true);
  };

  const setOpenDetail = (event: any, data: CHAT_TYPE) => {
    event.stopPropagation();
    setItemAction(data);
    setOpenDetailPopup(true);
  };

  return (
    <Stack spacing="8px">
      <Stack>
        <ToggleField
          value={params?.deleted || false}
          onChange={handleShowDelete}
          label="Show Deleted"
        />
      </Stack>
      <TableContainer
        component={Paper}
        elevation={0}
        sx={{
          border: '1px solid #D9D9D9',
          maxHeight: 'calc(100vh - 355px)',
        }}
      >
        <Table stickyHeader sx={{ minWidth: 650 }} aria-labelledby="tableTitle">
          <HeadTable<CHAT_TYPE>
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={chat?.records.length}
            headCells={headCells}
          />
          <TableBody
            sx={{
              height: 'auto !important',
            }}
          >
            {chat?.records?.length ? (
              chat.records.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-sitter-chat-cb-${index}`;
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    <TableCell
                      padding="checkbox"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                      }}
                    >
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        onChange={(e) => handleClick(e, row.id)}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                      }}
                    >
                      {row.id}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                      }}
                    >
                      {row.Sitter?.User?.first_name}&nbsp;
                      {row.Sitter?.User?.last_name}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                      }}
                    >
                      {formatDateTime(row.lastMessageAt)}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                      }}
                    >
                      {formatDateTime(row.deletedAt)}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                      }}
                    >
                      <Stack direction="row" spacing="10px">
                        <ButtonDetail onClick={(e) => setOpenDetail(e, row)} />
                        <ButtonDelete onClick={(e) => setOpenDelete(e, row)} />
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCellNoData colSpan={headCells.length + 2} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TableCaption
        rowsPerPage={params?.limit ?? 0}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        metadata={chat?.metadata}
        items={items}
      />
      {openDeletePopup && (
        <DeleteDialog
          name=""
          open={openDeletePopup}
          onClose={() => onClosePopup(ECLOSE_POPUP.DELETE)}
          subject={itemAction?.id}
          onSuccess={onReloadList}
          type={DELETE_DIALOG_TYPE.CHAT}
        />
      )}
      {openDetailPopup && (
        <PopupDetailChat
          open
          data={itemAction}
          type={CHAT_DETAIL_POPUP_TYPE.SITTER}
          handleClosePopup={() => onClosePopup(ECLOSE_POPUP.DETAIL)}
        />
      )}
    </Stack>
  );
});

export default Chat;
