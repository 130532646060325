import {
  FormHelperText,
  FormHelperTextProps,
  IconButton,
  InputAdornment,
  InputBase,
  InputBaseProps,
  InputLabelProps,
  Stack,
  StackProps,
  Typography,
} from '@mui/material';
import { EyeCloseInputs, EyeInputs, Pencil } from 'asset';
import { memo, useState } from 'react';
import { Control, useController } from 'react-hook-form';
import { StyledFormHelperText, StyledInputLabel } from './styles';

interface InputFieldProps extends InputBaseProps {
  title?: string;
  name?: string;
  control?: Control<any>;
  showEyes?: boolean;
  showCount?: boolean;
  numberCount?: number;
  errorsName?: any;
  hideHelper?: boolean;
  value?: any;
  showPopulate?: boolean;
  helperText?: string;
  rootProps?: StackProps;
  labelProps?: InputLabelProps;
  helperProps?: FormHelperTextProps;
  handlePopulate?: (value: string) => void;
}

const InputField = memo(
  ({
    type,
    title,
    control,
    name = '',
    showEyes,
    showCount,
    numberCount,
    rows,
    sx,
    errorsName,
    showPopulate,
    helperText,
    readOnly,
    hideHelper,
    labelProps,
    helperProps,
    rootProps,
    handlePopulate,
    value: externalValue,
    onChange: externalOnChange,
    ...rest
  }: InputFieldProps) => {
    const {
      field: { onChange, onBlur, value, ref },
      fieldState: { error },
    } = control
      ? // eslint-disable-next-line react-hooks/rules-of-hooks
        useController({ name, control })
      : {
          field: {
            onChange: externalOnChange as (...event: any[]) => void,
            value: externalValue,
            onBlur: undefined,
            ref: undefined,
          },
          fieldState: { error: undefined },
        };

    const [toggleEyes, setToggleEyes] = useState(false);

    const handleClick = () => {
      setToggleEyes(!toggleEyes);
    };

    return (
      <Stack {...rootProps}>
        {!!title && (
          <StyledInputLabel {...labelProps}>{title}</StyledInputLabel>
        )}
        <InputBase
          inputRef={ref}
          multiline={!!rows}
          rows={rows}
          value={value ?? ''}
          readOnly={readOnly}
          type={!toggleEyes ? type : 'text'}
          onBlur={onBlur}
          onChange={(e) => {
            if (control) externalOnChange?.(e);
            onChange(e);
          }}
          endAdornment={
            showEyes ? (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClick}
                >
                  {toggleEyes ? <EyeCloseInputs /> : <EyeInputs />}
                </IconButton>
              </InputAdornment>
            ) : showCount ? (
              <InputAdornment position="end">
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '15px',
                    lineHeight: '100%',
                    color: '#000',
                    opacity: 0.5,
                  }}
                >
                  {numberCount}/50
                </Typography>
              </InputAdornment>
            ) : showPopulate && !!value ? (
              <InputAdornment position="end">
                <IconButton
                  aria-label="Populate"
                  onClick={() => handlePopulate?.(value)}
                >
                  <Pencil fill={'black'} />
                </IconButton>
              </InputAdornment>
            ) : (
              ''
            )
          }
          sx={{
            color: '#000',
            fontSize: '13px',
            lineHeight: !!rows ? 'normal' : '100%',
            border: '1px solid #D5D5D5',
            p: '16px',
            width: '100%',
            height: !!rows ? 'auto' : '38px',
            background: readOnly ? '#eaeaea' : '#fff',
            borderRadius: '5px',
            padding: '13px 10px',
            ...sx,
          }}
          {...rest}
        />
        {!!helperText && (
          <FormHelperText sx={{ color: '#8E8E93', fontSize: '12px' }}>
            {helperText}
          </FormHelperText>
        )}
        {!hideHelper && (error || errorsName) && (
          <StyledFormHelperText error {...helperProps}>
            {errorsName || error?.message}
          </StyledFormHelperText>
        )}
      </Stack>
    );
  }
);

export default InputField;
