import {
  Button,
  Dialog,
  DialogActions,
  dialogClasses,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { IconCreate } from 'asset';
import React from 'react';

interface Props {
  open: boolean;
  title?: string;
  description?: string;
  onClose: () => void;
  onClick: any;
}

const PopupWarningGenPending = ({
  open = true,
  onClose,
  onClick,
  title,
  description,
}: Props) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        [`& .${dialogClasses.paper}`]: {
          maxWidth: '400px',
          width: '100%',
        },
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          py: '24px',
          display: 'flex',
          alignItems: 'center',
        }}
        component="div"
      >
        <IconCreate />
      </DialogTitle>
      <DialogContent
        sx={{
          padding: '0px 24px',
        }}
      >
        <Typography
          variant="Large_Body_18_SemiBold"
          sx={{
            mb: '8px',
          }}
        >
          {title}
        </Typography>
        <Typography variant="Small_Body_16_Regular">{description}</Typography>
      </DialogContent>
      <DialogActions
        sx={{
          padding: '24px',
        }}
      >
        <Button
          onClick={onClose}
          variant="outlined"
          sx={{
            border: '1px solid rgba(0, 0, 0, 0.08)',
            color: 'black.light',
            ':hover': {
              color: 'secondary.main',
              border: '1px solid rgba(0, 0, 0, 0.08)',
              bgcolor: 'transparent',
            },
            width: '100%',
          }}
        >
          No
        </Button>
        <Button
          autoFocus
          variant="contained"
          color="secondary"
          sx={{
            width: '100%',
          }}
          onClick={onClick}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PopupWarningGenPending;
