import {
  Button,
  Checkbox,
  Grid,
  Paper,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import usePagination from '@mui/material/usePagination/usePagination';
import { Eye } from 'asset';
import {
  DeleteDialog,
  HeadTable,
  PopupCreateOrEditNannyBooking,
  TableCaption,
} from 'components';
import {
  ButtonDelete,
  ButtonEdit,
  TableCellNoData,
} from 'components/common/table';
import { getSortItem } from 'helpers';
import { useDateTime } from 'hooks';
import {
  BOOKING_TYPE,
  DELETE_DIALOG_TYPE,
  ECLOSE_POPUP,
  GET_JOBS_PARAMS_REQUEST,
  bookingNannyStatusColor,
  bookingNannyStatusTitle,
} from 'models';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setParentJobsParams } from 'store/reducers/accounts';
import { getNannyBookingsAction } from 'store/reducers/accounts/actionTypes';
import { headCells } from './data';

interface Props {
  nannyId: string | undefined;
}
const Booking = ({ nannyId }: Props) => {
  const { formatDateTime } = useDateTime();
  const { bookings } = useAppSelector(
    (state) => state.accountReducer.nanny.nanny_detail_tab
  );

  const { params } = bookings;

  const dispatch = useAppDispatch();
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [itemAction, setItemAction] = useState<BOOKING_TYPE>();

  const onSetListParams = (params: GET_JOBS_PARAMS_REQUEST) => {
    dispatch(setParentJobsParams(params));
  };

  useEffect(() => {
    if (nannyId) {
      dispatch(getNannyBookingsAction(nannyId, params));
    }
  }, [params, nannyId, dispatch]);

  const onReloadList = () => {
    if (!nannyId) return;
    dispatch(getNannyBookingsAction(nannyId, params));
  };

  const handleChangeRowsPerPage = (event: SelectChangeEvent) => {
    const limit = parseInt(event.target.value, 10);
    onSetListParams({ ...params, page: 1, limit });
  };

  const { items } = usePagination({
    count: bookings.totalPages,
    page: bookings.pageNum,
    onChange(_event, page) {
      onSetListParams({ ...params, page: page });
    },
  });

  const sortItem = useMemo(() => {
    return getSortItem('');
  }, []);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = bookings?.data.map((n: any) => n?.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (_: any, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleRequestSort = (_: any, property: keyof BOOKING_TYPE) => {
    setSelected([]);
    const isAsc = sortItem?.orderBy === property && sortItem?.order === 'asc';
    onSetListParams({
      ...params,
      sort: `${property}-${isAsc ? 'desc' : 'asc'}`,
    });
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const onClosePopup = (type: ECLOSE_POPUP) => {
    switch (type) {
      case ECLOSE_POPUP.DELETE:
        setOpenDeletePopup(false);
        setItemAction(undefined);
        break;
      case ECLOSE_POPUP.CREATE_OR_EDIT:
        setOpenEditPopup(false);
        setItemAction(undefined);
        break;
    }
  };

  const setOpenDelete = (event: any, data: any) => {
    event.stopPropagation();
    setItemAction(data);
    setOpenDeletePopup(true);
  };

  const setOpenEdit = (event: any, data: any) => {
    event.stopPropagation();
    setItemAction(data);
    setOpenEditPopup(true);
  };

  return (
    <Stack spacing="8px">
      <TableContainer
        component={Paper}
        elevation={0}
        sx={{
          border: '1px solid #D9D9D9',
          maxHeight: 'calc(100vh - 355px)',
        }}
      >
        <Table stickyHeader sx={{ minWidth: 650 }} aria-labelledby="tableTitle">
          <HeadTable<BOOKING_TYPE>
            numSelected={selected.length}
            order={sortItem?.order}
            orderBy={sortItem?.orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={bookings?.data.length}
            headCells={headCells}
          />
          <TableBody
            sx={{
              height: 'auto !important',
            }}
          >
            {!!bookings?.data?.length ? (
              bookings?.data?.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    <TableCell
                      padding="checkbox"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                      }}
                    >
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        onChange={(e) => handleClick(e, row.id)}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                      }}
                    >
                      <Link
                        to={`/nanny-bookings/${row.nannyBooking.bookingId}`}
                      >
                        {row.nannyBooking.bookingId}
                      </Link>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                      }}
                    >
                      {formatDateTime(
                        row.nannyBooking.edd,
                        'DD MMM YYYY',
                        'DD-MM-YYYY'
                      )}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                      }}
                    >
                      {row.nannyBooking.duration}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                        minWidth: '110px',
                      }}
                    >
                      <Grid
                        container
                        alignItems="center"
                        textTransform="capitalize"
                      >
                        <Grid
                          sx={{
                            width: '8px',
                            height: '8px',
                            borderRadius: '50%',
                            marginRight: '5px',
                            backgroundColor:
                              bookingNannyStatusColor[
                                row.nannyBooking.nannyStatus
                              ] || '#B6B6B6',
                          }}
                        />
                        <Typography>
                          {bookingNannyStatusTitle[
                            row.nannyBooking.nannyStatus
                          ] || '-'}
                        </Typography>
                      </Grid>
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                      }}
                    >
                      ${row.nannyAskingAmount.toFixed(2)}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                      }}
                    >
                      <Stack direction="row" spacing="10px">
                        <Link
                          to={`/nanny-bookings/${row.nannyBooking.bookingId}`}
                        >
                          <Button
                            sx={{
                              minWidth: '38px !important',
                              height: '32px',
                              '&.MuiButton-root': {
                                p: '0px !important',
                              },
                            }}
                            variant="contained"
                            color="secondary"
                          >
                            <Eye />
                          </Button>
                        </Link>
                        <ButtonEdit onClick={(e) => setOpenEdit(e, row)} />
                        <ButtonDelete onClick={(e) => setOpenDelete(e, row)} />
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCellNoData colSpan={headCells.length + 2} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TableCaption
        rowsPerPage={params?.pageSize ?? 0}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        metadata={{
          page: bookings.pageNum,
          limit: bookings.pageSize,
          page_count: 1,
          total_pages: bookings.totalPages,
          total_count: bookings.totalSize,
        }}
        items={items}
      />
      {openDeletePopup && (
        <DeleteDialog
          name=""
          subject={itemAction?.nannyBooking.bookingId}
          open={openDeletePopup}
          onSuccess={onReloadList}
          type={DELETE_DIALOG_TYPE.NANNY_BOOKING}
          onClose={() => onClosePopup(ECLOSE_POPUP.DELETE)}
        />
      )}
      {openEditPopup && (
        <PopupCreateOrEditNannyBooking
          cleanDetail
          subject={itemAction?.nannyBooking.bookingId}
          onClose={() => onClosePopup(ECLOSE_POPUP.CREATE_OR_EDIT)}
          onSuccess={onReloadList}
        />
      )}
    </Stack>
  );
};

export default Booking;
