import {
  GET_APP_VERSION_LIST_PARAMS_REQUEST,
  GET_CERTIFICATES_PARAMS_REQUEST,
  GET_CHAT_KEYWORD_TRACKING_LIST_PARAMS_REQUEST,
  GET_COUNTRIES_PARAMS_REQUEST,
  GET_REASONS_LIST_PARAMS,
  GET_REGIONS_PARAMS_REQUEST,
} from 'models';

export const GET_REGIONS_LIST_REQUEST: string = 'GET_REGIONS_LIST_REQUEST';
export const DELETE_REGIONS_REQUEST: string = 'DELETE_REGIONS_REQUEST';

export const GET_REASON_LIST_REQUEST: string = 'GET_REASON_LIST_REQUEST';
export const DELETE_REASON_REQUEST: string = 'DELETE_REASON_REQUEST';

export const GET_BADGE_LIST_REQUEST: string = 'GET_BADGE_LIST_REQUEST';
export const DELETE_BADGE_REQUEST: string = 'DELETE_BADGE_REQUEST';

export const GET_COUNTRIES_LIST_REQUEST: string = 'GET_COUNTRIES_LIST_REQUEST';
export const DELETE_COUNTRY_REQUEST: string = 'DELETE_COUNTRY_REQUEST';

export const GET_CERTIFICATE_LIST_REQUEST: string =
  'GET_CERTIFICATE_LIST_REQUEST';
export const DELETE_CERTIFICATE_REQUEST: string = 'DELETE_CERTIFICATE_REQUEST';

export const GET_APP_VERSION_LIST_REQUEST: string =
  'GET_APP_VERSION_LIST_REQUEST';

export const GET_CHAT_KEYWORD_TRACKING_LIST_REQUEST: string =
  'GET_CHAT_KEYWORD_TRACKING_LIST_REQUEST';
export const DELETE_CHAT_KEYWORD_TRACKING_REQUEST: string =
  'DELETE_CHAT_KEYWORD_TRACKING_REQUEST';

export const GET_SETTING_CONFIGS_REQUEST: string =
  'GET_SETTING_CONFIGS_REQUEST';

export const getRegionListRequest = (
  params?: GET_REGIONS_PARAMS_REQUEST | null
) => {
  return {
    type: GET_REGIONS_LIST_REQUEST,
    payload: { params },
  };
};

export const deleteRegionRequest = (id: string, onSuccess?: () => void) => {
  return {
    type: DELETE_REGIONS_REQUEST,
    payload: {
      id,
      onSuccess,
    },
  };
};

export const getReasonListRequest = (
  params?: GET_REASONS_LIST_PARAMS | null
) => {
  return {
    type: GET_REASON_LIST_REQUEST,
    payload: { params },
  };
};

export const deleteReasonRequest = (id: string, onSuccess?: () => void) => {
  return {
    type: DELETE_REASON_REQUEST,
    payload: { id, onSuccess },
  };
};

export const getBadgeListRequest = () => {
  return {
    type: GET_BADGE_LIST_REQUEST,
  };
};

export const deleteBadgeRequest = (id: string, onSuccess?: () => void) => {
  return {
    type: DELETE_BADGE_REQUEST,
    payload: {
      id,
      onSuccess,
    },
  };
};

export const getAppVersionListRequest = (
  params?: GET_APP_VERSION_LIST_PARAMS_REQUEST | null
) => {
  return {
    type: GET_APP_VERSION_LIST_REQUEST,
    payload: { params },
  };
};

export const getChatKeywordTrackingListRequest = (
  params?: GET_CHAT_KEYWORD_TRACKING_LIST_PARAMS_REQUEST | null
) => {
  return {
    type: GET_CHAT_KEYWORD_TRACKING_LIST_REQUEST,
    payload: { params },
  };
};

export const deleteChatKeywordTrackingRequest = (
  id: string,
  onSuccess?: () => void
) => {
  return {
    type: DELETE_CHAT_KEYWORD_TRACKING_REQUEST,
    payload: { id, onSuccess },
  };
};

export const getCountryListRequest = (
  params?: GET_COUNTRIES_PARAMS_REQUEST | null
) => {
  return {
    type: GET_COUNTRIES_LIST_REQUEST,
    payload: { params },
  };
};

export const getSettingConfigsRequest = () => {
  return {
    type: GET_SETTING_CONFIGS_REQUEST,
  };
};

export const removeCountryFromBlackListRequest = (
  data: any,
  onSuccess?: () => void
) => {
  return {
    type: DELETE_COUNTRY_REQUEST,
    payload: { data, onSuccess },
  };
};

export const getCertificateListRequest = (
  params?: GET_CERTIFICATES_PARAMS_REQUEST | null
) => {
  return {
    type: GET_CERTIFICATE_LIST_REQUEST,
    payload: { params },
  };
};

export const deleteCertificateRequest = (payload: {
  id: string;
  onSuccess?: () => void;
}) => {
  return {
    type: DELETE_CERTIFICATE_REQUEST,
    payload,
  };
};
