import { Box, Divider, InputBase, inputBaseClasses, InputLabel } from '@mui/material';
import { INIT_COUNTRY_CODE } from 'config';
// import { splitNumber } from 'helpers';
import { Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import ErrorMessage from '../text/ErrorMessage';

interface PhoneNumberFieldProps {
  title: string;
  form: any;
  name: string;
  maxHeight?: number;
  errorsName?: string;
  marginBottom?: number;
}
const PhoneNumberField = (props: PhoneNumberFieldProps) => {
  const {
    form,
    name,
    title,
    errorsName,
  } = props;

  const {
    control,
    formState: { errors },
  } = form;

  // const [countryCode, setCountryCode] = useState<{
  //   code: string;
  //   name: string;
  // }>({ code: '+65', name: 'sg' });
  // const [number, setNumber] = useState('');

  // const handlePaste = (event: any, field: any) => {
  //   field.onChange(event.clipboardData.getData('text'));
  // };

  // const handleChange = (data: { code: string; name: string }) => {
  //   setCountryCode(data);
  // };

  const handleKeydown = (e: any) => {
    const invalidChars = ['-', '+', 'e', '.', 'E'];
    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <Box>
      {!!title && (
        <InputLabel
          sx={{
            fontWeight: 500,
            fontSize: '15px',
            lineHeight: '100%',
            color: '#000',
            marginBottom: '5px',
          }}
        >
          {title}
        </InputLabel>
      )}
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <Box
            p={0}
            sx={{
              position: 'relative',
              '& .flag': {
                display: 'none',
              },
            }}
          >
            <PhoneInput
              value={field?.value?.country_code || ''}
              onChange={(value, country: any) => {
                field.onChange({
                  country_code: `+${value}`,
                  phone_number: field.value?.phone_number
                })
              }}
              country={INIT_COUNTRY_CODE.name}
              specialLabel=""
              inputStyle={{
                border: '1px solid #D5D5D5',
                background: '#fff',
                borderRadius: 5,
                padding: '0px 11px',
                width: '100%',
                height: 38,
                fontSize: 13,
              }}
              buttonStyle={{
                paddingLeft: '9px',
              }}
              // buttonClass={styles.buttonClass}
              dropdownStyle={{
                width: '270px',
                marginLeft: '-10px',
                borderRadius: 0,
                padding: '0px 5px',
              }}
              searchStyle={{
                width: 230,
              }}
              searchClass={'aaaaa'}
              preferredCountries={['fr', 'us', 'gb', 'de']}
              countryCodeEditable={false}
            />
            <Box p={0}>
              <InputBase
                value={field.value?.phone_number || ''}
                onKeyDown={handleKeydown}
                sx={{
                  width: '86%',
                  lineHeight: '100%',
                  marginLeft: 'auto',
                  top: 0,
                  position: 'absolute',
                  right: 0,
                  overflow: 'hidden',
                  [`& .${inputBaseClasses.input}`]: {
                    padding: '10px 0px',
                    fontSize: 13,
                    height: 20,
                    '&::-webkit-outer-spin-button': {
                      WebkitAppearance: 'none',
                      margin: 0,
                    },
                    '&::-webkit-inner-spin-button': {
                      WebkitAppearance: 'none',
                      margin: 0,
                    },
                    MozAppearance: 'textfield',
                  },
                }}
                onChange={(e) => {
                  field.onChange({
                    country_code: !!field?.value?.country_code ? field?.value?.country_code : INIT_COUNTRY_CODE.code,
                    phone_number: e.target.value
                  })
                }}
                id={name}
                type="number"
              // onPaste={(e: any) => handlePaste(e, field)}
                onWheel={e => (e.target instanceof HTMLElement && (e.target as any).type === 'number') && e.target.blur()}
              />
              <Divider
                orientation="vertical"
                sx={{
                  height: '36px',
                  position: 'absolute',
                  top: 1,
                  left: 55,
                  width: '1px',
                }}
              />
            </Box>
          </Box>
        )}
      />
      {(!!errors?.[name]?.message || !!errors?.[name]?.country_code?.message || !!errors?.[name]?.phone_number?.message) ? (
        <ErrorMessage>{errors?.[name]?.message || errors?.[name]?.country_code?.message || errors?.[name]?.phone_number?.message || ''}</ErrorMessage>
      ) : (
        <ErrorMessage>{errorsName || ''}</ErrorMessage>
      )}
    </Box>
  );
};
export default PhoneNumberField;
