import {
  Box,
  Button,
  Checkbox,
  Paper,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import {
  DeleteDialog,
  HeadTable,
  PopupEditAddress,
  TableCaption,
} from 'components';
import React, { useState, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { getParentAddressesRequest } from 'store/reducers/accounts/actionTypes';
import usePagination from '@mui/material/usePagination/usePagination';
import {
  HeadCell,
  ADDRESS_PARENT_DETAIL_TYPE,
  ECLOSE_POPUP,
  GET_ADDRESSES_PARENT_DETAIL_PARAMS_REQUEST,
  DELETE_DIALOG_TYPE,
} from 'models';
import PopupDetailAddress from 'components/Popups/PopupDetailAddress';
import { setParentAddressesParams } from 'store/reducers/accounts';
import { getSortItem } from 'helpers';
import {
  ButtonDelete,
  ButtonDetail,
  ButtonEdit,
} from 'components/common/table';

const headCells: readonly HeadCell<ADDRESS_PARENT_DETAIL_TYPE>[] = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    isSort: true,
    label: 'ID',
  },
  {
    id: 'address_name',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Address Name',
  },
  {
    id: 'block_no',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Block No.',
  },
  {
    id: 'street_name',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Street Name',
  },
  {
    id: 'building_name',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Building Name',
  },
  {
    id: 'unit_no',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Unit No.',
  },
  {
    id: 'postal_code',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Postal Code',
  },
  {
    id: 'country',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Region',
  },
];

interface ServiceRequestProps {
  parentId: string | undefined;
}
const Addresses = ({ parentId }: ServiceRequestProps) => {
  const { addresses } = useAppSelector(
    (state) => state.accountReducer.parents.parent_detail_tab
  );

  const { params } = addresses;

  const dispatch = useAppDispatch();
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [openDetailPopup, setOpenDetailPopup] = useState(false);
  const [openCreatePopup, setOpenCreatePopup] = useState(false);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [itemAction, setItemAction] = useState<ADDRESS_PARENT_DETAIL_TYPE>();

  const onSetListParams = (
    params: GET_ADDRESSES_PARENT_DETAIL_PARAMS_REQUEST
  ) => {
    dispatch(setParentAddressesParams(params));
  };

  useEffect(() => {
    if (parentId) {
      dispatch(getParentAddressesRequest(parentId, params));
    }
  }, [params, parentId, dispatch]);

  const onReloadList = () => {
    if (!parentId) return;
    dispatch(getParentAddressesRequest(parentId, params));
  };

  const handleChangeRowsPerPage = (event: SelectChangeEvent) => {
    const limit = parseInt(event.target.value, 10);
    onSetListParams({ ...params, page: 1, limit });
  };

  const { items } = usePagination({
    count: addresses.metadata.total_pages,
    page: addresses.metadata.page,
    onChange(_event, page) {
      onSetListParams({ ...params, page: page });
    },
  });

  const sortItem = useMemo(() => {
    return getSortItem(params?.sort);
  }, [params]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = addresses?.records?.map((n: any) => n?.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (_: any, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleRequestSort = (
    _: any,
    property: keyof ADDRESS_PARENT_DETAIL_TYPE
  ) => {
    setSelected([]);
    const isAsc = sortItem?.orderBy === property && sortItem?.order === 'asc';
    onSetListParams({
      ...params,
      sort: `${property}-${isAsc ? 'desc' : 'asc'}`,
    });
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const onClosePopup = (type: ECLOSE_POPUP) => {
    switch (type) {
      case ECLOSE_POPUP.DELETE:
        setOpenDeletePopup(false);
        setItemAction(undefined);
        break;
      case ECLOSE_POPUP.CREATE_OR_EDIT:
        setOpenCreatePopup(false);
        setOpenEditPopup(false);
        setItemAction(undefined);
        break;
      case ECLOSE_POPUP.DETAIL:
        setOpenDetailPopup(false);
        setItemAction(undefined);
        break;
    }
  };

  const setOpenDelete = (event: any, data: ADDRESS_PARENT_DETAIL_TYPE) => {
    event.stopPropagation();
    setItemAction(data);
    setOpenDeletePopup(true);
  };

  const setOpenDetail = (event: any, data: ADDRESS_PARENT_DETAIL_TYPE) => {
    event.stopPropagation();
    setItemAction(data);
    setOpenDetailPopup(true);
  };

  const setOpenEdit = (event: any, data: ADDRESS_PARENT_DETAIL_TYPE) => {
    event.stopPropagation();
    setItemAction(data);
    setOpenEditPopup(true);
  };

  const setOpenCreate = () => {
    setOpenCreatePopup(true);
  };

  return (
    <>
      <Box display="flex" justifyContent="flex-end" mb={'8px'}>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            height: 30,
            width: '72px',
            fontWeight: 600,
            fontSize: '13px',
            lineHeight: '100%',
          }}
          onClick={setOpenCreate}
        >
          Create
        </Button>
      </Box>
      <Stack spacing="8px">
        <TableContainer
          component={Paper}
          elevation={0}
          sx={{
            border: '1px solid #D9D9D9',
            maxHeight: 'calc(100vh - 395px)',
          }}
        >
          <Table
            stickyHeader
            sx={{ minWidth: 650 }}
            aria-labelledby="tableTitle"
          >
            <HeadTable<ADDRESS_PARENT_DETAIL_TYPE>
              numSelected={selected.length}
              order={sortItem?.order}
              orderBy={sortItem?.orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={addresses?.records.length}
              headCells={headCells}
            />
            <TableBody
              sx={{
                height: 'auto !important',
              }}
            >
              {addresses?.records &&
                addresses?.records?.map((row: any, index: any) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell
                        padding="checkbox"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                        }}
                      >
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          onChange={(e) => handleClick(e, row.id)}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                          fontSize: '14px',
                          fontWeight: 400,
                        }}
                      >
                        {row.id}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                          fontSize: '14px',
                          fontWeight: 400,
                        }}
                      >
                        {row.address_name}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                          fontSize: '14px',
                          fontWeight: 400,
                        }}
                      >
                        {row.block_no}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                          fontSize: '14px',
                          fontWeight: 400,
                        }}
                      >
                        {row.street_name}
                      </TableCell>

                      <TableCell
                        align="left"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                          fontSize: '14px',
                          fontWeight: 400,
                        }}
                      >
                        {row.building_name}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                          fontSize: '14px',
                          fontWeight: 400,
                        }}
                      >
                        {row.unit_no}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                          fontSize: '14px',
                          fontWeight: 400,
                        }}
                      >
                        {row.postal_code}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                          fontSize: '14px',
                          fontWeight: 400,
                        }}
                      >
                        {row.region?.name}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                        }}
                      >
                        <Stack direction="row" spacing="10px">
                          <ButtonDetail
                            onClick={(e) => setOpenDetail(e, row)}
                          />
                          <ButtonEdit onClick={(e) => setOpenEdit(e, row)} />
                          <ButtonDelete
                            onClick={(e) => setOpenDelete(e, row)}
                          />
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TableCaption
          rowsPerPage={params?.limit ?? 0}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          metadata={addresses?.metadata}
          items={items}
        />
      </Stack>

      <DeleteDialog
        name=""
        open={openDeletePopup}
        onClose={() => onClosePopup(ECLOSE_POPUP.DELETE)}
        subject={parentId}
        onSuccess={onReloadList}
        type={DELETE_DIALOG_TYPE.DETAIL_PARENT}
        typeParent="addresses"
        idTypeParent={itemAction?.id}
      />
      {((itemAction && openEditPopup) || openCreatePopup) && (
        <PopupEditAddress
          open={true}
          handleClosePopup={() => onClosePopup(ECLOSE_POPUP.CREATE_OR_EDIT)}
          subject={parentId}
          onSuccess={onReloadList}
          idAddress={itemAction?.id}
          isEdit={openEditPopup}
        />
      )}
      {itemAction && openDetailPopup && (
        <PopupDetailAddress
          open={true}
          handleClosePopup={() => onClosePopup(ECLOSE_POPUP.DETAIL)}
          subject={parentId}
          idAddress={itemAction?.id}
        />
      )}
    </>
  );
};

export default Addresses;
