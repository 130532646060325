import { Stack, Box, Divider, Button } from "@mui/material"
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'config/yup.custom';
import { useForm } from 'react-hook-form';
import { useEffect, useMemo } from "react";
import { Inputs } from "components/form-control";
import { SETTING_KEY } from "models";
import { getSettingsJobsAndSittersApi, updateSettingsApi } from "services";
import { NotifyService } from "helpers";
import { useAppDispatch } from "store/hook";
import { getSettingConfigsRequest } from "store/reducers/settings/actionType";

interface IFormInputs {
  [SETTING_KEY.aunty_commission_rate]: number | string;
  [SETTING_KEY.job_completion_timeout]: number | string;
  [SETTING_KEY.sitter_review_timeout]: number | string;
  [SETTING_KEY.edit_review_timeout]: number | string;
  [SETTING_KEY.star_sitter_quota]: number | string;
}

const defaultValues: Partial<IFormInputs> = {
  [SETTING_KEY.aunty_commission_rate]: '',
  [SETTING_KEY.job_completion_timeout]: '',
  [SETTING_KEY.sitter_review_timeout]: '',
  [SETTING_KEY.edit_review_timeout]: '',
  [SETTING_KEY.star_sitter_quota]: ''
}

const JobsAndSitters = () => {

  const dispatch = useAppDispatch();
  
  const schema = useMemo(() => {
    return yup.object().shape({
      [SETTING_KEY.aunty_commission_rate]: yup.number()
        .typeError('This field must be a number')
        .empty()
        .notRequired(),
      [SETTING_KEY.job_completion_timeout]: yup.number()
        .typeError('This field must be a number')
        .empty()
        .notRequired(),
      [SETTING_KEY.sitter_review_timeout]: yup.number()
        .typeError('This field must be a number')
        .empty()
        .notRequired(),
      [SETTING_KEY.edit_review_timeout]: yup.number()
        .typeError('This field must be a number')
        .empty()
        .notRequired(),
      [SETTING_KEY.star_sitter_quota]: yup.number()
        .typeError('This field must be a number')
        .empty()
        .notRequired(),
    });
  }, []);

  const form = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  const {
    reset,
    handleSubmit,
  } = form;

  const fetchData = async () => {
    await getSettingsJobsAndSittersApi()
      .then(({ data: { data } }) => {
        reset({
          [SETTING_KEY.aunty_commission_rate]: data[SETTING_KEY.aunty_commission_rate],
          [SETTING_KEY.job_completion_timeout]: data[SETTING_KEY.job_completion_timeout],
          [SETTING_KEY.sitter_review_timeout]: data[SETTING_KEY.sitter_review_timeout],
          [SETTING_KEY.edit_review_timeout]: data[SETTING_KEY.edit_review_timeout],
          [SETTING_KEY.star_sitter_quota]: data[SETTING_KEY.star_sitter_quota]
        })
      })
      .catch(e => NotifyService.error(e))
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = async (value: IFormInputs) => {
    const data = {
      [SETTING_KEY.aunty_commission_rate]: value[SETTING_KEY.aunty_commission_rate] ?? '',
      [SETTING_KEY.job_completion_timeout]: value[SETTING_KEY.job_completion_timeout] ?? '',
      [SETTING_KEY.sitter_review_timeout]: value[SETTING_KEY.sitter_review_timeout] ?? '',
      [SETTING_KEY.edit_review_timeout]: value[SETTING_KEY.edit_review_timeout] ?? '',
      [SETTING_KEY.star_sitter_quota]: value[SETTING_KEY.star_sitter_quota] ?? ''
    }
    await updateSettingsApi(data)
      .then((r) => {
        fetchData()
        NotifyService.success(r)
        dispatch(getSettingConfigsRequest())
      })
      .catch((e) => NotifyService.error(e))
  }

  return (
    <Box onSubmit={handleSubmit(onSubmit)} component="form">
      <Stack sx={{ width: '475px' }} spacing="16px">
        <Stack>
          <Inputs
            title="Aunty Commission Rate"
            form={form}
            name={SETTING_KEY.aunty_commission_rate}
          />
        </Stack>
        <Divider />
        <Stack>
          <Inputs
            title="Job completion timeout (hours)"
            form={form}
            name={SETTING_KEY.job_completion_timeout}
            helperText="System will mark job as completed after the specified hours."
          />
        </Stack>
        <Stack>
          <Inputs
            title="Sitter Review Timeout (hours)"
            form={form}
            name={SETTING_KEY.sitter_review_timeout}
            helperText="System will disable review after the specified hours."
          />
        </Stack>
        <Stack>
          <Inputs
            title="Edit Review Timeout (hours)"
            form={form}
            name={SETTING_KEY.edit_review_timeout}
            helperText="Time when editing of review after posting will be disabled."
          />
        </Stack>
        <Divider />
        <Stack>
          <Inputs
            title="Star sitter quota ($ per payout)"
            form={form}
            name={SETTING_KEY.star_sitter_quota}
            helperText="Star is calculated per payout"
          />
        </Stack>
        <Divider />
        <Button
          fullWidth
          color="secondary"
          variant="contained"
          sx={{
            height: '30px',
          }}
          type="submit"
        >
          Save
        </Button>
      </Stack>
    </Box>
  )
}

export default JobsAndSitters