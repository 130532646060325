import { API } from 'config';
import {
  CREATE_NANNY_TESTIMONIAL_TYPE,
  CREATE_NANNY_TYPE,
  DATA_PAGINATION,
  GET_ACTIVITY_TAB_PARAMS_REQUEST,
  GET_NANNY_PARAMS_REQUEST,
  GET_NANNY_TAB_PARAMS_REQUEST,
  NANNY_DETAIL_TYPE,
  NANNY_TYPE,
  OptionItem,
  ResponseType,
  UPDATE_NANNY_CERTIFICATE_ASSOCIATION_TYPE,
  UPDATE_NANNY_CERTIFICATE_TYPE,
  UPDATE_NANNY_PICTURE_TYPE,
  UPDATE_NANNY_TESTIMONIAL_TYPE,
  UPDATE_NANNY_TYPE,
} from 'models';
import { AppAPIInstanceNanny } from 'services';

export const nannyApi = {
  getAll: async (params?: GET_NANNY_PARAMS_REQUEST) => {
    const _params = {
      ...params,
      status: params?.status?.id,
    };
    return await AppAPIInstanceNanny.get<DATA_PAGINATION<NANNY_TYPE>>(
      API.ADMIN.NANNY.ALL,
      { params: _params }
    );
  },
  add: async (data: CREATE_NANNY_TYPE) => {
    return await AppAPIInstanceNanny.post(API.ADMIN.NANNY.ADD, data);
  },
  update: async (data: UPDATE_NANNY_TYPE) => {
    return await AppAPIInstanceNanny.put(API.ADMIN.NANNY.UPDATE, data);
  },
  getDetail: async (id: string) => {
    return await AppAPIInstanceNanny.get<ResponseType<NANNY_DETAIL_TYPE>>(
      `${API.ADMIN.NANNY.DEFAULT}/${id}`
    );
  },
  approveBulk: async (nannyId: string[]) => {
    return await AppAPIInstanceNanny.put(API.ADMIN.NANNY.BULK_APPROVE, {
      nannyId,
    });
  },
  deleteBulk: async (nannyId: string[]) => {
    return await AppAPIInstanceNanny.put(API.ADMIN.NANNY.BULK_DELETE, {
      nannyId,
    });
  },
  uploadPicture: (id: string, file: File) => {
    const data = new FormData();
    data.append('id', id);
    data.append('file', file);
    return AppAPIInstanceNanny.post<ResponseType<any>>(
      API.ADMIN.NANNY.UPLOAD_PICTURE,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  },
};

export const nannyTabsApi = {
  getBookings: async (params?: GET_NANNY_TAB_PARAMS_REQUEST) => {
    return await AppAPIInstanceNanny.get(API.ADMIN.NANNY.BOOKING, {
      params,
    });
  },
  getActivity: async (params?: GET_ACTIVITY_TAB_PARAMS_REQUEST) => {
    return await AppAPIInstanceNanny.get(API.ADMIN.NANNY.ACTIVITY, {
      params,
    });
  },
  getPictures: async (params?: GET_NANNY_TAB_PARAMS_REQUEST) => {
    return await AppAPIInstanceNanny.get(API.ADMIN.NANNY.PICTURE, {
      params,
    });
  },
  deletePictures: async (id: string) => {
    return await AppAPIInstanceNanny.delete(API.ADMIN.NANNY.PICTURE, {
      data: { imageId: id },
    });
  },
  uploadPicture: async (id: string, data: UPDATE_NANNY_PICTURE_TYPE) => {
    const _data = new FormData();
    _data.append('id', id);
    _data.append('file', data.file);
    _data.append('caption', data.caption);
    return await AppAPIInstanceNanny.post(API.ADMIN.NANNY.PICTURE, _data);
  },
  getTestimonials: async (params?: GET_NANNY_TAB_PARAMS_REQUEST) => {
    return await AppAPIInstanceNanny.get(API.ADMIN.NANNY.TESTIMONIAL, {
      params,
    });
  },
  addTestimonials: async (data: CREATE_NANNY_TESTIMONIAL_TYPE) => {
    return await AppAPIInstanceNanny.post(API.ADMIN.NANNY.TESTIMONIAL, data);
  },
  updateTestimonials: async (data: UPDATE_NANNY_TESTIMONIAL_TYPE) => {
    return await AppAPIInstanceNanny.put(API.ADMIN.NANNY.TESTIMONIAL, data);
  },
  deleteTestimonials: async (id: string) => {
    return await AppAPIInstanceNanny.delete(API.ADMIN.NANNY.TESTIMONIAL, {
      data: { testimonialId: id },
    });
  },
  getCertificate: async (params?: GET_NANNY_TAB_PARAMS_REQUEST) => {
    return await AppAPIInstanceNanny.get(API.ADMIN.NANNY.CERTIFICATE, {
      params,
    });
  },
  deleteCertificate: async (id: string) => {
    return await AppAPIInstanceNanny.delete(API.ADMIN.NANNY.CERTIFICATE, {
      data: { certificateId: id },
    });
  },
  uploadCertificate: async (data: UPDATE_NANNY_CERTIFICATE_TYPE) => {
    const _data = new FormData();
    _data.append('title', data.title);
    _data.append('icon', data.icon);
    return await AppAPIInstanceNanny.post(API.ADMIN.NANNY.CERTIFICATE, _data);
  },
  getCertificateAssociation: async (params?: GET_NANNY_TAB_PARAMS_REQUEST) => {
    return await AppAPIInstanceNanny.get(
      API.ADMIN.NANNY.CERTIFICATE_ASSOCIATION,
      {
        params,
      }
    );
  },
  deleteCertificateAssociation: async (id: string) => {
    return await AppAPIInstanceNanny.delete(
      API.ADMIN.NANNY.CERTIFICATE_ASSOCIATION,
      {
        data: { certificateAssociationId: id },
      }
    );
  },
  uploadCertificateAssociation: async (
    data: UPDATE_NANNY_CERTIFICATE_ASSOCIATION_TYPE
  ) => {
    const _data = new FormData();
    _data.append('nannyId', data.nannyId);
    _data.append('certificateId', data.certificateId);
    _data.append('document', data.document);
    _data.append('documentDescription', data.documentDescription);
    return await AppAPIInstanceNanny.post(
      API.ADMIN.NANNY.CERTIFICATE_ASSOCIATION,
      _data
    );
  },
};

export const staticDataApi = {
  getEthnicity: async () => {
    return await AppAPIInstanceNanny.get<ResponseType<OptionItem<string>>>(
      API.ADMIN.NANNY.STATIC_DATA.ETHNICITY
    );
  },
  getNationality: async () => {
    return await AppAPIInstanceNanny.get<ResponseType<OptionItem<string>>>(
      API.ADMIN.NANNY.STATIC_DATA.NATIONALITY
    );
  },
};
