import styled from '@emotion/styled';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { theme } from 'theme';

export const ToggleButtonGroupStyled = styled(ToggleButtonGroup)`
  padding: 5px 10px;
  gap: 4px;
  border-radius: 5px;
  background: ${theme.palette.background.light};
`;
export const ToggleButtonStyled = styled(ToggleButton)`
  height: 20px;
  border-radius: 5px !important;
  background-color: ${theme.palette.grey6.light};
  border: none;
  text-transform: none;
  &.Mui-selected {
    background-color: ${theme.palette.primary.main} !important;
    color: ${theme.palette.white.light};
  }
`;
