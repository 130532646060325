import {
  Button,
  Dialog,
  DialogActions,
  dialogClasses,
  DialogContent,
  // DialogContentText,
  DialogTitle,
  Typography,
  useTheme,
} from '@mui/material';
import { IconCreate } from 'asset';
import { NotifyService } from 'helpers';
import React, { useState } from 'react';
import { updateJobStatusApi } from 'services/jobs';

interface Props {
  open: boolean;
  onClose: () => void;
  subject: any;
  onSuccess: any;
}

const PopupCompletedJob = ({
  open = true,
  onClose,
  subject,
  onSuccess,
}: Props) => {
  const onSuccessAccept = () => {
    onClose();
    onSuccess();
  };

  const [loading, setLoading] = useState<boolean>(false);

  const onClickConfirm = async () => {
    setLoading(true);
    await updateJobStatusApi(subject as string, {
      status: 'completed',
    })
      .then((r) => {
        onSuccessAccept();
        NotifyService.success(r);
      })
      .catch((e) => NotifyService.error(e))
      .finally(() => {
        setLoading(false);
      });
  };

  const theme = useTheme();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        [`& .${dialogClasses.paper}`]: {
          maxWidth: '400px',
          width: '100%',
        },
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          py: '24px',
          display: 'flex',
          alignItems: 'center',
        }}
        component="div"
      >
        <IconCreate />
      </DialogTitle>
      <DialogContent
        sx={{
          padding: '0px 24px',
        }}
      >
        <Typography
          variant="Large_Body_18_SemiBold"
          sx={{
            mb: '8px',
          }}
        >
          Mark as completed?
        </Typography>
        <Typography variant="Small_Body_16_Regular">
          Service fee will be credited to sitter
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          padding: '24px',
        }}
      >
        <Button
          onClick={onClose}
          variant="outlined"
          sx={{
            border: '1px solid rgba(0, 0, 0, 0.08)',
            color: 'black.light',
            ':hover': {
              color: 'secondary.main',
              border: '1px solid rgba(0, 0, 0, 0.08)',
              bgcolor: 'transparent',
            },
            width: '100%',
          }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          disabled={loading}
          color="secondary"
          sx={{
            border: `1px solid ${theme.palette.secondary.main}`,
            width: '100%',
          }}
          onClick={onClickConfirm}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PopupCompletedJob;
