import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {
  AppBar,
  Box,
  Button,
  Grid,
  Paper,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from '@mui/material';
import { EditParentIcon } from 'asset';
import { Layout, PopupCreateOrEditNannyBooking } from 'components';
import { drawerWidth } from 'config';
import { useDateTime } from 'hooks';
import {
  POPUP_ACTION,
  TYPE_POPUP,
  bookingNannyStatusTitle,
  bookingParentStatusTitle,
} from 'models';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from 'store/hook';
import {
  initialNannyBookingState,
  setNannyBookingDetailSuccess,
  setNannyBookingDetailTab,
} from 'store/reducers/nanny-bookings';
import { getNannyBookingDetailAction } from 'store/reducers/nanny-bookings/actionTypes';
import ActivityLogInBooking from './ActivityLogInBooking';
import NanniesInBooking from './NanniesInBooking';
import { DetailItem } from './styles';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nanny-booking-detail-tabpanel-${index}`}
      aria-labelledby={`nanny-booking-detail-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `nanny-booking-detail-tab-${index}`,
    'aria-controls': `nanny-booking-detail-tabpanel-${index}`,
  };
}

const tabs: string[] = ['Nanny', 'Activity Log'];

export const NannyBookingDetail = () => {
  const { formatDateTime } = useDateTime();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { bookingId } = useParams();

  const [tabValue, setTabValue] = useState(0);
  const [popupAction, setPopupAction] = useState<POPUP_ACTION>();
  const { booking_detail } = useAppSelector(
    (state) => state.nannyBookingsReducer
  );

  const handleChangeTabValue = (_: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const onShowPopupAction = (type: TYPE_POPUP) => {
    setPopupAction({ type: type });
  };

  const onClosePopupAction = () => {
    setPopupAction(undefined);
  };

  const onReloadDetail = () => {
    dispatch(getNannyBookingDetailAction({ data: { id: bookingId as any } }));
  };

  useEffect(() => {
    dispatch(getNannyBookingDetailAction({ data: { id: bookingId as any } }));
    return () => {
      dispatch(setNannyBookingDetailSuccess(null));
      dispatch(
        setNannyBookingDetailTab(initialNannyBookingState.booking_detail_tab)
      );
    };
  }, [bookingId, dispatch]);

  return (
    <Layout>
      <AppBar
        position="fixed"
        sx={{
          width: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`,
          backgroundColor: 'white.light',
          borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
        }}
        elevation={0}
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Grid>
            <Typography
              sx={{
                fontSize: '20px',
                lineHeight: 1,
                color: 'grey1.light',
              }}
            >
              Booking / {bookingId}
            </Typography>
          </Grid>
        </Toolbar>
      </AppBar>
      <Paper elevation={0} sx={{ p: '16px' }}>
        <Grid container justifyContent="space-between" pb="16px">
          <Button
            startIcon={<ArrowBackIosNewIcon />}
            sx={{
              p: 0,
              color: 'black.light',
              ':hover': {
                backgroundColor: 'white.light',
              },
            }}
            variant="text"
            onClick={() => navigate(`/nanny-bookings`)}
          >
            Back
          </Button>
          <Button
            startIcon={<EditParentIcon />}
            sx={{
              p: 0,
            }}
            onClick={() => onShowPopupAction(TYPE_POPUP.EDIT)}
          >
            Edit Booking
          </Button>
        </Grid>
        <Box
          sx={{
            backgroundColor: 'rgba(34, 111, 227, 0.04)',
            padding: '21px 31px',
          }}
        >
          <Grid container rowSpacing={'8px'} columnSpacing={'70px'}>
            <DetailItem item>
              <Typography
                fontWeight={500}
                lineHeight="14px"
                fontSize={14}
                letterSpacing="0.1px"
                color="text.secondary"
              >
                Booking ID
              </Typography>
              <Typography
                fontWeight={400}
                lineHeight="16px"
                fontSize={14}
                letterSpacing="0.25px"
                color="black.light"
              >
                {booking_detail?.bookingId}
              </Typography>
            </DetailItem>
            <DetailItem item>
              <Typography
                fontWeight={500}
                lineHeight="14px"
                fontSize={14}
                letterSpacing="0.1px"
                color="text.secondary"
              >
                Commencement Date
              </Typography>
              <Typography
                fontWeight={400}
                lineHeight="16px"
                fontSize={14}
                letterSpacing="0.25px"
                color="black.light"
              >
                {formatDateTime(
                  booking_detail?.commencementDate,
                  'DD MMM YYYY',
                  'DD-MM-YYYY'
                )}
              </Typography>
            </DetailItem>
            <DetailItem item>
              <Typography
                fontWeight={500}
                lineHeight="14px"
                fontSize={14}
                letterSpacing="0.1px"
                color="text.secondary"
              >
                Expected Due Date
              </Typography>
              <Typography
                fontWeight={400}
                lineHeight="16px"
                fontSize={14}
                letterSpacing="0.25px"
                color="black.light"
              >
                {formatDateTime(
                  booking_detail?.edd,
                  'DD MMM YYYY',
                  'DD-MM-YYYY'
                )}
              </Typography>
            </DetailItem>
            <DetailItem item>
              <Typography
                fontWeight={500}
                lineHeight="14px"
                fontSize={14}
                letterSpacing="0.1px"
                color="text.secondary"
              >
                Single/Twin
              </Typography>
              <Typography
                fontWeight={400}
                lineHeight="16px"
                fontSize={14}
                letterSpacing="0.25px"
                color="black.light"
              >
                {booking_detail?.singleTwinPregnancy}
              </Typography>
            </DetailItem>
            <DetailItem item>
              <Typography
                fontWeight={500}
                lineHeight="14px"
                fontSize={14}
                letterSpacing="0.1px"
                color="text.secondary"
              >
                Single/Multi-storey
              </Typography>
              <Typography
                fontWeight={400}
                lineHeight="16px"
                fontSize={14}
                letterSpacing="0.25px"
                color="black.light"
              >
                {booking_detail?.houseType}
              </Typography>
            </DetailItem>
            <DetailItem item>
              <Typography
                fontWeight={500}
                lineHeight="14px"
                fontSize={14}
                letterSpacing="0.1px"
                color="text.secondary"
              >
                Duration (days)
              </Typography>
              <Typography
                fontWeight={400}
                lineHeight="16px"
                fontSize={14}
                letterSpacing="0.25px"
                color="black.light"
              >
                {booking_detail?.duration}
              </Typography>
            </DetailItem>
            {/* <DetailItem item>
              <Typography
                fontWeight={500}
                lineHeight="14px"
                fontSize={14}
                letterSpacing="0.1px"
                color="text.secondary"
              >
                Amount Paid
              </Typography>
              <Typography
                fontWeight={400}
                lineHeight="16px"
                fontSize={14}
                letterSpacing="0.25px"
                color="black.light"
              >
                $0.00
              </Typography>
            </DetailItem>
            <DetailItem item>
              <Typography
                fontWeight={500}
                lineHeight="14px"
                fontSize={14}
                letterSpacing="0.1px"
                color="text.secondary"
              >
                Total Amount
              </Typography>
              <Typography
                fontWeight={400}
                lineHeight="16px"
                fontSize={14}
                letterSpacing="0.25px"
                color="black.light"
              >
                $0.00
              </Typography>
            </DetailItem> */}
            <DetailItem item>
              <Typography
                fontWeight={500}
                lineHeight="14px"
                fontSize={14}
                letterSpacing="0.1px"
                color="text.secondary"
              >
                Parent Status
              </Typography>
              <Typography
                fontWeight={400}
                lineHeight="16px"
                fontSize={14}
                letterSpacing="0.25px"
                color="black.light"
              >
                {!!booking_detail &&
                  bookingParentStatusTitle[booking_detail.parentStatus]}
              </Typography>
            </DetailItem>
            <DetailItem item>
              <Typography
                fontWeight={500}
                lineHeight="14px"
                fontSize={14}
                letterSpacing="0.1px"
                color="text.secondary"
              >
                Nanny Status
              </Typography>
              <Typography
                fontWeight={400}
                lineHeight="16px"
                fontSize={14}
                letterSpacing="0.25px"
                color="black.light"
              >
                {!!booking_detail &&
                  bookingNannyStatusTitle[booking_detail.nannyStatus]}
              </Typography>
            </DetailItem>
            <DetailItem item>
              <Typography
                fontWeight={500}
                lineHeight="14px"
                fontSize={14}
                letterSpacing="0.1px"
                color="text.secondary"
              >
                Other Requirements
              </Typography>
              <Typography
                fontWeight={400}
                lineHeight="16px"
                fontSize={14}
                letterSpacing="0.25px"
                color="black.light"
              >
                {booking_detail?.otherRequirements}
              </Typography>
            </DetailItem>
          </Grid>
        </Box>
        <Grid
          container
          sx={{
            borderBottom: '1px solid  rgba(0, 0, 0, 0.08)',
            marginBottom: '16px',
          }}
        >
          <Tabs value={tabValue} onChange={handleChangeTabValue}>
            {tabs.map((item, index) => {
              return (
                <Tab
                  key={index}
                  label={item}
                  sx={{
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '125%',
                    letterSpacing: '0.1px',
                    color: '#959595',
                    textTransform: 'capitalize',
                  }}
                  {...a11yProps(index)}
                />
              );
            })}
          </Tabs>
        </Grid>
        <TabPanel value={tabValue} index={0}>
          <NanniesInBooking bookingId={bookingId as string} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <ActivityLogInBooking bookingId={bookingId as string} />
        </TabPanel>
      </Paper>
      {popupAction?.type === TYPE_POPUP.EDIT && (
        <PopupCreateOrEditNannyBooking
          subject={bookingId}
          onClose={onClosePopupAction}
          onSuccess={onReloadDetail}
        />
      )}
    </Layout>
  );
};

export default NannyBookingDetail;
