import {
  AppBar,
  Button,
  buttonClasses,
  Checkbox,
  Grid,
  InputAdornment,
  InputBase,
  inputBaseClasses,
  Menu,
  Paper,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import {
  Check,
  CloseCircle,
  DirectionDown,
  DocumentDownload,
  Eye,
  Magnifier,
  PendingPayoutsIcon,
} from 'asset';
import {
  DeleteDialog,
  FilterMenu,
  HeadTable,
  Layout,
  PopupCreateOrEditTopupPayout,
  TableCaption,
} from 'components';
import { drawerWidth } from 'config';
import React, { useState, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hook';
import usePagination from '@mui/material/usePagination/usePagination';
import {
  cashTXTStatusColor,
  DELETE_DIALOG_TYPE,
  FilterOption,
  FilterOptionType,
  FilterValue,
  GET_PAYMENT_PARAMS_REQUEST,
  CASH_TRANSACTION_CODE,
  CASH_TRANSACTION_STATUS,
  PAYMENT_TYPE,
  TYPE_POPUP,
} from 'models';
import { getSortItem, NotifyService, printPrice } from 'helpers';
import DeleteAllDialog from 'components/common/DeleteAllDialog';
import useDebounce from 'hooks/useDebounce';
import { headCells } from './data';
import { ButtonDelete, ButtonEdit } from 'components/common/table';
import { getPayoutsRequest } from 'store/reducers/payment/actionTypes';
import { initialPaymentState, setPayoutsParams } from 'store/reducers/payment';
import PopupDetailPayment from 'components/Popups/Transaction/PopupDetailPayment';
import {
  deleteAllPaymentApi,
  generatePendingPayoutApi,
  getPaymentsExportCSVApi,
  updateManyPaymentStatusApi,
} from 'services';
import PopupWarningApprove from 'components/Popups/Warning/PopupWarningApprove';
import { PopupRejectPaymentPayout } from 'components/Popups/Transaction';
import FileSaver from 'file-saver';
import { useDateTime } from 'hooks';
import { Link } from 'react-router-dom';
import PopupWarningGenPending from 'components/Popups/Warning/PopupWarningGenPending';

const filterOptions: FilterOption[] = [
  {
    name: 'User',
    key: 'user',
    type: FilterOptionType.TEXT,
  },
  {
    name: 'TXN Date',
    key: 'timestamp',
    type: FilterOptionType.DATE_PICKER,
  },
];

const Payouts = () => {
  const theme = useTheme();
  const { moment, formatDateTime } = useDateTime();
  const dispatch = useAppDispatch();
  const { payouts } = useAppSelector((state) => state.paymentReducer.payment);
  const { params } = payouts;
  const [anchorElAction, setAnchorElAction] = useState<null | HTMLElement>(
    null
  );
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [keyword, setKeyword] = useState('');
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [openDetailPopup, setOpenDetailPopup] = useState(false);
  const [itemPayment, setItemPayment] = useState<PAYMENT_TYPE>();
  const [openDeleteAll, setOpenDeleteAll] = useState<boolean>(false);
  const [openApproveAll, setOpenApproveAll] = useState<boolean>(false);
  const [openGenPending, setOpenGenPending] = useState<boolean>(false);
  const [openRejectAll, setOpenRejectAll] = useState<boolean>(false);
  const [openCreateOrEdit, setOpenCreateOrEdit] = useState<boolean>(false);
  const [responseApprove, setResponseApprove] = useState<any>(null);

  const onSetListParams = (params: GET_PAYMENT_PARAMS_REQUEST) => {
    dispatch(setPayoutsParams(params));
  };

  useEffect(() => {
    if (params) {
      dispatch(getPayoutsRequest(params));
    }
  }, [params, dispatch]);

  useEffect(() => {
    if (!responseApprove) return;
    if (responseApprove.status === 202) {
      NotifyService.success(responseApprove);
      NotifyService.warning(responseApprove);
    } else {
      NotifyService.success(responseApprove);
    }
  }, [responseApprove]);

  const onReloadList = () => {
    if (!params) return;
    dispatch(getPayoutsRequest(params));
  };

  useEffect(() => {
    setKeyword(params?.keyword ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setOpenDelete = (event: any, data: PAYMENT_TYPE) => {
    event.stopPropagation();
    setItemPayment(data);
    setOpenDeletePopup(true);
  };

  const setOpenEdit = (event: any, data: PAYMENT_TYPE) => {
    event.stopPropagation();
    setItemPayment(data);
    setOpenCreateOrEdit(true);
  };

  const setOpenCreate = () => {
    setOpenCreateOrEdit(true);
  };

  const handleOpenBulkAction = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElAction(event.currentTarget);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = payouts.records.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (_: any, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangeRowsPerPage = (event: SelectChangeEvent) => {
    const limit = parseInt(event.target.value, 10);
    onSetListParams({ ...params, page: 1, limit });
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const { items } = usePagination({
    count: payouts.metadata.total_pages,
    page: payouts.metadata.page,
    onChange(_event, page) {
      onSetListParams({ ...params, page: page });
    },
  });

  const onSetKeywordParams = useDebounce((keyword: string) => {
    const _params = { ...params, page: 1 };
    if (keyword) _params.keyword = keyword;
    else delete _params.keyword;
    onSetListParams(_params);
  }, 250);

  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(event.target.value);
    onSetKeywordParams(event.target.value);
  };

  const onClosePopup = (type: number) => {
    switch (type) {
      case TYPE_POPUP.DELETE:
        setOpenDeletePopup(false);
        setItemPayment(undefined);
        break;
      case TYPE_POPUP.CREATE_OR_EDIT:
        setOpenCreateOrEdit(false);
        setItemPayment(undefined);
        break;
      case TYPE_POPUP.DETAIL:
        setOpenDetailPopup(false);
        setItemPayment(undefined);
        break;
      case TYPE_POPUP.APPROVE_ALL:
        setOpenApproveAll(false);
        break;
      case TYPE_POPUP.REJECT_ALL:
        setOpenRejectAll(false);
        break;
      case TYPE_POPUP.GEN_PENDING:
        setOpenGenPending(false);
        break;
    }
  };

  const deleteManyPayment = async () => {
    const paymentIds: any = selected;
    await deleteAllPaymentApi(paymentIds)
      .then((r) => {
        onReloadList();
        setOpenDeleteAll(false);
        setSelected([]);
        NotifyService.success(r);
      })
      .catch((e) => NotifyService.error(e))
      .finally(() => {});
  };

  const filterValue: FilterValue = useMemo(() => {
    return {
      user: payouts.params?.user,
      timestamp: payouts.params?.timestamp,
    };
  }, [payouts.params]);

  const onChangeFilter = (value: FilterValue) => {
    setSelected([]);
    const _params = { ...params };
    delete _params.user;
    delete _params.timestamp;
    onSetListParams({
      ..._params,
      ...value,
      page: 1,
    });
  };

  const sortItem = useMemo(() => {
    return getSortItem(payouts.params?.sort);
  }, [payouts.params]);

  const handleRequestSort = (
    _: React.MouseEvent<unknown>,
    property: keyof PAYMENT_TYPE
  ) => {
    setSelected([]);
    const isAsc = sortItem?.orderBy === property && sortItem?.order === 'asc';
    onSetListParams({
      ...params,
      sort: `${property}-${isAsc ? 'desc' : 'asc'}`,
    });
  };

  const isPendding = useMemo(() => {
    return params?.status === CASH_TRANSACTION_STATUS.PENDING;
  }, [params]);

  const handleShowPendingPayouts = () => {
    const _params = {
      ...initialPaymentState.payment.payouts.params,
      sort: params?.sort ?? undefined,
    };
    setKeyword('');
    if (isPendding) delete _params.status;
    else _params.status = CASH_TRANSACTION_STATUS.PENDING;
    setSelected([]);
    onSetListParams({ ..._params, page: 1 });
  };

  const approveManyPayouts = async () => {
    const paymentIds: any = selected;
    await updateManyPaymentStatusApi({
      ids: paymentIds,
      status: CASH_TRANSACTION_STATUS.SUCCESS,
    })
      .then((r) => {
        setSelected([]);
        onClosePopup(TYPE_POPUP.APPROVE_ALL);
        setResponseApprove(r);
        onReloadList();
      })
      .catch((e) => NotifyService.error(e))
      .finally(() => {});
  };
  const generatePending = async () => {
    onClosePopup(TYPE_POPUP.GEN_PENDING);
    onCloseBulkAction();
    await generatePendingPayoutApi()
      .then((r) => {
        onReloadList();
        NotifyService.success(r);
      })
      .catch((e) => NotifyService.error(e))
      .finally(() => {});
  };

  const onCloseBulkAction = () => {
    setAnchorElAction(null);
  };

  const onOpenApproveAll = () => {
    if (!selected?.length) return;
    setOpenApproveAll(true);
  };

  const onOpenRejectAll = () => {
    if (!selected?.length) return;
    setOpenRejectAll(true);
  };

  const onOpenDeleteAll = () => {
    if (!selected?.length) return;
    setOpenDeleteAll(true);
  };
  const onOpenGenPending = () => {
    setOpenGenPending(true);
  };

  const rejectManyPayoutsSuccess = () => {
    onReloadList();
    setSelected([]);
  };

  const onExportCSV = async () => {
    await getPaymentsExportCSVApi({
      sort: params?.sort,
      code: params?.code,
    })
      .then((res) => {
        FileSaver.saveAs(
          res.data,
          `${moment().format('YYYY-MM-DD')} Payouts.csv`
        );
      })
      .catch((e) => NotifyService.error(e));
  };

  return (
    <Layout>
      <AppBar
        position="fixed"
        sx={{
          width: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`,
          backgroundColor: 'white.light',
          borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
        }}
        elevation={0}
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Grid>
            <Typography
              sx={{
                fontSize: '20px',
                lineHeight: 1,
                color: 'grey1.light',
              }}
            >
              Finance / Payouts
            </Typography>
          </Grid>
          <Grid display="flex" gap="8px" flexGrow={1} justifyContent="center">
            <InputBase
              placeholder="Search"
              sx={{
                maxWidth: '369px',
                flexGrow: 1,
                // paddingRight: '9px',
                background: '#F2F4F6',
                border: '1px solid #D9D9D9',
                p: '0px 7px 0px 11px',
                borderRadius: '5px',
                [`& .${inputBaseClasses.input}`]: {
                  fontSize: 14,
                  fontWeight: 400,
                  lineHeight: '100%',
                  p: 0,
                  height: 'fit-content',
                },
              }}
              onChange={onChangeSearch}
              // autoComplete=""
              value={keyword}
              endAdornment={
                <InputAdornment position="end">
                  <Magnifier />
                </InputAdornment>
              }
            />
            <FilterMenu
              filterOptions={filterOptions}
              filterValue={filterValue}
              onChange={onChangeFilter}
            />
          </Grid>
          <Grid display="flex" gap="8px">
            <Button
              sx={{
                background: '#fff',
                border: `1px solid #226FE3`,
                borderRadius: '3px',
                boxShadow: 'none',

                color: '#226FE3',
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '100%',
                textTransform: 'none',

                p: '6px 16px',
                height: '30px',
                alignItems: 'flex-end',
                ':hover': {
                  background: 'rgba(34, 111, 227, 0.08)',
                  border: `1px solid ${theme.palette.secondary.main}`,
                  borderRadius: '3px',
                  boxShadow: 'none',
                },
                [`& .${buttonClasses.endIcon}`]: {
                  mb: '-3px',
                  ml: '3px',
                },
              }}
              variant="contained"
              endIcon={<DirectionDown />}
              onClick={handleOpenBulkAction}
            >
              Bulk Action
            </Button>
            <Menu
              sx={{
                mt: '45px',
                '& .MuiList-padding': {
                  pt: '0px !important',
                  pb: '0px !important',
                },
              }}
              id="menu-appbar"
              anchorEl={anchorElAction}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElAction)}
              onClose={onCloseBulkAction}
            >
              <Stack
                sx={{
                  width: '319px',
                  p: '14px 22px 25px 23px',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  ':hover': {
                    backgroundColor: 'white.light',
                  },
                }}
              >
                <Stack spacing="10px">
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: '16px',
                      lineHeight: '100%',
                    }}
                  >
                    Bulk Actions
                  </Typography>
                  <Button
                    startIcon={<Check />}
                    variant="outlined"
                    color="secondary"
                    sx={{
                      p: 0,
                      width: '250px',
                      height: '32px',
                      fontWeight: 600,
                      fontSize: '15px',
                      lineHeight: 1,
                      borderRadius: '10px',
                      '& .MuiButton-startIcon': {
                        mr: '5px',
                      },
                    }}
                    onClick={onOpenApproveAll}
                  >
                    Approve Pending Payouts
                  </Button>

                  <Button
                    startIcon={
                      <CloseCircle
                        width={15}
                        heigh={15}
                        color={theme.palette.secondary.main}
                      />
                    }
                    variant="outlined"
                    color="secondary"
                    sx={{
                      p: 0,
                      fontWeight: 600,
                      fontSize: '15px',
                      lineHeight: 1,
                      width: '230px',
                      height: '32px',
                      borderRadius: '10px',
                      '& .MuiButton-startIcon': {
                        mr: '5px',
                      },
                    }}
                    onClick={onOpenDeleteAll}
                  >
                    Delete Selected Payouts
                  </Button>
                  <Button
                    // startIcon={
                    //   <CloseCircle
                    //     width={15}
                    //     heigh={15}
                    //     color={theme.palette.secondary.main}
                    //   />
                    // }
                    variant="outlined"
                    color="secondary"
                    sx={{
                      p: 0,
                      fontWeight: 600,
                      fontSize: '15px',
                      lineHeight: 1,
                      width: '230px',
                      height: '32px',
                      borderRadius: '10px',
                      '& .MuiButton-startIcon': {
                        mr: '5px',
                      },
                    }}
                    onClick={onOpenRejectAll}
                  >
                    Reject Selected Payouts
                  </Button>
                  <Button
                    // startIcon={
                    //   <CloseCircle
                    //     width={15}
                    //     heigh={15}
                    //     color={theme.palette.secondary.main}
                    //   />
                    // }
                    variant="outlined"
                    color="secondary"
                    sx={{
                      p: 0,
                      fontWeight: 600,
                      fontSize: '15px',
                      lineHeight: 1,
                      width: '230px',
                      height: '32px',
                      borderRadius: '10px',
                      '& .MuiButton-startIcon': {
                        mr: '5px',
                      },
                    }}
                    onClick={onOpenGenPending}
                  >
                    Generate Pending Payout
                  </Button>
                </Stack>
              </Stack>
            </Menu>
            <Button
              variant={isPendding ? 'contained' : 'outlined'}
              endIcon={<PendingPayoutsIcon active={isPendding} />}
              sx={{
                height: 30,
                fontWeight: 600,
                fontSize: '13px',
                '& .MuiButton-endIcon': {
                  paddingBottom: '2px',
                },
              }}
              onClick={handleShowPendingPayouts}
            >
              Pending Payouts{' '}
              {`(${payouts.metadata.pending_payouts_count || 0})`}
            </Button>
            <Button
              sx={{
                width: '131px',
                height: 30,
                fontWeight: 600,
                fontSize: '13px',
                border: `1px solid ${theme.palette.secondary.main}`,
                p: '0px',
              }}
              variant="outlined"
              color="secondary"
              endIcon={<DocumentDownload />}
              onClick={onExportCSV}
            >
              Export to CSV
            </Button>
            <Button
              variant="contained"
              color="secondary"
              sx={{
                height: 30,
                width: '72px',
                fontWeight: 600,
                fontSize: '13px',
                lineHeight: '100%',
              }}
              onClick={setOpenCreate}
            >
              Create
            </Button>
          </Grid>
        </Toolbar>
      </AppBar>
      <Stack spacing="8px">
        <TableContainer
          component={Paper}
          elevation={0}
          sx={{
            border: '1px solid #D9D9D9',
            maxHeight: 'calc(100vh - 150px)',
            maxWidth: 'calc(100vw - 282px)',
          }}
        >
          <Table
            stickyHeader
            sx={{ minWidth: 650 }}
            aria-labelledby="tableTitle"
          >
            <HeadTable<PAYMENT_TYPE>
              numSelected={selected.length}
              order={sortItem?.order}
              orderBy={sortItem?.orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={payouts.records.length}
              headCells={headCells}
            />
            <TableBody
              sx={{
                height: 'auto !important',
              }}
            >
              {payouts.records.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    <TableCell
                      padding="checkbox"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                      }}
                    >
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        onChange={(e) => handleClick(e, row.id)}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {formatDateTime(row.timestamp)}
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {row.number}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {row.type}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {`${row.user?.first_name} ${row.user?.last_name}`}
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                        color: row.total_amount > 0 ? '#0CAD35' : '#000',
                      }}
                    >
                      {printPrice(row.total_amount)}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                        color:
                          cashTXTStatusColor[row.status || ''] || '#B6B6B6',
                      }}
                    >
                      {row.status}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {row.user?.phone_number}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                        minWidth: '160px',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {row?.PayoutMethods[0]?.account_holder_name}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {row?.PayoutMethods[0]?.bank_account_number}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                      }}
                    >
                      {row.doc_ref_no}
                    </TableCell>

                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {row.walletTransaction?.number}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                      }}
                    >
                      <Stack direction="row" spacing="10px">
                        <Link to={`/finance/payouts/${row.id}`}>
                          <Button
                            sx={{
                              minWidth: '38px !important',
                              height: '32px',
                              '&.MuiButton-root': {
                                p: '0px !important',
                              },
                            }}
                            variant="contained"
                            color="secondary"
                          >
                            <Eye />
                          </Button>
                        </Link>
                        <ButtonEdit onClick={(e) => setOpenEdit(e, row)} />
                        <ButtonDelete onClick={(e) => setOpenDelete(e, row)} />
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TableCaption
          rowsPerPage={params?.limit ?? 0}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          metadata={payouts.metadata}
          items={items}
        />
      </Stack>

      {openDeletePopup && (
        <DeleteDialog
          name=""
          open={openDeletePopup}
          onClose={() => onClosePopup(TYPE_POPUP.DELETE)}
          subject={itemPayment?.id}
          onSuccess={onReloadList}
          type={DELETE_DIALOG_TYPE.PAYMENT}
        />
      )}
      {openDeleteAll && (
        <DeleteAllDialog
          open={openDeleteAll}
          onClose={() => setOpenDeleteAll(false)}
          onClickDelete={deleteManyPayment}
        />
      )}
      {openDetailPopup && (
        <PopupDetailPayment
          open
          title="Payout detail"
          subject={itemPayment?.id}
          handleClosePopup={() => onClosePopup(TYPE_POPUP.DETAIL)}
        />
      )}
      {openApproveAll && (
        <PopupWarningApprove
          open={openApproveAll}
          title="Approve this payout(s)?"
          description={`You’re going to approve ${
            selected?.length || 0
          } payout requests`}
          onClose={() => setOpenApproveAll(false)}
          onClickApprove={approveManyPayouts}
        />
      )}
      {openGenPending && (
        <PopupWarningGenPending
          open={openGenPending}
          title="Generate Pending Payout"
          description={`Do you want to generate pending payout for all the ACTIVE sitters?`}
          onClose={() => setOpenGenPending(false)}
          onClick={generatePending}
        />
      )}
      <PopupRejectPaymentPayout
        multiple={true}
        open={openRejectAll}
        subject={selected as string[]}
        onSuccess={rejectManyPayoutsSuccess}
        handleClosePopup={() => onClosePopup(TYPE_POPUP.REJECT_ALL)}
      />
      <PopupCreateOrEditTopupPayout
        open={openCreateOrEdit}
        title="Payout"
        typeCode={CASH_TRANSACTION_CODE.PAYOUT}
        subject={itemPayment?.id}
        onSuccess={onReloadList}
        handleClosePopup={() => onClosePopup(TYPE_POPUP.CREATE_OR_EDIT)}
      />
    </Layout>
  );
};

export default Payouts;
