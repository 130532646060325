import { SortItem } from 'models';

const ASC = '-asc';
const DESC = '-desc';

export const getSortItem = (value?: string | null): SortItem | null => {
  if (value?.includes(ASC)) {
    return {
      orderBy: value.replace(ASC, ''),
      order: 'asc',
    };
  } else if (value?.includes(DESC)) {
    return {
      orderBy: value.replace(DESC, ''),
      order: 'desc',
    };
  }
  return null;
};

export const newGetSortItem = (
  sort?: string | null,
  orderBy?: any
): SortItem | null => {
  if (sort === 'asc') {
    return {
      orderBy: orderBy,
      order: 'asc',
    };
  } else if (sort === 'desc') {
    return {
      orderBy: orderBy,
      order: 'desc',
    };
  }
  return null;
};
