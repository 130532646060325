import { INIT_COUNTRY_CODE } from 'config';
import { IFormInputsSitter, OptionItem } from 'models';
import { SITTER_STATUS } from 'models/accounts';

export const experienceGroup: OptionItem[] = [
  { id: 'experience_with_kids_of_age', name: 'Experience with kids of age' },
  {
    id: 'experience_with_special_needs',
    name: 'Experience with Special Needs?',
  },
  { id: 'languages', name: 'What languages do you speak?' },
  { id: 'certifications', name: 'Certifications' },
  { id: 'pets', name: 'Are you comfortable with pets?' },
  { id: 'preferred_sit_location', name: 'Preferred Sit location' },
  { id: 'overnight_sits', name: 'Overnight sits' },
];

export const optionStatus = [
  {
    name: 'Active',
    value: SITTER_STATUS.ACTIVE,
  },
  {
    name: 'Inactive',
    value: SITTER_STATUS.INACTIVE,
  },
  {
    name: 'Approved',
    value: SITTER_STATUS.APPROVED,
  },
  {
    name: 'Pending',
    value: SITTER_STATUS.PENDING,
  },
];

export const defaultValues: IFormInputsSitter = {
  details: {
    first_name: '',
    last_name: '',
    email: '',
    phone: {
      country_code: INIT_COUNTRY_CODE.code,
      phone_number: '',
    },
    bio: '',
    salary_per_hour: 0,
    status: 'approved',
    address_name: '',
    block_number: '',
    street: '',
    building_name: '',
    unit_number: '',
    postal_code: '',
    region_id: null,
    video: '',
    years_of_experience: 0,
    password: '',
    profile_picture: null,
  },
  check_list_obj: [],
  badge_ids: [],
};
