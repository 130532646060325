import { FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { DropDown } from 'asset';
import React, { FC, Fragment } from 'react';
import { Controller } from 'react-hook-form';

interface Props {
  title: string;
  type?: string;
  name: string;
  form?: any;
  rules?: any;
  autoComplete?: string;
  placeholder?: string;
  showEyes?: boolean;
  options: any[];
  errorsName?: any;
  handleOnChange?: any;
  isDisabled?: boolean;
}

const SelectInput: FC<Props> = ({
  type,
  title,
  form,
  name,
  rules,
  placeholder,
  showEyes,
  options,
  errorsName,
  isDisabled = false,
  handleOnChange = () => {},

  ...rest
}) => {
  const {
    control,
    formState: { errors },
  } = form;

  return (
    <Fragment>
      <InputLabel
        sx={{
          fontWeight: 500,
          fontSize: '15px',
          lineHeight: '100%',
          color: '#000',
          marginBottom: '5px',
        }}
      >
        {title}
      </InputLabel>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => (
          <Select
            {...field}
            value={field.value ?? ''}
            disabled={isDisabled}
            IconComponent={() => <DropDown />}
            sx={{
              color: '#000',
              fontSize: '13px',
              p: '16px',
              width: '100%',
              height: '38px',
              background: isDisabled ? '#eaeaea' : '#fff',
              borderRadius: '5px',
              padding: 0,
              '& > svg': {
                position: 'absolute',
                right: '15px',
              },
              '& > div': {
                padding: '13px 10px',
              },
              '& fieldset': {
                borderColor: '#D5D5D5 !important',
                borderWidth: '1px !important',
              },
            }}
            {...rest}
            onChange={(e) => {
              field.onChange(e);
              handleOnChange(e);
            }}
          >
            {options.map((op, index) => (
              <MenuItem key={index} value={op.value} sx={{}}>
                {op.name}
              </MenuItem>
            ))}
          </Select>
        )}
      />

      {!!(errors as any)[name] ? (
        <FormHelperText
          error
          sx={{
            fontSize: '16px',
            letterSpacing: '0.045em',
            color: 'white.light',
          }}
        >
          {(errors as any)?.[name]?.message || ''}
        </FormHelperText>
      ) : (
        <FormHelperText
          error
          sx={{
            fontSize: '16px',
            letterSpacing: '0.045em',
            color: 'white.light',
          }}
        >
          {errorsName || ''}
        </FormHelperText>
      )}
    </Fragment>
  );
};

export default SelectInput;
