import {
  Box,
  Button,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { CloseCircle } from 'asset';
import { Inputs, LoadingPopup, SelectInput } from 'components';
import { patternPassword } from 'config';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { editAdminsApi } from 'services';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { GET_STAFFS_DETAIL_REQUEST } from 'store/reducers/accounts/actionTypes';
import { NotifyService } from 'helpers/notify';

interface EditAdminProps {
  open: boolean;
  handleClosePopup: any;
  onSuccess?: any;
  subject?: any;
}

interface IFormInputs {
  full_name: string;
  email: string;
  old_password: string;
  new_password: string;
  repeat_password: string;
  role: string;
}

const options = [
  {
    name: 'Staff',
    value: 'staff',
  },
  {
    name: 'View Only',
    value: 'view-only',
  },
  {
    name: 'SuperAdmin',
    value: 'superadmin',
  },
];

const EditAdmin = (props: EditAdminProps) => {
  const { open, handleClosePopup, onSuccess, subject } = props;
  const { staffs } = useAppSelector((state) => state.accountReducer);
  const { loadingPopup } = useAppSelector((state) => state.globalReducer);

  const theme = useTheme();
  const dispatch = useAppDispatch();

  const form = useForm<IFormInputs>({
    defaultValues: {
      full_name: '',
      email: '',
      old_password: '',
      new_password: '',
      repeat_password: '',
      role: '',
    },
  });

  useEffect(() => {
    if (subject) {
      dispatch({
        type: GET_STAFFS_DETAIL_REQUEST,
        payload: {
          id: subject,
        },
      });
    }
    // eslint-disable-next-line
  }, [subject]);

  useEffect(() => {
    if (staffs.staffs_detail) {
      form.reset({
        full_name: staffs.staffs_detail.full_name,
        email: staffs.staffs_detail.email,
        role: staffs.staffs_detail.role,
        old_password: '',
        new_password: '',
        repeat_password: '',
      });
    }
    // eslint-disable-next-line
  }, [staffs.staffs_detail]);

  const onSubmit = async (value: any) => {
    await editAdminsApi(subject, value)
      .then((r) => {
        onSuccess();
        handleClosePopup();
        NotifyService.success(r);
      })
      .catch((e) => NotifyService.error(e))
      .finally(() => {});
  };

  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiPaper-root': {
          width: 527,
          borderRadius: 0,
        },
      }}
    >
      <Grid>
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '20px 25px',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '100%',
            }}
          >
            Edit Admin
          </Typography>
          <IconButton
            onClick={handleClosePopup}
            sx={{
              padding: 0,
            }}
          >
            <CloseCircle />
          </IconButton>
        </Grid>
        <Divider />
        {loadingPopup && <LoadingPopup color="primary" />}
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Box p="25px 24px">
            <Box mb="20px">
              <Inputs
                title="Name"
                name="full_name"
                placeholder="Input your name"
                form={form}
                rules={{
                  required: {
                    value: true,
                    message: 'Name is required',
                  },
                }}
              />
            </Box>
            <Box mb="20px">
              <Inputs
                title="Email Address"
                name="email"
                placeholder="Input your email"
                form={form}
                rules={{
                  required: {
                    value: true,
                    message: 'Email is required',
                  },
                }}
              />
            </Box>
            <Box mb="20px">
              <Inputs
                showEyes
                title="Old Password"
                type="password"
                name="old_password"
                placeholder="Input your password"
                form={form}
                // rules={{
                //   required: {
                //     value: true,
                //     message: 'Old Password is required',
                //   },
                // }}
              />
            </Box>
            <Box mb="8px">
              <Inputs
                showEyes
                title="New Password"
                type="password"
                name="new_password"
                placeholder="Input your password"
                form={form}
                rules={{
                  // required: {
                  //   value: true,
                  //   message: 'New Password is required',
                  // },
                  pattern: {
                    value: patternPassword,
                    message: 'Invalid password',
                  },
                }}
              />
            </Box>
            <Box mb="20px">
              <Typography
                sx={{
                  color: '#8E8E93',
                  fontWeight: 400,
                  fontSize: 12,
                  lineHeight: '100%',
                }}
              >
                Password should contain 8 letters
              </Typography>
              <ul
                style={{
                  color: '#8E8E93',
                  fontWeight: 400,
                  fontSize: 12,
                  lineHeight: '100%',
                  margin: 0,
                  paddingLeft: 20,
                }}
              >
                <li>Has both uppercase and lowercase letters</li>
                <li>Contains letters and numbers</li>
                <li>Has at least one special character, e.g., ! @ # ? ]</li>
              </ul>
            </Box>
            <Box mb="20px">
              <Inputs
                showEyes
                title="Repeat Password"
                type="password"
                name="repeat_password"
                placeholder="Input your password"
                form={form}
                rules={{
                  validate: (value: any) =>
                    value === form.watch('new_password') ||
                    'The password does not match',
                }}
              />
            </Box>
            <Box mb="32px">
              <SelectInput
                title="Role"
                name="role"
                form={form}
                options={options}
              />
            </Box>
            <Stack direction="row" spacing="8px">
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleClosePopup}
                sx={{
                  height: 30,
                  width: '100%',
                  fontWeight: 600,
                  fontSize: '14px',
                  lineHeight: 1.1,
                  border: `1px solid ${theme.palette.secondary.main}`,
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                sx={{
                  height: 30,
                  width: '100%',
                  fontWeight: 600,
                  fontSize: '14px',
                  lineHeight: 1.1,
                }}
              >
                Update
              </Button>
            </Stack>
          </Box>
        </form>
      </Grid>
    </Dialog>
  );
};

export default EditAdmin;
