import styled from '@emotion/styled';
import { InputField, SelectField } from 'components/form-control';

export const StyledInputField = styled(({ className, ...rest }: any) => (
  <InputField
    helperProps={{ className }}
    labelProps={{ className }}
    {...rest}
  />
))`
  font-weight: 400;
  font-size: 12px;
  color: #000;
`;

export const StyledSelectField = styled(({ className, ...rest }: any) => (
  <SelectField
    helperProps={{ className }}
    labelProps={{ className }}
    {...rest}
  />
))`
  font-weight: 400;
  font-size: 12px;
  color: #000;
`;
