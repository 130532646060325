import { METADATA_TYPE } from './accounts';
import { OptionItem } from './global';

export interface GET_REQUESTS_PARAMS_REQUEST {
  page?: number;
  limit?: number;
  sort?: string;
  parent_name?: string;
  start_datetime?: string;
  from_time?: string;
  end_datetime?: string;
  to_time?: string;
  address?: string;
  status?: number;
  type?: string;
  id?: string;
  parent_id?: string;
  keyword?: string;
  createdAt?: string;
}
export interface BOOKINGS_TYPE {
  Sitter: {
    User: {
      first_name: string;
      last_name: string;
      email: string;
      phone_number: string;
    };
    profile_picture: string;
    sitter_id: string;
    status: string;
    salary_per_hour: number;
  };
  id: string;
  salary_per_hour: number;
  status: string;
}

export interface REQUESTS_DETAIL_TYPE {
  address: {
    id: string;
    block_number: string;
    name: string;
    street: string;
    unit_number: string;
  };
  children: [
    {
      id: string;
      picture: any;
      age_range: number;
      full_name: string;
    }
  ];
  bookings: BOOKINGS_TYPE[];
  number: string;
  createdAt: string;
  from_datetime: string;
  to_datetime: string;
  to_date: string;
  to_time: string;
  id: string;
  is_flexible: boolean;
  days_of_week: any[];
  parent: {
    parent_id: string;
    first_name: string;
    last_name: string;
  };
  special_notes: { '0': string; '1': string; '2': string };
  from_date: string;
  from_time: string;
  date: string;
  time: string;
  status: string;
  status_payment: any;
  total: number;
  total_hours: number;
  type: string;
  updatedAt: string;
  promocode: string | null;
  discount_amount: string;
  selected_sitter_only: boolean;
}

export interface REQUESTS_TYPE {
  id: string;
  number: string;
  parent_id?: string;
  Parent?: {
    email: string;
    first_name: string;
    last_name: string;
    phone_number: string;
  };
  from_datetime: string;
  to_datetime: string;
  from_date: string;
  from_time: string;
  to_date: string;
  to_time: string;
  address: {
    id?: string;
    name?: string;
    street?: string;
  };
  status?: string;
  type?: string;
  createdAt?: string;
  days_of_week?: string[];
  end_recurring_period?: string;
  start_recurring_period?: string;
  start_datetime: string;
  end_datetime: string;
  total_hours: string;
  selected_sitter_only: boolean;
}

export interface REQUESTS_BODY_RESULT {
  records: REQUESTS_TYPE[];
  metadata: METADATA_TYPE;
  params?: GET_REQUESTS_PARAMS_REQUEST | null | undefined;
}

export enum SR_TYPE {
  JUST_ONCE = 'just-once',
  RECURRING = 'recurring',
}

export enum SREQUEST_STATUS {
  PENDING = 'pending',
  UPCOMING = 'upcoming',
  CANCELED = 'canceled',
  EXPIRED = 'expired',
  PAID = 'paid',
}

export enum BOOKING_STATUS {
  INVITED = 'invited',
  APPLIED = 'applied',
  RFP = 'requested for payment',
  PAID = 'paid',
  CANCELED = 'canceled',
}

export const SREQUEST_STATUSES: OptionItem[] = [
  {
    id: SREQUEST_STATUS.PENDING,
    name: 'Pending',
  },
  {
    id: SREQUEST_STATUS.PAID,
    name: 'Paid',
  },
  {
    id: SREQUEST_STATUS.EXPIRED,
    name: 'Expired',
  },
  {
    id: SREQUEST_STATUS.CANCELED,
    name: 'Canceled',
  },
];
