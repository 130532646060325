import { takeLatest, call, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { GET_REPORT_LIST_PARAMS, LIST_BODY_RESULT, REPORT_TYPE } from 'models';

import { PayloadAction } from '@reduxjs/toolkit';
import { setReportListSuccess } from 'store/reducers/reports';
import { getReportListApi, deleteReportApi } from 'services';
import {
  DELETE_REPORT_REQUEST,
  GET_REPORT_LIST_REQUEST,
} from 'store/reducers/reports/actionType';
import { setGlobalLoading } from 'store/reducers/global';

function* getReportListRequest(
  action: PayloadAction<{
    params?: GET_REPORT_LIST_PARAMS;
  }>
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<{
      data: LIST_BODY_RESULT<REPORT_TYPE>;
    }> = yield call(getReportListApi, action.payload.params);

    yield put(
      setReportListSuccess({
        records: response.data.data.records,
        metadata: response.data.data.metadata,
      })
    );
    yield put(setGlobalLoading(false));
  } catch (error: unknown) {}
}

function* deleteReportRequest(
  action: PayloadAction<{
    id: string;
    onSuccess?: () => void;
  }>
) {
  try {
    yield call(deleteReportApi, action.payload.id);
    if (action.payload.onSuccess) action.payload.onSuccess();
  } catch (error: unknown) {}
}

function* reports() {
  yield takeLatest(GET_REPORT_LIST_REQUEST, getReportListRequest);
  yield takeLatest(DELETE_REPORT_REQUEST, deleteReportRequest);
}

export default reports;
