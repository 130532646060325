import { ACTIVITY_TYPE, HeadCell } from 'models';

export const headCells: readonly HeadCell<ACTIVITY_TYPE>[] = [
  {
    id: 'type',
    numeric: false,
    disablePadding: true,
    isSort: false,
    label: 'Type',
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Description',
  },
  {
    id: 'amount',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Amount',
  },
  {
    id: 'edd' as keyof ACTIVITY_TYPE,
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'EDD',
  },
  {
    id: 'bookingId' as keyof ACTIVITY_TYPE,
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Booking ID',
  },
  {
    id: 'parentFirstName' as keyof ACTIVITY_TYPE,
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Parent',
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Created At',
  },
  {
    id: 'updatedAt',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Updated At',
  },
];

export const OPTIONS_DATA_FILTER = [
  {
    id: 'BOOKING_CREATED',
    label: 'BOOKING_CREATED',
  },
  {
    id: 'NANNY_AVAILABLE',
    label: 'NANNY_AVAILABLE',
  },
  {
    id: 'NANNY_SELECTED',
    label: 'NANNY_SELECTED',
  },
  {
    id: 'PAYMENT',
    label: 'PAYMENT',
  },
  {
    id: 'PAYMENT_PARENT_AUNTY',
    label: 'PAYMENT_PARENT_AUNTY',
  },
  {
    id: 'PAYMENT_PARENT_NANNY',
    label: 'PAYMENT_PARENT_NANNY',
  },
  {
    id: 'PAYMENT_NANNY_PARENT',
    label: 'PAYMENT_NANNY_PARENT',
  },
  {
    id: 'PAYMENT_NANNY_AUNTY',
    label: 'PAYMENT_NANNY_AUNTY',
  },
  {
    id: 'PAYMENT_AUNTY_PARENT',
    label: 'PAYMENT_AUNTY_PARENT',
  },
  {
    id: 'PAYMENT_AUNTY_NANNY',
    label: 'PAYMENT_AUNTY_NANNY',
  },
  {
    id: 'COMMENCED',
    label: 'COMMENCED',
  },
  {
    id: 'COMPLETED',
    label: 'COMPLETED',
  },
  {
    id: 'CANCELLATION',
    label: 'CANCELLATION',
  },
  {
    id: 'DELETED',
    label: 'DELETED',
  },
  {
    id: 'ADMIN_NOTE',
    label: 'ADMIN_NOTE',
  },
];
