import { METADATA_TYPE } from './accounts';
import { OptionItem } from './global';

export interface GET_JOBS_PARAMS_REQUEST {
  page?: number;
  limit?: number;
  sort?: string;
  parent_name?: string;
  sitter_name?: string;
  start_datetime?: string;
  end_datetime?: string;
  address?: string;
  status?: any;
  type?: string;
  keyword?: string;
  createdAt?: string;
  total?: number;
  sitter_id?: string;
}

export interface JOBS_TYPE {
  Parent: {
    email: string;
    first_name: string;
    last_name: string;
    phone_number: string;
  };
  Sitter: {
    email: string;
    first_name: string;
    last_name: string;
    phone_number: string;
  };
  address: {
    block_number: string;
    country: string;
    id: string;
    name: string;
    postal_code: string;
    street: string;
    unit_number: string;
  };
  number: string;
  createdAt: string;
  end_datetime: string;
  id: string;
  start_datetime: string;
  status: string;
  total: number;
  srequest_number: string;
}

export interface JOBS_BODY_RESULT {
  records: JOBS_TYPE[];
  metadata: METADATA_TYPE;
  params?: GET_JOBS_PARAMS_REQUEST | null;
}

export enum JOBS_STATUS {
  UPCOMING = 'upcoming',
  STARTED = 'started',
  PENDING_COMPLETION = 'pending completion',
  COMPLETED = 'completed',
  CANCELED = 'canceled',
}

export const JOB_STATUSES: OptionItem[] = [
  {
    id: JOBS_STATUS.UPCOMING,
    name: 'Upcoming',
  },
  {
    id: JOBS_STATUS.STARTED,
    name: 'Started',
  },
  {
    id: JOBS_STATUS.PENDING_COMPLETION,
    name: 'Pending Completion',
  },
  {
    id: JOBS_STATUS.COMPLETED,
    name: 'Completed',
  },
  {
    id: JOBS_STATUS.CANCELED,
    name: 'Canceled',
  },
];

export interface JOB_DETAIL_TYPE {
  id: string;
  number: string;
  sitter: {
    id: string;
    first_name: string;
    last_name: string;
    salary_per_hour: number;
  };
  parent: {
    id: string;
    first_name: string;
    last_name: string;
  };
  start_datetime: {
    date: string;
    time: string;
  };
  end_datetime: {
    date: string;
    time: string;
  };
  address: {
    id: string;
    name: string;
    street: string;
    block_number: string;
    unit_number: string;
    country: string;
    postal_code: string;
  };
  type: string;
  children: {
    id: string;
    picture: string | null;
    age_range: number;
    full_name: string;
  }[];
  sr_id: string;
  status: string;
  total: string;
  special_notes: {
    [key: string]: string;
  };
}

export interface UPDATE_JOB_BODY {
  sitter_id?: string;
  start_day?: string;
  start_time?: string;
  end_day?: string;
  end_time?: string;
  special_notes?: string[];
  total?: number;
  address_id?: string;
  status?: string;
  children?: {
    id: string;
    picture: string | null;
    age_range: number;
    full_name: string;
  }[];
}

export interface UPDATE_JOB_STATUS {
  behalf_of?: string;
  status: string;
}
