import { AxiosResponse } from 'axios';
import { API } from 'config';
import {
  GET_JOBS_PARAMS_REQUEST,
  JOB_DETAIL_TYPE,
  UPDATE_JOB_BODY,
  UPDATE_JOB_STATUS,
} from 'models';
import { AppAPIInstance } from 'services';

export const getJobsListApi = async (params?: GET_JOBS_PARAMS_REQUEST) => {
  return await AppAPIInstance.get(API.ADMIN.JOB.DEFAULT, {
    params,
  });
};

export const getJobDetailApi = async (
  id: string
): Promise<AxiosResponse<{ data: JOB_DETAIL_TYPE }>> => {
  return await AppAPIInstance.get(`${API.ADMIN.JOB.DEFAULT}/${id}`);
};

export const updateJobApi = async (id: string, data: UPDATE_JOB_BODY) => {
  return await AppAPIInstance.put(`${API.ADMIN.JOB.DEFAULT}/${id}`, data);
};

export const deleteJobApi = async (id: string) => {
  return await AppAPIInstance.delete(`${API.ADMIN.JOB.DEFAULT}/${id}`);
};

export const deleteAllJobApi = async (ids: string[]) => {
  const idsObj = {
    ids,
  };
  return await AppAPIInstance.delete(`${API.ADMIN.JOB.DEFAULT}/`, {
    data: idsObj,
  });
};

export const updateJobStatusApi = async (
  id: string,
  data?: UPDATE_JOB_STATUS
) => {
  return await AppAPIInstance.put(
    API.ADMIN.JOB.UPDATE_STATUS.replace(':id', id),
    data
  );
};

export const getJobsExportCSVApi = async (
  params?: Partial<GET_JOBS_PARAMS_REQUEST>
) => {
  return await AppAPIInstance.get(API.ADMIN.JOB.EXPORT, {
    params,
    responseType: 'blob',
  }).catch(async (e) => {
    return Promise.reject(
      JSON.parse((await e?.response?.data?.text()) || '{}')?.error
    );
  });
};
