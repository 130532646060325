import {
  Box,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { CloseCircle } from 'asset';
import { LoadingPopup } from 'components/common';
import { NotifyService, printPrice } from 'helpers';
import { useDateTime } from 'hooks';
import { cashTXTStatusColor, PAYMENT_DETAIL_TYPE } from 'models';
import { memo, useEffect, useState } from 'react';
import { getPaymentApi } from 'services';

interface Props {
  open: boolean;
  title?: string;
  subject?: any;
  handleClosePopup: () => void;
}

const PopupDetailPayment = memo(
  ({ title, open, subject, handleClosePopup }: Props) => {
    const { formatDateTime } = useDateTime();
    const [data, setData] = useState<PAYMENT_DETAIL_TYPE>();
    const [loadingPopup, setLoadingPopup] = useState(false);

    const _handleClosePopup = () => {
      setData(undefined);
      handleClosePopup();
    };

    useEffect(() => {
      if (subject) {
        setLoadingPopup(true);
        const getDetail = async () => {
          await getPaymentApi(subject)
            .then(({ data }) => {
              setData(data.data);
              setLoadingPopup(false);
            })
            .catch((e) => NotifyService.error(e));
        };
        getDetail();
      }
    }, [subject]);

    return (
      <Dialog
        open={open}
        sx={{
          '& .MuiPaper-root': {
            width: 523,
            height: 'auto',
            borderRadius: 0,
          },
        }}
      >
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '20px 25px',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '100%',
            }}
          >
            {title || 'Payment History'}
          </Typography>
          <IconButton onClick={_handleClosePopup} sx={{ p: 0 }}>
            <CloseCircle />
          </IconButton>
        </Grid>
        <Divider />
        {loadingPopup && <LoadingPopup color="primary" />}
        <Box sx={{ height: '100%' }}>
          <Stack
            p="30px 24px 50px 26px"
            height="100%"
            justifyContent="space-between"
          >
            <Box mb="30px">
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '100%',
                  color: '#8E8E93',
                  mb: '5px',
                }}
              >
                TXN No.
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '18px',
                  lineHeight: '100%',
                  color: '#000',
                }}
              >
                {data?.record?.number}
              </Typography>
            </Box>
            <Box display="flex">
              <Box mr="30px">
                <Box mb="30px">
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: '14px',
                      lineHeight: '100%',
                      color: '#8E8E93',
                      mb: '5px',
                    }}
                  >
                    Transaction Type
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: '14px',
                      lineHeight: '100%',
                      color: '#000',
                    }}
                  >
                    {data?.record?.type}
                  </Typography>
                </Box>
                {!!data?.record?.doc_ref_no && (
                  <Box mb="30px">
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '100%',
                        color: '#8E8E93',
                        mb: '5px',
                      }}
                    >
                      Doc Reference
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '100%',
                        color: '#000',
                      }}
                    >
                      {data?.record?.doc_ref_no}
                    </Typography>
                  </Box>
                )}
                <Box>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: '14px',
                      lineHeight: '100%',
                      color: '#8E8E93',
                      mb: '5px',
                    }}
                  >
                    Timestamp
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: '14px',
                      lineHeight: '100%',
                      color: '#000',
                    }}
                  >
                    {formatDateTime(data?.record?.timestamp)}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Box mb="30px">
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: '14px',
                      lineHeight: '100%',
                      color: '#8E8E93',
                      mb: '5px',
                    }}
                  >
                    User
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: '14px',
                      lineHeight: '100%',
                      color: '#226FE3',
                    }}
                  >
                    {data?.record?.first_name}&nbsp;{data?.record?.last_name}
                  </Typography>
                </Box>
                <Box mb="30px">
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: '14px',
                      lineHeight: '100%',
                      color: '#8E8E93',
                      mb: '5px',
                    }}
                  >
                    Total Amount
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: '14px',
                      lineHeight: '100%',
                      color: '#34C759',
                    }}
                  >
                    {printPrice(data?.record?.total_amount || 0)}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: '14px',
                      lineHeight: '100%',
                      color: '#8E8E93',
                      mb: '5px',
                    }}
                  >
                    Status
                  </Typography>
                  <Grid
                    container
                    alignItems="center"
                    textTransform="capitalize"
                  >
                    <Grid
                      sx={{
                        width: '8px',
                        height: '8px',
                        borderRadius: '50%',
                        marginRight: '5px',
                        backgroundColor:
                          cashTXTStatusColor[data?.record?.status || ''] ||
                          '#B6B6B6',
                      }}
                    />
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: '14px',
                        color: '#000',
                        height: '20px',
                      }}
                    >
                      {data?.record?.status}
                    </Typography>
                  </Grid>
                </Box>
              </Box>
            </Box>
            {!!data?.record?.remark && (
              <Box mt="30px">
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '100%',
                    color: '#8E8E93',
                    mb: '5px',
                  }}
                >
                  Remark
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '14px',
                    color: '#000',
                    height: '20px',
                  }}
                >
                  {data?.record?.remark}
                </Typography>
              </Box>
            )}
          </Stack>
        </Box>
      </Dialog>
    );
  }
);

export default PopupDetailPayment;
