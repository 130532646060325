import {
  Box,
  Button,
  Checkbox,
  Paper,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import usePagination from '@mui/material/usePagination/usePagination';
import { DeleteDialog, HeadTable, TableCaption } from 'components';
import PopupCreateNannyCertificateAssociation from 'components/Popups/Nanny/PopupCreateNannyCertificateAssociation';
import { ButtonDelete, TableCellNoData } from 'components/common/table';
import { getSortItem } from 'helpers';
import {
  CERTIFICATE_ASSOCIATION_TYPE,
  DELETE_DIALOG_TYPE,
  ECLOSE_POPUP,
  GET_NANNY_TAB_PARAMS_REQUEST,
  HeadCell,
} from 'models';
import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setNannyCertificateParams } from 'store/reducers/accounts';
import { getNannyCertificateAssociationRequest } from 'store/reducers/accounts/actionTypes';

const headCells: readonly HeadCell<CERTIFICATE_ASSOCIATION_TYPE>[] = [
  {
    id: 'title',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Certificate Name',
  },
  {
    id: 'icon',
    numeric: false,
    disablePadding: true,
    isSort: false,
    label: 'Attachment',
  },
];

interface ServiceRequestProps {
  nannyId: string | undefined;
}
const Certificates = ({ nannyId }: ServiceRequestProps) => {
  const { certificate } = useAppSelector(
    (state) => state.accountReducer.nanny.nanny_detail_tab
  );

  const { params } = certificate;

  const dispatch = useAppDispatch();
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [openCreatePopup, setOpenCreatePopup] = useState(false);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [itemAction, setItemAction] = useState<CERTIFICATE_ASSOCIATION_TYPE>();

  const onSetListParams = (params: GET_NANNY_TAB_PARAMS_REQUEST) => {
    dispatch(setNannyCertificateParams(params));
  };

  useEffect(() => {
    if (nannyId) {
      dispatch(getNannyCertificateAssociationRequest(nannyId, params));
    }
  }, [params, nannyId, dispatch]);

  const onReloadList = () => {
    if (!nannyId) return;
    dispatch(getNannyCertificateAssociationRequest(nannyId, params));
  };

  const handleChangeRowsPerPage = (event: SelectChangeEvent) => {
    const limit = parseInt(event.target.value, 10);
    onSetListParams({ ...params, pageNum: 1, pageSize: limit });
  };

  const { items } = usePagination({
    count: certificate.totalPages,
    page: certificate.pageNum,
    onChange(_event, page) {
      onSetListParams({ ...params, pageNum: page });
    },
  });

  const sortItem = useMemo(() => {
    return getSortItem('');
  }, []);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = certificate?.data?.map((n: any) => n?.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (_: any, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleRequestSort = (
    _: any,
    property: keyof CERTIFICATE_ASSOCIATION_TYPE
  ) => {
    setSelected([]);
    // const isAsc = sortItem?.orderBy === property && sortItem?.order === 'asc';
    // onSetListParams({
    //   ...params,
    //   sort: `${property}-${isAsc ? 'desc' : 'asc'}`,
    // });
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const onClosePopup = (type: ECLOSE_POPUP) => {
    switch (type) {
      case ECLOSE_POPUP.DELETE:
        setOpenDeletePopup(false);
        setItemAction(undefined);
        break;
      case ECLOSE_POPUP.CREATE_OR_EDIT:
        setOpenCreatePopup(false);
        setOpenEditPopup(false);
        setItemAction(undefined);
        break;
    }
  };

  const setOpenDelete = (event: any, data: CERTIFICATE_ASSOCIATION_TYPE) => {
    event.stopPropagation();
    setItemAction(data);
    setOpenDeletePopup(true);
  };

  const setOpenCreate = () => {
    setOpenCreatePopup(true);
  };

  return (
    <>
      <Box display="flex" justifyContent="flex-end" mb={'8px'}>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            height: 30,
            fontWeight: 600,
            fontSize: '13px',
            lineHeight: '100%',
          }}
          onClick={setOpenCreate}
        >
          Add Certificate
        </Button>
      </Box>
      <Stack spacing="8px">
        <TableContainer
          component={Paper}
          elevation={0}
          sx={{
            border: '1px solid #D9D9D9',
            maxHeight: 'calc(100vh - 395px)',
          }}
        >
          <Table
            stickyHeader
            sx={{ minWidth: 650 }}
            aria-labelledby="tableTitle"
          >
            <HeadTable<CERTIFICATE_ASSOCIATION_TYPE>
              numSelected={selected.length}
              order={sortItem?.order}
              orderBy={sortItem?.orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={certificate?.data.length}
              headCells={headCells}
            />
            <TableBody
              sx={{
                height: 'auto !important',
              }}
            >
              {!!certificate?.data.length ? (
                certificate?.data?.map((row, index) => {
                  const isItemSelected = isSelected(
                    row.certificateAssociationId
                  );
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.certificateAssociationId}
                      selected={isItemSelected}
                    >
                      <TableCell
                        padding="checkbox"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                        }}
                      >
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          onChange={(e) =>
                            handleClick(e, row.certificateAssociationId)
                          }
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '5px 12px 5px',
                          fontSize: '14px',
                          fontWeight: 400,
                        }}
                      >
                        {row.title}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                          fontSize: '14px',
                          fontWeight: 400,
                        }}
                      >
                        {row.documentUrl}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                        }}
                      >
                        <Stack direction="row" spacing="10px">
                          {/* <ButtonEdit onClick={(e) => setOpenEdit(e, row)} /> */}
                          <ButtonDelete
                            onClick={(e) => setOpenDelete(e, row)}
                          />
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCellNoData colSpan={headCells.length + 2} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TableCaption
          rowsPerPage={params?.pageSize ?? 0}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          metadata={{
            page: certificate.pageNum,
            limit: certificate.pageSize,
            page_count: 1,
            total_pages: certificate.totalPages,
            total_count: certificate.totalSize,
          }}
          items={items}
        />
      </Stack>

      <DeleteDialog
        name=""
        open={openDeletePopup}
        onClose={() => onClosePopup(ECLOSE_POPUP.DELETE)}
        subject={itemAction?.certificateAssociationId}
        onSuccess={onReloadList}
        type={DELETE_DIALOG_TYPE.NANNY_CERTIFICATE_ASSOCIATION}
      />
      {((itemAction && openEditPopup) || openCreatePopup) && (
        <PopupCreateNannyCertificateAssociation
          nannyId={nannyId || ''}
          subject={itemAction}
          onClose={() => onClosePopup(ECLOSE_POPUP.CREATE_OR_EDIT)}
          onSuccess={onReloadList}
        />
      )}
    </>
  );
};

export default Certificates;
