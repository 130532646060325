import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { CloseCircle } from 'asset';
import { Inputs, Select } from 'components';
import { LoadingPopup } from 'components/common';
import { GetOptionsResponse } from 'components/form-control/Select';
import yup from 'config/yup.custom';
import { NotifyService } from 'helpers';
import {
  FLAG_STATUSES,
  GET_SITTERS_PARAMS_REQUEST,
  OptionItem,
  POST_CREATE_FLAG,
  SITTER_TYPE,
} from 'models';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { getSittersListApi } from 'services';
import { createFlagApi } from 'services/user-flags';

interface Props {
  open: boolean;
  user?: {
    id: string;
    first_name: string;
    last_name: string;
  };
  flag?: string;
  remark?: string;
  onSuccess: () => void;
  handleClosePopup: () => void;
}

interface IFormInputs {
  user_id: OptionItem | null;
  flag: OptionItem | null;
  remark?: string;
}

const defaultValues: Partial<IFormInputs> = {
  user_id: null,
  flag: null,
};

const PopupCreateFlag = (props: Props) => {
  const { open, handleClosePopup, onSuccess, flag, user, remark = '' } = props;

  const [loadingPopup, setLoadingPopup] = useState(false);

  const theme = useTheme();

  const schema = useMemo(() => {
    return yup.object({
      user_id: yup.mixed().required('Sitter is required'),
      flag: yup.mixed().required('Flag is required'),
      remark: yup.string().notRequired(),
    });
  }, []);

  const form = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  const { reset } = form;

  const _handleClosePopup = () => {
    handleClosePopup();
    reset({ ...defaultValues });
  };

  useEffect(() => {
    if (!open) return;
    if (user?.id) {
      reset({
        user_id: {
          id: user.id,
          label: `${user.first_name} ${user.last_name}`,
        },
        flag: FLAG_STATUSES.find((t) => t.id === flag),
        remark: remark,
      });
    }
  }, [open, user, flag, reset, remark]);

  const onSubmit = async (value: IFormInputs) => {
    const _value: POST_CREATE_FLAG = {
      user_id: value.user_id?.id || '',
      flag: value.flag?.id || '',
      remark: value.remark || '',
    };
    setLoadingPopup(true);
    await createFlagApi(_value)
      .then((r) => {
        onSuccess();
        _handleClosePopup();
        NotifyService.success(r);
      })
      .catch((e) => NotifyService.error(e))
      .finally(() => {
        setLoadingPopup(false);
      });
  };

  const getOptions =
    (key: keyof IFormInputs) =>
    async (page: number = 1, limit: number = 20, keyword?: string) => {
      let data: GetOptionsResponse | null = null;
      switch (key) {
        case 'user_id':
          const paramsSitter: GET_SITTERS_PARAMS_REQUEST = {
            page: page,
            limit: limit,
          };
          if (keyword) paramsSitter.keyword = keyword;
          data = await getSittersListApi(paramsSitter)
            .then(({ data }) => {
              return {
                metadata: data.data.metadata,
                options: data.data.records.map((it: SITTER_TYPE) => ({
                  ...it,
                  id: it.sitter_id,
                  label: `${it.first_name} ${it.last_name}`,
                })),
              };
            })
            .catch((e) => {
              NotifyService.error(e);
              return null;
            });
          break;
      }
      return data;
    };

  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiPaper-root': {
          width: 527,
          borderRadius: 0,
        },
      }}
    >
      <Grid>
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '20px 25px',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '100%',
            }}
          >
            {user?.id ? 'Edit' : 'Create'} Flag
          </Typography>
          <IconButton
            onClick={_handleClosePopup}
            sx={{
              padding: 0,
            }}
          >
            <CloseCircle />
          </IconButton>
        </Grid>
        <Divider />
        {loadingPopup && <LoadingPopup color="primary" />}

        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Stack spacing="20px" p="25px 24px">
            <Stack>
              <Select
                title="Sitter"
                name="user_id"
                form={form}
                isHasMore
                filterFunc={false}
                isDisabled={!!user?.id}
                getOptions={getOptions('user_id')}
              />
            </Stack>
            <Stack>
              <Select
                title="Flag"
                name="flag"
                bindLabel="name"
                form={form}
                filterFunc={true}
                options={FLAG_STATUSES}
              />
            </Stack>
            <Stack>
              <Inputs
                title="Remark"
                form={form}
                name="remark"
                multiline
                rows={4}
              />
            </Stack>
            <Stack direction="row" spacing="8px">
              <Button
                variant="outlined"
                color="secondary"
                onClick={_handleClosePopup}
                sx={{
                  height: 30,
                  width: '100%',
                  fontWeight: 600,
                  fontSize: '14px',
                  lineHeight: 1.1,
                  border: `1px solid ${theme.palette.secondary.main}`,
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                sx={{
                  height: 30,
                  width: '100%',
                  fontWeight: 600,
                  fontSize: '14px',
                  lineHeight: 1.1,
                }}
              >
                {user?.id ? 'Update' : 'Create'}
              </Button>
            </Stack>
          </Stack>
        </form>
      </Grid>
    </Dialog>
  );
};

export default PopupCreateFlag;
