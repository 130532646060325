import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Divider, Stack } from '@mui/material';
import { Inputs } from 'components/form-control';
import yup from 'config/yup.custom';
import { NotifyService } from 'helpers';
import { UPDATE_GOOGLE_REVIEW_BODY } from 'models';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { editGoogleLinkApi, getGoogleLinkApi } from 'services';

interface IFormInputs {
  link: string;
  id: string;
}

const defaultValues: Partial<IFormInputs> = {
  link: '',
  id: '',
};

const Review = () => {
  const schema = useMemo(() => {
    return yup.object({
      link: yup.string().required('Link is required'),
    });
  }, []);

  const form = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  const { reset, handleSubmit } = form;

  const fetchData = async () => {
    await getGoogleLinkApi()
      .then(({ data: { data } }) => {
        reset({
          link: data.value,
          id: data.id,
        });
      })
      .catch((e) => NotifyService.error(e));
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (value: IFormInputs) => {
    const data: UPDATE_GOOGLE_REVIEW_BODY = {
      link: value.link,
    };
    await editGoogleLinkApi(value.id, data)
      .then((r) => {
        fetchData();
        NotifyService.success(r);
      })
      .catch((e) => NotifyService.error(e));
  };

  return (
    <Box onSubmit={handleSubmit(onSubmit)} component="form">
      <Stack sx={{ width: '475px' }} spacing="16px">
        <Stack>
          <Inputs title="Google review link" form={form} name="link" />
        </Stack>
        <Divider />
        <Button
          fullWidth
          color="secondary"
          variant="contained"
          sx={{
            height: '30px',
          }}
          type="submit"
        >
          Save
        </Button>
      </Stack>
    </Box>
  );
};

export default Review;
