import {
  AppBar,
  Button,
  Checkbox,
  Grid,
  InputAdornment,
  InputBase,
  inputBaseClasses,
  Paper,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import usePagination from '@mui/material/usePagination/usePagination';
import { Magnifier } from 'asset';
import {
  DeleteDialog,
  FilterMenu,
  HeadTable,
  Layout,
  PopupEditUser,
  TableCaption,
} from 'components';
import {
  ButtonDelete,
  ButtonDetail,
  ButtonEdit,
} from 'components/common/table';
import { drawerWidth } from 'config';
import { getSortItem } from 'helpers';
import useDebounce from 'hooks/useDebounce';
import {
  FilterOption,
  FilterValue,
  GET_USERS_PARAMS_REQUEST,
  TYPE_POPUP,
  USERS_TYPE,
} from 'models';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setUsersListParams } from 'store/reducers/accounts';
import { GET_USERS_LIST_REQUEST } from 'store/reducers/accounts/actionTypes';
import { headCells } from './data';

const filterOptions: FilterOption[] = [
  // {
  //   name: 'Name',
  //   key: 'full_name',
  //   type: FilterOptionType.TEXT,
  //   placeholder: 'Enter parent name',
  // },
];

const Users = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { users_list } = useAppSelector((state) => state.accountReducer.users);
  const { params } = users_list;
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [keyword, setKeyword] = useState('');
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  // const [openDetailPopup, setOpenDetailPopup] = useState(false);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  // const [openCreatePopup, setOpenCreatePopup] = useState(false);
  const [itemUser, setItemUser] = useState<USERS_TYPE>();
  // const [openDeleteAll, setOpenDeleteAll] = useState<boolean>(false);

  const onSetUsersParams = (params: GET_USERS_PARAMS_REQUEST | null) => {
    dispatch(setUsersListParams(params));
  };

  useEffect(() => {
    dispatch({
      type: GET_USERS_LIST_REQUEST,
      payload: {
        params: params,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, dispatch]);

  useEffect(() => {
    setKeyword(params?.keyword ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setOpenDelete = (event: any, data: USERS_TYPE) => {
    event.stopPropagation();
    setItemUser(data);
    setOpenDeletePopup(true);
  };

  const setOpenDetail = (event: any, data: USERS_TYPE) => {
    event.stopPropagation();
    setItemUser(data);
    // setOpenDetailPopup(true);
  };

  const setOpenEdit = (event: any, data: USERS_TYPE) => {
    event.stopPropagation();
    setItemUser(data);
    setOpenEditPopup(true);
  };

  const setOpenCreate = () => {
    // setOpenCreatePopup(true);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      console.log(event.target.checked);
      const newSelected = users_list.record.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (_: any, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangeRowsPerPage = (event: SelectChangeEvent) => {
    const limit = parseInt(event.target.value, 10);
    onSetUsersParams({ ...params, page: 1, limit });
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  //   page > 0
  //     ? Math.max(0, (1 + page) * rowsPerPage - jobs.metadata.total_count)
  //     : 0;

  const { items } = usePagination({
    count: users_list.metadata.total_pages,
    page: users_list.metadata.page,
    onChange(_event, page) {
      onSetUsersParams({ ...params, page: page });
    },
  });

  const onSetKeywordParams = useDebounce((keyword: string) => {
    const _params = { ...params, page: 1 };
    if (keyword) _params.keyword = keyword;
    else delete _params.keyword;
    onSetUsersParams(_params);
  }, 250);

  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(event.target.value);
    onSetKeywordParams(event.target.value);
  };

  const onClosePopup = (type: number) => {
    switch (type) {
      case TYPE_POPUP.DELETE:
        setOpenDeletePopup(false);
        setItemUser(undefined);
        break;
      case TYPE_POPUP.EDIT:
        setOpenEditPopup(false);
        setItemUser(undefined);
        break;
      case TYPE_POPUP.DETAIL:
        // setOpenDetailPopup(false);
        setItemUser(undefined);
        break;
      case TYPE_POPUP.CREATE:
        // setOpenCreatePopup(false);
        setItemUser(undefined);
        break;
    }
  };

  const onReloadList = () => {
    dispatch({
      type: GET_USERS_LIST_REQUEST,
      payload: {
        params: params,
      },
    });
  };
  // const deleteManySuperadmin = async () => {
  //   const SuperadminIds: any = selected;
  //   await deleteAdminAllApi(SuperadminIds)
  //     .then((r) => {
  //       onReloadList();
  //       // setOpenDeleteAll(false);
  //       setSelected([]);
  //       toast.success('Success', TOAST.success);
  //     })
  //     .catch((e) => {
  //       toast.error(e.response.data.message, TOAST.error);
  //     })
  //     .finally(() => {});
  // };

  const filterValue: FilterValue = useMemo(() => {
    return {
      // full_name: users_list.params?.full_name,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users_list.params]);

  const onChangeFilter = (value: FilterValue) => {
    setSelected([]);
    const _params = { ...params };
    // delete _params.full_name;
    onSetUsersParams({
      ..._params,
      ...value,
      page: 1,
    });
  };

  const sortItem = useMemo(() => {
    return getSortItem(users_list.params?.sort);
  }, [users_list.params]);

  const handleRequestSort = (
    _: React.MouseEvent<unknown>,
    property: keyof USERS_TYPE
  ) => {
    setSelected([]);
    const isAsc = sortItem?.orderBy === property && sortItem?.order === 'asc';
    onSetUsersParams({
      ...params,
      sort: `${property}-${isAsc ? 'desc' : 'asc'}`,
    });
  };

  return (
    <Layout>
      <AppBar
        position="fixed"
        sx={{
          width: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`,
          backgroundColor: 'white.light',
          borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
        }}
        elevation={0}
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Grid>
            <Typography
              sx={{
                fontSize: '20px',
                lineHeight: 1,
                color: 'grey1.light',
              }}
            >
              Accounts / All User
            </Typography>
          </Grid>
          <Grid display="flex" gap="8px" flexGrow={1} justifyContent="center">
            <InputBase
              placeholder="Search"
              sx={{
                maxWidth: '369px',
                flexGrow: 1,
                // paddingRight: '9px',
                background: '#F2F4F6',
                border: '1px solid #D9D9D9',
                p: '0px 7px 0px 11px',
                borderRadius: '5px',
                [`& .${inputBaseClasses.input}`]: {
                  fontSize: 14,
                  fontWeight: 400,
                  lineHeight: '100%',
                  p: 0,
                  height: 'fit-content',
                },
              }}
              onChange={onChangeSearch}
              // autoComplete=""
              value={keyword}
              endAdornment={
                <InputAdornment position="end">
                  <Magnifier />
                </InputAdornment>
              }
            />
            <FilterMenu
              filterOptions={filterOptions}
              filterValue={filterValue}
              onChange={onChangeFilter}
            />
          </Grid>
          <Grid display="flex" gap="8px">
            {/* <Button
              sx={{
                width: '131px',
                height: 30,
                fontWeight: 600,
                fontSize: '13px',
                border: `1px solid ${theme.palette.secondary.main}`,
                p: '0px',
              }}
              variant="outlined"
              color="secondary"
              endIcon={<DocumentDownload />}
            >
              Export to CSV
            </Button> */}
            <Button
              disabled
              variant="contained"
              color="secondary"
              sx={{
                height: 30,
                width: '72px',
                fontWeight: 600,
                fontSize: '13px',
                lineHeight: '100%',
              }}
              onClick={setOpenCreate}
            >
              Create
            </Button>
          </Grid>
        </Toolbar>
      </AppBar>
      <Stack spacing="8px">
        <TableContainer
          component={Paper}
          elevation={0}
          sx={{
            border: '1px solid #D9D9D9',
            maxHeight: 'calc(100vh - 150px)',
          }}
        >
          <Table
            stickyHeader
            sx={{ minWidth: 650 }}
            aria-labelledby="tableTitle"
          >
            <HeadTable<USERS_TYPE>
              numSelected={selected.length}
              order={sortItem?.order}
              orderBy={sortItem?.orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={users_list.record.length}
              headCells={headCells}
            />
            <TableBody
              sx={{
                height: 'auto !important',
              }}
            >
              {users_list.record.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    <TableCell
                      padding="checkbox"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                      }}
                    >
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        onChange={(e) => handleClick(e, row.id)}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align="left"
                      component="th"
                      scope="row"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                      }}
                    >
                      {row.first_name}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                      }}
                    >
                      {row.last_name}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                      }}
                    >
                      {row.email}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                      }}
                    >
                      {row.phone_number}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                        textTransform: 'capitalize',
                      }}
                    >
                      <Link
                        to={`/accounts/parents/${row.parent?.parent_id}`}
                        style={{
                          color:
                            row.parent.status === 'active'
                              ? theme.palette.green.light
                              : theme.palette.yellow.light,
                        }}
                      >
                        {row.parent.status}
                      </Link>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                        textTransform: 'capitalize',
                      }}
                    >
                      <Link
                        to={`/accounts/sitters/${row.sitter?.sitter_id}`}
                        style={{
                          color:
                            row.sitter.status === 'active'
                              ? theme.palette.green.light
                              : theme.palette.yellow.light,
                        }}
                      >
                        {row.sitter.status}
                      </Link>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                      }}
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        textTransform="capitalize"
                        sx={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {row.status === 'active' ? (
                          <Grid
                            sx={{
                              width: '8px',
                              height: '8px',
                              borderRadius: '50%',
                              backgroundColor: 'green.light',
                            }}
                          />
                        ) : row.status === 'inactive' ? (
                          <Grid
                            sx={{
                              width: '8px',
                              height: '8px',
                              borderRadius: '50%',
                              backgroundColor: 'red.light',
                            }}
                          />
                        ) : (
                          <Grid
                            sx={{
                              width: '8px',
                              height: '8px',
                              borderRadius: '50%',
                              backgroundColor: 'gray',
                            }}
                          />
                        )}
                        &nbsp;{row.status}
                      </Stack>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                      }}
                    >
                      <Stack direction="row" spacing="10px">
                        <ButtonDetail
                          disabled
                          onClick={(e) => setOpenDetail(e, row)}
                        />
                        <ButtonEdit onClick={(e) => setOpenEdit(e, row)} />
                        <ButtonDelete onClick={(e) => setOpenDelete(e, row)} />
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
              {/* {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )} */}
            </TableBody>
          </Table>
        </TableContainer>
        <TableCaption
          rowsPerPage={params?.limit ?? 0}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          metadata={users_list.metadata}
          items={items}
        />
      </Stack>
      {/* {openCreatePopup && (
        <CreateAdmin
          open={openCreatePopup}
          handleClosePopup={() => {
            onClosePopup(TYPE_POPUP.CREATE);
          }}
          onSuccess={onReloadList}
        />
      )} */}
      {openEditPopup && (
        <PopupEditUser
          open={openEditPopup}
          handleClosePopup={() => onClosePopup(TYPE_POPUP.EDIT)}
          onSuccess={onReloadList}
          subject={itemUser?.id}
        />
      )}

      {openDeletePopup && (
        <DeleteDialog
          name={itemUser?.id}
          open={openDeletePopup}
          onClose={() => onClosePopup(TYPE_POPUP.DELETE)}
          subject={itemUser?.id}
          onSuccess={onReloadList}
          type="users"
        />
      )}
      {/* {openDeleteAll && (
        <DeleteAllDialog
          open={openDeleteAll}
          onClose={() => setOpenDeleteAll(false)}
          onClickDelete={deleteManySuperadmin}
        />
      )} */}
    </Layout>
  );
};

export default Users;
