
export const OPTIONS_DATA_TYPE = [
  {
    name: 'Just Once',
    value: 'just_once',
  },
  {
    name: 'Recurring',
    value: 'recurring',
  },
];

export const DAY_OF_WEEK = [
  {
    name: 'Mon',
    value: 1,
    isChecked: false,
    nameData: 'MO',
  },
  {
    name: 'Tue',
    value: 2,
    isChecked: false,
    nameData: 'TU',
  },
  {
    name: 'Wed',
    value: 3,
    isChecked: false,
    nameData: 'WE',
  },
  {
    name: 'Thu',
    value: 4,
    isChecked: false,
    nameData: 'TH',
  },
  {
    name: 'Fri',
    value: 5,
    isChecked: false,
    nameData: 'FR',
  },
  {
    name: 'Sat',
    value: 6,
    isChecked: false,
    nameData: 'SA',
  },
  {
    name: 'Sun',
    value: 7,
    isChecked: false,
    nameData: 'SU',
  },
];

export enum TypeSR {
  just_once = 'just_once',
  recurring = 'recurring',
}
