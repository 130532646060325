import React from 'react';

const NotVerifiedIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00004 1.33331C4.32671 1.33331 1.33337 4.32665 1.33337 7.99998C1.33337 11.6733 4.32671 14.6666 8.00004 14.6666C11.6734 14.6666 14.6667 11.6733 14.6667 7.99998C14.6667 4.32665 11.6734 1.33331 8.00004 1.33331ZM10.24 9.53331C10.4334 9.72665 10.4334 10.0466 10.24 10.24C10.14 10.34 10.0134 10.3866 9.88671 10.3866C9.76004 10.3866 9.63337 10.34 9.53337 10.24L8.00004 8.70665L6.46671 10.24C6.36671 10.34 6.24004 10.3866 6.11337 10.3866C5.98671 10.3866 5.86004 10.34 5.76004 10.24C5.56671 10.0466 5.56671 9.72665 5.76004 9.53331L7.29337 7.99998L5.76004 6.46665C5.56671 6.27331 5.56671 5.95331 5.76004 5.75998C5.95337 5.56665 6.27337 5.56665 6.46671 5.75998L8.00004 7.29331L9.53337 5.75998C9.72671 5.56665 10.0467 5.56665 10.24 5.75998C10.4334 5.95331 10.4334 6.27331 10.24 6.46665L8.70671 7.99998L10.24 9.53331Z"
        fill="#FFCC00"
      />
    </svg>
  );
};

export default NotVerifiedIcon;
