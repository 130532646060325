import {
  Checkbox,
  Paper,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Avatar,
  Box,
  Button,
} from '@mui/material';
import {
  DeleteDialog,
  HeadTable,
  PopupCreateOrEditChildren,
  TableCaption,
} from 'components';
import React, { useState, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { getParentChildrenListRequest } from 'store/reducers/accounts/actionTypes';
import usePagination from '@mui/material/usePagination/usePagination';
import {
  HeadCell,
  CHILDREN_PARENT_DETAIL_TYPE,
  GET_CHILDREN_PARENT_DETAIL_TYPE_PARAMS_REQUEST,
  ECLOSE_POPUP,
  DELETE_DIALOG_TYPE,
} from 'models';
import { setParentChildrenListParams } from 'store/reducers/accounts';
import { changeAge, getSortItem } from 'helpers';
import { ButtonDelete, ButtonEdit } from 'components/common/table';

const headCells: readonly HeadCell<CHILDREN_PARENT_DETAIL_TYPE>[] = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    isSort: true,
    label: 'ID',
  },
  {
    id: 'picture',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: '',
  },
  {
    id: 'children_name',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Children Name',
  },
  {
    id: 'age_range',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Age Group',
  },
];

interface ServiceRequestProps {
  parentId: string | undefined;
}
const Children = ({ parentId }: ServiceRequestProps) => {
  const { children } = useAppSelector(
    (state) => state.accountReducer.parents.parent_detail_tab
  );

  const { params } = children;

  const dispatch = useAppDispatch();
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [openCreatePopup, setOpenCreatePopup] = useState(false);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [itemAction, setItemAction] = useState<CHILDREN_PARENT_DETAIL_TYPE>();

  const onSetListParams = (
    params: GET_CHILDREN_PARENT_DETAIL_TYPE_PARAMS_REQUEST
  ) => {
    dispatch(setParentChildrenListParams(params));
  };

  useEffect(() => {
    if (parentId) {
      dispatch(getParentChildrenListRequest(parentId, params));
    }
  }, [params, parentId, dispatch]);

  const onReloadList = () => {
    if (!parentId) return;
    dispatch(getParentChildrenListRequest(parentId, params));
  };

  const handleChangeRowsPerPage = (event: SelectChangeEvent) => {
    const limit = parseInt(event.target.value, 10);
    onSetListParams({ ...params, page: 1, limit });
  };

  const { items } = usePagination({
    count: children.metadata.total_pages,
    page: children.metadata.page,
    onChange(_event, page) {
      onSetListParams({ ...params, page: page });
    },
  });

  const sortItem = useMemo(() => {
    return getSortItem(params?.sort);
  }, [params]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = children?.records?.map((n: any) => n?.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (_: any, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleRequestSort = (
    _: any,
    property: keyof CHILDREN_PARENT_DETAIL_TYPE
  ) => {
    setSelected([]);
    const isAsc = sortItem?.orderBy === property && sortItem?.order === 'asc';
    onSetListParams({
      ...params,
      sort: `${property}-${isAsc ? 'desc' : 'asc'}`,
    });
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const onClosePopup = (type: ECLOSE_POPUP) => {
    switch (type) {
      case ECLOSE_POPUP.DELETE:
        setOpenDeletePopup(false);
        setItemAction(undefined);
        break;
      case ECLOSE_POPUP.CREATE_OR_EDIT:
        setOpenCreatePopup(false);
        setOpenEditPopup(false);
        setItemAction(undefined);
        break;
    }
  };

  const setOpenDelete = (event: any, data: CHILDREN_PARENT_DETAIL_TYPE) => {
    event.stopPropagation();
    setItemAction(data);
    setOpenDeletePopup(true);
  };

  const setOpenEdit = (event: any, data: CHILDREN_PARENT_DETAIL_TYPE) => {
    event.stopPropagation();
    setItemAction(data);
    setOpenEditPopup(true);
  };

  const setOpenCreate = () => {
    setOpenCreatePopup(true);
  };

  return (
    <Stack spacing="8px">
      <Box display="flex" justifyContent="flex-end" mb={'8px'}>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            height: 30,
            width: '72px',
            fontWeight: 600,
            fontSize: '13px',
            lineHeight: '100%',
          }}
          onClick={setOpenCreate}
        >
          Create
        </Button>
      </Box>
      <TableContainer
        component={Paper}
        elevation={0}
        sx={{
          border: '1px solid #D9D9D9',
          maxHeight: 'calc(100vh - 395px)',
        }}
      >
        <Table stickyHeader sx={{ minWidth: 650 }} aria-labelledby="tableTitle">
          <HeadTable<CHILDREN_PARENT_DETAIL_TYPE>
            numSelected={selected.length}
            order={sortItem?.order}
            orderBy={sortItem?.orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={children?.records.length}
            headCells={headCells}
          />
          <TableBody
            sx={{
              height: 'auto !important',
            }}
          >
            {children?.records &&
              children?.records?.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    <TableCell
                      padding="checkbox"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                      }}
                    >
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        onChange={(e) => handleClick(e, row.id)}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                      }}
                    >
                      {row.id}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                      }}
                    >
                      <Avatar
                        src={row.picture}
                        sx={{
                          width: '37px',
                          height: '37px',
                          marginRight: '6px',
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                      }}
                    >
                      {row.children_name}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                      }}
                    >
                      {changeAge(row.age_range)}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                      }}
                    >
                      <Stack direction="row" spacing="10px">
                        <ButtonEdit onClick={(e) => setOpenEdit(e, row)} />
                        <ButtonDelete onClick={(e) => setOpenDelete(e, row)} />
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TableCaption
        rowsPerPage={params?.limit ?? 0}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        metadata={children?.metadata}
        items={items}
      />
      <DeleteDialog
        name=""
        open={openDeletePopup}
        onClose={() => onClosePopup(ECLOSE_POPUP.DELETE)}
        subject={parentId}
        onSuccess={onReloadList}
        type={DELETE_DIALOG_TYPE.DETAIL_PARENT}
        typeParent="children"
        idTypeParent={itemAction?.id}
      />
      {((!!itemAction && openEditPopup) || openCreatePopup) && (
        <PopupCreateOrEditChildren
          open={true}
          subject={parentId}
          onSuccess={onReloadList}
          idChildren={itemAction?.id}
          handleClosePopup={() => onClosePopup(ECLOSE_POPUP.CREATE_OR_EDIT)}
        />
      )}
    </Stack>
  );
};

export default Children;
