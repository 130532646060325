import {
  Button,
  Checkbox,
  Dialog,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  inputBaseClasses,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { CloseCircle, Magnifier } from 'asset';
import { GET_TXN_PAYOUT_PARAMS_REQUEST, TXN_PAYOUT_TYPE } from 'models';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'config/yup.custom';
import { HeadTable } from 'components/common';
import { addTransactionFromPayoutPaymentApi } from 'services';
import { NotifyService } from 'helpers';
import useDebounce from 'hooks/useDebounce';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setTXNPayoutsParams } from 'store/reducers/payment';
import { GET_TXN_FROM_PAYOUT_LIST_REQUEST } from 'store/reducers/payment/actionTypes';
import { headCells } from './data';

interface Props {
  open: boolean;
  data?: string;
  onSuccess: () => void;
  handleClosePopup: () => void;
}

interface IFormInputs {
  rate: number;
  content: string;
  user_id: any;
  job_number: any;
  anonymously: boolean;
}

const defaultValue: Partial<IFormInputs> = {
  rate: 0,
  content: '',
  user_id: '',
  job_number: '',
  anonymously: false,
};

const PopupAddTXN = (props: Props) => {
  const { open, data, handleClosePopup, onSuccess } = props;
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { txn_payout_list } = useAppSelector(
    (state) => state.paymentReducer.payment
  );
  const { params } = txn_payout_list;

  const [keyword, setKeyword] = useState('');
  const [selected, setSelected] = useState<readonly string[]>([]);

  const schema = useMemo(() => {
    return yup.object({
      rate: yup
        .number()
        .typeError('Rating is required')
        .transform((value) => (isNaN(value) ? 0 : value))
        .min(1, 'Rating must be greater than or equal to 1')
        .required('Rating is required'),
      content: yup.string().required('Review Comments is required'),
      user_id: !data
        ? yup.mixed().required('User is required')
        : yup.mixed().notRequired(),
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const form = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: defaultValue,
  });

  const { reset, handleSubmit } = form;

  const _handleClosePopup = () => {
    reset({ ...defaultValue });
    handleClosePopup();
  };

  const onSetTXNListParams = (params: GET_TXN_PAYOUT_PARAMS_REQUEST | null) => {
    dispatch(setTXNPayoutsParams(params));
  };

  useEffect(() => {
    dispatch({
      type: GET_TXN_FROM_PAYOUT_LIST_REQUEST,
      payload: {
        params: { ...params, cash_txn_id: data },
      },
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, dispatch]);

  const onSubmit = async () => {
    const newData: any = {
      // wallet_txn_id: selected[0],
      wallet_txn_ids: selected,
      cash_out_txn_id: data,
    };
    // console.log(newData, 'newData');
    await addTransactionFromPayoutPaymentApi(newData)
      .then((r) => {
        _handleClosePopup();
        onSuccess();
        NotifyService.success(r);
      })
      .catch((err) => NotifyService.error(err));
  };

  const onSetKeywordParams = useDebounce((keyword: string) => {
    const _params = { ...params, page: 1 };
    if (keyword) _params.keyword = keyword;
    else delete _params.keyword;
    onSetTXNListParams(_params);
  }, 250);

  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(event.target.value);
    onSetKeywordParams(event.target.value);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = txn_payout_list.records.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  // const handleClick = (_: any, name: string) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected: readonly string[] = [];
  //   if (selectedIndex === -1) {
  //     newSelected = [...newSelected, name];
  //   } else {
  //     newSelected = [];
  //   }

  //   setSelected(newSelected);
  // };

  const handleClick = (_: any, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiPaper-root': {
          width: '100%',
          maxWidth: 750,
          borderRadius: 0,
        },
      }}
    >
      <Grid>
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '20px 25px',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '100%',
            }}
          >
            Add Transaction
          </Typography>
          <IconButton
            onClick={_handleClosePopup}
            sx={{
              padding: 0,
            }}
          >
            <CloseCircle />
          </IconButton>
        </Grid>
        <Divider />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack p="30px 24px 50px 26px" spacing="25px">
            <Stack>
              <InputBase
                placeholder="Search"
                sx={{
                  maxWidth: '210px',
                  height: '30px',
                  flexGrow: 1,
                  // paddingRight: '9px',
                  background: '#F2F4F6',
                  border: '1px solid #D9D9D9',
                  p: '0px 7px 0px 11px',
                  borderRadius: '5px',
                  [`& .${inputBaseClasses.input}`]: {
                    fontSize: 14,
                    fontWeight: 400,
                    lineHeight: '100%',
                    p: 0,
                    height: 'fit-content',
                  },
                }}
                onChange={onChangeSearch}
                // autoComplete=""
                value={keyword}
                endAdornment={
                  <InputAdornment position="end">
                    <Magnifier />
                  </InputAdornment>
                }
              />
            </Stack>
            <TableContainer
              component={Paper}
              elevation={0}
              sx={{
                border: '1px solid #D9D9D9',
              }}
            >
              <Table sx={{ minWidth: 650 }} aria-labelledby="tableTitle">
                <HeadTable<TXN_PAYOUT_TYPE>
                  // checkboxOnly={true}
                  onAction={false}
                  numSelected={selected.length}
                  onSelectAllClick={handleSelectAllClick}
                  rowCount={txn_payout_list.records.length}
                  headCells={headCells}
                />
                <TableBody>
                  {txn_payout_list.records.map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell
                          padding="checkbox"
                          sx={{
                            borderRight: '1px solid #DEE2E6',
                          }}
                        >
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            onChange={(e) => handleClick(e, row.id)}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                        </TableCell>
                        <TableCell
                          align="left"
                          component="th"
                          scope="row"
                          sx={{
                            borderRight: '1px solid #DEE2E6',
                            padding: '18px 12px 17px',
                            fontSize: '14px',
                            fontWeight: 400,
                          }}
                        >
                          {row.number}
                        </TableCell>
                        <TableCell
                          align="left"
                          component="th"
                          scope="row"
                          sx={{
                            borderRight: '1px solid #DEE2E6',
                            padding: '18px 12px 17px',
                            fontSize: '14px',
                            fontWeight: 400,
                          }}
                        >
                          {row.TransactionType.code}
                        </TableCell>
                        <TableCell
                          align="left"
                          component="th"
                          scope="row"
                          sx={{
                            borderRight: '1px solid #DEE2E6',
                            padding: '18px 12px 17px',
                            fontSize: '14px',
                            fontWeight: 400,
                          }}
                        >
                          {row.doc_ref_no}
                        </TableCell>
                        <TableCell
                          align="right"
                          component="th"
                          scope="row"
                          sx={{
                            borderRight: '1px solid #DEE2E6',
                            padding: '18px 12px 17px',
                            fontSize: '14px',
                            fontWeight: 400,
                            color: theme.palette.green.light,
                          }}
                        >
                          ${row.total_amount}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Stack direction="row" justifyContent="space-between" spacing="8px">
              <Button
                variant="outlined"
                color="secondary"
                sx={{
                  width: '100%',
                  height: '30px',
                }}
                onClick={_handleClosePopup}
              >
                Cancel
              </Button>
              <Button
                disabled={selected.length === 0}
                color="secondary"
                variant="contained"
                sx={{
                  width: '100%',
                  height: '30px',
                }}
                onClick={() => {
                  onSubmit();
                }}
              >
                Save
              </Button>
            </Stack>
          </Stack>
        </form>
      </Grid>
    </Dialog>
  );
};

export default PopupAddTXN;
