import { TOAST } from 'config';
import { toast } from 'react-toastify';

export class NotifyService {
  static error(e: any) {
    toast.error(e?.response?.data?.message || e, TOAST.error);
  }

  static success(r: any) {
    toast.success(
      r?.data?.data?.success || r?.data?.message || r,
      TOAST.success
    );
  }
  static warning(r: any) {
    toast.warning(
      r?.data?.data?.failed || r?.data?.message || r,
      TOAST.success
    );
  }
}
