import { takeLatest, call, put, debounce } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { GET_JOBS_PARAMS_REQUEST, JOBS_BODY_RESULT } from 'models';

import { PayloadAction } from '@reduxjs/toolkit';
import { deleteJobApi, getJobsListApi } from 'services/jobs';
import {
  DELETE_JOBS_REQUEST,
  GET_JOBS_LIST_REQUEST,
  GET_JOBS_LIST_REQUEST_WITH_KEYWORD,
} from 'store/reducers/jobs/actionTypes';
import { setJobsListSuccess } from 'store/reducers/jobs';
import { setGlobalLoading } from 'store/reducers/global';
import { NotifyService } from 'helpers';

function* setJobsListRequest(
  action: PayloadAction<{
    params?: GET_JOBS_PARAMS_REQUEST;
  }>
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<JOBS_BODY_RESULT> = yield call(
      getJobsListApi,
      action.payload.params
    );
    yield put(
      setJobsListSuccess({
        records: response.data.records,
        metadata: response.data.metadata,
      })
    );
    yield put(setGlobalLoading(false));
  } catch (error: any) {
    NotifyService.error(error);
    yield put(setGlobalLoading(false));
  }
}

function* deleteJobRequest(
  action: PayloadAction<{
    id: string;
    onSuccess?: () => void;
  }>
) {
  try {
    yield call(deleteJobApi, action.payload.id);
    if (action.payload.onSuccess) action.payload.onSuccess();
  } catch (error: unknown) {}
}

function* jobs() {
  yield takeLatest(GET_JOBS_LIST_REQUEST, setJobsListRequest);
  yield debounce(250, GET_JOBS_LIST_REQUEST_WITH_KEYWORD, setJobsListRequest);
  yield takeLatest(DELETE_JOBS_REQUEST, deleteJobRequest);
}

export default jobs;
