import {
  Paper,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { HeadTable, PopupEditAppVersion, TableCaption } from 'components';
import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hook';
import usePagination from '@mui/material/usePagination/usePagination';
import {
  GET_APP_VERSION_LIST_PARAMS_REQUEST,
  APP_VERSION_TYPE,
  TYPE_POPUP,
} from 'models';
import { headCells } from './data';
import { ButtonEdit } from 'components/common/table';
import { setAppVersionListParams } from 'store/reducers/settings';
import { getAppVersionListRequest } from 'store/reducers/settings/actionType';
import { useDateTime } from 'hooks';

const ManageAppVersion = () => {
  const dispatch = useAppDispatch();
  const { formatDateTime } = useDateTime();
  const { appVersionList } = useAppSelector(
    (state) => state.settingsReducer.appVersions
  );
  const { params } = appVersionList;
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [itemAction, setItemAction] = useState<APP_VERSION_TYPE>();

  const onSetRegionsParams = (
    params: GET_APP_VERSION_LIST_PARAMS_REQUEST | null
  ) => {
    dispatch(setAppVersionListParams(params));
  };

  useEffect(() => {
    dispatch(getAppVersionListRequest(params));
  }, [params, dispatch]);

  const setOpenEdit = (event: any, data: APP_VERSION_TYPE) => {
    event.stopPropagation();
    setItemAction(data);
    setOpenEditPopup(true);
  };

  const handleChangeRowsPerPage = (event: SelectChangeEvent) => {
    const limit = parseInt(event.target.value, 10);
    onSetRegionsParams({ ...params, page: 1, limit });
  };

  const { items } = usePagination({
    count: appVersionList.metadata.total_pages,
    page: appVersionList.metadata.page,
    onChange(_event, page) {
      onSetRegionsParams({ ...params, page: page });
    },
  });

  const onClosePopup = (type: number) => {
    switch (type) {
      case TYPE_POPUP.CREATE_OR_EDIT:
        setOpenEditPopup(false);
        setItemAction(undefined);
        break;
    }
  };

  const onReloadList = () => {
    dispatch(getAppVersionListRequest(params));
  };

  return (
    <Stack spacing="8px">
      <TableContainer
        component={Paper}
        elevation={0}
        sx={{
          border: '1px solid #D9D9D9',
          maxHeight: 'calc(100vh - 280px)',
        }}
      >
        <Table stickyHeader sx={{ minWidth: 650 }} aria-labelledby="tableTitle">
          <HeadTable<APP_VERSION_TYPE>
            rowCount={appVersionList.records.length}
            headCells={headCells}
            onCheckbox={false}
          />
          <TableBody
            sx={{
              height: 'auto !important',
            }}
          >
            {appVersionList.records.map((row, index) => {
              return (
                <TableRow hover tabIndex={-1} key={row.id}>
                  <TableCell
                    align="left"
                    component="th"
                    scope="row"
                    sx={{
                      borderRight: '1px solid #DEE2E6',
                      padding: '18px 12px 17px',
                      fontSize: '14px',
                      fontWeight: 400,
                    }}
                  >
                    {row.flatform}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      borderRight: '1px solid #DEE2E6',
                      padding: '18px 12px 17px',
                      fontSize: '14px',
                      fontWeight: 400,
                    }}
                  >
                    {row.version}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      borderRight: '1px solid #DEE2E6',
                      padding: '18px 12px 17px',
                      fontSize: '14px',
                      fontWeight: 400,
                    }}
                  >
                    {row.force_updatero}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      borderRight: '1px solid #DEE2E6',
                      padding: '18px 12px 17px',
                      fontSize: '14px',
                      fontWeight: 400,
                    }}
                  >
                    {formatDateTime(row.updatedAt)}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      borderRight: '1px solid #DEE2E6',
                      padding: '18px 12px 17px',
                      width: '209px',
                    }}
                  >
                    <Stack direction="row" spacing="10px">
                      <ButtonEdit onClick={(e) => setOpenEdit(e, row)} />
                    </Stack>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TableCaption
        rowsPerPage={params?.limit ?? 0}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        metadata={appVersionList.metadata}
        items={items}
      />
      {openEditPopup && itemAction && (
        <PopupEditAppVersion
          open={true}
          subject={itemAction?.id}
          onSuccess={onReloadList}
          handleClosePopup={() => onClosePopup(TYPE_POPUP.CREATE_OR_EDIT)}
        />
      )}
    </Stack>
  );
};

export default ManageAppVersion;
