import { HeadCell, CHAT_KEYWORD_TRACKING } from 'models';

export const headCells: readonly HeadCell<CHAT_KEYWORD_TRACKING>[] = [
  {
    id: 'keyword',
    numeric: false,
    disablePadding: true,
    isSort: true,
    label: 'Keyword',
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Created Date',
  },
];
