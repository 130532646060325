import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { CloseCircle } from 'asset';
import { Inputs } from 'components/form-control';
import yup from 'config/yup.custom';
import { NotifyService } from 'helpers';
import { BOOKINGS_TYPE } from 'models';
import React, { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { makePaymentApi } from 'services';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { GET_PARENT_DETAIL_REQUEST } from 'store/reducers/accounts/actionTypes';

interface Props {
  open: boolean;
  onClose: () => void;
  subject: BOOKINGS_TYPE | undefined;
  onSuccess: any;
  parent_id: string | undefined;
  totalFee: number;
}

interface IFormInputs {
  create_jobs: boolean;
  notify_parent: boolean;
  notify_sitter: boolean;
  deduct_from_parent_credit: boolean;
  credit_balance: number;
}

const defaultValues: IFormInputs = {
  create_jobs: false,
  notify_parent: false,
  notify_sitter: false,
  deduct_from_parent_credit: false,
  credit_balance: 0,
};

const makePaymentOption = [
  {
    label: 'Create jobs',
    name: 'create_jobs',
    note: 'Create jobs from the SR',
  },
  {
    label: 'Notify Parent',
    name: 'notify_parent',
    note: 'Notify parent via chat',
  },
  {
    label: 'Notify Sitter',
    name: 'notify_sitter',
    note: 'Notify sitter via chat',
  },
  {
    label: 'Deduct from parent credit',
    name: 'deduct_from_parent_credit',
    note: '',
  },
];

const PopupEditHourlyFee = ({
  open = true,
  onClose,
  subject,
  onSuccess,
  parent_id,
  totalFee,
}: Props) => {
  const schema = useMemo(() => {
    return yup.object().shape({
      // credit_balance: yup
      //   .number()
      //   .min(totalFee, 'khong dung')
      //   // .test(
      //   //   'total-credit',
      //   //   'Parent has insufficient credit!',
      //   //   (value) => value !== 0 || value >= totalFee
      //   // )
      //   .required('Parent has insufficient credit!'),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const dispatch = useAppDispatch();

  const { parent_detail } = useAppSelector(
    (state) => state.accountReducer.parents
  );

  const form = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  const { reset, handleSubmit, setError, clearErrors, formState: { errors } } = form;

  useEffect(() => {
    dispatch({
      type: GET_PARENT_DETAIL_REQUEST,
      payload: { id: parent_id },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parent_id]);

  useEffect(() => {
    reset({
      credit_balance: parent_detail?.aunty_credits_balance,
    });

    // if (
    //   (parent_detail && parent_detail?.aunty_credits_balance < totalFee) ||
    //   parent_detail?.aunty_credits_balance === 0
    // ) {
    //   setError('credit_balance', {
    //     message: 'Parent has insufficient credit!',
    //   });
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parent_detail]);

  const onSubmit = async (value: IFormInputs) => {
    if(!!Object.keys(errors).length) return;
    
    const newData = {
      create_jobs: value.create_jobs,
      notify_parent: value.notify_parent,
      notify_sitter: value.notify_sitter,
      deduct_from_parent_credit: value.deduct_from_parent_credit,
    };
    if (subject) {
      await makePaymentApi(subject.id, newData)
        .then((r) => {
          onSuccess();
          onClose();
          NotifyService.success(r);
        })
        .catch((e) => NotifyService.error(e))
        .finally(() => {});
    }
  };

  // useEffect(() => {
  //   reset({
  //     salary_per_hour: subject.salary_per_hour,
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const handleChangeCheckBox = (method: any, name: string) => (e: any) => {
    method.onChange(e.target.checked);
    switch(name){
      case 'deduct_from_parent_credit': {
        if(!e.target.checked){
          clearErrors('credit_balance')
        }else{
          if (
            (parent_detail && parent_detail?.aunty_credits_balance < totalFee) ||
            parent_detail?.aunty_credits_balance === 0
          ) {
            setError('credit_balance', {
              message: 'Parent has insufficient credit!',
            });
          }
        }
      }
    }
  }

  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiPaper-root': {
          width: 530,
          borderRadius: 0,
        },
      }}
    >
      <Grid>
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '20px 25px',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '100%',
            }}
          >
            Make Payment
          </Typography>
          <IconButton
            onClick={onClose}
            sx={{
              padding: 0,
            }}
          >
            <CloseCircle />
          </IconButton>
        </Grid>
        <Divider />
        <Box p="23px 32px">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing="16px">
              {makePaymentOption.map((item, index) => (
                <Stack key={index}>
                  <Controller
                    name={item.name as any}
                    control={form.control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="secondary"
                            // checked={field.value || false}
                            onChange={handleChangeCheckBox(field, item.name)}
                            name="none"
                          />
                        }
                        label={item.label}
                      />
                    )}
                  />
                  <Typography
                    sx={{
                      mb: '16px',
                      fontWeight: 400,
                      fontSize: '16px',
                      lineHeight: '100%',
                      color: 'rgba(60, 60, 67, 0.6)',
                    }}
                  >
                    {item.note}
                  </Typography>
                  {index !== makePaymentOption.length - 1 && <Divider />}
                </Stack>
              ))}
              <Stack>
                <Inputs
                  type="number"
                  title="Parent’s Credit Balance"
                  form={form}
                  name="credit_balance"
                  readOnly
                />
              </Stack>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              mt="32px"
              spacing="8px"
            >
              <Button
                variant="outlined"
                color="secondary"
                sx={{
                  width: '233.5px',
                  height: '30px',
                }}
                onClick={() => {
                  onClose();
                }}
              >
                Cancel
              </Button>
              <Button
                color="secondary"
                variant="contained"
                sx={{
                  width: '233.5px',
                  height: '30px',
                }}
                type="submit"
                disabled={!parent_detail || !!Object.keys(errors).length}
              >
                Confirm Payment
              </Button>
            </Stack>
          </form>
        </Box>
      </Grid>
    </Dialog>
  );
};

export default PopupEditHourlyFee;
