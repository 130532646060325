import { Backdrop } from '@mui/material';
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

interface Props {
  open: boolean;
}

const GlobalLoading = ({ open }: Props) => {
  return (
    <Backdrop
      sx={{
        color: 'primary.main',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={open}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default GlobalLoading;
