import React from 'react';

const IconCheckEmpty = ({ ...props }) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8931_62839)">
        <path
          d="M3.99967 1.33333H11.9997C13.4724 1.33333 14.6663 2.52724 14.6663 4V12C14.6663 13.4728 13.4724 14.6667 11.9997 14.6667H3.99967C2.52692 14.6667 1.33301 13.4728 1.33301 12V4C1.33301 2.52724 2.52692 1.33333 3.99967 1.33333Z"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_8931_62839">
          <rect width={16} height={16} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconCheckEmpty;
