import React from 'react';
import { Navigate, Outlet } from 'react-router';

interface Props {
  isLoggedIn: boolean;
  redirectPath?: string;
  children: any;
}

const PrivateRoute = ({
  isLoggedIn,
  redirectPath = '/login',
  children,
}: Props) => {
  if (!isLoggedIn) {
    return <Navigate to={redirectPath} replace />;
  }

  return <>{children ? children : <Outlet />}</>;
};

export default PrivateRoute;
