import { HeadCell, MESSAGE_CHAT } from "models";

export const headCells: readonly HeadCell<MESSAGE_CHAT>[] = [
  {
    id: 'send_by',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Sender',
  },
  {
    id: 'message',
    numeric: false,
    disablePadding: true,
    isSort: false,
    label: 'Chat Message',
  },
  {
    id: 'trackedBy',
    numeric: false,
    disablePadding: true,
    isSort: false,
    label: 'Tracked By',
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Timestamp',
  },
];
