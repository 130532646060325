import { HeadCell, TRANSACTION_TYPE_TYPE } from 'models';

export const headCells: readonly HeadCell<TRANSACTION_TYPE_TYPE>[] = [
  {
    id: 'title',
    numeric: false,
    disablePadding: true,
    isSort: false,
    label: 'Title',
  },
  {
    id: 'code',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Code',
  },
  {
    id: 'default_include_in_payout',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Include In Payout',
  },
];
