import { HeadCell, REQUESTS_TYPE } from 'models';

export const headCells: readonly HeadCell<REQUESTS_TYPE>[] = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    isSort: true,
    label: 'SR No.',
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Type',
  },
  {
    id: 'start_datetime',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Date',
  },
  {
    id: 'address',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Short address',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Status',
  },
];
