import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from '@mui/material';
import { EditParentIcon, NotVerifiedIcon, VerifiedIcon } from 'asset';
import { Layout, PopupCreateOrEditSitter } from 'components';
import { drawerWidth } from 'config';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from 'store/hook';
import {
  initialAcountState,
  setSitterDetailSuccess,
  setSitterListDetail,
} from 'store/reducers/accounts';
import { GET_SITTER_DETAIL_REQUEST } from 'store/reducers/accounts/actionTypes';
import Chat from './Chat';
import Flags from './Flag';
import Jobs from './Jobs';
import Notes from './Notes';
import Payment from './Payment';
import PayoutHistory from './PayoutHistory';
import Reviews from './Reviews';
import Transactions from './Transactions';
import { DetailItem } from './styles';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`sitter-detail-tabpanel-${index}`}
      aria-labelledby={`sitter-detail-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `sitter-detail-tab-${index}`,
    'aria-controls': `sitter-detail-tabpanel-${index}`,
  };
}

const tabs: string[] = [
  'Admin Notes',
  'Jobs',
  'Transactions',
  'Payout History',
  'Chat',
  'Reviews',
  'Flagged History',
  'Payment Info',
];

const SitterDetail = () => {
  const dispatch = useAppDispatch();

  const { sitter_id } = useParams();

  const [openEditPopup, setOpenEditPopup] = useState(false);

  const { sitter_detail } = useAppSelector(
    (state) => state.accountReducer.sitters
  );

  const navigate = useNavigate();

  const [tabValue, setTabValue] = useState(0);

  const setOpenEdit = (event: any) => {
    event.stopPropagation();
    setOpenEditPopup(true);
  };

  const onClosePopup = (txt: 'edit') => {
    switch (txt) {
      case 'edit':
        setOpenEditPopup(false);
        break;
    }
  };

  const onSuccessEdit = () => {
    dispatch({
      type: GET_SITTER_DETAIL_REQUEST,
      payload: { id: sitter_id },
    });
  };

  const handleChangeTabValue = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    dispatch({
      type: GET_SITTER_DETAIL_REQUEST,
      payload: { id: sitter_id },
    });
    return () => {
      dispatch(setSitterDetailSuccess(null));
      dispatch(
        setSitterListDetail(initialAcountState.sitters.sitter_list_detail)
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sitter_id]);

  return (
    <Layout>
      <AppBar
        position="fixed"
        sx={{
          width: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`,
          backgroundColor: 'white.light',
          borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
        }}
        elevation={0}
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Grid>
            <Typography
              sx={{
                fontSize: '20px',
                lineHeight: 1,
                color: 'grey1.light',
              }}
            >
              Accounts / Sitters / {sitter_detail?.user.first_name}{' '}
              {sitter_detail?.user.last_name}
            </Typography>
          </Grid>
        </Toolbar>
      </AppBar>
      <Paper elevation={0} sx={{ p: '16px' }}>
        <Grid container justifyContent="space-between" pb="16px">
          <Button
            startIcon={<ArrowBackIosNewIcon />}
            sx={{
              p: 0,
              color: 'black.light',
              ':hover': {
                backgroundColor: 'white.light',
              },
            }}
            variant="text"
            onClick={() => navigate(`/accounts/sitters`)}
          >
            Back
          </Button>
          <Button
            startIcon={<EditParentIcon />}
            sx={{
              p: 0,
            }}
            onClick={(e) => setOpenEdit(e)}
          >
            Edit Sitter
          </Button>
        </Grid>
        <Box
          sx={{
            backgroundColor: 'rgba(34, 111, 227, 0.04)',
            padding: '21px 31px',
          }}
        >
          <Grid container rowSpacing={'8px'} columnSpacing={'70px'}>
            <DetailItem item>
              <Stack direction="row" spacing="16px" alignItems="center">
                <Avatar
                  src={sitter_detail?.profile_picture}
                  sx={{
                    width: '40px',
                    height: '40px',
                    marginRight: '6px',
                  }}
                />
                <Stack>
                  <Typography
                    fontWeight={500}
                    lineHeight="14px"
                    fontSize={14}
                    letterSpacing="0.1px"
                    color="text.secondary"
                  >
                    Sitter Name (First+Last)
                  </Typography>
                  <Typography
                    fontWeight={400}
                    lineHeight="16px"
                    fontSize={14}
                    letterSpacing="0.25px"
                    color="black.light"
                  >
                    {sitter_detail?.user.first_name}{' '}
                    {sitter_detail?.user.last_name}
                  </Typography>
                </Stack>
              </Stack>
            </DetailItem>
            <DetailItem item>
              <Typography
                fontWeight={500}
                lineHeight="14px"
                fontSize={14}
                letterSpacing="0.1px"
                color="text.secondary"
              >
                Email
              </Typography>
              <Grid container>
                <Typography
                  fontWeight={400}
                  lineHeight="16px"
                  fontSize={14}
                  letterSpacing="0.25px"
                  color="black.light"
                  display="flex"
                >
                  {sitter_detail?.user?.email || 'none'}
                </Typography>
                <Grid display="flex" alignItems="center" ml="16px">
                  {sitter_detail?.user?.email_verified ? (
                    <>
                      <Typography
                        component="span"
                        variant="Caption_12_Medium"
                        display="flex"
                        alignItems="center"
                        color="#AEAEB2"
                      >
                        Verified &nbsp;
                      </Typography>
                      <VerifiedIcon />
                    </>
                  ) : (
                    <>
                      <Typography
                        component="span"
                        variant="Caption_12_Medium"
                        display="flex"
                        alignItems="center"
                        color="#AEAEB2"
                      >
                        Not verified &nbsp;
                      </Typography>
                      <NotVerifiedIcon />
                    </>
                  )}
                </Grid>
              </Grid>
            </DetailItem>
            <DetailItem item>
              <Typography
                fontWeight={500}
                lineHeight="14px"
                fontSize={14}
                letterSpacing="0.1px"
                color="text.secondary"
              >
                Phone Number
              </Typography>
              <Grid container>
                <Typography variant="Components_UnstyledContainer">
                  {sitter_detail?.user?.phone_number || 'none'}
                </Typography>
                <Grid display="flex" alignItems="center" ml="16px">
                  {sitter_detail?.user?.phone_number_verified ? (
                    <>
                      <Typography
                        component="span"
                        variant="Caption_12_Medium"
                        display="flex"
                        alignItems="center"
                        color="#AEAEB2"
                      >
                        Verified &nbsp;
                      </Typography>
                      <VerifiedIcon />
                    </>
                  ) : (
                    <>
                      <Typography
                        component="span"
                        variant="Caption_12_Medium"
                        display="flex"
                        alignItems="center"
                        color="#AEAEB2"
                      >
                        Not verified &nbsp;
                      </Typography>
                      <NotVerifiedIcon />
                    </>
                  )}
                </Grid>
              </Grid>
            </DetailItem>
            <DetailItem item>
              <Typography
                fontWeight={500}
                lineHeight="14px"
                fontSize={14}
                letterSpacing="0.1px"
                color="text.secondary"
              >
                Ratings
              </Typography>
              <Typography
                fontWeight={400}
                lineHeight="16px"
                fontSize={14}
                letterSpacing="0.25px"
                color="black.light"
              >
                {sitter_detail?.rating || 0}/5.0
              </Typography>
            </DetailItem>
            <DetailItem item>
              <Typography
                fontWeight={500}
                lineHeight="14px"
                fontSize={14}
                letterSpacing="0.1px"
                color="text.secondary"
              >
                Reviews
              </Typography>
              <Typography
                fontWeight={400}
                lineHeight="16px"
                fontSize={14}
                letterSpacing="0.25px"
                color="black.light"
              >
                {sitter_detail?.rating_count || 0}
              </Typography>
            </DetailItem>
            <DetailItem item>
              <Typography
                fontWeight={500}
                lineHeight="14px"
                fontSize={14}
                letterSpacing="0.1px"
                color="text.secondary"
              >
                Wallet Balance
              </Typography>
              <Typography
                fontWeight={400}
                lineHeight="16px"
                fontSize={14}
                letterSpacing="0.25px"
                color="black.light"
              >
                {`$${sitter_detail?.balance.toFixed(2)}`}
              </Typography>
            </DetailItem>
          </Grid>
        </Box>
        <Grid
          container
          sx={{
            borderBottom: '1px solid  rgba(0, 0, 0, 0.08)',
            marginBottom: '16px',
          }}
        >
          <Tabs
            value={tabValue}
            onChange={handleChangeTabValue}
            aria-label="basic tabs example"
          >
            {tabs.map((item, index) => {
              return (
                <Tab
                  key={index}
                  label={item}
                  sx={{
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '125%',
                    letterSpacing: '0.1px',
                    color: '#959595',
                    textTransform: 'capitalize',
                  }}
                  {...a11yProps(index)}
                />
              );
            })}
          </Tabs>
        </Grid>
        <TabPanel value={tabValue} index={0}>
          <Notes parentId={sitter_id} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Jobs sitter_id={sitter_id} />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <Transactions sitter_id={sitter_id} />
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <PayoutHistory sitterId={sitter_id} />
        </TabPanel>
        <TabPanel value={tabValue} index={4}>
          <Chat sitterId={sitter_id} />
        </TabPanel>
        <TabPanel value={tabValue} index={5}>
          <Reviews sitterId={sitter_id} onSuccess={onSuccessEdit} />
        </TabPanel>
        <TabPanel value={tabValue} index={6}>
          <Flags sitterData={sitter_detail?.user} />
        </TabPanel>
        <TabPanel value={tabValue} index={7}>
          <Payment
            sitterId={sitter_id}
            item={sitter_detail}
            onSuccess={onSuccessEdit}
          />
        </TabPanel>
      </Paper>
      <PopupCreateOrEditSitter
        open={openEditPopup}
        subject={sitter_id}
        onSuccess={onSuccessEdit}
        handleClosePopup={() => onClosePopup('edit')}
      />
    </Layout>
  );
};

export default SitterDetail;
