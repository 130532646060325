import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface GlobalType {
  loading: boolean;
  loadingPopup: boolean;
}

const initialState: GlobalType = {
  loading: false,
  loadingPopup: false,
};

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setGlobalLoading(state: GlobalType, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setLoadingPopup(state: GlobalType, action: PayloadAction<boolean>) {
      state.loadingPopup = action.payload;
    },
  },
});

// Actions
export const { setGlobalLoading, setLoadingPopup } = globalSlice.actions;

export default globalSlice.reducer;
