import { METADATA_TYPE } from './accounts';
import { OptionItem } from './global';

export interface GET_TRANSACTIONS_PARAMS_REQUEST {
  page?: number;
  limit?: number;
  sort?: string;
  code?: string;
  user_id?: string;
  user?: string;
  timestamp?: string;
  type?: OptionItem;
  keyword?: string;
}

export interface TRANSACTION_TYPE {
  id: string;
  number: string;
  code: string;
  job_id: string | null;
  job_number: string;
  booking_id: string;
  sr_id: string;
  cash_txn_id: string;
  amount: number;
  tax_amount: number;
  total_amount: number;
  include_in_payout: boolean;
  payment_method_type: string;
  doc_ref_no: string;
  timestamp: string;
  createdAt: string;
  updatedAt: string;
  payout_date: string;
  remark: string;
  user: {
    user_id: string;
    first_name: string;
    last_name: string;
    email: string;
  };
  type: {
    id: string;
    code: string;
    title: string;
    sign: string;
  };
}

export interface TRANSACTIONS_BODY_RESULT {
  records: TRANSACTION_TYPE[];
  metadata: METADATA_TYPE;
  params?: GET_TRANSACTIONS_PARAMS_REQUEST | null;
}

export interface TRANSACTION_LOG {
  wallet_transaction_id: string;
  actor_name: string;
  actor_id: string;
  content: {
    id: string;
    code: string;
    sr_id: string;
    amount: number;
    job_id: string;
    status: string;
    user_id: string;
    createdBy: string;
    updatedBy: string;
    booking_id: string;
    tax_amount: string;
    cash_txn_id: string;
    total_amount: number;
  };
  action: string;
  createdAt: string;
}

export interface TRANSACTION_DETAIL_TYPE {
  record: TRANSACTION_TYPE;
  logs: TRANSACTION_LOG[];
}

export enum WALLET_TRANSACTION_CODE {
  SERVICE_CHARGE = 'SC', // user pay for sr
  JOB_REFUND = 'JR', // refund job
  SERVICE_FEE = 'SF', // sitter receive credit for job done
  PAYMENT_RECEIVED = 'PR', // user top up credit
  PAYMENT_MADE = 'PM', // withdraw money
  CREDIT = 'CR', // Credit
  DEBIT = 'DR', // Debit
}

export enum TRANSACTION_CODE_DES {
  SC = 'Service Charge',
  JR = 'Job Refund',
  SF = 'Service Fee',
  PR = 'Payment Received',
  PM = 'Payment Made',
}

export interface TRANSACTION_TYPE_TYPE {
  id: string;
  title: string;
  code: string;
  sign: string;
  createdAt: string;
  default_include_in_payout: boolean;
  updatedAt: string;
  deletedAt: string | null;
}

export interface GET_DOC_REF_NO_PARAMS {
  user_id: string;
  type_id: string;
  search?: string;
  page?: number;
  limit?: number;
}

export interface DOC_REF_NO_RESPONSE {
  is_required_doc: boolean;
  data: {
    records: { id: string; number: string }[];
  };
}

export interface CREATE_TRANSACTION_BODY {
  type_id: string;
  user_id: string;
  doc_ref_no?: string;
  date?: string;
  time?: string;
  amount: string;
  remark?: string;
}

export interface UPDATE_TRANSACTION_BODY {
  date?: string;
  time?: string;
  amount?: string;
  remark?: string;
  is_update_balance?: boolean;
  include_in_payout?: boolean;
}

export interface UPDATE_TRANSACTION_TYPE_BODY {
  default_include_in_payout: boolean;
}
