import { FilterOption, FilterOptionType, HeadCell, NANNY_TYPE } from 'models';

export const headCells: readonly HeadCell<NANNY_TYPE>[] = [
  {
    id: 'firstName',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'First name',
  },
  {
    id: 'lastName',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Last Name',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Email',
  },
  {
    id: 'dateOfBirth',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Date of birth',
  },
  {
    id: 'startOfNannyExperience',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Experience (years)',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Status',
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Created At',
  },
];

export const filterOptions: FilterOption[] = [
  {
    name: 'First Name',
    key: 'firstName',
    type: FilterOptionType.TEXT,
    placeholder: 'Enter first name',
  },
  {
    name: 'Last Name',
    key: 'lastName',
    type: FilterOptionType.TEXT,
    placeholder: 'Enter last name',
  },
  {
    name: 'Email',
    key: 'email',
    type: FilterOptionType.TEXT,
    placeholder: 'Enter email',
  },
  {
    name: 'Status',
    key: 'status',
    type: FilterOptionType.SINGLE_SELECT,
  },
];
