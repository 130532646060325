import { AxiosResponse } from 'axios';
import { API } from 'config';
import {
  ADDRESS_DETAIL_PARENT_DETAIL_TYPE,
  ADDRESS_PARENT_DETAIL_TYPE,
  CHILDREN_PARENT_DETAIL_DETAIL_TYPE,
  CHILDREN_PARENT_DETAIL_TYPE,
  CREATE_OR_EDIT_CHILDREN_PARENT_DETAIL_BODY,
  GET_ADDRESSES_PARENT_DETAIL_PARAMS_REQUEST,
  GET_CHILDREN_PARENT_DETAIL_TYPE_PARAMS_REQUEST,
  GET_NOTES_BODY,
  GET_PARENTS_PARAMS_REQUEST,
  LIST_BODY_RESULT,
  PARENT_DETAIL_TYPE,
  PARENT_TYPE,
} from 'models';
import { AppAPIInstance } from 'services';

type Params = {
  queryKey: [string, { page: number; limit: number }];
};

export const getParentsApi = async (
  params: Params
): Promise<AxiosResponse<{ data: LIST_BODY_RESULT<PARENT_TYPE> }>> => {
  const [, { page, limit }] = params.queryKey;
  const res = await AppAPIInstance.get('/admin/parents', {
    params: {
      page,
      limit,
    },
  });
  return res.data;
};

export const getParentListApi = async (params?: GET_PARENTS_PARAMS_REQUEST) => {
  return await AppAPIInstance.get('/admin/parents', {
    params,
  });
};

export const getParentListDetailApi = async (
  id: string,
  type: string,
  params?: GET_PARENTS_PARAMS_REQUEST
) => {
  return await AppAPIInstance.get(`/admin/parents/${id}/${type}`, {
    params,
  });
};

export const createParentAddressApi = async (id: string, data: any) => {
  return await AppAPIInstance.post(`/admin/parents/${id}/addresses`, data);
};

export const getParentDetailApi = async (id: string) => {
  return await AppAPIInstance.get(`/admin/parents/${id}`);
};

export const getParentJobsDetailApi = async (id: string, idJobs: string) => {
  return await AppAPIInstance.get(`/admin/parents/${id}/jobs/${idJobs}`);
};

export const getParentChildrenApi = async (id: string) => {
  return await AppAPIInstance.get(`/admin/parents/${id}/children`);
};

export const getParentChildrenDetailApi = async (
  id: string,
  idChildren: string
): Promise<AxiosResponse<{ data: CHILDREN_PARENT_DETAIL_DETAIL_TYPE }>> => {
  return await AppAPIInstance.get(
    `/admin/parents/${id}/children/${idChildren}`
  );
};

export const getParentServiceRequestsDetailApi = async (
  id: string,
  idSR: string
) => {
  return await AppAPIInstance.get(
    `/admin/parents/${id}/service-requests/${idSR}`
  );
};

export const getParentAddressesApi = async (
  id: string,
  params?: GET_ADDRESSES_PARENT_DETAIL_PARAMS_REQUEST
): Promise<AxiosResponse<LIST_BODY_RESULT<ADDRESS_PARENT_DETAIL_TYPE>>> => {
  return await AppAPIInstance.get(
    API.ADMIN.PARENT.ADDRESS.DEFAULT.replace(':id', id),
    { params }
  );
};

export const getParentAddressDetailApi = async (
  id: string,
  idAddress: string
): Promise<AxiosResponse<{ data: ADDRESS_DETAIL_PARENT_DETAIL_TYPE }>> => {
  return await AppAPIInstance.get(
    `/admin/parents/${id}/addresses/${idAddress}`
  );
};

export const getParentAddressApi = async (id: string) => {
  return await AppAPIInstance.get(`/admin/parents/${id}/addresses`);
};

export const createParentApi = async (data: PARENT_DETAIL_TYPE) => {
  return await AppAPIInstance.post(`/admin/parents`, data);
};

export const editParentDetailApi = async (
  id: string,
  data?: PARENT_DETAIL_TYPE
) => {
  return await AppAPIInstance.put(`/admin/parents/${id}`, data);
};

export const editParentDetailAddressApi = async (
  id: string,
  idAddress: string,
  data?: { is_default: boolean }
) => {
  return await AppAPIInstance.put(
    `/admin/parents/${id}/addresses/${idAddress}`,
    data
  );
};

export const getParentChildrenListApi = async (
  id: string,
  params?: GET_CHILDREN_PARENT_DETAIL_TYPE_PARAMS_REQUEST
): Promise<AxiosResponse<LIST_BODY_RESULT<CHILDREN_PARENT_DETAIL_TYPE>>> => {
  return await AppAPIInstance.get(
    API.ADMIN.PARENT.CHILDREN.DEFAULT.replace(':id', id),
    { params }
  );
};

export const createParentChildrenDetailApi = async (
  id: string,
  data: CREATE_OR_EDIT_CHILDREN_PARENT_DETAIL_BODY
) => {
  return await AppAPIInstance.post(`/admin/parents/${id}/children`, data);
};

export const editParentChildrenDetailApi = async (
  id: string,
  idChildren: string,
  data: CREATE_OR_EDIT_CHILDREN_PARENT_DETAIL_BODY
) => {
  return await AppAPIInstance.put(
    `/admin/parents/${id}/children/${idChildren}`,
    data
  );
};

export const editParentAddressDetailApi = async (
  id: string,
  idAddress: string,
  data?: any
) => {
  return await AppAPIInstance.put(
    `/admin/parents/${id}/addresses/${idAddress}`,
    data
  );
};

export const editParentJobsDetailApi = async (
  id: string,
  idJobs: string,
  data?: any
) => {
  return await AppAPIInstance.put(`/admin/parents/${id}/jobs/${idJobs}`, data);
};

export const deleteParentListDetailApi = async (
  id: string,
  type: string,
  idType: string
) => {
  return await AppAPIInstance.delete(`/admin/parents/${id}/${type}/${idType}`);
};

export const deleteParentApi = async (id: string) => {
  return await AppAPIInstance.delete(`/admin/parents/${id}`);
};

export const deleteParentAllApi = async (ids: string[]) => {
  const idsObj = {
    ids,
  };
  return await AppAPIInstance.delete('/admin/parents', {
    data: idsObj,
  });
};

export const getParentsExportCSVApi = async (
  params?: Partial<GET_PARENTS_PARAMS_REQUEST>
) => {
  return await AppAPIInstance.get(API.ADMIN.PARENT.EXPORT, {
    params,
    responseType: 'blob',
  }).catch(async (e) => {
    return Promise.reject(
      JSON.parse((await e?.response?.data?.text()) || '{}')?.error
    );
  });
};

export const getListNotesApi = async (params?: {
  sitter_id?: string;
  parent_id?: string;
}): Promise<AxiosResponse<{ data: GET_NOTES_BODY }>> => {
  return await AppAPIInstance.get(API.ADMIN.PARENT.NOTES.DEFAULT, {
    params,
  });
};

export const createNoteApi = async (data: {
  sitter_id?: string;
  parent_id?: string;
  note: string;
}) => {
  return await AppAPIInstance.post(API.ADMIN.PARENT.NOTES.DEFAULT, data);
};
