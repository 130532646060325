import { HeadCell, REGIONS_TYPE } from 'models';

export const headCells: readonly HeadCell<REGIONS_TYPE>[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    isSort: true,
    label: 'Region',
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: true,
    isSort: true,
    label: 'Created Date',
  },
];
