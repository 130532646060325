import { takeLatest, call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  DELETE_PAYMENT_REQUEST,
  DELETE_TXN_FROM_PAYOUT_REQUEST,
  GET_PAYMENT_LIST_REQUEST,
  GET_PAYOUTS_DETAIL_REQUEST,
  GET_PAYOUTS_REQUEST,
  GET_TXN_FROM_PAYOUT_LIST_REQUEST,
} from 'store/reducers/payment/actionTypes';
import {
  deletePaymentApi,
  getPaymentApi,
  getPaymentListApi,
  getTransactionFromPayoutListApi,
  removeTransactionFromPayoutApi,
} from 'services';
import { AxiosResponse } from 'axios';
import {
  setPaymentListSuccess,
  setPayoutsDetailSuccess,
  setPayoutsSuccess,
  setTXNPayoutsSuccess,
} from 'store/reducers/payment';
import {
  GET_PAYMENT_PARAMS_REQUEST,
  GET_TXN_PAYOUT_PARAMS_REQUEST,
  PAYMENT_BODY_RESULT,
  PAYMENT_DETAIL_TYPE,
  TXN_PAYOUT_BODY_RESULT,
} from 'models';
import { setGlobalLoading } from 'store/reducers/global';
import { NotifyService } from 'helpers';

function* setPaymentListRequest(
  action: PayloadAction<{
    params?: GET_PAYMENT_PARAMS_REQUEST;
  }>
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<{
      data: PAYMENT_BODY_RESULT;
    }> = yield call(getPaymentListApi, action.payload.params);
    yield put(
      setPaymentListSuccess({
        records: response.data.data.records,
        metadata: response.data.data.metadata,
      })
    );
    yield put(setGlobalLoading(false));
  } catch (error: any) {
    NotifyService.error(error);
    yield put(setGlobalLoading(false));
  }
}

function* getPayoutRequest(
  action: PayloadAction<{
    params?: GET_PAYMENT_PARAMS_REQUEST;
  }>
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<{
      data: PAYMENT_BODY_RESULT;
    }> = yield call(getPaymentListApi, action.payload.params);
    yield put(
      setPayoutsSuccess({
        records: response.data.data.records,
        metadata: response.data.data.metadata,
      })
    );
    yield put(setGlobalLoading(false));
  } catch (error: unknown) {}
}

function* getPayoutsDetailRequest(
  action: PayloadAction<{
    id: string;
    onSuccess?: () => void;
  }>
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<{
      data: PAYMENT_DETAIL_TYPE;
    }> = yield call(getPaymentApi, action.payload.id);
    yield put(setPayoutsDetailSuccess(response.data.data));
    if (action.payload.onSuccess) action.payload.onSuccess();
    yield put(setGlobalLoading(false));
  } catch (error: unknown) {}
}

function* deletePaymentRequest(
  action: PayloadAction<{
    id: string;
    onSuccess?: () => void;
  }>
) {
  try {
    yield call(deletePaymentApi, action.payload.id);
    if (action.payload.onSuccess) action.payload.onSuccess();
  } catch (error: unknown) {}
}

function* removeTransactionFromPayoutRequest(
  action: PayloadAction<{
    data: any;
    onSuccess?: () => void;
  }>
) {
  try {
    yield put(setGlobalLoading(true));
    yield call(removeTransactionFromPayoutApi, action.payload.data);
    if (action.payload.onSuccess) action.payload.onSuccess();
    NotifyService.success('Success');
    yield put(setGlobalLoading(false));
  } catch (error: unknown) {
    NotifyService.error(error);
    yield put(setGlobalLoading(false));
  }
}

function* setTXNListInPayoutRequest(
  action: PayloadAction<{
    params?: GET_TXN_PAYOUT_PARAMS_REQUEST;
  }>
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<{
      data: TXN_PAYOUT_BODY_RESULT;
    }> = yield call(getTransactionFromPayoutListApi, action.payload.params);
    yield put(
      setTXNPayoutsSuccess({
        records: response.data.data.records,
        metadata: response.data.data.metadata,
      })
    );
    yield put(setGlobalLoading(false));
  } catch (error: unknown) {}
}

function* payment() {
  yield takeLatest(GET_PAYMENT_LIST_REQUEST, setPaymentListRequest);
  yield takeLatest(DELETE_PAYMENT_REQUEST, deletePaymentRequest);
  yield takeLatest(GET_PAYOUTS_REQUEST, getPayoutRequest);
  yield takeLatest(GET_PAYOUTS_DETAIL_REQUEST, getPayoutsDetailRequest);
  yield takeLatest(
    DELETE_TXN_FROM_PAYOUT_REQUEST,
    removeTransactionFromPayoutRequest
  );
  yield takeLatest(GET_TXN_FROM_PAYOUT_LIST_REQUEST, setTXNListInPayoutRequest);
}

export default payment;
