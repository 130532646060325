import { AxiosResponse } from 'axios';
import { API } from 'config';
import {
  CREATE_OR_UPDATE_REASON_BODY,
  GET_REASONS_LIST_PARAMS,
  LIST_BODY_RESULT_ROWS,
  REASON_TYPE,
  GET_REPORT_LIST_PARAMS,
  REPORT_TYPE,
  UPDATE_REPORT_STATUS_BODY,
} from 'models';
import { AppAPIInstance } from 'services';

export const getReasonListApi = async (
  params?: GET_REASONS_LIST_PARAMS
): Promise<AxiosResponse<{ data: LIST_BODY_RESULT_ROWS<REASON_TYPE> }>> => {
  return await AppAPIInstance.get(API.REPORT.REASON.DEFAULT, {
    params,
  });
};

export const getReasonDetailApi = async (
  id: string
): Promise<AxiosResponse<{ data: REASON_TYPE }>> => {
  return await AppAPIInstance.get(`${API.REPORT.REASON.DEFAULT}/${id}`);
};

export const deleteReasonApi = async (id: string) => {
  return await AppAPIInstance.delete(`${API.REPORT.REASON.DEFAULT}/${id}`);
};

export const deleteManyReasonsApi = async (ids: string[]) => {
  return await AppAPIInstance.delete(API.REPORT.REASON.DEFAULT, {
    data: {
      ids,
    },
  });
};

export const createReasonApi = async (data: CREATE_OR_UPDATE_REASON_BODY) => {
  return await AppAPIInstance.post(API.REPORT.REASON.DEFAULT, data);
};

export const updateReasonApi = async (
  id: string,
  data: CREATE_OR_UPDATE_REASON_BODY
) => {
  return await AppAPIInstance.put(`${API.REPORT.REASON.DEFAULT}/${id}`, data);
};

export const getReportListApi = async (
  params?: GET_REPORT_LIST_PARAMS
): Promise<AxiosResponse<{ data: LIST_BODY_RESULT_ROWS<REPORT_TYPE> }>> => {
  return await AppAPIInstance.get(API.REPORT.DEFAULT, {
    params,
  });
};

export const getReportDetailApi = async (
  id: string
): Promise<AxiosResponse<{ data: REPORT_TYPE }>> => {
  return await AppAPIInstance.get(`${API.REPORT.DEFAULT}/${id}`);
};

export const deleteReportApi = async (id: string) => {
  return await AppAPIInstance.delete(`${API.REPORT.DEFAULT}/${id}`);
};

export const deleteManyReportApi = async (ids: string[]) => {
  return await AppAPIInstance.delete(API.REPORT.DEFAULT, {
    data: {
      ids,
    },
  });
};

export const updateReportStatus = async (
  id: string,
  data: UPDATE_REPORT_STATUS_BODY
) => {
  return await AppAPIInstance.put(`${API.REPORT.DEFAULT}/${id}`, data);
};

export const updateImageApi = async (data: any) => {
  return await AppAPIInstance.post(`${API.REPORT.UPLOAD_IMG}`, data);
};
