import { HeadCell, STAFFS_TYPE } from 'models';

export const headCells: readonly HeadCell<STAFFS_TYPE>[] = [
  {
    id: 'full_name',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Admin Name',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Email',
  },
  {
    id: 'role',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Role',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Status',
  },
];
