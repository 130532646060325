import { AxiosResponse } from 'axios';
import { API } from 'config';
import {
  GET_CHAT_MESSAGES_PARAMS_REQUEST,
  GET_CHATS_PARAMS_REQUEST,
  DataListResponse,
  CHAT_TYPE,
  MESSAGE_CHAT,
  GET_CHAT_KEYWORD_TRACKING_LIST_PARAMS_REQUEST,
  CREATE_OR_UPDATE_CHAT_KEYWORD_TRACKING_BODY,
  LIST_BODY_RESULT,
  CHAT_KEYWORD_TRACKING
} from 'models';
import { AppAPIInstance } from 'services';

export const getChatListApi = async (params?: GET_CHATS_PARAMS_REQUEST): Promise<AxiosResponse<{ data: DataListResponse<CHAT_TYPE> }>> => {
  return await AppAPIInstance.get(API.CHAT.DEFAULT, {
    params,
  });
};

export const deleteChatApi = async (id: string) => {
  return await AppAPIInstance.delete(`${API.CHAT.DEFAULT}/${id}`);
}

export const getChatMessageListApi = async (chanelId: string, params?: GET_CHAT_MESSAGES_PARAMS_REQUEST): Promise<AxiosResponse<{ data: DataListResponse<MESSAGE_CHAT> }>> => {
  return await AppAPIInstance.get(API.CHAT.MESSAGES.DEFAULT.replace(":id", chanelId), {
    params,
  });
};

export const deleteChatMessageApi = async (chanelId: string, message_id: string): Promise<AxiosResponse<{ data: MESSAGE_CHAT }>> => {
  return await AppAPIInstance.delete( `${API.CHAT.MESSAGES.DEFAULT.replace(":id", chanelId)}/${message_id}`);
};

export const getChatKeywordTrackingListApi = async (params?: GET_CHAT_KEYWORD_TRACKING_LIST_PARAMS_REQUEST): Promise<AxiosResponse<{data: LIST_BODY_RESULT<CHAT_KEYWORD_TRACKING>}>> => {
  return await AppAPIInstance.get(API.CHAT.KEYWORD_TRACKING.DEFAULT, {
    params,
  });
};

export const getChatKeywordTrackingDetailApi = async (id: string): Promise<AxiosResponse<{ data: CHAT_KEYWORD_TRACKING }>> => {
  return await AppAPIInstance.get(`${API.CHAT.KEYWORD_TRACKING.DEFAULT}/${id}`);
};

export const createChatKeywordTrackingListApi = async (data: CREATE_OR_UPDATE_CHAT_KEYWORD_TRACKING_BODY) => {
  return await AppAPIInstance.post(API.CHAT.KEYWORD_TRACKING.DEFAULT, data);
};

export const updateChatKeywordTrackingListApi = async (id: string, data: CREATE_OR_UPDATE_CHAT_KEYWORD_TRACKING_BODY) => {
  return await AppAPIInstance.put(`${API.CHAT.KEYWORD_TRACKING.DEFAULT}/${id}`, data);
};

export const deleteChatKeywordTrackingListApi = async (id: string) => {
  return await AppAPIInstance.delete(`${API.CHAT.KEYWORD_TRACKING.DEFAULT}/${id}`);
};

export const deleteManyChatKeywordTrackingListApi = async (ids: string[]) => {
  return await AppAPIInstance.delete(API.CHAT.KEYWORD_TRACKING.DEFAULT, {
    data: {
      ids
    },
  });
};