import { GET_REPORT_LIST_PARAMS } from "models";

export const GET_REPORT_LIST_REQUEST: string = 'GET_REPORT_LIST_REQUEST';
export const DELETE_REPORT_REQUEST: string = 'DELETE_REPORT_REQUEST';

export const getReportListRequest = (params?: GET_REPORT_LIST_PARAMS | null) => {
  return {
    type: GET_REPORT_LIST_REQUEST,
    payload: { params }
  }
}

export const deleteReportRequest = (id: string, onSuccess?: () => void) => {
  return {
    type: DELETE_REPORT_REQUEST,
    payload: { id, onSuccess }
  };
};