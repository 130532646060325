export const optionStatus = [
  {
    name: 'Active',
    value: 'active',
  },
  {
    name: 'Inactive',
    value: 'inactive',
  },
];

export const optionStatusEmailOrPhone = [
  {
    name: 'Verified',
    value: true,
  },
  {
    name: 'Pending',
    value: false,
  },
];
