import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { Fragment, memo, useEffect } from 'react';
import AppRoute from 'routers';
import { useAppSelector } from 'store/hook';
import { theme } from 'theme';
// import Cookies from 'js-cookie';
import { StyledEngineProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { GlobalLoading } from 'components';
import * as CONSTANTS from 'config/constants';
import { useInitDateTime } from 'hooks';
import useIdleTimeout from 'hooks/useIdleTimeout';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setToken, setTokenNanny } from 'services';
import { useAppDispatch } from 'store/hook';
import { setLoggedInSuccess } from 'store/reducers/authentication';
import { getSettingConfigsRequest } from 'store/reducers/settings/actionType';

const queryClient = new QueryClient();

const App = memo((props: any) => {
  useInitDateTime();
  useIdleTimeout({ idleTime: 30 });
  const dispatch = useAppDispatch();

  const { isLoggedIn } = useAppSelector((state) => state.authenticationReducer);
  const { loading } = useAppSelector((state) => state.globalReducer);
  const token = localStorage.getItem(CONSTANTS.ACCESS_TOKEN);

  useEffect(() => {
    if (token) {
      setToken(token);
      setTokenNanny(token);
      dispatch(setLoggedInSuccess(true));
    } else {
      dispatch(setLoggedInSuccess(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getSettingConfigsRequest());
    }
  }, [isLoggedIn, dispatch]);

  return (
    <Fragment>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <StyledEngineProvider injectFirst>
            <CssBaseline />
            <AppRoute />
            <GlobalLoading open={loading} />
            <ToastContainer />
          </StyledEngineProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </Fragment>
  );
});

export default App;
