import { METADATA_TYPE } from './accounts';
import { OptionItem } from './global';
export enum FLAGS_STATUS {
  RAISED = 'raised',
  RESOLVED = 'resolved',
  FLAGGED = 'flagged',
  CANCEL = 'cancel',
}

export const FLAG_STATUSES: OptionItem[] = [
  {
    id: FLAGS_STATUS.RAISED,
    name: 'Raised',
  },
  {
    id: FLAGS_STATUS.FLAGGED,
    name: 'Flagged',
  },
  {
    id: FLAGS_STATUS.RESOLVED,
    name: 'Resolved',
  },
  {
    id: FLAGS_STATUS.CANCEL,
    name: 'Cancel',
  },
];

export const StatusColor: { [key: string]: string } = {
  [FLAGS_STATUS.FLAGGED]: '#34C759',
  [FLAGS_STATUS.RAISED]: '#FF3B30',
  [FLAGS_STATUS.RESOLVED]: '#226FE3',
  [FLAGS_STATUS.CANCEL]: '#B6B6B6',
};
export interface GET_USER_FLAG_LIST_PARAMS_REQUEST {
  page?: number;
  limit?: number;
  offset?: number;
  keyword?: string;
  sort?: string;
}
interface User {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
}

export interface UserFlag {
  flag: string;
  remark: string;
  createdAt: string;
}

export interface FLAG_TYPE {
  years_of_experience: number;
  salary_per_hour: number;
  rating: number;
  hours_worked: number;
  sitter_id: string;
  bio: string;
  profile_picture: string;
  star_sitter_badge: string;
  short_video: string;
  status: string;
  is_push_notification: boolean;
  is_email_notification: boolean;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  User: User;
  UserFlag: UserFlag;
}

export interface FLAG_LIST_STATE {
  records: FLAG_TYPE[];
  metadata: METADATA_TYPE;
  params?: GET_USER_FLAG_LIST_PARAMS_REQUEST | null | undefined;
}

export interface FLAG_BY_USER_TYPE {
  id: string;
  user_id: string;
  active: boolean;
  flag: string;
  remark: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: String;
}

export interface FLAG_BY_USER_STATE {
  data: FLAG_BY_USER_TYPE[];
}

export interface POST_CREATE_FLAG {
  user_id: string;
  flag: string;
  remark?: string;
}
