import {
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { HeadTable } from 'components';
import { TableCellNoData } from 'components/common/table';
import PopupCreateFlag from 'components/Popups/PopupCreateFlag';
import { useDateTime } from 'hooks';
import { ECLOSE_POPUP, FLAG_BY_USER_TYPE, StatusColor } from 'models';
import { memo, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { getFlagByUserListRequest } from 'store/reducers/user-flag/actionType';
import { headCells } from './data';

interface Props {
  sitterData?: {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    country_code: string;
    phone_number: string;
    email_verified: boolean;
    phone_number_verified: boolean;
  };
}

const Flags = memo(({ sitterData }: Props) => {
  const dispatch = useAppDispatch();
  const { formatDateTime } = useDateTime();
  const [isOpenCreate, setIsOpenCreate] = useState<boolean>(false);

  const { data: flagData } = useAppSelector(
    (state) => state.userFlagsReducer.flag.flag_by_id
  );

  const setOpenAdd = () => {
    setIsOpenCreate(true);
  };

  const onReloadList = () => {
    if (!sitterData?.id) return;
    dispatch(getFlagByUserListRequest(sitterData?.id));
  };

  const onClosePopup = (type: ECLOSE_POPUP) => {
    switch (type) {
      case ECLOSE_POPUP.CREATE_OR_EDIT:
        setIsOpenCreate(false);
        break;
    }
  };

  useEffect(() => {
    if (sitterData?.id) {
      dispatch(getFlagByUserListRequest(sitterData?.id));
    }
  }, [sitterData, dispatch]);

  return (
    <>
      <Stack direction="row" spacing="8px" justifyContent="flex-end" mb="16px">
        <Button
          variant="contained"
          color="secondary"
          sx={{
            height: 30,
            fontWeight: 600,
            fontSize: '13px',
            lineHeight: '100%',
          }}
          onClick={setOpenAdd}
        >
          Update Flag
        </Button>
      </Stack>
      <Stack spacing="8px">
        <TableContainer
          component={Paper}
          elevation={0}
          sx={{
            border: '1px solid #D9D9D9',
            maxHeight: 'calc(100vh - 395px)',
          }}
        >
          <Table
            stickyHeader
            sx={{ minWidth: 650 }}
            aria-labelledby="tableTitle"
          >
            <HeadTable<FLAG_BY_USER_TYPE>
              rowCount={flagData?.length}
              headCells={headCells}
              onAction={false}
              onCheckbox={false}
            />
            <TableBody
              sx={{
                height: 'auto !important',
              }}
            >
              {flagData?.length ? (
                flagData.map((row, index) => {
                  const labelId = `enhanced-sitter-tab-cb-${index}`;
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                          fontSize: '14px',
                          fontWeight: 400,
                          textTransform: 'capitalize',
                          color: StatusColor[row.flag || ''] || '#B6B6B6',
                        }}
                      >
                        {row.flag}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                          fontSize: '14px',
                          fontWeight: 400,
                        }}
                      >
                        {row.remark}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                          fontSize: '14px',
                          fontWeight: 400,
                        }}
                      >
                        {formatDateTime(row.createdAt)}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCellNoData colSpan={headCells.length + 2} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {isOpenCreate && (
          <PopupCreateFlag
            open={true}
            flag={flagData?.[0].flag}
            user={{
              id: flagData?.[0].user_id,
              first_name: sitterData?.first_name || '',
              last_name: sitterData?.last_name || '',
            }}
            remark={flagData?.[0].remark}
            onSuccess={onReloadList}
            handleClosePopup={() => onClosePopup(ECLOSE_POPUP.CREATE_OR_EDIT)}
          />
        )}
      </Stack>
    </>
  );
});

export default Flags;
