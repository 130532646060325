import { AxiosResponse } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  deleteParentAllApi,
  deleteParentApi,
  deleteParentListDetailApi,
  getChatListApi,
  getParentAddressesApi,
  getParentChildrenListApi,
  getParentDetailApi,
  getParentListApi,
  getPaymentListApi,
  getRequestsListApi,
  getTransactionsListApi,
} from 'services';
// import { PayloadAction } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';
import { TOAST } from 'config';
import { getMetadata } from 'helpers';
import {
  ADDRESS_PARENT_DETAIL_TYPE,
  CHAT_TYPE,
  CHILDREN_PARENT_DETAIL_TYPE,
  DataListResponse,
  GET_ACTIVITY_TAB_PARAMS_REQUEST,
  GET_ADDRESSES_PARENT_DETAIL_PARAMS_REQUEST,
  GET_CHATS_PARAMS_REQUEST,
  GET_CHILDREN_PARENT_DETAIL_TYPE_PARAMS_REQUEST,
  GET_JOBS_PARAMS_REQUEST,
  GET_PARENTS_PARAMS_REQUEST,
  GET_PAYMENT_PARAMS_REQUEST,
  GET_REQUESTS_PARAMS_REQUEST,
  GET_TRANSACTIONS_PARAMS_REQUEST,
  JOBS_BODY_RESULT,
  LIST_BODY_RESULT,
  PARENT_DETAIL_TYPE,
  PARENT_TYPE,
  PAYMENT_BODY_RESULT,
  REQUESTS_BODY_RESULT,
  TRANSACTIONS_BODY_RESULT,
} from 'models';
import { toast } from 'react-toastify';
import { nannyTabsApi } from 'services/accounts/nanny';
import { getJobsListApi } from 'services/jobs';
import {
  setParentActivitySuccess,
  setParentAddressesSuccess,
  setParentChatsSuccess,
  setParentChildrenListSuccess,
  setParentDetailSuccess,
  setParentJobsSuccess,
  // setParentListDetailSuccess,
  setParentListSuccess,
  setParentPaymentHistorySuccess,
  setParentRequestsSuccess,
  setParentTransactionsSuccess,
} from 'store/reducers/accounts';
import {
  DELETE_ALL_PARENT_REQUEST,
  DELETE_PARENT_DETAIL_REQUEST,
  DELETE_PARENT_REQUEST,
  GET_PARENT_ACTIVITY_REQUEST,
  GET_PARENT_ADDRESSES_REQUEST,
  GET_PARENT_CHATS_REQUEST,
  GET_PARENT_CHILDREN_LIST_REQUEST,
  GET_PARENT_DETAIL_REQUEST,
  GET_PARENT_JOBS_REQUEST,
  GET_PARENT_LIST_DETAIL_REQUEST,
  GET_PARENT_LIST_REQUEST,
  GET_PARENT_PAYMENT_HISTORY_REQUEST,
  GET_PARENT_TRANSACTIONS_REQUEST,
} from 'store/reducers/accounts/actionTypes';
import { setGlobalLoading, setLoadingPopup } from 'store/reducers/global';

function* getParentListRequest(
  action: PayloadAction<{
    params?: GET_PARENTS_PARAMS_REQUEST;
  }>
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<{
      data: LIST_BODY_RESULT<PARENT_TYPE>;
    }> = yield call(getParentListApi, action.payload.params);
    yield put(setGlobalLoading(false));
    yield put(setParentListSuccess(response.data.data));
  } catch (error: any) {
    toast.error(error, TOAST.error);
    yield put(setGlobalLoading(false));
  }
}

function* getParentDetailRequest(
  action: PayloadAction<{
    id: string;
    onSuccess?: () => void;
  }>
) {
  try {
    yield put(setLoadingPopup(true));
    const response: AxiosResponse<{
      data: PARENT_DETAIL_TYPE;
    }> = yield call(getParentDetailApi, action.payload.id);
    yield put(setParentDetailSuccess(response.data.data));
    if (action.payload.onSuccess) action.payload.onSuccess();
    yield put(setLoadingPopup(false));
  } catch (error: unknown) {}
}

function* getParentSRRequest(
  action: PayloadAction<{
    params?: GET_REQUESTS_PARAMS_REQUEST;
  }>
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<{
      data: REQUESTS_BODY_RESULT;
    }> = yield call(getRequestsListApi, action.payload.params);
    yield put(
      setParentRequestsSuccess({
        records: response.data.data.records,
        metadata: response.data.data.metadata,
      })
    );
    yield put(setGlobalLoading(false));
  } catch (error: unknown) {}
}

function* deleteParentRequest(
  action: PayloadAction<{
    id: string;
    onSuccess?: () => void;
  }>
) {
  try {
    yield call(deleteParentApi, action.payload.id);
    if (action.payload.onSuccess) action.payload.onSuccess();
  } catch (error: unknown) {}
}

function* deleteParentDetailRequest(
  action: PayloadAction<{
    id: string;
    idType: string;
    type: string;
    onSuccess?: () => void;
  }>
) {
  try {
    yield call(
      deleteParentListDetailApi,
      action.payload.id,
      action.payload.type,
      action.payload.idType
    );
    if (action.payload.onSuccess) action.payload.onSuccess();
  } catch (error: unknown) {}
}

function* deleteAllParentRequest(
  action: PayloadAction<{
    ids: any;
    onSuccess?: () => void;
  }>
) {
  try {
    yield call(deleteParentAllApi, action.payload.ids);
    if (action.payload.onSuccess) action.payload.onSuccess();
  } catch (error: unknown) {}
}

function* getParentPaymentHistoryRequest(
  action: PayloadAction<{
    params?: GET_PAYMENT_PARAMS_REQUEST;
  }>
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<{
      data: PAYMENT_BODY_RESULT;
    }> = yield call(getPaymentListApi, action.payload.params);
    yield put(
      setParentPaymentHistorySuccess({
        records: response.data.data.records,
        metadata: response.data.data.metadata,
      })
    );
    yield put(setGlobalLoading(false));
  } catch (error: unknown) {}
}

function* getParentChatsRequest(
  action: PayloadAction<{
    params?: GET_CHATS_PARAMS_REQUEST;
  }>
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<{
      data: DataListResponse<CHAT_TYPE>;
    }> = yield call(getChatListApi, action.payload.params);
    yield put(
      setParentChatsSuccess({
        records: response.data.data.rows,
        metadata: getMetadata(
          response.data.data,
          action.payload.params?.page,
          action.payload.params?.limit
        ),
      })
    );
    yield put(setGlobalLoading(false));
  } catch (error: unknown) {}
}

function* getParentAddressesRequest(
  action: PayloadAction<{
    id: string;
    params?: GET_ADDRESSES_PARENT_DETAIL_PARAMS_REQUEST;
  }>
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<
      LIST_BODY_RESULT<ADDRESS_PARENT_DETAIL_TYPE>
    > = yield call(
      getParentAddressesApi,
      action.payload.id,
      action.payload.params
    );
    yield put(
      setParentAddressesSuccess({
        records: response.data.records,
        metadata: response.data.metadata,
      })
    );
    yield put(setGlobalLoading(false));
  } catch (error: unknown) {}
}

function* getParentChildrenRequest(
  action: PayloadAction<{
    id: string;
    params?: GET_CHILDREN_PARENT_DETAIL_TYPE_PARAMS_REQUEST;
  }>
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<
      LIST_BODY_RESULT<CHILDREN_PARENT_DETAIL_TYPE>
    > = yield call(
      getParentChildrenListApi,
      action.payload.id,
      action.payload.params
    );
    yield put(
      setParentChildrenListSuccess({
        records: response.data.records,
        metadata: response.data.metadata,
      })
    );
    yield put(setGlobalLoading(false));
  } catch (error: unknown) {}
}

function* getParentTransactionsRequest(
  action: PayloadAction<{
    params?: GET_TRANSACTIONS_PARAMS_REQUEST;
  }>
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<{
      data: TRANSACTIONS_BODY_RESULT;
    }> = yield call(getTransactionsListApi, action.payload.params);
    yield put(
      setParentTransactionsSuccess({
        records: response.data.data.records,
        metadata: response.data.data.metadata,
      })
    );
    yield put(setGlobalLoading(false));
  } catch (error: unknown) {}
}

function* getParentJobsRequest(
  action: PayloadAction<{
    params?: GET_JOBS_PARAMS_REQUEST;
  }>
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<JOBS_BODY_RESULT> = yield call(
      getJobsListApi,
      action.payload.params
    );
    yield put(
      setParentJobsSuccess({
        records: response.data.records,
        metadata: response.data.metadata,
      })
    );
    yield put(setGlobalLoading(false));
  } catch (error: unknown) {}
}

function* getParentActivityRequest(
  action: PayloadAction<{
    params?: GET_ACTIVITY_TAB_PARAMS_REQUEST;
  }>
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<any> = yield call(
      nannyTabsApi.getActivity,
      action.payload.params
    );
    yield put(setParentActivitySuccess(response.data));
    yield put(setGlobalLoading(false));
  } catch (error: unknown) {}
}

function* parents() {
  yield takeLatest(GET_PARENT_LIST_REQUEST, getParentListRequest);
  yield takeLatest(DELETE_PARENT_REQUEST, deleteParentRequest);
  yield takeLatest(DELETE_PARENT_DETAIL_REQUEST, deleteParentDetailRequest);
  yield takeLatest(DELETE_ALL_PARENT_REQUEST, deleteAllParentRequest);
  yield takeLatest(GET_PARENT_DETAIL_REQUEST, getParentDetailRequest);
  yield takeLatest(GET_PARENT_LIST_DETAIL_REQUEST, getParentSRRequest);
  yield takeLatest(
    GET_PARENT_PAYMENT_HISTORY_REQUEST,
    getParentPaymentHistoryRequest
  );
  yield takeLatest(GET_PARENT_CHATS_REQUEST, getParentChatsRequest);
  yield takeLatest(GET_PARENT_ADDRESSES_REQUEST, getParentAddressesRequest);
  yield takeLatest(GET_PARENT_CHILDREN_LIST_REQUEST, getParentChildrenRequest);
  yield takeLatest(GET_PARENT_ACTIVITY_REQUEST, getParentActivityRequest);
  yield takeLatest(
    GET_PARENT_TRANSACTIONS_REQUEST,
    getParentTransactionsRequest
  );
  yield takeLatest(GET_PARENT_JOBS_REQUEST, getParentJobsRequest);
}

export default parents;
