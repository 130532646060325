import {
  Button,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { CheckCircleMuiIcon } from 'asset';
import {
  HeadTable,
  PopupActionNannyiesInBooking,
  PopupConfirm,
} from 'components';
import { TableCellNoData } from 'components/common/table';
import { NotifyService } from 'helpers';
import {
  NANNY_IN_BOOKING_STATUS,
  NANNY_IN_BOOKING_TYPE,
  POPUP_ACTION,
  TYPE_POPUP,
  nannyInBookingStatusColor,
  nannyInBookingStatusName,
} from 'models';
import { memo, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { nannyBookingApi } from 'services';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setGlobalLoading } from 'store/reducers/global';
import { getListNannyInBookingAction } from 'store/reducers/nanny-bookings/actionTypes';
import { headCells } from './data';

interface NanniesInBookingProps {
  bookingId: string;
}

export const NanniesInBooking = memo(({ bookingId }: NanniesInBookingProps) => {
  const dispatch = useAppDispatch();
  const [popupAction, setPopupAction] =
    useState<POPUP_ACTION<NANNY_IN_BOOKING_TYPE>>();

  const { nannies } = useAppSelector(
    (state) => state.nannyBookingsReducer.booking_detail_tab
  );

  useEffect(() => {
    dispatch(getListNannyInBookingAction({ data: { id: bookingId } }));
  }, [bookingId, dispatch]);

  const onShowPopupAction = (
    type: TYPE_POPUP | string,
    data?: NANNY_IN_BOOKING_TYPE
  ) => {
    setPopupAction({ type: type, data });
  };

  const onClosePopupAction = () => {
    setPopupAction(undefined);
  };

  const onReload = () => {
    dispatch(getListNannyInBookingAction({ data: { id: bookingId } }));
  };

  const onSelectNannyInBooking = async () => {
    if (!popupAction?.data) return;
    dispatch(setGlobalLoading(true));
    await nannyBookingApi
      .selectNannyInBooking(bookingId, popupAction?.data?.nannyId)
      .then(() => {
        onReload();
        onClosePopupAction();
        NotifyService.success('Success');
      })
      .catch((e) => NotifyService.error(e))
      .finally(() => dispatch(setGlobalLoading(false)));
  };

  return (
    <>
      <Stack direction="row" spacing="8px" justifyContent="flex-end" mb="16px">
        <Button
          variant="contained"
          color="secondary"
          sx={{
            height: 30,
            fontWeight: 600,
            fontSize: '13px',
            lineHeight: '100%',
          }}
          onClick={() => onShowPopupAction(TYPE_POPUP.ADD)}
        >
          Add/Remove Nanny
        </Button>
      </Stack>
      <Stack spacing="8px">
        <TableContainer
          component={Paper}
          elevation={0}
          sx={{
            border: '1px solid #D9D9D9',
            maxHeight: 'calc(100vh - 395px)',
          }}
        >
          <Table
            stickyHeader
            sx={{ minWidth: 650 }}
            aria-labelledby="tableTitle"
          >
            <HeadTable<NANNY_IN_BOOKING_TYPE>
              rowCount={nannies?.data.length}
              headCells={headCells}
              onCheckbox={false}
            />
            <TableBody
              sx={{
                height: 'auto !important',
              }}
            >
              {nannies?.data?.length ? (
                nannies.data.map((row, index) => {
                  const labelId = `enhanced-nannies-tab-cb-${index}`;
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                          fontSize: '14px',
                          fontWeight: 400,
                        }}
                      >
                        <Link to={`/accounts/nanny/${row.nannyId}`}>
                          {row.nannyName}
                        </Link>
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                          fontSize: '14px',
                          fontWeight: 400,
                        }}
                      >
                        {row.email}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                          fontSize: '14px',
                          fontWeight: 400,
                        }}
                      >
                        {row.number}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                          fontSize: '14px',
                          fontWeight: 400,
                          minWidth: '110px',
                        }}
                      >
                        <Grid
                          container
                          alignItems="center"
                          textTransform="capitalize"
                        >
                          <Grid
                            sx={{
                              width: '8px',
                              height: '8px',
                              borderRadius: '50%',
                              marginRight: '5px',
                              backgroundColor:
                                nannyInBookingStatusColor[row.status] ??
                                '#B6B6B6',
                            }}
                          />
                          <Typography>
                            {nannyInBookingStatusName[row.status]}
                          </Typography>
                        </Grid>
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                        }}
                      >
                        <Button
                          variant="contained"
                          color="secondary"
                          startIcon={
                            <CheckCircleMuiIcon sx={{ fontSize: 18 }} />
                          }
                          sx={{
                            height: '32px',
                            p: '7px 10px',
                            fontSize: '14px',
                            fontWeight: 400,
                            lineHeight: '100%',
                          }}
                          onClick={() =>
                            onShowPopupAction(
                              NANNY_IN_BOOKING_STATUS.ASSIGNED,
                              row
                            )
                          }
                        >
                          Assign
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCellNoData colSpan={headCells.length + 1} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      {popupAction?.type === TYPE_POPUP.ADD && (
        <PopupActionNannyiesInBooking
          bookingId={bookingId}
          onClose={onClosePopupAction}
          onSuccess={onReload}
        />
      )}
      {popupAction?.type === NANNY_IN_BOOKING_STATUS.ASSIGNED && (
        <PopupConfirm
          open
          title="Assign?"
          description="Are you sure you want to assign?"
          onClose={onClosePopupAction}
          onConfirm={onSelectNannyInBooking}
        />
      )}
    </>
  );
});

export default NanniesInBooking;
