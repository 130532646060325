import styled from '@emotion/styled';
import { FormHelperText, InputLabel } from '@mui/material';
import { theme } from 'theme';

export const StyledFormHelperText = styled(FormHelperText)`
  font-size: 16px;
  letter-spacing: 0.045em;
  color: ${theme.palette.white.light};
`;

export const StyledInputLabel = styled(InputLabel)`
  font-weight: 500;
  font-size: 15px;
  line-height: 100%;
  color: #000000;
  margin-bottom: 5px;
`;
