import React from 'react';

const IconCreate = ({ ...props }) => {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={40} height={40} rx={20} fill="#226FE3" fillOpacity="0.2" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 20C30 25.5228 25.5228 30 20 30C14.4772 30 10 25.5228 10 20C10 14.4772 14.4772 10 20 10C25.5228 10 30 14.4772 30 20ZM20 14.75C18.7574 14.75 17.75 15.7574 17.75 17C17.75 17.4142 17.4142 17.75 17 17.75C16.5858 17.75 16.25 17.4142 16.25 17C16.25 14.9289 17.9289 13.25 20 13.25C22.0711 13.25 23.75 14.9289 23.75 17C23.75 18.6424 22.6946 20.0363 21.2273 20.5444C21.0697 20.5989 20.9366 20.6873 20.8517 20.7813C20.7708 20.8708 20.75 20.9441 20.75 21V22C20.75 22.4142 20.4142 22.75 20 22.75C19.5858 22.75 19.25 22.4142 19.25 22V21C19.25 19.9975 20.0578 19.3619 20.7365 19.1269C21.6185 18.8216 22.25 17.9836 22.25 17C22.25 15.7574 21.2426 14.75 20 14.75ZM20 26C20.5523 26 21 25.5523 21 25C21 24.4477 20.5523 24 20 24C19.4477 24 19 24.4477 19 25C19 25.5523 19.4477 26 20 26Z"
        fill="#226FE3"
      />
    </svg>
  );
};

export default IconCreate;
