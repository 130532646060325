import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CASH_TRANSACTION_CODE,
  GET_PAYMENT_PARAMS_REQUEST,
  GET_TXN_PAYOUT_PARAMS_REQUEST,
  PAYMENT_BODY_PARAMS_RESULT,
  PAYMENT_BODY_RESULT,
  PAYMENT_DETAIL_TYPE,
  TXN_PAYOUT_BODY_RESULT,
} from 'models';

export interface paymentType {
  payment: {
    payment_list: PAYMENT_BODY_PARAMS_RESULT;
    payouts: PAYMENT_BODY_PARAMS_RESULT;
    payment_detail: any | null;
    payouts_detail: PAYMENT_DETAIL_TYPE | null;
    txn_payout_list: TXN_PAYOUT_BODY_RESULT;
  };
}

export const initialPaymentState: paymentType = {
  payment: {
    payouts: {
      params: {
        page: 1,
        limit: 50,
        code: CASH_TRANSACTION_CODE.PAYOUT,
      },
      records: [],
      metadata: {
        page: 1,
        limit: 50,
        page_count: 1,
        total_pages: 1,
        total_count: 1,
      },
    },
    payment_list: {
      records: [],
      metadata: {
        page: 1,
        limit: 50,
        page_count: 1,
        total_pages: 1,
        total_count: 1,
      },
      params: {
        page: 1,
        limit: 50,
        code: CASH_TRANSACTION_CODE.CASH_FEE,
      },
    },
    payment_detail: null,
    payouts_detail: null,
    txn_payout_list: {
      params: {
        page: 1,
        limit: 10,
      },
      records: [],
      metadata: {
        page: 1,
        limit: 10,
        page_count: 1,
        total_pages: 1,
        total_count: 1,
      },
    },
  },
};

const paymentSlice = createSlice({
  name: 'payment',
  initialState: initialPaymentState,
  reducers: {
    setPaymentListSuccess(
      state: paymentType,
      action: PayloadAction<PAYMENT_BODY_RESULT>
    ) {
      state.payment.payment_list = {
        ...state.payment.payment_list,
        ...action.payload,
      };
    },
    setPaymentDetailSuccess(
      state: paymentType,
      action: PayloadAction<any | null>
    ) {
      state.payment.payment_detail = action.payload;
    },
    setPayoutsDetailSuccess(
      state: paymentType,
      action: PayloadAction<PAYMENT_DETAIL_TYPE | null>
    ) {
      state.payment.payouts_detail = action.payload;
    },
    setPaymentListParams(
      state: paymentType,
      action: PayloadAction<GET_PAYMENT_PARAMS_REQUEST | null>
    ) {
      state.payment.payment_list.params = action.payload;
    },
    setPayoutsParams(
      state: paymentType,
      action: PayloadAction<GET_PAYMENT_PARAMS_REQUEST>
    ) {
      state.payment.payouts.params = action.payload;
    },
    setPayoutsSuccess(
      state: paymentType,
      action: PayloadAction<PAYMENT_BODY_RESULT>
    ) {
      state.payment.payouts = {
        ...state.payment.payouts,
        ...action.payload,
      };
    },
    setTXNPayoutsParams(
      state: paymentType,
      action: PayloadAction<GET_TXN_PAYOUT_PARAMS_REQUEST | null>
    ) {
      state.payment.txn_payout_list.params = action.payload;
    },
    setTXNPayoutsSuccess(
      state: paymentType,
      action: PayloadAction<TXN_PAYOUT_BODY_RESULT>
    ) {
      state.payment.txn_payout_list = {
        ...state.payment.txn_payout_list,
        ...action.payload,
      };
    },
  },
});

// Actions
export const {
  setPaymentListSuccess,
  setPaymentDetailSuccess,
  setPaymentListParams,
  setPayoutsParams,
  setPayoutsSuccess,
  setPayoutsDetailSuccess,
  setTXNPayoutsParams,
  setTXNPayoutsSuccess,
} = paymentSlice.actions;

export default paymentSlice.reducer;
