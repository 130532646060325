import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { HeadTable } from 'components';
import PopupEditTransactionType from 'components/Popups/Transaction/PopupEditTransactionType';
import { ButtonEdit } from 'components/common/table';
import { TRANSACTION_TYPE_TYPE, TYPE_POPUP } from 'models';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { getTransactionsTypeListRequest } from 'store/reducers/transactions/actionTypes';
import { headCells } from './data';

const TransactionType = () => {
  const dispatch = useAppDispatch();
  const { transactions_type_list } = useAppSelector(
    (state) => state.transactionsReducer.transactionsType
  );
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [itemAction, setItemAction] = useState<TRANSACTION_TYPE_TYPE>();

  useEffect(() => {
    dispatch(getTransactionsTypeListRequest());
  }, [dispatch]);

  const setOpenEdit = (event: any, data: TRANSACTION_TYPE_TYPE) => {
    event.stopPropagation();
    setItemAction(data);
    setOpenEditPopup(true);
  };

  const onClosePopup = (type: number) => {
    switch (type) {
      case TYPE_POPUP.CREATE_OR_EDIT:
        setOpenEditPopup(false);
        setItemAction(undefined);
        break;
    }
  };

  const onReloadList = () => {
    dispatch(getTransactionsTypeListRequest());
  };

  return (
    <Stack spacing="8px">
      <TableContainer
        component={Paper}
        elevation={0}
        sx={{
          border: '1px solid #D9D9D9',
          maxHeight: 'calc(100vh - 280px)',
        }}
      >
        <Table stickyHeader sx={{ minWidth: 650 }} aria-labelledby="tableTitle">
          <HeadTable<TRANSACTION_TYPE_TYPE>
            rowCount={transactions_type_list.length}
            headCells={headCells}
            onCheckbox={false}
          />
          <TableBody
            sx={{
              height: 'auto !important',
            }}
          >
            {transactions_type_list.map((row, index) => {
              return (
                <TableRow hover tabIndex={-1} key={row.id}>
                  <TableCell
                    align="left"
                    component="th"
                    scope="row"
                    sx={{
                      borderRight: '1px solid #DEE2E6',
                      padding: '18px 12px 17px',
                      fontSize: '14px',
                      fontWeight: 400,
                    }}
                  >
                    {row.title}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      borderRight: '1px solid #DEE2E6',
                      padding: '18px 12px 17px',
                      fontSize: '14px',
                      fontWeight: 400,
                    }}
                  >
                    {row.code}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      borderRight: '1px solid #DEE2E6',
                      padding: '18px 12px 17px',
                      fontSize: '14px',
                      fontWeight: 400,
                    }}
                  >
                    {row.default_include_in_payout ? 'Yes' : 'No'}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      borderRight: '1px solid #DEE2E6',
                      padding: '18px 12px 17px',
                      width: '209px',
                    }}
                  >
                    <Stack direction="row" spacing="10px">
                      <ButtonEdit onClick={(e) => setOpenEdit(e, row)} />
                    </Stack>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {openEditPopup && itemAction && (
        <PopupEditTransactionType
          open={true}
          subject={itemAction?.id}
          onSuccess={onReloadList}
          handleClosePopup={() => onClosePopup(TYPE_POPUP.CREATE_OR_EDIT)}
        />
      )}
    </Stack>
  );
};

export default TransactionType;
