import { DataListResponse, METADATA_TYPE } from "models";

export const getMetadata = (data: DataListResponse, page: number = 1, limit: number = 10): METADATA_TYPE => {
  return {
    page: page,
    limit: limit,
    page_count: data.rows.length,
    total_pages: Math.ceil(data.count / limit),
    total_count: data.count
  }
}