import {
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { Box } from '@mui/system';
import { visuallyHidden } from '@mui/utils';
import { HeadCell, Order } from 'models';
import React from 'react';

interface EnhancedTableProps<T> {
  numSelected?: number;
  onRequestSort?: (event: React.MouseEvent<unknown>, property: keyof T) => void;
  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order?: Order;
  orderBy?: string;
  rowCount: number;
  headCells: readonly HeadCell<T>[];
  onCheckbox?: boolean;
  onAction?: boolean;
  checkboxOnly?: boolean;
}

const HeadTable = <T,>({
  onSelectAllClick,
  order,
  orderBy,
  numSelected = 0,
  rowCount,
  onRequestSort,
  headCells,
  onCheckbox = true,
  onAction = true,
  checkboxOnly = false,
}: EnhancedTableProps<T>) => {
  const createSortHandler =
    (property: keyof T) => (event: React.MouseEvent<unknown>) => {
      onRequestSort && onRequestSort(event, property);
    };

  return (
    <TableHead
      sx={{
        backgroundColor: '#F1F1F1',
      }}
    >
      <TableRow>
        {onCheckbox && (
          <TableCell
            padding="checkbox"
            sx={{
              borderBottom: '3px solid #DEE2E6',
              borderRight: '1px solid #DEE2E6',
              zIndex: 1,
            }}
          >
            {!checkboxOnly && (
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{
                  'aria-label': 'select all desserts',
                }}
              />
            )}
          </TableCell>
        )}

        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id as string}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              borderBottom: '3px solid #DEE2E6',
              borderRight: '1px solid #DEE2E6',
              fontWeight: 600,
              padding: '15px 12px',
              lineHeight: '100%',
              whiteSpace: 'nowrap',
              zIndex: 0,
            }}
          >
            {headCell.isSort ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              <>{headCell.label}</>
            )}
          </TableCell>
        ))}
        {onAction && (
          <TableCell
            align="left"
            padding="none"
            sx={{
              borderBottom: '3px solid #DEE2E6',
              borderRight: '1px solid #DEE2E6',
              fontWeight: 600,
              padding: '15px 12px',
              lineHeight: '100%',
            }}
          >
            Action
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};

export default HeadTable;
