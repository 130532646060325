import {
  Button,
  Checkbox,
  Paper,
  Rating,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  useTheme,
} from '@mui/material';
import usePagination from '@mui/material/usePagination/usePagination';
import { DocumentDownload, SolidStar } from 'asset';
import {
  DeleteDialog,
  HeadTable,
  PopupEditReview,
  TableCaption,
} from 'components';
import {
  ButtonDelete,
  ButtonEdit,
  TableCellNoData,
} from 'components/common/table';
import FileSaver from 'file-saver';
import { getSortItem, NotifyService } from 'helpers';
import { useDateTime } from 'hooks';
import {
  DELETE_DIALOG_TYPE,
  ECLOSE_POPUP,
  GET_REVIEWS_PARAMS_REQUEST,
  REVIEW_TYPE,
} from 'models';
import moment from 'moment';
import { memo, useEffect, useMemo, useState } from 'react';
import { getReviewExportCSVApi } from 'services';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setSitterReviewsParams } from 'store/reducers/accounts';
import { getSitterReviewsRequest } from '../../../../../store/reducers/accounts/actionTypes';
import { headCells } from './data';

interface Props {
  sitterId?: string;
  onSuccess: any;
}

const Reviews = memo(({ sitterId, onSuccess }: Props) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { formatDateTime } = useDateTime();
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [itemAction, setItemAction] = useState<REVIEW_TYPE>();
  const [idReview, setIdReview] = useState('');
  const [isOpenCreate, setIsOpenCreate] = useState<boolean>(false);

  const { reviews } = useAppSelector(
    (state) => state.accountReducer.sitters.sitter_list_detail
  );

  const { params } = reviews;

  const onSetListParams = (params: GET_REVIEWS_PARAMS_REQUEST) => {
    dispatch(setSitterReviewsParams(params));
  };

  useEffect(() => {
    if (sitterId) {
      dispatch(getSitterReviewsRequest(sitterId, params));
    }
  }, [params, sitterId, dispatch]);

  const setOpenAddReview = () => {
    setIsOpenCreate(true);
  };

  const onReloadList = () => {
    if (!sitterId) return;
    dispatch(getSitterReviewsRequest(sitterId, params));
    onSuccess();
  };

  const handleChangeRowsPerPage = (event: SelectChangeEvent) => {
    const limit = parseInt(event.target.value, 10);
    onSetListParams({ ...params, page: 1, limit });
  };

  const { items } = usePagination({
    count: reviews.metadata.total_pages,
    page: reviews.metadata.page,
    onChange(_event, page) {
      onSetListParams({ ...params, page: page });
    },
  });

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = reviews?.records?.map((n: any) => n?.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (_: any, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const onClosePopup = (type: ECLOSE_POPUP) => {
    switch (type) {
      case ECLOSE_POPUP.DELETE:
        setOpenDeletePopup(false);
        setItemAction(undefined);
        break;
      case ECLOSE_POPUP.CREATE_OR_EDIT:
        // setOpenEditPopup(false);
        setIsOpenCreate(false);
        setItemAction(undefined);
        break;
    }
  };

  const setOpenDelete = (event: any, data: REVIEW_TYPE) => {
    event.stopPropagation();
    setIdReview(data.job_id);
    setOpenDeletePopup(true);
  };

  const setOpenEdit = (event: any, data: REVIEW_TYPE) => {
    event.stopPropagation();
    setItemAction(data);
  };

  const sortItem = useMemo(() => {
    return getSortItem(params?.sort);
  }, [params]);

  const handleRequestSort = (_: any, property: keyof REVIEW_TYPE) => {
    setSelected([]);
    const isAsc = sortItem?.orderBy === property && sortItem?.order === 'asc';
    onSetListParams({
      ...params,
      sort: `${property}-${isAsc ? 'desc' : 'asc'}`,
    });
  };

  const onExportCSV = async () => {
    await getReviewExportCSVApi({
      user_id: sitterId,
    })
      .then((res) => {
        FileSaver.saveAs(
          res.data,
          `${moment().format('YYYY-MM-DD')} Sitters.csv`
        );
      })
      .catch((e) => NotifyService.error(e));
  };

  return (
    <>
      <Stack direction="row" spacing="8px" justifyContent="flex-end" mb="16px">
        <Button
          sx={{
            width: '131px',
            height: 30,
            fontWeight: 600,
            fontSize: '13px',
            border: `1px solid ${theme.palette.secondary.main}`,
            p: '0px',
          }}
          variant="outlined"
          color="secondary"
          endIcon={<DocumentDownload />}
          onClick={onExportCSV}
        >
          Export to CSV
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            height: 30,
            fontWeight: 600,
            fontSize: '13px',
            lineHeight: '100%',
          }}
          onClick={setOpenAddReview}
        >
          Add Review
        </Button>
      </Stack>
      <Stack spacing="8px">
        <TableContainer
          component={Paper}
          elevation={0}
          sx={{
            border: '1px solid #D9D9D9',
            maxHeight: 'calc(100vh - 395px)',
          }}
        >
          <Table
            stickyHeader
            sx={{ minWidth: 650 }}
            aria-labelledby="tableTitle"
          >
            <HeadTable<REVIEW_TYPE>
              numSelected={selected.length}
              order={sortItem?.order}
              orderBy={sortItem?.orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={reviews?.records.length}
              headCells={headCells}
            />
            <TableBody
              sx={{
                height: 'auto !important',
              }}
            >
              {reviews?.records?.length ? (
                reviews.records.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-sitter-tab-cb-${index}`;
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell
                        padding="checkbox"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                        }}
                      >
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          onChange={(e) => handleClick(e, row.id)}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                          fontSize: '14px',
                          fontWeight: 400,
                        }}
                      >
                        {row.job_number}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                          fontSize: '14px',
                          fontWeight: 400,
                          color: '#226FE3',
                        }}
                      >
                        {row.parent?.first_name}&nbsp;{row.parent?.last_name}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                          fontSize: '14px',
                          fontWeight: 400,
                        }}
                      >
                        {row.content}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                          fontSize: '14px',
                          fontWeight: 400,
                        }}
                      >
                        {row.is_anonymous ? 'Yes' : 'No'}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                          fontSize: '14px',
                          fontWeight: 400,
                        }}
                      >
                        <Rating
                          name="read-only"
                          value={row.rate}
                          readOnly
                          icon={<SolidStar fontSize="16px" />}
                          emptyIcon={
                            <SolidStar color="#D1D1D6" fontSize="16px" />
                          }
                        />
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                          fontSize: '14px',
                          fontWeight: 400,
                        }}
                      >
                        {formatDateTime(row.createdAt)}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                        }}
                      >
                        <Stack direction="row" spacing="10px">
                          <ButtonEdit onClick={(e) => setOpenEdit(e, row)} />
                          <ButtonDelete
                            onClick={(e) => setOpenDelete(e, row)}
                          />
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCellNoData colSpan={headCells.length + 2} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TableCaption
          rowsPerPage={params?.limit ?? 0}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          metadata={reviews?.metadata}
          items={items}
        />
      </Stack>

      {openDeletePopup && (
        <DeleteDialog
          name=""
          open={openDeletePopup}
          onClose={() => onClosePopup(ECLOSE_POPUP.DELETE)}
          subject={idReview}
          onSuccess={onReloadList}
          type={DELETE_DIALOG_TYPE.REVIEW}
        />
      )}
      <PopupEditReview
        open={isOpenCreate || !!itemAction}
        data={itemAction}
        onSuccess={onReloadList}
        handleClosePopup={() => onClosePopup(ECLOSE_POPUP.CREATE_OR_EDIT)}
        sitterId={sitterId}
      />
    </>
  );
});

export default Reviews;
