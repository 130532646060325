import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Avatar,
  Collapse,
  Divider,
  Drawer,
  List,
  ListItem,
  // ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  listItemButtonClasses,
} from '@mui/material';
import { Stack } from '@mui/system';
import {
  Barcode,
  ChartSquare,
  DangerOutline,
  Flag,
  ListIcon,
  Logout,
  ProfileCircle,
  ReceiptItem,
  SettingOutline,
  StarOutline,
  UserTag,
} from 'asset/icons';
import MenuLineHorizontal from 'asset/icons/MenuLineHorizontal';
import { drawerWidth } from 'config';
// import Cookies from 'js-cookie';
import React, { Fragment, useEffect, useState } from 'react';
import { matchPath, useLocation } from 'react-router';
import { LinkProps, Link as RouterLink } from 'react-router-dom';

interface ListItemLinkProps {
  icon?: (active: boolean) => React.ReactElement;
  primary: string;
  to: string;
}

const data_list_menu = [
  {
    label: 'Dashboard',
    href: '/',
    icon: (active: boolean) => <ChartSquare active={active} />,
  },
  {
    label: 'Accounts',
    href: '',
    icon: (active: boolean) => <ProfileCircle active={active} />,
    child: [
      { label: 'Parents', href: '/accounts/parents' },
      { label: 'Sitters', href: '/accounts/sitters' },
      { label: 'Nanny', href: '/accounts/nanny' },
      { label: 'Admins', href: '/accounts/admins' },
      { label: 'All User', href: '/accounts/users' },
    ],
  },
  {
    label: 'Requests',
    href: '',
    icon: (active: boolean) => <UserTag active={active} />,
    child: [
      { label: 'Just Once', href: '/requests/just-once' },
      { label: 'Recurring', href: '/requests/recurring' },
    ],
  },
  {
    label: 'Jobs',
    href: '/jobs',
    icon: (active: boolean) => <ListIcon active={active} />,
  },
  {
    label: 'Nanny Booking',
    href: '/nanny-bookings',
    icon: (active: boolean) => <ListIcon active={active} />,
  },

  {
    label: 'Finance',
    href: '',
    icon: (active: boolean) => <ReceiptItem active={active} />,
    child: [
      { label: 'Transactions', href: '/finance/transactions' },
      { label: 'Top-ups', href: '/finance/top-ups' },
      { label: 'Payouts', href: '/finance/payouts' },
    ],
  },
  {
    label: 'Reports',
    href: '/reports',
    icon: (active: boolean) => <DangerOutline active={active} />,
  },
  {
    label: 'Promotions',
    href: '/promotions',
    icon: (active: boolean) => <Barcode active={active} />,
  },
  {
    label: 'Reviews',
    href: '/reviews',
    icon: (active: boolean) => <StarOutline active={active} />,
  },
  {
    label: 'User Flags',
    href: '/user-flags',
    icon: (active: boolean) => <Flag active={active} />,
  },
  {
    label: 'Settings',
    href: '/settings',
    icon: (active: boolean) => <SettingOutline active={active} />,
  },
  {
    label: 'Finance Settings',
    href: '/finance-settings',
    icon: (active: boolean) => <SettingOutline active={active} />,
  },
];

const ListItemLink = (props: ListItemLinkProps) => {
  const { icon, primary, to } = props;
  const { pathname } = useLocation();

  const renderLink = React.useMemo(
    () =>
      React.forwardRef<HTMLAnchorElement, Omit<LinkProps, 'to'>>(function Link(
        itemProps,
        ref
      ) {
        return <RouterLink to={to} ref={ref} {...itemProps} role={undefined} />;
      }),
    [to]
  );

  return (
    <ListItem
      disablePadding
      sx={{
        padding: '2px 10px 2px 9px',
      }}
    >
      <ListItemButton
        sx={{
          padding: '7px 12px',
          borderRadius: '5px',
          color: '#666666',
          ':hover': {
            background: '#F1F1F1',
          },
          [`&.${listItemButtonClasses.selected}`]: {
            color: 'white.light',
            backgroundColor: 'primary.main',
            ':hover': {
              backgroundColor: 'primary.main',
            },
          },
        }}
        selected={Boolean(matchPath(to, pathname)?.pathname!)}
        component={renderLink}
      >
        <ListItemIcon
          sx={{
            minWidth: icon ? 'fit-content' : '24px',
          }}
        >
          {icon ? icon(Boolean(matchPath(to, pathname)?.pathname!)) : null}
        </ListItemIcon>
        <ListItemText
          primary={primary}
          sx={{
            marginLeft: '16px',
            my: '0px',
          }}
        />
      </ListItemButton>
    </ListItem>
  );
};

const MenuLeft = () => {
  const [openAccounts, setOpenAccounts] = useState(false);
  const [openRequests, setOpenRequests] = useState(false);
  const [openTransactions, setOpenTransactions] = useState(false);
  const { pathname } = useLocation();

  const handleClick = (txt: string) => {
    switch (txt) {
      case 'Accounts':
        setOpenAccounts(!openAccounts);
        break;
      case 'Requests':
        setOpenRequests(!openRequests);
        break;
      case 'Finance':
        setOpenTransactions(!openTransactions);
        break;
    }
  };

  const onClickLogout = () => {
    localStorage.clear();
    window.location.reload();
  };

  useEffect(() => {
    if (
      Boolean(matchPath('/accounts/parents', pathname)?.pathname!) ||
      Boolean(matchPath('/accounts/sitters', pathname)?.pathname!) ||
      Boolean(matchPath('/accounts/admins', pathname)?.pathname!) ||
      Boolean(matchPath('/accounts/users', pathname)?.pathname!) ||
      Boolean(matchPath('/accounts/nanny', pathname)?.pathname!)
    )
      setOpenAccounts(true);
    if (
      Boolean(matchPath('/finance/transactions', pathname)?.pathname!) ||
      Boolean(matchPath('/finance/top-ups', pathname)?.pathname!) ||
      Boolean(matchPath('/finance/payouts', pathname)?.pathname!)
    )
      setOpenTransactions(true);
    if (
      Boolean(matchPath('/requests/just-once', pathname)?.pathname!) ||
      Boolean(matchPath('/requests/recurring', pathname)?.pathname!)
    )
      setOpenRequests(true);
  }, [pathname]);

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Toolbar
        sx={{
          justifyContent: 'space-between',
          padding: { xs: '0px 12px 0px 20px' },
        }}
      >
        <Stack direction="row" spacing="15px">
          <Stack>
            <MenuLineHorizontal />
          </Stack>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '16px',
              lineHeight: '100%',
              display: 'flex',
              alignItems: 'center',
              color: '#8E8E93',
            }}
          >
            Aunty CMS
          </Typography>
        </Stack>
        <Avatar
          sx={{ background: 'rgba(255, 121, 110, 0.2)', width: 30, height: 30 }}
        >
          N
        </Avatar>
      </Toolbar>
      <Divider />
      <List
        disablePadding
        sx={{
          paddingTop: '23px',
        }}
      >
        {data_list_menu.map((item, index) => {
          if (index === 1 || index === 2 || index === 5) {
            return (
              <Fragment key={item.label}>
                <ListItem
                  key={item.label}
                  disablePadding
                  sx={{
                    padding: '2px 10px 2px 9px',
                  }}
                >
                  <ListItemButton
                    sx={{
                      padding: '7px 12px',
                      borderRadius: '5px',
                      color: '#666666',
                      ':hover': {
                        background: '#F1F1F1',
                      },
                      [`&.${listItemButtonClasses.selected}`]: {
                        color: 'white.light',
                        backgroundColor: 'primary.main',
                        ':hover': {
                          backgroundColor: 'primary.main',
                        },
                      },
                    }}
                    selected={
                      index === 1
                        ? openAccounts
                        : index === 2
                        ? openRequests
                        : openTransactions
                    }
                    onClick={() => handleClick(item.label)}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 'fit-content',
                      }}
                    >
                      {item.icon(
                        index === 1
                          ? openAccounts
                          : index === 2
                          ? openRequests
                          : openTransactions
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.label}
                      sx={{
                        marginLeft: '16px',
                        my: '0px',
                      }}
                    />
                    {index === 1 ? (
                      openAccounts ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )
                    ) : index === 2 ? (
                      openRequests ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )
                    ) : openTransactions ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </ListItemButton>
                </ListItem>
                <Collapse
                  in={
                    index === 1
                      ? openAccounts
                      : index === 2
                      ? openRequests
                      : openTransactions
                  }
                >
                  <List disablePadding>
                    {item.child?.map((item) => (
                      <ListItemLink
                        primary={item.label}
                        to={item.href}
                        key={item.label}
                      />
                    ))}
                  </List>
                </Collapse>
              </Fragment>
            );
          }
          if (item.href) {
            return (
              <ListItemLink
                icon={item.icon}
                primary={item.label}
                to={item.href}
                key={item.label}
              />
            );
          }
          return (
            <ListItem
              disablePadding
              sx={{
                padding: '2px 10px 2px 9px',
              }}
              key={item.label}
            >
              <ListItemButton
                sx={{
                  padding: '7px 12px',
                  borderRadius: '5px',
                  color: '#666666',
                  ':hover': {
                    background: '#F1F1F1',
                  },
                  [`&.${listItemButtonClasses.selected}`]: {
                    color: 'white.light',
                    backgroundColor: 'primary.main',
                    ':hover': {
                      backgroundColor: 'primary.main',
                    },
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 'fit-content',
                  }}
                >
                  {item.icon(false)}
                </ListItemIcon>
                <ListItemText
                  primary={item.label}
                  sx={{
                    marginLeft: '16px',
                    my: '0px',
                  }}
                />
              </ListItemButton>
            </ListItem>
          );
        })}
        <ListItem
          disablePadding
          sx={{
            padding: '2px 10px 2px 9px',
          }}
        >
          <ListItemButton
            sx={{
              padding: '7px 12px',
              borderRadius: '5px',
              color: '#666666',
              ':hover': {
                background: '#F1F1F1',
              },
              [`&.${listItemButtonClasses.selected}`]: {
                color: 'white.light',
                backgroundColor: 'primary.main',
                ':hover': {
                  backgroundColor: 'primary.main',
                },
              },
            }}
            onClick={() => onClickLogout()}
          >
            <ListItemIcon
              sx={{
                minWidth: 'fit-content',
              }}
            >
              <Logout active={false} />
            </ListItemIcon>
            <ListItemText
              primary={'Logout'}
              sx={{
                marginLeft: '16px',
                my: '0px',
              }}
            />
          </ListItemButton>
        </ListItem>
      </List>
      {/* <List
        sx={{
          width: '100%',
          maxWidth: 360,
          bgcolor: 'background.paper',
          paddingTop: '23px',
          color: '#666666 !important',
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        {data_list_menu.map((item, index) => {
          switch (item.label) {
            case 'Accounts':
              return (
                <Stack key={index} p="2px 10px 2px 9px">
                  <ListItemButton
                    sx={
                      openAccounts
                        ? {
                            p: '8px 11px',
                            height: '38px',
                            borderRadius: '5px',
                            color: '#ffffff',
                            bgcolor: 'primary.main',
                            '&:hover': {
                              bgcolor: 'primary.main',
                            },
                          }
                        : { p: '8px 11px', height: '38px', borderRadius: '5px' }
                    }
                    onClick={() => handleClick('acc')}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: '40px',
                      }}
                    >
                      {item.icon(openAccounts)}
                    </ListItemIcon>
                    <ListItemText primary={item.label} />
                    {openAccounts ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={openAccounts} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding sx={{ pt: '4px' }}>
                      {item.child?.map((item, index) => (
                        <Stack
                          key={index}
                          sx={{
                            padding: '2px 0px',
                          }}
                        >
                          <ListItemButton
                            sx={{
                              height: '38px',
                              pl: '51px',
                              borderRadius: '5px',
                              // backgroundColor: '#F1F1F1',
                            }}
                          >
                            <ListItemText primary={item.label} />
                          </ListItemButton>
                        </Stack>
                      ))}
                    </List>
                  </Collapse>
                </Stack>
              );
            case 'Requests':
              return (
                <Stack key={index} p="2px 10px 2px 9px">
                  <ListItemButton
                    sx={
                      openRequests
                        ? {
                            p: '8px 11px',
                            height: '38px',
                            borderRadius: '5px',
                            color: '#ffffff',
                            bgcolor: 'primary.main',
                            '&:hover': {
                              bgcolor: 'primary.main',
                            },
                          }
                        : { p: '8px 11px', height: '38px', borderRadius: '5px' }
                    }
                    onClick={() => handleClick('req')}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: '40px',
                      }}
                    >
                      {item.icon(openRequests)}
                    </ListItemIcon>
                    <ListItemText primary={item.label} />
                    {openRequests ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={openRequests} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding sx={{ pt: '4px' }}>
                      {item.child?.map((item, index) => (
                        <Stack
                          key={index}
                          sx={{
                            padding: '2px 0px',
                          }}
                        >
                          <ListItemButton
                            key={index}
                            sx={{
                              height: '38px',
                              pl: '51px',
                              borderRadius: '5px',
                            }}
                          >
                            <ListItemText primary={item.label} />
                          </ListItemButton>
                        </Stack>
                      ))}
                    </List>
                  </Collapse>
                </Stack>
              );
            case 'Finance':
              return (
                <Stack key={index} p="2px 10px 2px 9px">
                  <ListItemButton
                    sx={
                      openTransactions
                        ? {
                            p: '8px 11px',
                            height: '38px',
                            borderRadius: '5px',
                            color: '#ffffff',
                            bgcolor: 'primary.main',
                            '&:hover': {
                              bgcolor: 'primary.main',
                            },
                          }
                        : { p: '8px 11px', height: '38px', borderRadius: '5px' }
                    }
                    onClick={() => handleClick('trans')}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: '40px',
                      }}
                    >
                      {item.icon(openTransactions)}
                    </ListItemIcon>
                    <ListItemText primary={item.label} />
                    {openTransactions ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={openTransactions} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding sx={{ pt: '4px' }}>
                      {item.child?.map((item, index) => (
                        <Stack
                          key={index}
                          sx={{
                            padding: '2px 0px',
                          }}
                        >
                          <ListItemButton
                            sx={{
                              height: '38px',
                              pl: '51px',
                              borderRadius: '5px',
                            }}
                          >
                            <ListItemText primary={item.label} />
                          </ListItemButton>
                        </Stack>
                      ))}
                    </List>
                  </Collapse>
                </Stack>
              );
            case 'Logout':
              return (
                <Stack key={index} p="1px 10px 1px 9px" sx={{}}>
                  <ListItemButton
                    sx={
                      false
                        ? {
                            height: '38px',
                            borderRadius: '5px',
                            color: '#ffffff',
                            bgcolor: 'primary.main',
                            '&:hover': {
                              bgcolor: 'primary.main',
                            },
                          }
                        : { height: '38px', borderRadius: '5px' }
                    }
                    onClick={onClickLogout}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: '40px',
                      }}
                    >
                      {item.icon(false)}
                    </ListItemIcon>
                    <ListItemText primary={item.label} />
                  </ListItemButton>
                </Stack>
              );
            default:
              return (
                <Stack key={index} p="2px 10px 2px 9px" sx={{}}>
                  <ListItemButton
                    sx={
                      false
                        ? {
                            p: '8px 11px',
                            height: '38px',
                            borderRadius: '5px',
                            color: '#ffffff',
                            bgcolor: 'primary.main',
                            '&:hover': {
                              bgcolor: 'primary.main',
                            },
                          }
                        : { p: '8px 11px', height: '38px', borderRadius: '5px' }
                    }
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: '40px',
                      }}
                    >
                      {item.icon(false)}
                    </ListItemIcon>
                    <ListItemText primary={item.label} />
                  </ListItemButton>
                </Stack>
              );
          }
        })}
      </List> */}
    </Drawer>
  );
};

export default MenuLeft;
