import {
  Button,
  buttonClasses,
  Checkbox,
  Menu,
  Paper,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useTheme,
  InputAdornment,
  InputBase,
  inputBaseClasses,
} from '@mui/material';
import { DirectionDown, Magnifier } from 'asset';
import {
  DeleteDialog,
  HeadTable,
  TableCaption,
  PopupCreateOrEditChatKeywordTracking,
} from 'components';
import React, { useState, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hook';
import usePagination from '@mui/material/usePagination/usePagination';
import {
  DELETE_DIALOG_TYPE,
  GET_CHAT_KEYWORD_TRACKING_LIST_PARAMS_REQUEST,
  CHAT_KEYWORD_TRACKING,
  TYPE_POPUP,
} from 'models';
import { getSortItem, NotifyService } from 'helpers';
import { headCells } from './data';
import { ButtonDelete, ButtonEdit } from 'components/common/table';
import { setChatKeywordTrackingListParams } from 'store/reducers/settings';
import { getChatKeywordTrackingListRequest } from 'store/reducers/settings/actionType';
import DeleteAllDialog from 'components/common/DeleteAllDialog';
import { deleteManyChatKeywordTrackingListApi } from 'services';
import useDebounce from 'hooks/useDebounce';
import { useDateTime } from 'hooks';

const ChatKeywordTracking = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { formatDateTime } = useDateTime();
  const { chatKeywordTrackingList } = useAppSelector(
    (state) => state.settingsReducer.chatKeywordTrackings
  );
  const { params } = chatKeywordTrackingList;
  const [anchorElAction, setAnchorElAction] = useState<null | HTMLElement>(
    null
  );
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [keyword, setKeyword] = useState('');
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [openCreatePopup, setOpenCreatePopup] = useState(false);
  const [itemAction, setItemAction] = useState<CHAT_KEYWORD_TRACKING>();
  const [openDeleteAll, setOpenDeleteAll] = useState<boolean>(false);

  const onSetListParams = (
    params: GET_CHAT_KEYWORD_TRACKING_LIST_PARAMS_REQUEST | null
  ) => {
    dispatch(setChatKeywordTrackingListParams(params));
  };

  useEffect(() => {
    dispatch(getChatKeywordTrackingListRequest(params));
  }, [params, dispatch]);

  useEffect(() => {
    setKeyword(params?.keyword ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setOpenDelete = (event: any, data: CHAT_KEYWORD_TRACKING) => {
    event.stopPropagation();
    setItemAction(data);
    setOpenDeletePopup(true);
  };

  const setOpenEdit = (event: any, data: CHAT_KEYWORD_TRACKING) => {
    event.stopPropagation();
    setItemAction(data);
    setOpenEditPopup(true);
  };

  const setOpenCreate = () => {
    setOpenCreatePopup(true);
  };

  const handleOpenBulkAction = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElAction(event.currentTarget);
  };

  const onSetOpenDeleteAll = () => {
    if (!selected?.length) return;
    setOpenDeleteAll(true);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = chatKeywordTrackingList.records.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (_: any, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangeRowsPerPage = (event: SelectChangeEvent) => {
    const limit = parseInt(event.target.value, 10);
    onSetListParams({ ...params, page: 1, limit });
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const { items } = usePagination({
    count: chatKeywordTrackingList.metadata.total_pages,
    page: chatKeywordTrackingList.metadata.page,
    onChange(_event, page) {
      onSetListParams({ ...params, page: page });
    },
  });

  const onSetKeywordParams = useDebounce((keyword: string) => {
    const _params = { ...params, page: 1 };
    if (keyword) _params.keyword = keyword;
    else delete _params.keyword;
    onSetListParams(_params);
  }, 250);

  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(event.target.value);
    onSetKeywordParams(event.target.value);
  };

  const onClosePopup = (type: number) => {
    switch (type) {
      case TYPE_POPUP.DELETE:
        setOpenDeletePopup(false);
        setItemAction(undefined);
        break;
      case TYPE_POPUP.CREATE_OR_EDIT:
        setOpenCreatePopup(false);
        setOpenEditPopup(false);
        setItemAction(undefined);
        break;
      case TYPE_POPUP.DELETE_ALL:
        setOpenDeleteAll(false);
        break;
    }
  };

  const onReloadList = () => {
    dispatch(getChatKeywordTrackingListRequest(params));
  };

  const deleteMany = async () => {
    const ids = selected as string[];
    await deleteManyChatKeywordTrackingListApi(ids)
      .then((r) => {
        onReloadList();
        setSelected([]);
        onClosePopup(TYPE_POPUP.DELETE_ALL);
        NotifyService.success(r);
      })
      .catch((e) => NotifyService.error(e))
      .finally(() => {});
  };

  const sortItem = useMemo(() => {
    return getSortItem(chatKeywordTrackingList.params?.sort);
  }, [chatKeywordTrackingList.params]);

  const handleRequestSort = (
    _: React.MouseEvent<unknown>,
    property: keyof CHAT_KEYWORD_TRACKING
  ) => {
    setSelected([]);
    const isAsc = sortItem?.orderBy === property && sortItem?.order === 'asc';
    onSetListParams({
      ...params,
      sort: `${property}-${isAsc ? 'desc' : 'asc'}`,
    });
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between" mb="16px">
        <InputBase
          placeholder="Search"
          sx={{
            maxWidth: '210px',
            height: '30px',
            flexGrow: 1,
            // paddingRight: '9px',
            background: '#F2F4F6',
            border: '1px solid #D9D9D9',
            p: '0px 7px 0px 11px',
            borderRadius: '5px',
            [`& .${inputBaseClasses.input}`]: {
              fontSize: 14,
              fontWeight: 400,
              lineHeight: '100%',
              p: 0,
              height: 'fit-content',
            },
          }}
          onChange={onChangeSearch}
          // autoComplete=""
          value={keyword}
          endAdornment={
            <InputAdornment position="end">
              <Magnifier />
            </InputAdornment>
          }
        />
        <Stack direction="row" spacing="8px" justifyContent="flex-end">
          <Button
            sx={{
              background: '#fff',
              border: `1px solid #226FE3`,
              borderRadius: '3px',
              boxShadow: 'none',

              color: '#226FE3',
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '100%',
              textTransform: 'none',

              p: '6px 16px',
              height: '30px',
              alignItems: 'flex-end',
              ':hover': {
                background: 'rgba(34, 111, 227, 0.08)',
                border: `1px solid ${theme.palette.secondary.main}`,
                borderRadius: '3px',
                boxShadow: 'none',
              },
              [`& .${buttonClasses.endIcon}`]: {
                mb: '-3px',
                ml: '3px',
              },
            }}
            variant="contained"
            endIcon={<DirectionDown />}
            onClick={handleOpenBulkAction}
          >
            Bulk Action
          </Button>
          <Menu
            sx={{
              mt: '45px',
              '& .MuiList-padding': {
                pt: '0px !important',
                pb: '0px !important',
              },
            }}
            id="menu-appbar"
            anchorEl={anchorElAction}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElAction)}
            onClose={() => setAnchorElAction(null)}
          >
            <Stack
              sx={{
                width: '319px',
                p: '14px 22px 25px 23px',
                flexDirection: 'column',
                alignItems: 'flex-start',
                ':hover': {
                  backgroundColor: 'white.light',
                },
              }}
            >
              <Stack spacing="10px">
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: '16px',
                    lineHeight: '100%',
                  }}
                >
                  Bulk Actions
                </Typography>
                <Button
                  variant="outlined"
                  color="secondary"
                  sx={{
                    p: 0,
                    fontWeight: 600,
                    fontSize: '15px',
                    lineHeight: 1,
                    width: '230px',
                    height: '32px',
                    borderRadius: '10px',
                    '& .MuiButton-startIcon': {
                      mr: '5px',
                    },
                  }}
                  onClick={onSetOpenDeleteAll}
                >
                  Delete Selected Keywords
                </Button>
              </Stack>
            </Stack>
          </Menu>
          <Button
            variant="contained"
            color="secondary"
            sx={{
              height: 30,
              fontWeight: 600,
              fontSize: '13px',
              lineHeight: '100%',
            }}
            onClick={setOpenCreate}
          >
            Create
          </Button>
        </Stack>
      </Stack>
      <Stack spacing="8px">
        <TableContainer
          component={Paper}
          elevation={0}
          sx={{
            border: '1px solid #D9D9D9',
            maxHeight: 'calc(100vh - 280px)',
          }}
        >
          <Table
            stickyHeader
            sx={{ minWidth: 650 }}
            aria-labelledby="tableTitle"
          >
            <HeadTable<CHAT_KEYWORD_TRACKING>
              numSelected={selected.length}
              order={sortItem?.order}
              orderBy={sortItem?.orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={chatKeywordTrackingList.records.length}
              headCells={headCells}
            />
            <TableBody
              sx={{
                height: 'auto !important',
              }}
            >
              {chatKeywordTrackingList.records.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    <TableCell
                      padding="checkbox"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                      }}
                    >
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        onChange={(e) => handleClick(e, row.id)}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align="left"
                      component="th"
                      scope="row"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                      }}
                    >
                      {row.keyword}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                      }}
                    >
                      {formatDateTime(row.createdAt)}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        width: '209px',
                      }}
                    >
                      <Stack direction="row" spacing="10px">
                        <ButtonEdit onClick={(e) => setOpenEdit(e, row)} />
                        <ButtonDelete onClick={(e) => setOpenDelete(e, row)} />
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TableCaption
          rowsPerPage={params?.limit ?? 0}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          metadata={chatKeywordTrackingList.metadata}
          items={items}
        />
      </Stack>

      {((openEditPopup && itemAction) || openCreatePopup) && (
        <PopupCreateOrEditChatKeywordTracking
          open={true}
          subject={itemAction?.id}
          onSuccess={onReloadList}
          handleClosePopup={() => onClosePopup(TYPE_POPUP.CREATE_OR_EDIT)}
        />
      )}
      {openDeletePopup && (
        <DeleteDialog
          name=""
          open={openDeletePopup}
          onClose={() => onClosePopup(TYPE_POPUP.DELETE)}
          subject={itemAction?.id}
          onSuccess={onReloadList}
          type={DELETE_DIALOG_TYPE.CHAT_KEYWORD_TRACKING}
        />
      )}
      {openDeleteAll && (
        <DeleteAllDialog
          open={openDeleteAll}
          onClose={() => setOpenDeleteAll(false)}
          onClickDelete={deleteMany}
        />
      )}
    </>
  );
};

export default ChatKeywordTracking;
