import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { CloseCircle } from 'asset';
import { LoadingPopup } from 'components/common';
import { InputFile, Inputs, Select } from 'components/form-control';
import { GetOptionsResponse } from 'components/form-control/Select';
import { TOAST } from 'config';
import yup from 'config/yup.custom';
import { NotifyService } from 'helpers';
import {
  CERTIFICATE_ASSOCIATION_TYPE,
  CERTIFICATE_TYPE,
  GET_NANNY_TAB_PARAMS_REQUEST,
  UPDATE_NANNY_CERTIFICATE_ASSOCIATION_TYPE,
} from 'models';
import { useEffect, useMemo, useState } from 'react';
import { ControllerRenderProps, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { updateImageApi } from 'services';
import { nannyTabsApi } from 'services/accounts/nanny';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setNannyDetailSuccess } from 'store/reducers/accounts';

interface PopupCreateOrEditNannyProps {
  nannyId: string;
  subject?: CERTIFICATE_ASSOCIATION_TYPE;
  cleanDetail?: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

interface IFormInputs {
  documentDescription: string;
  document: File;
  certificateId: { id: string; label: string };
  nannyId: string;
  urlDocument: string;
}

const PopupCreateNannyCertificateAssociation = ({
  subject,
  cleanDetail,
  onClose,
  onSuccess,
  nannyId,
}: PopupCreateOrEditNannyProps) => {
  const { loadingPopup } = useAppSelector((state) => state.globalReducer);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [fileImg, setFileImg] = useState<File>();

  const schema = useMemo(() => {
    return yup.object().shape({
      documentDescription: yup
        .string()
        .required('Certificate Name is required'),
      document: yup.mixed().required('Attachment is required'),
    });
  }, []);

  const form = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const {
    reset,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = form;

  const urlDocument = watch('urlDocument');

  useEffect(() => {
    if (subject) {
      reset({
        certificateId: subject.certificateAssociationId
          ? { id: subject.certificateAssociationId, label: `${subject.title}` }
          : undefined,
        nannyId: nannyId,
        documentDescription: subject.title || '',
        urlDocument: subject.icon,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subject]);

  const onSubmit = async (data: IFormInputs) => {
    setLoading(true);
    // setValue('id', nannyId);
    const _data: UPDATE_NANNY_CERTIFICATE_ASSOCIATION_TYPE = {
      document: fileImg,
      documentDescription: data.documentDescription,
      nannyId: nannyId,
      certificateId: data?.certificateId?.id,
    };

    await nannyTabsApi
      .uploadCertificateAssociation(_data)
      .then((r) => {
        NotifyService.success('Success');
        onSuccess();
        _onClose();
      })
      .catch((e) => {
        NotifyService.error(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const _onClose = () => {
    onClose?.();
    if (cleanDetail) {
      dispatch(setNannyDetailSuccess(null));
    }
  };

  const handleChangeImg = async (
    e: React.ChangeEvent<HTMLInputElement>,
    _: ControllerRenderProps<any, string>
  ) => {
    const files = e?.target?.files;
    if (!files) return;
    setFileImg(files[0]);
    setValue('document', files[0]);
    const formData = new FormData();
    formData.append('image', files[0]);
    setLoading(true);
    await updateImageApi(formData)
      .then((r) => {
        if (r.data.message === 'Success') {
          setValue('urlDocument', r.data.data);
        }
      })
      .catch(() => {
        toast.error('Upload Photo Failed', TOAST.error);
      })
      .finally(() => {
        setLoading(false);
      });
    e.target.files = null;
  };

  const getOptions =
    (key: keyof IFormInputs) =>
    async (page: number = 1, limit: number = 20, keyword?: string) => {
      let data: GetOptionsResponse | null = null;

      switch (key) {
        case 'certificateId':
          const paramsCertificate: GET_NANNY_TAB_PARAMS_REQUEST = {
            pageNum: page,
            pageSize: limit,
            nannyId,
            includeDeleted: false,
          };
          data = await nannyTabsApi
            .getCertificate(paramsCertificate)
            .then(({ data }) => {
              return {
                metadata: data.data.metadata,
                options: data.data.map((it: CERTIFICATE_TYPE) => ({
                  id: it.id,
                  label: `${it.title} `,
                })),
              };
            })
            .catch((e) => {
              NotifyService.error(e);
              return null;
            });
          break;
      }

      return data;
    };

  return (
    <Dialog
      open
      sx={{
        '& .MuiPaper-root': {
          width: 523,
          height: 'auto',
          borderRadius: 0,
        },
      }}
    >
      <DialogTitle
        sx={{
          padding: '20px 25px',
        }}
      >
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '100%',
            }}
          >
            {subject ? 'Edit' : 'Add'} Certificate
          </Typography>
          <IconButton
            onClick={_onClose}
            sx={{
              padding: 0,
            }}
          >
            <CloseCircle />
          </IconButton>
        </Grid>
      </DialogTitle>
      {(loadingPopup || loading) && <LoadingPopup color="primary" />}
      <DialogContent
        sx={{
          p: '30px 24px 50px 26px',
        }}
        dividers
      >
        <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
          <Stack height="100%" justifyContent="space-between">
            <Stack spacing="16px">
              <Stack>
                <Select
                  isDisabled={!!subject}
                  title="Certificate"
                  name="certificateId"
                  form={form}
                  isHasMore
                  filterFunc={false}
                  getOptions={getOptions('certificateId')}
                />
              </Stack>
              <Stack>
                <Inputs
                  title="Certificate Name"
                  placeholder="Certificate Name"
                  form={form}
                  name="documentDescription"
                />
              </Stack>
              <Stack>
                <Stack>
                  <InputFile
                    form={form}
                    nameFile={urlDocument}
                    errorsName={errors.document?.message}
                    name="document"
                    title="Attachment (image or PDF)"
                    accept="/*"
                    handleChange={handleChangeImg}
                    sx={{
                      width: 118,
                      height: 34,
                      boxSizing: 'border-box',
                    }}
                  />
                </Stack>
                {urlDocument && (
                  <Box width="109px" sx={{ position: 'relative' }}>
                    <div
                      style={{
                        position: 'absolute',
                        zIndex: 2,
                        background: '#302d2d6b',
                        width: '109px',
                      }}
                    />
                    <img src={urlDocument} alt="" width="109" />
                  </Box>
                )}
              </Stack>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              marginTop="25px"
              spacing="8px"
            >
              <Button
                variant="outlined"
                color="secondary"
                sx={{
                  width: '233.5px',
                  height: '30px',
                }}
                onClick={_onClose}
              >
                Cancel
              </Button>
              <Button
                color="secondary"
                variant="contained"
                sx={{
                  width: '233.5px',
                  height: '30px',
                }}
                type="submit"
              >
                {subject ? 'Update' : 'Create'}
              </Button>
            </Stack>
          </Stack>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default PopupCreateNannyCertificateAssociation;
