import { Stack, Typography } from '@mui/material';
import { ToggleButtonGroupStyled, ToggleButtonStyled } from './styles';

interface Props {
  value: boolean;
  onChange: (value: boolean) => void;
  label?: string;
}

function ToggleField({ value, onChange, label }: Props) {
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      justifyContent="flex-end"
    >
      {label && (
        <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
          {label}
        </Typography>
      )}
      <ToggleButtonGroupStyled
        color="primary"
        value={value}
        exclusive
        onChange={(_, val) => onChange(val)}
        aria-label="Platform"
      >
        <ToggleButtonStyled value={true}>On</ToggleButtonStyled>
        <ToggleButtonStyled value={false}>Off</ToggleButtonStyled>
      </ToggleButtonGroupStyled>
    </Stack>
  );
}

export default ToggleField;
