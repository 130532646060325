import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  GET_REQUESTS_PARAMS_REQUEST,
  REQUESTS_BODY_RESULT,
  REQUESTS_DETAIL_TYPE,
} from 'models';

export interface RequestsType {
  requests: {
    requests_just_once: REQUESTS_BODY_RESULT;
    requests_recurring: REQUESTS_BODY_RESULT;
    requests_parent: REQUESTS_BODY_RESULT;
    requests_detail: REQUESTS_DETAIL_TYPE | null;
  };
}

const initialState: RequestsType = {
  requests: {
    requests_just_once: {
      records: [],
      metadata: {
        page: 1,
        limit: 50,
        page_count: 1,
        total_pages: 1,
        total_count: 1,
      },
      params: {
        sort: 'createdAt-desc',
        page: 1,
        limit: 50,
      },
    },
    requests_recurring: {
      records: [],
      metadata: {
        page: 1,
        limit: 50,
        page_count: 1,
        total_pages: 1,
        total_count: 1,
      },
      params: {
        sort: 'createdAt-desc',
        page: 1,
        limit: 50,
      },
    },
    requests_parent: {
      records: [],
      metadata: {
        page: 1,
        limit: 50,
        page_count: 1,
        total_pages: 1,
        total_count: 1,
      },
      params: {
        sort: 'createdAt-desc',
        page: 1,
        limit: 50,
      },
    },
    requests_detail: null,
  },
};

const requestsSlice = createSlice({
  name: 'requests',
  initialState,
  reducers: {
    setRequestsListJustOnceSuccess(
      state: RequestsType,
      action: PayloadAction<REQUESTS_BODY_RESULT>
    ) {
      state.requests.requests_just_once = {
        ...state.requests.requests_just_once,
        ...action.payload,
      };
    },
    setRequestsListJustOnceParams(
      state: RequestsType,
      action: PayloadAction<GET_REQUESTS_PARAMS_REQUEST | null>
    ) {
      state.requests.requests_just_once.params = action.payload;
    },
    setRequestsListRecurringSuccess(
      state: RequestsType,
      action: PayloadAction<REQUESTS_BODY_RESULT>
    ) {
      state.requests.requests_recurring = {
        ...state.requests.requests_recurring,
        ...action.payload,
      };
    },
    setRequestsListRecurringParams(
      state: RequestsType,
      action: PayloadAction<GET_REQUESTS_PARAMS_REQUEST | null>
    ) {
      state.requests.requests_recurring.params = action.payload;
    },
    setRequestsListParentSuccess(
      state: RequestsType,
      action: PayloadAction<REQUESTS_BODY_RESULT>
    ) {
      state.requests.requests_parent = {
        ...state.requests.requests_parent,
        ...action.payload,
      };
    },
    setRequestsListParentParams(
      state: RequestsType,
      action: PayloadAction<GET_REQUESTS_PARAMS_REQUEST | null>
    ) {
      state.requests.requests_parent.params = action.payload;
    },
    setRequestsDetailSuccess(
      state: RequestsType,
      action: PayloadAction<REQUESTS_DETAIL_TYPE | null>
    ) {
      state.requests.requests_detail = action.payload;
    },
  },
});

// Actions
export const {
  setRequestsListParentSuccess,
  setRequestsListParentParams,
  setRequestsListJustOnceSuccess,
  setRequestsListJustOnceParams,
  setRequestsListRecurringSuccess,
  setRequestsListRecurringParams,
  setRequestsDetailSuccess,
  // setParentListSuccess,
  // setParentDetailSuccess,
  // setStaffsListSuccess,
  // setStaffsDetailSuccess
} = requestsSlice.actions;

export default requestsSlice.reducer;
