import React, { useMemo } from 'react';

interface Props {
  active?: boolean;
}

const PendingSittersIcon = ({ active }: Props) => {
  const fill = useMemo(() => {
    return active ? "#ffffff" : "#FF796E"
  }, [active])
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0001 15.1666H6.00012C5.12012 15.1666 4.38678 15.08 3.76678 14.8933C3.54012 14.8267 3.39345 14.6066 3.40678 14.3733C3.57345 12.38 5.59345 10.8133 8.00012 10.8133C10.4068 10.8133 12.4201 12.3733 12.5934 14.3733C12.6134 14.6133 12.4668 14.8267 12.2334 14.8933C11.6134 15.08 10.8801 15.1666 10.0001 15.1666ZM4.48012 14.04C4.92012 14.1266 5.42012 14.1666 6.00012 14.1666H10.0001C10.5801 14.1666 11.0801 14.1266 11.5201 14.04C11.1668 12.76 9.70678 11.8133 8.00012 11.8133C6.29345 11.8133 4.83345 12.76 4.48012 14.04Z"
        fill={fill}
      />
      <path
        d="M9.99998 1.33331L5.99998 1.33331C2.66665 1.33331 1.33331 2.66665 1.33331 5.99998L1.33331 9.99998C1.33331 12.52 2.09331 13.9 3.90665 14.4133C4.05331 12.68 5.83331 11.3133 7.99998 11.3133C10.1666 11.3133 11.9466 12.68 12.0933 14.4133C13.9066 13.9 14.6666 12.52 14.6666 9.99998V5.99998C14.6666 2.66665 13.3333 1.33331 9.99998 1.33331ZM7.99998 9.44664C6.67998 9.44664 5.61331 8.37332 5.61331 7.05332C5.61331 5.73332 6.67998 4.66665 7.99998 4.66665C9.31998 4.66665 10.3866 5.73332 10.3866 7.05332C10.3866 8.37332 9.31998 9.44664 7.99998 9.44664Z"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99995 9.94668C6.40662 9.94668 5.11328 8.6467 5.11328 7.05337C5.11328 5.46003 6.40662 4.16669 7.99995 4.16669C9.59328 4.16669 10.8866 5.46003 10.8866 7.05337C10.8866 8.6467 9.59328 9.94668 7.99995 9.94668ZM7.99995 5.16669C6.95995 5.16669 6.11328 6.01337 6.11328 7.05337C6.11328 8.10003 6.95995 8.94668 7.99995 8.94668C9.03995 8.94668 9.88662 8.10003 9.88662 7.05337C9.88662 6.01337 9.03995 5.16669 7.99995 5.16669Z"
        fill={fill}
      />
    </svg>
  );
};

export default PendingSittersIcon;
