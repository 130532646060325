import { CHAT_TYPE, HeadCell } from 'models';

export const headCells: readonly HeadCell<CHAT_TYPE>[] = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    isSort: false,
    label: 'Conversation ID',
  },
  {
    id: 'Parent',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Parent',
  },
  {
    id: 'lastMessageAt',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Last Message',
  },
  {
    id: 'deletedAt',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Deleted At',
  },
];
