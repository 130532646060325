import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Checkbox,
  Dialog,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { CloseCircle } from 'asset';
import { LoadingPopup } from 'components/common';
import yup from 'config/yup.custom';
import { NotifyService } from 'helpers';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  getTransactionTypeDetailApi,
  updateTransactionTypeApi,
} from 'services';

interface EditAdminProps {
  open: boolean;
  subject?: string;
  onSuccess: () => void;
  handleClosePopup: () => void;
}

interface IFormInputs {
  default_include_in_payout: boolean;
}

const defaultValues: Partial<IFormInputs> = {
  default_include_in_payout: false,
};

const PopupEditTransactionType = (props: EditAdminProps) => {
  const { open, handleClosePopup, onSuccess, subject } = props;

  const [loadingPopup, setLoadingPopup] = useState(false);

  const schema = useMemo(() => {
    return yup.object({
      default_include_in_payout: yup.mixed().notRequired(),
    });
  }, []);

  const form = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  const { reset } = form;

  const _handleClosePopup = () => {
    handleClosePopup();
    reset({ ...defaultValues });
  };

  useEffect(() => {
    if (!open) return;
    if (subject) {
      setLoadingPopup(true);
      const fetchDetail = async () => {
        await getTransactionTypeDetailApi(subject)
          .then(({ data }) => {
            reset({
              default_include_in_payout: data.data.default_include_in_payout,
            });
            setLoadingPopup(false);
          })
          .catch((e) => NotifyService.error(e));
      };
      fetchDetail();
    }
  }, [open, subject, reset]);

  const onSubmit = async (value: IFormInputs) => {
    if (subject)
      await updateTransactionTypeApi(subject, {
        default_include_in_payout: value.default_include_in_payout,
      })
        .then((r) => {
          onSuccess();
          _handleClosePopup();
          NotifyService.success(r);
        })
        .catch((e) => NotifyService.error(e))
        .finally(() => {});
  };

  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiPaper-root': {
          width: 527,
          borderRadius: 0,
        },
      }}
    >
      <Grid>
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '20px 25px',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '100%',
            }}
          >
            Edit Transaction Type
          </Typography>
          <IconButton
            onClick={_handleClosePopup}
            sx={{
              padding: 0,
            }}
          >
            <CloseCircle />
          </IconButton>
        </Grid>
        <Divider />
        {loadingPopup && <LoadingPopup color="primary" />}
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Stack spacing="20px" p="25px 24px">
            <Stack spacing="16px">
              <Grid>
                <Controller
                  name="default_include_in_payout"
                  control={form.control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          checked={field.value || false}
                          onChange={(e) => field.onChange(e.target.checked)}
                          name="none"
                        />
                      }
                      label="Include In Payout"
                    />
                  )}
                />
              </Grid>
            </Stack>
            <Stack direction="row" spacing="8px">
              <Button
                variant="outlined"
                color="secondary"
                sx={{
                  width: '233.5px',
                  height: '30px',
                }}
                onClick={_handleClosePopup}
              >
                Cancel
              </Button>
              <Button
                color="secondary"
                variant="contained"
                sx={{
                  width: '233.5px',
                  height: '30px',
                }}
                type="submit"
              >
                Update
              </Button>
            </Stack>
          </Stack>
        </form>
      </Grid>
    </Dialog>
  );
};

export default PopupEditTransactionType;
