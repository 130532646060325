import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  APP_VERSION_LIST_STATE,
  CHAT_KEYWORD_TRACKING_LIST_STATE,
  GET_APP_VERSION_LIST_PARAMS_REQUEST,
  GET_CHAT_KEYWORD_TRACKING_LIST_PARAMS_REQUEST,
  GET_REASONS_LIST_PARAMS,
  GET_REGIONS_PARAMS_REQUEST,
  REASONS_LIST_STATE,
  REGIONS_BODY_RESULT,
  SETTING_CONFIGS,
} from 'models';
import {
  BADGE_LIST_STATE,
  CERTIFICATES_BODY_RESULT,
  COUNTRY_LIST_STATE,
  GET_CERTIFICATES_PARAMS_REQUEST,
  GET_COUNTRIES_PARAMS_REQUEST,
} from './../../../models/settings';

export interface SettingsState {
  badges: {
    badge_list: BADGE_LIST_STATE;
  };
  regions: {
    region_list: REGIONS_BODY_RESULT;
  };
  reasons: {
    reason_list: REASONS_LIST_STATE;
  };
  appVersions: {
    appVersionList: APP_VERSION_LIST_STATE;
  };
  chatKeywordTrackings: {
    chatKeywordTrackingList: CHAT_KEYWORD_TRACKING_LIST_STATE;
  };
  blackListCountries: {
    blackListCountryList: COUNTRY_LIST_STATE;
  };
  certificates: {
    certificateList: CERTIFICATES_BODY_RESULT;
  };
  configs: SETTING_CONFIGS | null;
}

const initialState: SettingsState = {
  regions: {
    region_list: {
      records: [],
      metadata: {
        page: 1,
        limit: 50,
        page_count: 1,
        total_pages: 1,
        total_count: 1,
      },
      params: { order: 'createdAt-desc', page: 1, limit: 50 },
    },
  },
  reasons: {
    reason_list: {
      records: [],
      metadata: {
        page: 1,
        limit: 50,
        page_count: 1,
        total_pages: 1,
        total_count: 1,
      },
      params: { order: 'createdAt-desc', page: 1, limit: 50 },
    },
  },
  badges: {
    badge_list: {
      records: [],
      metadata: {
        page: 1,
        limit: 50,
        page_count: 1,
        total_pages: 1,
        total_count: 1,
      },
      params: { order: 'createdAt-desc', page: 1, limit: 50 },
    },
  },
  appVersions: {
    appVersionList: {
      records: [],
      metadata: {
        page: 1,
        limit: 50,
        page_count: 1,
        total_pages: 1,
        total_count: 1,
      },
      params: { page: 1, limit: 50 },
    },
  },
  chatKeywordTrackings: {
    chatKeywordTrackingList: {
      records: [],
      metadata: {
        page: 1,
        limit: 50,
        page_count: 1,
        total_pages: 1,
        total_count: 1,
      },
      params: { page: 1, limit: 50 },
    },
  },

  blackListCountries: {
    blackListCountryList: {
      records: [],
      metadata: {
        page: 1,
        limit: 50,
        page_count: 1,
        total_pages: 1,
        total_count: 1,
      },
      params: { page: 1, limit: 50 },
    },
  },
  configs: null,
  certificates: {
    certificateList: {
      records: [],
      metadata: {
        page: 1,
        limit: 50,
        page_count: 1,
        total_pages: 1,
        total_count: 1,
      },
      params: { pageNum: 1, includeDeleted: true },
    },
  },
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    //REGIONS
    setRegionListSuccess(
      state: SettingsState,
      action: PayloadAction<REGIONS_BODY_RESULT>
    ) {
      state.regions.region_list = {
        ...state.regions.region_list,
        ...action.payload,
      };
    },
    setRegionListParams(
      state: SettingsState,
      action: PayloadAction<GET_REGIONS_PARAMS_REQUEST | null>
    ) {
      state.regions.region_list.params = action.payload;
    },
    //REASON
    setReasonListSuccess(
      state: SettingsState,
      action: PayloadAction<REASONS_LIST_STATE>
    ) {
      state.reasons.reason_list = {
        ...state.reasons.reason_list,
        ...action.payload,
      };
    },
    setReasonListParams(
      state: SettingsState,
      action: PayloadAction<GET_REASONS_LIST_PARAMS | null>
    ) {
      state.reasons.reason_list.params = action.payload;
    },
    //BADGES
    setBadgesListSuccess(
      state: SettingsState,
      action: PayloadAction<BADGE_LIST_STATE>
    ) {
      state.badges.badge_list = {
        ...state.badges.badge_list,
        ...action.payload,
      };
    },
    setAppVersionListSuccess(
      state: SettingsState,
      action: PayloadAction<APP_VERSION_LIST_STATE>
    ) {
      state.appVersions.appVersionList = {
        ...state.appVersions.appVersionList,
        ...action.payload,
      };
    },
    setAppVersionListParams(
      state: SettingsState,
      action: PayloadAction<GET_APP_VERSION_LIST_PARAMS_REQUEST | null>
    ) {
      state.appVersions.appVersionList.params = action.payload;
    },
    setChatKeywordTrackingListSuccess(
      state: SettingsState,
      action: PayloadAction<CHAT_KEYWORD_TRACKING_LIST_STATE>
    ) {
      state.chatKeywordTrackings.chatKeywordTrackingList = {
        ...state.chatKeywordTrackings.chatKeywordTrackingList,
        ...action.payload,
      };
    },
    setChatKeywordTrackingListParams(
      state: SettingsState,
      action: PayloadAction<GET_CHAT_KEYWORD_TRACKING_LIST_PARAMS_REQUEST | null>
    ) {
      state.chatKeywordTrackings.chatKeywordTrackingList.params =
        action.payload;
    },
    setBlackListCountryListSuccess(
      state: SettingsState,
      action: PayloadAction<COUNTRY_LIST_STATE>
    ) {
      state.blackListCountries.blackListCountryList = {
        ...state.blackListCountries.blackListCountryList,
        ...action.payload,
      };
    },
    setBlackListCountryListListParams(
      state: SettingsState,
      action: PayloadAction<GET_COUNTRIES_PARAMS_REQUEST | null>
    ) {
      state.blackListCountries.blackListCountryList.params = action.payload;
    },
    setSettingConfigsSuccess(
      state: SettingsState,
      action: PayloadAction<SETTING_CONFIGS>
    ) {
      state.configs = action.payload;
    },
    //CERTIFICATE
    setCertificateListSuccess(
      state: SettingsState,
      action: PayloadAction<CERTIFICATES_BODY_RESULT>
    ) {
      state.certificates.certificateList = {
        ...state.certificates.certificateList,
        ...action.payload,
      };
    },
    setCertificateListParams(
      state: SettingsState,
      action: PayloadAction<GET_CERTIFICATES_PARAMS_REQUEST | null>
    ) {
      state.certificates.certificateList.params = action.payload;
    },
  },
});

// Actions
export const {
  setRegionListSuccess,
  setRegionListParams,
  setReasonListSuccess,
  setReasonListParams,
  setBadgesListSuccess,
  setAppVersionListSuccess,
  setAppVersionListParams,
  setChatKeywordTrackingListSuccess,
  setChatKeywordTrackingListParams,
  setSettingConfigsSuccess,
  setBlackListCountryListSuccess,
  setBlackListCountryListListParams,
  setCertificateListSuccess,
  setCertificateListParams,
} = settingsSlice.actions;

export default settingsSlice.reducer;
