import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { NotifyService } from 'helpers';
import { GET_TRANSACTIONS_PARAMS_REQUEST } from 'models';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  deleteTransactionApi,
  getTransactionTypesApi,
  getTransactionsListApi,
} from 'services/transactions';
import { setGlobalLoading } from 'store/reducers/global';
import {
  setTransactionsListSuccess,
  setTransactionsTypeListSuccess,
} from 'store/reducers/transactions';
import {
  DELETE_TRANSACTION_REQUEST,
  GET_TRANSACTIONS_LIST_REQUEST,
  GET_TRANSACTIONS_TYPE_LIST_REQUEST,
} from 'store/reducers/transactions/actionTypes';

function* setTransactionListRequest(
  action: PayloadAction<{
    params?: GET_TRANSACTIONS_PARAMS_REQUEST;
  }>
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<any> = yield call(
      getTransactionsListApi,
      action.payload.params
    );
    yield put(
      setTransactionsListSuccess({
        records: response.data.data.records,
        metadata: response.data.data.metadata,
      })
    );
    yield put(setGlobalLoading(false));
  } catch (error: any) {
    NotifyService.error(error);
    yield put(setGlobalLoading(false));
  }
}

function* setTransactionTypeListRequest() {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<any> = yield call(getTransactionTypesApi);
    yield put(setTransactionsTypeListSuccess(response.data.data));
    yield put(setGlobalLoading(false));
  } catch (error: any) {
    NotifyService.error(error);
    yield put(setGlobalLoading(false));
  }
}

function* deleteTransactionRequest(
  action: PayloadAction<{
    id: string;
    onSuccess?: () => void;
  }>
) {
  try {
    yield call(deleteTransactionApi, action.payload.id);
    if (action.payload.onSuccess) action.payload.onSuccess();
  } catch (error: unknown) {
    NotifyService.error(error);
  }
}

function* transactions() {
  yield takeLatest(DELETE_TRANSACTION_REQUEST, deleteTransactionRequest);
  yield takeLatest(GET_TRANSACTIONS_LIST_REQUEST, setTransactionListRequest);
  yield takeLatest(
    GET_TRANSACTIONS_TYPE_LIST_REQUEST,
    setTransactionTypeListRequest
  );
}

export default transactions;
