import { createTheme } from '@mui/material/styles';
import { components } from './components';
import './theme.model';
import { typography } from './typography';

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      light: '#FFA8A1',
      main: '#FF796E',
      dark: '#E56D63 ',
      // shades_4: 'rgba(255, 121, 110, 0.04)',
      // shades_8: 'rgba(255, 121, 110, 0.08)',
      // shades_20: 'rgba(255, 121, 110, 0.2)',
      // shades_50: 'rgba(255, 121, 110, 0.5)',
      contrastText: '#fff',
    },
    secondary: {
      light: '#6FA1ED',
      main: '#226FE3',
      dark: '#1F64CC',
      // shades_4: 'rgba(34, 111, 227, 0.04)',
      // shades_8: 'rgba(34, 111, 227, 0.08)',
      // shades_20: 'rgba(34, 111, 227, 0.2)',
      // shades_50: 'rgba(34, 111, 227, 0.5)',
      contrastText: '#fff',
    },
    background: {
      light: '#FAFAFA',
      dark: '#EEECEC',
      default: '#F5F4F4',
    },
    black: {
      light: '#000000',
      dark: '#000000',
    },
    white: {
      light: '#FFFFFF',
      dark: '#FFFFFF',
    },
    blue: {
      light: '#007AFF',
      dark: '#0A84FF',
    },
    green: {
      light: '#34C759',
      dark: '#32D74B',
    },
    red: {
      light: '#FF3B30',
      dark: '#FF453A',
    },
    yellow: {
      light: '#FFCC00',
      dark: '#FFD60A',
    },
    grey1: {
      light: '#8E8E93',
      dark: '#8E8E93',
    },
    grey2: {
      light: '#AEAEB2',
      dark: '#636366',
    },
    grey3: {
      light: '#C7C7CC',
      dark: '#48484A',
    },
    grey4: {
      light: '#D1D1D6',
      dark: '#3A3A3C',
    },
    grey5: {
      light: '#E5E5EA',
      dark: '#2C2C2E',
    },
    grey6: {
      light: '#F2F2F7',
      dark: '#1C1C1E',
    },
    label: {
      primary: '#000000',
      secondary: 'rgba(60, 60, 67, 0.6)',
      tertiary: 'gba(60, 60, 67, 0.33)',
      quarternary: 'rgba(60, 60, 67, 0.18)',
    },
    seperators: {
      non_opaque: '#CECED1',
      opaque: 'rgba(206, 206, 209, 0.4)',
    },
    text: {
      primary: '#000000',
      secondary: '#545454',
      // tertiary: 'string',
      // quaternary: 'string',
      // placeholder: 'string',
      // disabled: 'string',
    },
    other: {
      stroke: 'rgba(0, 0, 0, 0.08)',
      disable_shades_50: 'rgba(183, 183, 183, 0.5)',
    },
  },
  typography,
  components,
});

export default theme;
