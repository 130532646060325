import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {
  AppBar,
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from '@mui/material';
import { EditParentIcon } from 'asset';
import { Layout } from 'components';
import PopupEditServiceRequest from 'components/Popups/ServiceRequest/PopupEditServiceRequest';
import { drawerWidth } from 'config';
import { ECLOSE_POPUP, SREQUEST_STATUS } from 'models';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setParentDetailSuccess } from 'store/reducers/accounts';
import { GET_REQUESTS_DETAIL_REQUEST } from 'store/reducers/requests/actionTypes';
import Sitters from './Sitters';

const titleTab = ['Sitters'];
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const RequestsDetail = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [tabValue, setTabValue] = useState(0);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const { requests_detail } = useAppSelector(
    (state) => state.requestReducer.requests
  );
  const navigate = useNavigate();

  const setOpenEdit = (event: any) => {
    event.stopPropagation();
    setOpenEditPopup(true);
  };
  const onClosePopup = (txt: string) => {
    if (txt === ECLOSE_POPUP.CREATE_OR_EDIT) {
      setOpenEditPopup(false);
    }
  };

  const handleChangeTabValue = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setTabValue(newValue);
  };

  const onSuccessDeleteParent = () => {
    dispatch({
      type: GET_REQUESTS_DETAIL_REQUEST,
      payload: { id: id },
    });
  };

  useEffect(() => {
    dispatch({
      type: GET_REQUESTS_DETAIL_REQUEST,
      payload: { id: id },
    });
    return () => {
      dispatch(setParentDetailSuccess(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const changeAge = (number: number) => {
    if (number === 0) {
      return '0 - 1 months';
    }
    if (number === 1) {
      return '2 - 12 months';
    }
    if (number === 2) {
      return '1 - 3 years old';
    }
    if (number === 3) {
      return '4 - 6 years old';
    }
    if (number === 4) {
      return '7 - 12 years old';
    }
    if (number === 5) {
      return '13+ years old';
    }
  };

  return (
    <Layout>
      <AppBar
        position="fixed"
        sx={{
          width: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`,
          backgroundColor: 'white.light',
          borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
        }}
        elevation={0}
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Grid>
            <Typography
              sx={{
                fontSize: '20px',
                lineHeight: 1,
                color: 'grey1.light',
              }}
            >
              Requests /{' '}
              {requests_detail?.type === 'just_once'
                ? 'Just Once'
                : 'Recurring'}{' '}
              /{' '}
              {requests_detail?.number
                ? requests_detail?.number
                : 'No data to display yet'}
            </Typography>
          </Grid>
        </Toolbar>
      </AppBar>
      <Paper
        elevation={0}
        sx={{
          p: '16px',
        }}
      >
        <Grid container justifyContent="space-between" pb="16px">
          <Button
            startIcon={<ArrowBackIosNewIcon />}
            sx={{
              p: 0,
              color: 'black.light',
              ':hover': {
                backgroundColor: 'white.light',
              },
            }}
            variant="text"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
          <Button
            startIcon={<EditParentIcon />}
            sx={{
              p: 0,
            }}
            onClick={(e) => setOpenEdit(e)}
          >
            Edit SR
          </Button>
        </Grid>
        <Stack
          justifyContent="center"
          sx={{
            backgroundColor: 'rgba(34, 111, 227, 0.04)',
            p: '21px 31px',
          }}
          spacing="30px"
        >
          <Stack direction="row" spacing="55px">
            <Grid
              mr={
                requests_detail?.status === SREQUEST_STATUS.PAID ? '0' : '100px'
              }
              display="flex"
              flexDirection="column"
              gap="4px"
            >
              <Typography
                fontWeight={500}
                lineHeight="14px"
                fontSize={14}
                letterSpacing="0.1px"
                color="text.secondary"
              >
                SR ID
              </Typography>
              <Typography
                fontWeight={400}
                lineHeight="16px"
                fontSize={14}
                letterSpacing="0.25px"
                color="black.light"
              >
                {requests_detail?.number}
              </Typography>
            </Grid>
            <Grid display="flex" flexDirection="column" gap="4px">
              <Typography
                fontWeight={500}
                lineHeight="14px"
                fontSize={14}
                letterSpacing="0.1px"
                color="text.secondary"
              >
                Type
              </Typography>
              <Grid container>
                <Typography
                  fontWeight={400}
                  lineHeight="16px"
                  fontSize={14}
                  letterSpacing="0.25px"
                  color="black.light"
                  display="flex"
                >
                  {requests_detail?.type === 'just_once'
                    ? 'Just Once'
                    : 'Recurring'}
                </Typography>
              </Grid>
            </Grid>
            <Grid display="flex" flexDirection="column" gap="4px">
              <Typography
                fontWeight={500}
                lineHeight="14px"
                fontSize={14}
                letterSpacing="0.1px"
                color="text.secondary"
              >
                Booking Status
              </Typography>
              <Grid container>
                <Typography
                  fontWeight={400}
                  lineHeight="16px"
                  fontSize={14}
                  letterSpacing="0.25px"
                  color="black.light"
                  display="flex"
                  textTransform="capitalize"
                >
                  {requests_detail?.status}
                </Typography>
              </Grid>
            </Grid>
            {requests_detail?.status === SREQUEST_STATUS.PAID ? (
              <>
                <Grid display="flex" flexDirection="column" gap="4px">
                  <Typography
                    fontWeight={500}
                    lineHeight="14px"
                    fontSize={14}
                    letterSpacing="0.1px"
                    color="text.secondary"
                  >
                    Total Amount
                  </Typography>
                  <Grid container>
                    <Typography
                      fontWeight={400}
                      lineHeight="16px"
                      fontSize={14}
                      letterSpacing="0.25px"
                      color="black.light"
                      display="flex"
                      textTransform="capitalize"
                    >
                      {`$${requests_detail?.total}`}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid display="flex" flexDirection="column" gap="4px">
                  <Typography
                    fontWeight={500}
                    lineHeight="14px"
                    fontSize={14}
                    letterSpacing="0.1px"
                    color="text.secondary"
                  >
                    Promocode
                  </Typography>
                  <Grid container>
                    <Typography
                      fontWeight={400}
                      lineHeight="16px"
                      fontSize={14}
                      letterSpacing="0.25px"
                      color="black.light"
                      display="flex"
                      textTransform="capitalize"
                    >
                      {requests_detail?.promocode
                        ? `{${requests_detail?.promocode}}`
                        : 'Null'}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid display="flex" flexDirection="column" gap="4px">
                  <Typography
                    fontWeight={500}
                    lineHeight="14px"
                    fontSize={14}
                    letterSpacing="0.1px"
                    color="text.secondary"
                  >
                    Promocode Amount
                  </Typography>
                  <Grid container>
                    <Typography
                      fontWeight={400}
                      lineHeight="16px"
                      fontSize={14}
                      letterSpacing="0.25px"
                      color="black.light"
                      display="flex"
                      textTransform="capitalize"
                    >
                      {`$${requests_detail?.discount_amount}`}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid display="flex" flexDirection="column" gap="4px">
                  <Typography
                    fontWeight={500}
                    lineHeight="14px"
                    fontSize={14}
                    letterSpacing="0.1px"
                    color="text.secondary"
                  >
                    From
                  </Typography>
                  <Grid container>
                    <Typography
                      fontWeight={400}
                      lineHeight="16px"
                      fontSize={14}
                      letterSpacing="0.25px"
                      color="black.light"
                      display="flex"
                      textTransform="capitalize"
                    >
                      {requests_detail?.from_date} {requests_detail?.from_time}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid display="flex" flexDirection="column" gap="4px">
                  <Typography
                    fontWeight={500}
                    lineHeight="14px"
                    fontSize={14}
                    letterSpacing="0.1px"
                    color="text.secondary"
                  >
                    To
                  </Typography>
                  <Grid container>
                    <Typography
                      fontWeight={400}
                      lineHeight="16px"
                      fontSize={14}
                      letterSpacing="0.25px"
                      color="black.light"
                      display="flex"
                      textTransform="capitalize"
                    >
                      {requests_detail?.to_date} {requests_detail?.to_time}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid display="flex" flexDirection="column" gap="4px">
                  <Typography
                    fontWeight={500}
                    lineHeight="14px"
                    fontSize={14}
                    letterSpacing="0.1px"
                    color="text.secondary"
                  >
                    Requsted on
                  </Typography>
                  <Grid container>
                    <Typography
                      fontWeight={400}
                      lineHeight="16px"
                      fontSize={14}
                      letterSpacing="0.25px"
                      color="black.light"
                      display="flex"
                      textTransform="capitalize"
                    >
                      {requests_detail?.createdAt}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}
          </Stack>
          <Stack direction="row" spacing="55px">
            {requests_detail?.status === SREQUEST_STATUS.PAID && (
              <>
                <Grid display="flex" flexDirection="column" gap="4px">
                  <Typography
                    fontWeight={500}
                    lineHeight="14px"
                    fontSize={14}
                    letterSpacing="0.1px"
                    color="text.secondary"
                  >
                    From
                  </Typography>
                  <Grid container>
                    <Typography
                      fontWeight={400}
                      lineHeight="16px"
                      fontSize={14}
                      letterSpacing="0.25px"
                      color="black.light"
                      display="flex"
                      textTransform="capitalize"
                    >
                      {requests_detail?.from_date} {requests_detail?.from_time}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid display="flex" flexDirection="column" gap="4px">
                  <Typography
                    fontWeight={500}
                    lineHeight="14px"
                    fontSize={14}
                    letterSpacing="0.1px"
                    color="text.secondary"
                  >
                    To
                  </Typography>
                  <Grid container>
                    <Typography
                      fontWeight={400}
                      lineHeight="16px"
                      fontSize={14}
                      letterSpacing="0.25px"
                      color="black.light"
                      display="flex"
                      textTransform="capitalize"
                    >
                      {requests_detail?.to_date} {requests_detail?.to_time}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid display="flex" flexDirection="column" gap="4px">
                  <Typography
                    fontWeight={500}
                    lineHeight="14px"
                    fontSize={14}
                    letterSpacing="0.1px"
                    color="text.secondary"
                  >
                    Requsted on
                  </Typography>
                  <Grid container>
                    <Typography
                      fontWeight={400}
                      lineHeight="16px"
                      fontSize={14}
                      letterSpacing="0.25px"
                      color="black.light"
                      display="flex"
                      textTransform="capitalize"
                    >
                      {requests_detail?.createdAt}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}
            <Grid mr="170px" display="flex" flexDirection="column" gap="4px">
              <Typography
                fontWeight={500}
                lineHeight="14px"
                fontSize={14}
                letterSpacing="0.1px"
                color="text.secondary"
              >
                Address
              </Typography>
              <Grid container>
                <Typography
                  fontWeight={400}
                  lineHeight="16px"
                  fontSize={14}
                  letterSpacing="0.25px"
                  color="black.light"
                  display="flex"
                  textTransform="capitalize"
                >
                  {requests_detail?.address.street}{' '}
                  {requests_detail?.address.name}
                </Typography>
              </Grid>
            </Grid>
            <Grid display="flex" flexDirection="column" gap="4px">
              <Typography
                fontWeight={500}
                lineHeight="14px"
                fontSize={14}
                letterSpacing="0.1px"
                color="text.secondary"
              >
                Children
              </Typography>
              <Grid container>
                <Typography
                  fontWeight={400}
                  lineHeight="16px"
                  fontSize={14}
                  letterSpacing="0.25px"
                  color="black.light"
                  display="flex"
                  textTransform="capitalize"
                >
                  {requests_detail?.children.map((item) => {
                    return `${item.full_name}(${changeAge(item.age_range)})`;
                  })}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
        </Stack>
        <Grid
          container
          sx={{
            borderBottom: '1px solid  rgba(0, 0, 0, 0.08)',
            marginBottom: '16px',
          }}
        >
          <Tabs
            value={tabValue}
            onChange={handleChangeTabValue}
            aria-label="basic tabs example"
          >
            {titleTab.map((item, index) => {
              return (
                <Tab
                  key={index}
                  label={item}
                  {...a11yProps(index)}
                  sx={{
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '125%',
                    letterSpacing: '0.1px',
                    color: '#959595',
                    textTransform: 'capitalize',
                  }}
                />
              );
            })}
          </Tabs>
        </Grid>
        <TabPanel value={tabValue} index={0}>
          <Sitters
            requests_detail={requests_detail}
            onSuccess={onSuccessDeleteParent}
          />
        </TabPanel>
      </Paper>
      {openEditPopup && (
        <PopupEditServiceRequest
          type={requests_detail?.type}
          parentId={requests_detail?.parent}
          open={openEditPopup}
          subject={requests_detail?.id}
          handleClosePopup={() => onClosePopup(ECLOSE_POPUP.CREATE_OR_EDIT)}
          onSuccess={onSuccessDeleteParent}
        />
      )}
    </Layout>
  );
};

export default RequestsDetail;
