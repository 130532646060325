import React from 'react';

const ListIcon = ({ ...props }) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 5.25C9.58579 5.25 9.25 5.58579 9.25 6C9.25 6.41421 9.58579 6.75 10 6.75V5.25ZM16 6.75C16.4142 6.75 16.75 6.41421 16.75 6C16.75 5.58579 16.4142 5.25 16 5.25V6.75ZM10 13.25C9.58579 13.25 9.25 13.5858 9.25 14C9.25 14.4142 9.58579 14.75 10 14.75V13.25ZM16 14.75C16.4142 14.75 16.75 14.4142 16.75 14C16.75 13.5858 16.4142 13.25 16 13.25V14.75ZM10 9.25C9.58579 9.25 9.25 9.58579 9.25 10C9.25 10.4142 9.58579 10.75 10 10.75V9.25ZM22 10.75C22.4142 10.75 22.75 10.4142 22.75 10C22.75 9.58579 22.4142 9.25 22 9.25V10.75ZM10 17.25C9.58579 17.25 9.25 17.5858 9.25 18C9.25 18.4142 9.58579 18.75 10 18.75V17.25ZM22 18.75C22.4142 18.75 22.75 18.4142 22.75 18C22.75 17.5858 22.4142 17.25 22 17.25V18.75ZM4 9.25C3.30964 9.25 2.75 8.69036 2.75 8H1.25C1.25 9.51878 2.48122 10.75 4 10.75V9.25ZM2.75 8C2.75 7.30964 3.30964 6.75 4 6.75V5.25C2.48122 5.25 1.25 6.48122 1.25 8H2.75ZM4 6.75C4.69036 6.75 5.25 7.30964 5.25 8H6.75C6.75 6.48122 5.51878 5.25 4 5.25V6.75ZM5.25 8C5.25 8.69036 4.69036 9.25 4 9.25V10.75C5.51878 10.75 6.75 9.51878 6.75 8H5.25ZM4 17.25C3.30964 17.25 2.75 16.6904 2.75 16H1.25C1.25 17.5188 2.48122 18.75 4 18.75V17.25ZM2.75 16C2.75 15.3096 3.30964 14.75 4 14.75V13.25C2.48122 13.25 1.25 14.4812 1.25 16H2.75ZM4 14.75C4.69036 14.75 5.25 15.3096 5.25 16H6.75C6.75 14.4812 5.51878 13.25 4 13.25V14.75ZM5.25 16C5.25 16.6904 4.69036 17.25 4 17.25V18.75C5.51878 18.75 6.75 17.5188 6.75 16H5.25ZM10 6.75H16V5.25H10V6.75ZM10 14.75H16V13.25H10V14.75ZM10 10.75H22V9.25H10V10.75ZM10 18.75H22V17.25H10V18.75Z"
        fill={props.active ? '#FFF' : '#8E8E93'}
      />
    </svg>
  );
};

export default ListIcon;
