import type { ThemeOptions } from '@mui/material/styles';
// import { createBreakpoints } from '@mui/system';

// const breakpoints = createBreakpoints({});
/**
 * Style overrides for Material UI components.
 *
 * @see https://mui.com/customization/theme-components/#global-style-overrides
 */
export const typography: ThemeOptions['typography'] = {
  fontFamily: ['Poppins', 'sans-serif'].join(','),
  // body1: {
  //   fontFamily: Poppins,
  //   color: 'white',
  //   letterSpacing: '0.03em',
  //   fontWeight: 300,
  //   lineHeight: '130%',
  //   fontSize: '18px',
  // },
  // h1: {
  //   fontFamily: LibreBodoni,
  //   fontWeight: 400,
  //   fontSize: '45px',
  //   lineHeight: '120%',
  //   letterSpacing: '0.03em',
  //   color: 'black',
  // },
  // h2: {
  //   fontFamily: LibreBodoni,
  //   fontWeight: 400,
  //   fontSize: '35px',
  //   lineHeight: '120%',
  //   letterSpacing: '0.03em',
  //   color: 'black',
  // },
  // h3: {
  //   fontFamily: LibreBodoni,
  //   fontWeight: 400,
  //   fontSize: '22px',
  //   lineHeight: '125%',
  //   letterSpacing: '0.03em',
  //   color: 'black',
  // },
  Large_Title_60_Regular: {
    fontWeight: 400,
    fontSize: '60px',
    lineHeight: '70px',
    letterSpacing: '-0.5px',
  },
  Large_Title_60_Bold: {
    fontWeight: 700,
    fontSize: '60px',
    lineHeight: '70px',
    letterSpacing: '-0.5px',
  },
  Large_Title_34_Regular: {
    fontWeight: 400,
    fontSize: '34px',
    lineHeight: '41px',
    letterSpacing: '-0.25px',
  },
  Large_Title_34_Bold: {
    fontWeight: 700,
    fontSize: '34px',
    lineHeight: '41px',
    letterSpacing: '-0.25px',
  },

  // Captions
  Caption_12_Regular: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0.05px',
  },
  Caption_12_Medium: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0.05px',
  },
  Caption_12_R_Italic: {
    fontStyle: 'italic',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0.05px',
  },
  Caption_12_M_Italic: {
    fontStyle: 'italic',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0.05px',
  },
  Caption_1_Caption_13_Regular: {
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '12px',
    letterSpacing: '0.06px',
  },
  Caption_1_Caption_13_SemiBold: {
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '12px',
    letterSpacing: '0.06px',
  },
  Caption_1_Caption_13_R_Italic: {
    fontStyle: 'italic',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '12px',
    letterSpacing: '0.06px',
  },
  Caption_1_Caption_13_M_Italic: {
    fontStyle: 'italic',
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '12px',
    letterSpacing: '0.06px',
  },
  Caption_1_Regular: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '12px',
    letterSpacing: '0.07px',
  },

  // titles
  Title_30_Regular: {
    fontWeight: 400,
    fontSize: '30px',
    lineHeight: '34px',
  },
  Title_30_SemiBold: {
    fontWeight: 600,
    fontSize: '30px',
    lineHeight: '34px',
  },
  Title_24_Regular: {
    fontWeight: 400,
    fontSize: '24px',
    lineHeight: '28px',
  },
  Title_24_SemiBold: {
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '28px',
  },
  Title_22_Regular: {
    fontWeight: 400,
    fontSize: '22px',
    lineHeight: '24px',
    letterSpacing: '0.25px',
  },
  Title_22_SemiBold: {
    fontWeight: 600,
    fontSize: '22px',
    lineHeight: '24px',
    letterSpacing: '0.25px',
  },

  // Body
  Large_Body_18_Regular: {
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '-0.408px',
  },
  Large_Body_18_Medium: {
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '-0.408px',
  },
  Large_Body_18_SemiBold: {
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '-0.408px',
  },
  Large_Body_18_R_Italic: {
    fontStyle: 'italic',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '-0.408px',
  },
  Large_Body_18_S_Italic: {
    fontStyle: 'italic',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '-0.408px',
  },
  Small_Body_16_Regular: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.1px',
  },
  Small_Body_16_Medium: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.1px',
  },
  Small_Body_16_SemiBold: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.1px',
  },
  Small_Body_16_R_Italic: {
    fontStyle: 'italic',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.1px',
  },
  Small_Body_16_S_Italic: {
    fontStyle: 'italic',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.1px',
  },

  // components
  Components_Button: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '16px',
    letterSpacing: '0.25px',
  },
  Components_Column_tittle: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '14px',
    letterSpacing: '0.1px',
  },
  Components_Column_content: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '14px',
    letterSpacing: '0.1px',
  },
  Components_UnstyledContainer: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0.25px',
  },
};
