import { HeadCell, NANNY_BOOKING_TYPE } from 'models';

export const headCells: readonly HeadCell<NANNY_BOOKING_TYPE>[] = [
  {
    id: 'bookingId',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'ID',
  },
  {
    id: 'parentName',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Parent',
  },
  // {
  //   id: 'commencementDate',
  //   numeric: false,
  //   disablePadding: false,
  //   isSort: false,
  //   label: 'Commencement Date',
  // },
  {
    id: 'edd',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Expected Due Date',
  },
  {
    id: 'singleTwinPregnancy',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Single/Twin',
  },
  {
    id: 'houseType',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Single/Multi-storey',
  },
  {
    id: 'duration',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Duration (days)',
  },
  {
    id: 'parentStatus',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Parent Status',
  },
  {
    id: 'nannyStatus',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Nanny Status',
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Create On',
  },
];
