import { DATA_PAGINATION, OptionItem, getKeys } from './global';

export enum BOOKING_PARENT_STATUS {
  SUBMITTED = 'SUBMITTED', // when parent creates the booking
  NANNIES_MATCHED = 'NANNIES_MATCHED',
  NANNY_CONFIRMED = 'NANNY_CONFIRMED',
  DEPOSIT_PAID = 'DEPOSIT_PAID',
  COMMENCED = 'COMMENCED',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  DELETED = 'DELETED',
}

export enum BOOKING_NANNY_STATUS {
  SUBMITTED = 'SUBMITTED',
  MATCHED = 'MATCHED',
  CONFIRMED = 'CONFIRMED',
  COMMENCED = 'COMMENCED',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  DELETED = 'DELETED',
}

export const bookingParentStatusTitle = {
  [BOOKING_PARENT_STATUS.SUBMITTED]: 'Submitted',
  [BOOKING_PARENT_STATUS.NANNIES_MATCHED]: 'Matched',
  [BOOKING_PARENT_STATUS.NANNY_CONFIRMED]: 'Confirmed',
  [BOOKING_PARENT_STATUS.DEPOSIT_PAID]: 'Deposit Paid',
  [BOOKING_PARENT_STATUS.COMMENCED]: 'Commenced',
  [BOOKING_PARENT_STATUS.COMPLETED]: 'Completed',
  [BOOKING_PARENT_STATUS.CANCELLED]: 'Cancelled',
  [BOOKING_PARENT_STATUS.DELETED]: 'Deleted',
};

export const bookingParentStatusColor = {
  [BOOKING_PARENT_STATUS.SUBMITTED]: 'yellow.light',
  [BOOKING_PARENT_STATUS.NANNIES_MATCHED]: 'secondary.main',
  [BOOKING_PARENT_STATUS.NANNY_CONFIRMED]: 'secondary.main',
  [BOOKING_PARENT_STATUS.DEPOSIT_PAID]: 'secondary.main',
  [BOOKING_PARENT_STATUS.COMMENCED]: 'green.light',
  [BOOKING_PARENT_STATUS.COMPLETED]: 'green.light',
  [BOOKING_PARENT_STATUS.CANCELLED]: '#B6B6B6',
  [BOOKING_PARENT_STATUS.DELETED]: '#B6B6B6',
};

export const bookingParentStatuses: OptionItem[] = getKeys(
  BOOKING_PARENT_STATUS
).map((t) => ({
  id: BOOKING_PARENT_STATUS[t],
  label: bookingParentStatusTitle[t],
  color: bookingParentStatusColor[t],
}));

export const bookingNannyStatusTitle = {
  [BOOKING_NANNY_STATUS.SUBMITTED]: 'Submitted',
  [BOOKING_NANNY_STATUS.MATCHED]: 'Matched',
  [BOOKING_NANNY_STATUS.CONFIRMED]: 'Confirmed',
  [BOOKING_NANNY_STATUS.COMMENCED]: 'Commenced',
  [BOOKING_NANNY_STATUS.COMPLETED]: 'Completed',
  [BOOKING_NANNY_STATUS.CANCELLED]: 'Cancelled',
  [BOOKING_NANNY_STATUS.DELETED]: 'Deleted',
};

export const bookingNannyStatusColor = {
  [BOOKING_NANNY_STATUS.SUBMITTED]: 'yellow.light',
  [BOOKING_NANNY_STATUS.MATCHED]: 'secondary.main',
  [BOOKING_NANNY_STATUS.CONFIRMED]: 'secondary.main',
  [BOOKING_NANNY_STATUS.COMMENCED]: 'green.light',
  [BOOKING_NANNY_STATUS.COMPLETED]: 'green.light',
  [BOOKING_NANNY_STATUS.CANCELLED]: '#B6B6B6',
  [BOOKING_NANNY_STATUS.DELETED]: '#B6B6B6',
};

export const bookingNannyStatuses: OptionItem[] = getKeys(
  BOOKING_NANNY_STATUS
).map((t) => ({
  id: BOOKING_NANNY_STATUS[t],
  label: bookingNannyStatusTitle[t],
  color: bookingNannyStatusColor[t],
}));

export interface GET_NANNY_BOOKING_LIST_PARAMS_REQUEST {
  pageNum?: number;
  pageSize?: number;
  keyword?: string;
  parentStatus?: OptionItem;
  nannyStatus?: OptionItem;
  parentFirstName?: string;
  parentLastName?: string;
  edd?: string;
  sort?: string;
}

export interface NANNY_BOOKING_TYPE {
  bookingId: string;
  parentId: string;
  parentName: string;
  email: string;
  parentStatus: BOOKING_PARENT_STATUS;
  nannyStatus: BOOKING_NANNY_STATUS;
  edd: string;
  commencementDate?: string;
  duration: number;
  singleTwinPregnancy: string;
  houseType: string;
  pets: boolean;
  otherRequirements: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
}

export interface NANNY_BOOKING_DETAIL_TYPE {
  bookingId: string;
  parentId: string;
  parentName: string;
  email: string;
  parentStatus: BOOKING_PARENT_STATUS;
  nannyStatus: BOOKING_NANNY_STATUS;
  edd: string;
  commencementDate?: string;
  duration: number;
  singleTwinPregnancy: string;
  houseType: string;
  pets: boolean;
  otherRequirements: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
}

export interface NANNY_BOOKING_LIST_STATE
  extends DATA_PAGINATION<NANNY_BOOKING_TYPE> {
  params?: GET_NANNY_BOOKING_LIST_PARAMS_REQUEST | null;
}

export interface NANNY_BOOKING_DETAIL_TAB_STATE {
  nannies: {
    data: NANNY_IN_BOOKING_TYPE[];
  };
  activityLog: {
    data: BOOKING_ACTIVITY_LOG[];
  };
}

export interface CREATE_OR_UPDATE_NANNY_BOOKING_TYPE {
  parentId: string;
  nannyStatus: string;
  parentStatus: string;
  edd: string; //dd-MM-yyyy
  commencementDate?: string | null; //dd-MM-yyyy
  singleTwinPregnancy: string;
  houseType: string;
  pets: boolean;
  duration: number;
  otherRequirements: string;
}

export interface UPDATE_NANNY_IN_BOOKING_REQUEST {
  bookingId: string;
  availabilityList: {
    nannyId: string;
    nannyAskingAmount: number;
    commission: number;
    nannyDeposit: number;
    status: string;
  }[];
}

export const singleTwinPregnancyOptions: OptionItem<string>[] = [
  { id: 'Single', label: 'Single' },
  { id: 'Twin', label: 'Twin' },
  { id: 'More than two', label: 'More than two' },
];

export const houseTypeOptions: OptionItem<string>[] = [
  { id: '1 floor', label: '1 floor' },
  { id: '2 floors', label: '2 floors' },
  { id: '3 or more floors', label: '3 or more floors' },
];

export const durationOptions: OptionItem<number>[] = [
  { id: 21, label: '21 days' },
  { id: 28, label: '28 days' },
  { id: 56, label: '56 days' },
];

export enum NANNY_IN_BOOKING_STATUS {
  AVAILABLE = 'AVAILABLE',
  ASSIGNED = 'ASSIGNED',
  CANCELLED = 'CANCELLED',
  DELETED = 'DELETED',
}

export const nannyInBookingStatusName = {
  [NANNY_IN_BOOKING_STATUS.AVAILABLE]: 'Available',
  [NANNY_IN_BOOKING_STATUS.ASSIGNED]: 'Assigned',
  [NANNY_IN_BOOKING_STATUS.DELETED]: 'Deleted',
  [NANNY_IN_BOOKING_STATUS.CANCELLED]: 'Cancelled',
};

export const nannyInBookingStatusColor = {
  [NANNY_IN_BOOKING_STATUS.AVAILABLE]: 'yellow.light',
  [NANNY_IN_BOOKING_STATUS.ASSIGNED]: 'secondary.main',
  [NANNY_IN_BOOKING_STATUS.DELETED]: '#B6B6B6',
  [NANNY_IN_BOOKING_STATUS.CANCELLED]: '#B6B6B6',
};

export const nannyInBookingStatuses: OptionItem<string>[] = getKeys(
  NANNY_IN_BOOKING_STATUS
).map((t) => ({
  id: NANNY_IN_BOOKING_STATUS[t],
  label: nannyInBookingStatusName[t],
}));

export interface NANNY_IN_BOOKING_TYPE {
  bookingId: string;
  commission: number;
  createdAt: string;
  deletedAt: string;
  email: string;
  id: string;
  nannyAskingAmount: number;
  nannyDeposit: number;
  nannyId: string;
  nannyName: string;
  number: string;
  status: NANNY_IN_BOOKING_STATUS;
  totalAmount: number;
  updatedAt: string;
}

export interface ADD_NANNY_TO_BOOKING {
  nannyId: string;
  bookingId: string;
  nannyAskingAmount: number;
  commission: number;
  nannyDeposit: number;
}

export enum BOOKING_ACTIVITY_LOG_TYPE {
  BOOKING_CREATED = 'BOOKING_CREATED',
  NANNY_SELECTED = 'NANNY_SELECTED',
  ADMIN_NOTE = 'ADMIN_NOTE',
  PAYMENT_PARENT_AUNTY = 'PAYMENT_PARENT_AUNTY',
  PAYMENT_PARENT_NANNY = 'PAYMENT_PARENT_NANNY',
  PAYMENT_AUNTY_PARENT = 'PAYMENT_AUNTY_PARENT',
  PAYMENT_AUNTY_NANNY = 'PAYMENT_AUNTY_NANNY',
  PAYMENT_NANNY_PARENT = 'PAYMENT_NANNY_PARENT',
  PAYMENT_NANNY_AUNTY = 'PAYMENT_NANNY_AUNTY',
}

export const bookingActivityLogTypeName = {
  [BOOKING_ACTIVITY_LOG_TYPE.BOOKING_CREATED]: 'Booking created',
  [BOOKING_ACTIVITY_LOG_TYPE.NANNY_SELECTED]: 'Nanny selected',
  [BOOKING_ACTIVITY_LOG_TYPE.ADMIN_NOTE]: 'Admin note',
  [BOOKING_ACTIVITY_LOG_TYPE.PAYMENT_PARENT_AUNTY]: 'Parent payment to Aunty',
  [BOOKING_ACTIVITY_LOG_TYPE.PAYMENT_PARENT_NANNY]: 'Parent payment to Nanny',
  [BOOKING_ACTIVITY_LOG_TYPE.PAYMENT_AUNTY_PARENT]: 'Aunty payment to Parent',
  [BOOKING_ACTIVITY_LOG_TYPE.PAYMENT_AUNTY_NANNY]: 'Aunty payment to Nanny',
  [BOOKING_ACTIVITY_LOG_TYPE.PAYMENT_NANNY_PARENT]: 'Nanny payment to Parent',
  [BOOKING_ACTIVITY_LOG_TYPE.PAYMENT_NANNY_AUNTY]: 'Nanny payment to Aunty',
};

export const bookingActivityLogTypes: OptionItem[] = getKeys(
  BOOKING_ACTIVITY_LOG_TYPE
).map((t) => ({
  id: BOOKING_ACTIVITY_LOG_TYPE[t],
  label: bookingActivityLogTypeName[t],
}));

export interface BOOKING_ACTIVITY_LOG {
  id: string;
  amount: number;
  bookingId: string;
  type: BOOKING_ACTIVITY_LOG_TYPE;
  description: string;
  nannyList: {
    nannyId: string;
    firstName: string;
    lastName: string;
  }[];
  parent: {
    parentId: string;
    parentFirstName: string;
  };
  createdAt: string;
  updatedAt: string;
}

export interface CREATE_NANNY_BOOKING_ACTIVITY_LOG_REQUEST {
  bookingId: string;
  type: string;
  description?: string;
  amount?: number | null;
  nannyList?: string[];
  parentId?: string | null;
}

export interface UPDATE_NANNY_BOOKING_ACTIVITY_LOG_REQUEST {
  activityId: string;
  type: string;
  description?: string;
  amount?: number | null;
  nannyList?: string[];
  parentId?: string | null;
}
