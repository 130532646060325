import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { CloseCircle } from 'asset';
import { Inputs } from 'components';
import { LoadingPopup } from 'components/common';
import yup from 'config/yup.custom';
import { NotifyService } from 'helpers';
import { EDIT_PAY_INFO_BODY } from 'models';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  addPaymentInfoBySitterApi,
  getPaymentMethodsListApi,
  updatePaymentInfoBySitterApi,
} from 'services';

interface Props {
  open: boolean;
  isEdit: boolean;
  paymentInfo: {
    bank_name?: string;
    bank_account_number?: string;
    account_holder_name?: string;
    pay_now_name?: string;
    pay_now_number?: string;
    sitter_id?: string;
  };
  onSuccess: () => void;
  handleClosePopup: () => void;
}

interface IFormInputs {
  bank_name: string;
  bank_account_number: string;
  account_holder_name: string;
  pay_now_name: string;
  pay_now_number: string;
  sitter_id: string;
}

const defaultValues: Partial<IFormInputs> = {
  bank_name: '',
  bank_account_number: '',
  account_holder_name: '',
  pay_now_name: '',
  pay_now_number: '',
  sitter_id: '',
};

const PopupEditPaynow = (props: Props) => {
  const { open, handleClosePopup, onSuccess, paymentInfo, isEdit } = props;

  const [loadingPopup, setLoadingPopup] = useState(false);
  const [idPaymentMethods, setIdPaymentMethods] = useState('');

  const theme = useTheme();

  const schema = useMemo(() => {
    return yup.object({
      bank_name: yup.string().optional(),
      bank_account_number: yup.string().optional(),
      account_holder_name: yup.string().optional(),
      pay_now_name: yup.string().required('Pay now name is required'),
      pay_now_number: yup.string().required('Pay now number is required'),
    });
  }, []);

  const form = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  const { reset } = form;

  const _handleClosePopup = () => {
    handleClosePopup();
    reset({ ...defaultValues });
  };

  useEffect(() => {
    if (!open) return;
    if (paymentInfo.sitter_id) {
      reset({
        bank_name: paymentInfo.bank_name,
        bank_account_number: paymentInfo.bank_account_number,
        account_holder_name: paymentInfo.account_holder_name,
        pay_now_name: paymentInfo.pay_now_name,
        pay_now_number: paymentInfo.pay_now_number,
        sitter_id: paymentInfo.sitter_id,
      });
    }
  }, [open, reset, paymentInfo]);

  useEffect(() => {
    if (isEdit && paymentInfo.sitter_id) {
      getIdMethods(paymentInfo.sitter_id);
    }
  }, [isEdit, paymentInfo]);

  const getIdMethods = async (sitter_id: string) => {
    const _params = {
      sitter_id: sitter_id,
    };
    await getPaymentMethodsListApi(_params)
      .then((r) => {
        setIdPaymentMethods(r.data.record[0].id);
      })
      .catch((e) => NotifyService.error(e));
  };

  const onSubmit = async (value: IFormInputs) => {
    const _value: EDIT_PAY_INFO_BODY = {
      ...(value.bank_name && {
        bank_name: value.bank_name,
      }),
      ...(value.bank_account_number && {
        bank_account_number: value.bank_account_number,
      }),
      ...(value.account_holder_name && {
        account_holder_name: value.account_holder_name,
      }),
      pay_now_name: value.pay_now_name,
      pay_now_number: value.pay_now_number,
      sitter_id: value.sitter_id,
    };

    setLoadingPopup(true);
    if (isEdit && idPaymentMethods) {
      await updatePaymentInfoBySitterApi(_value, idPaymentMethods)
        .then((r) => {
          onSuccess();
          _handleClosePopup();
          NotifyService.success(r);
        })
        .catch((e) => NotifyService.error(e))
        .finally(() => {
          setLoadingPopup(false);
        });
    } else {
      await addPaymentInfoBySitterApi(_value)
        .then((r) => {
          onSuccess();
          _handleClosePopup();
          NotifyService.success(r);
        })
        .catch((e) => NotifyService.error(e))
        .finally(() => {
          setLoadingPopup(false);
        });
    }
  };

  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiPaper-root': {
          width: 527,
          borderRadius: 0,
        },
      }}
    >
      <Grid>
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '20px 25px',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '100%',
            }}
          >
            {isEdit ? 'Edit' : 'Create'} Payment info
          </Typography>
          <IconButton
            onClick={_handleClosePopup}
            sx={{
              padding: 0,
            }}
          >
            <CloseCircle />
          </IconButton>
        </Grid>
        <Divider />
        {loadingPopup && <LoadingPopup color="primary" />}

        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Stack spacing="20px" p="25px 24px">
            <Stack>
              <Inputs title="Bank Name" form={form} name="bank_name" />
            </Stack>
            <Stack>
              <Inputs
                title="Bank Account Number"
                form={form}
                name="bank_account_number"
                type="number"
              />
            </Stack>
            <Stack>
              <Inputs
                title="Account Holder Name"
                form={form}
                name="account_holder_name"
              />
            </Stack>
            <Stack>
              <Inputs title="Pay Now Name" form={form} name="pay_now_name" />
            </Stack>
            <Stack>
              <Inputs
                title="Pay Now Number"
                form={form}
                name="pay_now_number"
                type="number"
              />
            </Stack>
            <Stack direction="row" spacing="8px">
              <Button
                variant="outlined"
                color="secondary"
                onClick={_handleClosePopup}
                sx={{
                  height: 30,
                  width: '100%',
                  fontWeight: 600,
                  fontSize: '14px',
                  lineHeight: 1.1,
                  border: `1px solid ${theme.palette.secondary.main}`,
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                sx={{
                  height: 30,
                  width: '100%',
                  fontWeight: 600,
                  fontSize: '14px',
                  lineHeight: 1.1,
                }}
              >
                {isEdit ? 'Save' : 'Create'}
              </Button>
            </Stack>
          </Stack>
        </form>
      </Grid>
    </Dialog>
  );
};

export default PopupEditPaynow;
