import { Grid, Container, Typography, Card, CardContent } from '@mui/material';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import BabyChangingStationIcon from '@mui/icons-material/BabyChangingStation';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import WalletIcon from '@mui/icons-material/Wallet';
import PaymentsIcon from '@mui/icons-material/Payments';
import SavingsIcon from '@mui/icons-material/Savings';
import PaidIcon from '@mui/icons-material/Paid';
import { Layout } from 'components/index';
import React, { useState } from 'react';
import { getNumberOfParentsData, 
  getNumberOfSittersData, 
  getNumberOfHoursData, 
  getNumberOfTransactionsData,
  getServicesFeeCharged,
  getSummaryStatistics 
} from 'services/dashboard';

import {
  CartesianGrid,
  Tooltip,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Legend,
  LabelList,
  ResponsiveContainer
} from 'recharts';

const Dashboard = () => {

  const [numParentsData, setNumberOfParentsData] = useState([] as any);
  const [numSittersData, setNumberOfSittersData] = useState([] as any);
  const [numHours, setNumHoursData] = useState([] as any);
  const [numTransactions, setNumTransactionsData] = useState([] as any);
  const [servicesFeeCharged, setServicesFeeCharged] = useState([] as any);
  const [summaryStatistics, setSummaryStatistics] = useState({
    "parents": [
        {
            "active_parents": 0,
            "inactive_parents": 0,
            "total_parents": 0
        }
    ],
    "sitters": [
        {
            "active_sitters": 0,
            "inactive_sitters": 0,
            "total_sitters": 0
        }
    ],
    "walletTransactions": [
        {
            "total_transactions": 0,
            "total_revenue": 0,
            "sitters_payout": 0,
            "commissions": 0
        }
    ],
    "sRequestsAndJobs": [
        {
            "total_hours_of_service": 0,
            "total_jobs": 0
        }
    ],
    "walletBalance": [
        {
            "total_wallet_balance": 0
        }
    ]
} as any);
  
  React.useEffect(() => {
  getNumberOfParentsData().then(res => {
    setNumberOfParentsData(res.data)
  })},[]);

  React.useEffect(() => {
  getNumberOfSittersData().then(res => {
    setNumberOfSittersData(res.data)
  })},[]);

  React.useEffect(() => {
  getNumberOfHoursData().then(res => {
    setNumHoursData(res.data)
  })},[]);

  React.useEffect(() => {
  getNumberOfTransactionsData().then(res => {
    setNumTransactionsData(res.data)
  })},[]);

  React.useEffect(() => {
  getServicesFeeCharged().then(res => {
    setServicesFeeCharged(res.data)
  })},[]);

  React.useEffect(() => {
  getSummaryStatistics().then(res => {
    setSummaryStatistics(res.data)
  })},[]);


  return (
    <Layout>
      <Grid container rowSpacing={2} columnSpacing={2} justifyContent='center'>
        <Grid xs= {3}  item={true} minWidth={200}>
          <Card style={{border: '1px solid #FFAFA8', borderRadius: '30px'}}>
            <CardContent>
              <Typography component="div"  ml={1} p={2} style={{ fontWeight: 'bold', color: '#FFAFA8' }} sx={{ typography: { lg: 'subtitle1', sm: 'body2' } }}>
                  Total Sitters:
              </Typography>
              <Grid container spacing={1} ml={1} pl={2}>
                  <Grid item xs={1} mr={2}>
                    <BabyChangingStationIcon sx={{ color: '#FFAFA8', mr: 1, fontSize: 26, }}/>
                  </Grid>
                  <Grid item xs={'auto'}>
                    <Typography component="div" style={{ fontWeight: 'bold' , color: '#FFAFA8'}}
                    sx={{ typography: { lg: 'h6', sm: 'subtitle1' } }}
                    >
                      {summaryStatistics.sitters[0].total_sitters}</Typography>
                  </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid xs= {3}  item={true}  minWidth={200}>
          <Card style={{border: '1px solid #FFAFA8', borderRadius: '30px'}}>
            <CardContent>
              <Typography component="div"  ml={1} p={2} style={{ fontWeight: 'bold', color: '#FFAFA8' }}
              sx={{ typography: { lg: 'subtitle1', sm: 'body2' } }}
              >
                  Total Parents:
              </Typography>
              <Grid container spacing={1} ml={1} pl={2}>
                  <Grid item xs={1} mr={2}>
                    <FamilyRestroomIcon sx={{ color: '#FFAFA8', mr: 1, fontSize: 26, }}/>
                  </Grid>
                  <Grid item xs={'auto'}>
                    <Typography component="div" style={{ fontWeight: 'bold' , color: '#FFAFA8'}}
                    sx={{ typography: { lg: 'h6', sm: 'subtitle1' } }}
                    >{summaryStatistics.parents[0].total_parents}</Typography>
                  </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid xs= {3} item={true}  minWidth={200}>
          <Card style={{border: '1px solid #FFAFA8', borderRadius: '30px'}}>
            <CardContent>   
              <Typography component="div"  ml={1} p={2} style={{ fontWeight: 'bold', color: '#FFAFA8' }}
              sx={{ typography: { lg: 'subtitle1', sm: 'body2'} }}
              >
                Total Hours of Service:
              </Typography>
              <Grid container spacing={1} ml={1} pl={2}>
                  <Grid item xs={1} mr={2}>
                    <AccessTimeFilledIcon sx={{ color: '#FFAFA8', mr: 1, fontSize: 26, }}/>
                  </Grid>
                  <Grid item xs={'auto'}>
                    <Typography style={{ fontWeight: 'bold' , color: '#FFAFA8'}}
                    sx={{ typography: { lg: 'h6', sm: 'subtitle1' } }}
                    >{summaryStatistics.sRequestsAndJobs[0].total_hours_of_service} </Typography>
                  </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid xs= {3} item={true}  minWidth={200}>
          <Card style={{border: '1px solid #FFAFA8', borderRadius: '30px'}}>
            <CardContent>
              <Typography component="div"  ml={1} p={2} style={{ fontWeight: 'bold', color: '#FFAFA8' }}
              sx={{ typography: { lg: 'subtitle1', sm: 'body2'} }}
              >
                Total No. of Transactions:
              </Typography>
              <Grid container spacing={1} ml={1} pl={2}>
                  <Grid item xs={1} mr={2}>
                    <PointOfSaleIcon sx={{ color: '#FFAFA8', mr: 1, fontSize: 26, }}/>
                  </Grid>
                  <Grid item xs={'auto'}>
                    <Typography style={{ fontWeight: 'bold' , color: '#FFAFA8'}}
                    sx={{ typography: { lg: 'h6', sm: 'subtitle1' } }}
                    >{summaryStatistics.sRequestsAndJobs[0].total_transactions}</Typography>
                  </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid xs= {3} item={true}  minWidth={200}>
          <Card style={{border: '1px solid #FFAFA8', borderRadius: '30px'}}>
            <CardContent>
              <Typography component="div"  ml={1} p={2} style={{ fontWeight: 'bold', color: '#FFAFA8' }}
              sx={{ typography: { lg: 'subtitle1', sm: 'body2'} }}
              >
                  Total Payout to Sitters:
              </Typography>
              <Grid container spacing={1} ml={1} pl={2}>
                  <Grid item xs={1} mr={2}>
                    <PaymentsIcon sx={{ color: '#FFAFA8', mr: 1, fontSize: 26, }}/>
                  </Grid>
                  <Grid item xs={'auto'}>
                    <Typography style={{ fontWeight: 'bold' , color: '#FFAFA8'}}
                    sx={{ typography: { lg: 'h6', sm: 'subtitle1' } }}
                    >${summaryStatistics.walletTransactions[0].sitters_payout}</Typography>
                  </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid xs= {3}  item={true}  minWidth={200}>
          <Card style={{border: '1px solid #FFAFA8', borderRadius: '30px'}}>
            <CardContent>
              <Typography component="div"  ml={1} p={2} style={{ fontWeight: 'bold', color: '#FFAFA8' }}
              sx={{ typography: { lg: 'subtitle1', sm: 'body2'} }}
              >
                Total Commissions Earned:
              </Typography>
              <Grid container spacing={1} ml={1} pl={2}>
                <Grid item xs={1} mr={2}>
                  <SavingsIcon sx={{ color: '#FFAFA8', mr: 1, fontSize: 26, }}/>
                </Grid>
                <Grid item xs={'auto'}>
                  <Typography style={{ fontWeight: 'bold' , color: '#FFAFA8'}}
                    sx={{ typography: { lg: 'h6', sm: 'subtitle1' } }}
                    >${summaryStatistics.walletTransactions[0].commissions}</Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid xs= {3}  item={true}  minWidth={200}>
          <Card style={{border: '1px solid #FFAFA8', borderRadius: '30px'}}>
            <CardContent>
              <Typography component="div"  ml={1} p={2} style={{ fontWeight: 'bold', color: '#FFAFA8' }}
              sx={{ typography: { lg: 'subtitle1', sm: 'body2'} }}
              >
                Total Service Fee:
              </Typography>
              <Grid container spacing={1} ml={1} pl={2}>
                <Grid item xs={1} mr={2}>
                  <PaidIcon sx={{ color: '#FFAFA8', mr: 1, fontSize: 26, }}/>
                </Grid>
                <Grid item xs={'auto'}>
                  <Typography style={{ fontWeight: 'bold' , color: '#FFAFA8'}}
                    sx={{ typography: { lg: 'h6', sm: 'subtitle1' } }}
                    >${summaryStatistics.walletTransactions[0].service_fee}</Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid xs= {3} item={true}  minWidth={200}>
          <Card style={{border: '1px solid #FFAFA8', borderRadius: '30px'}}>
            <CardContent>
              <Typography component="div"  ml={1} p={2} style={{ fontWeight: 'bold', color: '#FFAFA8' }}
              sx={{ typography: { lg: 'subtitle1', sm: 'body2'} }}
              >
                Total Balance in Aunty Credit Wallets:
              </Typography>
              <Grid container spacing={1} ml={1} pl={2}>
                  <Grid item xs={1} mr={2}>
                    <WalletIcon sx={{ color: '#FFAFA8', mr: 1, fontSize: 26, }}/>
                  </Grid>
                  <Grid item xs={'auto'}>
                    <Typography style={{ fontWeight: 'bold' , color: '#FFAFA8'}}
                    sx={{ typography: { lg: 'h6', sm: 'subtitle1' } }}
                    >${summaryStatistics.walletBalance[0].total_wallet_balance}</Typography>
                  </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container rowSpacing={2} columnSpacing={2} justifyContent='center' mt={5}>
        <Grid xs={6} minWidth={500} sx={{ pb: 4 }} item={true}>
          <Container>
            <Typography align={'center'}>Number of New Parents</Typography>
            <ResponsiveContainer width='100%' height={400}>
              <BarChart
                width={800}
                height={350}
                data={numParentsData}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" interval={0} />
                <YAxis/>
                <Tooltip />
                <Bar dataKey="New Parents" fill="#FFAFA8" minPointSize={5} isAnimationActive={false}>
                  <LabelList dataKey="New Parents" position="top" />
                </Bar>
                
              </BarChart>
            </ResponsiveContainer>
          </Container>
        </Grid>
        <Grid xs={6} minWidth={500} sx={{ pb: 4 }} item={true}>
          <Container>
            <Typography align={'center'}>Number of New Sitters</Typography>
            <ResponsiveContainer width='100%' height={400}>
              <BarChart
                width={800}
                height={350}
                data={numSittersData}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" interval={0} />
                <YAxis/>
                <Tooltip />
                <Bar dataKey="New Sitters" fill="#FFAFA8" minPointSize={5} isAnimationActive={false}>
                  <LabelList dataKey="New Sitters" position="top" />
                </Bar>
                
              </BarChart>
            </ResponsiveContainer>
          </Container>
        </Grid>
        <Grid xs={6} minWidth={500} sx={{ pb: 4 }} item={true}>
          <Container>
            <Typography align={'center'}>Number of hours</Typography>
            <ResponsiveContainer width='100%' height={400}>
              <BarChart
                width={600}
                height={250}
                data={numHours}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="Hours" stackId="hours" fill="#FFAFA8" isAnimationActive={false}>
                  <LabelList dataKey="Hours" position="top" />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Container>
        </Grid>
        <Grid xs={6} minWidth={500} sx={{ pb: 4 }} item={true}>
          <Container>
            <Typography align={'center'}>Number of Transactions</Typography>
            <ResponsiveContainer width='100%' height={400}>
              <BarChart
                width={600}
                height={250}
                data={numTransactions}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="Transactions" stackId="hours" fill="#FFAFA8" isAnimationActive={false}>
                  <LabelList dataKey="Transactions" position="top" />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Container>
        </Grid>
        <Grid xs={6} minWidth={500} sx={{ pb: 4 }} item={true}>
          <Container>
            <Typography align={'center'}>Services Fee Charged</Typography>
            <ResponsiveContainer width='100%' height={400}>
              <BarChart
                width={600}
                height={250}
                data={servicesFeeCharged}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" interval={0}/>
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="Sitters Payout" fill="#9AAEEF" stackId='service_fee' isAnimationActive={false}>
                  <LabelList dataKey='Sitters Payout' position='inside'/>
                </Bar>
                <Bar dataKey="Commissions" fill="#FFAFA8" stackId = 'service_fee'isAnimationActive={false}>
                  <LabelList dataKey='Commissions' position='inside'/>
                  <LabelList dataKey="Total" position="top" />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Container>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Dashboard;
