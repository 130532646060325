import { AxiosResponse } from 'axios';
import { API } from 'config';
import { GET_PROMOS_LIST_PARAMS, LIST_BODY_RESULT_ROWS } from 'models';
import { AppAPIInstance } from 'services';

export const getPromoCodeListApi = async (
  params?: GET_PROMOS_LIST_PARAMS
): Promise<AxiosResponse<{ data: LIST_BODY_RESULT_ROWS<any> }>> => {
  return await AppAPIInstance.get(API.PROMOS.DEFAULT, {
    params,
  });
};

export const getPromosDetailApi = async (
  id: string
): Promise<AxiosResponse<{ data: any }>> => {
  return await AppAPIInstance.get(`${API.PROMOS.DEFAULT}/${id}`);
};

export const createPromosApi = async (data: any) => {
  return await AppAPIInstance.post(API.PROMOS.DEFAULT, data);
};

export const updatePromosApi = async (id: string, data: any) => {
  return await AppAPIInstance.put(`${API.PROMOS.DEFAULT}/${id}`, data);
};

export const deletePromosApi = async (id: string) => {
  return await AppAPIInstance.delete(`${API.PROMOS.DEFAULT}/${id}`);
};

export const deleteManyPromosApi = async (ids: string[]) => {
  return await AppAPIInstance.delete(API.PROMOS.DEFAULT, {
    data: {
      ids,
    },
  });
};
