import React from 'react';

const FilteredIcon = () => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.44 8.09C13.1925 8.03 12.93 8 12.66 8C10.695 8 9.0975 9.5975 9.0975 11.5625C9.0975 12.23 9.285 12.86 9.615 13.4C9.8925 13.865 10.275 14.2625 10.74 14.5475C11.295 14.915 11.955 15.125 12.66 15.125C13.965 15.125 15.0975 14.4275 15.7125 13.4C16.0425 12.86 16.2225 12.23 16.2225 11.5625C16.2225 9.8675 15.0375 8.4425 13.44 8.09ZM14.4375 11.0975L12.5325 12.8525C12.4275 12.95 12.285 13.0025 12.15 13.0025C12.0075 13.0025 11.865 12.95 11.7525 12.8375L10.875 11.96C10.6575 11.7425 10.6575 11.3825 10.875 11.165C11.0925 10.9475 11.4525 10.9475 11.67 11.165L12.165 11.66L13.6725 10.2725C13.905 10.0625 14.2575 10.0775 14.4675 10.3025C14.6775 10.535 14.6625 10.8875 14.4375 11.0975Z"
        fill="#226FE3"
      />
      <path
        d="M15.435 3.515V5.18C15.435 5.7875 15.06 6.545 14.685 6.9275L14.55 7.0475C14.445 7.145 14.2875 7.1675 14.1525 7.1225C14.0025 7.07 13.8525 7.0325 13.7025 6.995C13.3725 6.9125 13.02 6.875 12.66 6.875C10.0725 6.875 7.9725 8.975 7.9725 11.5625C7.9725 12.4175 8.205 13.2575 8.6475 13.9775C9.0225 14.6075 9.5475 15.1325 10.1175 15.485C10.29 15.5975 10.3575 15.8375 10.2075 15.9725C10.155 16.0175 10.1025 16.055 10.05 16.0925L9 16.775C8.025 17.3825 6.6825 16.7 6.6825 15.485V11.4725C6.6825 10.94 6.3825 10.2575 6.0825 9.8825L3.24 6.8525C2.865 6.47 2.565 5.7875 2.565 5.3375V3.59C2.565 2.6825 3.24 2 4.0575 2H13.9425C14.76 2 15.435 2.6825 15.435 3.515Z"
        fill="#226FE3"
      />
    </svg>
  );
};

export default FilteredIcon;
