import React from 'react';

const Trash = ({ ...props }) => {
  return (
    <svg
      width={props.opacity ? 21 : 19}
      height={props.opacity ? 20 : 18}
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ opacity: props.opacity ? 0.33 : 1 }}
    >
      <path
        d="M8.35706 13.5C8.55597 13.5 8.74673 13.421 8.88739 13.2803C9.02804 13.1397 9.10706 12.9489 9.10706 12.75V8.25C9.10706 8.05109 9.02804 7.86032 8.88739 7.71967C8.74673 7.57902 8.55597 7.5 8.35706 7.5C8.15814 7.5 7.96738 7.57902 7.82673 7.71967C7.68607 7.86032 7.60706 8.05109 7.60706 8.25V12.75C7.60706 12.9489 7.68607 13.1397 7.82673 13.2803C7.96738 13.421 8.15814 13.5 8.35706 13.5ZM15.8571 4.5H12.8571V3.75C12.8571 3.15326 12.62 2.58097 12.198 2.15901C11.7761 1.73705 11.2038 1.5 10.6071 1.5H9.10706C8.51032 1.5 7.93802 1.73705 7.51607 2.15901C7.09411 2.58097 6.85706 3.15326 6.85706 3.75V4.5H3.85706C3.65814 4.5 3.46738 4.57902 3.32673 4.71967C3.18607 4.86032 3.10706 5.05109 3.10706 5.25C3.10706 5.44891 3.18607 5.63968 3.32673 5.78033C3.46738 5.92098 3.65814 6 3.85706 6H4.60706V14.25C4.60706 14.8467 4.84411 15.419 5.26607 15.841C5.68802 16.2629 6.26032 16.5 6.85706 16.5H12.8571C13.4538 16.5 14.0261 16.2629 14.448 15.841C14.87 15.419 15.1071 14.8467 15.1071 14.25V6H15.8571C16.056 6 16.2467 5.92098 16.3874 5.78033C16.528 5.63968 16.6071 5.44891 16.6071 5.25C16.6071 5.05109 16.528 4.86032 16.3874 4.71967C16.2467 4.57902 16.056 4.5 15.8571 4.5ZM8.35706 3.75C8.35706 3.55109 8.43607 3.36032 8.57673 3.21967C8.71738 3.07902 8.90814 3 9.10706 3H10.6071C10.806 3 10.9967 3.07902 11.1374 3.21967C11.278 3.36032 11.3571 3.55109 11.3571 3.75V4.5H8.35706V3.75ZM13.6071 14.25C13.6071 14.4489 13.528 14.6397 13.3874 14.7803C13.2467 14.921 13.056 15 12.8571 15H6.85706C6.65814 15 6.46738 14.921 6.32673 14.7803C6.18607 14.6397 6.10706 14.4489 6.10706 14.25V6H13.6071V14.25ZM11.3571 13.5C11.556 13.5 11.7467 13.421 11.8874 13.2803C12.028 13.1397 12.1071 12.9489 12.1071 12.75V8.25C12.1071 8.05109 12.028 7.86032 11.8874 7.71967C11.7467 7.57902 11.556 7.5 11.3571 7.5C11.1581 7.5 10.9674 7.57902 10.8267 7.71967C10.6861 7.86032 10.6071 8.05109 10.6071 8.25V12.75C10.6071 12.9489 10.6861 13.1397 10.8267 13.2803C10.9674 13.421 11.1581 13.5 11.3571 13.5Z"
        fill={props.color ? props.color : 'white'}
      />
    </svg>
  );
};

export default Trash;
