import { takeLatest, call, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { GET_APP_VERSION_LIST_REQUEST } from 'store/reducers/settings/actionType';
import {
  APP_VERSION_TYPE,
  DataListResponse,
  GET_APP_VERSION_LIST_PARAMS_REQUEST,
} from 'models';
import { getAppVersionListApi } from 'services';
import { setAppVersionListSuccess } from 'store/reducers/settings';
import { getMetadata } from 'helpers';
import { setGlobalLoading } from 'store/reducers/global';

function* getAppVersionListRequest(
  action: PayloadAction<{
    params?: GET_APP_VERSION_LIST_PARAMS_REQUEST;
  }>
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<{
      data: DataListResponse<APP_VERSION_TYPE>;
    }> = yield call(getAppVersionListApi, action.payload.params);

    yield put(
      setAppVersionListSuccess({
        records: response.data.data.rows,
        metadata: getMetadata(
          response.data.data,
          action.payload.params?.page,
          action.payload.params?.limit
        ),
      })
    );
    yield put(setGlobalLoading(false));
  } catch (error: unknown) {}
}

function* appVersions() {
  yield takeLatest(GET_APP_VERSION_LIST_REQUEST, getAppVersionListRequest);
}

export default appVersions;
