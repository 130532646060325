import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { CloseCircle, Magnifier } from 'asset';
import Select from 'components/form-control/Select';
import { GetOptionsResponse } from 'components/form-control/Selects';
import yup from 'config/yup.custom';
import { NotifyService } from 'helpers';
import { GET_SITTERS_PARAMS_REQUEST, SITTER_TYPE } from 'models';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { DropdownIndicatorProps, components } from 'react-select';
import { addManySittersApi, getSittersListApi } from 'services';
// import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { GET_REQUESTS_DETAIL_REQUEST } from 'store/reducers/requests/actionTypes';

interface Props {
  open: boolean;
  handleClosePopup?: any;
  onSuccess?: any;
  subject?: any;
}

interface IFormInputs {
  sitterIds: {
    country_code: string;
    createdAt: string;
    email: string;
    first_name: string;
    id: string;
    label: string;
    last_name: string;
    phone_number: string;
    rating: number;
    sitter_id: string;
    status: string;
  };
}

const PopupEditSitter = (props: Props) => {
  const { open, handleClosePopup, subject, onSuccess } = props;
  // const theme = useTheme();

  const { requests_detail } = useAppSelector(
    (state) => state.requestReducer.requests
  );

  const schema = useMemo(() => {
    return yup.object({
      sitterIds: yup.mixed().notRequired(),
    });
  }, []);

  const form = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      sitterIds: {},
    },
  });

  const { handleSubmit } = form;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (subject) {
      dispatch({
        type: GET_REQUESTS_DETAIL_REQUEST,
        payload: {
          id: subject.id,
        },
      });
    }
    // eslint-disable-next-line
  }, [subject]);

  const getOptions =
    (key: keyof IFormInputs) =>
    async (page: number = 1, limit: number = 20, keyword?: string) => {
      let data: GetOptionsResponse | null = null;
      switch (key) {
        case 'sitterIds':
          const paramsSitter: GET_SITTERS_PARAMS_REQUEST = {
            page: page,
            limit: limit,
            status: 'active',
          };
          if (keyword) paramsSitter.keyword = keyword;
          data = await getSittersListApi(paramsSitter)
            .then(({ data }) => {
              return {
                metadata: data.data.metadata,
                options: data.data.records.map((it: SITTER_TYPE) => ({
                  ...it,
                  id: it.sitter_id,
                  label: `${it.first_name} ${it.last_name}`,
                })),
              };
            })
            .catch((e) => {
              NotifyService.error(e);
              return null;
            });
          break;
      }
      return data;
    };

  const onSubmit = async (data: IFormInputs) => {
    if (
      !!!requests_detail?.bookings.find(
        (temp: any) => temp.Sitter.sitter_id === data.sitterIds.sitter_id
      )
    ) {
      const newData = {
        sitterIds: [data.sitterIds.sitter_id],
      };
      await addManySittersApi(requests_detail?.id, newData)
        .then((r) => {
          onSuccess(r.data.data);
          handleClosePopup();
          NotifyService.success('Success');
        })
        .catch((e) => NotifyService.error(e))
        .finally(() => {});
    } else {
      NotifyService.error('Sitter has been added');
    }
  };

  const DropdownIndicator = (props: DropdownIndicatorProps) => {
    return (
      <components.DropdownIndicator {...props}>
        <Magnifier />
      </components.DropdownIndicator>
    );
  };

  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiPaper-root': {
          width: 530,
          borderRadius: 0,
        },
      }}
    >
      <Grid>
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '20px 25px',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '100%',
            }}
          >
            Add Sitter
          </Typography>
          <IconButton
            onClick={handleClosePopup}
            sx={{
              padding: 0,
            }}
          >
            <CloseCircle />
          </IconButton>
        </Grid>
        <Divider />
        <Box p="23px 32px">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack
              mb="24px"
              spacing="9px"
              direction="row"
              justifyContent="space-between"
            >
              <Stack width="330px">
                <Select
                  components={{ DropdownIndicator }}
                  placeholder="Search sitters to add"
                  name="sitterIds"
                  form={form}
                  isHasMore
                  filterFunc={false}
                  getOptions={getOptions('sitterIds')}
                />
              </Stack>

              <Button
                type="submit"
                variant="outlined"
                color="secondary"
                sx={{
                  width: '125px',
                  height: '39px',
                  fontSize: '14px',
                }}
                // onClick={(e) => setOpenPopup(e, TYPE_POPUP.ADD, {})}
              >
                Add Sitter
              </Button>
            </Stack>
          </form>
          <Divider />
          {/* <Stack mt="24px" spacing="10px">
            {requests_detail?.bookings.map((item: any, index: any) => {
              return (
                <Stack
                  key={index}
                  spacing="7px"
                  direction="row"
                  justifyContent="space-between"
                >
                  <ItemSitterEdit
                    subject={item}
                    setOpenDelete={setOpenDelete}
                    setOpenPopup={setOpenPopup}
                  />
                </Stack>
              );
            })}
          </Stack> */}
        </Box>
      </Grid>
    </Dialog>
  );
};

export default PopupEditSitter;
