import { Button, CardMedia, Dialog, Divider, Grid, IconButton, Stack, Typography } from "@mui/material";
import { CloseCircle } from "asset";
import { NotifyService } from "helpers";
import { OptionItem, reportStatusColor, reportStatuses, REPORT_TYPE } from "models";
import { memo, useEffect, useState, useMemo } from "react"
import { getReportDetailApi, updateReportStatus } from "services";
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'config/yup.custom';
import { useForm } from 'react-hook-form';
import { DetailItemData, DetailItemTitle } from "components/common";
import { Inputs } from "components/form-control";
import Select from "components/form-control/Select";
import { useDateTime } from "hooks";

interface Props {
  open: boolean;
  subject?: string;
  onSuccess: () => void;
  handleClosePopup: () => void;
}

interface IFormInputs {
  remark: string;
  status: OptionItem;
}

const defaultValues: Partial<IFormInputs> = {
  remark: '',
  status: undefined,
}

const PopupDetailReport = memo(({ open, subject, onSuccess, handleClosePopup }: Props) => {

  const { formatDateTime } = useDateTime()

  const [data, setData] = useState<REPORT_TYPE>();

  const schema = useMemo(() => {
    return yup.object({
      remark: yup.string().notRequired(),
      status: yup.mixed().required("Status is required"),
    });
  }, []);

  const form = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: defaultValues
  });

  const { reset } = form

  const _handleClosePopup = () => {
    setData(undefined)
    reset({ ...defaultValues })
    handleClosePopup()
  }

  useEffect(() => {
    if (!open) return
    if (subject) {
      const getDetail = async () => {
        await getReportDetailApi(subject)
          .then(({ data }) => {
            setData(data.data)
            reset({
              remark: data.data.remark || '',
              status: reportStatuses.find(it => it.id === data.data.status)
            })
          })
          .catch((e) => NotifyService.error(e))
      }
      getDetail()
    }
  }, [open, subject, reset])

  const onSubmit = async (data: IFormInputs) => {
    if (!subject) return
    await updateReportStatus(subject, {
      status: data.status?.id,
      remark: data.remark
    })
      .then((r) => {
        onSuccess();
        _handleClosePopup();
        NotifyService.success(r)
      })
      .catch((e) => NotifyService.error(e))
      .finally(() => { });
  }


  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiPaper-root': {
          width: 523,
          height: 'auto',
          borderRadius: 0,
        },
      }}
    >
      <Grid>
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '20px 25px',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '100%',
            }}
          >
            Report Detail
          </Typography>
          <IconButton
            onClick={_handleClosePopup}
            sx={{ p: 0 }}
          >
            <CloseCircle />
          </IconButton>
        </Grid>
        <Divider />
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Stack spacing="20px" p="25px 24px">
            <Stack spacing="16px">
              <Grid>
                <DetailItemTitle>
                  Reported User
                </DetailItemTitle>
                <DetailItemData sx={{ color: '#1F64CC' }}>
                  {data?.target?.first_name}&nbsp;{data?.target?.last_name}
                </DetailItemData>
              </Grid>
              <Grid>
                <DetailItemTitle>
                  Reporter
                </DetailItemTitle>
                <DetailItemData sx={{ color: '#1F64CC' }}>
                  {data?.actor?.first_name}&nbsp;{data?.actor?.last_name}
                </DetailItemData>
              </Grid>
              <Grid>
                <DetailItemTitle>
                  Reason
                </DetailItemTitle>
                <DetailItemData>
                  {data?.ReportReason?.reason}
                </DetailItemData>
              </Grid>
              <Grid>
                <DetailItemTitle>
                  Description
                </DetailItemTitle>
                <DetailItemData>
                  {data?.description}
                </DetailItemData>
              </Grid>
              {!!data?.images?.length && (
                <Grid>
                  <DetailItemTitle>
                    Attachments
                  </DetailItemTitle>
                  <Stack direction="row" spacing={0} sx={{ flexWrap: 'wrap', gap: "5px" }}>
                    {data?.images?.map((it, index) => (
                      <CardMedia
                        key={index}
                        component="img"
                        image={it}
                        sx={{ width: '55px', height: '55px' }}
                        alt="report attachments"
                      />
                    ))}
                  </Stack>
                </Grid>
              )}
              <Grid>
                <DetailItemTitle>
                  Reported on
                </DetailItemTitle>
                <DetailItemData>
                  {formatDateTime(data?.createdAt)}
                </DetailItemData>
              </Grid>
              <Grid>
                <DetailItemTitle>
                  Status
                </DetailItemTitle>
                <DetailItemData sx={{ color: reportStatusColor[data?.status ?? ''] || '#B6B6B6', textTransform: "capitalize" }}>
                  {data?.status}
                </DetailItemData>
              </Grid>
              <Divider />
              <Grid>
                <Inputs
                  title="Action Taken"
                  form={form}
                  name="remark"
                  rows={4}
                  placeholder="Write something"
                />
              </Grid>
              <Grid>
                <Select
                  title="Status"
                  name="status"
                  form={form}
                  filterFunc
                  options={reportStatuses}
                />
              </Grid>
              <Divider />
              {!!data?.Admin && (
                <Grid>
                  <DetailItemTitle>
                    Last Update by {data?.Admin?.full_name}, {formatDateTime(data?.updatedAt)}
                  </DetailItemTitle>
                </Grid>
              )}
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing="8px"
              pt="42px"
            >
              <Button
                variant="outlined"
                color="secondary"
                sx={{
                  width: '233.5px',
                  height: '30px',
                }}
                onClick={_handleClosePopup}
              >
                Cancel
              </Button>
              <Button
                color="secondary"
                variant="contained"
                sx={{
                  width: '233.5px',
                  height: '30px',
                }}
                type="submit"
              >
                Save
              </Button>
            </Stack>
          </Stack>
        </form>
      </Grid>
    </Dialog>
  )
})

export default PopupDetailReport