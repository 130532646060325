import React from 'react';

const DocumentDownload = ({ ...props }) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 11.8334C5.93333 11.8334 5.87333 11.82 5.80667 11.7934C5.62 11.72 5.5 11.5334 5.5 11.3334V7.33337C5.5 7.06004 5.72667 6.83337 6 6.83337C6.27333 6.83337 6.5 7.06004 6.5 7.33337V10.1267L6.98 9.64671C7.17333 9.45337 7.49333 9.45337 7.68667 9.64671C7.88 9.84004 7.88 10.16 7.68667 10.3534L6.35333 11.6867C6.26 11.78 6.12667 11.8334 6 11.8334Z"
        fill="#226FE3"
      />
      <path
        d="M6 11.8334C5.87334 11.8334 5.74667 11.7867 5.64667 11.6867L4.31333 10.3534C4.12 10.16 4.12 9.84004 4.31333 9.64671C4.50667 9.45338 4.82667 9.45338 5.02 9.64671L6.35333 10.98C6.54667 11.1734 6.54667 11.4934 6.35333 11.6867C6.25333 11.7867 6.12667 11.8334 6 11.8334Z"
        fill="#226FE3"
      />
      <path
        d="M9.99998 15.1666H5.99998C2.37998 15.1666 0.833313 13.6199 0.833313 9.99992L0.833313 5.99992C0.833313 2.37992 2.37998 0.833252 5.99998 0.833252L9.33331 0.833252C9.60665 0.833252 9.83331 1.05992 9.83331 1.33325C9.83331 1.60659 9.60665 1.83325 9.33331 1.83325L5.99998 1.83325C2.92665 1.83325 1.83331 2.92659 1.83331 5.99992V9.99992C1.83331 13.0733 2.92665 14.1666 5.99998 14.1666H9.99998C13.0733 14.1666 14.1666 13.0733 14.1666 9.99992V6.66659C14.1666 6.39325 14.3933 6.16659 14.6666 6.16659C14.94 6.16659 15.1666 6.39325 15.1666 6.66659V9.99992C15.1666 13.6199 13.62 15.1666 9.99998 15.1666Z"
        fill="#226FE3"
      />
      <path
        d="M14.6666 7.16658H12C9.71998 7.16658 8.83331 6.27991 8.83331 3.99991V1.33324C8.83331 1.13324 8.95331 0.946578 9.13998 0.873244C9.32665 0.793244 9.53998 0.839911 9.68665 0.979911L15.02 6.31324C15.16 6.45324 15.2066 6.67324 15.1266 6.85991C15.0466 7.04658 14.8666 7.16658 14.6666 7.16658ZM9.83331 2.53991V3.99991C9.83331 5.71991 10.28 6.16658 12 6.16658H13.46L9.83331 2.53991Z"
        fill="#226FE3"
      />
    </svg>
  );
};

export default DocumentDownload;
