import { HeadCell, REVIEW_TYPE } from 'models';

export const headCells: readonly HeadCell<REVIEW_TYPE>[] = [
  {
    id: 'job_id',
    numeric: false,
    disablePadding: true,
    isSort: false,
    label: 'Job No.',
  },
  {
    id: 'parent',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Parent',
  },
  {
    id: 'content',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Review Comments',
  },
  {
    id: 'is_anonymous',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Anonymous',
  },
  {
    id: 'rate',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Rating',
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Review Date',
  },
];
