import {
  Box,
  Button,
  Checkbox,
  Paper,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  buttonClasses,
} from '@mui/material';
import usePagination from '@mui/material/usePagination/usePagination';
import { Filter } from 'asset';
import { HeadTable, SelectField, TableCaption } from 'components';
import { TableCellNoData } from 'components/common/table';
import { getSortItem } from 'helpers';
import { useDateTime } from 'hooks';
import {
  ACTIVITY_TYPE,
  GET_ACTIVITY_TAB_PARAMS_REQUEST,
  OptionItem,
} from 'models';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setParentActivityParams } from 'store/reducers/accounts';
import { getParentActivityRequest } from 'store/reducers/accounts/actionTypes';
import { theme } from 'theme';
import { OPTIONS_DATA_FILTER, headCells } from './constants';

interface IFormInputs {
  activityType?: OptionItem<string>[];
}

interface ActivityProps {
  parentId: string | undefined;
}
const Activity = ({ parentId }: ActivityProps) => {
  const { activity } = useAppSelector(
    (state) => state.accountReducer.parents.parent_detail_tab
  );

  const { params } = activity;
  const { formatDateTime } = useDateTime();
  const dispatch = useAppDispatch();
  const [selected, setSelected] = useState<readonly string[]>([]);
  const form = useForm<IFormInputs>({
    mode: 'onChange',
  });
  const { control, handleSubmit } = form;

  const onSetListParams = (params: GET_ACTIVITY_TAB_PARAMS_REQUEST) => {
    dispatch(setParentActivityParams(params));
  };

  useEffect(() => {
    if (parentId) {
      dispatch(getParentActivityRequest(parentId, params));
    }
  }, [params, parentId, dispatch]);

  const handleChangeRowsPerPage = (event: SelectChangeEvent) => {
    const limit = parseInt(event.target.value, 10);
    onSetListParams({ ...params, pageNum: 1, pageSize: limit });
  };

  const { items } = usePagination({
    count: activity.totalPages,
    page: activity.pageNum,
    onChange(_event, page) {
      onSetListParams({ ...params, pageNum: page });
    },
  });

  const sortItem = useMemo(() => {
    return getSortItem('');
  }, []);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = activity?.data?.map((n: any) => n?.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (_: any, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleRequestSort = (_: any, property: keyof ACTIVITY_TYPE) => {
    setSelected([]);
    // const isAsc = sortItem?.orderBy === property && sortItem?.order === 'asc';
    // onSetListParams({
    //   ...params,
    //   sort: `${property}-${isAsc ? 'desc' : 'asc'}`,
    // });
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const onSubmit = async (data: IFormInputs) => {
    if (!!data.activityType)
      onSetListParams({
        ...params,
        activityType: data.activityType.map((item) => item.id).join(','),
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={1} direction="row" mb="16px" alignItems="center">
          <Box minWidth="210px">
            <SelectField
              isMulti
              isHasMore
              name="activityType"
              placeholder="Activity Type"
              control={control}
              filterFunc={false}
              options={OPTIONS_DATA_FILTER}
            />
          </Box>
          <Button
            type="submit"
            sx={{
              background: 'rgba(34, 111, 227, 0.08)',
              border: ' 1px solid #D9D9D9',
              borderRadius: '5px',
              boxShadow: 'none',

              color: 'secondary.main',
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '100%',
              textTransform: 'none',

              p: '0 16px 10px',
              height: '39px',
              alignItems: 'flex-end',
              ':hover': {
                background: 'rgba(34, 111, 227, 0.08)',
                border: `1px solid ${theme.palette.secondary.main}`,
                borderRadius: '5px',
                boxShadow: 'none',
              },
              [`& .${buttonClasses.startIcon}`]: {
                mr: '3px',
              },
            }}
            variant="contained"
            startIcon={<Filter />}
          >
            Filter
          </Button>
        </Stack>
      </form>
      <Stack spacing="8px">
        <TableContainer
          component={Paper}
          elevation={0}
          sx={{
            border: '1px solid #D9D9D9',
            maxHeight: 'calc(100vh - 395px)',
          }}
        >
          <Table
            stickyHeader
            sx={{ minWidth: 650 }}
            aria-labelledby="tableTitle"
          >
            <HeadTable<ACTIVITY_TYPE>
              numSelected={selected.length}
              order={sortItem?.order}
              orderBy={sortItem?.orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={activity?.data.length}
              headCells={headCells}
              onAction={false}
            />
            <TableBody
              sx={{
                height: 'auto !important',
              }}
            >
              {!!activity?.data.length ? (
                activity?.data?.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell
                        padding="checkbox"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                        }}
                      >
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          onChange={(e) => handleClick(e, row.id)}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                          fontSize: '14px',
                          fontWeight: 400,
                        }}
                      >
                        {row.type || '-'}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                          fontSize: '14px',
                          fontWeight: 400,
                        }}
                      >
                        {row.description || '-'}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                          fontSize: '14px',
                          fontWeight: 400,
                        }}
                      >
                        {row.amount || '-'}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                          fontSize: '14px',
                          fontWeight: 400,
                        }}
                      >
                        {formatDateTime(
                          row.bookingSummary.edd,
                          'DD MMM YYYY',
                          'DD-MM-YYYY'
                        )}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                          fontSize: '14px',
                          fontWeight: 400,
                        }}
                      >
                        <Link
                          to={`/nanny-bookings/${row.bookingSummary.bookingId}`}
                        >
                          {row.bookingSummary.bookingId || '-'}
                        </Link>
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                          fontSize: '14px',
                          fontWeight: 400,
                        }}
                      >
                        <Link
                          to={`/accounts/parents/${row.bookingSummary.parentId}`}
                        >
                          {row.bookingSummary.parentFirstName || '-'}
                        </Link>
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                          fontSize: '14px',
                          fontWeight: 400,
                        }}
                      >
                        {formatDateTime(row.createdAt, 'DD MMM YYYY HH:mm')}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                          fontSize: '14px',
                          fontWeight: 400,
                        }}
                      >
                        {formatDateTime(row.updatedAt, 'DD MMM YYYY HH:mm')}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCellNoData colSpan={headCells.length + 2} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TableCaption
          rowsPerPage={params?.pageSize ?? 0}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          metadata={{
            page: activity.pageNum,
            limit: activity.pageSize,
            page_count: 1,
            total_pages: activity.totalPages,
            total_count: activity.totalSize,
          }}
          items={items}
        />
      </Stack>
    </>
  );
};

export default Activity;
