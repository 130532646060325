import {
  Checkbox,
  Grid,
  Paper,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { DeleteDialog, HeadTable, TableCaption } from 'components';
import { memo, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { getSitterJobsAction } from '../../../../../store/reducers/accounts/actionTypes';
import usePagination from '@mui/material/usePagination/usePagination';
import { ECLOSE_POPUP, GET_JOBS_PARAMS_REQUEST, JOBS_TYPE } from 'models';
import { setSitterJobsParams } from 'store/reducers/accounts';
import { getSortItem } from 'helpers';
import { headCells } from './data';
import {
  ButtonDelete,
  ButtonDetail,
  ButtonEdit,
} from 'components/common/table';
import PopupDetailJobs from 'components/Popups/Jobs/PopupDetailJobs';
import PopupEditJobs from 'components/Popups/Jobs/PopupEditJobs';
import { useDateTime } from 'hooks';

interface Props {
  sitter_id?: string;
}

const Jobs = memo(({ sitter_id }: Props) => {
  const dispatch = useAppDispatch();
  const { formatDateTime } = useDateTime();
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [openDetailPopup, setOpenDetailPopup] = useState(false);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [itemJobs, setItemJobs] = useState<JOBS_TYPE>();

  const { jobs } = useAppSelector(
    (state) => state.accountReducer.sitters.sitter_list_detail
  );

  const { params } = jobs;

  const onSetSitterJobsParams = (params: GET_JOBS_PARAMS_REQUEST | null) => {
    dispatch(setSitterJobsParams(params));
  };

  useEffect(() => {
    if (sitter_id) {
      dispatch(getSitterJobsAction(sitter_id, params));
    }
  }, [params, sitter_id, dispatch]);

  const onReloadList = () => {
    if (!sitter_id) return;
    dispatch(getSitterJobsAction(sitter_id, params));
  };

  const handleChangeRowsPerPage = (event: SelectChangeEvent) => {
    const limit = parseInt(event.target.value, 10);
    onSetSitterJobsParams({ ...params, page: 1, limit });
  };

  const { items } = usePagination({
    count: jobs.metadata.total_pages,
    page: jobs.metadata.page,
    onChange(_event, page) {
      onSetSitterJobsParams({ ...params, page: page });
    },
  });

  const sortItem = useMemo(() => {
    return getSortItem(params?.sort);
  }, [params]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = jobs?.records?.map((n: any) => n?.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (_: any, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleRequestSort = (_: any, property: keyof JOBS_TYPE) => {
    setSelected([]);
    const isAsc = sortItem?.orderBy === property && sortItem?.order === 'asc';
    onSetSitterJobsParams({
      ...params,
      sort: `${property}-${isAsc ? 'desc' : 'asc'}`,
    });
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const onClosePopup = (type: ECLOSE_POPUP) => {
    switch (type) {
      case ECLOSE_POPUP.DELETE:
        setOpenDeletePopup(false);
        setItemJobs(undefined);
        break;
      case ECLOSE_POPUP.CREATE_OR_EDIT:
        setOpenEditPopup(false);
        setItemJobs(undefined);
        break;
      case ECLOSE_POPUP.DETAIL:
        setOpenDetailPopup(false);
        setItemJobs(undefined);
        break;
    }
  };

  const setOpenDelete = (event: any, data: JOBS_TYPE) => {
    event.stopPropagation();
    setItemJobs(data);
    setOpenDeletePopup(true);
  };

  const setOpenDetail = (event: any, data: JOBS_TYPE) => {
    event.stopPropagation();
    setItemJobs(data);
    setOpenDetailPopup(true);
  };

  const setOpenEdit = (event: any, data: JOBS_TYPE) => {
    event.stopPropagation();
    setItemJobs(data);
    setOpenEditPopup(true);
  };

  return (
    <Stack spacing="8px">
      <TableContainer
        component={Paper}
        elevation={0}
        sx={{
          maxHeight: 'calc(100vh - 355px)',
          border: '1px solid #D9D9D9',
        }}
      >
        <Table stickyHeader sx={{ minWidth: 650 }} aria-labelledby="tableTitle">
          <HeadTable<JOBS_TYPE>
            numSelected={selected.length}
            order={sortItem?.order}
            orderBy={sortItem?.orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={jobs?.records.length}
            headCells={headCells}
          />
          <TableBody
            sx={{
              height: 'auto !important',
            }}
          >
            {jobs.records.map((row, index) => {
              const isItemSelected = isSelected(row.id);
              const labelId = `enhanced-jobs-table-cb-${index}`;
              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.id}
                  selected={isItemSelected}
                >
                  <TableCell
                    padding="checkbox"
                    sx={{
                      borderRight: '1px solid #DEE2E6',
                    }}
                  >
                    <Checkbox
                      color="primary"
                      checked={isItemSelected}
                      onChange={(e) => handleClick(e, row.id)}
                      inputProps={{
                        'aria-labelledby': labelId,
                      }}
                    />
                  </TableCell>
                  <TableCell
                    component="th"
                    id={labelId}
                    scope="row"
                    sx={{
                      borderRight: '1px solid #DEE2E6',
                      padding: '18px 12px 17px',
                      fontSize: '14px',
                      fontWeight: 400,
                    }}
                  >
                    {row.number}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      borderRight: '1px solid #DEE2E6',
                      padding: '18px 12px 17px',
                      fontSize: '14px',
                      fontWeight: 400,
                    }}
                  >
                    {formatDateTime(row.start_datetime)}
                    &nbsp;-&nbsp;
                    {formatDateTime(row.end_datetime)}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      borderRight: '1px solid #DEE2E6',
                      padding: '18px 12px 17px',
                      fontSize: '14px',
                      fontWeight: 400,
                    }}
                  >
                    {row.address?.street} {row.address?.name}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      borderRight: '1px solid #DEE2E6',
                      padding: '18px 12px 17px',
                      fontSize: '14px',
                      fontWeight: 400,
                    }}
                  >
                    <Grid
                      container
                      alignItems="center"
                      textTransform="capitalize"
                    >
                      <Grid
                        sx={{
                          width: '8px',
                          height: '8px',
                          borderRadius: '50%',
                          marginRight: '5px',
                          backgroundColor: '#B6B6B6',
                        }}
                      />
                      <Typography sx={{ height: '20px' }}>
                        {row.status}
                      </Typography>
                    </Grid>
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      borderRight: '1px solid #DEE2E6',
                      padding: '18px 12px 17px',
                      fontSize: '14px',
                      fontWeight: 400,
                    }}
                  >
                    ${row.total.toFixed(2)}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      borderRight: '1px solid #DEE2E6',
                      padding: '18px 12px 17px',
                    }}
                  >
                    <Stack direction="row" spacing="10px">
                      <ButtonDetail onClick={(e) => setOpenDetail(e, row)} />
                      <ButtonEdit onClick={(e) => setOpenEdit(e, row)} />
                      <ButtonDelete onClick={(e) => setOpenDelete(e, row)} />
                    </Stack>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TableCaption
        rowsPerPage={params?.limit ?? 0}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        metadata={jobs?.metadata}
        items={items}
      />
      {openDeletePopup && (
        <DeleteDialog
          name=""
          open={openDeletePopup}
          onClose={() => onClosePopup(ECLOSE_POPUP.DELETE)}
          subject={itemJobs?.id}
          onSuccess={onReloadList}
          type="job"
        />
      )}
      {openDetailPopup && (
        <PopupDetailJobs
          id={itemJobs?.id}
          open={openDetailPopup}
          handleClosePopup={() => onClosePopup(ECLOSE_POPUP.DETAIL)}
        />
      )}
      {openEditPopup && (
        <PopupEditJobs
          open={openEditPopup}
          handleClosePopup={() => onClosePopup(ECLOSE_POPUP.CREATE_OR_EDIT)}
          sitterId={sitter_id}
          jobId={itemJobs?.id}
          onSuccess={onReloadList}
        />
      )}
    </Stack>
  );
});

export default Jobs;
