import {
  Button,
  Checkbox,
  Grid,
  Paper,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { DeleteDialog, HeadTable, TableCaption } from 'components';
import { memo, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hook';
import usePagination from '@mui/material/usePagination/usePagination';
import { ECLOSE_POPUP, GET_JOBS_PARAMS_REQUEST, REQUESTS_TYPE } from 'models';
import { getSortItem } from 'helpers';
import { headCells } from './data';
import { ButtonDelete, ButtonEdit } from 'components/common/table';
import { setParentRequestsParams } from 'store/reducers/accounts';
import { GET_PARENT_LIST_DETAIL_REQUEST } from 'store/reducers/accounts/actionTypes';
import PopupEditServiceRequest from 'components/Popups/ServiceRequest/PopupEditServiceRequest';
import { useDateTime } from 'hooks';
import { Link } from 'react-router-dom';
import { Eye } from 'asset';

interface Props {
  parentId: string | undefined;
}

const ServiceRequest = memo(({ parentId }: Props) => {
  const theme = useTheme();

  const dispatch = useAppDispatch();
  const { formatDateTime } = useDateTime();
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [itemRequest, setItemRequest] = useState<REQUESTS_TYPE>();

  const { requests } = useAppSelector(
    (state) => state.accountReducer.parents.parent_detail_tab
  );

  const { params } = requests;

  const onSetParentRequestParams = (params: GET_JOBS_PARAMS_REQUEST | null) => {
    dispatch(setParentRequestsParams(params));
  };

  useEffect(() => {
    if (parentId) {
      dispatch({
        type: GET_PARENT_LIST_DETAIL_REQUEST,
        payload: { params: { ...params, parent_id: parentId } },
      });
    }
  }, [params, parentId, dispatch]);

  const onReloadList = () => {
    if (!parentId) return;
    dispatch({
      type: GET_PARENT_LIST_DETAIL_REQUEST,
      payload: { params: { ...params, parent_id: parentId } },
    });
  };

  const handleChangeRowsPerPage = (event: SelectChangeEvent) => {
    const limit = parseInt(event.target.value, 10);
    onSetParentRequestParams({ ...params, page: 1, limit });
  };

  const { items } = usePagination({
    count: requests.metadata.total_pages,
    page: requests.metadata.page,
    onChange(_event, page) {
      onSetParentRequestParams({ ...params, page: page });
    },
  });

  const sortItem = useMemo(() => {
    return getSortItem(params?.sort);
  }, [params]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = requests?.records?.map((n: any) => n?.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (_: any, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleRequestSort = (_: any, property: keyof REQUESTS_TYPE) => {
    setSelected([]);
    const isAsc = sortItem?.orderBy === property && sortItem?.order === 'asc';
    onSetParentRequestParams({
      ...params,
      sort: `${property}-${isAsc ? 'desc' : 'asc'}`,
    });
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const onClosePopup = (type: ECLOSE_POPUP) => {
    switch (type) {
      case ECLOSE_POPUP.DELETE:
        setOpenDeletePopup(false);
        setItemRequest(undefined);
        break;
      case ECLOSE_POPUP.CREATE_OR_EDIT:
        setOpenEditPopup(false);
        setItemRequest(undefined);
        break;
    }
  };

  const setOpenDelete = (event: any, data: REQUESTS_TYPE) => {
    event.stopPropagation();
    setItemRequest(data);
    setOpenDeletePopup(true);
  };

  const setOpenEdit = (event: any, data: REQUESTS_TYPE) => {
    event.stopPropagation();
    setItemRequest(data);
    setOpenEditPopup(true);
  };

  return (
    <Stack spacing="8px">
      <TableContainer
        component={Paper}
        elevation={0}
        sx={{
          border: '1px solid #D9D9D9',
          maxHeight: 'calc(100vh - 355px)',
        }}
      >
        <Table stickyHeader sx={{ minWidth: 650 }} aria-labelledby="tableTitle">
          <HeadTable<REQUESTS_TYPE>
            numSelected={selected.length}
            order={sortItem?.order}
            orderBy={sortItem?.orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={requests?.records.length}
            headCells={headCells}
          />
          <TableBody
            sx={{
              height: 'auto !important',
            }}
          >
            {requests.records.map((row, index) => {
              const isItemSelected = isSelected(row.id);
              const labelId = `enhanced-jobs-table-cb-${index}`;
              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.id}
                  selected={isItemSelected}
                >
                  <TableCell
                    padding="checkbox"
                    sx={{
                      borderRight: '1px solid #DEE2E6',
                    }}
                  >
                    <Checkbox
                      color="primary"
                      checked={isItemSelected}
                      onChange={(e) => handleClick(e, row.id)}
                      inputProps={{
                        'aria-labelledby': labelId,
                      }}
                    />
                  </TableCell>
                  <TableCell
                    component="th"
                    id={labelId}
                    scope="row"
                    sx={{
                      borderRight: '1px solid #DEE2E6',
                      padding: '18px 12px 17px',
                      fontSize: '14px',
                      fontWeight: 400,
                    }}
                  >
                    {row.number}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      borderRight: '1px solid #DEE2E6',
                      padding: '18px 12px 17px',
                      fontSize: '14px',
                      fontWeight: 400,
                      color: row.type === 'just_once' ? '#FF796E' : '#226FE3',
                    }}
                  >
                    {row.type === 'just_once' ? 'Just Once' : 'Recurring'}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      borderRight: '1px solid #DEE2E6',
                      padding: '18px 12px 17px',
                      fontSize: '14px',
                      fontWeight: 400,
                    }}
                  >
                    {formatDateTime(row.from_datetime)}
                    &nbsp;-&nbsp;
                    {formatDateTime(row.to_datetime)}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      borderRight: '1px solid #DEE2E6',
                      padding: '18px 12px 17px',
                      fontSize: '14px',
                      fontWeight: 400,
                    }}
                  >
                    {row.address?.street} {row.address?.name}
                  </TableCell>

                  <TableCell
                    align="left"
                    sx={{
                      borderRight: '1px solid #DEE2E6',
                      padding: '18px 12px 17px',
                      fontSize: '14px',
                      fontWeight: 400,
                    }}
                  >
                    <Grid
                      container
                      alignItems="center"
                      textTransform="capitalize"
                    >
                      <Grid
                        sx={{
                          width: '8px',
                          height: '8px',
                          borderRadius: '50%',
                          marginRight: '5px',
                          backgroundColor:
                            row.status === 'paid'
                              ? theme.palette.green.light
                              : row.status === 'pending' || 'Pending'
                              ? theme.palette.yellow.light
                              : '#B6B6B6',
                        }}
                      />
                      <Typography
                        sx={{
                          height: '20px',
                        }}
                      >
                        {row.status}
                      </Typography>
                    </Grid>
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      borderRight: '1px solid #DEE2E6',
                      padding: '18px 12px 17px',
                    }}
                  >
                    <Stack direction="row" spacing="10px">
                      <Link to={`/requests/just-once/${row.id}`}>
                        <Button
                          sx={{
                            minWidth: '38px !important',
                            height: '32px',
                            '&.MuiButton-root': {
                              p: '0px !important',
                            },
                          }}
                          variant="contained"
                          color="secondary"
                        >
                          <Eye />
                        </Button>
                      </Link>
                      <ButtonEdit onClick={(e) => setOpenEdit(e, row)} />
                      <ButtonDelete onClick={(e) => setOpenDelete(e, row)} />
                    </Stack>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TableCaption
        rowsPerPage={params?.limit ?? 0}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        metadata={requests?.metadata}
        items={items}
      />
      {openEditPopup && (
        <PopupEditServiceRequest
          type={itemRequest?.type}
          parentId={parentId}
          open={openEditPopup}
          subject={itemRequest?.id}
          handleClosePopup={() => setOpenEditPopup(false)}
        />
      )}
      {openDeletePopup && (
        <DeleteDialog
          name={itemRequest?.id}
          open={openDeletePopup}
          onClose={() => onClosePopup(ECLOSE_POPUP.DELETE)}
          subject={itemRequest?.id}
          onSuccess={onReloadList}
          type="serviceRequest"
        />
      )}
    </Stack>
  );
});

export default ServiceRequest;
