import { all } from 'redux-saga/effects';
import appVersions from './appVersions';
import chatKeywordTrackings from './chatKeywordTrackings';
import configs from './configs';
import settings from './settings';

export const settingsSaga = function* root() {
  yield all([
    settings(), 
    appVersions(),
    chatKeywordTrackings(),
    configs()
  ]);
};
