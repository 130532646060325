import { AxiosResponse } from 'axios';
import { API } from 'config';
import {
  DOC_REF_NO_RESPONSE,
  GET_DOC_REF_NO_PARAMS,
  GET_TRANSACTIONS_PARAMS_REQUEST,
  TRANSACTION_DETAIL_TYPE,
  TRANSACTION_TYPE_TYPE,
  UPDATE_TRANSACTION_BODY,
  UPDATE_TRANSACTION_TYPE_BODY,
} from 'models';
import { AppAPIInstance } from 'services';

export const getTransactionsListApi = async (
  params?: GET_TRANSACTIONS_PARAMS_REQUEST
) => {
  const paramsConverted = {
    ...params,
    type: params?.type?.id,
  };
  return await AppAPIInstance.get(API.ADMIN.TRANSACTION.DEFAULT, {
    params: paramsConverted,
  });
};

export const deleteAllTransactionApi = async (ids: string[]) => {
  const idsObj = {
    ids,
  };
  return await AppAPIInstance.delete(API.ADMIN.TRANSACTION.DEFAULT, {
    data: idsObj,
  });
};
export const getTransactionApi = async (
  id: string
): Promise<AxiosResponse<{ data: TRANSACTION_DETAIL_TYPE }>> => {
  return await AppAPIInstance.get(`${API.ADMIN.TRANSACTION.DEFAULT}/${id}`);
};

export const deleteTransactionApi = async (id: string) => {
  return await AppAPIInstance.delete(`${API.ADMIN.TRANSACTION.DEFAULT}/${id}`);
};

export const createTransactionApi = async (data: UPDATE_TRANSACTION_BODY) => {
  return await AppAPIInstance.post(API.ADMIN.TRANSACTION.DEFAULT, data);
};

export const updateTransactionApi = async (
  id: string,
  data: UPDATE_TRANSACTION_BODY
) => {
  return await AppAPIInstance.put(
    `${API.ADMIN.TRANSACTION.DEFAULT}/${id}`,
    data
  );
};

export const getTransactionTypesApi = async (): Promise<
  AxiosResponse<{ data: TRANSACTION_TYPE_TYPE[] }>
> => {
  return await AppAPIInstance.get(API.ADMIN.TRANSACTION.TYPE);
};

export const getTransactionTypeDetailApi = async (
  id: string
): Promise<AxiosResponse<{ data: TRANSACTION_TYPE_TYPE }>> => {
  return await AppAPIInstance.get(`${API.ADMIN.TRANSACTION.TYPE}/${id}`);
};

export const updateTransactionTypeApi = async (
  id: string,
  data: UPDATE_TRANSACTION_TYPE_BODY
) => {
  return await AppAPIInstance.patch(
    `${API.ADMIN.TRANSACTION.TYPE}/${id}`,
    data
  );
};

export const getDocRefNoApi = async (
  params: GET_DOC_REF_NO_PARAMS
): Promise<AxiosResponse<DOC_REF_NO_RESPONSE>> => {
  return await AppAPIInstance.get(API.ADMIN.TRANSACTION.DOC_REF_NO, { params });
};

export const getTransactionsExportCSVApi = async (
  params?: Partial<GET_TRANSACTIONS_PARAMS_REQUEST>
) => {
  return await AppAPIInstance.get(API.ADMIN.TRANSACTION.EXPORT, {
    params,
    responseType: 'blob',
  }).catch(async (e) => {
    return Promise.reject(
      JSON.parse((await e?.response?.data?.text()) || '{}')?.error
    );
  });
};
