import {
  FormHelperText,
  IconButton,
  InputLabel,
  Stack,
  Typography,
} from '@mui/material';
import { Attach } from 'asset';
import { ChangeEvent } from 'react';
import {
  Controller,
  ControllerRenderProps,
  RegisterOptions,
  UseFormReturn,
} from 'react-hook-form';

interface Props {
  name: string;
  form: UseFormReturn<any, any>;
  rules?: Exclude<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >;
  accept: string;
  title: string;
  sx?: any;
  icon?: boolean;
  padding?: string;
  index?: number | undefined;
  handleChange: (
    e: ChangeEvent<HTMLInputElement>,
    field: ControllerRenderProps<any, string>
  ) => void;
  nameFile?: string;
  errorsName?: any;
}

const InputFile = (props: Props) => {
  const {
    name,
    form,
    rules,
    accept,
    title,
    sx,
    handleChange,
    nameFile,
    errorsName,
  } = props;

  const { control } = form;
  return (
    <>
      <InputLabel
        sx={{
          fontWeight: 500,
          fontSize: '15px',
          lineHeight: '100%',
          color: '#000',
          marginBottom: '5px',
        }}
      >
        {title}
      </InputLabel>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => {
          return (
            <IconButton
              sx={{
                color: '#000000',
                fontWeight: 400,
                lineHeight: '100%',
                border: '1px solid #D5D5D5',
                borderRadius: '5px',
                minWidth: '100% !important',
                '& .MuiTypography-root': {
                  fontSize: '13px !important',
                },
                '&:hover': {
                  backgroundColor: 'transparent !important',
                },
                ...sx,
              }}
              aria-label="upload picture"
              component="label"
              {...field}
            >
              <input
                hidden
                accept={accept}
                type="file"
                onChange={(e) => handleChange(e, field)}
              />
              <Stack
                direction="row"
                spacing="10px"
                alignItems="center"
                sx={{
                  width: '100%',
                }}
                justifyContent="space-between"
              >
                <Typography
                  sx={{
                    // color: '#000000',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {nameFile ? nameFile : ''}
                </Typography>
                <Attach />
              </Stack>
            </IconButton>
          );
        }}
      />

      <FormHelperText
        error
        sx={{
          fontSize: '16px',
          letterSpacing: '0.045em',
          color: 'white.light',
        }}
      >
        {errorsName || ''}
      </FormHelperText>
    </>
  );
};

export default InputFile;
