import { AxiosResponse } from 'axios';
import {
  COUNTRIES_TYPE,
  GET_CERTIFICATES_PARAMS_REQUEST,
  GET_COUNTRIES_PARAMS_REQUEST,
  GET_PROMOS_LIST_PARAMS,
  GET_REASONS_LIST_PARAMS,
  GET_REGIONS_PARAMS_REQUEST,
  LIST_BODY_RESULT,
  LIST_BODY_RESULT_ROWS,
  REASON_TYPE,
} from 'models';
import { call, put, takeLatest } from 'redux-saga/effects';

import { PayloadAction } from '@reduxjs/toolkit';
import { NotifyService } from 'helpers';
import {
  deleteBadgesApi,
  deleteReasonApi,
  deleteRegionsApi,
  getBadgeListApi,
  getReasonListApi,
  getRegionListApi,
  getSettingsCountriesListApi,
  removeCountriesFromBlackListApi,
} from 'services';
import { nannyTabsApi } from 'services/accounts/nanny';
import { deletePromosApi, getPromoCodeListApi } from 'services/promotions';
import { setGlobalLoading } from 'store/reducers/global';
import { setPromosListSuccess } from 'store/reducers/promotions';
import {
  DELETE_PROMOS_REQUEST,
  GET_PROMOS_LIST_REQUEST,
} from 'store/reducers/promotions/actionType';
import {
  setBadgesListSuccess,
  setBlackListCountryListSuccess,
  setCertificateListSuccess,
  setReasonListSuccess,
  setRegionListSuccess,
} from 'store/reducers/settings';
import {
  DELETE_BADGE_REQUEST,
  DELETE_CERTIFICATE_REQUEST,
  DELETE_COUNTRY_REQUEST,
  DELETE_REASON_REQUEST,
  DELETE_REGIONS_REQUEST,
  GET_BADGE_LIST_REQUEST,
  GET_CERTIFICATE_LIST_REQUEST,
  GET_COUNTRIES_LIST_REQUEST,
  GET_REASON_LIST_REQUEST,
  GET_REGIONS_LIST_REQUEST,
} from 'store/reducers/settings/actionType';

function* setRegionListRequest(
  action: PayloadAction<{
    params?: GET_REGIONS_PARAMS_REQUEST;
  }>
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<any> = yield call(
      getRegionListApi,
      action.payload.params
    );

    yield put(
      setRegionListSuccess({
        records: response.data.data.rows,
        metadata: response.data.data.metadata,
      })
    );
    yield put(setGlobalLoading(false));
  } catch (error: unknown) {}
}
function* getReasonListRequest(
  action: PayloadAction<{
    params?: GET_REASONS_LIST_PARAMS;
  }>
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<{
      data: LIST_BODY_RESULT_ROWS<REASON_TYPE>;
    }> = yield call(getReasonListApi, action.payload.params);

    yield put(
      setReasonListSuccess({
        records: response.data.data.rows,
        metadata: response.data.data.metadata,
      })
    );
    yield put(setGlobalLoading(false));
  } catch (error: unknown) {}
}

function* deleteRegionRequest(
  action: PayloadAction<{
    id: string;
    onSuccess?: () => void;
  }>
) {
  try {
    yield call(deleteRegionsApi, action.payload.id);
    if (action.payload.onSuccess) action.payload.onSuccess();
  } catch (error: unknown) {}
}

function* deleteReasonRequest(
  action: PayloadAction<{
    id: string;
    onSuccess?: () => void;
  }>
) {
  try {
    yield call(deleteReasonApi, action.payload.id);
    if (action.payload.onSuccess) action.payload.onSuccess();
    NotifyService.success('Success');
  } catch (error: unknown) {
    NotifyService.error(error);
  }
}

//BADGES

function* setBadgeListRequest() {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<any> = yield call(getBadgeListApi);

    yield put(
      setBadgesListSuccess({
        records: response.data.data,
        metadata: {
          page: 1,
          limit: 50,
          page_count: 1,
          total_pages: 1,
          total_count: 1,
        },
      })
    );
    yield put(setGlobalLoading(false));
  } catch (error: unknown) {}
}

function* deleteBadgeRequest(
  action: PayloadAction<{
    id: string;
    onSuccess?: () => void;
  }>
) {
  try {
    yield call(deleteBadgesApi, action.payload.id);
    if (action.payload.onSuccess) action.payload.onSuccess();
    NotifyService.success('Success');
  } catch (error: unknown) {
    NotifyService.error(error);
  }
}

//Countries
function* getCountriesRequest(
  action: PayloadAction<{
    params?: GET_COUNTRIES_PARAMS_REQUEST;
  }>
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<{
      data: LIST_BODY_RESULT<COUNTRIES_TYPE>;
    }> = yield call(getSettingsCountriesListApi, action.payload.params);
    if (action.payload.params?.is_black_list) {
      yield put(
        setBlackListCountryListSuccess({
          records: response.data.data.records,
          metadata: response.data.data.metadata,
        })
      );
    }
    yield put(setGlobalLoading(false));
  } catch (error: unknown) {}
}

function* removeCountryFromBlackListRequest(
  action: PayloadAction<{
    data: any;
    onSuccess?: () => void;
  }>
) {
  try {
    yield put(setGlobalLoading(true));
    yield call(removeCountriesFromBlackListApi, action.payload.data);
    if (action.payload.onSuccess) action.payload.onSuccess();
    NotifyService.success('Success');
    yield put(setGlobalLoading(false));
  } catch (error: unknown) {
    NotifyService.error(error);
    yield put(setGlobalLoading(false));
  }
}

//Promos
function* setPromosListRequest(
  action: PayloadAction<{
    params?: GET_PROMOS_LIST_PARAMS;
  }>
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<any> = yield call(
      getPromoCodeListApi,
      action.payload.params
    );

    yield put(
      setPromosListSuccess({
        records: response.data.data.rows,
        metadata: response.data.data.metadata,
      })
    );
    yield put(setGlobalLoading(false));
  } catch (error: unknown) {}
}

function* deletePromosRequest(
  action: PayloadAction<{
    id: string;
    onSuccess?: () => void;
  }>
) {
  try {
    yield call(deletePromosApi, action.payload.id);
    if (action.payload.onSuccess) action.payload.onSuccess();
    NotifyService.success('Success');
  } catch (error: unknown) {
    NotifyService.error(error);
  }
}

//Certificate
function* setCertificateListRequest(
  action: PayloadAction<{
    params?: GET_CERTIFICATES_PARAMS_REQUEST;
  }>
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<any> = yield call(
      nannyTabsApi.getCertificate,
      action.payload.params
    );
    yield put(
      setCertificateListSuccess({
        records: response.data.data,
        metadata: {
          limit: 1,
          page: 1,
          page_count: 50,
          total_pages: 1,
          total_count: 1,
        },
      })
    );
    yield put(setGlobalLoading(false));
  } catch (error: unknown) {}
}

function* deleteCertificateRequest(
  action: PayloadAction<{
    id: string;
    onSuccess?: () => void;
  }>
) {
  try {
    yield call(nannyTabsApi.deleteCertificate, action.payload.id);
    if (action.payload.onSuccess) action.payload.onSuccess();
    NotifyService.success('Success');
  } catch (error: unknown) {
    NotifyService.error(error);
  }
}

function* regions() {
  yield takeLatest(GET_REGIONS_LIST_REQUEST, setRegionListRequest);
  yield takeLatest(DELETE_REGIONS_REQUEST, deleteRegionRequest);
  yield takeLatest(GET_REASON_LIST_REQUEST, getReasonListRequest);
  yield takeLatest(DELETE_REASON_REQUEST, deleteReasonRequest);
  yield takeLatest(GET_BADGE_LIST_REQUEST, setBadgeListRequest);
  yield takeLatest(GET_COUNTRIES_LIST_REQUEST, getCountriesRequest);
  yield takeLatest(DELETE_BADGE_REQUEST, deleteBadgeRequest);
  yield takeLatest(DELETE_COUNTRY_REQUEST, removeCountryFromBlackListRequest);
  yield takeLatest(GET_PROMOS_LIST_REQUEST, setPromosListRequest);
  yield takeLatest(DELETE_PROMOS_REQUEST, deletePromosRequest);
  yield takeLatest(GET_CERTIFICATE_LIST_REQUEST, setCertificateListRequest);
  yield takeLatest(DELETE_CERTIFICATE_REQUEST, deleteCertificateRequest);
}

export default regions;
