import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { NotifyService } from 'helpers';
import { GET_REVIEW_LIST_PARAMS_REQUEST, REVIEWS_STATE } from 'models';
import { call, put, takeLatest } from 'redux-saga/effects';
import { deleteReviewApi, getReviewsByAdminApi } from 'services';
import { setGlobalLoading } from 'store/reducers/global';
import { setReviewsListSuccess } from 'store/reducers/reviews';
import {
  DELETE_REVIEW_REQUEST,
  GET_REVIEW_BY_ADMIN_REQUEST,
} from 'store/reducers/reviews/actionTypes';

function* deleteReviewRequest(
  action: PayloadAction<{
    id: string;
    onSuccess?: () => void;
  }>
) {
  try {
    yield call(deleteReviewApi, action.payload.id);
    if (action.payload.onSuccess) action.payload.onSuccess();
    NotifyService.success('Success');
  } catch (error: unknown) {
    NotifyService.error(error);
  }
}

function* getReviewsByAdminRequest(
  action: PayloadAction<{
    params?: GET_REVIEW_LIST_PARAMS_REQUEST;
  }>
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<{
      data: REVIEWS_STATE;
    }> = yield call(getReviewsByAdminApi, action.payload.params);

    yield put(
      setReviewsListSuccess({
        rows: response.data.data.rows,
        metadata: response.data.data.metadata,
      })
    );
    yield put(setGlobalLoading(false));
  } catch (error: unknown) {
    NotifyService.error(error);
  }
}

function* reviews() {
  yield takeLatest(DELETE_REVIEW_REQUEST, deleteReviewRequest);
  yield takeLatest(GET_REVIEW_BY_ADMIN_REQUEST, getReviewsByAdminRequest);
}

export default reviews;
