import { TableCell, TableCellProps } from "@mui/material"
import { memo } from "react"

interface Props extends TableCellProps {

}

const TableCellNoData = memo(({ children, ...rest }: Props) => {
  return (
    <TableCell
      padding="checkbox"
      sx={{
        borderRight: '1px solid #DEE2E6',
        height: 77,
        textAlign: 'center',
        fontWeight: 600,
        fontSize: '40px',
        lineHeight: 1,
        color: '#C7C7CC',
      }}
      {...rest}
    >
      {children || "No data to display yet"}
    </TableCell>
  )
})

export default TableCellNoData