import React from 'react';

const EyeInputs = ({ ...props }) => {
  return (
    <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M11 0.5C7.53526 0.5 4.19326 2.36019 0.979271 6.04521L0.758584 6.30135C0.41407 6.7061 0.41407 7.29391 0.756996 7.69679C4.03943 11.5532 7.45525 13.5 11 13.5C14.4647 13.5 17.8067 11.6398 21.0207 7.95479L21.2414 7.69865C21.5859 7.2939 21.5859 6.70609 21.243 6.30321C17.9606 2.44681 14.5448 0.5 11 0.5ZM11 1.3125C14.2708 1.3125 17.4735 3.13786 20.6126 6.8258C20.6983 6.92652 20.6983 7.07348 20.6126 7.1742L20.3951 7.42661C17.3264 10.945 14.1959 12.6875 11 12.6875C7.72917 12.6875 4.52645 10.8621 1.3874 7.1742C1.30166 7.07348 1.30166 6.92652 1.3874 6.8258L1.60487 6.57339C4.67357 3.05496 7.80406 1.3125 11 1.3125ZM11 3.20833C8.8781 3.20833 7.15796 4.90592 7.15796 7C7.15796 9.09408 8.8781 10.7917 11 10.7917C13.1219 10.7917 14.842 9.09408 14.842 7C14.842 4.90592 13.1219 3.20833 11 3.20833ZM11 4.02083C12.6672 4.02083 14.0188 5.35465 14.0188 7C14.0188 8.64535 12.6672 9.97917 11 9.97917C9.33279 9.97917 7.98125 8.64535 7.98125 7C7.98125 5.35465 9.33279 4.02083 11 4.02083Z" fill="black" fillOpacity="0.54" />
    </svg>
  );
};

export default EyeInputs;
