import { HeadCell, REASON_TYPE } from 'models';

export const headCells: readonly HeadCell<REASON_TYPE>[] = [
  {
    id: 'reason',
    numeric: false,
    disablePadding: true,
    isSort: true,
    label: 'Name',
  },
];
