import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { CloseCircle } from 'asset';
import { LoadingPopup } from 'components/common';
import { Inputs } from 'components/form-control';
import yup from 'config/yup.custom';
import { NotifyService } from 'helpers';
import { GENERATE_QRCODE_BODY_RESULT } from 'models';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import QRCode from 'react-qr-code';
import { getGenerateTokensApi, update2FAApi } from 'services';
import { useAppSelector } from 'store/hook';
// import { useForm } from 'react-hook-form';

interface Props {
  open: boolean;
  handleClosePopup?: any;
  onSuccess?: any;
  enable2FA?: any;
}

interface IFormInputs {
  token: string;
  enable_2fa: boolean;
}

const PopupUpdate2FA = (props: Props) => {
  const { loadingPopup } = useAppSelector((state) => state.globalReducer);
  const [loading, setLoading] = useState<boolean>(false);
  const [qrCode, setQrCode] = useState<GENERATE_QRCODE_BODY_RESULT | null>(
    null
  );
  const { open, handleClosePopup, enable2FA, onSuccess } = props;

  const schema = useMemo(() => {
    return yup.object({
      token: yup
        .string()
        .length(6, 'Incorrect OTP')
        .required('OTP is required'),
    });
  }, []);

  const form = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      token: '',
      enable_2fa: false,
    },
  });

  const { handleSubmit } = form;

  useEffect(() => {
    if (!enable2FA) {
      setLoading(true);
      const generateTokens = async () => {
        await getGenerateTokensApi()
          .then(({ data }) => {
            setQrCode(data.data);
          })
          .catch((e) => NotifyService.error(e))
          .finally(() => {
            setLoading(false);
          });
      };
      generateTokens();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
    // eslint-disable-next-line
  }, [enable2FA]);

  const onSubmit = async (data: IFormInputs) => {
    setLoading(true);
    await update2FAApi({
      enable_2fa: !enable2FA,
      token: data.token,
    })
      .then((r) => {
        handleClosePopup();
        NotifyService.success('Success');
        onSuccess();
      })
      .catch((e) => NotifyService.error(e))
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiPaper-root': {
          width: 530,
          borderRadius: 0,
        },
      }}
    >
      <Grid>
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '20px 25px',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '100%',
            }}
          >
            Update 2FA
          </Typography>
          <IconButton
            onClick={handleClosePopup}
            sx={{
              padding: 0,
            }}
          >
            <CloseCircle />
          </IconButton>
        </Grid>
        <Divider />
        {(loadingPopup || loading) && <LoadingPopup color="primary" />}
        <Box p="23px 32px">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack mb="24px" spacing={2}>
              {!enable2FA && (
                <Stack spacing={2}>
                  <Stack
                    direction={'row'}
                    alignItems="center"
                    sx={{
                      width: '100%',
                      padding: '20px',
                      border: '1px solid #E5E5E5',
                      borderRadius: '5px',
                    }}
                  >
                    <Box width={'150px'}>
                      <QRCode
                        size={100}
                        style={{
                          height: 'auto',
                          maxWidth: '100%',
                          width: '150px',
                        }}
                        value={qrCode ? qrCode.otpauth_url : ''}
                        viewBox={`0 0 100 100`}
                      />
                    </Box>
                    <Stack
                      alignItems="center"
                      justifyContent="center"
                      sx={{ flex: 1 }}
                    >
                      <Typography
                        sx={{ textAlign: 'center', fontSize: '14px' }}
                      >
                        {qrCode?.base32}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack spacing="5px">
                    <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
                      Instructions for setup
                    </Typography>
                    <Typography sx={{ fontSize: '12px', fontWeight: 600 }}>
                      1. Download an authentication app
                    </Typography>
                    <Typography sx={{ fontSize: '12px' }}>
                      We recommend downloading Duo Mobile or Google
                      Authenticator if you don't have one installed.
                    </Typography>
                    <Typography sx={{ fontSize: '12px', fontWeight: 600 }}>
                      2. Scan this barcode/QR code or copy the key
                    </Typography>
                    <Typography sx={{ fontSize: '12px' }}>
                      Scan this barcode/QR code in the authentication app or
                      copy the key and paste it in the authentication app.
                    </Typography>
                    <Typography sx={{ fontSize: '12px', fontWeight: 600 }}>
                      3. Copy and enter 6-digit code
                    </Typography>
                    <Typography sx={{ fontSize: '12px' }}>
                      After the barcode/QR code is scanned or the key is
                      entered, your authentication app will generate a 6-digit
                      code. Copy the code and then come back to CMS to enter it.
                    </Typography>
                  </Stack>
                </Stack>
              )}

              <Stack>
                <Inputs title="Code" form={form} name="token" />
              </Stack>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              mt="32px"
              spacing="8px"
            >
              <Button
                variant="outlined"
                color="secondary"
                sx={{
                  width: '233.5px',
                  height: '30px',
                }}
                onClick={handleClosePopup}
              >
                Cancel
              </Button>
              <Button
                color="secondary"
                variant="contained"
                sx={{
                  width: '233.5px',
                  height: '30px',
                }}
                type="submit"
              >
                {enable2FA ? 'Off' : 'On'}
              </Button>
            </Stack>
          </form>
        </Box>
      </Grid>
    </Dialog>
  );
};

export default PopupUpdate2FA;
