import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Dialog,
  Divider,
  Grid,
  IconButton,
  InputLabel,
  Stack,
  Typography,
} from '@mui/material';
import { CloseCircle, Magnifier } from 'asset';
import Select from 'components/form-control/Select';
import { GetOptionsResponse } from 'components/form-control/Selects';
import yup from 'config/yup.custom';
import { NotifyService } from 'helpers';
import { COUNTRIES_TYPE, GET_COUNTRIES_PARAMS_REQUEST } from 'models';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { DropdownIndicatorProps, components } from 'react-select';
import { updateCountriesApi } from 'services';
// import { useForm } from 'react-hook-form';
import { useAppDispatch } from 'store/hook';
import { GET_REQUESTS_DETAIL_REQUEST } from 'store/reducers/requests/actionTypes';
import { getSettingsCountriesListApi } from 'services';

interface Props {
  open: boolean;
  handleClosePopup?: any;
  onSuccess?: any;
  subject?: any;
}

interface IFormInputs {
  ids: {
    country_code: string;
    createdAt: string;
    email: string;
    first_name: string;
    id: string;
    label: string;
    last_name: string;
    phone_number: string;
    rating: number;
    sitter_id: string;
    status: string;
  };
}

const PopupAddCountry = (props: Props) => {
  const { open, handleClosePopup, subject, onSuccess } = props;
  // const theme = useTheme();

  const schema = useMemo(() => {
    return yup.object({
      ids: yup.mixed().notRequired(),
    });
  }, []);

  const form = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      ids: {},
    },
  });

  const { handleSubmit } = form;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (subject) {
      dispatch({
        type: GET_REQUESTS_DETAIL_REQUEST,
        payload: {
          id: subject.id,
        },
      });
    }
    // eslint-disable-next-line
  }, [subject]);

  const getOptions =
    (key: keyof IFormInputs) =>
    async (page: number = 1, limit: number = 20, keyword?: string) => {
      let data: GetOptionsResponse | null = null;
      switch (key) {
        case 'ids':
          const paramsCountry: GET_COUNTRIES_PARAMS_REQUEST = {
            page: page,
            limit: limit,
            is_black_list: false,
          };
          if (keyword) paramsCountry.keyword = keyword;
          data = await getSettingsCountriesListApi(paramsCountry)
            .then(({ data }) => {
              return {
                metadata: data.data.metadata,
                options: data.data.records.map((it: COUNTRIES_TYPE) => ({
                  ...it,
                  id: it.id,
                  label: `${it.phone_code} ${it.name}`,
                })),
              };
            })
            .catch((e) => {
              NotifyService.error(e);
              return null;
            });
          break;
      }
      return data;
    };

  const onSubmit = async (data: IFormInputs) => {
    const newData = {
      is_black_list: true,
    };
    await updateCountriesApi(data.ids.id, newData)
      .then((r) => {
        onSuccess(r.data.data);
        handleClosePopup();
        NotifyService.success('Success');
      })
      .catch((e) => NotifyService.error(e))
      .finally(() => {});
  };

  const DropdownIndicator = (props: DropdownIndicatorProps) => {
    return (
      <components.DropdownIndicator {...props}>
        <Magnifier />
      </components.DropdownIndicator>
    );
  };

  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiPaper-root': {
          width: 530,
          borderRadius: 0,
        },
      }}
    >
      <Grid>
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '20px 25px',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '100%',
            }}
          >
            Create Blacklist
          </Typography>
          <IconButton
            onClick={handleClosePopup}
            sx={{
              padding: 0,
            }}
          >
            <CloseCircle />
          </IconButton>
        </Grid>
        <Divider />
        <Box p="23px 32px">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack
              mb="24px"
              spacing="9px"
              direction="row"
              justifyContent="space-between"
            >
              <Stack width="100%">
                <InputLabel
                  sx={{
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '32px',
                  }}
                >
                  Country Code
                </InputLabel>
                <Select
                  components={{ DropdownIndicator }}
                  placeholder="Search sitters to add"
                  name="ids"
                  form={form}
                  isHasMore
                  filterFunc={false}
                  getOptions={getOptions('ids')}
                />
              </Stack>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              mt="32px"
              spacing="8px"
            >
              <Button
                variant="outlined"
                color="secondary"
                sx={{
                  width: '233.5px',
                  height: '30px',
                }}
                onClick={handleClosePopup}
              >
                Cancel
              </Button>
              <Button
                color="secondary"
                variant="contained"
                sx={{
                  width: '233.5px',
                  height: '30px',
                }}
                type="submit"
              >
                Add
              </Button>
            </Stack>
          </form>
        </Box>
      </Grid>
    </Dialog>
  );
};

export default PopupAddCountry;
