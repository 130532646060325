export interface CHAT_TYPE {
  id: string;
  parent_id: string | null;
  sitter_id: string | null;
  lastMessageAt: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  Parent: {
    profile_picture: string | null;
    User: {
      first_name: string;
      last_name: string;
      date_of_birth: string | null;
      email: string;
      phone_number: string;
    };
  };
  Sitter: {
    profile_picture: string | null;
    star_sitter_badge: string | null;
    User: {
      first_name: string;
      last_name: string;
      date_of_birth: string | null;
      email: string;
      phone_number: string;
    };
  };
  Messages: MESSAGE_CHAT[];
}

export interface GET_CHATS_PARAMS_REQUEST {
  page?: number;
  limit?: number;
  offset?: number;
  parent_id?: string;
  sitter_id?: string;
  deleted?: boolean;
}

export interface MESSAGE_CHAT {
  id: string;
  send_by: string;
  channel_id: string;
  message: string;
  sub_message: string | null;
  type: string;
  readed: boolean;
  deleted: boolean;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  trackedBy?: string;
}

export enum CHAT_DETAIL_POPUP_TYPE {
  SITTER = 'SITTER',
  PARENT = 'PARENT',
}

export interface GET_CHAT_MESSAGES_PARAMS_REQUEST {
  page?: number;
  limit?: number;
  offset?: number;
  sort?: string;
  check_tracked_by?: boolean;
  deleted?: boolean;
}

export enum MESSSAGE_CHAT_TYPE {
  image = 'image',
  text = 'text',
  video = 'video',
  audio = 'audio',
}

export enum MESSAGE_SENDER_TYPE {
  PARENT = 'PARENT',
  SITTER = 'SITTER',
  SYSTEM = 'SYSTEM',
}

export interface CHAT_KEYWORD_TRACKING {
  id: string;
  keyword: string;
  createdAt: string;
  updatedAt: string;
}

export interface GET_CHAT_KEYWORD_TRACKING_LIST_PARAMS_REQUEST {
  page?: number;
  limit?: number;
  offset?: number;
  keyword?: string;
  sort?: string;
}

export interface CREATE_OR_UPDATE_CHAT_KEYWORD_TRACKING_BODY {
  keyword: string;
}
