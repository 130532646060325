import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  dialogClasses,
} from '@mui/material';
import { IconCreate } from 'asset';
import React from 'react';

interface Props {
  open: boolean;
  title?: string;
  description?: string;
  ConfirmIcon?: React.ReactNode;
  cancelProps?: ButtonProps;
  confirmProps?: ButtonProps;
  cancelText?: string;
  confirmText?: string;
  onClose: () => void;
  onConfirm: () => void;
}

const PopupConfirm = ({
  open,
  title,
  description,
  cancelText,
  confirmText,
  ConfirmIcon = <IconCreate />,
  cancelProps = {},
  confirmProps = {},
  onClose,
  onConfirm,
}: Props) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        [`& .${dialogClasses.paper}`]: {
          maxWidth: '400px',
          width: '100%',
        },
      }}
    >
      <DialogTitle
        sx={{
          py: '24px',
          display: 'flex',
          alignItems: 'center',
        }}
        component="div"
      >
        {ConfirmIcon}
      </DialogTitle>
      <DialogContent
        sx={{
          padding: '0px 24px',
        }}
      >
        {title && (
          <Typography
            variant="Large_Body_18_SemiBold"
            sx={{
              mb: '8px',
            }}
          >
            {title}
          </Typography>
        )}
        {description && (
          <Typography variant="Small_Body_16_Regular">{description}</Typography>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          padding: '24px',
        }}
      >
        <Button
          onClick={onClose}
          variant="outlined"
          sx={{
            border: '1px solid rgba(0, 0, 0, 0.08)',
            color: 'black.light',
            ':hover': {
              color: 'secondary.main',
              border: '1px solid rgba(0, 0, 0, 0.08)',
              bgcolor: 'transparent',
            },
            width: '100%',
          }}
          {...cancelProps}
        >
          {cancelText || 'No'}
        </Button>
        <Button
          autoFocus
          variant="contained"
          color="secondary"
          sx={{
            width: '100%',
          }}
          onClick={onConfirm}
          {...confirmProps}
        >
          {confirmText || 'Yes'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PopupConfirm;
