import { all } from 'redux-saga/effects';
import sitters from './sitters';
import parents from './parents';
import staffs from './staffs';
import users from './users';
import nanny from './nanny';

export const accountsSaga = function* root() {
  yield all([sitters(), parents(), staffs(), users(), nanny()]);
};
