import {
  Checkbox,
  Paper,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import {
  DeleteDialog,
  HeadTable,
  PopupCreateOrEditTopupPayout,
  TableCaption,
} from 'components';
import { memo, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { getParentPaymentHistoryAction } from '../../../../../store/reducers/accounts/actionTypes';
import usePagination from '@mui/material/usePagination/usePagination';
import {
  cashTXTStatusColor,
  GET_PAYMENT_PARAMS_REQUEST,
  PAYMENT_TYPE,
  ECLOSE_POPUP,
  CASH_TRANSACTION_CODE,
} from 'models';
import { setParentPaymentHistoryParams } from 'store/reducers/accounts';
import { getSortItem, printPrice } from 'helpers';
import { headCells } from './data';
import {
  ButtonDelete,
  ButtonDetail,
  ButtonEdit,
  TableCellNoData,
} from 'components/common/table';
import PopupDetailPayment from 'components/Popups/Transaction/PopupDetailPayment';
import { useDateTime } from 'hooks';

interface Props {
  parentId?: string;
}

const PaymentHistory = memo(({ parentId }: Props) => {
  const dispatch = useAppDispatch();
  const { formatDateTime } = useDateTime();
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [openDetailPopup, setOpenDetailPopup] = useState(false);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [itemAction, setItemAction] = useState<PAYMENT_TYPE>();

  const { paymentHistory } = useAppSelector(
    (state) => state.accountReducer.parents.parent_detail_tab
  );

  const { params } = paymentHistory;

  const onSetListParams = (params: GET_PAYMENT_PARAMS_REQUEST) => {
    dispatch(setParentPaymentHistoryParams(params));
  };

  useEffect(() => {
    if (parentId) {
      dispatch(getParentPaymentHistoryAction(parentId, params));
    }
  }, [params, parentId, dispatch]);

  const onReloadList = () => {
    if (!parentId) return;
    dispatch(getParentPaymentHistoryAction(parentId, params));
  };

  const handleChangeRowsPerPage = (event: SelectChangeEvent) => {
    const limit = parseInt(event.target.value, 10);
    onSetListParams({ ...params, page: 1, limit });
  };

  const { items } = usePagination({
    count: paymentHistory.metadata.total_pages,
    page: paymentHistory.metadata.page,
    onChange(_event, page) {
      onSetListParams({ ...params, page: page });
    },
  });

  const sortItem = useMemo(() => {
    return getSortItem(params?.sort);
  }, [params]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = paymentHistory?.records?.map((n: any) => n?.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (_: any, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleRequestSort = (_: any, property: keyof PAYMENT_TYPE) => {
    setSelected([]);
    const isAsc = sortItem?.orderBy === property && sortItem?.order === 'asc';
    onSetListParams({
      ...params,
      sort: `${property}-${isAsc ? 'desc' : 'asc'}`,
    });
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const onClosePopup = (type: ECLOSE_POPUP) => {
    switch (type) {
      case ECLOSE_POPUP.DELETE:
        setOpenDeletePopup(false);
        setItemAction(undefined);
        break;
      case ECLOSE_POPUP.CREATE_OR_EDIT:
        setOpenEditPopup(false);
        setItemAction(undefined);
        break;
      case ECLOSE_POPUP.DETAIL:
        setOpenDetailPopup(false);
        setItemAction(undefined);
        break;
    }
  };

  const setOpenDelete = (event: any, data: PAYMENT_TYPE) => {
    event.stopPropagation();
    setItemAction(data);
    setOpenDeletePopup(true);
  };

  const setOpenDetail = (event: any, data: PAYMENT_TYPE) => {
    event.stopPropagation();
    setItemAction(data);
    setOpenDetailPopup(true);
  };

  const setOpenEdit = (event: any, data: PAYMENT_TYPE) => {
    event.stopPropagation();
    setItemAction(data);
    setOpenEditPopup(true);
  };

  return (
    <Stack spacing="8px">
      <TableContainer
        component={Paper}
        elevation={0}
        sx={{
          border: '1px solid #D9D9D9',
          maxHeight: 'calc(100vh - 355px)',
        }}
      >
        <Table stickyHeader sx={{ minWidth: 650 }} aria-labelledby="tableTitle">
          <HeadTable<PAYMENT_TYPE>
            numSelected={selected.length}
            order={sortItem?.order}
            orderBy={sortItem?.orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={paymentHistory?.records.length}
            headCells={headCells}
          />
          <TableBody
            sx={{
              height: 'auto !important',
            }}
          >
            {paymentHistory?.records?.length ? (
              paymentHistory.records.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-pr-pmh-table-cb-${index}`;
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    <TableCell
                      padding="checkbox"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                      }}
                    >
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        onChange={(e) => handleClick(e, row.id)}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                      }}
                    >
                      {formatDateTime(row.timestamp)}
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                      }}
                    >
                      {row.number}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                      }}
                    >
                      {row.type}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                      }}
                    >
                      {row.doc_ref_no}
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                        color: row.total_amount > 0 ? '#0CAD35' : '#000',
                      }}
                    >
                      {printPrice(row.total_amount)}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                        color:
                          cashTXTStatusColor[row.status || ''] || '#B6B6B6',
                      }}
                    >
                      {row.status}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                      }}
                    >
                      {row.walletTransaction?.number}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                      }}
                    >
                      <Stack direction="row" spacing="10px">
                        <ButtonDetail onClick={(e) => setOpenDetail(e, row)} />
                        <ButtonEdit onClick={(e) => setOpenEdit(e, row)} />
                        <ButtonDelete onClick={(e) => setOpenDelete(e, row)} />
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCellNoData colSpan={headCells.length + 2} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TableCaption
        rowsPerPage={params?.limit ?? 0}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        metadata={paymentHistory?.metadata}
        items={items}
      />
      {openDeletePopup && (
        <DeleteDialog
          name=""
          open={openDeletePopup}
          onClose={() => onClosePopup(ECLOSE_POPUP.DELETE)}
          subject={itemAction?.id}
          onSuccess={onReloadList}
          type="payment"
        />
      )}
      {openDetailPopup && (
        <PopupDetailPayment
          open
          subject={itemAction?.id}
          handleClosePopup={() => onClosePopup(ECLOSE_POPUP.DETAIL)}
        />
      )}
      <PopupCreateOrEditTopupPayout
        open={openEditPopup}
        title="Top-up"
        typeCode={CASH_TRANSACTION_CODE.TOP_UP}
        subject={itemAction?.id}
        onSuccess={onReloadList}
        handleClosePopup={() => onClosePopup(ECLOSE_POPUP.CREATE_OR_EDIT)}
      />
    </Stack>
  );
});

export default PaymentHistory;
