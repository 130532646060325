import { takeLatest, call, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  DELETE_CHAT_KEYWORD_TRACKING_REQUEST,
  GET_CHAT_KEYWORD_TRACKING_LIST_REQUEST,
} from 'store/reducers/settings/actionType';
import {
  CHAT_KEYWORD_TRACKING,
  GET_CHAT_KEYWORD_TRACKING_LIST_PARAMS_REQUEST,
  LIST_BODY_RESULT,
} from 'models';
import {
  deleteChatKeywordTrackingListApi,
  getChatKeywordTrackingListApi,
} from 'services';
import { setChatKeywordTrackingListSuccess } from 'store/reducers/settings';
import { setGlobalLoading } from 'store/reducers/global';

function* getChatKeywordTrackingListRequest(
  action: PayloadAction<{
    params?: GET_CHAT_KEYWORD_TRACKING_LIST_PARAMS_REQUEST;
  }>
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<{
      data: LIST_BODY_RESULT<CHAT_KEYWORD_TRACKING>;
    }> = yield call(getChatKeywordTrackingListApi, action.payload.params);

    yield put(
      setChatKeywordTrackingListSuccess({
        records: response.data.data.records,
        metadata: response.data.data.metadata,
      })
    );
    yield put(setGlobalLoading(false));
  } catch (error: unknown) {}
}

function* deleteChatKeywordTrackingRequest(
  action: PayloadAction<{
    id: string;
    onSuccess?: () => void;
  }>
) {
  try {
    yield call(deleteChatKeywordTrackingListApi, action.payload.id);
    if (action.payload.onSuccess) action.payload.onSuccess();
  } catch (error: unknown) {}
}

function* chatKeywordTrackings() {
  yield takeLatest(
    GET_CHAT_KEYWORD_TRACKING_LIST_REQUEST,
    getChatKeywordTrackingListRequest
  );
  yield takeLatest(
    DELETE_CHAT_KEYWORD_TRACKING_REQUEST,
    deleteChatKeywordTrackingRequest
  );
}

export default chatKeywordTrackings;
