import { BOOKING_TYPE, HeadCell } from 'models';

export const headCells: readonly HeadCell<BOOKING_TYPE>[] = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    isSort: false,
    label: 'Booking ID',
  },
  {
    id: 'nannyBooking.edd' as keyof BOOKING_TYPE,
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Expected Due Date',
  },
  {
    id: 'nannyBooking.duration' as keyof BOOKING_TYPE,
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Duration (days)',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Status',
  },
  {
    id: 'nannyBooking.pets' as keyof BOOKING_TYPE,
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Total Amount',
  },
];
