import {
  Checkbox,
  Paper,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { DeleteDialog, HeadTable, TableCaption } from 'components';
import { memo, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { getParentTransactionsAction } from '../../../../../store/reducers/accounts/actionTypes';
import usePagination from '@mui/material/usePagination/usePagination';
import {
  ECLOSE_POPUP,
  GET_TRANSACTIONS_PARAMS_REQUEST,
  TRANSACTION_TYPE,
} from 'models';
import { setParentTransactionsParams } from 'store/reducers/accounts';
import { getSortItem } from 'helpers';
import { headCells } from './data';
import {
  ButtonDelete,
  ButtonDetail,
  ButtonEdit,
} from 'components/common/table';
import {
  PopupCreateOrEditTransaction,
  PopupDetailTransaction,
} from 'components/Popups/Transaction';
import { useDateTime } from 'hooks';

interface Props {
  parentId?: string;
}

const Transactions = memo(({ parentId }: Props) => {
  const dispatch = useAppDispatch();
  const { formatDateTime } = useDateTime();
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [openDetailPopup, setOpenDetailPopup] = useState(false);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [itemTransaction, setItemTransaction] = useState<TRANSACTION_TYPE>();

  const { transactions } = useAppSelector(
    (state) => state.accountReducer.parents.parent_detail_tab
  );

  const { params } = transactions;

  const onSetParentTransactionsParams = (
    params: GET_TRANSACTIONS_PARAMS_REQUEST
  ) => {
    dispatch(setParentTransactionsParams(params));
  };

  useEffect(() => {
    if (parentId) {
      dispatch(getParentTransactionsAction(parentId, params));
    }
  }, [params, parentId, dispatch]);

  const onReloadList = () => {
    if (!parentId) return;
    dispatch(getParentTransactionsAction(parentId, params));
  };

  const handleChangeRowsPerPage = (event: SelectChangeEvent) => {
    const limit = parseInt(event.target.value, 10);
    onSetParentTransactionsParams({ ...params, page: 1, limit });
  };

  const { items } = usePagination({
    count: transactions.metadata.total_pages,
    page: transactions.metadata.page,
    onChange(_event, page) {
      onSetParentTransactionsParams({ ...params, page: page });
    },
  });

  const sortItem = useMemo(() => {
    return getSortItem(params?.sort);
  }, [params]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = transactions?.records?.map((n: any) => n?.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (_: any, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleRequestSort = (_: any, property: keyof TRANSACTION_TYPE) => {
    setSelected([]);
    const isAsc = sortItem?.orderBy === property && sortItem?.order === 'asc';
    onSetParentTransactionsParams({
      ...params,
      sort: `${property}-${isAsc ? 'desc' : 'asc'}`,
    });
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const onClosePopup = (type: ECLOSE_POPUP) => {
    switch (type) {
      case ECLOSE_POPUP.DELETE:
        setOpenDeletePopup(false);
        setItemTransaction(undefined);
        break;
      case ECLOSE_POPUP.CREATE_OR_EDIT:
        setOpenEditPopup(false);
        setItemTransaction(undefined);
        break;
      case ECLOSE_POPUP.DETAIL:
        setOpenDetailPopup(false);
        setItemTransaction(undefined);
        break;
    }
  };

  const setOpenDelete = (event: any, data: TRANSACTION_TYPE) => {
    event.stopPropagation();
    setItemTransaction(data);
    setOpenDeletePopup(true);
  };

  const setOpenDetail = (event: any, data: TRANSACTION_TYPE) => {
    event.stopPropagation();
    setItemTransaction(data);
    setOpenDetailPopup(true);
  };

  const setOpenEdit = (event: any, data: TRANSACTION_TYPE) => {
    event.stopPropagation();
    setItemTransaction(data);
    setOpenEditPopup(true);
  };

  return (
    <Stack spacing="8px">
      <TableContainer
        component={Paper}
        elevation={0}
        sx={{
          border: '1px solid #D9D9D9',
          maxHeight: 'calc(100vh - 355px)',
        }}
      >
        <Table stickyHeader sx={{ minWidth: 650 }} aria-labelledby="tableTitle">
          <HeadTable<TRANSACTION_TYPE>
            numSelected={selected.length}
            order={sortItem?.order}
            orderBy={sortItem?.orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={transactions?.records.length}
            headCells={headCells}
          />
          <TableBody
            sx={{
              height: 'auto !important',
            }}
          >
            {transactions.records.map((row, index) => {
              const isItemSelected = isSelected(row.id);
              const labelId = `enhanced-transaction-table-cb-${index}`;
              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.id}
                  selected={isItemSelected}
                >
                  <TableCell
                    padding="checkbox"
                    sx={{
                      borderRight: '1px solid #DEE2E6',
                    }}
                  >
                    <Checkbox
                      color="primary"
                      checked={isItemSelected}
                      onChange={(e) => handleClick(e, row.id)}
                      inputProps={{
                        'aria-labelledby': labelId,
                      }}
                    />
                  </TableCell>
                  <TableCell
                    component="th"
                    id={labelId}
                    scope="row"
                    sx={{
                      borderRight: '1px solid #DEE2E6',
                      padding: '18px 12px 17px',
                      fontSize: '14px',
                      fontWeight: 400,
                    }}
                  >
                    {row.number}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      borderRight: '1px solid #DEE2E6',
                      padding: '18px 12px 17px',
                      fontSize: '14px',
                      fontWeight: 400,
                    }}
                  >
                    {row.type?.title}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      borderRight: '1px solid #DEE2E6',
                      padding: '18px 12px 17px',
                      fontSize: '14px',
                      fontWeight: 400,
                    }}
                  >
                    {row.doc_ref_no}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      borderRight: '1px solid #DEE2E6',
                      padding: '18px 12px 17px',
                      fontSize: '14px',
                      fontWeight: 400,
                    }}
                  >
                    ${row.total_amount.toFixed(2)}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      borderRight: '1px solid #DEE2E6',
                      padding: '18px 12px 17px',
                      fontSize: '14px',
                      fontWeight: 400,
                    }}
                  >
                    {formatDateTime(row.timestamp)}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      borderRight: '1px solid #DEE2E6',
                      padding: '18px 12px 17px',
                    }}
                  >
                    <Stack direction="row" spacing="10px">
                      <ButtonDetail onClick={(e) => setOpenDetail(e, row)} />
                      <ButtonEdit onClick={(e) => setOpenEdit(e, row)} />
                      <ButtonDelete onClick={(e) => setOpenDelete(e, row)} />
                    </Stack>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TableCaption
        rowsPerPage={params?.limit ?? 0}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        metadata={transactions?.metadata}
        items={items}
      />
      {openDeletePopup && (
        <DeleteDialog
          name=""
          open={openDeletePopup}
          onClose={() => onClosePopup(ECLOSE_POPUP.DELETE)}
          subject={itemTransaction?.id}
          onSuccess={onReloadList}
          type="transaction"
        />
      )}
      {openDetailPopup && (
        <PopupDetailTransaction
          open
          subject={itemTransaction?.id}
          handleClosePopup={() => onClosePopup(ECLOSE_POPUP.DETAIL)}
        />
      )}
      {openEditPopup && (
        <PopupCreateOrEditTransaction
          open
          subject={itemTransaction?.id}
          onSuccess={onReloadList}
          handleClosePopup={() => onClosePopup(ECLOSE_POPUP.CREATE_OR_EDIT)}
        />
      )}
    </Stack>
  );
});

export default Transactions;
