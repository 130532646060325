import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { CloseCircle } from 'asset';
import { Inputs } from 'components';
import yup from 'config/yup.custom';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { createChatKeywordTrackingListApi, getChatKeywordTrackingDetailApi, updateChatKeywordTrackingListApi } from 'services';
import { NotifyService } from 'helpers';

interface Props {
  open: boolean;
  subject?: string;
  onSuccess: () => void;
  handleClosePopup: () => void;
}

interface IFormInputs {
  keyword: string;
}

const defaultValues: Partial<IFormInputs> = {
  keyword: ''
}

const PopupCreateOrEditChatKeywordTracking = (props: Props) => {
  const { open, handleClosePopup, onSuccess, subject } = props;

  const theme = useTheme();

  const schema = useMemo(() => {
    return yup.object({
      keyword: yup.string().required('Keyword is required'),
    });
  }, []);

  const form = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: defaultValues
  });

  const { reset } = form

  const _handleClosePopup = () => {
    handleClosePopup()
    reset({ ...defaultValues })
  }

  useEffect(() => {
    if (!open) return;
    if (subject) {
      const fetchDetail = async () => {
        await getChatKeywordTrackingDetailApi(subject)
          .then(({ data }) => {
            reset({
              keyword: data.data.keyword
            })
          })
          .catch((e) => NotifyService.error(e))
      }
      fetchDetail()
    }
  }, [open, subject, reset]);

  const onSubmit = async (value: IFormInputs) => {
    if (!subject)
      await createChatKeywordTrackingListApi(value)
        .then((r) => {
          onSuccess();
          _handleClosePopup();
          NotifyService.success(r)
        })
        .catch((e) => NotifyService.error(e))
        .finally(() => { });
    else
      await updateChatKeywordTrackingListApi(subject, value)
        .then((r) => {
          onSuccess();
          _handleClosePopup();
          NotifyService.success(r)
        })
        .catch((e) => NotifyService.error(e))
        .finally(() => { });
  };

  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiPaper-root': {
          width: 527,
          borderRadius: 0,
        },
      }}
    >
      <Grid>
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '20px 25px',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '100%',
            }}
          >
            {subject ? 'Edit' : 'Create'} Keyword
          </Typography>
          <IconButton
            onClick={_handleClosePopup}
            sx={{
              padding: 0,
            }}
          >
            <CloseCircle />
          </IconButton>
        </Grid>
        <Divider />
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Stack spacing="20px" p="25px 24px">
            <Stack>
              <Inputs
                title="Keyword"
                form={form}
                name="keyword"
              />
            </Stack>

            <Stack direction="row" spacing="8px">
              <Button
                variant="outlined"
                color="secondary"
                onClick={_handleClosePopup}
                sx={{
                  height: 30,
                  width: '100%',
                  fontWeight: 600,
                  fontSize: '14px',
                  lineHeight: 1.1,
                  border: `1px solid ${theme.palette.secondary.main}`,
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                sx={{
                  height: 30,
                  width: '100%',
                  fontWeight: 600,
                  fontSize: '14px',
                  lineHeight: 1.1,
                }}
              >
                {subject ? 'Update' : 'Create'}
              </Button>
            </Stack>
          </Stack>
        </form>
      </Grid>
    </Dialog>
  );
};

export default PopupCreateOrEditChatKeywordTracking;
