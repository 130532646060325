import { API } from 'config';
import {
  GET_REVIEWS_PARAMS_REQUEST,
  GET_REVIEW_LIST_PARAMS_REQUEST,
  UPDATE_REVIEW_BODY,
} from 'models';
import { AppAPIInstance } from 'services';

export const getReviewsApi = async (params?: GET_REVIEWS_PARAMS_REQUEST) => {
  return await AppAPIInstance.get(API.REVIEWS.DEFAULT, {
    params,
  });
};

export const deleteReviewApi = async (id: string) => {
  return await AppAPIInstance.delete(`${API.REVIEWS.DEFAULT}/${id}`);
};

export const updateReviewApi = async (id: string, data: UPDATE_REVIEW_BODY) => {
  return await AppAPIInstance.put(`${API.REVIEWS.DEFAULT}/${id}`, data);
};

export const createReviewApi = async (data: any) => {
  return await AppAPIInstance.post(`${API.REVIEWS.CREATE}`, data);
};

export const getReviewExportCSVApi = async (
  params?: Partial<GET_REVIEWS_PARAMS_REQUEST>
) => {
  return await AppAPIInstance.get(API.REVIEWS.EXPORT, {
    params,
    responseType: 'blob',
  }).catch(async (e) => {
    return Promise.reject(
      JSON.parse((await e?.response?.data?.text()) || '{}')?.error
    );
  });
};

export const getReviewsByAdminApi = async (
  params?: GET_REVIEW_LIST_PARAMS_REQUEST
) => {
  const _params = {
    ...params,
    parent_id: params?.parent_id?.id,
    sitter_id: params?.sitter_id?.id,
    rate: params?.rate?.id,
  };
  return await AppAPIInstance.get(API.REVIEWS.ADMIN, {
    params: _params,
  });
};

export const updatePublishReviewApi = async (
  id: string,
  data: {
    is_publish_google_review: boolean;
  }
) => {
  return await AppAPIInstance.put(`${API.REVIEWS.GOOGLE_REVIEW}/${id}`, data);
};
