import { Box, LinearProgress } from '@mui/material';
import React from 'react';

const LoadingPopup = ({ ...props }) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          right: 0,

          bgcolor: 'rgb(255, 255, 255 , 0.4)',
          height: 'calc(100% - 66px)',
          width: '100%',

          zIndex: 10,
        }}
      ></Box>
      <LinearProgress color={props.color} />
    </Box>
  );
};

export default LoadingPopup;
