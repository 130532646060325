import React from 'react';

const Filter = () => {
  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.473 4.00923L16.0687 4.46483C16.0777 4.45303 16.0864 4.44097 16.0947 4.42865L15.473 4.00923ZM11.1817 9.62058L10.5859 9.16498C10.5769 9.17678 10.5682 9.18884 10.5599 9.20116L11.1817 9.62058ZM10.394 14.6481L10.9212 15.1815V15.1815L10.394 14.6481ZM8.76083 16.262L8.23364 15.7286V15.7286L8.76083 16.262ZM6.8183 9.62058L7.44006 9.20116C7.43175 9.18884 7.42308 9.17678 7.41406 9.16498L6.8183 9.62058ZM2.52703 4.00923L1.90527 4.42865C1.91358 4.44097 1.92225 4.45303 1.93127 4.46483L2.52703 4.00923ZM3.88588 2.25H14.1141V0.75H3.88588V2.25ZM14.8772 3.55362L10.5859 9.16498L11.7775 10.0762L16.0687 4.46483L14.8772 3.55362ZM9.88313 11.4111V14.0774H11.3831V11.4111H9.88313ZM9.86678 14.1146L8.23364 15.7286L9.28802 16.7955L10.9212 15.1815L9.86678 14.1146ZM8.11687 15.6914V11.4111H6.61687V15.6914H8.11687ZM7.41406 9.16498L3.12279 3.55362L1.93127 4.46483L6.22255 10.0762L7.41406 9.16498ZM8.11687 11.4111C8.11687 10.6238 7.88101 9.85482 7.44006 9.20116L6.19655 10.04C6.47098 10.4468 6.61687 10.9239 6.61687 11.4111H8.11687ZM8.23364 15.7286C8.21627 15.7457 8.20546 15.7482 8.19945 15.7492C8.18941 15.7509 8.17328 15.7503 8.15508 15.7429C8.13686 15.7354 8.12685 15.7253 8.12299 15.7199C8.12132 15.7176 8.12027 15.7155 8.11944 15.7129C8.11868 15.7106 8.11687 15.7041 8.11687 15.6914H6.61687C6.61687 17.0948 8.31301 17.759 9.28802 16.7955L8.23364 15.7286ZM9.88313 14.0774C9.88313 14.0897 9.8783 14.1032 9.86678 14.1146L10.9212 15.1815C11.2159 14.8902 11.3831 14.4933 11.3831 14.0774H9.88313ZM10.5599 9.20116C10.119 9.85482 9.88313 10.6238 9.88313 11.4111H11.3831C11.3831 10.9239 11.529 10.4468 11.8035 10.04L10.5599 9.20116ZM14.1141 2.25C14.834 2.25 15.2296 3.02886 14.8512 3.5898L16.0947 4.42865C17.1634 2.84446 16.003 0.75 14.1141 0.75V2.25ZM3.88588 0.75C1.99697 0.75 0.836598 2.84446 1.90527 4.42865L3.14879 3.5898C2.77039 3.02886 3.16603 2.25 3.88588 2.25V0.75Z"
        fill="#226FE3"
      />
    </svg>
  );
};

export default Filter;
