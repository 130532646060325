import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  GET_TRANSACTIONS_PARAMS_REQUEST,
  TRANSACTION_TYPE_TYPE,
  TRANSACTIONS_BODY_RESULT,
} from 'models';

export interface transactionsType {
  transactions: {
    transactions_list: TRANSACTIONS_BODY_RESULT;
    transactions_detail: any | null;
  };
  transactionsType: {
    transactions_type_list: TRANSACTION_TYPE_TYPE[];
  };
}

const initialState: transactionsType = {
  transactions: {
    transactions_list: {
      records: [],
      metadata: {
        page: 1,
        limit: 50,
        page_count: 1,
        total_pages: 1,
        total_count: 1,
      },
      params: {
        sort: 'createdAt-desc',
        page: 1,
        limit: 50,
      },
    },
    transactions_detail: null,
  },
  transactionsType: {
    transactions_type_list: [],
  },
};

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    setTransactionsListSuccess(
      state: transactionsType,
      action: PayloadAction<TRANSACTIONS_BODY_RESULT>
    ) {
      state.transactions.transactions_list = {
        ...state.transactions.transactions_list,
        ...action.payload,
      };
    },
    setTransactionsDetailSuccess(
      state: transactionsType,
      action: PayloadAction<any | null>
    ) {
      state.transactions.transactions_detail = action.payload;
    },
    setTransactionsListParams(
      state: transactionsType,
      action: PayloadAction<GET_TRANSACTIONS_PARAMS_REQUEST | null>
    ) {
      state.transactions.transactions_list.params = action.payload;
    },
    setTransactionsTypeListSuccess(
      state: transactionsType,
      action: PayloadAction<TRANSACTION_TYPE_TYPE[]>
    ) {
      state.transactionsType.transactions_type_list = action.payload;
    },
  },
});

// Actions
export const {
  setTransactionsListSuccess,
  setTransactionsDetailSuccess,
  setTransactionsListParams,
  setTransactionsTypeListSuccess,
} = transactionsSlice.actions;

export default transactionsSlice.reducer;
