import {
  Box,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { CloseCircle } from 'asset';
import { LoadingPopup } from 'components/common';
import { NotifyService, printPrice } from 'helpers';
import { useDateTime } from 'hooks';
import { TRANSACTION_DETAIL_TYPE } from 'models';
import { memo, useEffect, useState } from 'react';
import { getTransactionApi } from 'services';

interface Props {
  open: boolean;
  subject?: any;
  handleClosePopup: () => void;
}

const PopupDetailTransaction = memo(
  ({ open, subject, handleClosePopup }: Props) => {
    const { formatDateTime } = useDateTime();
    const [data, setData] = useState<TRANSACTION_DETAIL_TYPE>();
    const [loadingPopup, setLoadingPopup] = useState(false);

    const theme = useTheme();

    useEffect(() => {
      if (subject) {
        setLoadingPopup(true);
        const getDetail = async () => {
          await getTransactionApi(subject)
            .then(({ data }) => {
              setData(data.data);
              setLoadingPopup(false);
            })
            .catch((e) => NotifyService.error(e));
        };
        getDetail();
      }
    }, [subject]);

    return (
      <Dialog
        open={open}
        sx={{
          '& .MuiPaper-root': {
            width: 523,
            height: 'auto',
            borderRadius: 0,
          },
        }}
      >
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '20px 25px',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '100%',
            }}
          >
            Transaction Detail
          </Typography>
          <IconButton onClick={handleClosePopup} sx={{ p: 0 }}>
            <CloseCircle />
          </IconButton>
        </Grid>
        <Divider />
        {loadingPopup && <LoadingPopup color="primary" />}
        <Box sx={{ height: '100%' }}>
          <Stack
            p="30px 24px 50px 26px"
            height="100%"
            justifyContent="space-between"
          >
            <Box mb="30px">
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '100%',
                  color: '#8E8E93',
                  mb: '5px',
                }}
              >
                TXN No.
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '18px',
                  lineHeight: '100%',
                  color: '#000',
                }}
              >
                {data?.record?.number}
              </Typography>
            </Box>
            <Box display="flex">
              <Box mr="30px">
                <Box mb="30px">
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: '14px',
                      lineHeight: '100%',
                      color: '#8E8E93',
                      mb: '5px',
                    }}
                  >
                    TXN Type
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: '14px',
                      lineHeight: '100%',
                      color: '#000',
                    }}
                  >
                    {data?.record?.type?.title}
                  </Typography>
                </Box>
                <Box mb="30px">
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: '14px',
                      lineHeight: '100%',
                      color: '#8E8E93',
                      mb: '5px',
                    }}
                  >
                    Doc Ref No.
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: '14px',
                      lineHeight: '100%',
                      color: '#000',
                    }}
                  >
                    {data?.record?.doc_ref_no}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: '14px',
                      lineHeight: '100%',
                      color: '#8E8E93',
                      mb: '5px',
                    }}
                  >
                    TXN Date
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: '14px',
                      lineHeight: '100%',
                      color: '#000',
                    }}
                  >
                    {formatDateTime(data?.record?.timestamp)}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Box mb="30px">
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: '14px',
                      lineHeight: '100%',
                      color: '#8E8E93',
                      mb: '5px',
                    }}
                  >
                    User
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: '14px',
                      lineHeight: '100%',
                      color: '#226FE3',
                    }}
                  >
                    {data?.record?.user?.first_name}&nbsp;
                    {data?.record?.user?.last_name}
                  </Typography>
                </Box>
                <Box mb="30px">
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: '14px',
                      lineHeight: '100%',
                      color: '#8E8E93',
                      mb: '5px',
                    }}
                  >
                    Total Amount
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: '14px',
                      lineHeight: '100%',
                      color:
                        data?.record?.total_amount &&
                        data?.record?.total_amount > 0
                          ? theme.palette.green.light
                          : '#000',
                    }}
                  >
                    {data?.record?.total_amount &&
                      printPrice(data?.record?.total_amount)}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: '14px',
                      lineHeight: '100%',
                      color: '#8E8E93',
                      mb: '5px',
                    }}
                  >
                    Last Updated
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: '14px',
                      lineHeight: '100%',
                      color: '#226FE3',
                    }}
                  >
                    {formatDateTime(data?.record?.updatedAt)}
                  </Typography>
                </Box>
              </Box>
            </Box>
            {!!data?.record?.remark && (
              <Box mt="30px">
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '100%',
                    color: '#8E8E93',
                    mb: '5px',
                  }}
                >
                  Remark
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '14px',
                    color: '#000',
                    height: '20px',
                  }}
                >
                  {data?.record?.remark}
                </Typography>
              </Box>
            )}
            <Box mt="30px">
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '100%',
                  color: '#8E8E93',
                  mb: '5px',
                }}
              >
                Include In Payout
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '100%',
                  color: '#226FE3',
                }}
              >
                {data?.record?.include_in_payout ? 'Yes' : 'No'}
              </Typography>
            </Box>
          </Stack>
        </Box>
      </Dialog>
    );
  }
);

export default PopupDetailTransaction;
