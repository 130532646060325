import { Clear } from '@mui/icons-material';
import {
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Calendar } from 'asset';
import { FC, Fragment, useState } from 'react';
import { useController } from 'react-hook-form';

interface Props {
  title?: string;
  type?: string;
  name: string;
  form?: any;
  rules?: any;
  autoComplete?: string;
  placeholder?: string;
  showEyes?: boolean;
  showCount?: boolean;
  numberCount?: number;
  rows?: number;
  sx?: any;
  errorsName?: any;
  readOnly?: boolean;
  disabled?: boolean;
  value?: any;
  isClearable?: boolean;
  onChange?: (value: any) => void;
}

const DateTimePicker: FC<Props> = ({
  type,
  title,
  readOnly,
  value,
  form,
  name,
  rules,
  placeholder,
  showEyes,
  showCount,
  numberCount,
  rows,
  sx,
  errorsName,
  isClearable,
  disabled,
  onChange,
  ...rest
}) => {
  const { control } = form || { control: null };

  const {
    field: {
      onChange: onChangeCrl,
      onBlur: onBlurCrl,
      value: valueCrl,
      ref: refCrl,
    },
    formState: { errors },
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = control
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useController({ name, control, rules })
    : {
        field: { onChange, value, onBlur: undefined, ref: undefined },
        formState: { errors: {} },
      };

  const [openDate, setOpenDate] = useState<boolean>(false);

  return (
    <Fragment>
      {!!title && (
        <InputLabel
          sx={{
            fontWeight: 500,
            fontSize: '15px',
            lineHeight: '100%',
            color: '#000',
            marginBottom: '5px',
          }}
        >
          {title}
        </InputLabel>
      )}
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DesktopDatePicker
          inputRef={refCrl}
          value={valueCrl ?? null}
          inputFormat="DD MMM yyyy"
          disableMaskedInput
          readOnly={readOnly}
          disabled={disabled}
          open={openDate}
          onOpen={() => setOpenDate(true)}
          onClose={() => setOpenDate(false)}
          onChange={(value) => onChangeCrl?.(value)}
          // disablePast={true}
          renderInput={(params) => (
            <TextField
              onBlur={onBlurCrl}
              {...params}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start" sx={{ mr: 0 }}>
                    <IconButton
                      onClick={() => setOpenDate(true)}
                      sx={{
                        '&:hover': {
                          bgcolor: 'transparent',
                        },
                        '& .MuiTouchRipple-root': {
                          display: 'none',
                        },
                        position: 'absolute',
                        top: -5,
                        left: -16,
                        borderRadius: '0px',
                        cursor: 'pointer',
                        width: '230px',
                      }}
                    >
                      <Calendar color="#fff" />
                    </IconButton>
                    {!!valueCrl && !readOnly && !disabled && isClearable && (
                      <IconButton
                        sx={{ p: 0.5, m: -0.5 }}
                        onClick={() => onChangeCrl?.(null)}
                      >
                        <Clear />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
              sx={{
                background: '#fff',
                borderRadius: '5px',
                height: '38px',
                p: '16px',
                display: 'flex',
                justifyContent: 'center',
                border: '1px solid #D5D5D5',
                '& >.MuiInput-root>.MuiInput-input': {
                  fontWeight: 400,
                  fontSize: '13px',
                  lineHeight: '100%',
                },
                '& >.MuiInput-root:before': { display: 'none' },
                '& >.MuiInput-root:after': { display: 'none' },
              }}
              variant="standard"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
      </LocalizationProvider>

      {!!(errors as any)[name] ? (
        <FormHelperText
          error
          sx={{
            fontSize: '16px',
            letterSpacing: '0.045em',
            color: 'white.light',
          }}
        >
          {(errors as any)?.[name]?.message || ''}
        </FormHelperText>
      ) : (
        <FormHelperText
          error
          sx={{
            fontSize: '16px',
            letterSpacing: '0.045em',
            color: 'white.light',
          }}
        >
          {errorsName || ''}
        </FormHelperText>
      )}
    </Fragment>
  );
};

export default DateTimePicker;
