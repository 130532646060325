import {
  Button,
  Dialog,
  DialogActions,
  dialogClasses,
  DialogContent,
  // DialogContentText,
  DialogTitle,
  Typography,
  useTheme,
} from '@mui/material';
import { IconCreate } from 'asset';
import React from 'react';

interface Props {
  open: boolean;
  onClose: () => void;
  subject: string | undefined;
  loading?: boolean;
}

const PopupWarningCreate = ({
  open = true,
  onClose,
  subject,
  loading = false,
}: Props) => {
  const onClickCreate = () => {
    onClose();
  };

  const theme = useTheme();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        [`& .${dialogClasses.paper}`]: {
          maxWidth: '400px',
          width: '100%',
        },
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          py: '24px',
          display: 'flex',
          alignItems: 'center',
        }}
        component="div"
      >
        <IconCreate />
      </DialogTitle>
      <DialogContent
        sx={{
          padding: '0px 24px',
        }}
      >
        <Typography
          variant="Large_Body_18_SemiBold"
          sx={{
            mb: '8px',
          }}
        >
          {subject ? `Create ${subject}?` : 'Accept This Sitter?'}
        </Typography>
        <Typography variant="Small_Body_16_Regular">
          {subject
            ? `Confirm create this ${subject}?`
            : 'RFP will be sent to the parent'}
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          padding: '24px',
        }}
      >
        <Button
          onClick={onClose}
          variant="outlined"
          sx={{
            border: '1px solid rgba(0, 0, 0, 0.08)',
            color: 'black.light',
            ':hover': {
              color: 'secondary.main',
              border: '1px solid rgba(0, 0, 0, 0.08)',
              bgcolor: 'transparent',
            },
            width: '100%',
          }}
        >
          Back
        </Button>
        <button
          disabled={loading}
          style={{
            width: '100%',
            height: '44px',
            backgroundColor: theme.palette.secondary.main,
            borderRadius: '4px',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '16px',
            letterSpacing: '0.25px',
            color: '#FFFFFF',
            border: 'none',
          }}
          type="submit"
          form="form1"
          onClick={onClickCreate}
        >
          {subject ? 'Create' : 'Confirm'}
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default PopupWarningCreate;
