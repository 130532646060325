import { AxiosResponse } from 'axios';
import { API } from 'config';
import {
  FLAG_TYPE,
  GET_USER_FLAG_LIST_PARAMS_REQUEST,
  LIST_BODY_RESULT_ROWS,
  POST_CREATE_FLAG,
} from 'models';
import { AppAPIInstance } from 'services';

export const getUserFlagWithApi = async (
  params?: GET_USER_FLAG_LIST_PARAMS_REQUEST
): Promise<AxiosResponse<{ data: LIST_BODY_RESULT_ROWS<FLAG_TYPE> }>> => {
  return await AppAPIInstance.get(API.FLAG.DEFAULT, {
    params,
  });
};

export const getSitterFlagListApi = async (
  id: string
): Promise<AxiosResponse<{ data: any }>> => {
  return await AppAPIInstance.get(`${API.FLAG.DEFAULT}/${id}`);
};

export const createFlagApi = async (data: POST_CREATE_FLAG) => {
  return await AppAPIInstance.post(API.FLAG.DEFAULT, data);
};

export const pushNotification = async () => {
  return await AppAPIInstance.post(API.FLAG.NOTIFY);
};
