import {
  Button,
  Dialog,
  DialogActions,
  dialogClasses,
  DialogContent,
  // DialogContentText,
  DialogTitle,
  Typography,
  useTheme,
} from '@mui/material';
import { IconCreate } from 'asset';
import React from 'react';
import { useAppDispatch } from 'store/hook';
import { REQUEST_FOR_PAYMENT } from 'store/reducers/requests/actionTypes';

interface Props {
  open: boolean;
  onClose: () => void;
  subject: any;
  onSuccess: any;
}

const PopupWarningAcceptOrRequestPayment = ({
  open = true,
  onClose,
  subject,
  onSuccess,
}: Props) => {
  const dispatch = useAppDispatch();

  const onSuccessAccept = () => {
    onClose();
    onSuccess();
  };

  const onClickConfirm = () => {
    dispatch({
      type: REQUEST_FOR_PAYMENT,
      payload: {
        id: subject.id,
        data: {
          sitter_id: subject.Sitter.sitter_id,
        },
        onSuccessAccept,
      },
    });
  };

  const theme = useTheme();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        [`& .${dialogClasses.paper}`]: {
          maxWidth: '400px',
          width: '100%',
        },
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          py: '24px',
          display: 'flex',
          alignItems: 'center',
        }}
        component="div"
      >
        <IconCreate />
      </DialogTitle>
      <DialogContent
        sx={{
          padding: '0px 24px',
        }}
      >
        <Typography
          variant="Large_Body_18_SemiBold"
          sx={{
            mb: '8px',
          }}
        >
          Request for Payment?
        </Typography>
        <Typography variant="Small_Body_16_Regular">
          {`Sitter fee is $${subject.salary_per_hour} per hour`}
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          padding: '24px',
        }}
      >
        <Button
          onClick={onClose}
          variant="outlined"
          sx={{
            border: '1px solid rgba(0, 0, 0, 0.08)',
            color: 'black.light',
            ':hover': {
              color: 'secondary.main',
              border: '1px solid rgba(0, 0, 0, 0.08)',
              bgcolor: 'transparent',
            },
            width: '100%',
          }}
        >
          Back
        </Button>
        <button
          style={{
            width: '100%',
            height: '44px',
            backgroundColor: theme.palette.secondary.main,
            borderRadius: '4px',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '16px',
            letterSpacing: '0.25px',
            color: '#FFFFFF',
            border: 'none',
          }}
          type="submit"
          form="form1"
          onClick={onClickConfirm}
        >
          Confirm
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default PopupWarningAcceptOrRequestPayment;
