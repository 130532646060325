import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  BOOKING_ACTIVITY_LOG,
  GET_NANNY_BOOKING_LIST_PARAMS_REQUEST,
  NANNY_BOOKING_DETAIL_TAB_STATE,
  NANNY_BOOKING_DETAIL_TYPE,
  NANNY_BOOKING_LIST_STATE,
  NANNY_IN_BOOKING_TYPE,
} from 'models';

export interface NANNY_BOOKING_STATE {
  booking_list: NANNY_BOOKING_LIST_STATE;
  booking_detail: NANNY_BOOKING_DETAIL_TYPE | null;
  booking_detail_tab: NANNY_BOOKING_DETAIL_TAB_STATE;
}

export const initialNannyBookingState: NANNY_BOOKING_STATE = {
  booking_list: {
    data: [],
    pageNum: 1,
    pageSize: 50,
    totalSize: 1,
    totalPages: 1,
    params: {
      pageNum: 1,
      pageSize: 50,
    },
  },
  booking_detail: null,
  booking_detail_tab: {
    nannies: {
      data: [],
    },
    activityLog: {
      data: [],
    },
  },
};

const nannyBookingsSlice = createSlice({
  name: 'nannyBookings',
  initialState: initialNannyBookingState,
  reducers: {
    setNannyBookingListSuccess(
      state: NANNY_BOOKING_STATE,
      action: PayloadAction<NANNY_BOOKING_LIST_STATE>
    ) {
      state.booking_list = {
        ...state.booking_list,
        ...action.payload,
      };
    },
    setNannyBookingDetailSuccess(
      state: NANNY_BOOKING_STATE,
      action: PayloadAction<NANNY_BOOKING_DETAIL_TYPE | null>
    ) {
      state.booking_detail = action.payload;
    },
    setNannyBookingListParams(
      state: NANNY_BOOKING_STATE,
      action: PayloadAction<GET_NANNY_BOOKING_LIST_PARAMS_REQUEST | null>
    ) {
      state.booking_list.params = action.payload;
    },
    setNannyBookingDetailTab(
      state: NANNY_BOOKING_STATE,
      action: PayloadAction<NANNY_BOOKING_DETAIL_TAB_STATE>
    ) {
      state.booking_detail_tab = action.payload;
    },
    setNanniesInBookingSuccess(
      state: NANNY_BOOKING_STATE,
      action: PayloadAction<NANNY_IN_BOOKING_TYPE[]>
    ) {
      state.booking_detail_tab.nannies.data = action.payload;
    },
    setActivityLogInBookingSuccess(
      state: NANNY_BOOKING_STATE,
      action: PayloadAction<BOOKING_ACTIVITY_LOG[]>
    ) {
      state.booking_detail_tab.activityLog.data = action.payload;
    },
  },
});

// Actions
export const {
  setNannyBookingDetailSuccess,
  setNannyBookingListSuccess,
  setNannyBookingListParams,
  setNannyBookingDetailTab,
  setNanniesInBookingSuccess,
  setActivityLogInBookingSuccess,
} = nannyBookingsSlice.actions;

export default nannyBookingsSlice.reducer;
