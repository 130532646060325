import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { CloseCircle } from 'asset';
import { Inputs } from 'components';
import yup from 'config/yup.custom';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { getAppVersionDetailApi, updateAppVersionApi } from 'services';
import { NotifyService } from 'helpers';
import { appVersionForceUpdateroOptions, OptionItem } from 'models';
import Select from 'components/form-control/Select';
import { LoadingPopup } from 'components/common';

interface EditAdminProps {
  open: boolean;
  subject?: string;
  onSuccess: () => void;
  handleClosePopup: () => void;
}

interface IFormInputs {
  flatform: string;
  version: string;
  force_updatero: OptionItem;
}

const defaultValues: Partial<IFormInputs> = {
  flatform: '',
  version: '',
  force_updatero: undefined,
};

const PopupEditAppVersion = (props: EditAdminProps) => {
  const { open, handleClosePopup, onSuccess, subject } = props;

  const [loadingPopup, setLoadingPopup] = useState(false);

  const schema = useMemo(() => {
    return yup.object({
      flatform: yup.string().required('Flatform is required'),
      version: yup.string().required('App Version is required'),
      force_updatero: yup.mixed().required('Update Type is required'),
    });
  }, []);

  const form = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  const { reset } = form;

  const _handleClosePopup = () => {
    handleClosePopup();
    reset({ ...defaultValues });
  };

  useEffect(() => {
    if (!open) return;
    if (subject) {
      setLoadingPopup(true);
      const fetchDetail = async () => {
        await getAppVersionDetailApi(subject)
          .then(({ data }) => {
            reset({
              flatform: data.data.flatform,
              version: data.data.version,
              force_updatero: appVersionForceUpdateroOptions.find(
                (t) => t.id === data.data.force_updatero
              ) || {
                id: data.data.force_updatero,
                label: data.data.force_updatero,
              },
            });
            setLoadingPopup(false);
          })
          .catch((e) => NotifyService.error(e));
      };
      fetchDetail();
    }
  }, [open, subject, reset]);

  const onSubmit = async (value: IFormInputs) => {
    if (subject)
      await updateAppVersionApi(subject, {
        version: value.version,
        force_updatero: value.force_updatero?.id,
      })
        .then((r) => {
          onSuccess();
          _handleClosePopup();
          NotifyService.success(r);
        })
        .catch((e) => NotifyService.error(e))
        .finally(() => {});
  };

  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiPaper-root': {
          width: 527,
          borderRadius: 0,
        },
      }}
    >
      <Grid>
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '20px 25px',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '100%',
            }}
          >
            Edit App Version
          </Typography>
          <IconButton
            onClick={_handleClosePopup}
            sx={{
              padding: 0,
            }}
          >
            <CloseCircle />
          </IconButton>
        </Grid>
        <Divider />
        {loadingPopup && <LoadingPopup color="primary" />}
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Stack spacing="20px" p="25px 24px">
            <Stack spacing="16px">
              <Stack>
                <Inputs title="Flatform" form={form} name="flatform" readOnly />
              </Stack>
              <Stack>
                <Inputs title="App Version" form={form} name="version" />
              </Stack>
              <Grid>
                <Select
                  title="Update Type"
                  form={form}
                  filterFunc={true}
                  name="force_updatero"
                  options={appVersionForceUpdateroOptions}
                />
              </Grid>
            </Stack>
            <Stack direction="row" spacing="8px">
              <Button
                variant="outlined"
                color="secondary"
                sx={{
                  width: '233.5px',
                  height: '30px',
                }}
                onClick={_handleClosePopup}
              >
                Cancel
              </Button>
              <Button
                color="secondary"
                variant="contained"
                sx={{
                  width: '233.5px',
                  height: '30px',
                }}
                type="submit"
              >
                Update
              </Button>
            </Stack>
          </Stack>
        </form>
      </Grid>
    </Dialog>
  );
};

export default PopupEditAppVersion;
