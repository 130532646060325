import axios from 'axios';
import * as CONSTANTS from 'config/constants';

let isRefreshing = false;
let failedQueue: any[] = [];

const _AppAPIInstance = axios.create({
  baseURL: `${process.env.REACT_APP_VINOVA_API_NANNY}`,
});

_AppAPIInstance.defaults.headers.common['Content-Type'] =
  'application/json; charset=utf-8';
_AppAPIInstance.defaults.headers.common['Time-Zone'] =
  Intl.DateTimeFormat().resolvedOptions().timeZone;

export const setTokenNanny = (token: string) => {
  AppAPIInstanceNanny.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${token}`;
  // AppAPIInstance.defaults.headers.common['token'] = `${token}`;
};

_AppAPIInstance.interceptors.response.use(
  (response) => {
    // if (response && response.data) {
    //   return response.data || null;
    // }
    return response;
  },
  (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers['Authorization'] = 'Bearer ' + token;
            return AppAPIInstanceNanny(originalRequest);
          })
          .catch((err) => {
            return null;
          });
      }

      originalRequest._retry = true;
      setIsRefreshing(true);

      return new Promise(function (resolve, reject) {
        getNewTokenNanny()
          .then((token) => {
            setTokenNanny(token);
            originalRequest.headers['Authorization'] = 'Bearer ' + token;
            processQueue(null, token);
            return resolve(AppAPIInstanceNanny(originalRequest));
          })
          .catch((err) => {
            localStorage.clear();
            const _window: any = window;
            _window.location = '/';
            return reject(err);
          })
          .finally(() => {
            setIsRefreshing(false);
          });
      });
    }
    return Promise.reject(error?.response?.data?.error || error);
  }
);

const processQueue = (error: any, token = '') => {
  failedQueue.forEach((prom: any) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

export const getNewTokenNanny = async () => {
  let refreshToken: any = localStorage.getItem(CONSTANTS.REFRESH_TOKEN) || null;
  if (!refreshToken) {
    return Promise.reject({ error: 'RefreshToken Invalid' });
  }

  const tokenData: any = await axios
    .post(
      `${process.env.REACT_APP_VINOVA_API}${CONSTANTS.API.AUTH_USER.REFRESH_TOKEN}`,
      { refresh_token: refreshToken },
      { params: { refreshToken } }
    )
    .then((r) => r.data)
    .catch(() => null);
  if (!tokenData) {
    return Promise.reject({ error: 'RefreshToken Invalid' });
  }

  const token = tokenData.data.access_token;
  localStorage.setItem(CONSTANTS.ACCESS_TOKEN, token);
  setTokenNanny(token);
  return token;
};

export const startWatchStorageNanny = () => {
  window.addEventListener('storage', (event: any) => {
    if (event.storageArea === localStorage) {
      let v;
      try {
        v = JSON.parse(event.newValue);
      } catch (e) {
        v = event.newValue;
      }

      if (event.key === CONSTANTS.isRefreshing) {
        isRefreshing = v;
        if (!v && !!failedQueue?.length) {
          let token = localStorage.getItem(CONSTANTS.ACCESS_TOKEN) || '';
          processQueue(null, token);
        }
      }
    }
  });
};

const setIsRefreshing = (status: boolean) => {
  isRefreshing = status;
  localStorage.setItem(CONSTANTS.isRefreshing, status.toString());
};

export default _AppAPIInstance;
export const AppAPIInstanceNanny = _AppAPIInstance;
