import { Box, Button, Stack, Typography } from '@mui/material';
import PopupEditPaynow from 'components/Popups/PopupEditPaynow';
import { SITTER_DETAIL_TYPE } from 'models';
import { useState } from 'react';

interface Props {
  item: SITTER_DETAIL_TYPE | null;
  onSuccess: any;
  sitterId?: string;
}
function Payment({ item, sitterId, onSuccess }: Props) {
  const [isOpenEdit, setIsOpenEdit] = useState<boolean>(false);

  const setOpenEdit = () => {
    setIsOpenEdit(true);
  };

  const onReloadList = () => {
    if (!sitterId) return;
    setIsOpenEdit(false);
    onSuccess();
  };

  return (
    <Stack spacing={3}>
      <Stack direction="row" justifyContent="space-between">
        <Stack spacing={1}>
          <Typography variant="Large_Body_18_SemiBold">Bank Info:</Typography>
          <Stack spacing={3} direction="row">
            <Box minWidth="200px">
              <Typography
                variant="Small_Body_16_Regular"
                color={'text.secondary'}
              >
                Bank Name
              </Typography>
              <Typography variant="Small_Body_16_Regular">
                {item?.payoutMethod?.bank_name || '-'}
              </Typography>
            </Box>
            <Box minWidth="200px">
              <Typography
                variant="Small_Body_16_Regular"
                color={'text.secondary'}
              >
                Account Name
              </Typography>
              <Typography variant="Small_Body_16_Regular">
                {item?.payoutMethod?.account_holder_name || '-'}
              </Typography>
            </Box>
            <Box minWidth="200px">
              <Typography
                variant="Small_Body_16_Regular"
                color={'text.secondary'}
              >
                Account Number
              </Typography>
              <Typography variant="Small_Body_16_Regular">
                {item?.payoutMethod?.bank_account_number || '-'}
              </Typography>
            </Box>
          </Stack>
        </Stack>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            height: 30,
            fontWeight: 600,
            fontSize: '13px',
            lineHeight: '100%',
          }}
          onClick={setOpenEdit}
        >
          {item?.payoutMethod?.pay_now_name ? 'Edit' : 'Add'}
        </Button>
      </Stack>

      <Stack spacing={1}>
        <Typography variant="Large_Body_18_SemiBold">Paynow Info:</Typography>
        <Stack spacing={3} direction="row">
          <Box minWidth="200px">
            <Typography
              variant="Small_Body_16_Regular"
              color={'text.secondary'}
            >
              Paynow Name
            </Typography>
            <Typography variant="Small_Body_16_Regular">
              {' '}
              {item?.payoutMethod?.pay_now_name || '-'}
            </Typography>
          </Box>
          <Box minWidth="200px">
            <Typography
              variant="Small_Body_16_Regular"
              color={'text.secondary'}
            >
              Paynow Number
            </Typography>
            <Typography variant="Small_Body_16_Regular">
              {' '}
              {item?.payoutMethod?.pay_now_number || '-'}
            </Typography>
          </Box>
        </Stack>
      </Stack>
      {isOpenEdit && (
        <PopupEditPaynow
          paymentInfo={{
            bank_name: item?.payoutMethod?.bank_name || '',
            account_holder_name: item?.payoutMethod?.account_holder_name || '',
            bank_account_number: item?.payoutMethod?.bank_account_number || '',
            pay_now_name: item?.payoutMethod?.pay_now_name || '',
            pay_now_number: item?.payoutMethod?.pay_now_number || '',
            sitter_id: sitterId,
          }}
          isEdit={!!item?.payoutMethod?.pay_now_name}
          open={isOpenEdit}
          onSuccess={onReloadList}
          handleClosePopup={() => setIsOpenEdit(false)}
        />
      )}
    </Stack>
  );
}

export default Payment;
