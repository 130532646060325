import React from 'react';

const DeleteIcon = (props: any) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="40" height="40" rx="20" fill="#FF3B30" fillOpacity="0.2" />
      <path
        d="M29.07 13.23C27.46 13.07 25.85 12.95 24.23 12.86V12.85L24.01 11.55C23.86 10.63 23.64 9.25 21.3 9.25H18.68C16.35 9.25 16.13 10.57 15.97 11.54L15.76 12.82C14.83 12.88 13.9 12.94 12.97 13.03L10.93 13.23C10.51 13.27 10.21 13.64 10.25 14.05C10.29 14.46 10.65 14.76 11.07 14.72L13.11 14.52C18.35 14 23.63 14.2 28.93 14.73C28.96 14.73 28.98 14.73 29.01 14.73C29.39 14.73 29.72 14.44 29.76 14.05C29.79 13.64 29.49 13.27 29.07 13.23Z"
        fill="#FF796E"
      />
      <path
        d="M27.23 16.14C26.99 15.89 26.66 15.75 26.32 15.75H13.68C13.34 15.75 13 15.89 12.77 16.14C12.54 16.39 12.41 16.73 12.43 17.08L13.05 27.34C13.16 28.86 13.3 30.76 16.79 30.76H23.21C26.7 30.76 26.84 28.87 26.95 27.34L27.57 17.09C27.59 16.73 27.46 16.39 27.23 16.14ZM21.66 25.75H18.33C17.92 25.75 17.58 25.41 17.58 25C17.58 24.59 17.92 24.25 18.33 24.25H21.66C22.07 24.25 22.41 24.59 22.41 25C22.41 25.41 22.07 25.75 21.66 25.75ZM22.5 21.75H17.5C17.09 21.75 16.75 21.41 16.75 21C16.75 20.59 17.09 20.25 17.5 20.25H22.5C22.91 20.25 23.25 20.59 23.25 21C23.25 21.41 22.91 21.75 22.5 21.75Z"
        fill="#FF796E"
      />
    </svg>
  );
};

export default DeleteIcon;
