import {
  GET_NANNY_BOOKING_LIST_PARAMS_REQUEST,
  PayloadActionData,
} from 'models';

export const GET_NANNY_BOOKING_LIST_REQUEST: string =
  'GET_NANNY_BOOKING_LIST_REQUEST';

export const GET_NANNY_BOOKING_DETAIL_REQUEST: string =
  'GET_NANNY_BOOKING_DETAIL_REQUEST';

export const DELETE_NANNY_BOOKING_REQUEST: string =
  'DELETE_NANNY_BOOKING_REQUEST';

export const GET_LIST_NANNY_IN_BOOKING_REQUEST: string =
  'GET_LIST_NANNY_IN_BOOKING_REQUEST';

export const GET_LIST_ACTIVITY_LOG_IN_BOOKING_REQUEST =
  'GET_LIST_ACTIVITY_LOG_IN_BOOKING_REQUEST';

export const DELETE_ACTIVITY_LOG_IN_BOOKING_REQUEST =
  'DELETE_ACTIVITY_LOG_IN_BOOKING_REQUEST';

export const getNannyBookingListAction = (
  params?: GET_NANNY_BOOKING_LIST_PARAMS_REQUEST | null
) => {
  return {
    type: GET_NANNY_BOOKING_LIST_REQUEST,
    payload: { params },
  };
};

export const deleteNannyBookingAction = (payload: {
  id: string;
  onSuccess?: () => void;
}) => {
  return {
    type: DELETE_NANNY_BOOKING_REQUEST,
    payload,
  };
};

export const getNannyBookingDetailAction = (
  payload: PayloadActionData<{ id: string }>
) => {
  return {
    type: GET_NANNY_BOOKING_DETAIL_REQUEST,
    payload,
  };
};

export const getListNannyInBookingAction = (
  payload: PayloadActionData<{ id: string }>
) => {
  return {
    type: GET_LIST_NANNY_IN_BOOKING_REQUEST,
    payload,
  };
};

export const getListActivityLogInBookingAction = (
  payload: PayloadActionData<{ id: string }>
) => {
  return {
    type: GET_LIST_ACTIVITY_LOG_IN_BOOKING_REQUEST,
    payload,
  };
};

export const deleteActivityLogInBookingAction = (payload: {
  id: string;
  onSuccess?: () => void;
}) => {
  return {
    type: DELETE_ACTIVITY_LOG_IN_BOOKING_REQUEST,
    payload,
  };
};
