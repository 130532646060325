import { HeadCell, JOBS_TYPE } from 'models';

export const headCells: readonly HeadCell<JOBS_TYPE>[] = [
  {
    id: 'number',
    numeric: false,
    disablePadding: true,
    isSort: true,
    label: 'Job No.',
  },
  {
    id: 'srequest_number',
    numeric: false,
    disablePadding: true,
    isSort: true,
    label: 'Request No.',
  },
  {
    id: 'Parent',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Parent Name',
  },
  {
    id: 'start_datetime',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Start Datetime',
  },
  {
    id: 'end_datetime',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'End Datetime',
  },
  {
    id: 'Sitter',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Sitter Name',
  },
  {
    id: 'address',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Short Address',
  },
  {
    id: 'total',
    numeric: true,
    disablePadding: false,
    isSort: true,
    label: 'Total Amount',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Status',
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Created Date',
  },
];
