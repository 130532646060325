import { FLAG_TYPE, HeadCell } from 'models';

export const headCells: readonly HeadCell<FLAG_TYPE>[] = [
  {
    id: 'UserFlag',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Flag',
  },
  {
    id: 'User',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Sitter',
  },
  {
    id: 'star_sitter_badge',
    numeric: false,
    disablePadding: true,
    isSort: false,
    label: 'Email',
  },
  {
    id: 'updatedAt',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Update At',
  },
  {
    id: 'bio',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Remarks',
  },
];
