import { takeLatest, call, put } from 'redux-saga/effects';
import { deleteUsersApi, getUserDetailApi, getUsersListApi } from 'services';
import { AxiosResponse } from 'axios';
import {
  DELETE_USERS_REQUEST,
  GET_USERS_DETAIL_REQUEST,
  GET_USERS_LIST_REQUEST,
} from 'store/reducers/accounts/actionTypes';
import {
  setUsersDetailSuccess,
  setUsersListSuccess,
} from 'store/reducers/accounts';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  GET_USERS_PARAMS_REQUEST,
  USERS_LIST_STATE,
  USER_DETAIL_TYPE,
} from 'models';
import { toast } from 'react-toastify';
import { TOAST } from 'config';
import { setGlobalLoading, setLoadingPopup } from 'store/reducers/global';
import { NotifyService } from 'helpers';

function* setUsersListRequest(
  action: PayloadAction<{
    params?: GET_USERS_PARAMS_REQUEST;
  }>
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<USERS_LIST_STATE> = yield call(
      getUsersListApi,
      action.payload.params
    );
    yield put(
      setUsersListSuccess({
        record: response.data.record,
        metadata: response.data.metadata,
      })
    );
    yield put(setGlobalLoading(false));
  } catch (error: unknown) {}
}

function* getUsersDetailRequest(
  action: PayloadAction<{
    id: string;
    onSuccess?: () => void;
  }>
) {
  try {
    yield put(setLoadingPopup(true));
    const response: AxiosResponse<{
      data: USER_DETAIL_TYPE;
    }> = yield call(getUserDetailApi, action.payload.id);
    yield put(setUsersDetailSuccess(response.data.data));
    if (action.payload.onSuccess) action.payload.onSuccess();
    yield put(setLoadingPopup(false));
  } catch (error: unknown) {}
}

function* deleteUsersRequest(
  action: PayloadAction<{
    id: string;
    onSuccess?: () => void;
  }>
) {
  try {
    yield call(deleteUsersApi, action.payload.id);
    if (action.payload.onSuccess) action.payload.onSuccess();
    toast.success('Success', TOAST.success);
  } catch (error: any) {
    NotifyService.error(error);
    yield put(setGlobalLoading(false));
  }
}

function* users() {
  yield takeLatest(GET_USERS_LIST_REQUEST, setUsersListRequest);
  yield takeLatest(GET_USERS_DETAIL_REQUEST, getUsersDetailRequest);
  yield takeLatest(DELETE_USERS_REQUEST, deleteUsersRequest);
  // yield debounce(
  //   250,
  //   GET_STAFFS_LIST_REQUEST_WITH_KEYWORD,
  //   setStaffsListRequest
  // );
}

export default users;
