import {
  FormControl,
  Grid,
  List,
  MenuItem,
  Select,
  SelectChangeEvent,
  selectClasses,
  Typography,
  useTheme,
} from '@mui/material';
import { UsePaginationItem } from '@mui/material/usePagination/usePagination';
import { Stack } from '@mui/system';
import { METADATA_TYPE } from 'models';
import React from 'react';
import { formatMoney } from 'helpers';

interface Props {
  items: UsePaginationItem[];
  metadata: METADATA_TYPE;
  numberItem?: number | undefined;
  rowsPerPage: number;
  handleChangeRowsPerPage: (event: SelectChangeEvent) => void;
  onlyOnePage?: boolean;
}

const TableCaption = ({
  items,
  metadata,
  rowsPerPage,
  handleChangeRowsPerPage,
  onlyOnePage = false,
  numberItem,
}: Props) => {
  const theme = useTheme();

  return (
    <div
      style={{
        padding: '0 33px',
        backgroundColor: '#fff',
        border: '1px solid #D9D9D9',
        marginTop: '8px',
      }}
    >
      <Grid container alignItems="center" justifyContent="space-between">
        {onlyOnePage ? (
          <Typography
            sx={{
              fontSize: '16px ',
              color: '#666666 ',
            }}
          >
            Showing {numberItem ? '1' : '0'} to {numberItem} of {numberItem}{' '}
            entries
          </Typography>
        ) : (
          <Typography
            sx={{
              fontSize: '16px ',
              color: '#666666 ',
            }}
          >
            Showing {formatMoney(metadata.limit * (metadata.page - 1) + 1, 0)}{' '}
            to{' '}
            {formatMoney(
              metadata.page * metadata.limit > metadata.total_count
                ? metadata?.total_count
                : metadata.page * metadata.limit,
              0
            )}{' '}
            of {formatMoney(metadata?.total_count || 0, 0)} entries
          </Typography>
        )}

        <Grid sx={{ display: 'flex' }} alignItems="center" gap="8px">
          <FormControl sx={{ minWidth: 132 }}>
            <Select
              value={rowsPerPage.toString()}
              onChange={handleChangeRowsPerPage}
              inputProps={{ 'aria-label': 'Without label' }}
              color="secondary"
              sx={{
                [`& .${selectClasses.select}`]: {
                  padding: '11px 12px 11px',
                  fontWeight: 400,
                  fontSize: 12,
                  lineHeight: '100%',
                  display: 'flex',
                  alignItems: 'center',
                },
                [`& .${selectClasses.iconOutlined}`]: {
                  top: 'calc(50% - 0.55em);',
                },
              }}
            >
              <MenuItem
                value={25}
                sx={{
                  fontWeight: 400,
                  fontSize: 12,
                  lineHeight: '100%',
                }}
              >
                25 per page
              </MenuItem>
              <MenuItem
                value={50}
                sx={{
                  fontWeight: 400,
                  fontSize: 12,
                  lineHeight: '100%',
                }}
              >
                50 per page
              </MenuItem>
              <MenuItem
                value={100}
                sx={{
                  fontWeight: 400,
                  fontSize: 12,
                  lineHeight: '100%',
                }}
              >
                100 per page
              </MenuItem>
              <MenuItem
                value={250}
                sx={{
                  fontWeight: 400,
                  fontSize: 12,
                  lineHeight: '100%',
                }}
              >
                250 per page
              </MenuItem>
            </Select>
          </FormControl>
          <List>
            <Stack direction="row">
              {items.map(
                ({ page, type, selected, ...item }: any, index: number) => {
                  let children = null;

                  if (type === 'start-ellipsis' || type === 'end-ellipsis') {
                    children = (
                      <button
                        type="button"
                        style={{
                          height: '38px',
                          width: '30px',
                          backgroundColor: 'white',
                          borderRight: '1px solid #D9D9D9',
                          borderTop: '1px solid #D9D9D9',
                          borderBottom: '1px solid #D9D9D9',
                          borderLeft: '0px',
                          cursor: 'pointer',
                          fontSize: '12px',
                        }}
                        {...item}
                      >
                        ...
                      </button>
                    );
                  } else if (type === 'page') {
                    children = (
                      <button
                        type="button"
                        style={{
                          fontWeight: selected ? 'bold' : undefined,
                          color: selected
                            ? theme.palette.white.light
                            : theme.palette.black.dark,
                          height: '38px',
                          width: '30px',
                          backgroundColor: selected
                            ? theme.palette.secondary.main
                            : 'white',
                          borderRight: '1px solid #D9D9D9',
                          borderTop: '1px solid #D9D9D9',
                          borderBottom: '1px solid #D9D9D9',
                          borderLeft: '0px',
                          cursor: 'pointer',
                          fontSize: '12px',
                        }}
                        {...item}
                      >
                        {page}
                      </button>
                    );
                  } else {
                    if (index === items.length - 1) {
                      children = (
                        <button
                          type="button"
                          style={{
                            height: '38px',
                            width: '63px',
                            borderRight: '1px solid #D9D9D9',
                            borderTop: '1px solid #D9D9D9',
                            borderBottom: '1px solid #D9D9D9',
                            borderLeft: '0px',
                            borderRadius: '0px 5px 5px 0px',
                            backgroundColor: 'white',
                            textTransform: 'capitalize',
                            cursor: 'pointer',
                            fontSize: '12px',
                          }}
                          {...item}
                        >
                          {type}
                        </button>
                      );
                    } else {
                      children = (
                        <button
                          type="button"
                          style={{
                            height: '38px',
                            width: '89px',
                            border: '1px solid #D9D9D9',
                            borderRadius: '5px 0px 0px 5px',
                            backgroundColor: 'white',
                            textTransform: 'capitalize',
                            cursor: 'pointer',
                            fontSize: '12px',
                          }}
                          {...item}
                        >
                          {type}
                        </button>
                      );
                    }
                  }

                  return <li key={index}>{children}</li>;
                }
              )}
            </Stack>
          </List>
        </Grid>
      </Grid>
    </div>
  );
};

export default TableCaption;
