import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from '@mui/material';
import { EditParentIcon, NotVerifiedIcon, VerifiedIcon } from 'asset';
import { Layout, PopupCreateOrEditParent } from 'components';
import { drawerWidth } from 'config';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from 'store/hook';
import {
  initialAcountState,
  setParentDetailSuccess,
  setParentDetailTabState,
} from 'store/reducers/accounts';
import { GET_PARENT_DETAIL_REQUEST } from 'store/reducers/accounts/actionTypes';
import Activity from './Activity';
import Addresses from './Addresses';
import Chat from './Chat';
import Children from './Children';
import Jobs from './Jobs';
import Notes from './Notes';
import PaymentHistory from './PaymentHistory';
import ServiceRequest from './ServiceRequest';
import Transactions from './Transactions';

const titleTab = [
  'Admin Notes',
  'Service Request',
  'Jobs',
  'Top-ups History',
  'Transactions',
  'Activity',
  'Chat',
  'Addresses',
  'Children',
];
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ParentDetail = () => {
  const dispatch = useAppDispatch();
  const { parent_id } = useParams();
  const [tabValue, setTabValue] = useState(0);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const { parent_detail } = useAppSelector(
    (state) => state.accountReducer.parents
  );
  const navigate = useNavigate();

  const setOpenEdit = (event: any) => {
    event.stopPropagation();
    setOpenEditPopup(true);
  };
  const onClosePopup = (txt: string) => {
    if (txt === 'edit') {
      setOpenEditPopup(false);
    }
  };

  const handleChangeTabValue = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setTabValue(newValue);
  };

  const onSuccessDeleteParent = () => {
    dispatch({
      type: GET_PARENT_DETAIL_REQUEST,
      payload: { id: parent_id },
    });
  };

  useEffect(() => {
    dispatch({
      type: GET_PARENT_DETAIL_REQUEST,
      payload: { id: parent_id },
    });
    return () => {
      dispatch(setParentDetailSuccess(null));
      dispatch(
        setParentDetailTabState(initialAcountState.parents.parent_detail_tab)
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parent_id]);

  return (
    <Layout>
      <AppBar
        position="fixed"
        sx={{
          width: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`,
          backgroundColor: 'white.light',
          borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
        }}
        elevation={0}
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Grid>
            <Typography
              sx={{
                fontSize: '20px',
                lineHeight: 1,
                color: 'grey1.light',
              }}
            >
              Accounts / Parents /{' '}
              {parent_detail?.first_name && parent_detail?.last_name
                ? parent_detail?.first_name + ' ' + parent_detail?.last_name
                : 'No data to display yet'}
            </Typography>
          </Grid>
        </Toolbar>
      </AppBar>
      <Paper
        elevation={0}
        sx={{
          p: '16px',
        }}
      >
        <Grid container justifyContent="space-between" pb="16px">
          <Button
            startIcon={<ArrowBackIosNewIcon />}
            sx={{
              p: 0,
              color: 'black.light',
              ':hover': {
                backgroundColor: 'white.light',
              },
            }}
            variant="text"
            onClick={() => {
              navigate(`/accounts/parents`);
            }}
          >
            Back
          </Button>
          <Button
            startIcon={<EditParentIcon />}
            sx={{
              p: 0,
            }}
            onClick={(e) => setOpenEdit(e)}
          >
            Edit Parent
          </Button>
        </Grid>
        <Stack
          spacing="55px"
          direction="row"
          alignItems="center"
          sx={{
            backgroundColor: 'rgba(34, 111, 227, 0.04)',
            p: '21px 31px',
          }}
        >
          <Stack direction="row" spacing="16px" alignItems="center">
            <Avatar
              src={parent_detail?.profile_picture ? parent_detail?.profile_picture : ''}
              sx={{
                width: '40px',
                height: '40px',
                marginRight: '6px',
              }}
            />
            <Grid display="flex" flexDirection="column" gap="4px">
              <Typography
                fontWeight={500}
                lineHeight="14px"
                fontSize={14}
                letterSpacing="0.1px"
                color="text.secondary"
              >
                Parent Name (First+Last)
              </Typography>
              <Typography
                fontWeight={400}
                lineHeight="16px"
                fontSize={14}
                letterSpacing="0.25px"
                color="black.light"
              >
                {parent_detail?.first_name && parent_detail?.last_name
                  ? parent_detail?.first_name + ' ' + parent_detail?.last_name
                  : 'No data to display yet'}
              </Typography>
            </Grid>
          </Stack>
          <Grid display="flex" flexDirection="column" gap="4px">
            <Typography
              fontWeight={500}
              lineHeight="14px"
              fontSize={14}
              letterSpacing="0.1px"
              color="text.secondary"
            >
              Email
            </Typography>
            <Grid container>
              <Typography
                fontWeight={400}
                lineHeight="16px"
                fontSize={14}
                letterSpacing="0.25px"
                color="black.light"
                display="flex"
              >
                {parent_detail?.email
                  ? parent_detail?.email
                  : 'No data to display yet'}
              </Typography>
              <Grid display="flex" alignItems="center" ml="16px">
                {parent_detail?.email_verified ? (
                  <>
                    <Typography
                      component="span"
                      variant="Caption_12_Medium"
                      display="flex"
                      alignItems="center"
                      color="#AEAEB2"
                    >
                      Verified &nbsp;
                    </Typography>
                    <VerifiedIcon />
                  </>
                ) : (
                  <>
                    <Typography
                      component="span"
                      variant="Caption_12_Medium"
                      display="flex"
                      alignItems="center"
                      color="#AEAEB2"
                    >
                      Not verified &nbsp;
                    </Typography>
                    <NotVerifiedIcon />
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid display="flex" flexDirection="column" gap="4px" ml="16px">
            <Typography
              fontWeight={500}
              lineHeight="14px"
              fontSize={14}
              letterSpacing="0.1px"
              color="text.secondary"
            >
              Phone number
            </Typography>
            <Grid container>
              <Typography variant="Components_UnstyledContainer">
                {parent_detail?.phone_number
                  ? parent_detail?.phone_number
                  : 'No data to display yet'}
              </Typography>
              <Grid display="flex" alignItems="center" ml="16px">
                {parent_detail?.phone_number_verified ? (
                  <>
                    <Typography
                      component="span"
                      variant="Caption_12_Medium"
                      display="flex"
                      alignItems="center"
                      color="#AEAEB2"
                    >
                      Verified &nbsp;
                    </Typography>
                    <VerifiedIcon />
                  </>
                ) : (
                  <>
                    <Typography
                      component="span"
                      variant="Caption_12_Medium"
                      display="flex"
                      alignItems="center"
                      color="#AEAEB2"
                    >
                      Not verified &nbsp;
                    </Typography>
                    <NotVerifiedIcon />
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid display="flex" flexDirection="column" gap="4px" ml="16px">
            <Typography
              fontWeight={500}
              lineHeight="14px"
              fontSize={14}
              letterSpacing="0.1px"
              color="text.secondary"
            >
              Aunty Credits
            </Typography>
            <Grid container>
              <Typography variant="Components_UnstyledContainer">
                {parent_detail?.aunty_credits_balance ||
                parent_detail?.aunty_credits_balance === 0
                  ? `$${Number(parent_detail?.aunty_credits_balance).toFixed(
                      2
                    )}`
                  : 'No data to display yet'}
              </Typography>
            </Grid>
          </Grid>
        </Stack>
        <Grid
          container
          sx={{
            borderBottom: '1px solid  rgba(0, 0, 0, 0.08)',
            marginBottom: '16px',
          }}
        >
          <Tabs
            value={tabValue}
            onChange={handleChangeTabValue}
            aria-label="basic tabs example"
          >
            {titleTab.map((item, index) => {
              return (
                <Tab
                  key={index}
                  label={item}
                  {...a11yProps(index)}
                  sx={{
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '125%',
                    letterSpacing: '0.1px',
                    color: '#959595',
                    textTransform: 'capitalize',
                  }}
                />
              );
            })}
          </Tabs>
        </Grid>
        <TabPanel value={tabValue} index={0}>
          <Notes parentId={parent_id} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <ServiceRequest parentId={parent_id} />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <Jobs parentId={parent_id} />
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <PaymentHistory parentId={parent_id} />
        </TabPanel>
        <TabPanel value={tabValue} index={4}>
          <Transactions parentId={parent_id} />
        </TabPanel>
        <TabPanel value={tabValue} index={5}>
          <Activity parentId={parent_id} />
        </TabPanel>
        <TabPanel value={tabValue} index={6}>
          <Chat parentId={parent_id} />
        </TabPanel>
        <TabPanel value={tabValue} index={7}>
          <Addresses parentId={parent_id} />
        </TabPanel>
        <TabPanel value={tabValue} index={8}>
          <Children parentId={parent_id} />
        </TabPanel>
      </Paper>
      <PopupCreateOrEditParent
        open={openEditPopup}
        handleClosePopup={() => onClosePopup('edit')}
        subject={parent_id}
        onSuccess={onSuccessDeleteParent}
      />
    </Layout>
  );
};

export default ParentDetail;
