import {
  ACTIVITY_TYPE,
  CHAT_TYPE,
  GET_ACTIVITY_TAB_PARAMS_REQUEST,
  GET_CHATS_PARAMS_REQUEST,
  GET_JOBS_PARAMS_REQUEST,
  GET_PAYMENT_PARAMS_REQUEST,
  GET_REQUESTS_PARAMS_REQUEST,
  GET_REVIEWS_PARAMS_REQUEST,
  GET_TRANSACTIONS_PARAMS_REQUEST,
  JOBS_TYPE,
  PAYMENT_TYPE,
  REQUESTS_TYPE,
  REVIEW_TYPE,
  TRANSACTION_TYPE,
} from 'models';

export interface GET_PARENTS_PARAMS_REQUEST {
  page?: number;
  limit?: number;
  sort?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  keyword?: string;
}
export interface GET_SITTERS_PARAMS_REQUEST {
  page?: number;
  limit?: number;
  sort?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  pending_payout?: boolean;
  keyword?: string;
  status?: string;
}

export interface METADATA_TYPE {
  page: number;
  limit: number;
  page_count: number;
  total_pages: number;
  total_count: number;
  pending_sitters_count?: number;
}

export interface PARENT_TYPE {
  parent_id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  status: string;
  createdAt: string;
}

export interface SITTER_TYPE {
  sitter_id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  status: string;
  rating: string;
  payout_pending: boolean;
  payoutMethod: {
    pay_now_name: string;
    pay_now_number: string;
  };
  balance: number;
}

export interface REQUESTS_PARENT_DETAIL_TYPE {
  id: string;
  type: string;
  date: string;
  address: string;
  status: string;
}

export interface JOBS_PARENT_DETAIL_TYPE {
  id: string;
  start_datetime: string;
  end_datetime: string;
  address: string;
  sitter: string;
  status: string;
  total_amount: string;
}

export interface ADDRESS_PARENT_DETAIL_TYPE {
  id: string;
  address_name: string;
  block_no: string;
  street_name: string;
  building_name: string;
  unit_no: string;
  postal_code: string;
  country: string;
  region: {
    id: string;
    name: string;
  };
}

export interface ADDRESS_DETAIL_PARENT_DETAIL_TYPE {
  latitude: number;
  longitude: number;
  id: string;
  parent_id: string;
  region_id: string;
  sitter_id: string | null;
  name: string;
  block_number: string;
  street: string;
  unit_number: string;
  building_name: string;
  country: string;
  postal_code: string;
  is_default: boolean;
  deleted: boolean;
  createdAt: string;
  updatedAt: string;
  region: {
    id: string;
    name: string;
  };
}

export interface CHILDREN_PARENT_DETAIL_TYPE {
  id: string;
  picture: string;
  children_name: string;
  age_range: number;
}

export interface CHILDREN_PARENT_DETAIL_DETAIL_TYPE {
  id: string;
  picture: string;
  full_name: string;
  children_name: string;
  age_range: number;
}

export interface PARENT_DETAIL_TYPE {
  first_name: string;
  last_name: string;
  email: string;
  email_verified: boolean;
  country_code: string;
  phone_number: string;
  phone_number_verified: boolean;
  aunty_credits_balance: number;
  payment_method: string;
  status: string;
  password?: string;
  profile_picture: string;
  children: {
    age_range: number;
    createdAt: string;
    deleted: boolean;
    full_name: string;
    id: string;
    parent_id: string;
    picture: string | null;
    updatedAt: string;
  }[];
  addresses: {
    latitude: any;
    longitude: any;
    id: string;
    parent_id: string;
    sitter_id: any;
    region_id: string;
    name: string;
    block_number: string;
    street: string;
    unit_number: string;
    building_name: string;
    country: string;
    postal_code: string;
    is_default: boolean;
    deleted: boolean;
    createdAt: string;
    updatedAt: string;
  }[];
}

export interface SITTER_DETAIL_TYPE {
  // sitter_id: string;
  // bio: any;
  // profile_picture: any;
  // star_sitter_badge: any;
  // short_video: any;
  // experiences: any;
  // languages: any;
  // pets: any;
  // additional_sit_preference: any;
  // additional_experience: any;
  // salary_per_hour: any;
  // hours_worked: any;
  // status: string;
  // deleted: boolean;
  // createdAt: string;
  // updatedAt: string;
  // years_of_experience: any;
  // User: {
  //   first_name: string;
  //   last_name: string;
  //   email: string;
  //   email_verified: boolean;
  //   phone_number: string;
  //   phone_number_verified: boolean;
  //   status: string;
  // };
  // Address: {
  //   block_number: any;
  //   country: any;
  //   postal_code: string;
  //   street: string;
  //   unit_number: string;
  // };

  address: {
    name: string | null;
    block_number: string | null;
    country: string | null;
    postal_code: string | null;
    street: string | null;
    building_name: string | null;
    unit_number: string | null;
    Region: {
      id: string;
      name: string;
    } | null;
  };
  payoutMethod: {
    account_holder_name: string;
    bank_account_number: string;
    bank_name: string;
    pay_now_name: string;
    pay_now_number: string;
  };
  bio: any;
  createdAt: string;
  hours_worked: any;
  profile_picture: any;
  rating: number;
  rating_count: number;
  salary_per_hour: any;
  short_video: any;
  sitter_id: string;
  star_sitter_badge: any;
  status: string;
  user: {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    country_code: string;
    phone_number: string;
    email_verified: boolean;
    phone_number_verified: boolean;
  };
  years_of_experience: number | null;
  balance: number;
}

export interface GET_STAFFS_PARAMS_REQUEST {
  role?: string;
  status?: string;
  email?: string;
  full_name?: string;
  page?: number;
  limit?: number;
  sort?: string;
  keyword?: string;
  orderBy?: string;
}

export interface STAFFS_TYPE {
  id: string;
  full_name: string;
  email: string;
  role: string;
  status: string;
}

export interface STAFFS_DETAIL_TYPE {
  id: string;
  full_name: string;
  date_of_birth: Date;
  email: string;
  phone_number: number;
  profile_picture: string;
  role: string;
  status: string;
  deleted: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export interface CREATE_STAFFS_TYPE {
  email: string;
  name: string;
  role: string;
}

export interface EDIT_STAFFS_TYPE {
  full_name: string;
  email: string;
  role: string;
  old_password: string;
  new_password: string;
  repeat_password: string;
}
export interface STAFFS_BODY_RESULT {
  records: STAFFS_TYPE[];
  metadata: METADATA_TYPE;
}

export interface PARENTS_LIST_STATE {
  records: PARENT_TYPE[];
  metadata: METADATA_TYPE;
  params?: GET_PARENTS_PARAMS_REQUEST | null;
}

export interface SITTER_BODY_RESULT {
  records: SITTER_TYPE[];
  metadata: METADATA_TYPE;
}

export interface SITTERS_LIST_STATE {
  records: SITTER_TYPE[];
  metadata: METADATA_TYPE;
  params: GET_SITTERS_PARAMS_REQUEST | null;
}

export interface STAFFS_LIST_STATE {
  records: STAFFS_TYPE[];
  metadata: METADATA_TYPE;
  params: GET_STAFFS_PARAMS_REQUEST | null;
}

export enum SITTER_STATUS {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  PENDING = 'pending',
  REJECTED = 'rejected',
  APPROVED = 'approved',
}

export interface SITTER_CHECKLIST_GROUP_ITEM {
  id: string;
  is_checked: boolean;
  name: string;
  type: string;
  text?: string | null;
  group_name: string;
}

export interface SITTER_CHECKLIST_GROUP {
  group_name: string;
  items: SITTER_CHECKLIST_GROUP_ITEM[];
}

export interface GET_ADDRESSES_PARENT_DETAIL_PARAMS_REQUEST {
  page?: number;
  limit?: number;
  sort?: string;
}

export interface GET_CHILDREN_PARENT_DETAIL_TYPE_PARAMS_REQUEST {
  page?: number;
  limit?: number;
  sort?: string;
}

export interface SITTERS_LIST_DETAIL_STATE {
  jobs: {
    records: JOBS_TYPE[];
    metadata: METADATA_TYPE;
    params?: GET_JOBS_PARAMS_REQUEST | null;
  };
  transactions: {
    records: TRANSACTION_TYPE[];
    metadata: METADATA_TYPE;
    params?: GET_TRANSACTIONS_PARAMS_REQUEST | null;
  };
  pendingPayout: {
    records: PAYMENT_TYPE[];
    metadata: METADATA_TYPE;
    params: GET_PAYMENT_PARAMS_REQUEST;
  };
  payoutHistory: {
    records: PAYMENT_TYPE[];
    metadata: METADATA_TYPE;
    params: GET_PAYMENT_PARAMS_REQUEST;
  };
  chat: {
    records: CHAT_TYPE[];
    metadata: METADATA_TYPE;
    params: GET_CHATS_PARAMS_REQUEST;
  };
  reviews: {
    records: REVIEW_TYPE[];
    metadata: METADATA_TYPE;
    params: GET_REVIEWS_PARAMS_REQUEST;
  };
}

export interface PARENT_DETAIL_TAB_STATE {
  jobs: {
    records: JOBS_TYPE[];
    metadata: METADATA_TYPE;
    params?: GET_JOBS_PARAMS_REQUEST | null;
  };
  paymentHistory: {
    records: PAYMENT_TYPE[];
    metadata: METADATA_TYPE;
    params: GET_PAYMENT_PARAMS_REQUEST;
  };
  transactions: {
    records: TRANSACTION_TYPE[];
    metadata: METADATA_TYPE;
    params: GET_TRANSACTIONS_PARAMS_REQUEST | null;
  };
  requests: {
    records: REQUESTS_TYPE[];
    metadata: METADATA_TYPE;
    params: GET_REQUESTS_PARAMS_REQUEST | null;
  };
  chat: {
    records: CHAT_TYPE[];
    metadata: METADATA_TYPE;
    params: GET_CHATS_PARAMS_REQUEST;
  };
  addresses: {
    records: ADDRESS_PARENT_DETAIL_TYPE[];
    metadata: METADATA_TYPE;
    params: GET_ADDRESSES_PARENT_DETAIL_PARAMS_REQUEST;
  };
  children: {
    records: CHILDREN_PARENT_DETAIL_TYPE[];
    metadata: METADATA_TYPE;
    params: GET_CHILDREN_PARENT_DETAIL_TYPE_PARAMS_REQUEST;
  };
  activity: {
    pageNum: number;
    pageSize: number;
    totalSize: number;
    totalPages: number;
    data: ACTIVITY_TYPE[];
    params?: GET_ACTIVITY_TAB_PARAMS_REQUEST | null;
  };
}

export interface GET_USERS_OPTION_PARAMS_REQUEST {
  page?: number;
  limit?: number;
  keyword?: string;
}

export interface USER_OPTION_TYPE {
  id: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  email: string;
}

export interface GET_USERS_PARAMS_REQUEST {
  page?: number;
  limit?: number;
  keyword?: string;
  sort?: string;
}

export interface USERS_TYPE {
  id: string;
  first_name: string;
  last_name: string;
  date_of_birth: string | null;
  email: string;
  email_verified: boolean;
  phone_number: string;
  phone_number_verified: boolean;
  status: string;
  parent: any;
  sitter: any;
}

export interface USERS_LIST_STATE {
  record: USERS_TYPE[];
  metadata: METADATA_TYPE;
  params?: GET_USERS_PARAMS_REQUEST | null;
}

export interface USERS_LIST_BODY {
  records: USERS_TYPE[];
  metadata: METADATA_TYPE;
}

export interface USER_DETAIL_TYPE {
  country_code: string;
  date_of_birth: string | null;
  email: string;
  email_verified: boolean;
  first_name: string;
  id: string;
  last_name: string;
  parent: {
    parent_id: string;
    bio: string | null;
    profile_picture: string | null;
    status: string;
  };
  phone_number: string;
  phone_number_verified: boolean;
  sitter: {
    bio: string | null;
    hours_worked: string | null;
    profile_picture: string | null;
    salary_per_hour: string | null;
    short_video: string | null;
    sitter_id: string;
    star_sitter_badge: string | null;
    status: string;
    years_of_experience: string | null;
  };
  status: string;
}
export interface EDIT_USER_TYPE {
  country_code: string;
  phone_number: string;
  phone_number_verified: boolean;
  password?: string;
  email: string;
  email_verified: boolean;
  first_name: string;
  last_name: string;
  status: string;
}

export interface CREATE_OR_EDIT_CHILDREN_PARENT_DETAIL_BODY {
  full_name: string;
  age_range: number;
  picture: string;
}

export interface EDIT_PAY_INFO_BODY {
  bank_name?: string;
  bank_account_number?: string;
  account_holder_name?: string;
  pay_now_name: string;
  pay_now_number: string;
  sitter_id: string;
}

export interface GET_NOTES_PARAMS_REQUEST {
  sitter_id?: string;
  parent_id?: string;
}

export interface GET_NOTES_BODY {
  id: string;
  parent_id: string;
  sitter_id: string;
  note: string;
  createdBy: string;
  updatedBy: string;
  updatedAt: string;
  createdAt: string;
  deletedAt: string;
}
