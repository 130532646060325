import { HeadCell, PROMOS_TYPE } from 'models';

export const headCells: readonly HeadCell<PROMOS_TYPE>[] = [
  {
    id: 'aunty_promocode',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Promocode',
  },
  {
    id: 'aunty_promo_title',
    numeric: false,
    disablePadding: true,
    isSort: false,
    label: 'Title',
  },
  {
    id: 'eber_hidden_code',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Eber Hidden Code',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Status',
  },
];
