import { takeLatest, call, put, debounce } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import {
  GET_REQUESTS_PARAMS_REQUEST,
  REQUESTS_BODY_RESULT,
  REQUESTS_DETAIL_TYPE,
} from 'models';

import { PayloadAction } from '@reduxjs/toolkit';
import {
  deleteAllRequestsApi,
  deleteRequestsApi,
  deleteSitterRequestsApi,
  getRequestsDetailApi,
  getRequestsListApi,
  requestForPaymentApi,
} from 'services/requests';
import {
  DELETE_ALL_REQUESTS_REQUEST,
  DELETE_REQUESTS_REQUEST,
  DELETE_SITTER_REQUESTS_REQUEST,
  GET_REQUESTS_DETAIL_REQUEST,
  GET_REQUESTS_JUST_ONCE_REQUEST,
  GET_REQUESTS_JUST_ONCE_REQUEST_WITH_KEYWORD,
  GET_REQUESTS_RECURRING_REQUEST,
  GET_REQUESTS_RECURRING_REQUEST_WITH_KEYWORD,
  REQUEST_FOR_PAYMENT,
} from 'store/reducers/requests/actionTypes';
import {
  setRequestsDetailSuccess,
  setRequestsListJustOnceSuccess,
  setRequestsListRecurringSuccess,
} from 'store/reducers/requests';
import { toast } from 'react-toastify';
import { TOAST } from 'config';
import { setGlobalLoading } from 'store/reducers/global';
import { NotifyService } from 'helpers';

function* setRequestsListJustOnceRequest(
  action: PayloadAction<{
    params?: GET_REQUESTS_PARAMS_REQUEST;
  }>
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<{
      data: REQUESTS_BODY_RESULT;
    }> = yield call(getRequestsListApi, action.payload.params);
    yield put(
      setRequestsListJustOnceSuccess({
        records: response.data.data.records,
        metadata: response.data.data.metadata,
      })
    );
    yield put(setGlobalLoading(false));
  } catch (error: any) {
    NotifyService.error(error);
    yield put(setGlobalLoading(false));
  }
}

function* setRequestsListRecurringRequest(
  action: PayloadAction<{
    params?: GET_REQUESTS_PARAMS_REQUEST;
  }>
) {
  yield put(setGlobalLoading(true));
  try {
    const response: AxiosResponse<{
      data: REQUESTS_BODY_RESULT;
    }> = yield call(getRequestsListApi, action.payload.params);
    yield put(
      setRequestsListRecurringSuccess({
        records: response.data.data.records,
        metadata: response.data.data.metadata,
      })
    );
    yield put(setGlobalLoading(false));
  } catch (error: any) {
    NotifyService.error(error);
    yield put(setGlobalLoading(false));
  }
}

function* getRequestsDetailRequest(
  action: PayloadAction<{
    id: string;
    onSuccess?: () => void;
  }>
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<{
      data: REQUESTS_DETAIL_TYPE;
    }> = yield call(getRequestsDetailApi, action.payload.id);
    yield put(setRequestsDetailSuccess(response.data.data));
    if (action.payload.onSuccess) action.payload.onSuccess();
    yield put(setGlobalLoading(false));
  } catch (error: unknown) {}
}

function* deleteRequestsRequest(
  action: PayloadAction<{
    id: string;
    onSuccess?: () => void;
  }>
) {
  try {
    yield call(deleteRequestsApi, action.payload.id);
    if (action.payload.onSuccess) action.payload.onSuccess();
    toast.success('Success', TOAST.success);
  } catch (error: unknown) {}
}

function* deleteSitterRequestsRequest(
  action: PayloadAction<{
    id: string;
    data: any;
    onSuccess?: () => void;
  }>
) {
  try {
    yield call(deleteSitterRequestsApi, action.payload.id, action.payload.data);
    if (action.payload.onSuccess) action.payload.onSuccess();
    toast.success('Success', TOAST.success);
  } catch (error: unknown) {}
}

function* PaymentRequestsRequest(
  action: PayloadAction<{
    id: string;
    data: any;
    onSuccessAccept?: () => void;
  }>
) {
  try {
    yield call(requestForPaymentApi, action.payload.id, action.payload.data);
    if (action.payload.onSuccessAccept) action.payload.onSuccessAccept();
    toast.success('Success', TOAST.success);
  } catch (error: unknown) {}
}

function* deleteAllRequestsRequest(
  action: PayloadAction<{
    ids: string[];
    onSuccess?: () => void;
  }>
) {
  try {
    yield call(deleteAllRequestsApi, action.payload.ids);
    if (action.payload.onSuccess) action.payload.onSuccess();
  } catch (error: unknown) {}
}

function* requests() {
  yield takeLatest(
    GET_REQUESTS_JUST_ONCE_REQUEST,
    setRequestsListJustOnceRequest
  );
  yield takeLatest(
    GET_REQUESTS_RECURRING_REQUEST,
    setRequestsListRecurringRequest
  );
  yield takeLatest(DELETE_REQUESTS_REQUEST, deleteRequestsRequest);
  yield takeLatest(REQUEST_FOR_PAYMENT, PaymentRequestsRequest);
  yield takeLatest(DELETE_SITTER_REQUESTS_REQUEST, deleteSitterRequestsRequest);
  yield takeLatest(DELETE_ALL_REQUESTS_REQUEST, deleteAllRequestsRequest);
  yield takeLatest(GET_REQUESTS_DETAIL_REQUEST, getRequestsDetailRequest);
  yield debounce(
    250,
    GET_REQUESTS_JUST_ONCE_REQUEST_WITH_KEYWORD,
    setRequestsListJustOnceRequest
  );

  yield debounce(
    250,
    GET_REQUESTS_RECURRING_REQUEST_WITH_KEYWORD,
    setRequestsListRecurringRequest
  );
}

export default requests;
