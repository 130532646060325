import {
  Checkbox,
  Paper,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Grid,
  Typography,
  useTheme,
  Box,
  Button,
} from '@mui/material';
import { DeleteDialog, HeadTable, TableCaption } from 'components';
import React, { useState, useMemo, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hook';
import usePagination from '@mui/material/usePagination/usePagination';
import {
  HeadCell,
  CHILDREN_PARENT_DETAIL_TYPE,
  GET_CHILDREN_PARENT_DETAIL_TYPE_PARAMS_REQUEST,
  ECLOSE_POPUP,
  BOOKINGS_TYPE,
  REQUESTS_DETAIL_TYPE,
} from 'models';
import { setParentChildrenListParams } from 'store/reducers/accounts';
import { getSortItem } from 'helpers';
import { ButtonDelete, ButtonEdit } from 'components/common/table';
import PopupEditSitter from 'components/Popups/ServiceRequest/PopupEditSitter';
import PopupAddSitter from 'components/Popups/ServiceRequest/PopupAddSitter';

const headCells: readonly HeadCell<any>[] = [
  {
    id: 'first_name',
    numeric: false,
    disablePadding: true,
    isSort: true,
    label: 'Sitter Name',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Email',
  },
  {
    id: 'phone_number',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Phone',
  },
  {
    id: 'salary_per_hour',
    numeric: true,
    disablePadding: false,
    isSort: true,
    label: 'Hourly Rate',
  },
  {
    id: 'total_hours',
    numeric: true,
    disablePadding: false,
    isSort: true,
    label: 'Total Hour',
  },
  {
    id: 'total',
    numeric: true,
    disablePadding: false,
    isSort: true,
    label: 'Total Fee',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Booking status',
  },
];

interface Props {
  requests_detail: REQUESTS_DETAIL_TYPE | null;
  onSuccess: any;
}
const Sitters = ({ requests_detail, onSuccess }: Props) => {
  const { children } = useAppSelector(
    (state) => state.accountReducer.parents.parent_detail_tab
  );

  const { params } = children;

  const dispatch = useAppDispatch();
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [openCreatePopup, setOpenCreatePopup] = useState(false);
  const [itemAction, setItemAction] = useState<BOOKINGS_TYPE>();
  const [itemSitter, setItemSitter] = useState({});
  const [openEditSitterPopup, setOpenEditSitterPopup] = useState(false);
  const [isDisable, setIsDisable] = useState(false);

  const theme = useTheme();

  useEffect(() => {
    if (
      (requests_detail?.bookings && requests_detail?.bookings.length >= 5) ||
      requests_detail?.status !== 'pending'
    ) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  }, [requests_detail]);

  const onSetListParams = (
    params: GET_CHILDREN_PARENT_DETAIL_TYPE_PARAMS_REQUEST
  ) => {
    dispatch(setParentChildrenListParams(params));
  };

  const handleChangeRowsPerPage = (event: SelectChangeEvent) => {
    const limit = parseInt(event.target.value, 10);
    onSetListParams({ ...params, page: 1, limit });
  };

  const { items } = usePagination({
    count: children.metadata.total_pages,
    page: children.metadata.page,
    onChange(_event, page) {
      onSetListParams({ ...params, page: page });
    },
  });

  const sortItem = useMemo(() => {
    return getSortItem(params?.sort);
  }, [params]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = children?.records?.map((n: any) => n?.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (_: any, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleRequestSort = (
    _: any,
    property: keyof CHILDREN_PARENT_DETAIL_TYPE
  ) => {
    setSelected([]);
    const isAsc = sortItem?.orderBy === property && sortItem?.order === 'asc';
    onSetListParams({
      ...params,
      sort: `${property}-${isAsc ? 'desc' : 'asc'}`,
    });
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const onClosePopup = (type: ECLOSE_POPUP) => {
    switch (type) {
      case ECLOSE_POPUP.DELETE:
        setOpenDeletePopup(false);
        setItemAction(undefined);
        break;
      case ECLOSE_POPUP.CREATE_OR_EDIT:
        setOpenEditSitterPopup(false);
        setItemAction(undefined);
        break;
      case ECLOSE_POPUP.ADD:
        setOpenCreatePopup(false);
        setItemAction(undefined);
        break;
    }
  };

  const setOpenDelete = (event: any, data: string) => {
    event.stopPropagation();
    setItemSitter({
      sitter_id: data,
    });
    setOpenDeletePopup(true);
  };

  const setOpenEdit = (event: any, data: BOOKINGS_TYPE) => {
    event.stopPropagation();
    setItemAction(data);
    setOpenEditSitterPopup(true);
  };

  const setOpenCreate = () => {
    setOpenCreatePopup(true);
  };

  return (
    <>
      <Box display="flex" justifyContent="flex-end" mb={'8px'}>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            height: 30,
            fontWeight: 600,
            fontSize: '13px',
            lineHeight: '100%',
          }}
          onClick={setOpenCreate}
          disabled={isDisable}
        >
          Add Sitter
        </Button>
      </Box>
      <Stack spacing="8px">
        <TableContainer
          component={Paper}
          elevation={0}
          sx={{
            border: '1px solid #D9D9D9',
            maxHeight: 'calc(100vh - 395px)',
          }}
        >
          <Table
            stickyHeader
            sx={{ minWidth: 650 }}
            aria-labelledby="tableTitle"
          >
            <HeadTable<CHILDREN_PARENT_DETAIL_TYPE>
              numSelected={selected.length}
              order={sortItem?.order}
              orderBy={sortItem?.orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={children?.records.length}
              headCells={headCells}
            />
            <TableBody
              sx={{
                height: 'auto !important',
              }}
            >
              {requests_detail?.bookings &&
                requests_detail.bookings.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell
                        padding="checkbox"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                        }}
                      >
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          onChange={(e) => handleClick(e, row.id)}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                          fontSize: '14px',
                          fontWeight: 400,
                        }}
                      >
                        {row.Sitter.User.first_name} {row.Sitter.User.last_name}
                      </TableCell>

                      <TableCell
                        align="left"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                          fontSize: '14px',
                          fontWeight: 400,
                        }}
                      >
                        {row.Sitter.User.email}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                          fontSize: '14px',
                          fontWeight: 400,
                        }}
                      >
                        {row.Sitter.User.phone_number}
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                          fontSize: '14px',
                          fontWeight: 400,
                        }}
                      >
                        ${row.salary_per_hour.toFixed(2)}
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                          fontSize: '14px',
                          fontWeight: 400,
                        }}
                      >
                        {Number(requests_detail.total_hours).toFixed(2)}
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                          fontSize: '14px',
                          fontWeight: 600,
                        }}
                      >
                        $
                        {(
                          row.salary_per_hour *
                          Number(requests_detail.total_hours)
                        ).toFixed(2)}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                          fontSize: '14px',
                          fontWeight: 400,
                        }}
                      >
                        <Stack
                          direction="row"
                          alignItems="center"
                          textTransform="capitalize"
                        >
                          <Grid
                            sx={{
                              width: '8px',
                              height: '8px',
                              borderRadius: '50%',
                              marginRight: '5px',
                              backgroundColor:
                                row.status === 'applied'
                                  ? theme.palette.secondary.main
                                  : row.status === 'requested for payment'
                                  ? theme.palette.green.light
                                  : '#B6B6B6',
                            }}
                          />
                          <Typography
                            sx={{
                              height: '20px',
                            }}
                          >
                            {row.status === 'requested for payment'
                              ? 'RFP'
                              : row.status}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          borderRight: '1px solid #DEE2E6',
                          padding: '18px 12px 17px',
                        }}
                      >
                        <Stack direction="row" spacing="10px">
                          <ButtonEdit onClick={(e) => setOpenEdit(e, row)} />
                          <ButtonDelete
                            onClick={(e) =>
                              setOpenDelete(e, row.Sitter.sitter_id)
                            }
                          />
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TableCaption
          onlyOnePage={true}
          rowsPerPage={params?.limit ?? 0}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          metadata={children.metadata}
          numberItem={requests_detail?.bookings.length}
          items={items}
        />
      </Stack>

      {openDeletePopup && (
        <DeleteDialog
          name=""
          open={openDeletePopup}
          onClose={() => onClosePopup(ECLOSE_POPUP.DELETE)}
          subject={requests_detail?.id}
          onSuccess={onSuccess}
          type="sitterSR"
          data={itemSitter}
        />
      )}
      {openEditSitterPopup && (
        <PopupEditSitter
          open={openEditSitterPopup}
          onClose={() => onClosePopup(ECLOSE_POPUP.CREATE_OR_EDIT)}
          subject={itemAction}
          handleClosePopup={() => onClosePopup(ECLOSE_POPUP.CREATE_OR_EDIT)}
          onSuccess={onSuccess}
          data_sr={requests_detail}
        />
      )}
      {openCreatePopup && (
        <PopupAddSitter
          open={openCreatePopup}
          handleClosePopup={() => onClosePopup(ECLOSE_POPUP.ADD)}
          subject={itemAction}
          onSuccess={onSuccess}
        />
      )}
    </>
  );
};

export default Sitters;
