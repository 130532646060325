import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Rating,
  Stack,
  Typography,
} from '@mui/material';
import { CloseCircle, IconCheckEmpty, IconChecked, SolidStar } from 'asset';
import { ErrorMessage, LoadingPopup } from 'components/common';
import { Inputs, Select } from 'components/form-control';
import { GetOptionsResponse } from 'components/form-control/Select';
import yup from 'config/yup.custom';
import { NotifyService } from 'helpers';
import {
  BOOKING_TYPE,
  CREATE_NANNY_TESTIMONIAL_TYPE,
  GET_NANNY_TAB_PARAMS_REQUEST,
  GET_PARENTS_PARAMS_REQUEST,
  PARENT_TYPE,
  TESTIMONIAL_TYPE,
  UPDATE_NANNY_TESTIMONIAL_TYPE,
} from 'models';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { getParentListApi } from 'services';
import { nannyTabsApi } from 'services/accounts/nanny';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setNannyDetailSuccess } from 'store/reducers/accounts';

interface PopupCreateOrEditNannyProps {
  nannyId: string;
  subject?: TESTIMONIAL_TYPE;
  cleanDetail?: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

interface IFormInputs {
  nannyId: string;
  parent: {
    id: string;
    label: string;
  } | null;
  bookingId: { id: string; label: string } | null;
  testimonialId: string;
  stars: number;
  content: string;
  isAnonymous: boolean;
}

const PopupCreateOrEditNannyTestimonial = ({
  subject,
  cleanDetail,
  onClose,
  onSuccess,
  nannyId,
}: PopupCreateOrEditNannyProps) => {
  const { loadingPopup } = useAppSelector((state) => state.globalReducer);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  const schema = useMemo(() => {
    return yup.object().shape({
      stars: yup.number().required('Rating is required'),
      parent: yup
        .mixed()
        .required('Parent is required')
        .nullable(false)
        .typeError('Parent is required'),
      bookingId: yup.mixed().notRequired(),
      isAnonymous: yup.boolean().notRequired(),
      content: yup.string().notRequired(),
    });
  }, []);

  const form = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      stars: 0,
      parent: null,
      bookingId: null,
      isAnonymous: false,
      content: '',
    },
  });

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = form;

  useEffect(() => {
    if (subject) {
      reset({
        parent: subject.parentId
          ? { id: subject.parentId, label: `${subject.parentFirstName}` }
          : undefined,
        bookingId: subject.bookingId
          ? { id: subject.bookingId, label: `${subject.bookingId}` }
          : undefined,
        testimonialId: subject.id,
        stars: subject.stars,
        content: subject.content,
        isAnonymous: subject.anonymous,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subject]);

  const createTestimonial = async (data: CREATE_NANNY_TESTIMONIAL_TYPE) => {
    await nannyTabsApi
      .addTestimonials(data)
      .then((r) => {
        NotifyService.success('Success');
        onSuccess();
        _onClose();
      })
      .catch((e) => {
        NotifyService.error(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const editTestimonial = async (data: UPDATE_NANNY_TESTIMONIAL_TYPE) => {
    if (!subject) return;
    await nannyTabsApi
      .updateTestimonials(data)
      .then((r) => {
        NotifyService.success('Success');
        onSuccess();
        _onClose();
      })
      .catch((e) => {
        NotifyService.error(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const actionForm = (data: any) => {
    setLoading(true);
    if (subject) editTestimonial(data);
    else {
      createTestimonial(data);
    }
  };

  const onSubmit = async (data: IFormInputs) => {
    if (subject) {
      const dataUpdate: UPDATE_NANNY_TESTIMONIAL_TYPE = {
        testimonialId: subject.id,
        stars: data.stars,
        content: data.content,
        isAnonymous: data.isAnonymous || false,
      };
      actionForm(dataUpdate);
    } else {
      const dataCreate: CREATE_NANNY_TESTIMONIAL_TYPE = {
        nannyId,
        bookingId: data.bookingId?.id || null,
        parentId: data.parent?.id || null,
        stars: data.stars,
        content: data.content,
        isAnonymous: data.isAnonymous || false,
      };
      actionForm(dataCreate);
    }
  };

  const _onClose = () => {
    onClose?.();
    if (cleanDetail) {
      dispatch(setNannyDetailSuccess(null));
    }
  };

  const getOptions =
    (key: keyof IFormInputs) =>
    async (page: number = 1, limit: number = 20, keyword?: string) => {
      let data: GetOptionsResponse | null = null;

      switch (key) {
        case 'parent':
          const paramsParent: GET_PARENTS_PARAMS_REQUEST = {
            page: page,
            limit: limit,
          };
          if (keyword) paramsParent.keyword = keyword;
          data = await getParentListApi(paramsParent)
            .then(({ data }) => {
              return {
                metadata: data.data.metadata,
                options: data.data.records.map((it: PARENT_TYPE) => ({
                  id: it.parent_id,
                  label: it.first_name,
                })),
              };
            })
            .catch((e) => {
              NotifyService.error(e);
              return null;
            });
          break;
        case 'bookingId':
          const paramsBookings: GET_NANNY_TAB_PARAMS_REQUEST = {
            pageNum: page,
            pageSize: limit,
            nannyId,
          };
          data = await nannyTabsApi
            .getBookings(paramsBookings)
            .then(({ data }) => {
              return {
                metadata: data.data.metadata,
                options: data.data.map((it: BOOKING_TYPE) => ({
                  id: it.id,
                  label: `${it.id} `,
                })),
              };
            })
            .catch((e) => {
              NotifyService.error(e);
              return null;
            });
          break;
      }

      return data;
    };

  return (
    <Dialog
      open
      sx={{
        '& .MuiPaper-root': {
          width: 523,
          height: 'auto',
          borderRadius: 0,
        },
      }}
    >
      <DialogTitle
        sx={{
          padding: '20px 25px',
        }}
      >
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '100%',
            }}
          >
            {subject ? 'Edit' : 'Add'} Testimonial
          </Typography>
          <IconButton
            onClick={_onClose}
            sx={{
              padding: 0,
            }}
          >
            <CloseCircle />
          </IconButton>
        </Grid>
      </DialogTitle>
      {(loadingPopup || loading) && <LoadingPopup color="primary" />}
      <DialogContent
        sx={{
          p: '30px 24px 50px 26px',
        }}
        dividers
      >
        <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
          <Stack height="100%" justifyContent="space-between">
            <Stack spacing="16px">
              <Stack>
                <Select
                  isDisabled={!!subject}
                  title="Parent"
                  name="parent"
                  form={form}
                  isHasMore
                  filterFunc={false}
                  getOptions={getOptions('parent')}
                />
              </Stack>
              <Stack>
                <Select
                  isDisabled={!!subject}
                  title="Booking ID"
                  name="bookingId"
                  form={form}
                  isHasMore
                  filterFunc={false}
                  getOptions={getOptions('bookingId')}
                />
                <Controller
                  name="isAnonymous"
                  control={form.control}
                  render={({ field }) => (
                    <FormControlLabel
                      sx={{
                        '& .MuiTypography-root': {
                          fontSize: '12px !important',
                        },
                      }}
                      control={
                        <Checkbox
                          icon={<IconCheckEmpty />}
                          checkedIcon={<IconChecked />}
                          color="secondary"
                          checked={field.value || form.watch('isAnonymous')}
                          onChange={(e) => field.onChange(e.target.checked)}
                          name="isAnonymous"
                        />
                      }
                      label="Review Anonymously"
                    />
                  )}
                />
              </Stack>
              <Stack>
                <InputLabel
                  sx={{
                    fontWeight: 500,
                    fontSize: '15px',
                    lineHeight: '100%',
                    color: '#000',
                    marginBottom: '10px',
                  }}
                >
                  Rating
                </InputLabel>
                <Controller
                  name="stars"
                  control={control}
                  render={({ field }) => (
                    <Rating
                      {...field}
                      icon={<SolidStar fontSize="48px" />}
                      emptyIcon={<SolidStar color="#D1D1D6" fontSize="48px" />}
                      onChange={(_, value) => field.onChange(value)}
                      precision={1}
                    />
                  )}
                />
                {!!errors?.stars && (
                  <ErrorMessage>{errors?.stars?.message}</ErrorMessage>
                )}
              </Stack>
              <Stack>
                <Inputs
                  title="Comments"
                  placeholder="Write something here"
                  form={form}
                  name="content"
                  rows={10}
                />
              </Stack>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              marginTop="25px"
              spacing="8px"
            >
              <Button
                variant="outlined"
                color="secondary"
                sx={{
                  width: '233.5px',
                  height: '30px',
                }}
                onClick={_onClose}
              >
                Cancel
              </Button>
              <Button
                color="secondary"
                variant="contained"
                sx={{
                  width: '233.5px',
                  height: '30px',
                }}
                type="submit"
              >
                {subject ? 'Update' : 'Add'}
              </Button>
            </Stack>
          </Stack>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default PopupCreateOrEditNannyTestimonial;
