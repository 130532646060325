import { Button, ButtonProps } from "@mui/material";
import { Trash } from "asset";
import { memo } from "react";

interface Props extends ButtonProps {
}

const ButtonDelete = memo(({ ...rest }: Props) => {

  return (
    <Button
      sx={{
        bgcolor: '#999999',
        minWidth: '38px !important',
        height: '32px',
        '&.MuiButton-root': {
          p: '0px !important',
        },
      }}
      variant="contained"
      {...rest}
    >
      <Trash />
    </Button>
  )
})

export default ButtonDelete