import { STAFFS_LIST_STATE } from './../../../models/accounts';
import { takeLatest, call, put, debounce } from 'redux-saga/effects';
import { getAdminsListApi, deleteAdminApi, getAdminDetailApi } from 'services';
import { AxiosResponse } from 'axios';
import { setGlobalLoading, setLoadingPopup } from 'store/reducers/global';
import { toast } from 'react-toastify';
import { STAFFS_DETAIL_TYPE, GET_STAFFS_PARAMS_REQUEST } from 'models';
import GET_STAFFS_LIST_REQUEST_WITH_KEYWORD, {
  DELETE_STAFFS_REQUEST,
  GET_STAFFS_DETAIL_REQUEST,
  GET_STAFFS_LIST_REQUEST,
} from 'store/reducers/accounts/actionTypes';
import {
  setStaffsDetailSuccess,
  setStaffsListSuccess,
} from 'store/reducers/accounts';
import { PayloadAction } from '@reduxjs/toolkit';
import { NotifyService } from 'helpers';

function* setStaffsListRequest(
  action: PayloadAction<{
    params?: GET_STAFFS_PARAMS_REQUEST;
  }>
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<{
      data: STAFFS_LIST_STATE;
    }> = yield call(getAdminsListApi, action.payload.params);
    yield put(setStaffsListSuccess(response.data.data));
    yield put(setGlobalLoading(false));
  } catch (error: any) {
    NotifyService.error(error);
    yield put(setGlobalLoading(false));
  }
}

function* getStaffsDetailRequest(
  action: PayloadAction<{
    id: string;
    onSuccess?: () => void;
  }>
) {
  try {
    yield put(setLoadingPopup(true));
    const response: AxiosResponse<{
      data: STAFFS_DETAIL_TYPE;
    }> = yield call(getAdminDetailApi, action.payload.id);
    yield put(setStaffsDetailSuccess(response.data.data));
    if (action.payload.onSuccess) action.payload.onSuccess();
    yield put(setLoadingPopup(false));
  } catch (error: unknown) {}
}

function* deleteStaffsRequest(
  action: PayloadAction<{
    id: string;
    onSuccess?: () => void;
    onFailed?: (data?: any) => void;
  }>
) {
  try {
    yield put(setGlobalLoading(true));
    yield call(deleteAdminApi, action.payload.id);
    yield put(setGlobalLoading(false));
    if (action.payload.onSuccess) action.payload.onSuccess();
  } catch (error: unknown) {
    if (action.payload.onFailed) action.payload.onFailed();
    yield put(setGlobalLoading(false));
    toast.error('Error!', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
}

function* staffs() {
  yield takeLatest(GET_STAFFS_LIST_REQUEST, setStaffsListRequest);
  yield takeLatest(DELETE_STAFFS_REQUEST, deleteStaffsRequest);
  yield takeLatest(GET_STAFFS_DETAIL_REQUEST, getStaffsDetailRequest);
  yield debounce(
    250,
    GET_STAFFS_LIST_REQUEST_WITH_KEYWORD,
    setStaffsListRequest
  );
}

export default staffs;
