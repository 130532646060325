import React from 'react';

const ProfileCircle = (props: any) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.12 13.53C12.1 13.53 12.07 13.53 12.05 13.53C12.02 13.53 11.98 13.53 11.95 13.53C9.67998 13.46 7.97998 11.69 7.97998 9.50998C7.97998 7.28998 9.78998 5.47998 12.01 5.47998C14.23 5.47998 16.04 7.28998 16.04 9.50998C16.03 11.7 14.32 13.46 12.15 13.53C12.13 13.53 12.13 13.53 12.12 13.53ZM12 6.96998C10.6 6.96998 9.46998 8.10998 9.46998 9.49998C9.46998 10.87 10.54 11.98 11.9 12.03C11.93 12.02 12.03 12.02 12.13 12.03C13.47 11.96 14.52 10.86 14.53 9.49998C14.53 8.10998 13.4 6.96998 12 6.96998Z"
        fill={props.active ? '#FFF' : '#8E8E93'}
      />
      <path
        d="M12 22.7501C9.31002 22.7501 6.74002 21.7501 4.75002 19.9301C4.57002 19.7701 4.49002 19.5301 4.51002 19.3001C4.64002 18.1101 5.38002 17.0001 6.61002 16.1801C9.59002 14.2001 14.42 14.2001 17.39 16.1801C18.62 17.0101 19.36 18.1101 19.49 19.3001C19.52 19.5401 19.43 19.7701 19.25 19.9301C17.26 21.7501 14.69 22.7501 12 22.7501ZM6.08002 19.1001C7.74002 20.4901 9.83002 21.2501 12 21.2501C14.17 21.2501 16.26 20.4901 17.92 19.1001C17.74 18.4901 17.26 17.9001 16.55 17.4201C14.09 15.7801 9.92002 15.7801 7.44002 17.4201C6.73002 17.9001 6.26002 18.4901 6.08002 19.1001Z"
        fill={props.active ? '#FFF' : '#8E8E93'}
      />
      <path
        d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z"
        fill={props.active ? '#FFF' : '#8E8E93'}
      />
    </svg>
  );
};

export default ProfileCircle;
