import { METADATA_TYPE } from './accounts';
import { DATA_PAGINATION, OptionItem } from './global';
import { NANNY_BOOKING_TYPE } from './nannyBooking';
import { GET_PAYMENT_PARAMS_REQUEST, PAYMENT_TYPE } from './payment';
import {
  GET_TRANSACTIONS_PARAMS_REQUEST,
  TRANSACTION_TYPE,
} from './transactions';

export enum STATUS_NANNY {
  PENDING = 'PENDING',
  VERIFIED = 'VERIFIED',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DELETED = 'DELETED',
}

export const colorStatus = {
  [STATUS_NANNY.ACTIVE]: 'green.light',
  [STATUS_NANNY.DELETED]: 'red.light',
  [STATUS_NANNY.INACTIVE]: 'gray.light',
  [STATUS_NANNY.PENDING]: 'yellow.light',
  [STATUS_NANNY.PENDING_APPROVAL]: 'yellow.light',
  [STATUS_NANNY.VERIFIED]: 'green.light',
};

export const nameStatus = {
  [STATUS_NANNY.ACTIVE]: 'Active',
  [STATUS_NANNY.DELETED]: 'Delete',
  [STATUS_NANNY.INACTIVE]: 'Inactive',
  [STATUS_NANNY.PENDING]: 'Pending',
  [STATUS_NANNY.PENDING_APPROVAL]: 'Pending approval',
  [STATUS_NANNY.VERIFIED]: 'Verified',
};

export const nannyStatuses: OptionItem[] = [
  {
    id: STATUS_NANNY.ACTIVE,
    label: nameStatus[STATUS_NANNY.ACTIVE],
    color: colorStatus[STATUS_NANNY.ACTIVE],
  },
  {
    id: STATUS_NANNY.INACTIVE,
    label: nameStatus[STATUS_NANNY.INACTIVE],
    color: colorStatus[STATUS_NANNY.INACTIVE],
  },
  {
    id: STATUS_NANNY.PENDING,
    label: nameStatus[STATUS_NANNY.PENDING],
    color: colorStatus[STATUS_NANNY.PENDING],
  },
  {
    id: STATUS_NANNY.VERIFIED,
    label: nameStatus[STATUS_NANNY.VERIFIED],
    color: colorStatus[STATUS_NANNY.VERIFIED],
  },
  {
    id: STATUS_NANNY.DELETED,
    label: nameStatus[STATUS_NANNY.DELETED],
    color: colorStatus[STATUS_NANNY.DELETED],
  },
  {
    id: STATUS_NANNY.PENDING_APPROVAL,
    label: nameStatus[STATUS_NANNY.PENDING_APPROVAL],
    color: colorStatus[STATUS_NANNY.PENDING_APPROVAL],
  },
];

export interface GET_NANNY_PARAMS_REQUEST {
  pageNum?: number;
  pageSize?: number;
  status?: OptionItem;
  sort?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  keyword?: string;
}

export interface GET_NANNY_TAB_PARAMS_REQUEST {
  pageNum?: number;
  pageSize?: number;
  nannyId?: string;
  includeDeleted?: boolean;
}

export interface NANNY_TYPE {
  nannyId: string;
  firstName: string;
  lastName: string;
  bio: any;
  averageStars: number;
  email: string;
  emailVerified: boolean;
  emailCode: any;
  countryCode: string;
  phoneNumber: string;
  phoneNumberVerified: boolean;
  dateOfBirth: string;
  profilePicture: any;
  startOfNannyExperience: any;
  ethnicity: any;
  nationality: any;
  status: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: any;
  yearsOfExperience: string | null;
}

export interface NANNY_LIST_STATE extends DATA_PAGINATION<NANNY_TYPE> {
  params?: GET_NANNY_PARAMS_REQUEST | null;
}

export interface PICTURE_TYPE {
  id: string;
  nannyId: string;
  imageUrl: string;
  caption: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: any;
}

export interface BOOKING_TYPE {
  id: string;
  nannyId: string;
  nannyAskingAmount: number;
  commission: number;
  status: string;
  nannyBooking: NANNY_BOOKING_TYPE;
  createdAt: string;
  updatedAt: string;
  deletedAt: any;
}

export interface TESTIMONIAL_TYPE {
  id: string;
  nannyId: string;
  parentId: string;
  parentFirstName: string;
  parentProfilePicture: string;
  bookingId: any;
  stars: number;
  content: string;
  createdAt: string;
  updatedAt: string;
  anonymous: boolean;
}

export interface CERTIFICATE_ASSOCIATION_TYPE {
  certificateAssociationId: string;
  nannyId: string;
  title: string;
  icon: string;
  documentUrl: string;
  documentDescription: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: any;
}

export interface CERTIFICATE_TYPE {
  id: string;
  title: string;
  icon: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: any;
}

export interface ACTIVITY_TYPE {
  id: string;
  type: string;
  description: string;
  amount: any;
  bookingSummary: BOOKING_SUMMARY;
  createdAt: string;
  updatedAt: string;
}

export interface BOOKING_SUMMARY {
  bookingId: string;
  parentId: string;
  parentFirstName: string;
  edd: string;
}

export interface GET_ACTIVITY_TAB_PARAMS_REQUEST {
  pageNum?: number;
  pageSize?: number;
  nannyId?: string;
  parentId?: string;
  showDeleted?: boolean;
  activityType?: string;
  keyword?: string;
}

export interface PICTURE_TAB_TYPE extends DATA_PAGINATION<PICTURE_TYPE> {
  params?: GET_NANNY_TAB_PARAMS_REQUEST | null;
}

export interface BOOKING_TAB_TYPE extends DATA_PAGINATION<BOOKING_TYPE> {
  params?: GET_NANNY_TAB_PARAMS_REQUEST | null;
}

export interface TESTIMONIAL_TAB_TYPE
  extends DATA_PAGINATION<TESTIMONIAL_TYPE> {
  params?: GET_NANNY_TAB_PARAMS_REQUEST | null;
}

export interface CERTIFICATE_TAB_TYPE
  extends DATA_PAGINATION<CERTIFICATE_ASSOCIATION_TYPE> {
  params?: GET_NANNY_TAB_PARAMS_REQUEST | null;
}

export interface ACTIVITY_TAB_TYPE extends DATA_PAGINATION<ACTIVITY_TYPE> {
  params?: GET_ACTIVITY_TAB_PARAMS_REQUEST | null;
}

export interface NANNY_DETAIL_TAB_STATE {
  bookings: BOOKING_TAB_TYPE;
  transactions: {
    records: TRANSACTION_TYPE[];
    metadata: METADATA_TYPE;
    params: GET_TRANSACTIONS_PARAMS_REQUEST | null;
  };
  pendingPayout: any;
  paymentHistory: {
    records: PAYMENT_TYPE[];
    metadata: METADATA_TYPE;
    params: GET_PAYMENT_PARAMS_REQUEST;
  };
  pictures: PICTURE_TAB_TYPE;
  certificate: CERTIFICATE_TAB_TYPE;
  testimonials: TESTIMONIAL_TAB_TYPE;
  activity: ACTIVITY_TAB_TYPE;
}

export interface NANNY_DETAIL_TYPE {
  nannyId: string;
  firstName: string;
  lastName: string;
  bio: string;
  averageStars: number;
  email: string;
  emailVerified: boolean;
  emailCode: any;
  countryCode: string;
  phoneNumber: string;
  phoneNumberVerified: boolean;
  dateOfBirth: string;
  profilePicture: any;
  startOfNannyExperience: string;
  ethnicity: string;
  nationality: string;
  status: string;
  nannyBankDetails: NannyBankDetail[];
  createdAt: string;
  updatedAt: string;
  deletedAt: any;
}

export interface NannyBankDetail {
  id: string;
  nannyId: string;
  bankName: any;
  bankAccountNo: string;
  bankAccountHolderName: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: any;
}

export interface UPDATE_NANNY_TYPE {
  nannyId: string;
  bio?: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  startOfNannyExperience: string;
  ethnicity: string;
  nationality: string;
  bankName?: string;
  bankAccountNo?: string;
  bankAccountHolderName?: string;
  password: string;
  countryCode: string;
  number: string;
  email?: string;
  status: string;
}

export interface CREATE_NANNY_TYPE {
  bio?: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  startOfNannyExperience: string;
  ethnicity: string;
  nationality: string;
  countryCode: string;
  number: string;
  password: string;
  email?: string;
}

export interface UPDATE_NANNY_PICTURE_TYPE {
  id: string;
  caption: string;
  file: any;
}

export interface CREATE_NANNY_TESTIMONIAL_TYPE {
  nannyId: string;
  parentId: string | null;
  bookingId: string | null;
  stars: number;
  content: string;
  isAnonymous: boolean;
}

export interface UPDATE_NANNY_TESTIMONIAL_TYPE {
  testimonialId: string;
  stars: number;
  content: string;
  isAnonymous: boolean;
}

export interface UPDATE_NANNY_CERTIFICATE_ASSOCIATION_TYPE {
  documentDescription: string;
  document: any;
  certificateId: string;
  nannyId: string;
}

export interface UPDATE_NANNY_CERTIFICATE_TYPE {
  title: string;
  icon: any;
}
