import { HeadCell, JOBS_TYPE } from 'models';

export const headCells: readonly HeadCell<JOBS_TYPE>[] = [
  {
    id: 'number',
    numeric: false,
    disablePadding: true,
    isSort: true,
    label: 'Job No.',
  },
  {
    id: 'start_datetime',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Date',
  },
  {
    id: 'address',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Short Address',
  },

  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Status',
  },
  {
    id: 'total',
    numeric: true,
    disablePadding: false,
    isSort: true,
    label: 'Total Amount',
  },
];
