import {
  FilterCurrentValue,
  FilterOption,
  FilterOptionType,
  FilterValue,
  ValueType,
} from 'models';
import { memo, useEffect, useMemo, useState } from 'react';
import { Filter, FilteredIcon } from 'asset';
import {
  Box,
  Button,
  Menu,
  useTheme,
  Typography,
  FormControlLabel,
  Checkbox,
  buttonClasses,
  Collapse,
  InputBase,
  Stack,
} from '@mui/material';
import Select, { GetOptionsResponse } from 'components/form-control/Selects';
import _ from 'lodash';
import { DateTimePicker } from 'components/form-control';
import { useDateTime } from 'hooks';

interface Props {
  filterOptions: FilterOption[];
  filterValue: FilterValue;
  onChange: (filterValue: FilterValue) => void;
  fetchDataOption?: (
    key: string,
    keyword?: string,
    limit?: number,
    page?: number
  ) => Promise<GetOptionsResponse | null | undefined> | null | undefined;
}

const FilterMenu = memo(
  ({ filterOptions, filterValue, onChange, fetchDataOption }: Props) => {
    const theme = useTheme();
    const { moment } = useDateTime();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [currentValue, setCurrentValue] = useState<FilterCurrentValue>({});

    const isValidData = (type: FilterOptionType, value: ValueType) => {
      switch (type) {
        case FilterOptionType.TEXT:
          return !!(value as string);
        case FilterOptionType.SINGLE_SELECT:
          return !!value;
        case FilterOptionType.DATE_PICKER:
          return !!value;
        case FilterOptionType.SINGLE_CHECKBOX:
          return !!value;
      }
    };

    const convertData = () => {
      const _currentValue: FilterCurrentValue = {};
      Object.keys(filterValue || {}).forEach((key) => {
        const item = filterOptions?.find((it) => it.key === key);
        if (item && isValidData(item.type, filterValue[key])) {
          _currentValue[key] = {
            ...item,
            value: filterValue[key],
          };
        }
      });
      return _currentValue;
    };

    useEffect(() => {
      const _currentValue = convertData();
      setCurrentValue(_currentValue);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValue, filterOptions]);

    const handleOpenFilter = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleCloseFilter = () => {
      setAnchorEl(null);
    };

    const initOption = (type: FilterOptionType) => {
      switch (type) {
        case FilterOptionType.TEXT:
          return '';
        case FilterOptionType.SINGLE_SELECT:
          return null;
        case FilterOptionType.DATE_PICKER:
          return null;
        case FilterOptionType.SINGLE_CHECKBOX:
          return true;
      }
    };

    const onToggleFilterControl = (item: FilterOption) => {
      let _currentValue = { ...currentValue };
      if (currentValue[item.key]) {
        delete _currentValue[item.key];
      } else {
        _currentValue[item.key] = {
          ...item,
          value: initOption(item.type),
        };
      }
      setCurrentValue(_currentValue);
    };

    const renderOption = (item: FilterOption) => {
      switch (item.type) {
        case FilterOptionType.TEXT:
          return (
            <InputBase
              sx={{
                color: '#000',
                fontSize: '13px',
                lineHeight: '100%',
                border: '1px solid #D5D5D5',
                width: '100%',
                height: '38px',
                background: '#fff',
                borderRadius: '5px',
                padding: '13px 10px',
              }}
              value={currentValue[item.key]?.value ?? ''}
              onChange={(e) => {
                const _currentValue = { ...currentValue };
                _currentValue[item.key].value = e.target.value;
                setCurrentValue(_currentValue);
              }}
            />
          );
        case FilterOptionType.SINGLE_SELECT:
          return (
            <Select
              name={item.key}
              isHasMore
              filterFunc={false}
              value={currentValue[item.key]?.value}
              onChange={(newValue: any) => {
                const _currentValue = { ...currentValue };
                _currentValue[item.key].value = newValue;
                setCurrentValue(_currentValue);
              }}
              getOptions={(page, limit, keyword) =>
                fetchDataOption &&
                fetchDataOption(item.key, keyword, limit || 20, page || 1)
              }
            />
          );
        case FilterOptionType.DATE_PICKER:
          return (
            <DateTimePicker
              name={item.key}
              value={currentValue[item.key]?.value ?? null}
              onChange={(value) => {
                const _currentValue = { ...currentValue };
                _currentValue[item.key].value = value
                  ? moment(value).toISOString()
                  : '';
                setCurrentValue(_currentValue);
              }}
            />
          );
      }
    };

    const getFilterValue = () => {
      const _filterValue: FilterValue = {};
      Object.keys(currentValue).forEach((key) => {
        if (
          currentValue[key] &&
          isValidData(currentValue[key].type, currentValue[key].value)
        ) {
          _filterValue[key] = currentValue[key].value;
        }
      });
      return _filterValue;
    };

    const isValidShow = useMemo(() => {
      const data = getFilterValue();
      return Object.keys(data)?.length;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentValue]);

    const _onChange = () => {
      const data = getFilterValue();
      if (!isValidShow || _.isEqual(data, filterValue)) return;
      onChange(data);
      handleCloseFilter();
    };

    const onClearAll = () => {
      const _currentValue = convertData();
      setCurrentValue({});
      if (_.isEmpty(_currentValue)) return;
      onChange({});
    };

    const numberFilter = useMemo(() => {
      return Object.keys(currentValue).length;
    }, [currentValue]);

    return (
      <>
        <Button
          sx={{
            background: 'rgba(34, 111, 227, 0.08)',
            border: ' 1px solid #D9D9D9',
            borderRadius: '5px',
            boxShadow: 'none',

            color: 'secondary.main',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '100%',
            textTransform: 'none',

            p: '0 16px 10px',
            height: '41px',
            alignItems: 'flex-end',
            ':hover': {
              background: 'rgba(34, 111, 227, 0.08)',
              border: `1px solid ${theme.palette.secondary.main}`,
              borderRadius: '5px',
              boxShadow: 'none',
            },
            [`& .${buttonClasses.startIcon}`]: {
              mr: '3px',
            },
          }}
          variant="contained"
          startIcon={numberFilter ? <FilteredIcon /> : <Filter />}
          onClick={handleOpenFilter}
        >
          Filter {!!numberFilter && `(${numberFilter})`}
        </Button>
        <Menu
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseFilter}
          sx={{
            '& .MuiList-padding': {
              pt: '0px !important',
              pb: '0px !important',
            },
          }}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Box
            sx={{
              width: '319px',
              p: '14px 22px 25px 23px',
              flexDirection: 'column',
              alignItems: 'flex-start',
              ':hover': {
                backgroundColor: 'white.light',
              },
            }}
          >
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '16px',
                lineHeight: '100%',
                mb: '20px',
              }}
            >
              Filter
            </Typography>
            <Box>
              {filterOptions.map((item) => {
                return (
                  <Box key={item.key}>
                    <FormControlLabel
                      sx={{
                        [`&.MuiFormControlLabel-root`]: {
                          height: '35px',
                        },
                      }}
                      control={
                        <Checkbox
                          checked={!!currentValue[item.key]}
                          onChange={() => {
                            onToggleFilterControl(item);
                          }}
                          sx={{
                            '&.Mui-checked': {
                              color: 'secondary.main',
                            },
                            ':hover': {
                              backgroundColor: 'white.light',
                            },
                          }}
                        />
                      }
                      label={item.name}
                    />
                    <Collapse in={!!currentValue[item.key]}>
                      {renderOption(item)}
                    </Collapse>
                  </Box>
                );
              })}
            </Box>
            <Stack mt={'20px'} direction="row">
              <Button
                disabled={!isValidShow}
                onClick={_onChange}
                variant="contained"
                color="secondary"
                sx={{
                  height: 30,
                  width: '100%',
                  fontWeight: 600,
                  fontSize: '14px',
                  padding: 0,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                Show
              </Button>
              <Button
                onClick={onClearAll}
                variant="outlined"
                color="secondary"
                sx={{
                  ml: 2,
                  height: 30,
                  width: '100%',
                  fontWeight: 600,
                  fontSize: '14px',
                  border: `1px solid ${theme.palette.secondary.main}`,
                }}
              >
                Clear All
              </Button>
            </Stack>
          </Box>
        </Menu>
      </>
    );
  }
);

export default FilterMenu;
