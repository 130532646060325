import { HeadCell, BADGES_TYPE } from 'models';

export const headCells: readonly HeadCell<BADGES_TYPE>[] = [
  {
    id: 'title',
    numeric: false,
    disablePadding: true,
    isSort: false,
    label: 'Badge',
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: true,
    isSort: false,
    label: 'Created Date',
  },
];
