import {
  AppBar,
  Button,
  Checkbox,
  Grid,
  InputAdornment,
  InputBase,
  Menu,
  Paper,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Toolbar,
  Typography,
  buttonClasses,
  inputBaseClasses,
  useTheme,
} from '@mui/material';
import usePagination from '@mui/material/usePagination/usePagination';
import {
  CloseCircle,
  DirectionDown,
  DocumentDownload,
  Eye,
  Magnifier,
} from 'asset';
import {
  DeleteDialog,
  FilterMenu,
  HeadTable,
  Layout,
  PopupConfirm,
  PopupCreateOrEditNannyBooking,
  TableCaption,
} from 'components';
import DeleteAllDialog from 'components/common/DeleteAllDialog';
import { ButtonDelete, ButtonEdit } from 'components/common/table';
import { GetOptionsResponse } from 'components/form-control/Select';
import { drawerWidth } from 'config';
import { NotifyService, getSortItem } from 'helpers';
import { useDateTime } from 'hooks';
import useDebounce from 'hooks/useDebounce';
import {
  DELETE_DIALOG_TYPE,
  FilterOption,
  FilterOptionType,
  FilterValue,
  GET_NANNY_BOOKING_LIST_PARAMS_REQUEST,
  NANNY_BOOKING_TYPE,
  POPUP_ACTION,
  TYPE_POPUP,
  bookingNannyStatusColor,
  bookingNannyStatusTitle,
  bookingNannyStatuses,
  bookingParentStatusColor,
  bookingParentStatusTitle,
  bookingParentStatuses,
} from 'models';
import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { nannyBookingApi } from 'services';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setNannyBookingListParams } from 'store/reducers/nanny-bookings';
import { getNannyBookingListAction } from 'store/reducers/nanny-bookings/actionTypes';
import { headCells } from './data';

const filterOptions: FilterOption[] = [
  {
    name: 'Parent Status',
    key: 'parentStatus',
    type: FilterOptionType.SINGLE_SELECT,
  },
  {
    name: 'Nanny Status',
    key: 'nannyStatus',
    type: FilterOptionType.SINGLE_SELECT,
  },
  {
    name: 'Parent First Name',
    key: 'parentFirstName',
    type: FilterOptionType.TEXT,
  },
  {
    name: 'Parent Last Name',
    key: 'parentLastName',
    type: FilterOptionType.TEXT,
  },
  {
    name: 'Expected Due Date',
    key: 'edd',
    type: FilterOptionType.DATE_PICKER,
  },
];

export const ListNannyBooking = () => {
  const theme = useTheme();
  const { moment, formatDateTime } = useDateTime();
  const dispatch = useAppDispatch();
  const { booking_list } = useAppSelector(
    (state) => state.nannyBookingsReducer
  );
  const { params } = booking_list;

  const [selected, setSelected] = useState<readonly string[]>([]);
  const [keyword, setKeyword] = useState('');
  const [popupAction, setPopupAction] =
    useState<POPUP_ACTION<NANNY_BOOKING_TYPE>>();
  const [anchorElAction, setAnchorElAction] = useState<null | HTMLElement>(
    null
  );

  const onSetListParams = (
    params: GET_NANNY_BOOKING_LIST_PARAMS_REQUEST | null
  ) => {
    dispatch(setNannyBookingListParams(params));
  };

  useEffect(() => {
    dispatch(getNannyBookingListAction(params));
  }, [params, dispatch]);

  useEffect(() => {
    setKeyword(params?.keyword ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onShowPopupAction = (
    event: any,
    type: TYPE_POPUP,
    data?: NANNY_BOOKING_TYPE
  ) => {
    event?.stopPropagation();
    setPopupAction({ type: type, data });
  };

  const onClosePopupAction = () => {
    setPopupAction(undefined);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = booking_list.data.map((n) => n.bookingId);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleOpenBulkAction = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElAction(event.currentTarget);
  };

  const handleClick = (_: any, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangeRowsPerPage = (event: SelectChangeEvent) => {
    const pageSize = parseInt(event.target.value, 10);
    onSetListParams({ ...params, pageNum: 1, pageSize });
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const { items } = usePagination({
    count: booking_list.totalPages,
    page: booking_list.pageNum,
    onChange(_event, pageNum) {
      onSetListParams({ ...params, pageNum });
    },
  });

  const onSetKeywordParams = useDebounce((keyword: string) => {
    const _params = { ...params, page: 1 };
    if (keyword) _params.keyword = keyword;
    else delete _params.keyword;
    onSetListParams(_params);
  }, 250);

  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(event.target.value);
    onSetKeywordParams(event.target.value);
  };

  const onReloadList = () => {
    if (!params) return;
    dispatch(getNannyBookingListAction(params));
  };

  const deleteBulk = async () => {
    const ids: any = selected;
    await nannyBookingApi
      .deleteBulk(ids)
      .then((r) => {
        onReloadList();
        onClosePopupAction();
        setSelected([]);
        NotifyService.success(r);
      })
      .catch((e) => NotifyService.error(e))
      .finally(() => {});
  };

  const onCancelBulk = async () => {
    const ids: any = selected;
    await nannyBookingApi
      .cancelBulk(ids)
      .then((r) => {
        onReloadList();
        onClosePopupAction();
        setSelected([]);
        NotifyService.success(r);
      })
      .catch((e) => NotifyService.error(e))
      .finally(() => {});
  };

  const filterValue: FilterValue = useMemo(() => {
    return {
      parentStatus: booking_list.params?.parentStatus,
      nannyStatus: booking_list.params?.nannyStatus,
      parentFirstName: booking_list.params?.parentFirstName,
      parentLastName: booking_list.params?.parentLastName,
      edd: booking_list.params?.edd,
    };
  }, [booking_list.params]);

  const onChangeFilter = ({ edd, ...value }: FilterValue) => {
    setSelected([]);
    const _params = { ...params };
    delete _params.parentStatus;
    delete _params.nannyStatus;
    delete _params.parentFirstName;
    delete _params.parentLastName;
    delete _params.edd;
    onSetListParams({
      ..._params,
      ...value,
      edd: edd ? moment(edd as string).format('DD-MM-YYYY') : undefined,
      pageNum: 1,
    });
  };

  const sortItem = useMemo(() => {
    return getSortItem(booking_list.params?.sort);
  }, [booking_list.params]);

  const handleRequestSort = (
    _: React.MouseEvent<unknown>,
    property: keyof NANNY_BOOKING_TYPE
  ) => {
    setSelected([]);
    const isAsc = sortItem?.orderBy === property && sortItem?.order === 'asc';
    onSetListParams({
      ...params,
      sort: `${property}-${isAsc ? 'desc' : 'asc'}`,
    });
  };

  const fetchDataOption = async (
    key: string,
    keyword?: string,
    limit: number = 20,
    page: number = 1
  ) => {
    let data: GetOptionsResponse | null = null;
    switch (key) {
      case 'nannyStatus':
        let nannyStatuses = bookingNannyStatuses;
        if (keyword) {
          nannyStatuses = nannyStatuses.filter((t) =>
            t.label?.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())
          );
        }
        data = {
          metadata: {
            page: page,
            limit: limit,
            page_count: nannyStatuses.length,
            total_pages: 1,
            total_count: nannyStatuses.length,
          },
          options: nannyStatuses,
        };
        break;
      case 'parentStatus':
        let parentStatuses = bookingParentStatuses;
        if (keyword) {
          parentStatuses = parentStatuses.filter((t) =>
            t.label?.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())
          );
        }
        data = {
          metadata: {
            page: page,
            limit: limit,
            page_count: parentStatuses.length,
            total_pages: 1,
            total_count: parentStatuses.length,
          },
          options: parentStatuses,
        };
        break;
    }
    return data;
  };

  return (
    <Layout>
      <AppBar
        position="fixed"
        sx={{
          width: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`,
          backgroundColor: 'white.light',
          borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
        }}
        elevation={0}
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Grid>
            <Typography
              sx={{
                fontSize: '20px',
                lineHeight: 1,
                color: 'grey1.light',
              }}
            >
              Nanny Bookings
            </Typography>
          </Grid>
          <Grid display="flex" gap="8px" flexGrow={1} justifyContent="center">
            <InputBase
              placeholder="Search"
              sx={{
                maxWidth: '369px',
                flexGrow: 1,
                // paddingRight: '9px',
                background: '#F2F4F6',
                border: '1px solid #D9D9D9',
                p: '0px 7px 0px 11px',
                borderRadius: '5px',
                [`& .${inputBaseClasses.input}`]: {
                  fontSize: 14,
                  fontWeight: 400,
                  lineHeight: '100%',
                  p: 0,
                  height: 'fit-content',
                },
              }}
              onChange={onChangeSearch}
              // autoComplete=""
              value={keyword}
              endAdornment={
                <InputAdornment position="end">
                  <Magnifier />
                </InputAdornment>
              }
            />
            <FilterMenu
              filterOptions={filterOptions}
              filterValue={filterValue}
              onChange={onChangeFilter}
              fetchDataOption={fetchDataOption}
            />
          </Grid>
          <Grid display="flex" gap="8px">
            <Button
              sx={{
                background: '#fff',
                border: `1px solid #226FE3`,
                borderRadius: '3px',
                boxShadow: 'none',

                color: '#226FE3',
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '100%',
                textTransform: 'none',

                p: '6px 16px',
                height: '30px',
                alignItems: 'flex-end',
                ':hover': {
                  background: 'rgba(34, 111, 227, 0.08)',
                  border: `1px solid ${theme.palette.secondary.main}`,
                  borderRadius: '3px',
                  boxShadow: 'none',
                },
                [`& .${buttonClasses.endIcon}`]: {
                  mb: '-3px',
                  ml: '3px',
                },
              }}
              variant="contained"
              endIcon={<DirectionDown />}
              onClick={handleOpenBulkAction}
            >
              Bulk Action
            </Button>
            <Menu
              sx={{
                mt: '45px',
                '& .MuiList-padding': {
                  pt: '0px !important',
                  pb: '0px !important',
                },
              }}
              id="menu-appbar"
              anchorEl={anchorElAction}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElAction)}
              onClose={() => setAnchorElAction(null)}
            >
              <Stack
                sx={{
                  width: '319px',
                  p: '14px 22px 25px 23px',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  ':hover': {
                    backgroundColor: 'white.light',
                  },
                }}
              >
                <Stack spacing="10px">
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: '16px',
                      lineHeight: '100%',
                    }}
                  >
                    Bulk Actions
                  </Typography>
                  <Button
                    startIcon={
                      <CloseCircle
                        width={15}
                        heigh={15}
                        color={theme.palette.secondary.main}
                      />
                    }
                    variant="outlined"
                    color="secondary"
                    sx={{
                      p: 0,
                      px: '15px',
                      fontWeight: 600,
                      fontSize: '15px',
                      lineHeight: 1,
                      height: '32px',
                      borderRadius: '10px',
                      '& .MuiButton-startIcon': {
                        mr: '5px',
                      },
                    }}
                    onClick={() =>
                      onShowPopupAction(null, TYPE_POPUP.DELETE_ALL)
                    }
                  >
                    Delete Selected Bookings
                  </Button>
                  <Button
                    startIcon={
                      <CloseCircle
                        width={15}
                        heigh={15}
                        color={theme.palette.secondary.main}
                      />
                    }
                    variant="outlined"
                    color="secondary"
                    sx={{
                      p: 0,
                      px: '15px',
                      fontWeight: 600,
                      fontSize: '15px',
                      lineHeight: 1,
                      height: '32px',
                      borderRadius: '10px',
                      '& .MuiButton-startIcon': {
                        mr: '5px',
                      },
                    }}
                    onClick={() =>
                      onShowPopupAction(null, TYPE_POPUP.CANCEL_ALL)
                    }
                  >
                    Cancel Selected Bookings
                  </Button>
                </Stack>
              </Stack>
            </Menu>
            <Button
              sx={{
                width: '131px',
                height: 30,
                fontWeight: 600,
                fontSize: '13px',
                border: `1px solid ${theme.palette.secondary.main}`,
                p: '0px',
              }}
              variant="outlined"
              color="secondary"
              endIcon={<DocumentDownload />}
              // onClick={onExportCSV}
            >
              Export to CSV
            </Button>
            <Button
              variant="contained"
              color="secondary"
              sx={{
                height: 30,
                width: '72px',
                fontWeight: 600,
                fontSize: '13px',
                lineHeight: '100%',
              }}
              onClick={() => onShowPopupAction(null, TYPE_POPUP.CREATE)}
            >
              Create
            </Button>
          </Grid>
        </Toolbar>
      </AppBar>
      <Stack spacing="8px">
        <TableContainer
          component={Paper}
          elevation={0}
          sx={{
            border: '1px solid #D9D9D9',
            maxHeight: 'calc(100vh - 150px)',
          }}
        >
          <Table
            sx={{ minWidth: 650 }}
            aria-labelledby="tableTitle"
            stickyHeader
          >
            <HeadTable<NANNY_BOOKING_TYPE>
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={booking_list.data.length}
              headCells={headCells}
              onRequestSort={handleRequestSort}
            />
            <TableBody
              sx={{
                height: 'auto !important',
              }}
            >
              {booking_list.data.map((row, index) => {
                const isItemSelected = isSelected(row.bookingId);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.bookingId}
                    selected={isItemSelected}
                  >
                    <TableCell
                      padding="checkbox"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                      }}
                    >
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        onChange={(e) => handleClick(e, row.bookingId)}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                      }}
                    >
                      {row.bookingId}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                      }}
                    >
                      {row.parentName}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                      }}
                    >
                      {formatDateTime(row.edd, 'DD MMM YYYY', 'DD-MM-YYYY')}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                      }}
                    >
                      {row.singleTwinPregnancy}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                      }}
                    >
                      {row.houseType}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                      }}
                    >
                      {row.duration}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                        minWidth: '110px',
                      }}
                    >
                      <Grid
                        container
                        flexWrap="nowrap"
                        alignItems="center"
                        textTransform="capitalize"
                      >
                        <Grid
                          sx={{
                            width: '8px',
                            height: '8px',
                            minWidth: '8px',
                            borderRadius: '50%',
                            marginRight: '5px',
                            backgroundColor:
                              bookingParentStatusColor[row.parentStatus] ||
                              '#B6B6B6',
                          }}
                        />
                        <Typography>
                          {bookingParentStatusTitle[row.parentStatus] || '-'}
                        </Typography>
                      </Grid>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                        minWidth: '110px',
                      }}
                    >
                      <Grid
                        container
                        flexWrap="nowrap"
                        alignItems="center"
                        textTransform="capitalize"
                      >
                        <Grid
                          sx={{
                            width: '8px',
                            height: '8px',
                            minWidth: '8px',
                            borderRadius: '50%',
                            marginRight: '5px',
                            backgroundColor:
                              bookingNannyStatusColor[row.nannyStatus] ||
                              '#B6B6B6',
                          }}
                        />
                        <Typography>
                          {bookingNannyStatusTitle[row.nannyStatus] || '-'}
                        </Typography>
                      </Grid>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                      }}
                    >
                      {formatDateTime(row.createdAt, 'DD MMM YYYY')}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                      }}
                    >
                      <Stack direction="row" spacing="10px">
                        <Link to={`/nanny-bookings/${row.bookingId}`}>
                          <Button
                            sx={{
                              minWidth: '38px !important',
                              height: '32px',
                              '&.MuiButton-root': {
                                p: '0px !important',
                              },
                            }}
                            variant="contained"
                            color="secondary"
                          >
                            <Eye />
                          </Button>
                        </Link>
                        <ButtonEdit
                          onClick={(e) =>
                            onShowPopupAction(e, TYPE_POPUP.EDIT, row)
                          }
                        />
                        <ButtonDelete
                          onClick={(e) =>
                            onShowPopupAction(e, TYPE_POPUP.DELETE, row)
                          }
                        />
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TableCaption
          rowsPerPage={params?.pageSize ?? 0}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          metadata={{
            page: booking_list?.pageNum,
            limit: booking_list?.pageSize,
            page_count: booking_list?.data?.length || 0,
            total_pages: booking_list?.totalPages,
            total_count: booking_list?.totalSize,
          }}
          items={items}
        />
      </Stack>
      {popupAction?.type === TYPE_POPUP.DELETE && (
        <DeleteDialog
          open
          onClose={onClosePopupAction}
          subject={popupAction?.data?.bookingId}
          onSuccess={onReloadList}
          type={DELETE_DIALOG_TYPE.NANNY_BOOKING}
          name="booking"
        />
      )}
      {popupAction?.type === TYPE_POPUP.DELETE_ALL && (
        <DeleteAllDialog
          open
          onClose={onClosePopupAction}
          onClickDelete={deleteBulk}
        />
      )}

      {[TYPE_POPUP.CREATE, TYPE_POPUP.EDIT].includes(
        popupAction?.type as TYPE_POPUP
      ) && (
        <PopupCreateOrEditNannyBooking
          cleanDetail
          subject={popupAction?.data?.bookingId}
          onClose={onClosePopupAction}
          onSuccess={onReloadList}
        />
      )}
      {popupAction?.type === TYPE_POPUP.CANCEL_ALL && (
        <PopupConfirm
          open
          title="Cancel selected rows?"
          description="Are you sure you want to cancel these rows?"
          onClose={onClosePopupAction}
          onConfirm={onCancelBulk}
        />
      )}
    </Layout>
  );
};

export default ListNannyBooking;
