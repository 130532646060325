import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputLabel,
  Stack,
  Typography,
} from '@mui/material';
import { CloseCircle } from 'asset';
import { LoadingPopup } from 'components/common';
import { Select } from 'components/form-control';
import { GetOptionsResponse } from 'components/form-control/Select';
import yup from 'config/yup.custom';
import { NotifyService } from 'helpers';
import {
  GET_NANNY_PARAMS_REQUEST,
  NANNY_TYPE,
  OptionItem,
  STATUS_NANNY,
  nannyInBookingStatuses,
  nannyStatuses,
} from 'models';
import { memo, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { nannyBookingApi } from 'services';
import { nannyApi } from 'services/accounts/nanny';
import { useAppSelector } from 'store/hook';
import { NannyItem } from './NannyItem';

interface PopupActionNannyiesInBookingProps {
  bookingId: string;
  onClose: () => void;
  onSuccess: () => void;
}

export interface IFormInputs {
  nannies: {
    nannyId: OptionItem<string>;
    nannyAskingAmount: number | string;
    commission: number | string;
    nannyDeposit: number | string;
    status: OptionItem<string> | null;
  }[];
}

const defaultValues: Partial<IFormInputs> = {
  nannies: [],
};

const schema = yup.object().shape({
  nannies: yup.array(
    yup.object({
      nannyId: yup
        .mixed()
        .required('Nanny is required')
        .nullable(false)
        .typeError('Nanny is required'),
      nannyAskingAmount: yup
        .number()
        .typeError('Asking Amount is required')
        .min(0, 'Asking Amount must be greater than or equal to 0')
        .required('Asking Amount is required'),
      commission: yup
        .number()
        .typeError('Comission is required')
        .min(0, 'Comission must be greater than or equal to 0')
        .required('Comission is required'),
      nannyDeposit: yup
        .number()
        .typeError('Deposit is required')
        .min(0, 'Deposit must be greater than or equal to 0')
        .required('Deposit is required'),
      status: yup
        .mixed()
        .required('Status is required')
        .nullable(false)
        .typeError('Status is required'),
    })
  ),
});

export const PopupActionNannyiesInBooking = memo(
  ({ bookingId, onClose, onSuccess }: PopupActionNannyiesInBookingProps) => {
    const { nannies } = useAppSelector(
      (state) => state.nannyBookingsReducer.booking_detail_tab
    );
    const { loadingPopup } = useAppSelector((state) => state.globalReducer);

    const [loading, setLoading] = useState<boolean>(false);

    const form = useForm<IFormInputs>({
      resolver: yupResolver(schema),
      mode: 'onChange',
      defaultValues: defaultValues,
    });

    const { handleSubmit, control, getValues, reset } = form;

    const {
      fields: nannyFields,
      append: appendNanny,
      remove: removeNanny,
    } = useFieldArray({
      control,
      name: 'nannies',
    });

    useEffect(() => {
      reset({
        nannies:
          nannies?.data?.map((item) => ({
            nannyId: { id: item.nannyId, label: item.nannyName },
            nannyAskingAmount: item.nannyAskingAmount,
            commission: item.commission,
            nannyDeposit: item.nannyDeposit,
            status: nannyInBookingStatuses.find((t) => t.id === item.status),
          })) || [],
      });
    }, [nannies, reset]);

    const onSubmit = async (data: IFormInputs) => {
      setLoading(true);
      await nannyBookingApi
        .updateNannyInBooking({
          bookingId,
          availabilityList: data.nannies.map((item) => ({
            nannyId: item.nannyId.id,
            nannyAskingAmount: Number(item.nannyAskingAmount),
            commission: Number(item.commission),
            nannyDeposit: Number(item.nannyDeposit),
            status: item.status?.id || '',
          })),
        })
        .then(() => {
          NotifyService.success('Success');
          onSuccess();
          _onClose();
        })
        .catch((e) => NotifyService.error(e))
        .finally(() => {
          setLoading(false);
        });
    };

    const _onClose = () => {
      onClose?.();
    };

    const getNannyOptions = async (
      page: number = 1,
      _limit: number = 20,
      keyword?: string
    ) => {
      const params: GET_NANNY_PARAMS_REQUEST = {
        pageNum: page,
        pageSize: 20,
        status: nannyStatuses.find((t) => t.id === STATUS_NANNY.ACTIVE),
      };
      if (keyword) params.keyword = keyword;
      let data: GetOptionsResponse | null = await nannyApi
        .getAll(params)
        .then(({ data }) => {
          return {
            metadata: {
              page: data?.pageNum,
              limit: data?.pageSize,
              page_count: data?.data?.length || 0,
              total_pages: data?.totalPages,
              total_count: data?.totalSize,
            },
            options: data.data.map((it: NANNY_TYPE) => ({
              ...it,
              id: it.nannyId,
              label: `${it.firstName} ${it.lastName}`.trim(),
            })),
          };
        })
        .catch((e) => {
          NotifyService.error(e);
          return null;
        });

      return data;
    };

    const onAddNanny = (item: OptionItem) => {
      const data = getValues('nannies');
      const existed = data?.find((t) => t.nannyId?.id === item.id);
      if (existed) return;
      appendNanny({
        nannyId: item,
        nannyAskingAmount: '',
        commission: '',
        nannyDeposit: '',
        status: null,
      });
    };

    return (
      <Dialog
        open
        sx={{
          '& .MuiPaper-root': {
            width: 523,
            height: 'auto',
            borderRadius: 0,
          },
        }}
      >
        <DialogTitle
          sx={{
            padding: '20px 25px',
          }}
        >
          <Grid
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '16px',
                lineHeight: '100%',
              }}
            >
              Add/Remove Nanny
            </Typography>
            <IconButton
              onClick={_onClose}
              sx={{
                padding: 0,
              }}
            >
              <CloseCircle />
            </IconButton>
          </Grid>
        </DialogTitle>
        {(loadingPopup || loading) && <LoadingPopup color="primary" />}
        <DialogContent
          sx={{
            p: '30px 24px 50px 26px',
          }}
          dividers
        >
          <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
            <Stack height="100%" justifyContent="space-between">
              <Stack spacing="26px">
                <Grid>
                  <Select
                    title="Nanny"
                    placeholder="Search Nanny"
                    name="nanny"
                    isHasMore
                    value={null}
                    filterFunc={false}
                    getOptions={getNannyOptions}
                    onChange={(val: any) => onAddNanny(val)}
                  />
                </Grid>
                <Stack>
                  {!!nannyFields?.length && (
                    <InputLabel
                      sx={{
                        fontWeight: 500,
                        fontSize: '15px',
                        lineHeight: '100%',
                        color: '#000',
                        marginBottom: '11px',
                      }}
                    >
                      Selected nanny
                    </InputLabel>
                  )}
                  <Stack spacing={1}>
                    {nannyFields.map((nannyField, index) => (
                      <NannyItem
                        key={nannyField.id}
                        form={form}
                        index={index}
                        field={nannyField}
                        remove={removeNanny}
                      />
                    ))}
                  </Stack>
                </Stack>
              </Stack>
              <Typography
                sx={{
                  mt: 1,
                  fontWeight: 500,
                  fontSize: '10px',
                  lineHeight: '100%',
                  color: '#000',
                  marginBottom: '5px',
                }}
              >
                *Asking Amount is the total amount nanny is asking which
                includes the deposit. <br /> Deposit is the amount the nanny
                needs before confirming the booking*
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: '10px',
                  lineHeight: '100%',
                  color: '#000',
                  marginBottom: '0px',
                }}
              >
                **If booking is 'Matched', nannies to show parent should have
                status as 'Available'.
                <br /> If booking is 'Confirmed', only the chosen nanny should
                have status as 'Assigned', the rest should have 'Cancelled'.**
              </Typography>
              <Stack
                direction="row"
                justifyContent="space-between"
                marginTop="25px"
                spacing="8px"
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  sx={{
                    width: '233.5px',
                    height: '30px',
                  }}
                  onClick={_onClose}
                >
                  Cancel
                </Button>
                <Button
                  color="secondary"
                  variant="contained"
                  sx={{
                    width: '233.5px',
                    height: '30px',
                  }}
                  type="submit"
                >
                  Update
                </Button>
              </Stack>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    );
  }
);

export default PopupActionNannyiesInBooking;
