import {
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
} from '@mui/material';
import { TimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Calendar } from 'asset';
import React, { FC, Fragment, useState } from 'react';
import { Controller } from 'react-hook-form';

interface Props {
  title?: string;
  type?: string;
  name: string;
  form?: any;
  rules?: any;
  autoComplete?: string;
  placeholder?: string;
  showEyes?: boolean;
  showCount?: boolean;
  numberCount?: number;
  rows?: number;
  sx?: any;
  errorsName?: any;
  readOnly?: boolean;
  value?: any;
}

const TimerPicker: FC<Props> = ({
  type,
  title,
  readOnly,
  value,
  form,
  name,
  rules,
  placeholder,
  showEyes,
  showCount,
  numberCount,
  rows,
  sx,
  errorsName,
  ...rest
}) => {
  const {
    control,
    formState: { errors },
  } = form;
  const [openDate, setOpenDate] = useState<boolean>(false);

  return (
    <Fragment>
      <InputLabel
        sx={{
          fontWeight: 500,
          fontSize: '15px',
          lineHeight: '100%',
          color: '#000',
          marginBottom: '5px',
        }}
      >
        {title}
      </InputLabel>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field }) => (
            <TimePicker
              {...field}
              value={field.value ?? null}
              inputFormat="HH:mm"
              open={openDate}
              onOpen={() => setOpenDate(true)}
              onClose={() => setOpenDate(false)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          onClick={() => setOpenDate(true)}
                          sx={{
                            '&:hover': {
                              bgcolor: 'transparent',
                            },
                            '& .MuiTouchRipple-root': {
                              display: 'none',
                            },
                            position: 'absolute',
                            top: -5,
                            left: -16,
                            borderRadius: '0px',
                            cursor: 'pointer',
                            width: '230px',
                          }}
                        >
                          <Calendar color="#fff" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    background: '#fff',
                    borderRadius: '5px',
                    height: '38px',
                    p: '16px',
                    display: 'flex',
                    justifyContent: 'center',
                    border: '1px solid #D5D5D5',
                    '& >.MuiInput-root>.MuiInput-input': {
                      fontWeight: 400,
                      fontSize: '13px',
                      lineHeight: '100%',
                    },
                    '& >.MuiInput-root:before': { display: 'none' },
                    '& >.MuiInput-root:after': { display: 'none' },
                  }}
                  variant="standard"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              {...rest}
            />
          )}
        />
      </LocalizationProvider>

      {!!(errors as any)[name] ? (
        <FormHelperText
          error
          sx={{
            fontSize: '16px',
            letterSpacing: '0.045em',
            color: 'white.light',
          }}
        >
          {(errors as any)?.[name]?.message || ''}
        </FormHelperText>
      ) : (
        <FormHelperText
          error
          sx={{
            fontSize: '16px',
            letterSpacing: '0.045em',
            color: 'white.light',
          }}
        >
          {errorsName || ''}
        </FormHelperText>
      )}
    </Fragment>
  );
};

export default TimerPicker;
