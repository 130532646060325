import React from 'react';
import Login from './Login';

interface authProps {}

const Authentication = (props: authProps) => {
  return (
    <>
      <Login />
    </>
  );
};

export default Authentication;
