// import { IFormInputsChecklist } from './../../models/editSitter';
// import { SIGNIN_BODY_REQUEST } from 'models';
import { AxiosResponse } from 'axios';
import { API } from 'config';
import { BADGE_TYPE, GET_SITTERS_PARAMS_REQUEST, ICreateOrEditSitterData, SITTER_CHECKLIST_GROUP, SITTER_STATUS } from 'models';
import { AppAPIInstance } from 'services';

export const getSittersListApi = async (
  params?: GET_SITTERS_PARAMS_REQUEST
) => {
  return await AppAPIInstance.get('/admin/sitters', {
    params,
  });
};

export const getSitterDetailApi = async (id: string) => {
  return await AppAPIInstance.get(`/admin/sitters/${id}`);
};

export const getSitterChecklistApi = async (id: string): Promise<AxiosResponse<{ data: { check_list: SITTER_CHECKLIST_GROUP[] } }>> => {
  return await AppAPIInstance.get(`/admin/sitters/${id}/checklist`);
};

export const deleteSitterApi = async (id: string) => {
  return await AppAPIInstance.delete(`/admin/sitters/${id}`);
};

// export const deleteAllSitterApi = async (data: any) => {
//   return await AppAPIInstance.delete(`/admin/sitters`, data);
// };

export const deleteAllSitterApi = async (ids: string[]) => {
  const idsObj = {
    ids,
  };
  return await AppAPIInstance.delete('/admin/parents', {
    data: idsObj,
  });
};

export const createSitterApi = async (data: ICreateOrEditSitterData) => {
  return await AppAPIInstance.post(`/admin/sitters`, data);
};

export const editSitterApi = async (id: string, data: ICreateOrEditSitterData) => {
  return await AppAPIInstance.put(`/admin/sitters/${id}`, data);
};

export const editSitterChecklistApi = async (id: string, data?: any) => {
  return await AppAPIInstance.put(`/admin/sitters/${id}/checklist`, data);
};

export const updateSitterStatusApi = async (id: string, status: SITTER_STATUS) => {
  return await AppAPIInstance.put(`/admin/sitters/${id}/status`, {
    status
  });
};

export const updateManySitterStatusApi = async (data: { sitterIds: string[], status: SITTER_STATUS }) => {
  return await AppAPIInstance.put(`/admin/sitters/status`, data);
};

export const updateVideoApi = async (data: any) => {
  return await AppAPIInstance.post(`/admin/upload/video`, data);
};

export const getCheckListsApi = async (): Promise<AxiosResponse<{ data: SITTER_CHECKLIST_GROUP[] }>>=> {
  return await AppAPIInstance.get(`/admin/checklists`);
}

export const getSittersExportCSVApi = async (params?: Partial<GET_SITTERS_PARAMS_REQUEST>) => {
  return await AppAPIInstance.get(API.ADMIN.SITTER.EXPORT,
    {
      params,
      responseType: 'blob'
    }).catch(async (e) => {
      return Promise.reject(JSON.parse(await e?.response?.data?.text() || '{}')?.error);
    })
};

export const getDetailSitterBadgesApi = async (sitter_id?: string): Promise<AxiosResponse<{ data: BADGE_TYPE[] }>> => {
  return await AppAPIInstance.get(API.ADMIN.SITTER.BADGE.DEFAULT,
    {
      params: {
        id: sitter_id
      }
    })
};
