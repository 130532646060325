import { BOOKING_ACTIVITY_LOG, HeadCell } from 'models';

export const headCells: readonly HeadCell<BOOKING_ACTIVITY_LOG>[] = [
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Activity',
  },
  {
    id: 'amount',
    numeric: true,
    disablePadding: false,
    isSort: false,
    label: 'Amount',
  },
  {
    id: 'parent',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Parent',
  },
  {
    id: 'nannyList',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Nanny',
  },
  // {
  //   id: 'deletedAt',
  //   numeric: false,
  //   disablePadding: false,
  //   isSort: false,
  //   label: 'Status',
  // },
  {
    id: 'updatedAt',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Last Updated Time',
  },
];
