import { AxiosResponse } from 'axios';
import { API } from 'config';
import {
  CREATE_PAYMENT_BODY,
  EDIT_PAY_INFO_BODY,
  GET_PAYMENT_METHODS_PARAMS_REQUEST,
  GET_PAYMENT_PARAMS_REQUEST,
  GET_TXN_PAYOUT_PARAMS_REQUEST,
  PAYMENT_DETAIL_TYPE,
  UPDATE_MANY_PAYMENT_STATUS,
  UPDATE_PAYMENT_BODY,
  UPDATE_PAYMENT_STATUS,
} from 'models';
import { AppAPIInstance } from 'services';

export const getPaymentListApi = async (
  params?: GET_PAYMENT_PARAMS_REQUEST
) => {
  return await AppAPIInstance.get(API.ADMIN.PAYMENT.DEFAULT, { params });
};

export const deletePaymentApi = async (id: string) => {
  return await AppAPIInstance.delete(`${API.ADMIN.PAYMENT.DEFAULT}/${id}`);
};

export const deleteAllPaymentApi = async (ids: string[]) => {
  const idsObj = {
    ids,
  };
  return await AppAPIInstance.delete(API.ADMIN.PAYMENT.DEFAULT, {
    data: idsObj,
  });
};

export const getPaymentApi = async (
  id: string
): Promise<AxiosResponse<{ data: PAYMENT_DETAIL_TYPE }>> => {
  return await AppAPIInstance.get(`${API.ADMIN.PAYMENT.DEFAULT}/${id}`);
};

export const updatePaymentApi = async (
  id: string,
  data?: UPDATE_PAYMENT_BODY
) => {
  return await AppAPIInstance.put(`${API.ADMIN.PAYMENT.DEFAULT}/${id}`, data);
};

export const updatePaymentStatusApi = async (
  id: string,
  data?: UPDATE_PAYMENT_STATUS
) => {
  return await AppAPIInstance.put(
    API.ADMIN.PAYMENT.UPDATE_STATUS.replace(':id', id),
    data
  );
};

export const updateManyPaymentStatusApi = async (
  data: UPDATE_MANY_PAYMENT_STATUS
) => {
  return await AppAPIInstance.put(API.ADMIN.PAYMENT.UPDATE_MANY_STATUS, data);
};

export const createPaymentApi = async (data: CREATE_PAYMENT_BODY) => {
  return await AppAPIInstance.post(API.ADMIN.PAYMENT.DEFAULT, data);
};

export const getPaymentsExportCSVApi = async (
  params?: Partial<GET_PAYMENT_PARAMS_REQUEST>
) => {
  return await AppAPIInstance.get(API.ADMIN.PAYMENT.EXPORT, {
    params,
    responseType: 'blob',
  }).catch(async (e) => {
    return Promise.reject(
      JSON.parse((await e?.response?.data?.text()) || '{}')?.error
    );
  });
};

export const removeTransactionFromPayoutApi = async (data: any) => {
  return await AppAPIInstance.delete(API.ADMIN.PAYMENT.REMOVE_TXN, {
    data: data,
  });
};

export const addTransactionFromPayoutPaymentApi = async (data: any) => {
  return await AppAPIInstance.post(API.ADMIN.PAYMENT.ADD_TXN, data);
};

export const getTransactionFromPayoutListApi = async (
  params?: GET_TXN_PAYOUT_PARAMS_REQUEST
) => {
  return await AppAPIInstance.get(API.ADMIN.PAYMENT.LIST_TXN, { params });
};

export const generatePendingPayoutApi = async () => {
  return await AppAPIInstance.post(API.ADMIN.PAYMENT.GEN_PENDING);
};

export const getPaymentMethodsListApi = async (
  params?: GET_PAYMENT_METHODS_PARAMS_REQUEST
) => {
  return await AppAPIInstance.get(API.ADMIN.PAYMENT.PAYMENT_METHODS, {
    params,
  });
};

export const addPaymentInfoBySitterApi = async (data?: EDIT_PAY_INFO_BODY) => {
  return await AppAPIInstance.post(
    `${API.ADMIN.PAYMENT.PAYMENT_METHODS}`,
    data
  );
};

export const updatePaymentInfoBySitterApi = async (
  data?: EDIT_PAY_INFO_BODY,
  id?: string
) => {
  return await AppAPIInstance.put(
    `${API.ADMIN.PAYMENT.PAYMENT_METHODS}${id}`,
    data
  );
};
