import { API } from 'config';
import {
  BOOKING_ACTIVITY_LOG,
  CREATE_NANNY_BOOKING_ACTIVITY_LOG_REQUEST,
  CREATE_OR_UPDATE_NANNY_BOOKING_TYPE,
  DATA_PAGINATION,
  GET_NANNY_BOOKING_LIST_PARAMS_REQUEST,
  NANNY_BOOKING_DETAIL_TYPE,
  NANNY_BOOKING_TYPE,
  NANNY_IN_BOOKING_TYPE,
  ResponseType,
  UPDATE_NANNY_BOOKING_ACTIVITY_LOG_REQUEST,
  UPDATE_NANNY_IN_BOOKING_REQUEST,
} from 'models';
import { AppAPIInstanceNanny } from 'services';

export const nannyBookingApi = {
  getAll: async (params?: GET_NANNY_BOOKING_LIST_PARAMS_REQUEST) => {
    const _params = {
      ...params,
      parentStatus: params?.parentStatus?.id,
      nannyStatus: params?.nannyStatus?.id,
    };
    return await AppAPIInstanceNanny.get<DATA_PAGINATION<NANNY_BOOKING_TYPE>>(
      API.NANNY_BOOKING.DEFAULT,
      { params: _params }
    );
  },
  getDetail: async (id: string) => {
    return await AppAPIInstanceNanny.get<
      ResponseType<NANNY_BOOKING_DETAIL_TYPE>
    >(`${API.NANNY_BOOKING.DEFAULT}/${id}`);
  },
  create: async (data: CREATE_OR_UPDATE_NANNY_BOOKING_TYPE) => {
    return await AppAPIInstanceNanny.post(API.NANNY_BOOKING.DEFAULT, data);
  },
  update: async (id: string, data: CREATE_OR_UPDATE_NANNY_BOOKING_TYPE) => {
    return await AppAPIInstanceNanny.put(
      `${API.NANNY_BOOKING.DEFAULT}/${id}`,
      data
    );
  },
  delete: async (id: string) => {
    return await AppAPIInstanceNanny.delete(
      `${API.NANNY_BOOKING.DEFAULT}/${id}`
    );
  },
  deleteBulk: async (ids: string[]) => {
    return await AppAPIInstanceNanny.delete(API.NANNY_BOOKING.BULK_DELETE, {
      data: {
        bookingId: ids,
      },
    });
  },
  cancelBulk: async (ids: string[]) => {
    return await AppAPIInstanceNanny.put(API.NANNY_BOOKING.BULK_CANCEL, {
      bookingId: ids,
    });
  },
  getNanniesInBooking: async (bookingId: string) => {
    return await AppAPIInstanceNanny.get<ResponseType<NANNY_IN_BOOKING_TYPE[]>>(
      `${API.NANNY_BOOKING.NANNY.LIST}/${bookingId}`
    );
  },
  updateNannyInBooking: async (data: UPDATE_NANNY_IN_BOOKING_REQUEST) => {
    return await AppAPIInstanceNanny.put(API.NANNY_BOOKING.NANNY.UPDATE, data);
  },
  selectNannyInBooking: async (bookingId: string, nannyId: string) => {
    return await AppAPIInstanceNanny.post(API.NANNY_BOOKING.NANNY.SELECT, {
      bookingId,
      nannyId,
    });
  },
  getActivityLogInBooking: async (bookingId: string) => {
    return await AppAPIInstanceNanny.get<ResponseType<BOOKING_ACTIVITY_LOG[]>>(
      `${API.NANNY_BOOKING.ACTIVITY_LOG.LIST}/${bookingId}`
    );
  },
  createActivityLogInBooking: async (
    data: CREATE_NANNY_BOOKING_ACTIVITY_LOG_REQUEST
  ) => {
    return await AppAPIInstanceNanny.post<ResponseType<BOOKING_ACTIVITY_LOG>>(
      API.NANNY_BOOKING.ACTIVITY_LOG.ADD,
      data
    );
  },
  updateActivityLogInBooking: async (
    data: UPDATE_NANNY_BOOKING_ACTIVITY_LOG_REQUEST
  ) => {
    return await AppAPIInstanceNanny.put<ResponseType<BOOKING_ACTIVITY_LOG>>(
      API.NANNY_BOOKING.ACTIVITY_LOG.UPDATE,
      data
    );
  },
  deleteActivityLogInBooking: async (activityId: string) => {
    return await AppAPIInstanceNanny.delete<ResponseType<any>>(
      API.NANNY_BOOKING.ACTIVITY_LOG.DELETE,
      {
        data: {
          activityId,
        },
      }
    );
  },
};
