import {
  DeleteDialog,
  Layout,
  TableCaption,
  HeadTable,
  PopupCreateOrEditParent,
  FilterMenu,
} from 'components';
import React, { useEffect, useMemo, useState } from 'react';
import {
  AppBar,
  Button,
  Checkbox,
  Grid,
  InputAdornment,
  InputBase,
  inputBaseClasses,
  Paper,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import { drawerWidth } from 'config';
import { Magnifier, DocumentDownload, Eye } from 'asset';
import usePagination from '@mui/material/usePagination/usePagination';
import {
  DELETE_DIALOG_TYPE,
  FilterValue,
  GET_PARENTS_PARAMS_REQUEST,
  PARENT_TYPE,
  TYPE_POPUP,
} from 'models';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { Link } from 'react-router-dom';
import { getParentListRequest } from 'store/reducers/accounts/actionTypes';
import { getSortItem, NotifyService } from 'helpers';
import FileSaver from 'file-saver';
import { useDateTime } from 'hooks';
import { getParentsExportCSVApi } from 'services';
import { setParentListParams } from 'store/reducers/accounts';
import useDebounce from 'hooks/useDebounce';
import { ButtonDelete, ButtonEdit } from 'components/common/table';
import { filterOptions, headCells } from './data';

const ParentList = () => {
  const theme = useTheme();
  const { moment, formatDateTime } = useDateTime();
  const dispatch = useAppDispatch();
  const { parent_list } = useAppSelector(
    (state) => state.accountReducer.parents
  );
  const { params } = parent_list;

  const [selected, setSelected] = useState<readonly string[]>([]);
  const [keyword, setKeyword] = useState('');
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [openCreatePopup, setOpenCreatePopup] = useState(false);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [itemAction, setItemAction] = useState<PARENT_TYPE>();

  const onSetListParams = (params: GET_PARENTS_PARAMS_REQUEST | null) => {
    dispatch(setParentListParams(params));
  };

  useEffect(() => {
    dispatch(getParentListRequest(params));
  }, [params, dispatch]);

  useEffect(() => {
    setKeyword(params?.keyword ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setOpenDelete = (event: any, data: PARENT_TYPE) => {
    event.stopPropagation();
    setItemAction(data);
    setOpenDeletePopup(true);
  };

  const setOpenEdit = (event: any, data: PARENT_TYPE) => {
    event.stopPropagation();
    setItemAction(data);
    setOpenEditPopup(true);
  };

  const setOpenCreate = () => {
    setOpenCreatePopup(true);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = parent_list.records.map((n) => n.parent_id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (_: any, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangeRowsPerPage = (event: SelectChangeEvent) => {
    const limit = parseInt(event.target.value, 10);
    onSetListParams({ ...params, page: 1, limit });
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const { items } = usePagination({
    count: parent_list.metadata.total_pages,
    page: parent_list.metadata.page,
    onChange(_event, page) {
      onSetListParams({ ...params, page: page });
    },
  });

  const onSetKeywordParams = useDebounce((keyword: string) => {
    const _params = { ...params, page: 1 };
    if (keyword) _params.keyword = keyword;
    else delete _params.keyword;
    onSetListParams(_params);
  }, 250);

  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(event.target.value);
    onSetKeywordParams(event.target.value);
  };

  const onClosePopup = (type: TYPE_POPUP) => {
    switch (type) {
      case TYPE_POPUP.DELETE:
        setOpenDeletePopup(false);
        setItemAction(undefined);
        break;
      case TYPE_POPUP.CREATE_OR_EDIT:
        setOpenCreatePopup(false);
        setOpenEditPopup(false);
        setItemAction(undefined);
        break;
    }
  };

  const onReloadList = () => {
    if (!params) return;
    dispatch(getParentListRequest(params));
  };

  const filterValue: FilterValue = useMemo(() => {
    return {
      first_name: parent_list.params?.first_name,
      last_name: parent_list.params?.last_name,
      email: parent_list.params?.email,
    };
  }, [parent_list.params]);

  const onChangeFilter = (value: FilterValue) => {
    setSelected([]);
    const _params = { ...params };
    delete _params.first_name;
    delete _params.last_name;
    delete _params.email;
    onSetListParams({
      ..._params,
      ...value,
      page: 1,
    });
  };

  const sortItem = useMemo(() => {
    return getSortItem(parent_list.params?.sort);
  }, [parent_list.params]);

  const handleRequestSort = (
    _: React.MouseEvent<unknown>,
    property: keyof PARENT_TYPE
  ) => {
    setSelected([]);
    const isAsc = sortItem?.orderBy === property && sortItem?.order === 'asc';
    onSetListParams({
      ...params,
      sort: `${property}-${isAsc ? 'desc' : 'asc'}`,
    });
  };

  const onExportCSV = async () => {
    await getParentsExportCSVApi({
      sort: params?.sort,
      ...params,
    })
      .then((res) => {
        FileSaver.saveAs(
          res.data,
          `${moment().format('YYYY-MM-DD')} Parents.csv`
        );
      })
      .catch((e) => NotifyService.error(e));
  };

  return (
    <Layout>
      <AppBar
        position="fixed"
        sx={{
          width: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`,
          backgroundColor: 'white.light',
          borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
        }}
        elevation={0}
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Grid>
            <Typography
              sx={{
                fontSize: '20px',
                lineHeight: 1,
                color: 'grey1.light',
              }}
            >
              Accounts / Parents
            </Typography>
          </Grid>
          <Grid display="flex" gap="8px" flexGrow={1} justifyContent="center">
            <InputBase
              placeholder="Search"
              sx={{
                maxWidth: '369px',
                flexGrow: 1,
                // paddingRight: '9px',
                background: '#F2F4F6',
                border: '1px solid #D9D9D9',
                p: '0px 7px 0px 11px',
                borderRadius: '5px',
                [`& .${inputBaseClasses.input}`]: {
                  fontSize: 14,
                  fontWeight: 400,
                  lineHeight: '100%',
                  p: 0,
                  height: 'fit-content',
                },
              }}
              onChange={onChangeSearch}
              // autoComplete=""
              value={keyword}
              endAdornment={
                <InputAdornment position="end">
                  <Magnifier />
                </InputAdornment>
              }
            />
            <FilterMenu
              filterOptions={filterOptions}
              filterValue={filterValue}
              onChange={onChangeFilter}
            />
          </Grid>
          <Grid display="flex" gap="8px">
            <Button
              sx={{
                width: '131px',
                height: 30,
                fontWeight: 600,
                fontSize: '13px',
                border: `1px solid ${theme.palette.secondary.main}`,
                p: '0px',
              }}
              variant="outlined"
              color="secondary"
              endIcon={<DocumentDownload />}
              onClick={onExportCSV}
            >
              Export to CSV
            </Button>
            <Button
              variant="contained"
              color="secondary"
              sx={{
                height: 30,
                width: '72px',
                fontWeight: 600,
                fontSize: '13px',
                lineHeight: '100%',
              }}
              onClick={setOpenCreate}
            >
              Create
            </Button>
          </Grid>
        </Toolbar>
      </AppBar>
      <Stack spacing="8px">
        <TableContainer
          component={Paper}
          elevation={0}
          sx={{
            border: '1px solid #D9D9D9',
            maxHeight: 'calc(100vh - 150px)',
          }}
        >
          <Table
            sx={{ minWidth: 650 }}
            aria-labelledby="tableTitle"
            stickyHeader
          >
            <HeadTable<PARENT_TYPE>
              numSelected={selected.length}
              order={sortItem?.order}
              orderBy={sortItem?.orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={parent_list.records.length}
              headCells={headCells}
            />
            <TableBody
              sx={{
                height: 'auto !important',
              }}
            >
              {parent_list.records.map((row, index) => {
                const isItemSelected = isSelected(row.parent_id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.parent_id}
                    selected={isItemSelected}
                  >
                    <TableCell
                      padding="checkbox"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                      }}
                    >
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        onChange={(e) => handleClick(e, row.parent_id)}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                      }}
                    >
                      {`${row.first_name} ${row.last_name} (${row.email})`}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                      }}
                    >
                      {row.phone_number}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                        minWidth: '110px',
                      }}
                    >
                      <Grid
                        container
                        alignItems="center"
                        textTransform="capitalize"
                      >
                        <Grid
                          sx={{
                            width: '8px',
                            height: '8px',
                            borderRadius: '50%',
                            marginRight: '5px',
                            backgroundColor:
                              row.status === 'active'
                                ? 'green.light'
                                : row.status === 'deactive'
                                ? 'red.light'
                                : '',
                          }}
                        />
                        <Typography>{row.status}</Typography>
                      </Grid>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                      }}
                    >
                      {formatDateTime(row.createdAt, 'DD MMM YYYY HH:mm')}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                      }}
                    >
                      <Stack direction="row" spacing="10px">
                        <Link to={`/accounts/parents/${row.parent_id}`}>
                          <Button
                            sx={{
                              minWidth: '38px !important',
                              height: '32px',
                              '&.MuiButton-root': {
                                p: '0px !important',
                              },
                            }}
                            variant="contained"
                            color="secondary"
                          >
                            <Eye />
                          </Button>
                        </Link>
                        <ButtonEdit onClick={(e) => setOpenEdit(e, row)} />
                        <ButtonDelete onClick={(e) => setOpenDelete(e, row)} />
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TableCaption
          rowsPerPage={params?.limit ?? 0}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          metadata={parent_list.metadata}
          items={items}
        />
      </Stack>
      <DeleteDialog
        open={openDeletePopup}
        onClose={() => onClosePopup(TYPE_POPUP.DELETE)}
        subject={itemAction?.parent_id}
        onSuccess={onReloadList}
        type={DELETE_DIALOG_TYPE.PARENT}
        name={itemAction?.first_name}
      />
      {((openEditPopup && !!itemAction) || openCreatePopup) && (
        <PopupCreateOrEditParent
          cleanDetail={true}
          open={true}
          handleClosePopup={() => onClosePopup(TYPE_POPUP.CREATE_OR_EDIT)}
          subject={itemAction?.parent_id}
          onSuccess={onReloadList}
        />
      )}
    </Layout>
  );
};

export default ParentList;
