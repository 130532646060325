import {
  Button,
  Dialog,
  DialogActions,
  dialogClasses,
  DialogContent,
  // DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import { DeleteIcon } from 'asset';
import { DELETE_DIALOG_TYPE } from 'models';
import { useAppDispatch } from 'store/hook';
import {
  DELETE_PARENT_DETAIL_REQUEST,
  DELETE_PARENT_REQUEST,
  DELETE_SITTER_REQUEST,
  DELETE_STAFFS_REQUEST,
  deleteNannyAction,
  deleteNannyCertificateAssociationAction,
  deleteNannyPicturesAction,
  deleteNannyTestimonialAction,
  deleteUsersRequest,
} from 'store/reducers/accounts/actionTypes';
import { deleteChatRequest } from 'store/reducers/chat/actionTypes';
import { deleteJobRequest } from 'store/reducers/jobs/actionTypes';
import {
  deleteActivityLogInBookingAction,
  deleteNannyBookingAction,
} from 'store/reducers/nanny-bookings/actionTypes';
import {
  deletePaymentRequest,
  removeTransactionFromPayoutRequest,
} from 'store/reducers/payment/actionTypes';
import { deletePromosRequest } from 'store/reducers/promotions/actionType';
import { deleteReportRequest } from 'store/reducers/reports/actionType';
import {
  DELETE_REQUESTS_REQUEST,
  DELETE_SITTER_REQUESTS_REQUEST,
} from 'store/reducers/requests/actionTypes';
import { deleteReviewRequest } from 'store/reducers/reviews/actionTypes';
import {
  deleteCertificateRequest,
  deleteChatKeywordTrackingRequest,
  deleteReasonRequest,
  deleteRegionRequest,
  removeCountryFromBlackListRequest,
} from 'store/reducers/settings/actionType';
import { deleteTransactionRequest } from 'store/reducers/transactions/actionTypes';

interface Props {
  open: boolean;
  onClose: () => void;
  subject: string | undefined;
  name: string | undefined;
  type?: DELETE_DIALOG_TYPE | string;
  typeParent?: string;
  idTypeParent?: string;
  onSuccess?: () => void;
  onDelete?: () => void;
  data?: any;
}

const DeleteDialog = ({
  open = true,
  onClose,
  subject,
  type,
  name,
  onSuccess,
  typeParent,
  idTypeParent,
  data,
  onDelete,
}: Props) => {
  const dispatch = useAppDispatch();

  const onClickDelete = () => {
    if (onDelete) {
      onDelete();
      return;
    }
    switch (type) {
      case DELETE_DIALOG_TYPE.SITTER:
        dispatch({
          type: DELETE_SITTER_REQUEST,
          payload: {
            id: subject,
            onSuccess,
          },
        });
        break;
      case DELETE_DIALOG_TYPE.PARENT:
        dispatch({
          type: DELETE_PARENT_REQUEST,
          payload: {
            id: subject,
            onSuccess,
          },
        });
        break;
      case DELETE_DIALOG_TYPE.ADMIN:
        dispatch({
          type: DELETE_STAFFS_REQUEST,
          payload: {
            id: subject,
            onSuccess,
          },
        });
        break;
      case DELETE_DIALOG_TYPE.SERVICE_REQUEST:
        dispatch({
          type: DELETE_REQUESTS_REQUEST,
          payload: {
            id: subject,
            onSuccess,
          },
        });
        break;
      case DELETE_DIALOG_TYPE.SERVICE_SITTER_REQUEST:
        dispatch({
          type: DELETE_SITTER_REQUESTS_REQUEST,
          payload: {
            id: subject,
            data: data,
            onSuccess,
          },
        });
        break;
      case DELETE_DIALOG_TYPE.DETAIL_PARENT:
        dispatch({
          type: DELETE_PARENT_DETAIL_REQUEST,
          payload: {
            id: subject,
            type: typeParent,
            idType: idTypeParent,
            onSuccess,
          },
        });
        break;
      case DELETE_DIALOG_TYPE.JOB:
        dispatch(deleteJobRequest(subject ?? '', onSuccess));
        break;
      case DELETE_DIALOG_TYPE.TRANSACTION:
        dispatch(deleteTransactionRequest(subject ?? '', onSuccess));
        break;
      case DELETE_DIALOG_TYPE.PAYMENT:
        dispatch(deletePaymentRequest(subject ?? '', onSuccess));
        break;
      case DELETE_DIALOG_TYPE.CHAT:
        dispatch(deleteChatRequest(subject ?? '', onSuccess));
        break;
      case DELETE_DIALOG_TYPE.REVIEW:
        dispatch(deleteReviewRequest(subject ?? '', onSuccess));
        break;
      case DELETE_DIALOG_TYPE.USERS:
        dispatch(deleteUsersRequest(subject ?? '', onSuccess));
        break;
      case DELETE_DIALOG_TYPE.REGION:
        dispatch(deleteRegionRequest(subject ?? '', onSuccess));
        break;
      case DELETE_DIALOG_TYPE.REASON:
        dispatch(deleteReasonRequest(subject ?? '', onSuccess));
        break;
      case DELETE_DIALOG_TYPE.REPORT:
        dispatch(deleteReportRequest(subject ?? '', onSuccess));
        break;
      case DELETE_DIALOG_TYPE.CHAT_KEYWORD_TRACKING:
        dispatch(deleteChatKeywordTrackingRequest(subject ?? '', onSuccess));
        break;
      case DELETE_DIALOG_TYPE.DELETE_TXN_FROM_PAYOUT:
        dispatch(removeTransactionFromPayoutRequest(data ?? '', onSuccess));
        break;
      case DELETE_DIALOG_TYPE.DELETE_COUNTRY_FROM_BLACK_LIST:
        dispatch(removeCountryFromBlackListRequest(subject ?? '', onSuccess));
        break;
      case DELETE_DIALOG_TYPE.PROMO_CODES:
        dispatch(deletePromosRequest(subject ?? '', onSuccess));
        break;
      case DELETE_DIALOG_TYPE.NANNY_BOOKING:
        dispatch(deleteNannyBookingAction({ id: subject ?? '', onSuccess }));
        break;
      case DELETE_DIALOG_TYPE.NANNY:
        dispatch(deleteNannyAction({ id: subject ?? '', onSuccess }));
        break;
      case DELETE_DIALOG_TYPE.NANNY_PICTURES:
        dispatch(deleteNannyPicturesAction({ id: subject ?? '', onSuccess }));
        break;
      case DELETE_DIALOG_TYPE.NANNY_TESTIMONIAL:
        dispatch(
          deleteNannyTestimonialAction({ id: subject ?? '', onSuccess })
        );
        break;
      case DELETE_DIALOG_TYPE.NANNY_CERTIFICATE:
        dispatch(
          deleteCertificateRequest({
            id: subject ?? '',
            onSuccess,
          })
        );
        break;
      case DELETE_DIALOG_TYPE.NANNY_CERTIFICATE_ASSOCIATION:
        dispatch(
          deleteNannyCertificateAssociationAction({
            id: subject ?? '',
            onSuccess,
          })
        );
        break;
      case DELETE_DIALOG_TYPE.ACTIVITY_LOG_IN_BOOKING:
        dispatch(
          deleteActivityLogInBookingAction({ id: subject ?? '', onSuccess })
        );
        break;

      default:
        break;
    }
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        [`& .${dialogClasses.paper}`]: {
          maxWidth: '400px',
          width: '100%',
        },
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          py: '24px',
          display: 'flex',
          alignItems: 'center',
        }}
        component="div"
      >
        <DeleteIcon />
      </DialogTitle>
      <DialogContent
        sx={{
          padding: '0px 24px',
        }}
      >
        <Typography
          variant="Large_Body_18_SemiBold"
          sx={{
            fontWeight: 600,
            mb: '8px',
          }}
        >
          {type === 'sitterSR'
            ? 'Remove Sitter?'
            : type === DELETE_DIALOG_TYPE.DELETE_TXN_FROM_PAYOUT
            ? `Unlink ${name}?`
            : `Delete ${name}?`}
        </Typography>
        <Typography variant="Small_Body_16_Regular">
          {type === 'sitterSR'
            ? 'Are you sure you want to remove this sitter from request?'
            : type === DELETE_DIALOG_TYPE.DELETE_TXN_FROM_PAYOUT
            ? `Are you sure you want to unlink this ${name}?`
            : `Are you sure you want to delete this ${name}?`}
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          padding: '24px',
        }}
      >
        <Button
          onClick={onClose}
          variant="outlined"
          sx={{
            border: '1px solid rgba(0, 0, 0, 0.08)',
            color: 'black.light',
            ':hover': {
              color: 'primary.main',
            },
            width: '100%',
          }}
        >
          Back
        </Button>
        <Button
          autoFocus
          variant="contained"
          sx={{
            width: '100%',
            backgroundColor: 'red.light',
            ':hover': {
              backgroundColor: 'red.dark',
            },
          }}
          onClick={onClickDelete}
        >
          {type === DELETE_DIALOG_TYPE.DELETE_TXN_FROM_PAYOUT
            ? 'Unlink'
            : 'Delete'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
