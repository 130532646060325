import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  color?: string;
}

function SolidStar({ color, ...props }: Props) {
  return (
    <svg
      width="1em"
      height="1em"
      transform=""
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M44 20.2001C44.2 19.2001 43.4 18.0001 42.4 18.0001L31 16.4001L25.8 6.00012C25.6 5.60012 25.4 5.40012 25 5.20012C24 4.60012 22.8 5.00012 22.2 6.00012L17.2 16.4001L5.8 18.0001C5.2 18.0001 4.8 18.2001 4.6 18.6001C3.8 19.4001 3.8 20.6001 4.6 21.4001L12.8 29.4001L10.8 40.8001C10.8 41.2001 10.8 41.6001 11 42.0001C11.6 43.0001 12.8 43.4001 13.8 42.8001L24 37.4001L34.2 42.8001C34.4 43.0001 34.8 43.0001 35.2 43.0001C35.4 43.0001 35.4 43.0001 35.6 43.0001C36.6 42.8001 37.4 41.8001 37.2 40.6001L35.2 29.2001L43.4 21.2001C43.8 21.0001 44 20.6001 44 20.2001Z"
        fill={color ? color : '#FFB904'}
      />
    </svg>
  );
}

export default SolidStar;
