import {
  FormHelperText,
  FormHelperTextProps,
  InputLabelProps,
  Stack,
  StackProps,
  useTheme,
} from '@mui/material';
import useDebounce from 'hooks/useDebounce';
import { METADATA_TYPE, typedMemo } from 'models';
import { useEffect, useState } from 'react';
import { Control, useController } from 'react-hook-form';
import ReactSelect from 'react-select';
import { StateManagerProps } from 'react-select/dist/declarations/src/stateManager';
import { StyledFormHelperText, StyledInputLabel } from '../InputField/styles';
import { DropdownIndicator, GetOptionsResponse, customStyles } from '../Select';

interface SelectFieldProps<Option, IsMulti extends boolean = boolean>
  extends StateManagerProps<Option, IsMulti> {
  title?: string;
  name: string;
  control?: Control<any>;
  errorsName?: any;
  bindKey?: string;
  bindLabel?: string;
  isHasMore?: boolean;
  filterFunc?: boolean;
  loadOptionInit?: boolean;
  refreshWhenOpen?: boolean;
  helperText?: string;
  hideHelper?: boolean;
  rootProps?: StackProps;
  labelProps?: InputLabelProps;
  helperProps?: FormHelperTextProps;
  getOptions?: (
    page?: number,
    limit?: number,
    keyword?: string
  ) => Promise<GetOptionsResponse | null | undefined> | null | undefined;
}

export const SelectField = typedMemo(
  <Option, IsMulti extends boolean = boolean>({
    title,
    name,
    control,
    errorsName,
    bindKey,
    refreshWhenOpen,
    bindLabel,
    isHasMore,
    helperText,
    hideHelper,
    rootProps,
    labelProps,
    helperProps,
    getOptions,
    filterFunc = true,
    value: externalValue,
    onChange: externalOnChange,
    loadOptionInit,
    ...rest
  }: SelectFieldProps<Option, IsMulti>) => {
    const theme = useTheme();

    const {
      field: { onChange, onBlur, value },
      fieldState: { error },
    } = control
      ? // eslint-disable-next-line react-hooks/rules-of-hooks
        useController({ name, control })
      : {
          field: {
            onChange: externalOnChange as (...event: any[]) => void,
            value: externalValue,
            onBlur: undefined,
          },
          fieldState: { error: undefined },
        };

    const [keyword, setKeyword] = useState<string>();
    const [options, setOptions] = useState<any[]>([]);
    const [metadata, setMetadata] = useState<METADATA_TYPE>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onMenuScrollToBottom = useDebounce(async () => {
      if (
        isLoading ||
        !isHasMore ||
        !getOptions ||
        !metadata ||
        metadata.page >= metadata.total_pages ||
        !options?.length
      )
        return;
      setIsLoading(true);
      const newPage = metadata.page + 1;
      const data = await getOptions(newPage, metadata.limit, keyword);
      setOptions((pre) => pre.concat(data?.options || []));
      setMetadata(data?.metadata);
      setIsLoading(false);
    }, 200);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onInputChange = useDebounce(async (newValue: string) => {
      if (!isHasMore || !getOptions || (!keyword && !newValue)) return;
      setKeyword(newValue);
      if (!isLoading) {
        setIsLoading(true);
        const data = await getOptions(1, metadata?.limit, newValue);
        setMetadata(data?.metadata);
        setOptions(data?.options);
        setIsLoading(false);
      }
    }, 200);

    const onMenuOpen = async () => {
      if (
        !isHasMore ||
        isLoading ||
        (options?.length && !refreshWhenOpen) ||
        !getOptions
      )
        return;
      setIsLoading(true);
      const data = await getOptions(1, metadata?.limit);
      setMetadata(data?.metadata);
      setOptions(data?.options);
      setIsLoading(false);
      setKeyword('');
    };

    useEffect(() => {
      const getOptionsInit = async () => {
        if (!isHasMore || !getOptions) return;
        setIsLoading(true);
        const data = await getOptions(1, metadata?.limit);
        setMetadata(data?.metadata);
        setOptions(data?.options);
        setIsLoading(false);
      };
      if (loadOptionInit) {
        getOptionsInit();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Stack {...rootProps}>
        {!!title && (
          <StyledInputLabel {...labelProps}>{title}</StyledInputLabel>
        )}
        <ReactSelect
          value={value ?? null}
          menuPosition="fixed"
          styles={customStyles(theme, !!errorsName || !!error) as any}
          getOptionValue={(option: any) => option[bindKey || 'id']}
          getOptionLabel={(option: any) => option[bindLabel || 'label']}
          components={{ DropdownIndicator } as any}
          isLoading={isLoading}
          options={options}
          filterOption={
            filterFunc
              ? (option: any, input) => {
                  return (option.data[bindLabel || 'label'] || '')
                    .toLocaleLowerCase()
                    .includes((input || '').toLocaleLowerCase());
                }
              : () => true
          }
          onMenuOpen={onMenuOpen}
          onInputChange={onInputChange}
          onMenuScrollToBottom={onMenuScrollToBottom}
          onBlur={onBlur}
          onChange={(...t) => {
            if (control) (externalOnChange as any)?.(...t);
            onChange(...t);
          }}
          {...rest}
        />
        {!!helperText && (
          <FormHelperText sx={{ color: '#8E8E93', fontSize: '12px' }}>
            {helperText}
          </FormHelperText>
        )}
        {!hideHelper && (error || errorsName) && (
          <StyledFormHelperText error {...helperProps}>
            {errorsName || error?.message}
          </StyledFormHelperText>
        )}
      </Stack>
    );
  }
);

export default SelectField;
