import {
  FormHelperText,
  IconButton,
  InputAdornment,
  InputBase,
  InputBaseProps,
  InputLabel,
  Typography,
} from '@mui/material';
import { EyeCloseInputs, EyeInputs, Pencil } from 'asset';
import { FC, Fragment, useState } from 'react';
import { Controller } from 'react-hook-form';

interface Props extends InputBaseProps {
  title?: string;
  type?: string;
  name: string;
  form?: any;
  rules?: any;
  autoComplete?: string;
  placeholder?: string;
  showEyes?: boolean;
  showCount?: boolean;
  numberCount?: number;
  sx?: any;
  errorsName?: any;
  readOnly?: boolean;
  value?: any;
  showPopulate?: boolean;
  helperText?: string;
  handlePopulate?: (value: string) => void;
}

const Inputs: FC<Props> = ({
  type,
  title,
  readOnly,
  value,
  form,
  name,
  rules,
  placeholder,
  showEyes,
  showCount,
  numberCount,
  rows,
  sx,
  errorsName,
  showPopulate,
  helperText,
  handlePopulate,
  ...rest
}) => {
  const {
    control,
    formState: { errors },
  } = form;
  const [toggleEyes, setToggleEyes] = useState(false);

  const handleClick = () => {
    setToggleEyes(!toggleEyes);
  };

  return (
    <Fragment>
      {!!title && (
        <InputLabel
          sx={{
            fontWeight: 500,
            fontSize: '15px',
            lineHeight: '100%',
            color: '#000',
            marginBottom: '5px',
          }}
        >
          {title}
        </InputLabel>
      )}
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => (
          <InputBase
            {...field}
            multiline={!!rows}
            rows={rows}
            value={field.value ?? ''}
            readOnly={readOnly}
            type={!toggleEyes ? type : 'text'}
            placeholder={placeholder}
            endAdornment={
              showEyes ? (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClick}
                  >
                    {toggleEyes ? <EyeCloseInputs /> : <EyeInputs />}
                  </IconButton>
                </InputAdornment>
              ) : showCount ? (
                <InputAdornment position="end">
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: '15px',
                      lineHeight: '100%',
                      color: '#000',
                      opacity: 0.5,
                    }}
                  >
                    {numberCount}/50
                  </Typography>
                </InputAdornment>
              ) : showPopulate && !!field.value ? (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Populate"
                    onClick={() =>
                      handlePopulate && handlePopulate(field.value)
                    }
                  >
                    <Pencil fill={'black'} />
                  </IconButton>
                </InputAdornment>
              ) : (
                ''
              )
            }
            sx={{
              color: '#000',
              fontSize: '13px',
              lineHeight: !!rows ? 'normal' : '100%',
              border: '1px solid #D5D5D5',
              p: '16px',
              width: '100%',
              height: !!rows ? 'auto' : '38px',
              background: readOnly ? '#eaeaea' : '#fff',
              borderRadius: '5px',
              padding: '13px 10px',
              ...sx,
            }}
            {...rest}
          />
        )}
      />
      {!!helperText && (
        <FormHelperText sx={{ color: '#8E8E93', fontSize: '12px' }}>
          {helperText}
        </FormHelperText>
      )}
      {!!(errors as any)[name] ? (
        <FormHelperText
          error
          sx={{
            fontSize: '16px',
            letterSpacing: '0.045em',
            color: 'white.light',
          }}
        >
          {(errors as any)?.[name]?.message || ''}
        </FormHelperText>
      ) : (
        <FormHelperText
          error
          sx={{
            fontSize: '16px',
            letterSpacing: '0.045em',
            color: 'white.light',
          }}
        >
          {errorsName || ''}
        </FormHelperText>
      )}
    </Fragment>
  );
};

export default Inputs;
