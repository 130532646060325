import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormControlProps,
  FormHelperText,
  FormHelperTextProps,
  SxProps,
} from '@mui/material';
import { memo } from 'react';
import { Control, FieldError, useController } from 'react-hook-form';
import { StyledFormHelperText } from '../InputField/styles';

export interface CheckboxFieldProps extends CheckboxProps {
  label?: string;
  sx?: SxProps;
  fullWidth?: boolean;
  control?: Control<any>;
  hideHelper?: boolean;
  errorsName?: string;
  helperText?: string;
  rootProps?: Partial<FormControlProps>;
  helperProps?: FormHelperTextProps;
  formControlLabel?: Partial<FormControlLabelProps> & { $error?: boolean };
  getErrorMess?: (error: FieldError, value: string) => string;
}

export const CheckboxField = memo(
  ({
    label,
    name,
    fullWidth,
    rootProps,
    checked: externalValue,
    onChange: externalOnChange,
    control,
    helperText,
    hideHelper,
    errorsName,
    helperProps,
    getErrorMess,
    formControlLabel,
    ...rest
  }: CheckboxFieldProps) => {
    const {
      field: { onChange, onBlur, value, ref },
      fieldState: { error },
    } =
      control && name
        ? // eslint-disable-next-line react-hooks/rules-of-hooks
          useController({ name, control })
        : {
            field: {
              onChange: externalOnChange as (...event: any[]) => void,
              value: externalValue,
              onBlur: undefined,
              ref: undefined,
            },
            fieldState: { error: undefined },
          };

    return (
      <FormControl
        fullWidth={fullWidth}
        sx={{ alignItems: 'flex-start', ...(rootProps?.sx || {}) }}
        {...rootProps}
      >
        <FormControlLabel
          checked={!!value}
          label={label}
          inputRef={ref}
          onBlur={onBlur}
          onChange={(...t) => {
            if (control) (externalOnChange as any)?.(...t);
            onChange(...t);
          }}
          control={<Checkbox color="primary" name="none" {...rest} />}
          {...formControlLabel}
        />
        {!!helperText && (
          <FormHelperText sx={{ color: '#8E8E93', fontSize: '12px' }}>
            {helperText}
          </FormHelperText>
        )}
        {!hideHelper && (error || errorsName) && (
          <StyledFormHelperText error {...helperProps}>
            {errorsName || error?.message}
          </StyledFormHelperText>
        )}
      </FormControl>
    );
  }
);

export default CheckboxField;
