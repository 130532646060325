import { patternPassword } from 'config';
import { Order } from 'models';
import moment from 'moment';

export const checkTypeSort = (
  key: string,
  query: { sortBy?: string; sortOrder?: string }
): any => {
  if (query.sortBy !== key) return '';
  if (query.sortOrder === 'asc') return 'az';
  return 'za';
};

export const getNewSort = (
  key: string,
  sortBy?: string,
  sortOrder?: string
) => {
  if (sortBy === key)
    return {
      sortBy: key,
      sortOrder: sortOrder === 'asc' ? 'desc' : 'asc',
    };
  else {
    return {
      sortBy: key,
      sortOrder: 'asc',
    };
  }
};

export const formatMoney = (
  num: number | string,
  decimalCount = 2,
  thousands = ','
) => {
  if (!num) return 0;
  const n = 'string' === typeof num ? +num : num;

  if (!decimalCount)
    return n
      .toFixed(1)
      .replace(/\d(?=(\d{3})+\.)/g, `$&${thousands}`)
      .replace('.0', '');
  return n.toFixed(decimalCount).replace(/\d(?=(\d{3})+\.)/g, `$&${thousands}`);
};

export const getPageAfterDelete = (
  totalCurrent: number,
  pageCurrent: number,
  pageSize: number,
  num: number
) => {
  let page = pageCurrent;
  if (pageCurrent === num) page = page - 1;
  else {
    const totalNew = totalCurrent - num;
    const maxPage = Math.ceil(totalNew / pageSize);
    if (pageCurrent > maxPage) page = page - 1;
  }
  if (page <= 0) page = 1;
  return page;
};

export const copyTextToClipboard = (text: string) => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () {
      console.log('Async: Copying to clipboard was successful!');
    },
    function (err) {
      console.error('Async: Could not copy text: ', err);
    }
  );
};

export const parseJwt = (token: string = '') => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  try {
    return JSON.parse(jsonPayload);
  } catch (e) {
    return {};
  }
};

const fallbackCopyTextToClipboard = (text: string) => {
  var textArea = document.createElement('textarea');
  textArea.value = text;

  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
};

export const sleep = (ms: number) => {
  return new Promise((resole) => setTimeout(resole, ms));
};

export const titleCase = (text: string | undefined) => {
  if (!text) return;
  let sentence = text.match(/[A-Z]([a-z]*)+/g);
  if (sentence) {
    for (var i = 0; i < sentence.length; i++) {
      sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }
    return sentence.join(' ');
  }
};

export const validatePassword = (value: any) => {
  if (value.length === 0) {
    return true;
  }
  if (value.length < 8) {
    return `Password must be at least 8 characters`;
  }
  if (value.length >= 8 && value.length <= 20) {
    if (!patternPassword.test(value)) {
      return `Password invalid. At least one uppercase English letter, At least one lower case, At least one special character, At least one digit`;
    }
  }
  if (value.length > 20) {
    return `Maximum of 20 characters`;
  }
};

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
export function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export const splitNumber = (value: string) => {
  const country_codes = [
    '+91',
    '+1',
    '+44',
    '+93',
    '+355',
    '+213',
    '+376',
    '+244',
    '+54',
    '+374',
    '+297',
    '+61',
    '+43',
    '+994',
    '+973',
    '+880',
    '+375',
    '+32',
    '+501',
    '+229',
    '+975',
    '+591',
    '+387',
    '+267',
    '+55',
    '+246',
    '+673',
    '+359',
    '+226',
    '+257',
    '+855',
    '+237',
    '+238',
    '+599',
    '+236',
    '+235',
    '+56',
    '+86',
    '+57',
    '+269',
    '+243',
    '+242',
    '+682',
    '+506',
    '+225',
    '+385',
    '+53',
    '+357',
    '+420',
    '+45',
    '+253',
    '+593',
    '+20',
    '+503',
    '+240',
    '+291',
    '+372',
    '+251',
    '+500',
    '+298',
    '+679',
    '+358',
    '+33',
    '+594',
    '+689',
    '+241',
    '+220',
    '+995',
    '+49',
    '+233',
    '+350',
    '+30',
    '+299',
    '+590',
    '+502',
    '+224',
    '+245',
    '+592',
    '+509',
    '+504',
    '+852',
    '+36',
    '+354',
    '+62',
    '+98',
    '+964',
    '+353',
    '+972',
    '+39',
    '+81',
    '+962',
    '+7',
    '+254',
    '+686',
    '+383',
    '+965',
    '+996',
    '+856',
    '+371',
    '+961',
    '+266',
    '+231',
    '+218',
    '+423',
    '+370',
    '+352',
    '+853',
    '+389',
    '+261',
    '+265',
    '+60',
    '+960',
    '+223',
    '+356',
    '+692',
    '+596',
    '+222',
    '+230',
    '+262',
    '+52',
    '+691',
    '+373',
    '+377',
    '+976',
    '+382',
    '+212',
    '+258',
    '+95',
    '+264',
    '+674',
    '+977',
    '+31',
    '+687',
    '+64',
    '+505',
    '+227',
    '+234',
    '+683',
    '+672',
    '+850',
    '+47',
    '+968',
    '+92',
    '+680',
    '+970',
    '+507',
    '+675',
    '+595',
    '+51',
    '+63',
    '+48',
    '+351',
    '+974',
    '+40',
    '+250',
    '+290',
    '+508',
    '+685',
    '+378',
    '+239',
    '+966',
    '+221',
    '+381',
    '+248',
    '+232',
    '+65',
    '+421',
    '+386',
    '+677',
    '+252',
    '+27',
    '+82',
    '+211',
    '+34',
    '+94',
    '+249',
    '+597',
    '+268',
    '+46',
    '+41',
    '+963',
    '+886',
    '+992',
    '+255',
    '+66',
    '+670',
    '+228',
    '+690',
    '+676',
    '+216',
    '+90',
    '+993',
    '+688',
    '+256',
    '+380',
    '+971',
    '+598',
    '+998',
    '+678',
    '+58',
    '+84',
    '+681',
    '+967',
    '+260',
    '+263',
    '+358',
  ];
  for (let i = 0; i < country_codes.length; i += 1) {
    if (value.includes(country_codes[i])) {
      const code_size: number = country_codes[i].length;
      return {
        code: value.substring(0, code_size),
        number: value.substring(code_size),
      };
    }
  }
  return null;
};

export const changeAge = (number: number) => {
  if (number === 0) {
    return '0 - 1 months';
  }
  if (number === 1) {
    return '2 - 12 months';
  }
  if (number === 2) {
    return '1 - 3 years old';
  }
  if (number === 3) {
    return '4 - 6 years old';
  }
  if (number === 4) {
    return '7 - 12 years old';
  }
  if (number === 5) {
    return '13+ years old';
  }
};

export const printPrice = (number: number) => {
  if ((number ?? null) === null) return '';
  if (number < 0) {
    return (
      number.toString().charAt(0) + '$' + number.toFixed(2).toString().slice(1)
    );
  } else {
    return '$' + number.toFixed(2);
  }
};

export const colorSatus = (status: string) => {
  switch (status) {
    case 'completed':
      return 1;

    default:
      break;
  }
};

export const truncate = (text: string, maxLength: number) => {
  if (text.length <= maxLength) return text;
  const shortStr = text.slice(0, maxLength - 1);
  return `${shortStr}\u2026`;
};

export const renderName = (text: string) =>
  text.includes('deleted') ? truncate(text, 47) : text;

export const calculateExperience = (start_date: string) => {
  const startMoment = moment(start_date, 'DD-MM-YYYY');
  const endMoment = moment();

  const duration = moment.duration(endMoment.diff(startMoment));
  if (duration.asYears() >= 1) {
    return Math.floor(duration.asYears()) + ' years';
  } else if (duration.asMonths() >= 6) {
    return 'Round up';
  } else {
    return 'Round down';
  }
};
