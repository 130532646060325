import {
  Button,
  Dialog,
  DialogActions,
  dialogClasses,
  DialogContent,
  // DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import { DeleteIcon } from 'asset';
import React from 'react';

interface Props {
  open: boolean;
  onClose: () => void;
  onClickDelete?: any;
}

const DeleteAllDialog = ({
  open = true,
  onClose,
  onClickDelete
}: Props) => {

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        [`& .${dialogClasses.paper}`]: {
          maxWidth: '400px',
          width: '100%',
        },
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          py: '24px',
          display: 'flex',
          alignItems: 'center',
        }}
        component="div"
      >
        <DeleteIcon />
      </DialogTitle>
      <DialogContent
        sx={{
          padding: '0px 24px',
        }}
      >
        <Typography
          variant="Large_Body_18_SemiBold"
          sx={{
            mb: '8px',
          }}
        >
          Delete selected rows?
        </Typography>
        <Typography variant="Small_Body_16_Regular">
          Are you sure you want to delete these rows?
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          padding: '24px',
        }}
      >
        <Button
          onClick={onClose}
          variant="outlined"
          sx={{
            border: '1px solid rgba(0, 0, 0, 0.08)',
            color: 'black.light',
            ':hover': {
              color: 'primary.main',
            },
            width: '100%',
          }}
        >
          Back
        </Button>
        <Button
          autoFocus
          variant="contained"
          sx={{
            width: '100%',
            backgroundColor: 'red.light',
            ':hover': {
              backgroundColor: 'red.dark',
            },
          }}
          onClick={onClickDelete}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteAllDialog;
