import {
  Button,
  Checkbox,
  Dialog,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Rating,
  Stack,
  Typography,
} from '@mui/material';
import { CloseCircle, IconChecked, IconCheckEmpty, SolidStar } from 'asset';
import { Inputs } from 'components/form-control';
import {
  GET_JOBS_PARAMS_REQUEST,
  GET_PARENTS_PARAMS_REQUEST,
  JOBS_TYPE,
  PARENT_TYPE,
  REVIEW_TYPE,
} from 'models';
import React, { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'config/yup.custom';
import { ErrorMessage } from 'components/common';
import { createReviewApi, getParentListApi, updateReviewApi } from 'services';
import { getJobsListApi } from 'services/jobs';
import { NotifyService } from 'helpers';
import Select from 'components/form-control/Select';
import { GetOptionsResponse } from 'components/form-control/Selects';

interface Props {
  open: boolean;
  data?: REVIEW_TYPE;
  onSuccess: () => void;
  handleClosePopup: () => void;
  sitterId: string | undefined;
}

interface IFormInputs {
  rate: number;
  content: string;
  user_id: any;
  job_number: any;
  anonymously: boolean;
}

const defaultValue: Partial<IFormInputs> = {
  rate: 0,
  content: '',
  user_id: '',
  job_number: '',
  anonymously: false,
};

const PopupEditReview = (props: Props) => {
  const { open, data, handleClosePopup, onSuccess, sitterId } = props;

  const schema = useMemo(() => {
    return yup.object({
      rate: yup
        .number()
        .typeError('Rating is required')
        .transform((value) => (isNaN(value) ? 0 : value))
        .min(1, 'Rating must be greater than or equal to 1')
        .required('Rating is required'),
      content: yup.string().required('Review Comments is required'),
      user_id: !data
        ? yup.mixed().required('User is required')
        : yup.mixed().notRequired(),
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const form = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: defaultValue,
  });

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = form;

  const _handleClosePopup = () => {
    reset({ ...defaultValue });
    handleClosePopup();
  };

  useEffect(() => {
    if (data) {
      reset({
        rate: data.rate,
        content: data.content,
        user_id: data.parent
          ? {
              id: data.parent.parent_id,
              label: `${data.parent.first_name} ${data.parent.last_name}`,
            }
          : undefined,
        job_number: data.job_number
          ? {
              id: data.job_number,
              label: `${data.job_number}`,
            }
          : undefined,
        anonymously: data.is_anonymous,
      });
    }
  }, [data, reset]);

  const onSubmit = async (value: IFormInputs) => {
    if (!data)
      await createReviewApi({
        rate: value.rate,
        content: value.content,
        is_anonymous: value.anonymously,
        job_id: value.job_number.id,
      })
        .then((r) => {
          onSuccess();
          NotifyService.success(r);
          _handleClosePopup();
        })
        .catch((e) => NotifyService.error(e))
        .finally(() => {});
    else
      await updateReviewApi(data.job_id, {
        rate: value.rate,
        content: value.content,
        is_anonymous: value.anonymously,
      })
        .then((r) => {
          onSuccess();
          NotifyService.success(r);
          _handleClosePopup();
        })
        .catch((e) => NotifyService.error(e))
        .finally(() => {});
  };

  const getOptions =
    (key: keyof IFormInputs) =>
    async (page: number = 1, limit: number = 20, keyword?: string) => {
      let data: GetOptionsResponse | null = null;
      switch (key) {
        case 'user_id':
          const paramsParent: GET_PARENTS_PARAMS_REQUEST = {
            page: page,
            limit: limit,
          };
          data = await getParentListApi(paramsParent)
            .then(({ data }) => {
              return {
                metadata: data.data.metadata,
                options: data.data.records.map((it: PARENT_TYPE) => ({
                  id: it.parent_id,
                  label: `${it.first_name} ${it.last_name}`,
                })),
              };
            })
            .catch((e) => {
              NotifyService.error(e);
              return null;
            });
          break;
        case 'job_number':
          const paramsJobs: GET_JOBS_PARAMS_REQUEST = {
            page: page,
            limit: limit,
            status: 'completed',
            sitter_id: sitterId,
          };
          if (keyword) paramsJobs.keyword = keyword;
          data = await getJobsListApi(paramsJobs)
            .then(({ data }) => {
              return {
                metadata: data.metadata,
                options: data.records.map((it: JOBS_TYPE) => ({
                  ...it,
                  label: `${it.number}`,
                })),
              };
            })
            .catch((e) => {
              NotifyService.error(e);
              return null;
            });
          break;
      }
      return data;
    };

  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiPaper-root': {
          width: 523,
          borderRadius: 0,
        },
      }}
    >
      <Grid>
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '20px 25px',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '100%',
            }}
          >
            {data ? 'Edit' : 'Add'} Review
          </Typography>
          <IconButton
            onClick={_handleClosePopup}
            sx={{
              padding: 0,
            }}
          >
            <CloseCircle />
          </IconButton>
        </Grid>
        <Divider />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack p="30px 24px 50px 26px" spacing="25px">
            <Stack>
              <Select
                isDisabled={!!data}
                title="Job No."
                name="job_number"
                form={form}
                isHasMore
                // onChange={onClearDocRefNo}
                getOptions={getOptions('job_number')}
              />
              <Controller
                name={`anonymously`}
                control={form.control}
                render={({ field }) => (
                  <FormControlLabel
                    sx={{
                      '& .MuiTypography-root': {
                        fontSize: '12px !important',
                      },
                    }}
                    control={
                      <Checkbox
                        icon={<IconCheckEmpty />}
                        checkedIcon={<IconChecked />}
                        color="secondary"
                        checked={field.value || form.watch('anonymously')}
                        onChange={(e) => field.onChange(e.target.checked)}
                        name="anonymously"
                      />
                    }
                    label="Review Anonymously"
                  />
                )}
              />
            </Stack>
            <Stack>
              <InputLabel
                sx={{
                  fontWeight: 500,
                  fontSize: '15px',
                  lineHeight: '100%',
                  color: '#000',
                  marginBottom: '10px',
                }}
              >
                Rating
              </InputLabel>
              <Controller
                name="rate"
                control={control}
                render={({ field }) => (
                  <Rating
                    {...field}
                    icon={<SolidStar fontSize="48px" />}
                    emptyIcon={<SolidStar color="#D1D1D6" fontSize="48px" />}
                    onChange={(_, value) => field.onChange(value)}
                    precision={1}
                  />
                )}
              />
              {!!errors?.rate && (
                <ErrorMessage>{errors?.rate?.message}</ErrorMessage>
              )}
            </Stack>
            <Stack>
              <Inputs
                title="Tell us about your experience"
                form={form}
                name="content"
                rows={10}
              />
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Button
                variant="outlined"
                color="secondary"
                sx={{
                  width: '233.5px',
                  height: '30px',
                }}
                onClick={_handleClosePopup}
              >
                Cancel
              </Button>
              <Button
                color="secondary"
                variant="contained"
                sx={{
                  width: '233.5px',
                  height: '30px',
                }}
                type="submit"
              >
                {data ? 'Update' : 'Create'}
              </Button>
            </Stack>
          </Stack>
        </form>
      </Grid>
    </Dialog>
  );
};

export default PopupEditReview;
