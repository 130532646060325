// import Cookies from 'js-cookie';
import * as CONSTANTS from 'config/constants';
import {
  Authentication,
  Dashboard,
  Jobs,
  JustOnce,
  NannyDetail,
  NannyList,
  ParentDetail,
  ParentList,
  Payments,
  PayoutsDetail,
  Recurring,
  RequestsDetail,
  SitterDetail,
  SitterList,
  Superadmin,
  Transaction,
  UserFlags,
  Users,
} from 'modules';
import CheckOTP from 'modules/Authentication/CheckOTP';
import Payouts from 'modules/Finance/Payouts';
import FinanceSettings from 'modules/FinanceSettings';
import { ListNannyBooking, NannyBookingDetail } from 'modules/NannyBookings';
import Promotions from 'modules/Promotions';
import Reports from 'modules/Reports';
import Reviews from 'modules/Reviews';
import Settings from 'modules/Settings';
import { Route, Routes } from 'react-router-dom';
import { useAppSelector } from 'store/hook';
import PrivateRoute from './PrivateRoute';

const AppRoute = () => {
  const { isLoggedIn } = useAppSelector((state) => state.authenticationReducer);
  const token = localStorage.getItem(CONSTANTS.ACCESS_TOKEN);

  // const Authentication = lazy(() => import('../modules/Authentication/Login'));

  return (
    <Routes>
      <Route
        path="/login"
        element={
          <PrivateRoute
            redirectPath="/"
            isLoggedIn={!Boolean(token) || !isLoggedIn}
          >
            <Authentication />
          </PrivateRoute>
        }
      />
      <Route
        path="/check-otp"
        element={
          <PrivateRoute
            redirectPath="/"
            isLoggedIn={!Boolean(token) || !isLoggedIn}
          >
            <CheckOTP />
          </PrivateRoute>
        }
      />
      <Route
        path="/"
        element={
          <PrivateRoute isLoggedIn={Boolean(token) || isLoggedIn}>
            <Dashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/jobs"
        element={
          <PrivateRoute isLoggedIn={Boolean(token) || isLoggedIn}>
            <Jobs />
          </PrivateRoute>
        }
      />

      <Route
        path="/accounts/parents"
        element={
          <PrivateRoute isLoggedIn={Boolean(token) || isLoggedIn}>
            <ParentList />
          </PrivateRoute>
        }
      />
      <Route
        path="/accounts/parents/:parent_id"
        element={
          <PrivateRoute isLoggedIn={Boolean(token) || isLoggedIn}>
            <ParentDetail />
          </PrivateRoute>
        }
      />
      <Route
        path="/accounts/sitters"
        element={
          <PrivateRoute isLoggedIn={Boolean(token) || isLoggedIn}>
            <SitterList />
          </PrivateRoute>
        }
      />
      <Route
        path="/accounts/sitters/:sitter_id"
        element={
          <PrivateRoute isLoggedIn={Boolean(token) || isLoggedIn}>
            <SitterDetail />
          </PrivateRoute>
        }
      />
      <Route
        path="/accounts/nanny"
        element={
          <PrivateRoute isLoggedIn={Boolean(token) || isLoggedIn}>
            <NannyList />
          </PrivateRoute>
        }
      />
      <Route
        path="/accounts/nanny/:nannyId"
        element={
          <PrivateRoute isLoggedIn={Boolean(token) || isLoggedIn}>
            <NannyDetail />
          </PrivateRoute>
        }
      />
      <Route
        path="/accounts/admins"
        element={
          <PrivateRoute isLoggedIn={Boolean(token) || isLoggedIn}>
            <Superadmin />
          </PrivateRoute>
        }
      />
      <Route
        path="/accounts/users"
        element={
          <PrivateRoute isLoggedIn={Boolean(token) || isLoggedIn}>
            <Users />
          </PrivateRoute>
        }
      />

      <Route
        path="/finance/top-ups"
        element={
          <PrivateRoute isLoggedIn={Boolean(token) || isLoggedIn}>
            <Payments />
          </PrivateRoute>
        }
      />
      <Route
        path="/finance/payouts"
        element={
          <PrivateRoute isLoggedIn={Boolean(token) || isLoggedIn}>
            <Payouts />
          </PrivateRoute>
        }
      />
      <Route
        path="/finance/payouts/:id"
        element={
          <PrivateRoute isLoggedIn={Boolean(token) || isLoggedIn}>
            <PayoutsDetail />
          </PrivateRoute>
        }
      />
      <Route
        path="/finance/transactions"
        element={
          <PrivateRoute isLoggedIn={Boolean(token) || isLoggedIn}>
            <Transaction />
          </PrivateRoute>
        }
      />

      <Route
        path="/requests/just-once"
        element={
          <PrivateRoute isLoggedIn={Boolean(token) || isLoggedIn}>
            <JustOnce />
          </PrivateRoute>
        }
      />
      <Route
        path="/requests/recurring"
        element={
          <PrivateRoute isLoggedIn={Boolean(token) || isLoggedIn}>
            <Recurring />
          </PrivateRoute>
        }
      />

      <Route
        path="/requests/recurring/:id"
        element={
          <PrivateRoute isLoggedIn={Boolean(token) || isLoggedIn}>
            <RequestsDetail />
          </PrivateRoute>
        }
      />

      <Route
        path="/requests/just-once/:id"
        element={
          <PrivateRoute isLoggedIn={Boolean(token) || isLoggedIn}>
            <RequestsDetail />
          </PrivateRoute>
        }
      />

      <Route
        path="/settings"
        element={
          <PrivateRoute isLoggedIn={Boolean(token) || isLoggedIn}>
            <Settings />
          </PrivateRoute>
        }
      />
      <Route
        path="/finance-settings"
        element={
          <PrivateRoute isLoggedIn={Boolean(token) || isLoggedIn}>
            <FinanceSettings />
          </PrivateRoute>
        }
      />
      <Route
        path="/reports"
        element={
          <PrivateRoute isLoggedIn={Boolean(token) || isLoggedIn}>
            <Reports />
          </PrivateRoute>
        }
      />
      <Route
        path="/nanny-bookings"
        element={
          <PrivateRoute isLoggedIn={Boolean(token) || isLoggedIn}>
            <ListNannyBooking />
          </PrivateRoute>
        }
      />
      <Route
        path="/nanny-bookings/:bookingId"
        element={
          <PrivateRoute isLoggedIn={Boolean(token) || isLoggedIn}>
            <NannyBookingDetail />
          </PrivateRoute>
        }
      />
      <Route
        path="/promotions"
        element={
          <PrivateRoute isLoggedIn={Boolean(token) || isLoggedIn}>
            <Promotions />
          </PrivateRoute>
        }
      />
      <Route
        path="/reviews"
        element={
          <PrivateRoute isLoggedIn={Boolean(token) || isLoggedIn}>
            <Reviews />
          </PrivateRoute>
        }
      />

      <Route
        path="/user-flags"
        element={
          <PrivateRoute isLoggedIn={Boolean(token) || isLoggedIn}>
            <UserFlags />
          </PrivateRoute>
        }
      />

      <Route path="*" element={<p>There's nothing here: 404!</p>} />
    </Routes>
  );
};

export default AppRoute;
