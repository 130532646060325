import React from 'react';

const ReceiptItem = ({ ...props }) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.42 11.75H16C15.59 11.75 15.25 11.41 15.25 11V4.01C15.25 3.27 15.54 2.58 16.06 2.06C16.58 1.54 17.27 1.25 18.01 1.25H18.02C19.27 1.26 20.45 1.75 21.35 2.64C22.25 3.55 22.74 4.75 22.74 6V8.42C22.75 10.41 21.41 11.75 19.42 11.75ZM16.75 10.25H19.42C20.58 10.25 21.25 9.58 21.25 8.42V6C21.25 5.14 20.91 4.32 20.3 3.7C19.69 3.1 18.87 2.76 18.02 2.75C18.02 2.75 18.02 2.75 18.01 2.75C17.68 2.75 17.36 2.88 17.12 3.12C16.88 3.36 16.75 3.67 16.75 4.01V10.25Z"
        fill={props.active ? '#FFF' : '#8E8E93'}
      />
      <path
        d="M9 23.33C8.53 23.33 8.09 23.15 7.76 22.81L6.1 21.14C6.01 21.05 5.87 21.04 5.77 21.12L4.05 22.4C3.52 22.8 2.82 22.87 2.22 22.57C1.62 22.27 1.25 21.67 1.25 21V6C1.25 2.98 2.98 1.25 6 1.25H18C18.41 1.25 18.75 1.59 18.75 2C18.75 2.41 18.41 2.75 18 2.75C17.31 2.75 16.75 3.31 16.75 4V21C16.75 21.67 16.38 22.27 15.78 22.57C15.18 22.87 14.48 22.81 13.95 22.41L12.24 21.13C12.14 21.05 12 21.07 11.92 21.15L10.24 22.83C9.91 23.15 9.47 23.33 9 23.33ZM5.91 19.57C6.37 19.57 6.82 19.74 7.16 20.09L8.82 21.76C8.88 21.82 8.96 21.83 9 21.83C9.04 21.83 9.12 21.82 9.18 21.76L10.86 20.08C11.48 19.46 12.46 19.4 13.15 19.93L14.85 21.2C14.96 21.28 15.06 21.25 15.11 21.22C15.16 21.19 15.25 21.13 15.25 21V4C15.25 3.55 15.36 3.12 15.55 2.75H6C3.78 2.75 2.75 3.78 2.75 6V21C2.75 21.14 2.84 21.2 2.89 21.23C2.95 21.26 3.05 21.28 3.15 21.2L4.86 19.92C5.17 19.69 5.54 19.57 5.91 19.57Z"
        fill={props.active ? '#FFF' : '#8E8E93'}
      />
      <path
        d="M12 13.76H9C8.59 13.76 8.25 13.42 8.25 13.01C8.25 12.6 8.59 12.26 9 12.26H12C12.41 12.26 12.75 12.6 12.75 13.01C12.75 13.42 12.41 13.76 12 13.76Z"
        fill={props.active ? '#FFF' : '#8E8E93'}
      />
      <path
        d="M12 9.76001H9C8.59 9.76001 8.25 9.42001 8.25 9.01001C8.25 8.60001 8.59 8.26001 9 8.26001H12C12.41 8.26001 12.75 8.60001 12.75 9.01001C12.75 9.42001 12.41 9.76001 12 9.76001Z"
        fill={props.active ? '#FFF' : '#8E8E93'}
      />
      <path
        d="M5.97021 10.01C5.42021 10.01 4.97021 9.56001 4.97021 9.01001C4.97021 8.46001 5.42021 8.01001 5.97021 8.01001C6.52021 8.01001 6.97021 8.46001 6.97021 9.01001C6.97021 9.56001 6.52021 10.01 5.97021 10.01Z"
        fill={props.active ? '#FFF' : '#8E8E93'}
      />
      <path
        d="M5.97021 14.01C5.42021 14.01 4.97021 13.56 4.97021 13.01C4.97021 12.46 5.42021 12.01 5.97021 12.01C6.52021 12.01 6.97021 12.46 6.97021 13.01C6.97021 13.56 6.52021 14.01 5.97021 14.01Z"
        fill={props.active ? '#FFF' : '#8E8E93'}
      />
    </svg>
  );
};

export default ReceiptItem;
