import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { REPORT_LIST_STATE, GET_REPORT_LIST_PARAMS } from 'models';

export interface ReportsState {
  reports: {
    report_list: REPORT_LIST_STATE;
  };
}

const initialState: ReportsState = {
  reports: {
    report_list: {
      records: [],
      metadata: { page: 1, limit: 50, page_count: 1, total_pages: 1, total_count: 1 },
      params: { page: 1, limit: 50 },
    },
  }
};

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setReportListSuccess(
      state: ReportsState,
      action: PayloadAction<REPORT_LIST_STATE>
    ) {
      state.reports.report_list = {
        ...state.reports.report_list,
        ...action.payload,
      };
    },
    setReportListParams(
      state: ReportsState,
      action: PayloadAction<GET_REPORT_LIST_PARAMS | null>
    ) {
      state.reports.report_list.params = action.payload;
    },
  },
});

// Actions
export const {
  setReportListSuccess,
  setReportListParams,
} = reportsSlice.actions;

export default reportsSlice.reducer;
