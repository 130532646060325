import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { NotifyService } from 'helpers';
import {
  FLAG_BY_USER_TYPE,
  FLAG_LIST_STATE,
  GET_USER_FLAG_LIST_PARAMS_REQUEST,
} from 'models';
import { call, put, takeLatest } from 'redux-saga/effects';
import { getSitterFlagListApi, getUserFlagWithApi } from 'services/user-flags';
import { setGlobalLoading } from 'store/reducers/global';
import {
  setflagByUserSuccess,
  setflagListSuccess,
} from 'store/reducers/user-flag';
import {
  GET_FLAG_BY_USER_REQUEST,
  GET_FLAG_LIST_REQUEST,
} from 'store/reducers/user-flag/actionType';

function* getuserFlagsRequest(
  action: PayloadAction<{
    params?: GET_USER_FLAG_LIST_PARAMS_REQUEST;
  }>
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<{
      data: FLAG_LIST_STATE;
    }> = yield call(getUserFlagWithApi, action.payload.params);

    yield put(
      setflagListSuccess({
        records: response.data.data.records,
        metadata: response.data.data.metadata,
      })
    );
    yield put(setGlobalLoading(false));
  } catch (error: unknown) {
    NotifyService.error(error);
  }
}

function* getuserFlagsByUserRequest(
  action: PayloadAction<{
    id: string;
  }>
) {
  try {
    yield put(setGlobalLoading(true));
    const response: AxiosResponse<{
      data: FLAG_BY_USER_TYPE[];
    }> = yield call(getSitterFlagListApi, action.payload.id);

    yield put(
      setflagByUserSuccess({
        data: response.data.data,
      })
    );
    yield put(setGlobalLoading(false));
  } catch (error: unknown) {
    NotifyService.error(error);
  }
}

function* flags() {
  yield takeLatest(GET_FLAG_LIST_REQUEST, getuserFlagsRequest);
  yield takeLatest(GET_FLAG_BY_USER_REQUEST, getuserFlagsByUserRequest);
}

export default flags;
