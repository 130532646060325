import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const DetailItemTitle = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  color: #8E8E93;
  margin-bottom: 5px;
`

export const DetailItemData = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  color: #000000;
`