import {
  Avatar,
  Box,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { CloseCircle } from 'asset';
import { LoadingPopup } from 'components/common';
import { changeAge, NotifyService } from 'helpers';
import { useDateTime } from 'hooks';
import { JOB_STATUSES, JOB_DETAIL_TYPE } from 'models';
import React, { useEffect, useState } from 'react';
import { getJobDetailApi } from 'services/jobs';

interface Props {
  id?: string;
  open: boolean;
  handleClosePopup: () => void;
}

const PopupDetailJobs = (props: Props) => {
  const { open, id, handleClosePopup } = props;
  const { formatDateTime } = useDateTime();
  const [data, setData] = useState<JOB_DETAIL_TYPE>();
  const [loadingPopup, setLoadingPopup] = useState(false);

  useEffect(() => {
    if (id) {
      setLoadingPopup(true);
      const getDetail = async () => {
        await getJobDetailApi(id)
          .then(({ data }) => {
            setData(data.data);
            setLoadingPopup(false);
          })
          .catch((e) => NotifyService.error(e))
          .finally(() => {});
      };
      getDetail();
    }
  }, [id]);

  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiPaper-root': {
          width: 523,
          height: 'auto',
          borderRadius: 0,
        },
      }}
    >
      <Grid
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '20px 25px',
        }}
      >
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '100%',
          }}
        >
          Jobs Detail
        </Typography>
        <IconButton
          onClick={handleClosePopup}
          sx={{
            padding: 0,
          }}
        >
          <CloseCircle />
        </IconButton>
      </Grid>
      <Divider />
      {loadingPopup && <LoadingPopup color="primary" />}
      <form style={{ height: '100%' }}>
        <Stack
          p="30px 24px 50px 26px"
          height="100%"
          justifyContent="space-between"
        >
          <Box mb="30px">
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '100%',
                color: '#8E8E93',
                mb: '5px',
              }}
            >
              Job No.
            </Typography>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: '18px',
                lineHeight: '100%',
                color: '#000',
              }}
            >
              {data?.number}
            </Typography>
          </Box>
          <Box display="flex">
            <Box mr="30px">
              <Box mb="30px">
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '100%',
                    color: '#8E8E93',
                    mb: '5px',
                  }}
                >
                  Sitter
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '100%',
                    color: '#226FE3',
                  }}
                >
                  {data?.sitter?.first_name}&nbsp;{data?.sitter?.last_name}
                </Typography>
              </Box>
              <Box mb="30px">
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '100%',
                    color: '#8E8E93',
                    mb: '5px',
                  }}
                >
                  From
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '100%',
                    color: '#000',
                  }}
                >
                  {formatDateTime(data?.start_datetime?.date, 'DD MMM YYYY')}
                  &nbsp;{formatDateTime(data?.start_datetime?.time, 'HH:mm')}
                </Typography>
              </Box>
              <Box mb="30px">
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '100%',
                    color: '#8E8E93',
                    mb: '5px',
                  }}
                >
                  To
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '100%',
                    color: '#000',
                  }}
                >
                  {formatDateTime(data?.end_datetime?.date, 'DD MMM YYYY')}
                  &nbsp;
                  {formatDateTime(data?.end_datetime?.time, 'HH:mm')}
                </Typography>
              </Box>
              <Box mb="30px">
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '100%',
                    color: '#8E8E93',
                    mb: '5px',
                  }}
                >
                  Child
                </Typography>
                <Stack spacing={'16px'}>
                  {data?.children?.map((child, index) => (
                    <Stack key={index} direction="row" alignItems="center">
                      <Avatar
                        sx={{
                          background: 'rgba(255, 121, 110, 0.2)',
                          width: 44,
                          height: 44,
                        }}
                        alt={child.full_name}
                        src={child.picture ?? undefined}
                      />
                      <Stack ml="8px">
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: '13px',
                            lineHeight: '20px',
                            letterSpacing: '0.1px',
                            mb: '2px',
                          }}
                        >
                          {child.full_name}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: '13px',
                            lineHeight: '20px',
                            letterSpacing: '0.1px',
                          }}
                        >
                          {changeAge(child.age_range)}
                        </Typography>
                      </Stack>
                    </Stack>
                  ))}
                </Stack>
              </Box>
            </Box>
            <Box>
              <Box mb="30px">
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '100%',
                    color: '#8E8E93',
                    mb: '5px',
                  }}
                >
                  User
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '100%',
                    color: '#226FE3',
                  }}
                >
                  {data?.parent?.first_name}&nbsp;{data?.parent?.last_name}
                </Typography>
              </Box>
              <Box mb="30px">
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '100%',
                    color: '#8E8E93',
                    mb: '5px',
                  }}
                >
                  Status
                </Typography>
                <Grid container alignItems="center" textTransform="capitalize">
                  <Grid
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Grid
                      sx={{
                        width: '8px',
                        height: '8px',
                        borderRadius: '50%',
                        marginRight: '5px',
                        marginBottom: '2px',
                        backgroundColor: '#B6B6B6',
                      }}
                    />
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '100%',
                        color: '#000',
                      }}
                    >
                      {JOB_STATUSES.find((it) => it.id === data?.status)?.name}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box mb="30px">
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '100%',
                    color: '#8E8E93',
                    mb: '5px',
                  }}
                >
                  Total Amount
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '100%',
                    color: '#34C759',
                  }}
                >
                  ${data?.total}
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '100%',
                    color: '#8E8E93',
                    mb: '5px',
                  }}
                >
                  Address
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '100%',
                    color: '#000000',
                  }}
                >
                  {data?.address?.street} {data?.address?.name}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Stack>
      </form>
    </Dialog>
  );
};

export default PopupDetailJobs;
