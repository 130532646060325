import { HeadCell, REPORT_TYPE } from 'models';

export const headCells: readonly HeadCell<REPORT_TYPE>[] = [
  {
    id: 'target',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Reported User',
  },
  {
    id: 'ReportReason',
    numeric: false,
    disablePadding: true,
    isSort: false,
    label: 'Reason',
  },
  {
    id: 'actor',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Reporter',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Status',
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Reported on',
  },
];
