import { AxiosResponse } from 'axios';
import { API } from 'config';
import {
  GET_USERS_OPTION_PARAMS_REQUEST,
  GET_USERS_PARAMS_REQUEST,
  LIST_BODY_RESULT,
  USER_OPTION_TYPE,
  USER_DETAIL_TYPE,
  EDIT_USER_TYPE,
} from 'models';
import { AppAPIInstance } from 'services';

export const getUsersOptionApi = async (
  params?: GET_USERS_OPTION_PARAMS_REQUEST
): Promise<AxiosResponse<{ data: LIST_BODY_RESULT<USER_OPTION_TYPE> }>> => {
  return await AppAPIInstance.get(API.ADMIN.USER.DEFAULT, {
    params,
  });
};

export const getUsersListApi = async (params?: GET_USERS_PARAMS_REQUEST) => {
  return await AppAPIInstance.get(API.ADMIN.USER.ALL, {
    params,
  });
};

export const getUserDetailApi = async (
  id: string
): Promise<AxiosResponse<{ data: USER_DETAIL_TYPE }>> => {
  return await AppAPIInstance.get(`${API.ADMIN.USER.ALL}/${id}`);
};

export const editUsersApi = async (id: string, data?: EDIT_USER_TYPE) => {
  return await AppAPIInstance.put(`${API.ADMIN.USER.ALL}/${id}`, data);
};

export const deleteUsersApi = async (id: string) => {
  return await AppAPIInstance.delete(`${API.ADMIN.USER.ALL}/${id}`);
};
