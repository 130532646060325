import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AuthenticationType {
  isLoggedIn: boolean;
}

const initialState: AuthenticationType = {
  isLoggedIn: false,
};

const authenticationSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setLoggedInSuccess(
      state: AuthenticationType,
      action: PayloadAction<boolean>
    ) {
      state.isLoggedIn = action.payload;
    },
  },
});

// Actions
export const { setLoggedInSuccess } = authenticationSlice.actions;

export default authenticationSlice.reducer;
