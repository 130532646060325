import {
  AppBar,
  Button,
  buttonClasses,
  Checkbox,
  Grid,
  InputAdornment,
  InputBase,
  inputBaseClasses,
  Menu,
  Paper,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import usePagination from '@mui/material/usePagination/usePagination';
import {
  DirectionDown,
  DocumentDownload,
  Eye,
  Magnifier,
  PendingSittersIcon,
} from 'asset';
import {
  DeleteDialog,
  FilterMenu,
  HeadTable,
  Layout,
  PopupCreateOrEditSitter,
  TableCaption,
} from 'components';
import DeleteAllDialog from 'components/common/DeleteAllDialog';
import { ButtonDelete, ButtonEdit } from 'components/common/table';
import PopupWarningApprove from 'components/Popups/Warning/PopupWarningApprove';
import { drawerWidth } from 'config';
import FileSaver from 'file-saver';
import { getSortItem, NotifyService, printPrice } from 'helpers';
import { useDateTime } from 'hooks';
import useDebounce from 'hooks/useDebounce';
import {
  DELETE_DIALOG_TYPE,
  FilterOption,
  FilterOptionType,
  FilterValue,
  GET_SITTERS_PARAMS_REQUEST,
  HeadCell,
  SITTER_STATUS,
  SITTER_TYPE,
  TYPE_POPUP,
} from 'models';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  deleteAllSitterApi,
  getSittersExportCSVApi,
  updateManySitterStatusApi,
  updateSitterStatusApi,
} from 'services';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setSitterListParams } from 'store/reducers/accounts';
import { getSitterListRequest } from 'store/reducers/accounts/actionTypes';

const filterOptions: FilterOption[] = [
  {
    name: 'First Name',
    key: 'first_name',
    type: FilterOptionType.TEXT,
    placeholder: 'Enter first name',
  },
  {
    name: 'Last Name',
    key: 'last_name',
    type: FilterOptionType.TEXT,
    placeholder: 'Enter last Name',
  },
  {
    name: 'Email',
    key: 'email',
    type: FilterOptionType.TEXT,
    placeholder: 'Enter email',
  },
  {
    name: 'Pending Payouts',
    key: 'pending_payout',
    type: FilterOptionType.SINGLE_CHECKBOX,
  },
];

const headCells: readonly HeadCell[] = [
  {
    id: 'first_name',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Sitter name',
  },
  {
    id: 'phone_number',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Phone number',
  },
  {
    id: 'rating',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Rating',
  },
  {
    id: 'payout_pending',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Pending payouts',
  },
  {
    id: 'balance',
    numeric: true,
    disablePadding: false,
    isSort: true,
    label: 'Wallet balance',
  },
  {
    id: 'payoutMethod[account_holder_name]',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Paynow name',
  },
  {
    id: 'payoutMethod[bank_account_number]',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Paynow number',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    isSort: true,
    label: 'Status',
  },
];

const SitterList = () => {
  const theme = useTheme();
  const { moment } = useDateTime();
  const dispatch = useAppDispatch();
  const { sitter_list } = useAppSelector(
    (state) => state.accountReducer.sitters
  );
  const { params } = sitter_list;

  const [anchorElAction, setAnchorElAction] = useState<null | HTMLElement>(
    null
  );
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [keyword, setKeyword] = useState('');
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [deleteItem, setDeleteItem] = useState<any>();
  const [openDeleteAll, setOpenDeleteAll] = useState<boolean>(false);
  const [openApprove, setOpenApprove] = useState<boolean>(false);
  const [openApproveAll, setOpenApproveAll] = useState<boolean>(false);
  const [isOpenCreate, setIsOpenCreate] = useState<boolean>(false);
  const [editItem, setEditItem] = useState<SITTER_TYPE>();

  useEffect(() => {
    dispatch(getSitterListRequest(params));
  }, [params, dispatch]);

  useEffect(() => {
    setKeyword(params?.keyword ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSetSitterListParams = (params: GET_SITTERS_PARAMS_REQUEST | null) => {
    dispatch(setSitterListParams(params));
  };

  const setOpenEdit = (event: any, item: SITTER_TYPE) => {
    event.stopPropagation();
    setEditItem(item);
  };

  const setOpenCreate = () => {
    setIsOpenCreate(true);
  };

  const handleOpenBulkAction = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElAction(event.currentTarget);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      console.log(event.target.checked);
      const newSelected = sitter_list.records.map((n) => n.sitter_id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (_: any, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangeRowsPerPage = (event: SelectChangeEvent) => {
    const limit = parseInt(event.target.value, 10);
    onSetSitterListParams({ ...params, page: 1, limit });
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const { items } = usePagination({
    count: sitter_list.metadata.total_pages,
    page: sitter_list.metadata.page,
    onChange(_event, page) {
      onSetSitterListParams({ ...params, page: page });
    },
  });

  const onSetKeywordParams = useDebounce((keyword: string) => {
    const _params = { ...params, page: 1 };
    if (keyword) _params.keyword = keyword;
    else delete _params.keyword;
    onSetSitterListParams(_params);
  }, 250);

  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(event.target.value);
    onSetKeywordParams(event.target.value);
  };

  const onClosePopup = (type: TYPE_POPUP) => {
    switch (type) {
      case TYPE_POPUP.DELETE:
        setOpenDeletePopup(false);
        setDeleteItem(undefined);
        break;
      case TYPE_POPUP.CREATE_OR_EDIT:
        setIsOpenCreate(false);
        setEditItem(undefined);
        break;
      case TYPE_POPUP.DELETE_ALL:
        setOpenDeleteAll(false);
        break;
      case TYPE_POPUP.APPROVE_ALL:
        setOpenApproveAll(false);
        break;
      case TYPE_POPUP.APPROVE:
        setOpenApprove(false);
        break;
    }
  };

  const setOpenDelete = (event: any, item: any) => {
    event.stopPropagation();
    setDeleteItem(item);
    setOpenDeletePopup(true);
  };

  const onReloadList = () => {
    dispatch(getSitterListRequest(params));
  };

  const approveSitter = async () => {
    if (!deleteItem?.sitter_id) return;
    await updateSitterStatusApi(deleteItem?.sitter_id, SITTER_STATUS.APPROVED)
      .then((r) => {
        onReloadList();
        onClosePopup(TYPE_POPUP.APPROVE);
        NotifyService.success(r);
      })
      .catch((e) => NotifyService.error(e))
      .finally(() => {});
  };

  const approveManySitter = async () => {
    await updateManySitterStatusApi({
      sitterIds: [...selected],
      status: SITTER_STATUS.APPROVED,
    })
      .then((r) => {
        onReloadList();
        onClosePopup(TYPE_POPUP.APPROVE_ALL);
        setSelected([]);
        NotifyService.success(r);
      })
      .catch((e) => NotifyService.error(e))
      .finally(() => {});
  };

  const deleteManySitter = async () => {
    const sitterIds: any = selected;
    await deleteAllSitterApi(sitterIds)
      .then((r) => {
        onReloadList();
        onClosePopup(TYPE_POPUP.DELETE_ALL);
        setSelected([]);
        NotifyService.success(r);
      })
      .catch((e) => NotifyService.error(e))
      .finally(() => {});
  };

  const filterValue: FilterValue = useMemo(() => {
    return {
      first_name: sitter_list.params?.first_name,
      last_name: sitter_list.params?.last_name,
      email: sitter_list.params?.email,
      pending_payout: sitter_list.params?.pending_payout,
    };
  }, [sitter_list.params]);

  const onChangeFilter = (value: FilterValue) => {
    setSelected([]);
    const _params = { ...params };
    delete _params.first_name;
    delete _params.last_name;
    delete _params.email;
    delete _params.pending_payout;
    onSetSitterListParams({
      ..._params,
      ...value,
      page: 1,
    });
  };

  const sortItem = useMemo(() => {
    return getSortItem(sitter_list.params?.sort);
  }, [sitter_list.params]);

  const isPendding = useMemo(() => {
    return params?.status === SITTER_STATUS.PENDING;
  }, [params]);

  const handleRequestSort = (
    _: React.MouseEvent<unknown>,
    property: keyof SITTER_TYPE
  ) => {
    setSelected([]);
    const isAsc = sortItem?.orderBy === property && sortItem?.order === 'asc';
    onSetSitterListParams({
      ...params,
      sort: `${property}-${isAsc ? 'desc' : 'asc'}`,
    });
  };

  const handlePendingSitters = () => {
    const _params = { ...params };
    if (isPendding) delete _params.status;
    else _params.status = SITTER_STATUS.PENDING;
    setSelected([]);
    onSetSitterListParams({ ..._params, page: 1 });
  };

  const onExportCSV = async () => {
    await getSittersExportCSVApi({
      sort: params?.sort,
      ...params,
    })
      .then((res) => {
        FileSaver.saveAs(
          res.data,
          `${moment().format('YYYY-MM-DD')} Sitters.csv`
        );
      })
      .catch((e) => NotifyService.error(e));
  };

  return (
    <Layout>
      <AppBar
        position="fixed"
        sx={{
          width: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`,
          backgroundColor: 'white.light',
          borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
        }}
        elevation={0}
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Grid>
            <Typography
              sx={{
                fontSize: '20px',
                lineHeight: 1,
                color: 'grey1.light',
              }}
            >
              Accounts / Sitters
            </Typography>
          </Grid>
          <Grid display="flex" gap="8px" flexGrow={1} justifyContent="center">
            <InputBase
              placeholder="Search"
              sx={{
                maxWidth: '369px',
                flexGrow: 1,
                // paddingRight: '9px',
                background: '#F2F4F6',
                border: '1px solid #D9D9D9',
                p: '0px 7px 0px 11px',
                borderRadius: '5px',
                [`& .${inputBaseClasses.input}`]: {
                  fontSize: 14,
                  fontWeight: 400,
                  lineHeight: '100%',
                  p: 0,
                  height: 'fit-content',
                },
              }}
              onChange={onChangeSearch}
              // autoComplete=""
              value={keyword}
              endAdornment={
                <InputAdornment position="end">
                  <Magnifier />
                </InputAdornment>
              }
            />
            <FilterMenu
              filterOptions={filterOptions}
              filterValue={filterValue}
              onChange={onChangeFilter}
            />
          </Grid>
          <Grid display="flex" gap="8px">
            <Button
              sx={{
                background: '#fff',
                border: `1px solid #226FE3`,
                borderRadius: '3px',
                boxShadow: 'none',

                color: '#226FE3',
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '100%',
                textTransform: 'none',

                p: '6px 16px',
                height: '30px',
                alignItems: 'flex-end',
                ':hover': {
                  background: 'rgba(34, 111, 227, 0.08)',
                  border: `1px solid ${theme.palette.secondary.main}`,
                  borderRadius: '3px',
                  boxShadow: 'none',
                },
                [`& .${buttonClasses.endIcon}`]: {
                  mb: '-3px',
                  ml: '3px',
                },
              }}
              variant="contained"
              endIcon={<DirectionDown />}
              onClick={handleOpenBulkAction}
            >
              Bulk Action
            </Button>
            <Menu
              sx={{
                mt: '45px',
                '& .MuiList-padding': {
                  pt: '0px !important',
                  pb: '0px !important',
                },
              }}
              id="menu-appbar"
              anchorEl={anchorElAction}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElAction)}
              onClose={() => setAnchorElAction(null)}
            >
              <Stack
                sx={{
                  width: '319px',
                  p: '14px 22px 25px 23px',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  ':hover': {
                    backgroundColor: 'white.light',
                  },
                }}
              >
                <Stack spacing="10px">
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: '16px',
                      lineHeight: '100%',
                    }}
                  >
                    Bulk Actions
                  </Typography>
                  <Button
                    // startIcon={<Check />}
                    variant="outlined"
                    color="secondary"
                    sx={{
                      p: 0,
                      width: '250px',
                      height: '32px',
                      fontWeight: 600,
                      fontSize: '15px',
                      lineHeight: 1,
                      borderRadius: '10px',
                      '& .MuiButton-startIcon': {
                        mr: '5px',
                      },
                    }}
                    onClick={() => setOpenApproveAll(true)}
                  >
                    Approve Pending Profiles
                  </Button>
                  <Button
                    // startIcon={<Check />}
                    variant="outlined"
                    color="secondary"
                    sx={{
                      p: 0,
                      width: '225px',
                      height: '32px',
                      fontWeight: 600,
                      fontSize: '15px',
                      lineHeight: 1,
                      borderRadius: '10px',
                      '& .MuiButton-startIcon': {
                        mr: '5px',
                      },
                    }}
                    onClick={() => setOpenDeleteAll(true)}
                  >
                    Delete Selected Profiles
                  </Button>
                </Stack>
              </Stack>
            </Menu>
            <Button
              variant={isPendding ? 'contained' : 'outlined'}
              endIcon={<PendingSittersIcon active={isPendding} />}
              sx={{
                height: 30,
                fontWeight: 600,
                fontSize: '13px',
                '& .MuiButton-endIcon': {
                  paddingBottom: '2px',
                },
              }}
              onClick={handlePendingSitters}
            >
              Pending Sitters{' '}
              {`(${sitter_list.metadata.pending_sitters_count})`}
            </Button>
            <Button
              sx={{
                width: '131px',
                height: 30,
                fontWeight: 600,
                fontSize: '13px',
                border: `1px solid ${theme.palette.secondary.main}`,
                p: '0px',
              }}
              variant="outlined"
              color="secondary"
              endIcon={<DocumentDownload />}
              onClick={onExportCSV}
            >
              Export to CSV
            </Button>
            <Button
              variant="contained"
              color="secondary"
              sx={{
                height: 30,
                width: '72px',
                fontWeight: 600,
                fontSize: '13px',
                lineHeight: '100%',
              }}
              onClick={setOpenCreate}
            >
              Create
            </Button>
          </Grid>
        </Toolbar>
      </AppBar>
      <Stack spacing="8px">
        <TableContainer
          component={Paper}
          elevation={0}
          sx={{
            border: '1px solid #D9D9D9',
            maxHeight: 'calc(100vh - 150px)',
            maxWidth: 'calc(100vw - 282px)',
          }}
        >
          <Table
            stickyHeader
            sx={{ minWidth: 650 }}
            aria-labelledby="tableTitle"
          >
            <HeadTable<SITTER_TYPE>
              numSelected={selected.length}
              order={sortItem?.order}
              orderBy={sortItem?.orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={sitter_list.records.length}
              headCells={headCells}
            />
            <TableBody
              sx={{
                height: 'auto !important',
              }}
            >
              {sitter_list.records.map((row, index) => {
                const isItemSelected = isSelected(row.sitter_id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.sitter_id}
                    selected={isItemSelected}
                  >
                    <TableCell
                      padding="checkbox"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                      }}
                    >
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        onChange={(e) => handleClick(e, row.sitter_id)}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {`${row.first_name} ${row.last_name} (${row.email})`}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {row.phone_number}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                        whiteSpace: 'nowrap',
                        color:
                          parseInt(row.rating) < 5
                            ? 'red.light'
                            : 'black.light',
                      }}
                    >
                      {row.rating}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {row.payout_pending ? 'Yes' : 'No'}
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {printPrice(row.balance)}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                      }}
                    >
                      {row.payoutMethod.pay_now_name}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                      }}
                    >
                      {row.payoutMethod.pay_now_number}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                        fontSize: '14px',
                        fontWeight: 400,
                      }}
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        textTransform="capitalize"
                      >
                        <Grid
                          sx={{
                            width: '8px',
                            height: '8px',
                            borderRadius: '50%',
                            marginRight: '5px',
                            backgroundColor:
                              row.status === 'active'
                                ? theme.palette.green.light
                                : row.status === 'pending'
                                ? 'yellow.light'
                                : '#B6B6B6',
                          }}
                        />
                        <Typography
                          sx={{
                            fontSize: '14px',
                            fontWeight: 400,
                          }}
                        >
                          {row.status}
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderRight: '1px solid #DEE2E6',
                        padding: '18px 12px 17px',
                      }}
                    >
                      <Stack direction="row" spacing="10px">
                        <Link to={`/accounts/sitters/${row.sitter_id}`}>
                          <Button
                            sx={{
                              minWidth: '38px !important',
                              height: '32px',
                              '&.MuiButton-root': {
                                p: '0px !important',
                              },
                            }}
                            variant="contained"
                            color="secondary"
                          >
                            <Eye />
                          </Button>
                        </Link>
                        <ButtonEdit onClick={(e) => setOpenEdit(e, row)} />
                        <ButtonDelete onClick={(e) => setOpenDelete(e, row)} />
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TableCaption
          rowsPerPage={params?.limit ?? 0}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          metadata={sitter_list.metadata}
          items={items}
        />
      </Stack>

      <PopupCreateOrEditSitter
        cleanDetail={true}
        open={isOpenCreate || !!editItem}
        subject={editItem?.sitter_id}
        onSuccess={onReloadList}
        handleClosePopup={() => onClosePopup(TYPE_POPUP.CREATE_OR_EDIT)}
      />
      {openDeletePopup && (
        <DeleteDialog
          open={openDeletePopup}
          onClose={() => onClosePopup(TYPE_POPUP.DELETE)}
          subject={deleteItem?.sitter_id}
          onSuccess={onReloadList}
          type={DELETE_DIALOG_TYPE.SITTER}
          name={deleteItem?.first_name}
        />
      )}
      {openDeleteAll && (
        <DeleteAllDialog
          open={openDeleteAll}
          onClose={() => setOpenDeleteAll(false)}
          onClickDelete={deleteManySitter}
        />
      )}
      {openApprove && (
        <PopupWarningApprove
          open={openApprove}
          onClose={() => setOpenApprove(false)}
          onClickApprove={approveSitter}
        />
      )}
      {openApproveAll && (
        <PopupWarningApprove
          open={openApproveAll}
          onClose={() => setOpenApproveAll(false)}
          onClickApprove={approveManySitter}
        />
      )}
    </Layout>
  );
};

export default SitterList;
