import React from 'react';

const Check = ({ ...props }) => {
  return (
    <svg
      width={11}
      height={8}
      viewBox="0 0 11 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 4L3.82843 6.82842L9.48528 1.17157"
        stroke="#226FE3"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Check;
