import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { FormHelperText, InputLabel, Theme, useTheme } from '@mui/material';
import { METADATA_TYPE } from 'models';
import { memo, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import ReactSelect, {
  components,
  DropdownIndicatorProps,
  GroupBase,
  StylesConfig,
} from 'react-select';
import { StateManagerProps } from 'react-select/dist/declarations/src/stateManager';
import useDebounce from 'hooks/useDebounce';

const customStyles = (
  theme: Theme,
  _?: boolean
): StylesConfig<any, boolean, GroupBase<unknown>> => ({
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    '> div': {
      padding: '6px',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    fontStyle: 'normal',
    fontSize: 13,
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    color:
      state.isSelected || state.isFocused
        ? theme.palette.white.light
        : theme.palette.text.primary,
    background:
      state.isSelected || state.isFocused
        ? theme.palette.secondary.main
        : theme.palette.white.light,
  }),
  placeholder: (provided) => ({
    ...provided,
  }),
  valueContainer: (provided) => ({
    ...provided,
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: 13,
    color: theme.palette.text.primary,
  }),
  input: (provided) => ({
    ...provided,
    fontSize: 13,
    color: theme.palette.text.primary,
  }),
  control: (provided) => ({
    ...provided,
    borderRadius: '5px',
    border: '1px solid #D5D5D5',
    backgroundColor: theme.palette.white.light,
  }),
});

const DropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      {props.selectProps.menuIsOpen ? (
        <KeyboardArrowUp sx={{ color: 'black.light' }} />
      ) : (
        <KeyboardArrowDown sx={{ color: 'black.light' }} />
      )}
    </components.DropdownIndicator>
  );
};

export interface GetOptionsResponse {
  options: any;
  metadata: METADATA_TYPE;
}

interface Props extends StateManagerProps {
  title?: string;
  name: string;
  form?: any;
  rules?: any;
  errorsName?: any;
  bindKey?: string;
  bindLabel?: string;
  isHasMore?: boolean;
  filterFunc?: boolean;
  loadOptionInit?: boolean;
  refreshWhenOpen?: boolean;
  getOptions?: (
    page?: number,
    limit?: number,
    keyword?: string
  ) => Promise<GetOptionsResponse | null | undefined> | null | undefined;
}

const Selects = memo(
  ({
    title,
    name,
    form,
    rules,
    errorsName,
    bindKey,
    refreshWhenOpen,
    bindLabel,
    isHasMore,
    filterFunc = true,
    getOptions,
    loadOptionInit,
    ...rest
  }: Props) => {
    const theme = useTheme();

    const {
      control,
      formState: { errors },
    } = form || { formState: {} };

    const [keyword, setKeyword] = useState<string>();
    const [options, setOptions] = useState<any[]>([]);
    const [metadata, setMetadata] = useState<METADATA_TYPE>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onMenuScrollToBottom = useDebounce(async () => {
      if (
        isLoading ||
        !isHasMore ||
        !getOptions ||
        !metadata ||
        metadata.page >= metadata.total_pages ||
        !options?.length
      )
        return;
      setIsLoading(true);
      const newPage = metadata.page + 1;
      const data = await getOptions(newPage, metadata.limit, keyword);
      setOptions((pre) => pre.concat(data?.options || []));
      setMetadata(data?.metadata);
      setIsLoading(false);
    }, 200);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onInputChange = useDebounce(async (newValue: string) => {
      if (!isHasMore || !getOptions || (!keyword && !newValue)) return;
      setKeyword(newValue);

      if (!isLoading) {
        setIsLoading(true);
        const data = await getOptions(1, metadata?.limit, newValue);
        setMetadata(data?.metadata);
        setOptions(data?.options);
        setIsLoading(false);
      }
      //====
    }, 200);

    const onMenuOpen = async () => {
      if (
        !isHasMore ||
        isLoading ||
        (options?.length && !refreshWhenOpen) ||
        !getOptions
      )
        return;
      setIsLoading(true);
      const data = await getOptions(1, metadata?.limit);
      setMetadata(data?.metadata);
      setOptions(data?.options);
      setIsLoading(false);
      setKeyword('');
    };

    useEffect(() => {
      const getOptionsInit = async () => {
        if (!isHasMore || !getOptions) return;
        setIsLoading(true);
        const data = await getOptions(1, metadata?.limit);
        setMetadata(data?.metadata);
        setOptions(data?.options);
        setIsLoading(false);
      };
      if (loadOptionInit) {
        getOptionsInit();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <>
        {!!title && (
          <InputLabel
            sx={{
              fontWeight: 500,
              fontSize: '15px',
              lineHeight: '100%',
              color: '#000',
              marginBottom: '5px',
            }}
          >
            {title}
          </InputLabel>
        )}
        {form ? (
          <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field }) => (
              <ReactSelect
                {...field}
                menuPosition="fixed"
                styles={customStyles(
                  theme,
                  !!errorsName || (errors as any)?.[name]
                )}
                getOptionValue={(option) => option[bindKey || 'id']}
                getOptionLabel={(option) => option[bindLabel || 'label']}
                components={{ DropdownIndicator }}
                isLoading={isLoading}
                options={options}
                filterOption={
                  filterFunc
                    ? (option: any, input) => {
                        return (option?.label || '')
                          .toLocaleLowerCase()
                          .includes((input || '').toLocaleLowerCase());
                      }
                    : () => true
                }
                onMenuOpen={onMenuOpen}
                onInputChange={onInputChange}
                onMenuScrollToBottom={onMenuScrollToBottom}
                {...rest}
              />
            )}
          />
        ) : (
          <ReactSelect
            menuPosition="fixed"
            styles={customStyles(
              theme,
              !!errorsName || (errors as any)?.[name]
            )}
            getOptionValue={(option: any) => option[bindKey || 'id']}
            getOptionLabel={(option: any) => option[bindLabel || 'label']}
            components={{ DropdownIndicator }}
            isLoading={isLoading}
            options={options}
            filterOption={
              filterFunc
                ? (option: any, input) => {
                    return (option?.label || '')
                      .toLocaleLowerCase()
                      .includes((input || '').toLocaleLowerCase());
                  }
                : () => true
            }
            onMenuOpen={onMenuOpen}
            onInputChange={onInputChange}
            onMenuScrollToBottom={onMenuScrollToBottom}
            {...rest}
          />
        )}
        {!!(errors as any)?.[name] ? (
          <FormHelperText
            error
            sx={{
              fontSize: '16px',
              letterSpacing: '0.045em',
              color: 'white.light',
            }}
          >
            {(errors as any)?.[name]?.message || ''}
          </FormHelperText>
        ) : (
          <FormHelperText
            error
            sx={{
              fontSize: '16px',
              letterSpacing: '0.045em',
              color: 'white.light',
            }}
          >
            {errorsName || ''}
          </FormHelperText>
        )}
      </>
    );
  }
);

export default Selects;
