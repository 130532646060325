import { Grid, IconButton, Stack, Typography } from '@mui/material';
import { CloseCircle } from 'asset';
import yup from 'config/yup.custom';
import { nannyInBookingStatuses } from 'models';
import {
  FieldArrayWithId,
  UseFieldArrayRemove,
  UseFormReturn,
  useWatch,
} from 'react-hook-form';
import { theme } from 'theme';
import { IFormInputs } from '..';
import { StyledInputField, StyledSelectField } from './styles';

interface NannyItemProps {
  index: number;
  form: UseFormReturn<IFormInputs, any>;
  field: FieldArrayWithId<IFormInputs, 'nannies', 'id'>;
  remove: UseFieldArrayRemove;
}

const schema = yup.object({
  nannyAskingAmount: yup.number().min(0).required(),
  commission: yup.number().min(0).required(),
});

export const NannyItem = ({ index, field, form, remove }: NannyItemProps) => {
  const { control } = form;

  const nanny = useWatch({
    name: `nannies.${index}`,
    control: control,
  });

  const getTotal = () => {
    if (
      schema.isValidSync({
        commission: nanny.commission,
        nannyAskingAmount: nanny.nannyAskingAmount,
      })
    ) {
      return Number(nanny.commission) + Number(nanny.nannyAskingAmount);
    }
    return '';
  };

  return (
    <Stack
      key={field.id}
      spacing={'22px'}
      sx={{
        background: '#F1F1F1',
        padding: '10px 15px 20px 18px',
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography sx={{ textTransform: 'capitalize', fontSize: 14 }}>
          {nanny?.nannyId?.label}
        </Typography>
        <IconButton sx={{ p: 0 }} onClick={() => remove(index)}>
          <CloseCircle
            sx={{ color: theme.palette.grey1.light, fontSize: 24 }}
          />
        </IconButton>
      </Stack>
      <Stack>
        <Grid container spacing={1.75}>
          <Grid item xs={6}>
            <StyledInputField
              control={control}
              title="Asking Amount"
              placeholder="Asking Amount"
              name={`nannies.${index}.nannyAskingAmount`}
            />
          </Grid>
          <Grid item xs={6}>
            <StyledInputField
              control={control}
              title="Comission"
              placeholder="Comission"
              name={`nannies.${index}.commission`}
            />
          </Grid>
          <Grid item xs={6}>
            <StyledInputField
              readOnly
              title="Total"
              placeholder="Total"
              name={`total`}
              value={getTotal()}
            />
          </Grid>
          <Grid item xs={6}>
            <StyledInputField
              control={control}
              title="Deposit"
              placeholder="Deposit"
              name={`nannies.${index}.nannyDeposit`}
            />
          </Grid>

          <Grid item xs={6}>
            <StyledSelectField
              title="Status"
              placeholder="Select"
              name={`nannies.${index}.status`}
              control={control}
              options={nannyInBookingStatuses}
            />
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
};
