import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { CloseCircle } from 'asset';
import { Inputs } from 'components';
import yup from 'config/yup.custom';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  createBadgesApi,
  editBadgesApi,
  getBadgesDetailApi,
  updateImageApi,
} from 'services';
import { NotifyService } from 'helpers';
import { InputFile } from 'components/form-control';
import { toast } from 'react-toastify';
import { TOAST } from 'config';
import { LoadingPopup } from 'components/common';

interface EditAdminProps {
  open: boolean;
  subject?: string;
  onSuccess: () => void;
  handleClosePopup: () => void;
}

interface IFormInputs {
  title: string;
  icon: string | undefined;
  no_of_bookings_needed: number | null;
  no_of_hours_worked: number | null;
}

const defaultValues: Partial<IFormInputs> = {
  title: '',
  icon: '',
  no_of_bookings_needed: null,
  no_of_hours_worked: null,
};

const PopupCreateOrEditBadge = (props: EditAdminProps) => {
  const { open, handleClosePopup, onSuccess, subject } = props;

  const theme = useTheme();

  const [nameIcon, setNameIcon] = useState<string>();
  const [urlImage, setUrlImage] = useState<any>();
  const [loadingPopup, setLoadingPopup] = useState(false);

  const schema = useMemo(() => {
    return yup.object({
      title: yup.string().required('Title is required'),
      icon: yup.string().notRequired(),
    });
  }, []);

  const form = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  const { reset, handleSubmit } = form;

  const _handleClosePopup = () => {
    handleClosePopup();
    setNameIcon(undefined);
    setUrlImage(undefined);
    reset({ ...defaultValues });
  };

  useEffect(() => {
    if (!open) return;
    if (subject) {
      setLoadingPopup(true);
      const fetchData = async () => {
        await getBadgesDetailApi(subject)
          .then(({ data }) => {
            reset({
              title: data.data.title,
              icon: data.data.icon,
              no_of_bookings_needed: data.data.no_of_bookings_needed,
              no_of_hours_worked: data.data.no_of_hours_worked,
            });
            setUrlImage(data.data.icon);
            const nameIcon =
              data.data.icon.split('/')[data.data.icon.split('/').length - 1];

            setNameIcon(nameIcon);
            setLoadingPopup(false);
          })
          .catch((e) => NotifyService.error(e));
      };
      fetchData();
    }
  }, [open, subject, reset]);

  const onSubmit = async (value: any) => {
    const newData = { ...value, icon: urlImage };

    if (!subject)
      await createBadgesApi(newData)
        .then((r) => {
          onSuccess();
          _handleClosePopup();
          NotifyService.success(r);
        })
        .catch((e) => NotifyService.error(e))
        .finally(() => {});
    else
      await editBadgesApi(subject, newData)
        .then((r) => {
          onSuccess();
          _handleClosePopup();
          NotifyService.success(r);
        })
        .catch((e) => NotifyService.error(e))
        .finally(() => {});
  };

  const handleChangeIcon = (e: any, _: any) => {
    if (e?.target?.files === null) return;
    const files = e?.target?.files[0];
    const formData = new FormData();
    formData.append('image', files);
    const fetchData = async () => {
      await updateImageApi(formData)
        .then((r) => {
          if (r.data.message === 'Success') {
            setUrlImage(r.data.data);
          }
        })
        .catch(() => {
          toast.error('Upload Video Failed', TOAST.error);
        })
        .finally(() => {});
    };
    fetchData();
    setNameIcon(files.name);
    e.target.value = null;
  };

  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiPaper-root': {
          width: 527,
          borderRadius: 0,
        },
      }}
    >
      <Grid>
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '20px 25px',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '100%',
            }}
          >
            {subject ? 'Edit' : 'Create'} Badge
          </Typography>
          <IconButton
            onClick={_handleClosePopup}
            sx={{
              padding: 0,
            }}
          >
            <CloseCircle />
          </IconButton>
        </Grid>
        <Divider />
        {loadingPopup && <LoadingPopup color="primary" />}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing="20px" p="25px 24px">
            <Stack>
              <Inputs title="Badge Title" form={form} name="title" />
            </Stack>
            <Stack>
              <InputFile
                form={form}
                nameFile={nameIcon}
                name="icon"
                title="Badge Icon (48x48px)"
                accept="image/*"
                handleChange={handleChangeIcon}
                sx={{
                  width: 118,
                  height: 34,
                  boxSizing: 'border-box',
                }}
              />
            </Stack>
            {urlImage && (
              <Box width="109px" height="109px" sx={{ position: 'relative' }}>
                <div
                  style={{
                    position: 'absolute',
                    zIndex: 2,
                    background: '#302d2d6b',
                    width: '109px',
                    height: '109px',
                  }}
                />
                <img src={urlImage} alt="" width="109" height="109" />
              </Box>
            )}
            {!subject && (
              <>
                <Stack>
                  <Inputs
                    type="number"
                    title="No of Bookings needed"
                    form={form}
                    name="no_of_bookings_needed"
                  />
                </Stack>
                <Stack>
                  <Inputs
                    type="number"
                    title="No.of Job-hours required"
                    form={form}
                    name="no_of_hours_worked"
                  />
                </Stack>
              </>
            )}

            <Stack direction="row" spacing="8px">
              <Button
                variant="outlined"
                color="secondary"
                onClick={_handleClosePopup}
                sx={{
                  height: 30,
                  width: '100%',
                  fontWeight: 600,
                  fontSize: '14px',
                  lineHeight: 1.1,
                  border: `1px solid ${theme.palette.secondary.main}`,
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                sx={{
                  height: 30,
                  width: '100%',
                  fontWeight: 600,
                  fontSize: '14px',
                  lineHeight: 1.1,
                }}
              >
                {subject ? 'Update' : 'Create'}
              </Button>
            </Stack>
          </Stack>
        </form>
      </Grid>
    </Dialog>
  );
};

export default PopupCreateOrEditBadge;
