import { Avatar, Box, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';

interface Props {
  subject: {
    Sitter: {
      User: { first_name: string; last_name: string };
      profile_picture: string;
      sitter_id: string;
      status: string;
      salary_per_hour: number;
    };
    id: string;
    salary_per_hour: number;
    status: string;
  };
}

const ItemSitter = (props: Props) => {
  const { subject } = props;
  const theme = useTheme();
  return (
    <Stack
      sx={{
        padding: '10px 12px',
        width: '100%',
        height: '57px',
        border: '1px solid rgba(34, 111, 227, 0.08)',
        borderRadius: '5px',
        display: 'flex',
        alignItems: 'center',
      }}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Stack direction="row">
        <Avatar
          src={subject.Sitter.profile_picture}
          sx={{
            width: '37px',
            height: '37px',
            marginRight: '6px',
          }}
        />
        <Box>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '16px',
              color: '#000',
            }}
          >
            {subject.Sitter.User.first_name} {subject.Sitter.User.last_name}
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '16px',
              color: '#000',
              opacity: 0.5,
            }}
          >
            {true && `$${subject.salary_per_hour} / hour`}
          </Typography>
        </Box>
      </Stack>
      <Stack>
        <Typography
          sx={{
            fontSize: '14px',
            lineHeight: '20px',
            textAlign: 'right',
            letterSpacing: '0.1px',
            textTransform: 'capitalize',
            color: theme.palette.secondary.main,
          }}
        >
          {subject.status === 'requested for payment' ? 'RFP' : subject.status}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ItemSitter;
