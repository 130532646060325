import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { CloseCircle } from 'asset';
import { Inputs } from 'components';
import yup from 'config/yup.custom';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { NotifyService } from 'helpers';
import { LoadingPopup } from 'components/common';
import {
  createPromosApi,
  getPromosDetailApi,
  updatePromosApi,
} from 'services/promotions';
import Select from 'components/form-control/Selects';
import { OptionItem, PROMOS_STATUS, PROMOS_STATUSES } from 'models';

interface EditAdminProps {
  open: boolean;
  subject?: string;
  onSuccess: () => void;
  handleClosePopup: () => void;
}

interface IFormInputs {
  aunty_promocode: string;
  aunty_promo_title: string;
  eber_hidden_code: string;
  status: OptionItem | null;
}

const defaultValues: Partial<IFormInputs> = {
  aunty_promocode: '',
  aunty_promo_title: '',
  eber_hidden_code: '',
  status: {
    id: PROMOS_STATUS.ACTIVE,
    name: 'Active',
  },
};

const PopupCreateOrEditPromos = (props: EditAdminProps) => {
  const { open, handleClosePopup, onSuccess, subject } = props;

  const theme = useTheme();
  const [loadingPopup, setLoadingPopup] = useState(false);

  const schema = useMemo(() => {
    return yup.object({
      aunty_promocode: yup.string().required('Promocode is required'),
      aunty_promo_title: yup.string().notRequired(),
      eber_hidden_code: yup.string().required('Eber Code is required'),
      status: yup.mixed().required('Status is required'),
    });
  }, []);

  const form = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  const { reset, handleSubmit } = form;

  const _handleClosePopup = () => {
    handleClosePopup();
    reset({ ...defaultValues });
  };

  useEffect(() => {
    if (!open) return;
    if (subject) {
      setLoadingPopup(true);
      const fetchData = async () => {
        await getPromosDetailApi(subject)
          .then(({ data }) => {
            reset({
              aunty_promocode: data.data.aunty_promocode,
              aunty_promo_title: data.data.aunty_promo_title,
              eber_hidden_code: data.data.eber_hidden_code,
              status: PROMOS_STATUSES.find(
                (item) => item.id === data.data.status
              ),
            });
            setLoadingPopup(false);
          })
          .catch((e) => NotifyService.error(e));
      };
      fetchData();
    }
  }, [open, subject, reset]);

  const onSubmit = async (value: any) => {
    const newData = {
      ...value,
      status: value.status.id,
    };
    if (!subject)
      await createPromosApi(newData)
        .then((r) => {
          onSuccess();
          _handleClosePopup();
          NotifyService.success(r);
        })
        .catch((e) => NotifyService.error(e))
        .finally(() => {});
    else
      await updatePromosApi(subject, newData)
        .then((r) => {
          onSuccess();
          _handleClosePopup();
          NotifyService.success(r);
        })
        .catch((e) => NotifyService.error(e))
        .finally(() => {});
  };

  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiPaper-root': {
          width: 527,
          borderRadius: 0,
        },
      }}
    >
      <Grid>
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '20px 25px',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '100%',
            }}
          >
            {subject ? 'Edit' : 'Create'} Promocode
          </Typography>
          <IconButton
            onClick={_handleClosePopup}
            sx={{
              padding: 0,
            }}
          >
            <CloseCircle />
          </IconButton>
        </Grid>
        <Divider />
        {loadingPopup && <LoadingPopup color="primary" />}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack p="25px 24px" spacing="20px">
            <Stack>
              <Inputs title="Promocode" form={form} name="aunty_promocode" />
            </Stack>
            <Stack>
              <Inputs
                title="Promotion Title"
                form={form}
                name="aunty_promo_title"
              />
            </Stack>
            <Stack>
              <Inputs title="Eber Code" form={form} name="eber_hidden_code" />
            </Stack>
            <Stack>
              <Select
                title="Status"
                name="status"
                bindLabel="name"
                form={form}
                filterFunc={true}
                options={PROMOS_STATUSES}
              />
            </Stack>

            <Stack direction="row" spacing="8px">
              <Button
                variant="outlined"
                color="secondary"
                onClick={_handleClosePopup}
                sx={{
                  height: 30,
                  width: '100%',
                  fontWeight: 600,
                  fontSize: '14px',
                  lineHeight: 1.1,
                  border: `1px solid ${theme.palette.secondary.main}`,
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                sx={{
                  height: 30,
                  width: '100%',
                  fontWeight: 600,
                  fontSize: '14px',
                  lineHeight: 1.1,
                }}
              >
                {subject ? 'Update' : 'Create'}
              </Button>
            </Stack>
          </Stack>
        </form>
      </Grid>
    </Dialog>
  );
};

export default PopupCreateOrEditPromos;
