import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Stack } from '@mui/material';
import { Inputs } from 'components';
import yup from 'config/yup.custom';
import { NotifyService } from 'helpers';
import { memo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { createNoteApi, getListNotesApi } from 'services';

interface Props {
  parentId?: string;
}

interface IFormInputs {
  note: string;
}

const defaultValues: Partial<IFormInputs> = {
  note: '',
};

const schema = yup.object({
  note: yup.string().optional(),
});

const Notes = memo(({ parentId }: Props) => {
  const [disabled, setDisabled] = useState(true);
  const form = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  const { reset, handleSubmit, watch } = form;

  const fetchData = async () => {
    await getListNotesApi({ parent_id: parentId })
      .then(({ data }) => {
        reset({
          note: data.data.note,
        });
      })
      .catch((e) => NotifyService.error(e));
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'note') {
        setDisabled(value.note === defaultValues.note && value.note !== '');
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = async (value: IFormInputs) => {
    await createNoteApi({ parent_id: parentId, note: value.note })
      .then(() => {
        NotifyService.success('Success');
        setDisabled(true);
      })
      .catch((e) => NotifyService.error(e));
  };

  return (
    <Stack spacing="8px">
      <Box onSubmit={handleSubmit(onSubmit)} component="form">
        <Stack direction="row" spacing="8px" justifyContent="flex-end">
          <Button
            disabled={disabled}
            color="secondary"
            variant="contained"
            type="submit"
            sx={{
              marginBottom: '8px',
              height: '32px',
            }}
          >
            Save
          </Button>
        </Stack>
        <Inputs form={form} name="note" rows={10} placeholder="Enter note" />
      </Box>
    </Stack>
  );
});

export default Notes;
