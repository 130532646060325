import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { JOBS_BODY_RESULT, GET_JOBS_PARAMS_REQUEST } from 'models';

export interface JobsType {
  jobs: {
    jobs_list: JOBS_BODY_RESULT;
    jobs_detail: any | null;
  };
}

const initialState: JobsType = {
  jobs: {
    jobs_list: {
      records: [],
      metadata: {
        page: 1,
        limit: 50,
        page_count: 1,
        total_pages: 1,
        total_count: 1,
      },
      params: {
        sort: 'createdAt-desc',
        page: 1,
        limit: 50,
      },
    },
    jobs_detail: null,
  },
};

const jobsSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    setJobsListSuccess(
      state: JobsType,
      action: PayloadAction<JOBS_BODY_RESULT>
    ) {
      state.jobs.jobs_list = {
        ...state.jobs.jobs_list,
        ...action.payload,
      };
    },
    setJobsDetailSuccess(state: JobsType, action: PayloadAction<any | null>) {
      state.jobs.jobs_detail = action.payload;
    },
    setJobsListParams(
      state: JobsType,
      action: PayloadAction<GET_JOBS_PARAMS_REQUEST | null>
    ) {
      state.jobs.jobs_list.params = action.payload;
    },
  },
});

// Actions
export const { setJobsListSuccess, setJobsDetailSuccess, setJobsListParams } =
  jobsSlice.actions;

export default jobsSlice.reducer;
