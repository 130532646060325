import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Checkbox,
  Dialog,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { CloseCircle } from 'asset';
import { LoadingPopup } from 'components/common';
import { DateTimePicker, Inputs, TimerPicker } from 'components/form-control';
import Select, { GetOptionsResponse } from 'components/form-control/Select';
import yup from 'config/yup.custom';
import { NotifyService } from 'helpers';
import { useDateTime } from 'hooks';
import {
  CREATE_TRANSACTION_BODY,
  DOC_REF_NO_RESPONSE,
  GET_USERS_OPTION_PARAMS_REQUEST,
  OptionItem,
  UPDATE_TRANSACTION_BODY,
  WALLET_TRANSACTION_CODE,
} from 'models';
import { memo, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  createTransactionApi,
  getTransactionApi,
  getTransactionTypesApi,
  getUsersOptionApi,
  updateTransactionApi,
} from 'services';

interface IFormInputs {
  number: string;
  type_id: OptionItem;
  date: string;
  time: string;
  amount: number | string;
  doc_ref_no?: string;
  user_id: OptionItem;
  remark?: string;
  include_in_payout: boolean;
  is_update_balance: boolean;
}

const defaultValues: Partial<IFormInputs> = {
  number: '',
  type_id: undefined,
  amount: '',
  date: '',
  time: '',
  doc_ref_no: undefined,
  user_id: undefined,
  remark: '',
  include_in_payout: false,
  is_update_balance: false,
};

interface Props {
  open: boolean;
  subject?: string;
  onSuccess: () => void;
  handleClosePopup: () => void;
}

const PopupCreateOrEditTransaction = memo(
  ({ open, subject, onSuccess, handleClosePopup }: Props) => {
    const { moment } = useDateTime();

    const [docRefNoData, setDocRefNoData] = useState<DOC_REF_NO_RESPONSE>();
    const [typeOptions, setTypeOptions] = useState<OptionItem[]>([]);
    const [loadingPopup, setLoadingPopup] = useState(false);

    const schema = useMemo(() => {
      return yup.object({
        number: yup.string().notRequired(),
        type_id: yup.mixed().required('Transaction type is required'),
        user_id: yup.mixed().required('User is required'),
        doc_ref_no: docRefNoData?.is_required_doc
          ? yup.mixed().required('Doc Ref No. is required')
          : yup.mixed().notRequired(),
        amount: yup
          .number()
          .typeError('Amount is required')
          .min(0, 'Amount must be greater than or equal to 0')
          .required('Amount is required'),
        date: yup.string().notRequired(),
        time: yup.string().notRequired(),
        remark: yup.string().notRequired(),
        include_in_payout: yup.boolean().notRequired(),
        is_update_balance: yup.boolean().notRequired(),
      });
    }, [docRefNoData]);

    const form = useForm<IFormInputs>({
      resolver: yupResolver(schema),
      mode: 'onChange',
      defaultValues: defaultValues,
    });

    const { reset, setValue, handleSubmit } = form;

    const _handleClosePopup = () => {
      reset({ ...defaultValues });
      setDocRefNoData(undefined);
      handleClosePopup();
    };

    useEffect(() => {
      if (open) {
        const fetchOptions = async () => {
          await getTransactionTypesApi()
            .then(({ data }) => {
              setTypeOptions(
                data.data
                  ?.filter((it) =>
                    [
                      WALLET_TRANSACTION_CODE.CREDIT,
                      WALLET_TRANSACTION_CODE.DEBIT,
                    ].includes(it.code as WALLET_TRANSACTION_CODE)
                  )
                  ?.map((it) => ({
                    ...it,
                    label: `${it.code} - ${it.title} (${
                      it.code === WALLET_TRANSACTION_CODE.CREDIT
                        ? 'Add to Wallet'
                        : 'Deduct from Wallet'
                    })`,
                  }))
              );
            })
            .catch((e) => NotifyService.error(e));
        };
        fetchOptions();
      }
    }, [open]);

    useEffect(() => {
      if (subject) {
        setLoadingPopup(true);
        const getDetail = async () => {
          await getTransactionApi(subject)
            .then(({ data }) => {
              console.log(data, 'datadatadatadata');

              reset({
                number: data.data.record.number,
                type_id: {
                  ...data.data.record.type,
                  label: `${data.data.record.type.code} - ${data.data.record.type.title}`,
                },
                amount: Math.abs(data.data.record.amount),
                date: data.data.record.timestamp,
                time: data.data.record.timestamp,
                doc_ref_no: data.data.record.doc_ref_no
                  ? data.data.record.doc_ref_no
                  : undefined,
                user_id: {
                  id: data.data.record.user.user_id,
                  label: `${data.data.record.user.first_name} ${data.data.record.user.last_name}`,
                },
                remark: data.data.record.remark || '',
                include_in_payout: data.data.record.include_in_payout,
              });
              setLoadingPopup(false);
            })
            .catch((e) => NotifyService.error(e));
        };
        getDetail();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subject]);

    const onSubmit = async (data: IFormInputs) => {
      let date, time;
      if (data.date && data.time) {
        date = moment(data.date).format('YYYY-MM-DD');
        time = moment(data.time).format('HH:mm');
      }
      if (subject) {
        const body: UPDATE_TRANSACTION_BODY = {
          date: date,
          time: time,
          amount: data.amount as string,
          remark: data.remark || '',
          include_in_payout: data.include_in_payout,
          is_update_balance: data.is_update_balance,
        };
        await updateTransactionApi(subject, body)
          .then((r) => {
            _handleClosePopup();
            onSuccess();
            NotifyService.success(r);
          })
          .catch((err) => NotifyService.error(err));
      } else {
        const body: CREATE_TRANSACTION_BODY = {
          type_id: data.type_id.id,
          user_id: data.user_id.id,
          doc_ref_no: data.doc_ref_no,
          date: date,
          time: time,
          amount: data.amount as string,
          remark: data.remark || '',
        };
        setLoadingPopup(true);
        await createTransactionApi(body)
          .then((r) => {
            _handleClosePopup();
            onSuccess();
            NotifyService.success(r);
          })
          .catch((err) => NotifyService.error(err))
          .finally(() => {
            setLoadingPopup(false);
          });
      }
    };

    const getOptions =
      (key: keyof IFormInputs) =>
      async (page: number = 1, limit: number = 20, keyword?: string) => {
        let data: GetOptionsResponse | null = null;
        switch (key) {
          case 'user_id':
            const paramsUser: GET_USERS_OPTION_PARAMS_REQUEST = {
              page: page,
              limit: limit,
            };
            if (keyword) paramsUser.keyword = keyword;
            data = await getUsersOptionApi(paramsUser)
              .then(({ data }) => {
                return {
                  metadata: data.data.metadata,
                  options: data.data.records.map((it) => ({
                    ...it,
                    label: `${it.first_name} ${it.last_name} (${it.email})`,
                  })),
                };
              })
              .catch((e) => {
                NotifyService.error(e);
                return null;
              });
            break;
        }
        return data;
      };

    const onClearDocRefNo = () => {
      setValue('doc_ref_no', undefined);
    };

    return (
      <Dialog
        open={open}
        sx={{
          '& .MuiPaper-root': {
            minWidth: 523,
            borderRadius: 0,
          },
        }}
      >
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '20px 25px',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '100%',
            }}
          >
            {subject ? 'Edit' : 'Create'} Transaction
          </Typography>
          <IconButton onClick={_handleClosePopup} sx={{ p: 0 }}>
            <CloseCircle />
          </IconButton>
        </Grid>
        <Divider />
        {loadingPopup && <LoadingPopup color="primary" />}
        <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
          <Stack
            p="20px 24px 50px 26px"
            height="100%"
            justifyContent="space-between"
          >
            <Stack spacing="16px">
              {subject && (
                <Grid>
                  <Inputs
                    title="Transaction No."
                    form={form}
                    name="number"
                    readOnly
                  />
                </Grid>
              )}
              <Grid>
                <Select
                  isDisabled={!!subject}
                  title="Transaction Type"
                  name="type_id"
                  form={form}
                  filterFunc={true}
                  options={typeOptions}
                  onChange={onClearDocRefNo}
                />
              </Grid>
              <Grid>
                <Stack direction="row" spacing="14px" alignItems="flex-end">
                  <Stack sx={{ width: '50%' }}>
                    <Select
                      isDisabled={!!subject}
                      title="User"
                      name="user_id"
                      form={form}
                      isHasMore
                      onChange={onClearDocRefNo}
                      getOptions={getOptions('user_id')}
                    />
                  </Stack>
                  <Stack sx={{ width: '50%' }}>
                    <Inputs title="Doc Ref No." form={form} name="doc_ref_no" />
                  </Stack>
                </Stack>
              </Grid>
              <Stack>
                <Grid>
                  <Inputs
                    type="number"
                    title="Amount"
                    form={form}
                    name="amount"
                  />
                </Grid>
                {subject && (
                  <Grid>
                    <Controller
                      name="is_update_balance"
                      control={form.control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="secondary"
                              checked={field.value || false}
                              onChange={(e) => field.onChange(e.target.checked)}
                              name="none"
                            />
                          }
                          label="Update amount in both transaction and balance"
                        />
                      )}
                    />
                  </Grid>
                )}
              </Stack>
              <Grid>
                <Stack direction="row" spacing="14px" alignItems="flex-start">
                  <Stack sx={{ width: '50%' }}>
                    <DateTimePicker title="Date" form={form} name="date" />
                  </Stack>
                  <Stack sx={{ width: '50%' }}>
                    <TimerPicker title="Time" form={form} name="time" />
                  </Stack>
                </Stack>
              </Grid>
              <Grid>
                <Inputs title="Remark" form={form} name="remark" />
              </Grid>
              {subject && (
                <Grid>
                  <Controller
                    name="include_in_payout"
                    control={form.control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="secondary"
                            checked={field.value || false}
                            onChange={(e) => field.onChange(e.target.checked)}
                            name="none"
                          />
                        }
                        label="Include In Payout"
                      />
                    )}
                  />
                </Grid>
              )}
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing="8px"
              pt="42px"
            >
              <Button
                variant="outlined"
                color="secondary"
                sx={{
                  width: '233.5px',
                  height: '30px',
                }}
                onClick={_handleClosePopup}
              >
                Cancel
              </Button>
              <Button
                color="secondary"
                variant="contained"
                sx={{
                  width: '233.5px',
                  height: '30px',
                }}
                type="submit"
              >
                {subject ? 'Update' : 'Create'}
              </Button>
            </Stack>
          </Stack>
        </form>
      </Dialog>
    );
  }
);

export default PopupCreateOrEditTransaction;
