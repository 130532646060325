import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { CloseCircle } from 'asset';
import { LoadingPopup } from 'components/common';
import PhoneNumberField from 'components/common/PhoneNumberField';
import {
  DateTimePicker,
  InputFile,
  Inputs,
  Select,
} from 'components/form-control';
import { INIT_COUNTRY_CODE, TOAST, patternPassword } from 'config';
import yup from 'config/yup.custom';
import { NotifyService } from 'helpers';
import { useDateTime } from 'hooks';
import {
  CREATE_NANNY_TYPE,
  OptionItem,
  PHONE_TYPE,
  UPDATE_NANNY_TYPE,
  nannyStatuses,
} from 'models';
import { useEffect, useMemo, useState } from 'react';
import { ControllerRenderProps, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { nannyApi, staticDataApi } from 'services/accounts/nanny';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setNannyDetailSuccess } from 'store/reducers/accounts';
import { getNannyDetailAction } from 'store/reducers/accounts/actionTypes';

interface PopupCreateOrEditNannyProps {
  subject?: string;
  cleanDetail?: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

interface IFormInputs {
  nannyId?: string;
  bio: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: PHONE_TYPE;
  dateOfBirth: moment.Moment;
  startOfNannyExperience: moment.Moment;
  ethnicity: OptionItem<string>;
  nationality: OptionItem<string>;
  password: string;
  status: OptionItem<string>;
  bankName?: string;
  bankAccountNo?: string;
  bankAccountHolderName?: string;
}

const PopupCreateOrEditNanny = ({
  subject,
  cleanDetail,
  onClose,
  onSuccess,
}: PopupCreateOrEditNannyProps) => {
  const { formatDateTime, moment } = useDateTime();
  const { loadingPopup } = useAppSelector((state) => state.globalReducer);
  const dispatch = useAppDispatch();
  const { nanny_detail } = useAppSelector(
    (state) => state.accountReducer.nanny
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [ethnicities, setEthnicities] = useState<OptionItem<string>[]>([]);
  const [nationalities, setNationalities] = useState<OptionItem<string>[]>([]);
  const [urlImage, setUrlImage] = useState<any>();

  const schema = useMemo(() => {
    return yup.object().shape({
      firstName: yup.string().required('First name is required'),
      lastName: yup.string().required('Last name is required'),
      email: yup.string().notRequired(),
      phoneNumber: yup
        .object({
          country_code: yup.string().required('Country code is required'),
          phone_number: yup.string().required('Phone number is required'),
        })
        .required('Phone number is required'),
      nationality: yup.mixed().required('Nationality is required'),
      ethnicity: yup.mixed().required('Ethnicity is required'),
      dateOfBirth: yup.mixed().required('Date of birth is required'),
      password: subject
        ? yup
            .string()
            .matches(patternPassword, {
              message: 'Invalid password',
              excludeEmptyString: true,
            })
            .notRequired()
        : yup
            .string()
            .matches(patternPassword, {
              message: 'Invalid password',
              excludeEmptyString: true,
            })
            .required('Password is required'),
      status: yup.mixed().notRequired(),
      startOfNannyExperience: yup
        .mixed()
        .required('Start of nanny experience is required'),
      bio: yup.string().notRequired(),
    });
  }, [subject]);

  const form = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const { reset, handleSubmit } = form;

  useEffect(() => {
    if (subject) {
      dispatch(getNannyDetailAction({ data: { id: subject }, isPopup: true }));
    }
  }, [subject, dispatch]);

  useEffect(() => {
    if (subject && nanny_detail) {
      setUrlImage(nanny_detail.profilePicture);
      reset({
        nannyId: nanny_detail.nannyId,
        bio: nanny_detail.bio || '',
        firstName: nanny_detail.firstName || '',
        lastName: nanny_detail.lastName || '',
        email: nanny_detail.email || '',
        phoneNumber: {
          country_code: nanny_detail.countryCode || INIT_COUNTRY_CODE.code,
          phone_number: nanny_detail.phoneNumber || '',
        },
        dateOfBirth: nanny_detail.dateOfBirth
          ? formatDateTime(
              nanny_detail.dateOfBirth,
              'YYYY-MM-DDTHH:mm:ss.SSSZ',
              'DD-MM-YYYY'
            )
          : undefined,
        startOfNannyExperience: nanny_detail.startOfNannyExperience
          ? formatDateTime(
              nanny_detail.startOfNannyExperience,
              'YYYY-MM-DDTHH:mm:ss.SSSZ',
              'DD-MM-YYYY'
            )
          : undefined,
        ethnicity: nanny_detail.ethnicity
          ? { id: nanny_detail.ethnicity, label: nanny_detail.ethnicity }
          : undefined,
        nationality: nanny_detail.nationality
          ? { id: nanny_detail.nationality, label: nanny_detail.nationality }
          : undefined,
        password: '',
        status: nannyStatuses.find((t) => t.id === nanny_detail.status),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subject, nanny_detail]);

  const createNanny = async (data: CREATE_NANNY_TYPE) => {
    await nannyApi
      .add(data)
      .then((r) => {
        NotifyService.success('Success');
        onSuccess();
        _onClose();
      })
      .catch((e) => {
        NotifyService.error(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const editNanny = async (data: UPDATE_NANNY_TYPE) => {
    if (!subject) return;
    await nannyApi
      .update(data)
      .then((r) => {
        NotifyService.success('Success');
        onSuccess();
        _onClose();
      })
      .catch((e) => {
        NotifyService.error(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const actionForm = (data: any) => {
    setLoading(true);
    if (subject) editNanny(data);
    else {
      createNanny(data);
    }
  };

  const onSubmit = async (data: IFormInputs) => {
    const dataCreate: CREATE_NANNY_TYPE = {
      bio: data.bio || '',
      email: data.email || '',
      firstName: data.firstName || '',
      lastName: data.lastName || '',
      dateOfBirth: moment(data.dateOfBirth).format('DD-MM-YYYY') || '',
      startOfNannyExperience:
        moment(data.startOfNannyExperience).format('DD-MM-YYYY') || '',
      ethnicity: data.ethnicity?.id,
      nationality: data.nationality?.id,
      countryCode: data.phoneNumber.country_code,
      number: data.phoneNumber.phone_number,
      password: data.password,
    };
    if (subject) {
      const dataUpdate: UPDATE_NANNY_TYPE = {
        ...dataCreate,
        nannyId: data.nannyId || '',

        bankAccountNo: '',
        bankAccountHolderName: '',
        status: data.status.id,
      };
      actionForm(dataUpdate);
    } else {
      actionForm(dataCreate);
    }
  };

  const _onClose = () => {
    onClose?.();
    setUrlImage(undefined);
    if (cleanDetail) {
      dispatch(setNannyDetailSuccess(null));
    }
  };

  useEffect(() => {
    const fetchOptions = async () => {
      await Promise.all([
        staticDataApi.getEthnicity(),
        staticDataApi.getNationality(),
      ])
        .then(
          ([
            {
              data: { data: _ethnicities },
            },
            {
              data: { data: _nationalities },
            },
          ]) => {
            setEthnicities(
              _ethnicities.map((t: any) => ({ id: t.name, label: t.name }))
            );
            setNationalities(
              _nationalities.map((t: any) => ({ id: t.name, label: t.name }))
            );
          }
        )
        .catch((e) => NotifyService.error(e));
    };
    fetchOptions();
  }, []);

  const handleChangeImg = async (
    e: React.ChangeEvent<HTMLInputElement>,
    _: ControllerRenderProps<any, string>
  ) => {
    if (e?.target?.files === null || !nanny_detail) return;
    const files = e?.target?.files[0];
    setLoading(true);
    await nannyApi
      .uploadPicture(nanny_detail.nannyId, files)
      .then((r) => {
        setUrlImage(r.data.data);
      })
      .catch((e) => {
        toast.error('Upload Avatar Failed', TOAST.error);
      })
      .finally(() => {
        setLoading(false);
      });

    e.target.files = null;
  };

  return (
    <Dialog
      open
      sx={{
        '& .MuiPaper-root': {
          width: 523,
          height: 'auto',
          borderRadius: 0,
        },
      }}
    >
      <DialogTitle
        sx={{
          padding: '20px 25px',
        }}
      >
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '100%',
            }}
          >
            {subject ? 'Edit' : 'Create'} Nanny
          </Typography>
          <IconButton
            onClick={_onClose}
            sx={{
              padding: 0,
            }}
          >
            <CloseCircle />
          </IconButton>
        </Grid>
      </DialogTitle>
      {(loadingPopup || loading) && <LoadingPopup color="primary" />}
      <DialogContent
        sx={{
          p: '30px 24px 50px 26px',
        }}
        dividers
      >
        <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
          <Stack height="100%" justifyContent="space-between">
            <Stack spacing="16px">
              <Stack direction="row" spacing="14px">
                <Stack sx={{ width: '50%' }}>
                  <Inputs title="First Name" form={form} name="firstName" />
                </Stack>
                <Stack sx={{ width: '50%' }}>
                  <Inputs title="Last Name" form={form} name="lastName" />
                </Stack>
              </Stack>
              <Stack>
                <Inputs
                  title="Email"
                  placeholder="Email"
                  form={form}
                  name="email"
                />
              </Stack>
              <Stack>
                <PhoneNumberField
                  title="Phone Number"
                  name="phoneNumber"
                  form={form}
                />
              </Stack>
              <Stack direction="row" spacing="14px">
                <Stack sx={{ width: '50%' }}>
                  <Select
                    title="Nationality"
                    name="nationality"
                    form={form}
                    options={nationalities}
                  />
                </Stack>
                <Stack sx={{ width: '50%' }}>
                  <Select
                    title="Ethnicity"
                    name="ethnicity"
                    form={form}
                    options={ethnicities}
                  />
                </Stack>
              </Stack>
              <Stack direction="row" spacing="14px">
                <Stack sx={{ width: '50%' }}>
                  <DateTimePicker
                    title="Date of Birth"
                    form={form}
                    name="dateOfBirth"
                  />
                </Stack>
                <Stack sx={{ width: '50%' }}>
                  <DateTimePicker
                    title="Start of Nanny Experience"
                    form={form}
                    name="startOfNannyExperience"
                  />
                </Stack>
              </Stack>
              <Stack>
                <Inputs
                  title="About me"
                  placeholder="Write something here"
                  form={form}
                  name="bio"
                  rows={6}
                />
              </Stack>
              {subject && (
                <>
                  <Stack>
                    <Stack>
                      <InputFile
                        form={form}
                        nameFile={urlImage}
                        name="icon"
                        title="Avatar"
                        accept="image/*"
                        handleChange={handleChangeImg}
                        sx={{
                          width: 118,
                          height: 34,
                          boxSizing: 'border-box',
                        }}
                      />
                    </Stack>
                    {urlImage && (
                      <Box width="109px" sx={{ position: 'relative' }}>
                        <div
                          style={{
                            position: 'absolute',
                            zIndex: 2,
                            background: '#302d2d6b',
                            width: '109px',
                          }}
                        />
                        <img src={urlImage} alt="" width="109" />
                      </Box>
                    )}
                  </Stack>

                  <Stack>
                    <Select
                      title="Status"
                      name="status"
                      form={form}
                      options={nannyStatuses}
                    />
                  </Stack>
                </>
              )}
              <Stack>
                <Inputs
                  showEyes
                  title={subject ? 'Change password' : 'Password'}
                  type="password"
                  name="password"
                  placeholder="Password"
                  form={form}
                />
              </Stack>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              marginTop="25px"
              spacing="8px"
            >
              <Button
                variant="outlined"
                color="secondary"
                sx={{
                  width: '233.5px',
                  height: '30px',
                }}
                onClick={_onClose}
              >
                Cancel
              </Button>
              <Button
                color="secondary"
                variant="contained"
                sx={{
                  width: '233.5px',
                  height: '30px',
                }}
                type="submit"
              >
                {subject ? 'Update' : 'Create'}
              </Button>
            </Stack>
          </Stack>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default PopupCreateOrEditNanny;
