import {
  Box,
  Button,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { CloseCircle } from 'asset';
import { Inputs, SelectInput } from 'components';
import { useForm } from 'react-hook-form';
import { createAdminsApi } from 'services';
import { patternPassword } from 'config';
import { useMemo, useState } from 'react';
import PopupWarningCreate from 'components/Popups/Warning/PopupWarningCreate';
import yup from 'config/yup.custom';
import { yupResolver } from '@hookform/resolvers/yup';
import { NotifyService } from 'helpers';

interface EditAdminProps {
  open: boolean;
  handleClosePopup: () => void;
  onSuccess: () => void;
}

interface IFormInputs {
  full_name: string;
  email: string;
  role: string;
  password: string;
}

const options = [
  {
    name: 'Staff',
    value: 'staff',
  },
  {
    name: 'View Only',
    value: 'view-only',
  },
  {
    name: 'SuperAdmin',
    value: 'superadmin',
  },
];

const defaultValues: Partial<IFormInputs> = {
  full_name: '',
  email: '',
  role: '',
  password: '',
};

const CreateAdmin = (props: EditAdminProps) => {
  const { open, handleClosePopup, onSuccess } = props;
  const theme = useTheme();

  const [openWrCreate, setOpenWrCreate] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const schema = useMemo(() => {
    return yup.object({
      full_name: yup
        .string()
        .max(50, 'Invalid name')
        .required('Name is required'),
      email: yup.string().email('Invalid email').required('Email is required'),
      role: yup.string().required('Role is required'),
      password: yup
        .string()
        .matches(patternPassword, {
          message: 'Invalid password',
          excludeEmptyString: true,
        })
        .required('Password is required'),
    });
  }, []);

  const form = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  const {
    reset,
    watch,
    handleSubmit,
    formState: { errors },
  } = form;

  const _handleClosePopup = () => {
    reset({ ...defaultValues });
    handleClosePopup();
  };

  const onClosePopup = () => {
    setOpenWrCreate(false);
  };

  const onSubmit = async (value: any) => {
    setLoading(true);
    await createAdminsApi(value)
      .then((r) => {
        onSuccess();
        _handleClosePopup();
        NotifyService.success(r);
      })
      .catch((e) => NotifyService.error(e))
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiPaper-root': {
          width: 527,
          borderRadius: 0,
        },
      }}
    >
      <Grid>
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '20px 25px',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '100%',
            }}
          >
            Create Admin
          </Typography>
          <IconButton
            onClick={_handleClosePopup}
            sx={{
              padding: 0,
            }}
          >
            <CloseCircle />
          </IconButton>
        </Grid>
        <Divider />
        <form onSubmit={handleSubmit(onSubmit)} id="form1">
          <Box p="25px 24px">
            <Stack spacing="20px" mb="20px">
              <Box>
                <Inputs
                  title="Name"
                  name="full_name"
                  placeholder="Input your name"
                  form={form}
                  showCount
                  numberCount={watch('full_name').length}
                />
              </Box>
              <Box>
                <Inputs
                  title="Email Address"
                  name="email"
                  placeholder="Input your email"
                  form={form}
                />
              </Box>
              <Box>
                <SelectInput
                  title="Role"
                  name="role"
                  form={form}
                  options={options}
                />
              </Box>
              <Box>
                <Inputs
                  showEyes
                  title="Password"
                  type="password"
                  name="password"
                  placeholder="Password"
                  form={form}
                  errorsName={errors.password?.message}
                />
              </Box>
            </Stack>

            <Stack direction="row" spacing="8px">
              <Button
                variant="outlined"
                color="secondary"
                onClick={_handleClosePopup}
                sx={{
                  height: 30,
                  width: '100%',
                  fontWeight: 600,
                  fontSize: '14px',
                  lineHeight: 1.1,
                  border: `1px solid ${theme.palette.secondary.main}`,
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setOpenWrCreate(true);
                }}
                variant="contained"
                color="secondary"
                sx={{
                  height: 30,
                  width: '100%',
                  fontWeight: 600,
                  fontSize: '14px',
                  lineHeight: 1.1,
                }}
              >
                Create
              </Button>
            </Stack>
            <PopupWarningCreate
              loading={loading}
              open={openWrCreate}
              onClose={onClosePopup}
              subject={form.watch('full_name')}
            />
          </Box>
        </form>
      </Grid>
    </Dialog>
  );
};

export default CreateAdmin;
