import React from 'react';

const CheckCircleEmpty = () => {
  return (
    <svg
      width={25}
      height={24}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1.14294" y="0.5" width={23} height={23} rx="11.5" fill="white" />
      <rect
        x="1.14294"
        y="0.5"
        width={23}
        height={23}
        rx="11.5"
        stroke="#B7B7B7"
      />
    </svg>
  );
};

export default CheckCircleEmpty;
