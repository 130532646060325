export const GET_JOBS_LIST_REQUEST: string = 'GET_JOBS_LIST_REQUEST';

export const GET_JOBS_LIST_REQUEST_WITH_KEYWORD: string =
  'GET_JOBS_LIST_REQUEST_WITH_KEYWORD';

export const GET_JOBS_DETAIL_REQUEST: string = 'GET_JOBS_DETAIL_REQUEST';
export const DELETE_JOBS_REQUEST: string = 'DELETE_JOBS_REQUEST';
export const DELETE_ALL_JOBS_REQUEST: string = 'DELETE_ALL_JOBS_REQUEST';

export const deleteJobRequest = (id: string, onSuccess?: () => void) => {
  return {
    type: DELETE_JOBS_REQUEST,
    payload: {
      id,
      onSuccess,
    }
  }
}