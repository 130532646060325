import {
  Box,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Paper,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import usePagination from '@mui/material/usePagination/usePagination';
import { CloseCircle } from 'asset';
import {
  DeleteDialog,
  DetailItemData,
  DetailItemTitle,
  HeadTable,
  LoadingPopup,
  TableCaption,
} from 'components/common';
import { ButtonDelete, TableCellNoData } from 'components/common/table';
import { NotifyService, getMetadata, getSortItem } from 'helpers';
import { useDateTime } from 'hooks';
import {
  CHAT_DETAIL_POPUP_TYPE,
  CHAT_TYPE,
  GET_CHAT_MESSAGES_PARAMS_REQUEST,
  LIST_BODY_RESULT,
  MESSAGE_CHAT,
  MESSAGE_SENDER_TYPE,
  MESSSAGE_CHAT_TYPE,
  TYPE_POPUP,
} from 'models';

import { ToggleField } from 'components/form-control';
import { memo, useEffect, useMemo, useState } from 'react';
import { deleteChatMessageApi, getChatMessageListApi } from 'services';
import { headCells } from './data';

interface Props {
  open: boolean;
  data?: CHAT_TYPE;
  type: CHAT_DETAIL_POPUP_TYPE;
  handleClosePopup: () => void;
}

const defaultMessage: LIST_BODY_RESULT<MESSAGE_CHAT> = {
  records: [],
  metadata: {
    page: 1,
    limit: 50,
    page_count: 1,
    total_pages: 1,
    total_count: 1,
  },
};

const PopupDetailChat = memo(
  ({ open, data, type, handleClosePopup }: Props) => {
    const { formatDateTime } = useDateTime();
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [itemAction, setItemAction] = useState<MESSAGE_CHAT>();
    const [messages, setMessages] = useState<LIST_BODY_RESULT<MESSAGE_CHAT>>({
      ...defaultMessage,
    });
    const [params, setParams] = useState<GET_CHAT_MESSAGES_PARAMS_REQUEST>({
      limit: 50,
      page: 1,
      check_tracked_by: true,
      deleted: false,
    });
    const [loadingPopup, setLoadingPopup] = useState(false);

    const _handleClosePopup = () => {
      handleClosePopup();
      setMessages({ ...defaultMessage });
    };

    const fetchMessages = async () => {
      if (!data) return;
      const _params = { ...params };
      setLoadingPopup(true);
      await getChatMessageListApi(data.id, params)
        .then(({ data }) => {
          setMessages({
            records: data.data.rows,
            metadata: getMetadata(data.data, _params?.page, _params?.limit),
          });
          setLoadingPopup(false);
        })
        .catch((e) => NotifyService.error(e));
    };

    const onReloadList = () => {
      fetchMessages();
    };

    useEffect(() => {
      fetchMessages();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, params]);

    const handleChangeRowsPerPage = (event: SelectChangeEvent) => {
      const limit = parseInt(event.target.value, 10);
      setParams({ ...params, page: 1, limit });
    };

    const { items } = usePagination({
      count: messages?.metadata.total_pages,
      page: messages?.metadata.page,
      onChange(_event, page) {
        setParams({ ...params, page: page });
      },
    });

    const onClosePopup = (type: TYPE_POPUP) => {
      switch (type) {
        case TYPE_POPUP.DELETE:
          setOpenDeletePopup(false);
          setItemAction(undefined);
          break;
      }
    };

    const setOpenDelete = (event: any, data: MESSAGE_CHAT) => {
      event.stopPropagation();
      setItemAction(data);
      setOpenDeletePopup(true);
    };

    const getSenderType = (item: MESSAGE_CHAT): MESSAGE_SENDER_TYPE => {
      if (item.createdBy === 'system') return MESSAGE_SENDER_TYPE.SYSTEM;
      else if (item.send_by === data?.parent_id)
        return MESSAGE_SENDER_TYPE.PARENT;
      return MESSAGE_SENDER_TYPE.SITTER;
    };

    const getSendBy = (item: MESSAGE_CHAT) => {
      const senderType = getSenderType(item);
      switch (senderType) {
        case MESSAGE_SENDER_TYPE.SYSTEM:
          return 'System';
        case MESSAGE_SENDER_TYPE.PARENT:
          return `${data?.Parent?.User?.first_name} ${data?.Parent?.User?.last_name}`;
        case MESSAGE_SENDER_TYPE.SITTER:
          return `${data?.Sitter?.User?.first_name} ${data?.Sitter?.User?.last_name}`;
      }
    };

    const getMessageContent = (item: MESSAGE_CHAT) => {
      switch (item.type) {
        case MESSSAGE_CHAT_TYPE.image:
          return (
            <>
              Message{' '}
              <Typography
                component="a"
                href={item.message}
                sx={{
                  color: '#226FE3',
                  fontWeight: 400,
                  fontSize: '14px',
                  textDecoration: 'underline',
                }}
              >
                Picture
              </Typography>
            </>
          );
        case MESSSAGE_CHAT_TYPE.audio:
          return (
            <>
              Message{' '}
              <Typography
                component="a"
                href={item.message}
                sx={{
                  color: '#226FE3',
                  fontWeight: 400,
                  fontSize: '14px',
                  textDecoration: 'underline',
                }}
              >
                Voice
              </Typography>
            </>
          );
        case MESSSAGE_CHAT_TYPE.video:
          return (
            <>
              Message{' '}
              <Typography
                component="a"
                href={item.message}
                sx={{
                  color: '#226FE3',
                  fontWeight: 400,
                  fontSize: '14px',
                  textDecoration: 'underline',
                }}
              >
                Video
              </Typography>
            </>
          );
        case MESSSAGE_CHAT_TYPE.text:
          return item.message;
      }
    };

    const sortItem = useMemo(() => {
      return getSortItem(params?.sort);
    }, [params]);

    const handleRequestSort = (
      _: React.MouseEvent<unknown>,
      property: keyof MESSAGE_CHAT
    ) => {
      const isAsc = sortItem?.orderBy === property && sortItem?.order === 'asc';
      setParams({
        ...params,
        sort: `${property}-${isAsc ? 'desc' : 'asc'}`,
      });
    };

    const handleShowDelete = (val: boolean) => {
      if (val === null) return;
      setParams({ ...params, deleted: val });
    };

    const onDelete = async () => {
      if (!itemAction) return;
      await deleteChatMessageApi(itemAction.channel_id, itemAction.id)
        .then((r) => {
          onReloadList();
          onClosePopup(TYPE_POPUP.DELETE);
          NotifyService.success(r);
        })
        .catch((e) => NotifyService.error(e))
        .finally(() => {});
    };

    const renderColorRow = (type: MESSAGE_SENDER_TYPE, item: MESSAGE_CHAT) => {
      if (item.deleted) return '#fff4f4';
      switch (type) {
        case MESSAGE_SENDER_TYPE.SYSTEM:
          return undefined;
        case MESSAGE_SENDER_TYPE.PARENT:
          return undefined;
        case MESSAGE_SENDER_TYPE.SITTER:
          return 'rgba(34, 111, 227, 0.08)';
      }
    };

    return (
      <Dialog
        open={open}
        maxWidth={'md'}
        sx={{
          '& .MuiPaper-root': {
            height: 'auto',
            borderRadius: 0,
          },
        }}
      >
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '20px 25px',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '100%',
            }}
          >
            Chat Messages
          </Typography>
          <IconButton onClick={_handleClosePopup} sx={{ p: 0 }}>
            <CloseCircle />
          </IconButton>
        </Grid>
        <Divider />
        {loadingPopup && <LoadingPopup color="primary" />}
        <Box sx={{ height: '100%' }}>
          <Stack
            p="17px 25px 26px"
            height="100%"
            justifyContent="space-between"
          >
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="row" spacing="40px">
                <Box>
                  <DetailItemTitle>Conversation ID</DetailItemTitle>
                  <DetailItemData>{data?.id}</DetailItemData>
                </Box>
                <Box>
                  <DetailItemTitle>Parent</DetailItemTitle>
                  <DetailItemData sx={{ color: '#226FE3' }}>
                    {data?.Parent?.User?.first_name}&nbsp;
                    {data?.Parent?.User?.last_name}
                  </DetailItemData>
                </Box>
                <Box>
                  <DetailItemTitle>Sitter</DetailItemTitle>
                  <DetailItemData sx={{ color: '#226FE3' }}>
                    {data?.Sitter?.User?.first_name}&nbsp;
                    {data?.Sitter?.User?.last_name}
                  </DetailItemData>
                </Box>
              </Stack>
              <Stack justifyContent="center" alignItems="center">
                <DetailItemTitle>Show Deleted</DetailItemTitle>
                <DetailItemData>
                  <Stack>
                    <ToggleField
                      value={params?.deleted || false}
                      onChange={handleShowDelete}
                    />
                  </Stack>
                </DetailItemData>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            spacing="8px"
            sx={{
              mb: '15px',
            }}
          >
            <TableContainer
              component={Paper}
              elevation={0}
              sx={{
                border: '1px solid #D9D9D9',
                maxHeight: 'calc(100vh - 355px)',
              }}
            >
              <Table
                stickyHeader
                sx={{ minWidth: 823 }}
                aria-labelledby="tableTitle"
              >
                <HeadTable<MESSAGE_CHAT>
                  onCheckbox={false}
                  order={sortItem?.order}
                  orderBy={sortItem?.orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={messages?.records.length}
                  headCells={headCells}
                />
                <TableBody>
                  {!!messages?.records?.length ? (
                    messages?.records.map((row) => {
                      const senderType = getSenderType(row);
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                          sx={{
                            background: renderColorRow(senderType, row),
                          }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{
                              borderRight: '1px solid #DEE2E6',
                              padding: '18px 12px 17px',
                              fontSize: '14px',
                              fontWeight: 400,
                            }}
                          >
                            {getSendBy(row)}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              borderRight: '1px solid #DEE2E6',
                              padding: '18px 12px 17px',
                              fontSize: '14px',
                              fontWeight: 400,
                              color: row.deleted ? '#8E8E93' : undefined,
                            }}
                          >
                            {getMessageContent(row)}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              borderRight: '1px solid #DEE2E6',
                              padding: '18px 12px 17px',
                              fontSize: '14px',
                              fontWeight: 400,
                            }}
                          >
                            {row.trackedBy}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              borderRight: '1px solid #DEE2E6',
                              padding: '18px 12px 17px',
                              fontSize: '14px',
                              fontWeight: 400,
                            }}
                          >
                            {formatDateTime(row.createdAt)}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              borderRight: '1px solid #DEE2E6',
                              padding: '18px 12px 17px',
                            }}
                          >
                            <Stack direction="row" spacing="10px">
                              {senderType !== MESSAGE_SENDER_TYPE.SYSTEM &&
                                !row.deleted && (
                                  <ButtonDelete
                                    onClick={(e) => setOpenDelete(e, row)}
                                  />
                                )}
                            </Stack>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCellNoData colSpan={headCells.length + 1} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TableCaption
              rowsPerPage={params?.limit ?? 0}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              metadata={messages?.metadata}
              items={items}
            />
          </Stack>
        </Box>
        {openDeletePopup && (
          <DeleteDialog
            name=""
            open={openDeletePopup}
            subject={itemAction?.id}
            onDelete={onDelete}
            onClose={() => onClosePopup(TYPE_POPUP.DELETE)}
          />
        )}
      </Dialog>
    );
  }
);

export default PopupDetailChat;
