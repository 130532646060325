import React from 'react';

const EyeCloseInputs = ({ ...props }) => {
  return (
    <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M19.2532 0.964321C19.0775 0.788586 18.7926 0.788586 18.6168 0.964321L3.1818 16.3994L3.12414 16.4699C3.00881 16.6446 3.02803 16.882 3.1818 17.0357C3.35754 17.2115 3.64246 17.2115 3.8182 17.0357L19.2532 1.60072L19.3109 1.53012C19.4262 1.35546 19.407 1.11809 19.2532 0.964321ZM11 2.28252C12.5925 2.28252 14.1589 2.67544 15.699 3.4579L15.0907 4.06622C13.7389 3.41783 12.3755 3.09502 11 3.09502C7.80406 3.09502 4.67357 4.83748 1.60487 8.3559L1.3874 8.60832C1.30166 8.70904 1.30166 8.856 1.3874 8.95672C2.93957 10.7803 4.5073 12.1485 6.09114 13.0657L5.49579 13.6611C3.88676 12.6956 2.30701 11.3004 0.756996 9.47931C0.41407 9.07642 0.41407 8.48862 0.758584 8.08387L0.979271 7.82773C4.19326 4.14271 7.53526 2.28252 11 2.28252ZM11 4.99085C11.8914 4.99085 12.7119 5.29044 13.3637 5.79315L12.7805 6.37644C12.2813 6.01609 11.6658 5.80335 11 5.80335C9.33279 5.80335 7.98125 7.13717 7.98125 8.78252C7.98125 9.45373 8.20617 10.0731 8.58568 10.5712L8.0024 11.1545C7.47405 10.505 7.15796 9.6802 7.15796 8.78252C7.15796 6.68844 8.8781 4.99085 11 4.99085ZM14.3513 6.92691L13.7425 7.53573C13.9198 7.91503 14.0188 8.33737 14.0188 8.78252C14.0188 10.4279 12.6672 11.7617 11 11.7617C10.5631 11.7617 10.1478 11.6701 9.77293 11.5053L9.1641 12.1141C9.70957 12.4075 10.3351 12.5742 11 12.5742C13.1219 12.5742 14.842 10.8766 14.842 8.78252C14.842 8.10843 14.6638 7.47543 14.3513 6.92691ZM11 14.47C9.82871 14.47 8.66615 14.2359 7.51213 13.7661L6.89096 14.3872C8.24065 14.9833 9.61042 15.2825 11 15.2825C14.4647 15.2825 17.8067 13.4223 21.0207 9.73731L21.2414 9.48117C21.5859 9.07642 21.5859 8.48861 21.243 8.08572C19.8646 6.46635 18.4628 5.18369 17.0377 4.24052L16.4485 4.82969C17.849 5.7336 19.2369 6.99209 20.6126 8.60832C20.6983 8.70904 20.6983 8.856 20.6126 8.95672L20.3951 9.20913C17.3264 12.7276 14.1959 14.47 11 14.47Z" fill="black" fillOpacity="0.54" />
    </svg>
  );
};

export default EyeCloseInputs;
