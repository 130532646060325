import { API } from 'config';
import { GET_REQUESTS_PARAMS_REQUEST } from 'models';
import { AppAPIInstance } from 'services';

export const getRequestsListApi = async (
  params?: GET_REQUESTS_PARAMS_REQUEST
) => {
  return await AppAPIInstance.get(API.ADMIN.SR.DEFAULT, {
    params,
  });
};

export const getRequestsDetailApi = async (id: string) => {
  return await AppAPIInstance.get(API.ADMIN.SR.BY_ID.replace('{id}', id));
};

export const deleteRequestsApi = async (id: string) => {
  return await AppAPIInstance.delete(API.ADMIN.SR.BY_ID.replace('{id}', id));
};

export const deleteSitterRequestsApi = async (id: string, sitter_id?: any) => {
  return await AppAPIInstance.delete(API.ADMIN.SR.SITTERS.replace('{id}', id), {
    data: sitter_id,
  });
};

export const deleteAllRequestsApi = async (ids: string[]) => {
  return await AppAPIInstance.delete(API.ADMIN.SR.DEFAULT, {
    data: {
      ids,
    },
  });
};

export const addManySittersApi = async (id: any, data?: any) => {
  return await AppAPIInstance.put(API.ADMIN.SR.SITTERS.replace('{id}', id), {
    sitterIds: data.sitterIds,
  });
};

export const UpdateRequestsApi = async (id: string, data?: any) => {
  return await AppAPIInstance.put(API.ADMIN.SR.BY_ID.replace('{id}', id), data);
};

export const UpdateRateOfferApi = async (
  id: string | undefined,
  data?: any
) => {
  return await AppAPIInstance.put(`${API.ADMIN.SR.RATE_OFFER}${id}`, data);
};

export const createRequestsApi = async (type: string, data?: any) => {
  return await AppAPIInstance.post(API.ADMIN.SR.DEFAULT + `${type}`, data);
};

export const requestForPaymentApi = async (id: string, data?: any) => {
  return await AppAPIInstance.post(
    API.ADMIN.SR.REQUEST_FOR_PAYMENT.replace('{id}', id),
    data
  );
};

export const makePaymentApi = async (id: string, data?: any) => {
  return await AppAPIInstance.post(
    API.ADMIN.SR.MAKE_PAYMENT.replace('{id}', id),
    data
  );
};
