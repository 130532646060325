import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { CloseCircle } from 'asset';
import { DeleteDialog } from 'components/common';
import { Inputs, SelectInput } from 'components/form-control';
import PopupWarningAcceptOrRequestPayment from 'components/Popups/Warning/PopupWarningAcceptOrRequestPayment';
import yup from 'config/yup.custom';
import { NotifyService } from 'helpers';
import {
  BOOKINGS_TYPE,
  BOOKING_STATUS,
  REQUESTS_DETAIL_TYPE,
  TYPE_POPUP,
} from 'models';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { UpdateRateOfferApi } from 'services';
import { useAppSelector } from 'store/hook';
// import { useForm } from 'react-hook-form';
import PopupEditHourlyFee from '../PopupEditHourlyFee';

interface Props {
  open: boolean;
  handleClosePopup?: any;
  onSuccess?: any;
  onClose?: any;
  subject?: BOOKINGS_TYPE;
  data_sr?: REQUESTS_DETAIL_TYPE | null;
}

interface IFormInputs {
  salary_per_hour: number | undefined;
  status: string;
  total_hours: number | undefined;
  total_fee: number | undefined;
}

const OPTIONS_DATA = [
  {
    value: BOOKING_STATUS.PAID,
    name: 'Paid',
  },
  {
    value: BOOKING_STATUS.RFP,
    name: 'RFP',
  },
  {
    value: BOOKING_STATUS.APPLIED,
    name: 'Applied',
  },
  {
    value: BOOKING_STATUS.INVITED,
    name: 'Invited',
  },
  {
    value: BOOKING_STATUS.CANCELED,
    name: 'Canceled',
  },
];

const PopupEditSitter = (props: Props) => {
  const { open, handleClosePopup, subject, onSuccess, data_sr, onClose } =
    props;
  // const theme = useTheme();

  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [openEditHourlyFeePopup, setOpenEditHourlyFeePopup] = useState(false);
  const [openAcceptOrRequestPopup, setOpenAcceptOrRequestPopup] =
    useState(false);
  const [itemSitter, setItemSitter] = useState({});
  const [totalFee, setTotalFee] = useState<number>(0);

  const { requests_detail } = useAppSelector(
    (state) => state.requestReducer.requests
  );

  const schema = useMemo(() => {
    return yup.object({
      sitterIds: yup.mixed().notRequired(),
    });
  }, []);

  const form = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      salary_per_hour: subject?.salary_per_hour,
      status: subject?.status,
      total_hours: data_sr?.total_hours,
      total_fee: 0,
    },
  });

  useEffect(() => {
    if (subject && data_sr) {
      const data_total: any = subject?.salary_per_hour * data_sr?.total_hours;
      setTotalFee(data_total);
      form.reset({
        salary_per_hour: subject?.salary_per_hour,
        status: subject?.status,
        total_hours: data_sr?.total_hours,
        total_fee: data_total.toFixed(2),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subject, data_sr]);

  const { handleSubmit } = form;

  // const dispatch = useAppDispatch();

  // useEffect(() => {
  //   if (subject) {
  //     dispatch({
  //       type: GET_REQUESTS_DETAIL_REQUEST,
  //       payload: {
  //         id: subject.id,
  //       },
  //     });
  //   }
  //   // eslint-disable-next-line
  // }, [subject]);

  // const setOpenDelete = (event: any, data: any) => {
  //   event.stopPropagation();
  //   setItemSitter({
  //     sitter_id: data,
  //   });
  //   setOpenDeletePopup(true);
  // };
  const setOpenPopup = (event: any, type: number, data: any) => {
    switch (type) {
      case TYPE_POPUP.ACCEPT:
        event.stopPropagation();
        setOpenAcceptOrRequestPopup(true);
        setItemSitter(data);
        break;
      case TYPE_POPUP.EDIT:
        event.stopPropagation();
        setOpenEditHourlyFeePopup(true);
        setItemSitter(data);
        break;
    }
  };

  const onClosePopup = (type: number) => {
    switch (type) {
      case TYPE_POPUP.DELETE:
        setOpenDeletePopup(false);
        setItemSitter({});
        break;
      case TYPE_POPUP.ACCEPT:
        setOpenAcceptOrRequestPopup(false);
        setItemSitter({});
        break;
      case TYPE_POPUP.EDIT:
        setOpenEditHourlyFeePopup(false);
        setItemSitter({});
        break;
    }
  };

  // const getOptions =
  //   (key: keyof IFormInputs) =>
  //   async (page: number = 1, limit: number = 20, keyword?: string) => {
  //     let data: GetOptionsResponse | null = null;
  //     switch (key) {
  //       case 'sitterIds':
  //         const paramsSitter: GET_SITTERS_PARAMS_REQUEST = {
  //           page: page,
  //           limit: limit,
  //           status: 'active',
  //         };
  //         if (keyword) paramsSitter.keyword = keyword;
  //         data = await getSittersListApi(paramsSitter)
  //           .then(({ data }) => {
  //             return {
  //               metadata: data.data.metadata,
  //               options: data.data.records.map((it: SITTER_TYPE) => ({
  //                 ...it,
  //                 id: it.sitter_id,
  //                 label: `${it.first_name} ${it.last_name}`,
  //               })),
  //             };
  //           })
  //           .catch((e) => {
  //             NotifyService.error(e);
  //             return null;
  //           });
  //         break;
  //     }
  //     return data;
  //   };

  const onSubmit = async (data: IFormInputs) => {
    const new_data = {
      salary_per_hour: data.salary_per_hour,
      status: data.status,
    };
    await UpdateRateOfferApi(subject?.id, new_data)
      .then((r) => {
        onSuccess();
        onClose();
        NotifyService.success(r);
      })
      .catch((e) => NotifyService.error(e))
      .finally(() => {});
  };

  // useEffect(() => {
  //   if (
  //     (requests_detail?.bookings && requests_detail?.bookings.length >= 5) ||
  //     requests_detail?.status !== 'pending'
  //   ) {
  //     setIsDisable(true);
  //   } else {
  //     setIsDisable(false);
  //   }
  // }, [requests_detail]);

  // const DropdownIndicator = (props: DropdownIndicatorProps) => {
  //   return (
  //     <components.DropdownIndicator {...props}>
  //       <Magnifier />
  //     </components.DropdownIndicator>
  //   );
  // };

  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiPaper-root': {
          width: 530,
          borderRadius: 0,
        },
      }}
    >
      <Grid>
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '20px 25px',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '100%',
            }}
          >
            Edit Sitters
          </Typography>
          <IconButton
            onClick={handleClosePopup}
            sx={{
              padding: 0,
            }}
          >
            <CloseCircle />
          </IconButton>
        </Grid>
        <Divider />
        <Box p="23px 32px">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack mb="24px" spacing="16px">
              <Grid>
                <SelectInput
                  title="Status"
                  form={form}
                  options={OPTIONS_DATA}
                  name="status"
                />
              </Grid>
              <Stack
                direction="row"
                justifyContent="space-between"
                spacing="14px"
              >
                <Grid>
                  <Inputs
                    type="number"
                    title="Hourly Rate"
                    form={form}
                    name="salary_per_hour"
                  />
                </Grid>
                <Grid>
                  <Inputs
                    type="number"
                    title="Total hours"
                    form={form}
                    name="total_hours"
                    readOnly
                  />
                </Grid>
                <Grid>
                  <Inputs
                    type="number"
                    title="Total Fee"
                    form={form}
                    name="total_fee"
                    readOnly
                  />
                </Grid>
              </Stack>
              <Button
                onClick={(e) => setOpenPopup(e, TYPE_POPUP.EDIT, subject)}
                variant="contained"
                color="secondary"
              >
                Make Payment
              </Button>
            </Stack>
            <Divider />

            <Stack
              direction="row"
              justifyContent="space-between"
              mt="32px"
              spacing="8px"
            >
              <Button
                variant="outlined"
                color="secondary"
                sx={{
                  width: '233.5px',
                  height: '30px',
                }}
                onClick={() => {
                  onClose();
                }}
              >
                Cancel
              </Button>
              <Button
                color="secondary"
                variant="contained"
                sx={{
                  width: '233.5px',
                  height: '30px',
                }}
                type="submit"
              >
                Update
              </Button>
            </Stack>
          </form>
        </Box>
      </Grid>
      {openDeletePopup && (
        <DeleteDialog
          name=""
          open={openDeletePopup}
          onClose={() => onClosePopup(TYPE_POPUP.DELETE)}
          subject={requests_detail?.id}
          onSuccess={onSuccess}
          type="sitterSR"
          data={itemSitter}
        />
      )}
      {openAcceptOrRequestPopup && (
        <PopupWarningAcceptOrRequestPayment
          open={openAcceptOrRequestPopup}
          onClose={() => onClosePopup(TYPE_POPUP.ACCEPT)}
          subject={itemSitter}
          onSuccess={onSuccess}
        />
      )}
      {openEditHourlyFeePopup && (
        <PopupEditHourlyFee
          onSuccess={onSuccess}
          open={openEditHourlyFeePopup}
          onClose={() => onClosePopup(TYPE_POPUP.EDIT)}
          subject={subject}
          parent_id={data_sr?.parent.parent_id}
          totalFee={totalFee}
        />
      )}
    </Dialog>
  );
};

export default PopupEditSitter;
