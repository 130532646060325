import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { CloseCircle } from 'asset';
import { LoadingPopup } from 'components/common';
import {
  CheckboxField,
  InputField,
  SelectField,
} from 'components/form-control';
import { GetOptionsResponse } from 'components/form-control/Select';
import yup from 'config/yup.custom';
import { NotifyService } from 'helpers';
import {
  BOOKING_ACTIVITY_LOG,
  GET_NANNY_PARAMS_REQUEST,
  NANNY_BOOKING_DETAIL_TYPE,
  NANNY_BOOKING_TYPE,
  OptionItem,
  bookingActivityLogTypes,
} from 'models';
import { memo, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { nannyBookingApi } from 'services';
import { nannyApi } from 'services/accounts/nanny';
import { useAppSelector } from 'store/hook';

interface PopupCreateOrEditActivityBookingProps {
  subject?: BOOKING_ACTIVITY_LOG;
  booking: NANNY_BOOKING_TYPE | NANNY_BOOKING_DETAIL_TYPE;
  onClose: () => void;
  onSuccess: () => void;
}

interface IFormInputs {
  type: OptionItem<string>;
  description: string;
  amount: number | string;
  isShowToParent: boolean;
  isShowToNannies: boolean;
  nannyList?: OptionItem<string>[];
}

const defaultValues: Partial<IFormInputs> = {
  description: '',
  isShowToParent: true,
  isShowToNannies: false,
};

export const PopupCreateOrEditActivityBooking = memo(
  ({
    subject,
    booking,
    onClose,
    onSuccess,
  }: PopupCreateOrEditActivityBookingProps) => {
    const { loadingPopup } = useAppSelector((state) => state.globalReducer);
    const [nanniesInBooking, setNanniesInBooking] = useState<
      OptionItem<string>[]
    >([]);
    const [loading, setLoading] = useState<boolean>(false);

    const schema = useMemo(() => {
      return yup.object().shape({
        type: yup
          .mixed()
          .required('Activity is required')
          .nullable(false)
          .typeError('Activity is required'),
        description: yup.string().notRequired(),
        amount: yup
          .number()
          .typeError('Invalid number format')
          .empty()
          .notRequired(),
        isShowToNannies: yup.boolean(),
        nannyList: yup.array(yup.mixed()).when('isShowToNannies', {
          is: (val: boolean) => !!val,
          then: (schema) =>
            schema
              .min(1, 'Nannies is required')
              .required('Nannies is required'),
          otherwise: (schema) => schema.notRequired(),
        }),
      });
    }, []);

    const form = useForm<IFormInputs>({
      resolver: yupResolver(schema),
      mode: 'onChange',
      defaultValues: defaultValues,
    });

    const { control, reset, handleSubmit, watch, getValues, setValue } = form;

    const [isShowToNannies] = watch(['isShowToNannies']);

    useEffect(() => {
      const fetchData = async () => {
        await nannyBookingApi
          .getNanniesInBooking(booking.bookingId)
          .then(({ data: { data } }) =>
            setNanniesInBooking(
              data.map((t) => ({ id: t.nannyId, label: t.nannyName }))
            )
          )
          .catch(() => {});
      };
      fetchData();
    }, [booking]);

    useEffect(() => {
      if (subject) {
        reset({
          type: bookingActivityLogTypes.find((t) => t.id === subject.type),
          description: subject.description || '',
          amount: subject.amount || '',
          isShowToParent: !!subject.parent,
          isShowToNannies: !!subject.nannyList?.length,
          nannyList:
            subject.nannyList?.map((t) => ({
              id: t.nannyId,
              label: `${t.firstName} ${t.lastName}`.trim(),
            })) || [],
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subject]);

    const onSubmit = async (data: IFormInputs) => {
      setLoading(true);
      const dataCommon = {
        type: data.type?.id,
        description: data.description,
        amount: (data.amount ?? null) !== null ? Number(data.amount) : null,
        nannyList: data.isShowToNannies ? data.nannyList?.map((t) => t.id) : [],
        parentId: data.isShowToParent ? booking.parentId : null,
      };
      if (subject) {
        await nannyBookingApi
          .updateActivityLogInBooking({
            activityId: subject.id,
            ...dataCommon,
          })
          .then(() => {
            NotifyService.success('Success');
            onSuccess();
            _onClose();
          })
          .catch((e) => NotifyService.error(e))
          .finally(() => setLoading(false));
      } else {
        await nannyBookingApi
          .createActivityLogInBooking({
            bookingId: booking.bookingId,
            ...dataCommon,
          })
          .then(() => {
            NotifyService.success('Success');
            onSuccess();
            _onClose();
          })
          .catch((e) => NotifyService.error(e))
          .finally(() => setLoading(false));
      }
    };

    const _onClose = () => {
      onClose?.();
    };

    const getOptions =
      (key: keyof IFormInputs) =>
      async (pageNum: number = 1, _pageSize: number = 20, keyword?: string) => {
        let data: GetOptionsResponse | null = null;

        switch (key) {
          case 'nannyList':
            const paramsParent: GET_NANNY_PARAMS_REQUEST = {
              pageNum,
              pageSize: 20,
            };
            if (keyword) paramsParent.keyword = keyword;
            data = await nannyApi
              .getAll(paramsParent)
              .then(({ data }) => {
                return {
                  metadata: {
                    page: data?.pageNum,
                    limit: data?.pageSize,
                    page_count: data?.data?.length || 0,
                    total_pages: data?.totalPages,
                    total_count: data?.totalSize,
                  },
                  options: data.data.map((it) => ({
                    id: it.nannyId,
                    label: `${it.firstName} ${it.lastName}`.trim(),
                  })),
                };
              })
              .catch((e) => {
                NotifyService.error(e);
                return null;
              });
            break;
        }

        return data;
      };

    const onToggleShowToNannies = (checked: boolean) => {
      if (checked) {
        const nanies = getValues('nannyList');
        if (!nanies?.length)
          setValue('nannyList', nanniesInBooking, {
            shouldValidate: !!nanniesInBooking?.length,
          });
      }
    };

    return (
      <Dialog
        open
        sx={{
          '& .MuiPaper-root': {
            width: 523,
            height: 'auto',
            borderRadius: 0,
          },
        }}
      >
        <DialogTitle
          sx={{
            padding: '20px 25px',
          }}
        >
          <Grid
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '16px',
                lineHeight: '100%',
              }}
            >
              {subject ? 'Edit' : 'Create'} Activity
            </Typography>
            <IconButton
              onClick={_onClose}
              sx={{
                padding: 0,
              }}
            >
              <CloseCircle />
            </IconButton>
          </Grid>
        </DialogTitle>
        {(loadingPopup || loading) && <LoadingPopup color="primary" />}
        <DialogContent
          sx={{
            p: '30px 24px 50px 26px',
          }}
          dividers
        >
          <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
            <Stack height="100%" justifyContent="space-between">
              <Stack spacing="16px">
                <SelectField
                  title="Activity"
                  name="type"
                  placeholder="Activity"
                  control={control}
                  options={bookingActivityLogTypes}
                />
                <InputField
                  control={control}
                  name="amount"
                  placeholder="value"
                  title="Value (optional)"
                />
                <InputField
                  rows={6}
                  control={control}
                  name="description"
                  placeholder="Remark"
                  title="Remark (optional)"
                />
                <Divider />
                <Stack direction="row" alignItems="flex-start" spacing={3}>
                  <CheckboxField
                    label="Show to parent"
                    name="isShowToParent"
                    control={control}
                  />
                  <CheckboxField
                    label="Show to the below nannies"
                    name="isShowToNannies"
                    control={control}
                    onChange={(_, checked) => onToggleShowToNannies(checked)}
                  />
                </Stack>
                <SelectField
                  isMulti
                  isHasMore
                  title="Nannies"
                  name="nannyList"
                  placeholder="Nannies"
                  control={control}
                  isDisabled={!isShowToNannies}
                  filterFunc={false}
                  getOptions={getOptions('nannyList')}
                />
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                marginTop="25px"
                spacing="8px"
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  sx={{
                    width: '233.5px',
                    height: '30px',
                  }}
                  onClick={_onClose}
                >
                  Cancel
                </Button>
                <Button
                  color="secondary"
                  variant="contained"
                  sx={{
                    width: '233.5px',
                    height: '30px',
                  }}
                  type="submit"
                >
                  {subject ? 'Update' : 'Create'}
                </Button>
              </Stack>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    );
  }
);

export default PopupCreateOrEditActivityBooking;
