import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { CloseCircle, DirectionRight } from 'asset';
import { DateTimePicker, Inputs, TimerPicker } from 'components/form-control';
import {
  getParentAddressApi,
  getParentChildrenApi,
  getSittersListApi,
} from 'services';
import PopupEditAddress from '../../PopupEditAddress';
import {
  GET_SITTERS_PARAMS_REQUEST,
  OptionItem,
  SITTER_TYPE,
  JOB_DETAIL_TYPE,
  ADDRESS_PARENT_DETAIL_TYPE,
  ADDRESS_DETAIL_PARENT_DETAIL_TYPE,
  UPDATE_JOB_BODY,
  CHILDREN_PARENT_DETAIL_TYPE,
  JOB_STATUSES,
} from 'models';
import Select, { GetOptionsResponse } from 'components/form-control/Selects';
import { changeAge, NotifyService } from 'helpers';
import { getJobDetailApi, updateJobApi } from 'services/jobs';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'config/yup.custom';
import { useDateTime } from 'hooks';
import PopupCompletedJob from '../PopupCompletedJob';
import PopupCancelledJob from '../PopupCancelledJob';
import { LoadingPopup } from 'components/common';

interface Props {
  open: boolean;
  jobId?: string;
  parentId?: string;
  sitterId?: string;
  onSuccess: () => void;
  handleClosePopup: () => void;
}

interface IFormInputs {
  from_time: string;
  from_date: string;
  to_time: string;
  to_date: string;
  address: OptionItem | null;
  status: OptionItem | null;
  sitter_id: OptionItem | null;
  id: string;
  parent: string;
  children: {
    id: string;
    picture: string | null;
    age_range: number;
    full_name: string;
    is_checked: boolean;
  }[];
  salary_per_hour: string;
}

const defaultValues: IFormInputs = {
  from_time: '',
  from_date: '',
  to_time: '',
  to_date: '',
  address: null,
  status: null,
  sitter_id: null,
  id: '',
  parent: '',
  children: [],
  salary_per_hour: '',
};

const PopupEditJobs = (props: Props) => {
  const theme = useTheme();
  const { moment } = useDateTime();
  const { open, jobId, handleClosePopup, onSuccess } = props;

  const [job, setJob] = useState<JOB_DETAIL_TYPE>();
  const [openEditAddress, setOpenEditAddress] = useState(false);
  const [openAddAddress, setOpenAddAddress] = useState(false);
  const [openCompletedJob, setOpenCompletedJob] = useState(false);
  const [openCancelledJob, setOpenCancelledJob] = useState(false);
  const [loadingPopup, setLoadingPopup] = useState(false);

  const schema = useMemo(() => {
    return yup.object({
      from_time: yup.string().required('From time is required'),
      from_date: yup.string().required('From date is required'),
      to_time: yup.string().required('To time is required'),
      to_date: yup.string().required('To date is required'),
      address: yup.mixed().required('Address is required'),
      status: yup.mixed().required('Status is required'),
      sitter_id: yup.mixed().required('Sitter is required'),
      id: yup.string().notRequired(),
      parent: yup.string().notRequired(),
      children: yup.mixed().notRequired(),
      sitter_rate: yup.string().notRequired(),
    });
  }, []);

  const form = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  const { reset, handleSubmit, control } = form;

  const { fields: children } = useFieldArray({
    control,
    name: 'children',
  });

  const _handleClosePopup = () => {
    reset({ ...defaultValues });
    setJob(undefined);
    handleClosePopup();
  };

  useEffect(() => {
    if (jobId) {
      const getDetail = async () => {
        setLoadingPopup(true);
        await getJobDetailApi(jobId)
          .then(({ data }) => {
            setJob(data.data);
            setLoadingPopup(false);
          })
          .catch((e) => NotifyService.error(e))
          .finally(() => {});
      };
      getDetail();
    }

    // eslint-disable-next-line
  }, [jobId]);

  const updateStatusSuccess = () => {
    if (jobId) {
      const getDetail = async () => {
        setLoadingPopup(true);
        await getJobDetailApi(jobId)
          .then(({ data }) => {
            setJob(data.data);
            setLoadingPopup(false);
          })
          .catch((e) => NotifyService.error(e))
          .finally(() => {});
      };
      getDetail();
    }
    onSuccess();
  };

  useEffect(() => {
    if (job) {
      const setFormData = async () => {
        const parentChildren: CHILDREN_PARENT_DETAIL_TYPE[] =
          await getParentChildrenApi(job.parent.id)
            .then(({ data }) => data.records)
            .catch((e) => {
              NotifyService.error(e);
              return [];
            });
        reset({
          id: job.number,
          from_time: moment(job.start_datetime.time).toISOString(),
          from_date: moment(job.start_datetime.date).toISOString(),
          to_time: moment(job.end_datetime.time).toISOString(),
          to_date: moment(job.end_datetime.date).toISOString(),
          address: job.address
            ? {
                ...job.address,
                label: getAddressLabel(job.address),
              }
            : null,
          sitter_id: job.sitter
            ? {
                id: job.sitter.id,
                label: `${job.sitter.first_name} ${job.sitter.last_name}`,
              }
            : null,
          status: JOB_STATUSES.find((t) => t.id === job.status),
          parent: `${job.parent.first_name} ${job.parent.last_name}`,
          children: parentChildren.map((item) => ({
            id: item.id,
            picture: item.picture,
            age_range: item.age_range,
            full_name: item.children_name,
            is_checked: !!job.children.find((temp) => temp.id === item.id),
          })),
          salary_per_hour: job.sitter?.salary_per_hour
            ? `$${job.sitter.salary_per_hour}`
            : '',
        });
      };
      setFormData();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job]);

  const onSubmit = async (data: IFormInputs) => {
    if (!job) return;
    const body: UPDATE_JOB_BODY = {
      start_day: moment(data.from_date).format('YYYY-MM-DD'),
      start_time: moment(data.from_time).format('HH:mm'),
      end_day: moment(data.to_date).format('YYYY-MM-DD'),
      end_time: moment(data.to_time).format('HH:mm'),
      address_id: data.address?.id,
      children: data.children
        ?.filter((it) => it.is_checked)
        ?.map((item) => ({
          id: item.id,
          picture: item.picture,
          age_range: item.age_range,
          full_name: item.full_name,
        })),
      status: data.status?.id,
      sitter_id: data.sitter_id?.id,
    };
    await updateJobApi(job.id, body)
      .then((r) => {
        if (r.data.message === 'Success') {
          onSuccess();
          NotifyService.success(r);
          _handleClosePopup();
        }
      })
      .catch((e) => NotifyService.error(e))
      .finally(() => {});
  };

  const getOptions =
    (key: keyof IFormInputs) =>
    async (page: number = 1, limit: number = 20, keyword?: string) => {
      let data: GetOptionsResponse | null = null;
      switch (key) {
        case 'sitter_id':
          const paramsSitter: GET_SITTERS_PARAMS_REQUEST = {
            page: page,
            limit: limit,
          };
          if (keyword) paramsSitter.keyword = keyword;
          data = await getSittersListApi(paramsSitter)
            .then(({ data }) => {
              return {
                metadata: data.data.metadata,
                options: data.data.records.map((it: SITTER_TYPE) => ({
                  ...it,
                  id: it.sitter_id,
                  label: `${it.first_name} ${it.last_name}`,
                })),
              };
            })
            .catch((e) => {
              NotifyService.error(e);
              return null;
            });
          break;
        case 'address':
          if (job?.parent?.id) {
            data = await getParentAddressApi(job.parent.id)
              .then(({ data }) => {
                return {
                  metadata: data.metadata,
                  options: data.records
                    .map((it: ADDRESS_PARENT_DETAIL_TYPE) => ({
                      id: it.id,
                      name: it.address_name,
                      street: it.street_name,
                      block_number: it.block_no,
                      unit_number: it.unit_no,
                      country: it.country,
                      postal_code: it.postal_code,
                    }))
                    .map((it: ADDRESS_DETAIL_PARENT_DETAIL_TYPE) => ({
                      ...it,
                      label: getAddressLabel(it),
                    })),
                };
              })
              .catch((e) => {
                NotifyService.error(e);
                return null;
              });
          }
          break;
      }
      return data;
    };

  const onSuccessAddOrEditAddress = (
    data?: ADDRESS_DETAIL_PARENT_DETAIL_TYPE
  ) => {
    const address = form.getValues('address');
    if (address && address.id === data?.id) {
      form.setValue('address', { ...data, label: getAddressLabel(data) });
    }
  };

  const getAddressLabel = (data: {
    block_number: string;
    name: string;
    street: string;
    unit_number: string;
  }) => {
    return `${data?.block_number} ${data.name} ${data.street} ${data?.unit_number}`;
  };

  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiPaper-root': {
          width: 523,
          borderRadius: 0,
          height: 'auto',
        },
      }}
    >
      <DialogTitle
        sx={{
          padding: '20px 25px',
        }}
      >
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '100%',
            }}
          >
            Edit Jobs
          </Typography>
          <IconButton
            onClick={_handleClosePopup}
            sx={{
              padding: 0,
            }}
          >
            <CloseCircle />
          </IconButton>
        </Grid>
      </DialogTitle>
      {loadingPopup && <LoadingPopup color="primary" />}
      <DialogContent
        sx={{
          p: '30px 24px 50px 26px',
        }}
        dividers
      >
        <Stack spacing="16px">
          <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
            <Stack height="100%" justifyContent="space-between">
              <Stack spacing="16px">
                <Grid>
                  <Inputs title="Job No." form={form} name="id" readOnly />
                </Grid>
                <Grid>
                  <Inputs title="Parent" form={form} name="parent" readOnly />
                </Grid>
                <Grid>
                  <Stack direction="row" spacing="14px" alignItems="flex-end">
                    <Stack
                      sx={{
                        width: '50%',
                      }}
                    >
                      <Select
                        title="Sitter"
                        name="sitter_id"
                        form={form}
                        isHasMore
                        filterFunc={false}
                        getOptions={getOptions('sitter_id')}
                      />
                    </Stack>
                    <Stack
                      sx={{
                        width: '50%',
                      }}
                    >
                      <Inputs
                        title="Sitter Rate"
                        form={form}
                        name="salary_per_hour"
                        readOnly
                      />
                    </Stack>
                  </Stack>
                </Grid>
                <Grid>
                  <Stack direction="row" spacing="14px" alignItems="flex-end">
                    <Stack
                      sx={{
                        width: '50%',
                      }}
                    >
                      <DateTimePicker
                        title="Date"
                        form={form}
                        name="from_date"
                      />
                    </Stack>
                    <Typography height="30px" color="#545454">
                      -
                    </Typography>
                    <Stack
                      sx={{
                        width: '50%',
                      }}
                    >
                      <DateTimePicker form={form} name="to_date" />
                    </Stack>
                  </Stack>
                </Grid>
                <Grid>
                  <Stack direction="row" spacing="14px" alignItems="flex-end">
                    <Stack
                      sx={{
                        width: '50%',
                      }}
                    >
                      <TimerPicker title="Time" form={form} name="from_time" />
                    </Stack>
                    <Typography height="30px" color="#545454">
                      -
                    </Typography>
                    <Stack
                      sx={{
                        width: '50%',
                      }}
                    >
                      <TimerPicker form={form} name="to_time" />
                    </Stack>
                  </Stack>
                </Grid>
                <Grid>
                  <Select
                    title="Status"
                    name="status"
                    bindLabel="name"
                    form={form}
                    filterFunc={true}
                    options={JOB_STATUSES}
                  />
                </Grid>
                <Grid>
                  <Select
                    title="Address"
                    name="address"
                    form={form}
                    isHasMore
                    filterFunc
                    refreshWhenOpen
                    getOptions={getOptions('address')}
                  />
                </Grid>
                <Box display="flex" sx={{ mt: '9px !important' }}>
                  <Button
                    sx={{
                      padding: '0px',
                      color: '#226fe3',
                      fontSize: '14px',
                      fontWeight: 400,
                      marginRight: '15px',
                    }}
                    onClick={() => setOpenEditAddress(true)}
                  >
                    Edit Address
                  </Button>
                  <Button
                    sx={{
                      padding: '0px',
                      color: '#226fe3',
                      fontSize: '14px',
                      fontWeight: 400,
                    }}
                    onClick={() => setOpenAddAddress(true)}
                  >
                    Add New Address
                  </Button>
                </Box>
                <Grid>
                  <InputLabel
                    sx={{
                      fontWeight: 500,
                      fontSize: '15px',
                      lineHeight: '100%',
                      color: '#000',
                      marginBottom: '10px',
                    }}
                  >
                    Child
                  </InputLabel>
                  <FormGroup>
                    <Stack spacing="17px">
                      {children?.map((item, index) => {
                        const child = form.watch(`children.${index}`);
                        return (
                          <Stack
                            key={item.id}
                            direction="row"
                            alignItems="center"
                          >
                            <Controller
                              name={`children.${index}.is_checked`}
                              control={form.control}
                              render={({ field }) => (
                                <Checkbox
                                  {...field}
                                  checked={field.value ?? false}
                                  sx={{
                                    '&.Mui-checked': {
                                      color: theme.palette.secondary.main,
                                    },
                                  }}
                                />
                              )}
                            />
                            <Avatar
                              alt={child.full_name}
                              src={child.picture ?? ''}
                            />
                            <Stack ml="8px">
                              <Typography
                                sx={{
                                  fontWeight: 600,
                                  fontSize: '13px',
                                  lineHeight: '20px',
                                  letterSpacing: '0.1px',
                                }}
                              >
                                {child.full_name}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: '13px',
                                  lineHeight: '20px',
                                  letterSpacing: '0.1px',
                                }}
                              >
                                {changeAge(child.age_range)}
                              </Typography>
                            </Stack>
                          </Stack>
                        );
                      })}
                    </Stack>
                  </FormGroup>
                </Grid>
              </Stack>

              <Stack
                spacing="8px"
                direction="row"
                justifyContent="space-between"
                mt="32px"
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  sx={{
                    width: '233.5px',
                    height: '30px',
                  }}
                  onClick={_handleClosePopup}
                >
                  Cancel
                </Button>
                <Button
                  color="secondary"
                  variant="contained"
                  sx={{
                    width: '233.5px',
                    height: '30px',
                  }}
                  type="submit"
                >
                  Update
                </Button>
              </Stack>
            </Stack>
          </form>
          <Divider />
          <Stack spacing="10px">
            <Button
              sx={{
                background: '#fff',
                border: `1px solid ${theme.palette.secondary.main}`,
                borderRadius: '3px',
                boxShadow: 'none',

                justifyContent: 'space-between',

                color: theme.palette.secondary.main,
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '100%',
                textTransform: 'none',

                p: '14px 16px',
                height: '40px',
                ':hover': {
                  background: 'rgba(34, 111, 227, 0.08)',
                  border: `1px solid ${theme.palette.secondary.main}`,
                  borderRadius: '3px',
                  boxShadow: 'none',
                },
              }}
              variant="contained"
              endIcon={<DirectionRight color={theme.palette.secondary.main} />}
              onClick={() => setOpenCompletedJob(true)}
            >
              Mark job as Completed
            </Button>
            <Button
              sx={{
                background: '#fff',
                border: `1px solid ${theme.palette.primary.main}`,
                borderRadius: '3px',
                boxShadow: 'none',

                justifyContent: 'space-between',

                color: theme.palette.primary.main,
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '100%',
                textTransform: 'none',

                p: '14px 16px',
                height: '40px',
                ':hover': {
                  background: 'rgba(255, 121, 110, 0.13)',
                  border: `1px solid ${theme.palette.primary.main}`,
                  borderRadius: '3px',
                  boxShadow: 'none',
                },
              }}
              variant="contained"
              endIcon={<DirectionRight color={theme.palette.primary.main} />}
              onClick={() => setOpenCancelledJob(true)}
            >
              Mark job as Cancelled
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
      {job && (openEditAddress || openAddAddress) && (
        <PopupEditAddress
          open
          isEdit={openEditAddress}
          subject={job?.parent?.id}
          idAddress={form.watch('address')?.id}
          handleClosePopup={() => {
            setOpenAddAddress(false);
            setOpenEditAddress(false);
          }}
          onSuccess={onSuccessAddOrEditAddress}
        />
      )}
      {job && openCompletedJob && (
        <PopupCompletedJob
          open={openCompletedJob}
          onClose={() => {
            setOpenCompletedJob(false);
          }}
          subject={job.id}
          onSuccess={updateStatusSuccess}
        />
      )}
      {job && openCancelledJob && (
        <PopupCancelledJob
          open={openCancelledJob}
          onClose={() => {
            setOpenCancelledJob(false);
          }}
          subject={job.id}
          onSuccess={updateStatusSuccess}
        />
      )}
    </Dialog>
  );
};

export default PopupEditJobs;
