import React from 'react';

const DirectionDown = ({ ...props }) => {
  return (
    <svg
      width={props.width ? props.width : 26}
      height={props.height ? props.height : 24}
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.4168 9.16994C18.2138 8.98369 17.9392 8.87915 17.653 8.87915C17.3668 8.87915 17.0922 8.98369 16.8893 9.16994L13.0001 12.7099L9.16509 9.16994C8.96211 8.98369 8.68754 8.87915 8.40134 8.87915C8.11514 8.87915 7.84056 8.98369 7.63759 9.16994C7.53605 9.26291 7.45545 9.37351 7.40045 9.49537C7.34545 9.61723 7.31714 9.74793 7.31714 9.87994C7.31714 10.012 7.34545 10.1427 7.40045 10.2645C7.45545 10.3864 7.53605 10.497 7.63759 10.5899L12.2309 14.8299C12.3316 14.9237 12.4514 14.9981 12.5835 15.0488C12.7155 15.0996 12.8571 15.1257 13.0001 15.1257C13.1431 15.1257 13.2847 15.0996 13.4167 15.0488C13.5487 14.9981 13.6685 14.9237 13.7693 14.8299L18.4168 10.5899C18.5183 10.497 18.5989 10.3864 18.6539 10.2645C18.7089 10.1427 18.7372 10.012 18.7372 9.87994C18.7372 9.74793 18.7089 9.61723 18.6539 9.49537C18.5989 9.37351 18.5183 9.26291 18.4168 9.16994Z"
        fill="#226FE3"
      />
    </svg>
  );
};

export default DirectionDown;
