import {
  Box,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { CloseCircle } from 'asset';
import { LoadingPopup } from 'components/common';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getParentAddressDetailApi } from 'services';

interface Props {
  open: boolean;
  handleClosePopup?: any;
  onSuccess?: any;
  subject?: any;
  idAddress?: any;
}

const PopupDetailAddress = (props: Props) => {
  const { open, handleClosePopup, subject, idAddress } = props;
  const [data, setData] = useState<any>();
  const [loadingPopup, setLoadingPopup] = useState(false);

  useEffect(() => {
    getDetail();
    // eslint-disable-next-line
  }, []);

  const getDetail = async () => {
    setLoadingPopup(true);
    await getParentAddressDetailApi(subject, idAddress)
      .then((r) => {
        setData(r.data.data);
        setLoadingPopup(false);
      })
      .catch((e) => {
        toast.error(e.response.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .finally(() => {});
  };

  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiPaper-root': {
          width: 523,
          height: 'auto',
          borderRadius: 0,
        },
      }}
    >
      <Grid
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '20px 25px',
        }}
      >
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '100%',
          }}
        >
          Address Detail
        </Typography>
        <IconButton
          onClick={handleClosePopup}
          sx={{
            padding: 0,
          }}
        >
          <CloseCircle />
        </IconButton>
      </Grid>
      <Divider />
      {loadingPopup && <LoadingPopup color="primary" />}
      <form style={{ height: '100%' }}>
        <Stack
          p="30px 24px 50px 26px"
          height="100%"
          justifyContent="space-between"
        >
          <Box mb="30px">
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '100%',
                color: '#8E8E93',
                mb: '5px',
              }}
            >
              ID
            </Typography>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: '18px',
                lineHeight: '100%',
                color: '#000',
              }}
            >
              {data?.id}
            </Typography>
          </Box>
          <Box display="flex">
            <Box mr="30px">
              <Box mb="30px">
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '100%',
                    color: '#8E8E93',
                    mb: '5px',
                  }}
                >
                  Address Name
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '100%',
                    color: '#000',
                  }}
                >
                  {data?.name}
                </Typography>
              </Box>
              <Box mb="30px">
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '100%',
                    color: '#8E8E93',
                    mb: '5px',
                  }}
                >
                  Block No.
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '100%',
                    color: '#000',
                  }}
                >
                  {data?.block_number}
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '100%',
                    color: '#8E8E93',
                    mb: '5px',
                  }}
                >
                  Street Name
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '100%',
                    color: '#000',
                  }}
                >
                  {data?.street}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Box mb="30px">
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '100%',
                    color: '#8E8E93',
                    mb: '5px',
                  }}
                >
                  Building Name
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '100%',
                    color: '#000',
                  }}
                >
                  {data?.building_name}
                </Typography>
              </Box>
              <Box mb="30px">
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '100%',
                    color: '#000',
                    mb: '5px',
                  }}
                >
                  Unit No.
                </Typography>
                <Grid container alignItems="center" textTransform="capitalize">
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: '14px',
                      lineHeight: '100%',
                      color: '#000',
                    }}
                  >
                    {data?.unit_number}
                  </Typography>
                </Grid>
              </Box>
              <Box mb="30px">
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '100%',
                    color: '#8E8E93',
                    mb: '5px',
                  }}
                >
                  Postal Code
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '100%',
                    color: '#000',
                  }}
                >
                  {data?.postal_code}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Stack>
      </form>
    </Dialog>
  );
};

export default PopupDetailAddress;
