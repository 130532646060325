import React, { useMemo } from 'react';

interface Props {
  active?: boolean;
}

const PendingPayoutsIcon = ({ active }: Props) => {
  const fill = useMemo(() => {
    return active ? "#ffffff" : "#FF796E"
  }, [active])
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2" y="5.33301" width="12" height="9.33333" rx="3" stroke={fill} />
      <ellipse rx="0.666667" ry="0.666667" transform="matrix(1 0 0 -1 12 12.6663)" fill={fill} />
      <ellipse rx="0.666667" ry="0.666667" transform="matrix(1 0 0 -1 3.99998 7.33333)" fill={fill} />
      <ellipse rx="1.33333" ry="1.33333" transform="matrix(1 0 0 -1 8.00002 9.99967)" stroke={fill} strokeWidth="1.5" />
      <path d="M9.68687 2.03665C9.88213 2.23191 9.88213 2.54849 9.68687 2.74375C9.4916 2.93901 9.17502 2.93901 8.97976 2.74375L9.68687 2.03665ZM8.47138 1.52827L8.11783 1.88182L8.11783 1.88182L8.47138 1.52827ZM7.52858 1.52827L7.88213 1.88182L7.88213 1.88182L7.52858 1.52827ZM7.0202 2.74375C6.82494 2.93901 6.50836 2.93901 6.31309 2.74375C6.11783 2.54849 6.11783 2.23191 6.31309 2.03665L7.0202 2.74375ZM8.49998 3.66634C8.49998 3.94248 8.27612 4.16634 7.99998 4.16634C7.72384 4.16634 7.49998 3.94248 7.49998 3.66634H8.49998ZM7.49998 1.72353C7.49998 1.44739 7.72384 1.22353 7.99998 1.22353C8.27612 1.22353 8.49998 1.44739 8.49998 1.72353H7.49998ZM8.97976 2.74375L8.11783 1.88182L8.82494 1.17472L9.68687 2.03665L8.97976 2.74375ZM7.88213 1.88182L7.0202 2.74375L6.31309 2.03665L7.17502 1.17472L7.88213 1.88182ZM8.11783 1.88182C8.05274 1.81674 7.94722 1.81674 7.88213 1.88182L7.17502 1.17472C7.63063 0.719105 8.36933 0.719104 8.82494 1.17472L8.11783 1.88182ZM7.49998 3.66634V1.72353H8.49998V3.66634H7.49998Z" fill={fill} />
    </svg>
  );
};

export default PendingPayoutsIcon;
