import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { CloseCircle } from 'asset';
import { Inputs, SelectInput } from 'components';
import PhoneNumberField from 'components/common/PhoneNumberField';
import { INIT_COUNTRY_CODE, patternPassword } from 'config';
import yup from 'config/yup.custom';
import { NotifyService } from 'helpers';
import { EDIT_USER_TYPE, PHONE_TYPE } from 'models';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { editUsersApi } from 'services';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { GET_USERS_DETAIL_REQUEST } from 'store/reducers/accounts/actionTypes';
import { optionStatus, optionStatusEmailOrPhone } from './data';
import { LoadingPopup } from 'components/common';

interface EditAdminProps {
  open: boolean;
  handleClosePopup: any;
  onSuccess?: any;
  subject?: any;
}

interface IFormInputs {
  id: string;
  status: string;
  phone_number: PHONE_TYPE;
  phone_number_verified: boolean;
  password: string;
  email: string;
  email_verified: boolean;
  first_name: string;
  last_name: string;
}

const defaultValues: Partial<IFormInputs> = {
  id: '',
  status: '',
  phone_number: {
    country_code: INIT_COUNTRY_CODE.code,
    phone_number: '',
  },
  phone_number_verified: false,
  password: '',
  email: '',
  email_verified: false,
  first_name: '',
  last_name: '',
};

const PopupEditUser = (props: EditAdminProps) => {
  const { open, handleClosePopup, onSuccess, subject } = props;
  const { users } = useAppSelector((state) => state.accountReducer);
  const { loadingPopup } = useAppSelector((state) => state.globalReducer);

  const theme = useTheme();
  const dispatch = useAppDispatch();

  const schema = useMemo(() => {
    return yup.object({
      email: yup.string().email('Invalid email').required('Email is required'),
      first_name: yup.string().required('First name is required'),
      last_name: yup.string().required('Last name is required'),
      phone_number: yup
        .object({
          country_code: yup.string().required('Country code is required'),
          phone_number: yup.string().required('Phone number is required'),
        })
        .required('Phone number is required'),
      password: yup.string().matches(patternPassword, {
        message: 'Invalid password',
        excludeEmptyString: true,
      }),
    });
  }, []);

  const form = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  useEffect(() => {
    if (subject) {
      dispatch({
        type: GET_USERS_DETAIL_REQUEST,
        payload: {
          id: subject,
        },
      });
    }
    // eslint-disable-next-line
  }, [subject]);

  useEffect(() => {
    if (users.users_detail) {
      form.reset({
        id: users.users_detail.id,
        status: users.users_detail.status,
        phone_number: {
          country_code:
            users.users_detail.country_code ||
            defaultValues?.phone_number?.country_code,
          phone_number: users.users_detail.phone_number,
        },
        phone_number_verified: users.users_detail.phone_number_verified,
        email: users.users_detail.email,
        email_verified: users.users_detail.email_verified,
        first_name: users.users_detail.first_name,
        last_name: users.users_detail.last_name,
      });
    }
    // eslint-disable-next-line
  }, [users.users_detail]);

  const onSubmit = async (value: IFormInputs) => {
    const dataEdit: EDIT_USER_TYPE = {
      country_code: value.phone_number?.country_code,
      phone_number: value.phone_number?.phone_number,
      phone_number_verified: value.phone_number_verified,
      email: value.email,
      email_verified: value.email_verified,
      first_name: value.first_name,
      last_name: value.last_name,
      status: value.status,
    };
    if (value.password) dataEdit.password = value.password;

    await editUsersApi(subject, dataEdit)
      .then((r) => {
        if (r.data.message === 'Success') {
          NotifyService.success('Success');
          onSuccess();
          handleClosePopup();
        }
      })
      .catch((e) => NotifyService.error(e))
      .finally(() => {});
  };

  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiPaper-root': {
          width: 527,
          borderRadius: 0,
        },
      }}
    >
      <Grid>
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '20px 25px',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '100%',
            }}
          >
            Edit User
          </Typography>
          <IconButton
            onClick={handleClosePopup}
            sx={{
              padding: 0,
            }}
          >
            <CloseCircle />
          </IconButton>
        </Grid>
        <Divider />
        {loadingPopup && <LoadingPopup color="primary" />}

        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Stack spacing="20px" p="25px 24px">
            <Stack>
              <Inputs title="ID" form={form} name="id" readOnly />
            </Stack>
            <Stack>
              <SelectInput
                title="Status"
                form={form}
                options={optionStatus}
                name="status"
              />
            </Stack>
            <Stack>
              <Inputs
                title="Email"
                form={form}
                name="email"
                // errorsName={errors.details?.email?.message}
              />
            </Stack>
            <Stack>
              <SelectInput
                title="Email Status "
                form={form}
                options={optionStatusEmailOrPhone}
                name="email_verified"
              />
            </Stack>
            <Stack>
              <PhoneNumberField
                title="Phone Number"
                name="phone_number"
                form={form}
                // errorsName={errors.details?.phone_number?.message}
              />
            </Stack>
            <Stack>
              <SelectInput
                title="Phone Status "
                form={form}
                options={optionStatusEmailOrPhone}
                name="phone_number_verified"
              />
            </Stack>
            <Stack direction="row" spacing="14px">
              <Stack
                sx={{
                  width: '50%',
                }}
              >
                <Inputs
                  title="First Name"
                  form={form}
                  name="first_name"
                  // errorsName={errors.details?.first_name?.message}
                />
              </Stack>
              <Stack
                sx={{
                  width: '50%',
                }}
              >
                <Inputs
                  title="Last Name"
                  form={form}
                  name="last_name"
                  // errorsName={errors.details?.last_name?.message}
                />
              </Stack>
            </Stack>
            <Grid>
              <Inputs
                showEyes
                title={subject ? 'Change password' : 'Password'}
                type="password"
                name="password"
                placeholder="Password"
                form={form}
                // errorsName={errors.details?.password?.message}
              />
            </Grid>

            <Stack direction="row" spacing="8px">
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleClosePopup}
                sx={{
                  height: 30,
                  width: '100%',
                  fontWeight: 600,
                  fontSize: '14px',
                  lineHeight: 1.1,
                  border: `1px solid ${theme.palette.secondary.main}`,
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                sx={{
                  height: 30,
                  width: '100%',
                  fontWeight: 600,
                  fontSize: '14px',
                  lineHeight: 1.1,
                }}
              >
                Update
              </Button>
            </Stack>
          </Stack>
        </form>
      </Grid>
    </Dialog>
  );
};

export default PopupEditUser;
