import {
  Box,
  // Drawer,
  // ListItem,
  // ListItemIcon,
  // ListItemText,
  Toolbar,
} from '@mui/material';
import React, { ReactNode } from 'react';
// import { Link as RouterLink, LinkProps } from 'react-router-dom';
// import InboxIcon from '@mui/icons-material/MoveToInbox';
import MenuLeft from 'components/Global/MenuLeft';

interface Props {
  children: ReactNode;
}

// interface ListItemLinkProps {
//   icon?: React.ReactElement;
//   primary: string;
//   to: string;
// }

// const drawerList = [
//   {
//     icon: <InboxIcon />,
//     text: 'Dashboard',
//     to: '/',
//     links: null,
//   },
//   {
//     icon: <InboxIcon />,
//     text: 'Accounts',
//     to: null,
//     links: [
//       {
//         icon: <InboxIcon />,
//         text: 'Parents',
//         to: '/accounts/parents',
//       },
//       {
//         icon: <InboxIcon />,
//         text: 'Sitters',
//         to: '/accounts/sitters',
//       },
//       {
//         icon: <InboxIcon />,
//         text: 'Superadmin',
//         to: '/accounts/superadmin',
//       },
//     ],
//   },
//   {
//     icon: <InboxIcon />,
//     text: 'Requests',
//     to: '/requests',
//     links: [
//       {
//         icon: <InboxIcon />,
//         text: 'Just once',
//         to: '/requests/just-once',
//       },
//       {
//         icon: <InboxIcon />,
//         text: 'Recurring',
//         to: '/requests/recurring',
//       },
//     ],
//   },
//   {
//     icon: <InboxIcon />,
//     text: 'Jobs',
//     to: '/jobs',
//     links: null,
//   },
//   {
//     icon: <InboxIcon />,
//     text: 'Finance',
//     to: '/',
//     links: [
//       {
//         icon: <InboxIcon />,
//         text: 'Transaction',
//         to: '/finance/transaction',
//       },
//       {
//         icon: <InboxIcon />,
//         text: 'Recurring',
//         to: '/finance/payments',
//       },
//       {
//         icon: <InboxIcon />,
//         text: 'Pending Payout',
//         to: '/finance/pending-payout',
//       },
//     ],
//   },
//   {
//     icon: <InboxIcon />,
//     text: 'Report',
//     to: null,
//     links: null,
//   },
//   {
//     icon: <InboxIcon />,
//     text: 'Settings',
//     to: '/settings',
//     links: null,
//   },
//   {
//     icon: <InboxIcon />,
//     text: 'Contact Us',
//     to: '/contact-us',
//     links: null,
//   },
// ];

// const ListItemLink = (props: ListItemLinkProps) => {
//   const { icon, primary, to } = props;

//   const renderLink = React.useMemo(
//     () =>
//       React.forwardRef<HTMLAnchorElement, Omit<LinkProps, 'to'>>(function Link(
//         itemProps,
//         ref
//       ) {
//         return <RouterLink to={to} ref={ref} {...itemProps} role={undefined} />;
//       }),
//     [to]
//   );

//   return (
//     <li>
//       <ListItem button component={renderLink}>
//         {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
//         <ListItemText primary={primary} />
//       </ListItem>
//     </li>
//   );
// };

const Layout = ({ children }: Props) => {
  // const [openAccount, setOpenAccount] = useState<boolean>(false);
  // const [openFinance, setOpenFinance] = useState<boolean>(false);
  // const [openRequest, setOpenRequest] = useState<boolean>(false);

  // const onClickDropdownDrawer = (type: string) => {
  //   switch (type) {
  //     case 'Accounts':
  //       setOpenAccount(!openAccount);
  //       break;
  //     case 'Requests':
  //       setOpenRequest(!openRequest);
  //       break;
  //     case 'Finance':
  //       setOpenFinance(!openFinance);
  //       break;
  //     default:
  //       break;
  //   }
  // };

  return (
    <Box sx={{ display: 'flex' }}>
      <MenuLeft />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: '#F4F6F9',
          p: '15px 10px 18px 19px',
          height: '100vh',
        }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
};

export default Layout;
