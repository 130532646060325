import { Stack, Typography } from '@mui/material';
import PopupUpdate2FA from 'components/Popups/Popup2FE';
import { NotifyService } from 'helpers';
import { useEffect, useState } from 'react';
import { getAdminProfileApi } from 'services';
import { ToggleButtonGroupStyled, ToggleButtonStyled } from './styles';

const Accounts = () => {
  const [enable2fa, setEnable2fa] = useState(false);
  const [open2FA, setOpen2FA] = useState(false);

  const fetch2fa = async () => {
    await getAdminProfileApi()
      .then(({ data }) => {
        setEnable2fa(data.data.enable_2fa);
      })
      .catch((e) => NotifyService.error(e));
  };

  useEffect(() => {
    fetch2fa();
  }, []);

  const handleChange = (val: boolean) => {
    if (val === null) return;
    setOpen2FA(true);
  };

  const handleSuccess = () => {
    fetch2fa();
    setOpen2FA(false);
  };

  return (
    <>
      <Stack sx={{ width: '475px' }} spacing="5px">
        <Typography sx={{ fontSize: '15px', fontWeight: '500' }}>
          Two-factor authentication
        </Typography>
        <ToggleButtonGroupStyled
          fullWidth
          color="primary"
          value={enable2fa}
          exclusive
          onChange={(_, val) => handleChange(val)}
          aria-label="Platform"
        >
          <ToggleButtonStyled value={true}>On</ToggleButtonStyled>
          <ToggleButtonStyled value={false}>Off</ToggleButtonStyled>
        </ToggleButtonGroupStyled>
      </Stack>
      {open2FA && (
        <PopupUpdate2FA
          open={open2FA}
          handleClosePopup={() => setOpen2FA(false)}
          enable2FA={enable2fa}
          onSuccess={handleSuccess}
        />
      )}
    </>
  );
};

export default Accounts;
