import {
  Button,
  Dialog,
  DialogActions,
  dialogClasses,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { IconCreate } from 'asset';
import React, { useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'config/yup.custom';
import { useForm } from 'react-hook-form';
import { Inputs } from 'components/form-control';
import { updateManyPaymentStatusApi, updatePaymentStatusApi } from 'services';
import { CASH_TRANSACTION_STATUS } from 'models';
import { NotifyService } from 'helpers';

interface IFormInputs {
  remark: string;
}

const defaultValues: Partial<IFormInputs> = {
  remark: ''
};

interface Props {
  open: boolean;
  subject?: string | string[];
  multiple?: boolean;
  handleClosePopup: () => void;
  onSuccess: () => void;
}


const PopupRejectPaymentPayout = ({
  open,
  subject,
  multiple,
  handleClosePopup,
  onSuccess,
}: Props) => {

  const schema = useMemo(() => {
    return yup.object({
      remark: yup.string().required("Reason for rejection is required")
    });
  }, []);

  const form = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  const { reset, handleSubmit } = form;

  const _handleClosePopup = () => {
    reset({ ...defaultValues})
    handleClosePopup()
  }

  const onSubmit = async (data: IFormInputs) => {
    const append: any = {}
    if (data.remark) append.remark = data.remark
    if (multiple) {
      if (!subject?.length) return
      await updateManyPaymentStatusApi({
        ids: subject as string[],
        status: CASH_TRANSACTION_STATUS.FAIL,
        ...append
      })
      .then(r => {
        _handleClosePopup()
        onSuccess()
        NotifyService.success(r)
      })
      .catch((e) => NotifyService.error(e))
      .finally(() => { });
    } else {
      if (!subject) return
      await updatePaymentStatusApi(subject as string, {
        status: CASH_TRANSACTION_STATUS.FAIL,
        ...append
      })
      .then(r => {
        _handleClosePopup()
        onSuccess()
        NotifyService.success(r)
      })
      .catch((e) => NotifyService.error(e))
      .finally(() => { });
    }
  }

  return (
    <Dialog
      open={open}
      onClose={_handleClosePopup}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        [`& .${dialogClasses.paper}`]: {
          maxWidth: '400px',
          width: '100%',
        },
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            py: '24px',
            display: 'flex',
            alignItems: 'center',
          }}
          component="div"
        >
          <IconCreate />
        </DialogTitle>
        <DialogContent
          sx={{
            padding: '0px 24px',
          }}
        >
          <Typography
            variant="Large_Body_18_SemiBold"
            sx={{
              mb: '8px',
            }}
          >
            Reason for Rejection
          </Typography>
          <Grid>
            <Inputs
              form={form}
              name="remark"
            />
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            padding: '24px',
          }}
        >
          <Button
            onClick={_handleClosePopup}
            variant="outlined"
            sx={{
              border: '1px solid rgba(0, 0, 0, 0.08)',
              color: 'black.light',
              ':hover': {
                color: 'secondary.main',
                border: '1px solid rgba(0, 0, 0, 0.08)',
                bgcolor: 'transparent',
              },
              width: '100%',
            }}
          >
            No
          </Button>
          <Button
            autoFocus
            variant="contained"
            color="secondary"
            sx={{
              width: '100%',
            }}
            type="submit"
          >
            Yes
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default PopupRejectPaymentPayout;
