import { AxiosResponse } from 'axios';
import { API } from 'config';
import {
  CHART_DATA_TYPE,
} from 'models';
import { AppAPIInstance } from 'services';


export const getNumberOfParentsData = async (): Promise<AxiosResponse<{ data: CHART_DATA_TYPE }>> => {
  return await AppAPIInstance.get(`${API.DASHBOARD.PARENTS.DEFAULT}`);
};

export const getNumberOfSittersData = async (): Promise<AxiosResponse<{ data: CHART_DATA_TYPE }>> => {
  return await AppAPIInstance.get(`${API.DASHBOARD.SITTERS.DEFAULT}`);
};

export const getNumberOfHoursData = async (): Promise<AxiosResponse<{ data: CHART_DATA_TYPE }>> => {
  return await AppAPIInstance.get(`${API.DASHBOARD.HOURS.DEFAULT}`);
};

export const getNumberOfTransactionsData = async (): Promise<AxiosResponse<{ data: CHART_DATA_TYPE }>> => {
  return await AppAPIInstance.get(`${API.DASHBOARD.TRANSACTIONS.DEFAULT}`);
};

export const getServicesFeeCharged = async (): Promise<AxiosResponse<{ data: CHART_DATA_TYPE }>> => {
  return await AppAPIInstance.get(`${API.DASHBOARD.SERVICE_FEE.DEFAULT}`);
};

export const getSummaryStatistics = async (): Promise<AxiosResponse<{ data: CHART_DATA_TYPE }>> => {
  return await AppAPIInstance.get(`${API.DASHBOARD.STATISTICS.DEFAULT}`);
};