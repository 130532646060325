import {
  Avatar,
  Box,
  Button,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { AddSitter, CloseCircle } from 'asset';
import ItemSitter from 'components/common/ItemSitter';
import PopupEditServiceRequest from 'components/Popups/ServiceRequest/PopupEditServiceRequest';
import { ECLOSE_POPUP, SR_TYPE } from 'models';
import { useEffect, useState } from 'react';
// import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { GET_REQUESTS_DETAIL_REQUEST } from 'store/reducers/requests/actionTypes';
import PopupEditSitter from '../PopupEditSitter';

interface DetailRequestsProps {
  open: boolean;
  handleClosePopup?: any;
  onSuccess?: any;
  subject?: any;
}

const DetailRequests = (props: DetailRequestsProps) => {
  const { open, handleClosePopup, subject, onSuccess } = props;
  const theme = useTheme();

  const { requests_detail } = useAppSelector(
    (state) => state.requestReducer.requests
  );
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [openEditSitterPopup, setOpenEditSitterPopup] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (subject) {
      dispatch({
        type: GET_REQUESTS_DETAIL_REQUEST,
        payload: {
          id: subject.id,
        },
      });
    }
    // eslint-disable-next-line
  }, [subject]);
  const onSuccessRequests = () => {
    onSuccess();
    dispatch({
      type: GET_REQUESTS_DETAIL_REQUEST,
      payload: {
        id: subject.id,
      },
    });
  };

  const changeAge = (number: number) => {
    if (number === 0) {
      return '0 - 1 months';
    }
    if (number === 1) {
      return '2 - 12 months';
    }
    if (number === 2) {
      return '1 - 3 years old';
    }
    if (number === 3) {
      return '4 - 6 years old';
    }
    if (number === 4) {
      return '7 - 12 years old';
    }
    if (number === 5) {
      return '13+ years old';
    }
  };

  const onClosePopup = (type: string) => {
    if (type === ECLOSE_POPUP.CREATE_OR_EDIT) {
      setOpenEditPopup(false);
    }
    if (type === ECLOSE_POPUP.ADD) {
      setOpenEditSitterPopup(false);
    }
  };

  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiPaper-root': {
          width: 530,
          borderRadius: 0,
        },
      }}
    >
      <Grid>
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '20px 25px',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '100%',
            }}
          >
            {requests_detail?.number}
          </Typography>
          <IconButton
            onClick={handleClosePopup}
            sx={{
              padding: 0,
            }}
          >
            <CloseCircle />
          </IconButton>
        </Grid>
        <Stack
          direction="row"
          spacing="8px"
          sx={{
            padding: '0px 25px',
            marginBottom: '23px',
          }}
        >
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            sx={{
              height: 32,
              width: '169px',
              fontWeight: 600,
              fontSize: '14px',
              lineHeight: 1.1,
              padding: 0,
              minWidth: 169,
            }}
            onClick={() => setOpenEditPopup(true)}
          >
            Edit Request Details
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            endIcon={<AddSitter />}
            sx={{
              height: 32,
              width: '156px',
              fontWeight: 600,
              fontSize: '14px',
              lineHeight: 1.1,
              padding: 0,
              border: `1px solid ${theme.palette.secondary.main}`,
            }}
            onClick={() => setOpenEditSitterPopup(true)}
          >
            Manage Sitters
          </Button>
        </Stack>
        <Divider />
        <Box p="23px 32px">
          <Stack direction="row" spacing="12px" mb="21px">
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '18px',
                lineHeight: '100%',
              }}
            >
              {requests_detail?.type === SR_TYPE.JUST_ONCE
                ? 'Just Once'
                : 'Recurring'}
            </Typography>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '18px',
                lineHeight: '100%',
                color: theme.palette.primary.main,
                textTransform: 'capitalize',
              }}
            >
              {requests_detail?.status}
            </Typography>
          </Stack>
          <Stack direction="row" spacing="45px" sx={{ marginBottom: '25px' }}>
            <Grid>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '14px',
                  color: '#545454',
                  marginBottom: '4px',
                }}
              >
                Form
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '16px',
                  color: '#000',
                }}
              >
                {requests_detail?.from_date} {requests_detail?.from_time}
              </Typography>
            </Grid>
            <Grid>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '14px',
                  color: '#545454',
                  marginBottom: '4px',
                }}
              >
                To
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '16px',
                  color: '#000',
                }}
              >
                {requests_detail?.to_date} {requests_detail?.to_time}
              </Typography>
            </Grid>
          </Stack>
          <Box mb="25px">
            <Grid>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '14px',
                  color: '#545454',
                  marginBottom: '4px',
                }}
              >
                Address
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '16px',
                  color: '#000',
                }}
              >
                {requests_detail?.address.street}{' '}
                {requests_detail?.address.name}
              </Typography>
            </Grid>
          </Box>
          <Box mb="16px">
            <Grid>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '14px',
                  color: '#545454',
                  marginBottom: '9px',
                }}
              >
                Children
              </Typography>
              <Stack direction="row" spacing="10px">
                {requests_detail?.children.map((item) => {
                  return (
                    <Grid
                      key={item.id}
                      sx={{
                        padding: '10px 12px',
                        width: '164px',
                        height: '57px',
                        background: 'rgba(255, 121, 110, 0.04)',
                        border: '1px solid rgba(34, 111, 227, 0.08)',
                        borderRadius: '5px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Avatar
                        src={item.picture}
                        sx={{
                          width: '37px',
                          height: '37px',
                          marginRight: '6px',
                        }}
                      />
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: 400,
                            fontSize: '14px',
                            lineHeight: '16px',
                            color: '#000',
                          }}
                        >
                          {item.full_name}
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 400,
                            fontSize: '14px',
                            lineHeight: '16px',
                            color: '#000',
                            opacity: 0.5,
                          }}
                        >
                          {changeAge(item.age_range)}
                        </Typography>
                      </Box>
                    </Grid>
                  );
                })}
              </Stack>
            </Grid>
          </Box>
          <Box mb="25px">
            <Grid>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '14px',
                  color: '#545454',
                  marginBottom: '4px',
                }}
              >
                Requested on
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '16px',
                  color: '#000',
                }}
              >
                {requests_detail?.createdAt}
              </Typography>
            </Grid>
          </Box>
          <Divider />
          <Box my="16px">
            <Grid>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '14px',
                  color: '#545454',
                  marginBottom: '9px',
                }}
              >
                Sitters
              </Typography>
              <Stack spacing="10px">
                {requests_detail?.bookings.map((item, index) => {
                  return <ItemSitter key={index} subject={item} />;
                })}
              </Stack>
            </Grid>
          </Box>
        </Box>
      </Grid>
      {openEditPopup && (
        <PopupEditServiceRequest
          type={requests_detail?.type}
          parentId={subject?.parent_id}
          open={openEditPopup}
          subject={subject?.id}
          handleClosePopup={() => onClosePopup(ECLOSE_POPUP.CREATE_OR_EDIT)}
          onSuccess={onSuccessRequests}
        />
      )}
      {openEditSitterPopup && (
        <PopupEditSitter
          open={openEditSitterPopup}
          // subject={subject?.id}
          handleClosePopup={() => onClosePopup(ECLOSE_POPUP.ADD)}
          onSuccess={onSuccessRequests}
        />
      )}
    </Dialog>
  );
};

export default DetailRequests;
