import { HeadCell, APP_VERSION_TYPE } from 'models';

export const headCells: readonly HeadCell<APP_VERSION_TYPE>[] = [
  {
    id: 'flatform',
    numeric: false,
    disablePadding: true,
    isSort: false,
    label: 'Flatform',
  },
  {
    id: 'version',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'App Version',
  },
  {
    id: 'force_updatero',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Update Type',
  },
  {
    id: 'updatedAt',
    numeric: false,
    disablePadding: false,
    isSort: false,
    label: 'Date',
  },
];
