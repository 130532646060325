import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GET_PROMOS_LIST_PARAMS, PROMOS_LIST_STATE } from 'models';

export interface PromosState {
  promos: {
    promos_list: PROMOS_LIST_STATE;
  };
}

const initialState: PromosState = {
  promos: {
    promos_list: {
      records: [],
      metadata: {
        page: 1,
        limit: 50,
        page_count: 1,
        total_pages: 1,
        total_count: 1,
      },
      params: { page: 1, limit: 50 },
    },
  },
};

const promosSlice = createSlice({
  name: 'promos',
  initialState,
  reducers: {
    setPromosListSuccess(
      state: PromosState,
      action: PayloadAction<PROMOS_LIST_STATE>
    ) {
      state.promos.promos_list = {
        ...state.promos.promos_list,
        ...action.payload,
      };
    },
    setPromosListParams(
      state: PromosState,
      action: PayloadAction<GET_PROMOS_LIST_PARAMS | null>
    ) {
      state.promos.promos_list.params = action.payload;
    },
  },
});

// Actions
export const { setPromosListSuccess, setPromosListParams } =
  promosSlice.actions;

export default promosSlice.reducer;
